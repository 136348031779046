/* eslint-disable */
import Button from '../../../components/v2/Button/Button';
import { Typography } from '../../../components/v2/Typography/Typography';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useDashboard } from '../../../provider/dashboardProvider';

import LanderItem from '../../../components/LanderItem/LanderItem';
import border1Svg from './border-1.svg';
import border2Svg from './border-2.svg';
import border3Svg from './border-3.svg';
import border4Svg from './border-4.svg';
import image1Png from './image-1.png';
import image2Png from './image-2.png';
import image3Png from './image-3.png';
import image4Png from './image-4.png';
import { useNavigate } from 'react-router-dom';
import { getOutputData, OutputResponse } from '../../../api/output';
import { useEffect, useState } from 'react';

type CeremonyDesignerLanderProps = {
  onUpgradeButtonClick?: () => void;
};

const CeremonyDesignerLander = (props: CeremonyDesignerLanderProps) => {
  const { onUpgradeButtonClick } = props;
  const size = useWindowSize();
  const { ceremony } = useDashboard();
  const navigate = useNavigate();
  const [hasOutputData, setHasOutputData] = useState(false);
  const [outputDataLoaded, setOutputDataLoaded] = useState(false);

  const getOutput = async (ceremonyId: string) => {
    try {
      const outputResponse: OutputResponse = await getOutputData(ceremonyId);
      if (
        outputResponse.success &&
        outputResponse.data &&
        outputResponse.data.length > 0
      ) {
        setHasOutputData(true);
      }
      setOutputDataLoaded(true);
    } catch (err) {}
  };

  useEffect(() => {
    if (ceremony?.id) {
      getOutput(ceremony.id.toString());
    }
  }, [ceremony]);
  return (
    <div className=' flex flex-col items-center text-center gap-6 mt-10 lg:mt-0'>
      <div>
        <Typography
          className='max-w-xl'
          type={size.isMobile ? 'display-600' : 'display-1000'}
        >
          Craft Your Ceremony with Ease
        </Typography>
        <Typography type='body-600' className='max-w-xl mt-6'>
          Not sure where to begin with your ceremony script? Whether adapting
          traditions or starting from scratch, our all-in-one Ceremony Builder
          helps couples and their officiants every step of the way.
        </Typography>
      </div>

      <div className='w-full items-center justify-center flex flex-row gap-2 my-6 '>

        {outputDataLoaded && (
          <Button
            fullWidth={size.isMobile}
            variant='primary'
            className='vow-benefit-placeholder'
            onClick={() =>
              navigate(`/ceremony-designer/${ceremony?.id.toString() || ''}`)
            }
          >
            {hasOutputData ? 'Continue' : 'Get Started'}
          </Button>
        )}
        {/* <a href={`${process.env.REACT_APP_BIRTHS_URL}/gifting`} target='_blank'>
          <Button
            fullWidth={size.isMobile}
            leftImgSrc={circlePlaySvg}
            variant='secondary'
            className='vow-benefit-placeholder'
            onClick={() => {}}
          >
            Watch Demo
          </Button>
        </a> */}

      </div>
      <div className='w-full flex flex-col justify-center items-center md:flex-row gap-3 flex-wrap'>
        <LanderItem
          borderSvgSource={border1Svg}
          iconSvgSource={image1Png}
          text='Complete the tailored questionnaire'
        />
        <LanderItem
          borderSvgSource={border2Svg}
          iconSvgSource={image2Png}
          text='Generate your personalized draft'
          shiftDown={size.isMobile ? false : true}
        />
        <LanderItem
          borderSvgSource={border3Svg}
          iconSvgSource={image3Png}
          text='Explore 600+ readings and rituals'
        />
        <LanderItem
          borderSvgSource={border4Svg}
          iconSvgSource={image4Png}
          text='A ceremony that reflects both partners'
        />
      </div>
    </div>
  );
};

export default CeremonyDesignerLander;
