import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { appToolOptions } from '../../../api/paywall';
import { setUserCustomField } from '../../../helpers/userHelper';
import { useAuth } from '../../../provider/authProvider';
import { useDashboard } from '../../../provider/dashboardProvider';
import CustomCheckBox from '../../forms/CustomCheckBox';
import Button from '../Button/Button';
import AddonSingleSelectCard from '../Cards/AddonSingleSelectCard';
import { Modal } from '../Modal/Modal';
import Space from '../Space/Space';
import { Typography } from '../Typography/Typography';

type SpeechwritingAddonModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onContinue?: () => void;
  appTool: appToolOptions;
};

const SpeechwritingAddonModal = ({
  isOpen,
  onClose,
  appTool,
  onContinue,
}: SpeechwritingAddonModalProps) => {
  const { user, getProfile } = useAuth();
  const { ceremony } = useDashboard();
  const params = useParams();

  const [usableCeremonyId, setUsableCeremonyId] = useState<string | null>(null);

  const [dontShowAgain, setDontShowAgain] = useState(false);

  useEffect(() => {
    if (params.ceremonyId) {
      setUsableCeremonyId(params.ceremonyId);
    }

    if (ceremony?.id) {
      setUsableCeremonyId(ceremony.id.toString());
    }
  }, [ceremony, params.ceremonyId]);

  useEffect(() => {
    if (usableCeremonyId) {
      //eslint-disable-next-line
      setUserCustomField(
        `skip-modal-${usableCeremonyId}-${appTool}`,
        `${dontShowAgain.toString()}`
      );
      getProfile(() => {});
    }
  }, [dontShowAgain]);

  return (
    <Modal
      maxWidth='lg'
      title='Get Help From a Pro'
      titleProps={{ isCentered: true }}
      showCloseButton={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <>
        <Typography type='body-600' className='text-center max-w-[436px] mb-8'>
          Script still not sounding right? Let our professional speechwriters
          ensure your ceremony is a meaningful and memorable celebration of your
          love
        </Typography>
        <AddonSingleSelectCard
          title='Speech Editing Pro'
          price={299}
          buttonTitle='Purchase This Service'
          attributes={[
            'Upload your ceremony draft',
            'Get matched with one of our professional writers',
            'Receive two rounds of feedback & edits',
          ]}
          onSelect={() => {
            usableCeremonyId &&
              (window.location.href = `/pay-merchandise/${usableCeremonyId}/${appTool}`);
          }}
        />
        <Space size={2} />
        <AddonSingleSelectCard
          title='Speech Editing Pro + Coaching'
          price={499}
          buttonTitle='Purchase This Service'
          description='Everything in Speech Editing Pro, plus:'
          attributes={[
            'Two video calls with a professional public speaking coach',
          ]}
          onSelect={() => {
            usableCeremonyId &&
              (window.location.href = `/pay-merchandise/${usableCeremonyId}/${appTool}`);
          }}
        />

        <div className='flex flex-row mt-8 justify-between'>
          <CustomCheckBox
            label="Don't show again"
            bold={false}
            value={dontShowAgain}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              setDontShowAgain(ev.target.value === 'false' ? true : false);
            }}
            checked={dontShowAgain}
          />

          <Button variant='text' size='400' onClick={onContinue}>
            Skip and Regenerate
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default SpeechwritingAddonModal;
