import { outputModules } from '../api/output';
import { Module } from '../hooks/useActiveModule';

import { getTextDurationString } from './textHelper';

export const getModuleTitle = (module: outputModules) => {
  let result = '';
  switch (module) {
    case outputModules.welcome:
      result = 'Welcome & Procession';
      break;
    case outputModules['officiant-address']:
      result = "Officiant's Address";
      break;
    case outputModules['readings-rituals']:
      result = 'Readings & Rituals';
      break;
    case outputModules.vows:
      result = 'Vows';
      break;
    case outputModules.declaration:
      result = 'Ring Exchange & "I Do"';
      break;
    case outputModules.pronouncement:
      result = 'Pronouncement, Kiss & Celebration';
      break;
    case outputModules.guest_standalone_toast:
      result = 'Wedding Toast';
      break;
    case outputModules.friends_engagement_toast:
      result = 'Engagement Party Toast';
      break;
    case outputModules.friends_reception_toast:
      result = 'Reception Party Toast';
      break;
    case outputModules.friends_rehearsal_toast:
      result = 'Rehearsal Dinner Toast';
      break;
    case outputModules.guest_standalone_toast_ai:
      result = 'Wedding Toast';
      break;
    case outputModules.friends_engagement_toast_ai:
      result = 'Engagement Party Toast';
      break;
    case outputModules.friends_reception_toast_ai:
      result = 'Reception Party Toast';
      break;
    case outputModules.friends_rehearsal_toast_ai:
      result = 'Rehearsal Dinner Toast';
      break;
    case outputModules.vows_reflectional:
      result = 'Intro';
      break;
    case outputModules.vows_promises:
      result = 'The Vows';
      break;
    case outputModules.vows_future:
      result = 'Conclusion';
      break;

    default:
      break;
  }
  return result;
};

export const getModuleTitleFromModule = (module: Module | null) => {
  let result = '';
  switch (module) {
    case Module.DirectionalQuestions:
      result = 'Welcome & Procession';
      break;
    case Module.OfficiantRemarks:
      result = "Officiant's Address";
      break;
    case Module.RitualsAndReadings:
      result = 'Readings & Rituals';
      break;
    case Module.Vows:
    case Module.VowsCeremony:
      result = 'Vows';
      break;
    case Module.DeclarationOfIntent:
      result = 'Ring Exchange & "I Do"';
      break;
    case Module.Pronouncement:
    case Module.PronouncementCelebration:
      result = 'Pronouncement, Kiss & Celebration';
      break;
    default:
      break;
  }
  return result;
};

export const mapOutputModuleToQuestionModule = (module: outputModules) => {
  let result: Module | null = null;
  switch (module) {
    case outputModules.welcome:
      result = Module.DirectionalQuestions;
      break;
    case outputModules['officiant-address']:
      result = Module.OfficiantRemarks;
      break;
    case outputModules['readings-rituals']:
      result = Module.RitualsAndReadings;
      break;
    case outputModules.vows:
      result = Module.VowsCeremony;
      break;
    case outputModules.declaration:
      result = Module.DeclarationOfIntent;
      break;
    case outputModules.pronouncement:
      result = Module.PronouncementCelebration;
      break;
    case outputModules.guest_standalone_toast:
      result = Module.GuestFFQ;
      break;
    case outputModules.friends_engagement_toast:
    case outputModules.friends_reception_toast:
    case outputModules.friends_rehearsal_toast:
      result = Module.GuestToast;
      break;
    case outputModules.vows_reflectional:
      result = Module.VowsReflectional;
      break;
    case outputModules.vows_promises:
      result = Module.VowsPromises;
      break;
    case outputModules.vows_future:
      result = Module.VowsFuture;
      break;
    case outputModules.friends_engagement_toast_ai:
    case outputModules.friends_reception_toast_ai:
    case outputModules.friends_rehearsal_toast_ai:
      result = Module.GuestToastAi;
      break;
    default:
      break;
  }
  return result;
};

export const calculateTime = (
  module: outputModules,
  text: string,
  ritualMinutes?: number
) => {
  let extraDuration = 0;

  if (module === outputModules['readings-rituals'] && ritualMinutes) {
    extraDuration = ritualMinutes;
  }
  // todo - do this calculation when vows module is completed
  // else if (module === outputModules.vows) {
  //   result += vowsMinutes;
  // }
  const resultString = getTextDurationString(text, extraDuration);
  return resultString;
};
