/* eslint-disable */
import { useNavigate, useParams } from 'react-router-dom';

import { ModuleQuestion, ModuleWrapper } from '../components';

import unionIcon from '../../../assets/images/icons/union.svg';
import useQuestions from '../../../hooks/useQuestions';
import useDeclarations from '../../../hooks/useDeclarations';
import useCeremony from '../../../hooks/useCeremony';
import usePronouncements from '../../../hooks/usePronouncements';
import { ModuleStatus } from '../enum';
import Button from '../../../components/v2/Button/Button';
import { Module } from '../../../hooks/useActiveModule';
import { getTexts } from '../../../helpers/renderText';
import { useCeremonyBuilder } from '../../../provider/ceremonyBuilderProvider';
import { useEffect } from 'react';
import { generalLog } from '../../../api/ceremony';
import { transformQuestionOptions } from '../components/ModuleQuestion/helpers';
import { CTAProvider } from '../../../provider/ctaProvider';

export const RingExchangeModule = ({
  hideWrapper = false,
  onModuleStatusChange,
}: {
  hideWrapper?: boolean;
  onModuleStatusChange?: (status: ModuleStatus) => void;
}) => {
  const ceremonyId = useParams()?.ceremonyId;

  const { setDraftingModalOpen, setSelectedModule, setGenerateDraftInitiated } =
    useCeremonyBuilder();

  const { questions, fetchQuestions } = useQuestions();
  const { declarations } = useDeclarations();
  const { pronouncements } = usePronouncements();
  const navigate = useNavigate();

  const {
    ceremony,
    couple1,
    couple2,
    officiant,
    currentUser,
    fetchCeremonyById,
  } = useCeremony();

  useEffect(() => {
    if (ceremonyId) {
      void fetchCeremonyById(ceremonyId);
    }
  }, [ceremonyId]);

  useEffect(() => {
    if (ceremony) {
      void fetchQuestions(Module.DeclarationOfIntent, ceremony.id.toString());
    }
  }, [ceremony]);

  const members = { couple1, couple2, officiant, currentUser };

  return (
    <CTAProvider
      totalQuestions={questions?.length || 0}
      requiredQuestions={questions?.length || 0}
      onModuleStatusChange={onModuleStatusChange}
      module={Module.DeclarationOfIntent}
    >
      <ModuleWrapper
        hideWrapper={hideWrapper}
        chapterProps={{
          chapter: 'Chapter 5',
          renderIcon: () => <img src={unionIcon} draggable={false} />,
          iconWrapperProps: { className: 'bg-cashmere-600' },
        }}
        summaryProps={{
          moduleName: 'Ring Exchange & “I Do’s”',
          moduleDescription: getTexts('i_dos_about', currentUser?.member_type),
          numberOfQuestions: 2,
          estimatedTime: 5,
        }}
        questionsHeaderProps={{
          renderCenterButton: (
            moduleStatus: ModuleStatus,
            status: 'saving' | 'saved' | 'unsaved'
          ) => (
            <Button
              size='100'
              disabled={
                (moduleStatus !== ModuleStatus.completed &&
                  moduleStatus !== ModuleStatus.inProgress) ||
                status !== 'saved'
              }
              onClick={() => {
                if (ceremonyId) {
                  setSelectedModule(Module.DeclarationOfIntent);
                  setDraftingModalOpen(true);
                  setGenerateDraftInitiated(true);
                  generalLog({
                    ceremony_id: ceremonyId,
                    activity: `Generate - Ring Exchange & “I Do’s” Module`,
                  });
                  navigate(`/ceremony-designer/${ceremonyId}`);
                } else {
                  navigate(-1);
                }
              }}
            >
              Generate Section
            </Button>
          ),
          renderRightButton: (moduleStatus: ModuleStatus) => (
            <Button
              variant='secondary'
              onClick={() => {
                if (ceremonyId) {
                  navigate(`/ceremony-designer/${ceremonyId}`);
                } else {
                  navigate(-1);
                }
              }}
            >
              Exit & Save
            </Button>
          ),
        }}
      >
        {ceremony &&
          questions &&
          questions.map((questionData, questionIndex) => {
            return (
              <ModuleQuestion
                key={`question-${questionData.id}`}
                ceremonyId={ceremony.id}
                question={questionData}
                questionNumber={questionIndex + 1}
                numberOfQuestions={questions?.length ?? 0}
                members={members}
                options={transformQuestionOptions(
                  questionData,
                  declarations || [],
                  pronouncements || []
                )}
              />
            );
          })}
      </ModuleWrapper>
    </CTAProvider>
  );
};
