import { _request } from './request';

export type Pronouncement = {
  id: number;
  name: string;
  text: string;
};

export type GetPronouncementsResponse = {
  success: boolean;
  message: string;
  data: Pronouncement[];
};

export const getPronouncements = async () => {
  const responseBody = await _request<GetPronouncementsResponse>({
    url: `pronouncements`,
    method: 'GET',
  });
  return responseBody;
};
