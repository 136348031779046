import 'react-responsive-carousel/lib/styles/carousel.min.css';

import './index.scss';



import { set } from 'dot-object';
import { useEffect, useState } from 'react';
import ReactFbPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TiktokPixel from 'tiktok-pixel';

import {
  OrderResponse,
  confirmpaymentSession,
  NewPaymentBody,
  Order,
  PayOption,
  confirmSubscription,
} from '../../api/paywall';
import { activateAllCeremonies } from '../../api/weddingPlanner';
import Container from '../../components/Container';
import Loader from '../../components/Loader/loader';
import SnackbarComponent from '../../components/Snackbar/Snackbar';
import Button from '../../components/button';
import { Title } from '../../components/titles';
import { ChecklistItemIdentifier } from '../../helpers/checklistHelpers/identifiers';
import { sendCustomerIoEventHandler } from '../../helpers/customerIoHelper';
import { getAPIErrorMessage } from '../../helpers/helper';
import useLoader from '../../hooks/useLoader';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useChecklist } from '../../provider/checklistProvider';

type payeeDetailType = {
  name: string;
  email: string;
};
interface LocationState {
  plan: PayOption;
  payee: string;
  payeeDetail: payeeDetailType;
  success: boolean;
}
const SubscriptionConfirmation = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { isShowing, showLoader, hideLoader } = useLoader();
  const { isActive, message, type, openSnackBar, handleClose } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState<Order>();
  const [hashedEmail, setHashedEmail] = useState<string>('');

  const { saveMemberChecklistItemUsingIdentifier } = useChecklist();

  const status = searchParams.get('st') === 't';
  const ref = searchParams.get('rf');
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    void confirmPaymentRef();
  }, [ref]);

  // useEffect(() => {
  //   if (hashedEmail) {
  //     window.gtag('set', 'user_data', {
  //       sha256_email_address: hashedEmail,
  //     });
  //   }
  // }, [hashedEmail]);

  // const hash = async (string: string) => {
  //   string = string.trim().toLowerCase();
  //   const utf8 = new TextEncoder().encode(string);
  //   const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  //   const hashArray = Array.from(new Uint8Array(hashBuffer));
  //   const hashHex = hashArray
  //     .map((bytes) => bytes.toString(16).padStart(2, '0'))
  //     .join('');
  //   setHashedEmail(hashHex);
  // };

  // useEffect(() => {
  //   if (status) {
  //     if (order) {
  //       order.user?.email && hash(order.user?.email);

  //       // ReactFbPixel.track('Purchase', {
  //       //   currency: 'USD',
  //       //   value: order.actual_amount,
  //       // });
  //       ReactFbPixel.fbq(
  //         'track',
  //         'Purchase',
  //         {
  //           currency: 'USD',
  //           value: order.actual_amount,
  //         },
  //         {
  //           eventID: `prov_${order.id}`,
  //         }
  //       );

  //       ReactGA.event({
  //         category: 'PayWall',
  //         action: 'OrderPaymentSuccessful',
  //         label: 'OrderPaymentSuccessful',
  //       });
  //       window.gtag('event', 'conversion', {
  //         send_to: 'AW-11021677486/qoHXCMOh6JoYEK7nxYcp',
  //         value: order.actual_amount,
  //         currency: 'USD',
  //         transaction_id: '',
  //       });
  //       window.gtag('event', 'conversion', {
  //         send_to: 'AW-11021677486/qoHXCMOh6JoYEK7nxYcp',
  //         value: order.actual_amount,
  //         currency: 'USD',
  //         transaction_id: '',
  //       });

  //       TiktokPixel.track('CompletePayment', {
  //         content_type: 'product',
  //         quantity: 1,
  //         content_id: order.product_id,
  //         currency: 'USD',
  //         value: order.actual_amount,
  //       });
  //     }
  //   }
  // }, [order]);

  const confirmPaymentRef = async () => {
    try {
      const body: NewPaymentBody = {
        ref: ref || '',
        sessionId: sessionId || '',
      };
      const sessionResponse: OrderResponse = await confirmSubscription(body);
      if (sessionResponse.success) {
        setOrder(sessionResponse.order);
        if (status) {
          await saveMemberChecklistItemUsingIdentifier(
            {
              completed: true,
            },
            ChecklistItemIdentifier.ADD_REST_OF_CLIENTS,
            true
          );

          void sendCustomerIoEventHandler('WP Payment Confirmed');

          openSnackBar('Payment processed successfully', 5000, 'success');
          void activateAllCeremonies();
        } else {
          openSnackBar('Payment could not be processed', 5000, 'error');
        }
      } else {
        openSnackBar(sessionResponse.message, 5000, 'error');
      }
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      openSnackBar(getAPIErrorMessage(err as any), 5000, 'error');
    } finally {
      hideLoader();
    }
  };

  return (
    <div className='bg-containerbg min-h-4/5 flex flex-col space-between items-center justify-center overflow-x-hidden pb-10'>
      <Loader isShowing={isShowing} hide={hideLoader} allowHide={false} />
      <SnackbarComponent
        isActive={isActive}
        message={message}
        type={type}
        handleClose={handleClose}
      />
      <Title
        className='text-copper-primary font-sans uppercase mt-8'
        h={2}
        text='Provenance'
      />
      <Title
        className={`${
          status ? 'text-forest-primary' : 'text-red-500'
        } font-nanum font-bold`}
        h={1}
        text={status ? 'Payment Completed' : 'Payment incomplete'}
      />
      <Container className='content-center place-content-center mt-4 auto-rows-fr'>
        <div className='hidden md:block md:col-span-1 lg:col-span-3'></div>
        <div className='col-span-4 md:col-span-4 lg:col-span-6'>
          <div className='border border-forest-100 p-6 bg-white flex flex-col items-center'>
            <div className='grid grid-cols-12 gap-x-10'>
              <div className='col-span-1'></div>
              <div className='col-span-10 flex flex-col font-inter text-forest-primary text-center py-2'>
                <span className=''>
                  {status
                    ? `Your payment has been successfully processed.`
                    : `There was a problem with your payment!`}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Button
        title={'Continue'}
        onClick={() => {
          navigate(`/ceremony/planner/dashboard`)
        }}
        className={`mt-6 px-2 md:px-3 lg:px-4 xl:px-6 2xl:px-8 
            py-2 md:py-2 lg:py-3 xl:py-3 2xl:py-4 
            min-w-b1 md:min-w-b1 lg:min-w-b2 xl:min-w-b3 2xl:min-w-b4
            uppercase text-base flex justify-center cursor-pointer mx-3 border border-forest-primary bg-white text-forest-primary`}
      />
      {status && order && order.id && (
        <img
          id='_SHRSL_img_1'
          src={`https://www.shareasale.com/sale.cfm?tracking=${order.id}&amount=${order.actual_amount}&merchantID=135962&v=dev1.5&transtype=sale&currency=${order.currency}`}
          width='1'
          height='1'
        ></img>
      )}
    </div>
  );
};

export default SubscriptionConfirmation;
