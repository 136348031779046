import { EventAddOfficiantModal } from '../../pages/EventCreation/EventAddOfficiantModal';
import { EventAddSpeakerModal } from '../../pages/EventCreation/EventAddSpeakerModal';
import { EventDateModal } from '../../pages/EventCreation/EventDateModal';
import { EventGuestRoleModal } from '../../pages/EventCreation/EventGuestRoleModal';
import { EventPartnerNamesModal } from '../../pages/EventCreation/EventPartnerNamesModal';
import { EventRoleModal } from '../../pages/EventCreation/EventRoleModal';
import { useAuth } from '../../provider/authProvider';
import { useCreateEvent } from '../../provider/createEventProvider';

export const CreateNewEventModals = () => {
  const {
    eventRoleModalOpen,
    setEventRoleModalOpen,
    handleEventRoleModalNextClick,
    eventGuestRoleModalOpen,
    setEventGuestRoleModalOpen,
    handleEventGuestRoleModaBackClick,
    handleEventGuestRoleModalNextClick,
    eventDateModalOpen,
    setEventDateModalOpen,
    handleEventDateModalBackClick,
    handleEventDateModalNextClick,
    eventPartnerNamesModalOpen,
    setEventPartnerNamesModalOpen,
    handlePartnerNamesModalBackClick,
    handlePartnerNamesModalNextClick,
    memberType,
    allEmails,
    eventAddOfficiantModalOpen,
    setEventEventAddOfficiantModalOpen,
    handleAddOfficiantModalNextClick,
    handleAddOfficiantModalBackClick,
    setOfficiants,
    officiants,
    eventAddSpeakerModalOpen,
    setEventAddSpeakerModalOpen,
    handleAddSpeakerModalNextClick,
    handleAddSpeakerModalBackClick,
    setSpeakers,
    speakers,
  } = useCreateEvent();

  const auth = useAuth();
  return (
    <>
      <EventRoleModal
        isOpen={eventRoleModalOpen}
        onClose={() => setEventRoleModalOpen(false)}
        title='What is your role in the wedding?'
        onNextClick={handleEventRoleModalNextClick}
      />
      <EventGuestRoleModal
        isOpen={eventGuestRoleModalOpen}
        onClose={() => setEventGuestRoleModalOpen(false)}
        title='What is your role in the wedding?'
        onBackClick={handleEventGuestRoleModaBackClick}
        onNextClick={handleEventGuestRoleModalNextClick}
      />
      <EventDateModal
        isOpen={eventDateModalOpen}
        onClose={() => setEventDateModalOpen(false)}
        title='What is the date of the wedding?'
        onBackClick={handleEventDateModalBackClick}
        onNextClick={handleEventDateModalNextClick}
      />
      <EventPartnerNamesModal
        isOpen={eventPartnerNamesModalOpen}
        onClose={() => setEventPartnerNamesModalOpen(false)}
        title='Who is getting married?'
        onBackClick={handlePartnerNamesModalBackClick}
        onCompleteClick={handlePartnerNamesModalNextClick}
        loggedUserRole={memberType}
        loggedUser={auth.user}
        allEmails={allEmails}
      />
      <EventAddOfficiantModal
        isOpen={eventAddOfficiantModalOpen}
        onClose={() => setEventEventAddOfficiantModalOpen(false)}
        title='Add Officiant'
        onNextClick={handleAddOfficiantModalNextClick}
        onBackClick={handleAddOfficiantModalBackClick}
        setOfficiants={setOfficiants}
        officiants={officiants}
        allEmails={allEmails}
      />
      <EventAddSpeakerModal
        isOpen={eventAddSpeakerModalOpen}
        onClose={() => setEventAddSpeakerModalOpen(false)}
        title='Add speakers at other events?'
        onNextClick={handleAddSpeakerModalNextClick}
        onBackClick={handleAddSpeakerModalBackClick}
        setSpeakers={setSpeakers}
        speakers={speakers}
        allEmails={allEmails}
      />
    </>
  );
};
