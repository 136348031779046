import { TextField as MUITextField } from '@mui/material';
import classNames from 'classnames';

import './TextField.scss';
import { TextFieldProps } from './typings.d';

import React from 'react';

import BlurOverlay from '../BlurOverlay/BlurOverlay';
import { Typography } from '../Typography/Typography';
import lockSvg from '../../../assets/images/svg/lock-new.svg';

export const TextField = ({
  label,
  labelVariant = 'standard',
  fontVariant = 'inter',
  wrapperProps,
  isPrivate,
  ...props
}: TextFieldProps) => {
  const muiLabel = !!label && labelVariant === 'standard' ? label : undefined;
  return (
    <div
      {...wrapperProps}
      className={classNames('TextField', wrapperProps?.className, {
        'TextField-grotesk': fontVariant === 'grotesk',
        'TextField-inter': fontVariant === 'inter',
      })}
    >
      {labelVariant === 'outside' && !!label && (
        <React.Fragment>
          {typeof label === 'string' && (
            <label className='TextField__label'>{label}</label>
          )}
          {typeof label !== 'string' && (
            <div className='TextField__label'>{label}</div>
          )}
        </React.Fragment>
      )}
      {isPrivate ? (
        <BlurOverlay
          deductBorder={true}
          overlayContent={
            <div className='flex flex-row items-center justify-center'>
              <img src={lockSvg} alt='locked' />
              <Typography
                variant='functional-low'
                type='body-400'
                className='ml-2'
              >
                Private
              </Typography>
            </div>
          }
        >
          <MUITextField disabled {...props} label={muiLabel} />
        </BlurOverlay>
      ) : (
        <MUITextField {...props} label={muiLabel} />
      )}
    </div>
  );
};
