import { useMediaQuery } from '@mui/material';
import { useEffect, useRef } from 'react';

import { Command, RichTextCommandButtonToolbar } from './RichTextCommandButton';
import { COMMANDS } from './commands';
import './rich-text-toolbar.scss';

type Props = {
  warningMobileVisible?: boolean;
}

export const RichTextToolbar = ({ warningMobileVisible }: Props) => {
  const isMobile = true;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const containerRefProps = useRef<Record<string, any>>({ // eslint-disable-line
    clone: null,
    offsetTop: 0,
  });
  
  function execCommand(command: string) {
    document.execCommand(command, false);
  }

  function calculateContainerBoundings() {
    if (containerRef.current) {
      const clone = containerRef.current.cloneNode(true) as HTMLDivElement;
      clone.style.visibility = 'hidden';
      const elementY = containerRef.current.getBoundingClientRect().top;
      containerRefProps.current = {
        ...containerRefProps.current,
        offsetTop: elementY + window.scrollY,
        clone,
      }
    }
  }

  useEffect(() => {
    function handlerScroll() {
      if (!containerRef.current) return;
      const offsetY = window.scrollY;
      const { offsetTop, clone } = containerRefProps.current
      if (offsetY > offsetTop) {
        containerRef.current.parentNode?.appendChild(clone);
        containerRef.current.style.position = 'fixed';
        containerRef.current.style.top = '0px';
      } else if (containerRef.current.parentNode?.contains?.(clone as Node)) {
        containerRef.current.parentNode?.removeChild(clone);
        containerRef.current.style.position = 'static';
        containerRef.current.style.top = '';
      }
    }

    window.addEventListener('scroll', handlerScroll);
    return () => window.removeEventListener('scroll', handlerScroll);
  }, [isMobile]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    calculateContainerBoundings();
  }, [isMobile, warningMobileVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full relative">
      <div className='RichTextToolbar-wrapper' ref={containerRef}>
        <div className='RichTextToolbar-container'>
          {COMMANDS.map((button, idx) => {
            const isGroup = button.type === 'group';
            const commands = isGroup
              ? (button.commands || [])
              : [{ icon: button.icon, command: button.command }];
            return (
              <RichTextCommandButtonToolbar
                key={`buttom-command-${idx}`}
                command={commands as Command[]}
                grouped={button.type === 'group'}
                onClick={execCommand}
                useDropdownOnMobile={button.dropdownOnMobile}
                maxCommandsOnMobile={button.maxCommandsOnMobile}
              />
            )
          })}
        </div>
      </div>
    </div>
  );
};
