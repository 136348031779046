import { Typography } from '../../../../components/v2/Typography/Typography';
import envelopeSvg from '../ClientsResources/images/envelope.svg';

import {
  ClientResourcesItem,
  ClientResourcesItemsProps,
} from './ClientResourcesItem';
import fileLine from './images/file-lines.svg';
import filePowerpoint from './images/file-powerpoint.svg';
import video from './images/video.svg';
import './ClientsResources.scss';

import { downloadFile } from '../../../../helpers/downloadHelper';
import { useDashboard } from '../../../../provider/dashboardProvider';
import VideoModal from '../../../../components/v2/VideoModal/VideoModal';

import { useNavigate } from 'react-router-dom';

export const ClientsResources = () => {
  const { setYoutubeVideoModalOpen, setYoutubeVideoUrl } = useDashboard();

  const navigate = useNavigate();

  const resources: ClientResourcesItemsProps[] = [
    {
      title: 'Email Templates',
      description:
        "All of the copy you need for your email automations relating to clients' ceremonies & speeches.",
      image: envelopeSvg,
      buttonText: 'View',
   
      link: 'https://docs.google.com/document/d/1ThIavGEQcwC9EzOcaApTqpgqa0uijvGbdEc3qPons3M/edit#heading=h.e1r6pnw6fdln',
    },
    {
      title: 'Video Demo for Clients',
      description:
        'The best way to show your clients how our tools make their ceremony and vows stress-free.',
      image: video,
      buttonText: 'Watch',  
      onClick: () => {
        setYoutubeVideoUrl('M9viKA0ZJQs');
        setYoutubeVideoModalOpen(true);
      }
    },
    {
      title: 'PDF One-Pager',
      description:
        'Everything your client needs to know about Provenance on one page.',
      image: fileLine,
      buttonText: 'View',
    
      link: 'https://www.canva.com/design/DAGKxYRRzmQ/uxf2Rz7rI3VHyjJJgRYWLg/view?utm_content=DAGKxYRRzmQ&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview',
    },
    {
      title: 'Sales Consultation Slide',
      description:
        'Add this Canva slide to your sales consultations to book more couples with ease.',
      image: filePowerpoint,
      buttonText: 'View',
     
      link: 'https://www.canva.com/design/DAGJHJKFC78/Y6rtTc8aYZP9X1nnuCuh6w/view?utm_content=DAGJHJKFC78&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview',
    },
  ];

  return (
    <div className='PlannerDashboard PlannerDashboard-root'>
      <VideoModal />
      <div className='PlannerDashboard-section flex flex-col'>
        <div className='PlannerDashboard-section__header'>
          <Typography type='display-400'>Your Client Resources</Typography>
          <Typography type='body-400'>
            Resources to enhance consultations & sales, to onboard clients, and
            to help them create impressive ceremonies, vows, and toasts.
          </Typography>
        </div>

        <div className='PlannerDashboard-resources'>
          {resources.map((resource) => (
            <ClientResourcesItem
              key={resource.title}
              title={resource.title}
              description={resource.description}
              image={resource.image}
              buttonText={resource.buttonText}
              onClick={resource.onClick}
              link={resource.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
