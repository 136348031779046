import React, { useState } from 'react';

import { signIn, SignInUpResponse } from '../../../api/auth';
import { getAPIErrorMessage } from '../../../helpers/helper';
import {
  getAppTitleByType,
  navigateToApp,
} from '../../../helpers/redirectToAppHelper';
import { useAuth } from '../../../provider/authProvider';
import { useDashboard } from '../../../provider/dashboardProvider';
import { useSnackbar } from '../../../provider/snackbarProvider';
import CustomTextInput from '../../forms/CustomTextInput';
import Button from '../Button/Button';
import { Modal } from '../Modal/Modal';
import { Typography } from '../Typography/Typography';

const EnterPasswordModal = () => {
  const [password, setPassword] = useState('');
  const {
    currentUser,
    enterPasswordModalOpen,
    setEnterPasswordModalOpen,
    setVerifyCodeModalOpen,
    redeemerApp,
    ceremony,
  } = useDashboard();

  const { openSnackBar } = useSnackbar();

  const auth = useAuth();

  const onConfirmPassword = async () => {
    //showLoader();
    try {
      let loginResponse: SignInUpResponse;

      loginResponse = await signIn({
        email: currentUser?.email || '',
        password: password,
      });

      if (loginResponse.success) {
        await auth.signin(loginResponse.token, () => {
          if (redeemerApp && ceremony) {
            openSnackBar('Creating secure connection...', 'success');
            void navigateToApp(ceremony.id, redeemerApp);
          }
        });
      } else {
        //hideLoader();
        openSnackBar(loginResponse.message, 'error');
      }
    } catch (err) {
      //hideLoader();
      openSnackBar(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getAPIErrorMessage(err as any),
        'error'
      );
    }
    setEnterPasswordModalOpen(false);
  };

  const onNoPasswordClick = () => {
    setEnterPasswordModalOpen(false);
    setVerifyCodeModalOpen(true);
  };

  return (
    <Modal
      isOpen={enterPasswordModalOpen}
      onClose={() => setEnterPasswordModalOpen(false)}
    >
      <>
        <Typography type='display-600'>Enter your password</Typography>
        <Typography type='body-400' className='mt-2 mb-8 text-center'>
          {`Enter your existing password below to gain access to
          ${redeemerApp ? getAppTitleByType(redeemerApp) : ''}.`}
        </Typography>
        <CustomTextInput
          fullWidth={true}
          label='Password'
          placeholder='Enter password'
          className='w-full mx-0'
          value={password}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(ev.target.value);
          }}
          type={'password'}
        />
        <div className='flex flex-row justify-between items-center mt-8'>
          <Button variant='text' onClick={onNoPasswordClick}>
            I don't have a password
          </Button>
          <Button disabled={!password} onClick={onConfirmPassword}>
            Confirm Password
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default EnterPasswordModal;
