import { Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import chevronDownSvg from '../../../assets/images/icons/chevron-down.svg';
import chevronUpSvg from '../../../assets/images/icons/chevron-up.svg';
import { Drawer } from '../../../pages/ModulesV2/components/Drawer/Drawer';
import { useAuth } from '../../../provider/authProvider';
import Button from '../Button/Button';

import './accountMenu.scss';

import { Typography } from '../Typography/Typography';

import { Logout } from '@mui/icons-material';




type AccountMenuProps = {
  name: string;
  expanded: boolean;
};

export const MobileAccountMenu = (props: AccountMenuProps) => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const auth = useAuth();

  const signOut = () => {
    auth.signout();
    navigate('/');
  };

  const { name } = props;
  return (
    <>
      <div onClick={() => setOpen(true)} className='AccountMenu-root'>
        <div className='AccountMenu-avatar'>
          <Typography variant='functional-low' type='body-100'>
            {name[0]}
          </Typography>
        </div>
      </div>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <div className='p-8'>
          <div
            className='cursor-pointer flex flex-row  py-6'
            onClick={() => navigate('/settings')}
          >
            <div className='AccountMenu-avatar mr-4'>
              <Typography variant='functional-low' type='body-100'>
                {name[0]}
              </Typography>
            </div>
            <Typography variant='functional-low' type='body-400'>
              Account
            </Typography>
          </div>
          <Divider />
          <div
            className='cursor-pointer flex flex-row py-6'
            onClick={() => {
              signOut();
            }}
          >
            <ListItemIcon className='min-w-0'>
              <Logout className='mr-5' fontSize='small' />
            </ListItemIcon>
            <Typography variant='functional-low' type='body-400'>
              Log Out
            </Typography>
          </div>

          <Button
            className='w-full'
            variant='secondary'
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </div>
      </Drawer>
    </>
  );
};
