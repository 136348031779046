/*eslint-disable*/
import { sendCustomerIoEvent } from '../api/ceremony';

interface CustomerIoIdentify {
  email: string;
}

export const sendCustomerIoEventHandler = (
  eventName: string,
  eventData?: any,
  customEmail?: string
) => {
  try {
    sendCustomerIoEvent(eventName, eventData || {}, customEmail);
  } catch (error) {
    console.log(error);
  }
};

export const customerIoIdentify = ({ email }: CustomerIoIdentify) => {
  const _cio = (window as any)._cio || [];
  (window as any)._cio = _cio;

  _cio.identify({ id: email, email: email });
};

export const customerIoTrack = (eventData: { name: string; data: any }) => {
  const _cio = (window as any)._cio || [];
  (window as any)._cio = _cio;

  _cio.track(eventData.name, eventData.data);
};
