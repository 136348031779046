import React from 'react';

import Button from '../../../../components/v2/Button/Button';

type MarkCompleteButtonProps = {
  onClick: () => Promise<void> | void;
};

const MarkCompleteButton = (props: MarkCompleteButtonProps) => {
  const { onClick } = props;
  return (
    <Button
      eventClick={(event) => {
        event.stopPropagation();
        void onClick();
      }}
      variant='text'
    >
      Mark Complete
    </Button>
  );
};

export default MarkCompleteButton;
