import { Member } from '../../../../api/ceremony';
import { Declaration } from '../../../../api/declaration';
import { Pronouncement } from '../../../../api/pronouncement';
import { capitalizeFirstLetter } from '../../../../helpers/helper';
import { interpolatePlaceholders } from '../../../../helpers/placeholderHelper';
import {
  Question,
  QuestionOptions,
} from '../../../Modules/common/moduleQuestion/ModuleQuestion';

export const getOptionLabel = (
  option: QuestionOptions | Declaration | Pronouncement,
  members: {
    couple1: Member | undefined;
    couple2: Member | undefined;
    officiant: Member | undefined;
    currentUser: Member | undefined;
  }
) => {
  //get the name if option is Declaration or Pronouncement
  let value = '';
  if ((option as Declaration).name) {
    value = (option as Declaration).name;
  } else if ((option as Pronouncement).name) {
    value = (option as Pronouncement).name;
  } else if (option as QuestionOptions) {
    value = (option as QuestionOptions).option;
  }

  return typeof value === 'string'
    ? capitalizeFirstLetter(
        interpolatePlaceholders({ text: value, data: members })
      )
    : value;
};

export const getOptionDescription = (
  option: QuestionOptions | Declaration | Pronouncement,
  members: {
    couple1: Member | undefined;
    couple2: Member | undefined;
    officiant: Member | undefined;
    currentUser: Member | undefined;
  }
) => {
  let value = '';
  if ((option as Declaration).text) {
    value = (option as Declaration).text;
  } else if ((option as Pronouncement).text) {
    value = (option as Pronouncement).text;
  } else if (option as QuestionOptions) {
    value = (option as QuestionOptions).description;
  }
  return typeof value === 'string'
    ? interpolatePlaceholders({ text: value, data: members })
    : value;
};

export enum QuestionSelectOptionType {
  Collapsible = 'collapsible',
  Grid = 'grid',
  Inline = 'inline',
}

export const getQuestionSelectType = (
  question: Question
): QuestionSelectOptionType => {
  let result = QuestionSelectOptionType.Inline;
  const collapsibleQuestionsTypes = ['declaration', 'pronouncement'];
  if (!!question.type && collapsibleQuestionsTypes.includes(question.type)) {
    result = QuestionSelectOptionType.Collapsible;
  }
  if (question.identifier?.includes('ceremonyDuration')) {
    result = QuestionSelectOptionType.Grid;
  }
  return result;
};

export const transformQuestionOptions = (
  question: Question,
  declarations: Declaration[],
  pronouncements: Pronouncement[]
): QuestionOptions[] => {
  if (question.type === 'declaration') {
    const filteredDeclarations = declarations?.filter((d) => {
      if (d.question_id === question.id) {
        return d;
      }
    });

    return filteredDeclarations.map(
      (declaration) =>
        ({
          id: declaration.id,
          option: declaration.name,
          description: declaration.text,
        } as QuestionOptions)
    );
  }
  if (question.type === 'pronouncement')
    return pronouncements.map(
      (pronouncement) =>
        ({
          id: pronouncement.id,
          option: pronouncement.name,
          description: pronouncement.text,
        } as QuestionOptions)
    );

  return question.options || [];
};
