import { Drawer } from '@mui/material';

import { generalLog } from '../../api/ceremony';
import { OutputData, outputModules } from '../../api/output';
import closeIcon from '../../assets/images/icons/close-x-v2.svg';
import EmptyState from '../../components/v2/EmptyState/EmptyState';
import { Typography } from '../../components/v2/Typography/Typography';
import { getModuleTitleFromModule } from '../../helpers/modulesHelper';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useOutput } from '../../provider/outputProvider';
import { useSnackbar } from '../../provider/snackbarProvider';

import VersionHistoryItem from './VersionHistoryItem';

type VersionHistoryDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  setSelectedVersionId: (versionId: number) => void;
  versions: OutputData[];
  selectedVersionHistoryModule?: outputModules | null;
  variant: 'toast' | 'vows' | 'ceremony' | 'welcomeToast';
};

export const VersionHistoryDrawer = (props: VersionHistoryDrawerProps) => {
  const {
    isOpen,
    setSelectedVersionId,
    versions,
    onClose,
    selectedVersionHistoryModule,
    variant,
  } = props;

  const { ceremony } = useOutput();

  const getEmptyStateText = (
    variant: 'toast' | 'vows' | 'ceremony' | 'welcomeToast'
  ) => {
    let result = '';

    switch (variant) {
      case 'toast':
        result = `As you edit your toast, you'll be able to view and restore previous versions here.`;
        break;
      case 'vows':
        result = `As you edit your vows, you'll be able to view and restore previous versions here.`;
        break;
      case 'ceremony':
        result = `As you edit your ceremony script, you'll be able to view and restore previous versions here.`;
        break;
      case 'welcomeToast':
        result = `As you edit your welcome toast, you'll be able to view and restore previous versions here.`;
        break;
    }
    return result;
  };

  const getToastMessage = (
    variant: 'toast' | 'vows' | 'ceremony' | 'welcomeToast'
  ) => {
    let result = '';

    switch (variant) {
      case 'toast':
        result = `Your toast has been restored.`;
        break;
      case 'vows':
        result = `Your vows have been restored.`;
        break;
      case 'ceremony':
        result = `Your ceremony script have been restored.`;
        break;
      case 'welcomeToast':
        result = `Your welcome toast has been restored.`;
        break;
    }
    return result;
  };
  const { openSnackBar } = useSnackbar();
  const size = useWindowSize();
  setSelectedVersionId(1);
  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        className: 'rounded-l-3xl rounded-r-none bg-cosmicLatte-primary',
      }}
    >
      <div
        className={`${
          size.isMobile ? 'w-[366px]' : 'w-[500px]'
        } p-8 h-screen overflow-hidden max-w-[100vw]`}
      >
        <div className='flex flex-row justify-between items-center mb-8'>
          <Typography type='display-100'>Version History</Typography>
          <div className='cursor-pointer' onClick={onClose}>
            <img alt='close' src={closeIcon} />
          </div>
        </div>
        <div className='h-[calc(100%-64px)] overflow-auto'>
          {versions.length === 0 && (
            <EmptyState
              title='No versions yet'
              text={getEmptyStateText(variant)}
            />
          )}
          {versions
            .filter((version) => {
              if (selectedVersionHistoryModule) {
                return version.module === selectedVersionHistoryModule;
              }

              return true;
            })
            .map((version, index) => (
              <VersionHistoryItem
                key={version.id}
                version={version}
                variant={variant}
                onConfirmSelect={() => {
                  setSelectedVersionId(version.id);
                  openSnackBar(getToastMessage(variant));
                  if (ceremony?.id) {
                    void generalLog({
                      ceremony_id: ceremony.id,
                      activity: `Restore ${variant} Builder`,
                    });
                  }
                  onClose();
                }}
                isCurrentVersion={index === 0}
              />
            ))}
        </div>
      </div>
    </Drawer>
  );
};
