import classNames from 'classnames';
import { useMemo } from 'react';

import { MemberType } from '../../api/ceremony';

import './NewAvatar.scss';
import { NewAvatarProps } from './typings.d';

function getNames(fullName: string) {
  const nameParts = fullName.split(' ');
  const firstName = nameParts[0];
  const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';
  return { firstName, lastName };
}

export const NewAvatar = ({
  fullName,
  memberType,
  useInitials = true,
  size = 'large',
}: NewAvatarProps) => {
  const initials = useMemo(() => {
    if (useInitials !== true) return fullName;
    const { firstName, lastName } = getNames(fullName);
    return firstName[0] + (lastName?.[0] || '');
  }, [fullName, useInitials]);

  const sizeInPixels = useMemo(() => {
    switch (size) {
      case 'small':
        return 'w-6 h-6 lg:w-6 lg:h-6';
      case 'large':
        return 'w-6 h-6 lg:w-12 lg:h-12';
      default:
        return 'w-6 h-6 lg:w-12 lg:h-12';
    }
  }, [size]);

  const fontSize = useMemo(() => {
    switch (size) {
      case 'small':
        return 'text-sm lg:text-sm';
      case 'large':
        return 'text-sm lg:text-xl';
      default:
        return 'text-sm lg:text-xl';
    }
  },  [size]);

  return (
    <div
      className={classNames(
        `${sizeInPixels} flex w- items-center justify-center font-nanum text-xl uppercase rounded-full`,
        {
          'bg-seafoam-100 text-[#324E54]':
            memberType === MemberType.weddingPlanner ||
            memberType === undefined,
          'bg-rose-100 text-redOchre-primary': memberType === MemberType.bride,
          'bg-forest-superlight text-forest-primary':
            memberType === MemberType.groom || memberType === MemberType.other,
          'bg-marigold-100 text-marigold-secondary':
            memberType === MemberType.officiant,
        }
      )}
    >
      <div
        className={classNames(
          `w-16 h-16 flex items-center justify-center font-grotesk ${fontSize} uppercase rounded-full`
        )}
      >
        {fullName === 'TBD TBD' ? '+' : initials}
      </div>
    </div>
  );
};
