import { CheckCircleOutline, ErrorOutlineOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { OrderSources } from '../../../api/paywall';
import downloadSvg from '../../../assets/images/svg/download.svg';
import versionsSvg from '../../../assets/images/svg/versions.svg';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { ModuleStatus } from '../../../pages/ModulesV2/enum';
import { useBuilderCTA } from '../../../provider/builderCTAprovider';
import { useCTA } from '../../../provider/ctaProvider';
import { useOutput } from '../../../provider/outputProvider';
import Button from '../Button/Button';

import './BuilderHeaderNew.scss';

import { usePaywall } from '../../../provider/paywallProvider';

export type BuilderHeadernewProps = {
  status?: ModuleStatus;
  onDownload: () => void;
  onVersionHistoryClick: () => Promise<void>;
  onClose: () => void;
  className?: string;
  lastVersionSaved?: moment.Moment;
  onRegenerateClick: () => void;
  ceremonyId?: number;
  regenerateDisabled?: boolean;
  disableBasedOnModuleStatus?: boolean;
  regenerateText?: string;
  showDownload?: boolean;
  showVersionHistory?: boolean;
  showVowsUnlockButton?: boolean;
};

export const BuilderHeaderNew = ({
  //onSaveClick,
  onDownload,
  onVersionHistoryClick,
  onClose,
  className,
  onRegenerateClick,
  regenerateDisabled,
  disableBasedOnModuleStatus,
  regenerateText = 'Regenerate',
  showDownload = true,
  showVersionHistory = true,
  showVowsUnlockButton = false,
}: BuilderHeadernewProps) => {
  const { status: variant } = useBuilderCTA();
  const isSaved = variant === 'saved';
  const isSaving = variant === 'saving';
  const size = useWindowSize();
  const navigate = useNavigate();

  const ceremonyId = useParams()?.ceremonyId;

  const { setSource } = usePaywall();

  const {
    saved: isOutputSaved,
    saving: isOutputSaving,
    wereChangesMade,
    setWereChangesMade,
  } = useOutput();

  const { moduleStatus } = useCTA();

  const shouldDisable = disableBasedOnModuleStatus
    ? moduleStatus !== ModuleStatus.completed &&
      moduleStatus !== ModuleStatus.requiredAnswered
    : false;

  useEffect(() => {
    return setWereChangesMade(false);
  }, []);

  const statusContent = () => {
    if (isSaving || isOutputSaving)
      return (
        <div className='flex items-center gap-2'>
          <CircularProgress size={16} className='text-system-green-400' />
          <span className='font-grotestk text-sm sm:text-base text-system-green-400'>
            Saving...
          </span>
        </div>
      );

    if (!wereChangesMade)
      return (
        <div className='flex items-center gap-2'>
          <CheckCircleOutline className='w-6 h-6 text-system-green-400' />
          <span className='font-grotestk text-sm sm:text-base text-system-green-400'>
            Saved
          </span>
          {/* <InfoOutlined className='w-4 h-4 text-forest-400' /> */}
        </div>
      );

    if (isSaved && isOutputSaved)
      return (
        <div className='flex items-center gap-2'>
          <CheckCircleOutline className='w-6 h-6 text-system-green-400' />
          <span className='font-grotestk text-sm sm:text-base text-system-green-400'>
            Saved
          </span>
        </div>
      );

    // default: unsaved
    return (
      <div className='flex items-center gap-2'>
        <ErrorOutlineOutlined className='w-6 h-6 text-system-green-400' />
        <span className='font-grotestk text-sm sm:text-base text-system-green-400'>
          Unsaved
        </span>
        {/* <InfoOutlined className='w-4 h-4 text-forest-400' /> */}
      </div>
    );
  };

  return (
    <div className={`BuilderHeader BuilderHeader-root ${className || ''}`}>
      <div className='flex flex-row items-center gap-4'>
        <Button
          size='100'
          variant='secondary'
          onClick={() => onClose()}
          disabled={!(isSaved && isOutputSaved)}
        >
          {isSaved && isOutputSaved ? 'Save & Exit' : 'Waiting for saving...'}
        </Button>
        {!size.isMobile && <div>{statusContent()}</div>}
      </div>

      <div className='flex flex-row items-center gap-2'>
        {showVowsUnlockButton && (
          <Button
            size='100'
            variant='secondary'
            onClick={() => {
              setSource(OrderSources.vb_editor);
              navigate(`/pay-plans/${ceremonyId || 'undefined'}?refferedFrom=openVows`);
            }}
          >
            Unlock Vows
          </Button>
        )}
        {showVersionHistory && (
          <Button
            size='100'
            variant='secondary'
            onClick={onVersionHistoryClick}
            disabled={!(isSaved && isOutputSaved)}
          >
            {size.isMobile ? (
              <img
                src={versionsSvg}
                alt='Versions'
                className='min-w-4 min-h-4'
              />
            ) : (
              'Versions'
            )}
          </Button>
        )}
        {showDownload && (
          <Button
            variant='secondary'
            size='100'
            onClick={() => onDownload()}
            disabled={!(isSaved && isOutputSaved)}
          >
            {size.isMobile ? (
              <img
                src={downloadSvg}
                alt='Download'
                className='min-w-4 min-h-4'
              />
            ) : (
              'Download'
            )}
          </Button>
        )}
        <Button
          size='100'
          variant='primary'
          onClick={onRegenerateClick}
          disabled={
            !(isSaved && isOutputSaved) || regenerateDisabled || shouldDisable
          }
        >
          {regenerateText}
        </Button>
      </div>
    </div>
  );
};
