import { useEffect, useState } from 'react';

import { User } from '../../api/auth';
import { MemberType } from '../../api/ceremony';
import Button from '../../components/button';
import CustomDropdown from '../../components/forms/CustomDropdown';
import CustomTextInput from '../../components/forms/CustomTextInput';
import { Modal } from '../../components/v2/Modal/Modal';
import { getFullName } from '../../helpers/nameHelper';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onBackClick: () => void;
  onCompleteClick: (
    partnerAName: string,
    partnerAEmail: string,
    partnerARole: MemberType,
    partnerBName: string,
    partnerBEmail: string,
    partnerBRole: MemberType
  ) => void;
  title: string;
  loggedUserRole: string;
  loggedUser: User;
  allEmails: string[];
};

export const EventPartnerNamesModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    onBackClick,
    onCompleteClick,
    title,
    loggedUserRole,
    loggedUser,
    allEmails,
  } = props;
  const [partnerAName, setPartnerAName] = useState('');
  const [partnerBName, setPartnerBName] = useState('');
  const [partnerAEmail, setPartnerAEmail] = useState('');
  const [partnerBEmail, setPartnerBEmail] = useState('');
  const [partnerARole, setPartnerARole] = useState<{
    label: string;
    value: string;
  }>();
  const [partnerBRole, setPartnerBRole] = useState<{
    label: string;
    value: string;
  }>();

  const dropdownOptions = [
    { label: 'Bride', value: 'Bride' },
    { label: 'Groom', value: 'Groom' },
    { label: 'Partner', value: 'Other' },
  ];

  useEffect(() => {
    if (loggedUserRole === 'couple' && loggedUser) {
      setPartnerAName(getFullName(loggedUser));
    }
  }, [loggedUserRole, loggedUser]);

  const getMemberType = (role: string) => {
    const typedString = role as keyof typeof MemberType;
    const typedRole = MemberType[typedString];
    return typedRole;
  };

  const areEmailsValid = () => {
    let result = true;
    if (loggedUserRole === 'wedding_planner') {
      if (!partnerAEmail || !partnerBEmail) {
        result = false;
      } else if (partnerAEmail === partnerBEmail) {
        result = false;
      } else if (
        (allEmails &&
          allEmails.filter((e) => e === partnerAEmail).length > 1) ||
        (allEmails && allEmails.filter((e) => e === partnerBEmail).length > 1)
      ) {
        result = false;
      }
    }
    return result;
  };

  return (
    <Modal
    maxWidth='xl'
    isOpen={isOpen} onClose={onClose}>
      <div className=' md:min-w-sm '>
        <h3 className='font-nanum text-2xl'>{title}</h3>
        <div className='w-full'>
          <div className='flex flex-col md:flex-row w-full mt-6'>
            <div className='w-full mr-0 md:mr-4'>
              <h3 className='text-sm'>
                {loggedUserRole === 'couple'
                  ? 'Your Full Name'
                  : "Partner A's full name"}
              </h3>
              <CustomTextInput
                className='w-full ml-0'
                value={partnerAName}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                  setPartnerAName(ev.target.value);
                }}
                type={'text'}
              />
            </div>
            <div className='w-full md:min-w-3xs'>
              <h3 className='text-sm'>
                {' '}
                {loggedUserRole === 'couple' ? 'Your Role' : 'Their Role'}
              </h3>
              <CustomDropdown
                className='w-full ml-0'
                value={partnerARole?.label}
                onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
                  setPartnerARole(
                    dropdownOptions.find(
                      (f) =>
                        f.value.toLowerCase() === ev.target.value.toLowerCase()
                    )
                  );
                }}
                options={dropdownOptions}
              />
            </div>
            {loggedUserRole === 'wedding_planner' && (
              <div className='w-full mr-0 md:ml-4'>
                <h3 className='text-sm'>{'Their email'}</h3>
                <CustomTextInput
                  className='w-full ml-0'
                  value={partnerAEmail}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    setPartnerAEmail(ev.target.value);
                  }}
                  type={'email'}
                  error={
                    (partnerAEmail &&
                      partnerBEmail &&
                      partnerAEmail === partnerBEmail) ||
                    (partnerAEmail &&
                      allEmails &&
                      allEmails.filter((e) => e === partnerAEmail).length > 1)
                      ? true
                      : false
                  }
                  helperText={
                    (partnerAEmail &&
                      partnerBEmail &&
                      partnerAEmail === partnerBEmail) ||
                    (partnerAEmail &&
                      allEmails &&
                      allEmails.filter((e) => e === partnerAEmail).length > 1)
                      ? 'This email is already used!'
                      : undefined
                  }
                />
              </div>
            )}
          </div>
          <div className='flex flex-col md:flex-row w-full mt-6'>
            <div className='w-full mr-0 md:mr-4'>
              <h3 className='text-sm'>
                {' '}
                {loggedUserRole === 'couple'
                  ? "Your Partner's Full Name"
                  : "Partner B's full name"}
              </h3>
              <CustomTextInput
                className='w-full ml-0'
                value={partnerBName}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                  setPartnerBName(ev.target.value);
                }}
                type={'text'}
              />
            </div>
            <div className='w-full md:min-w-3xs'>
              <h3 className='text-sm'>Their Role</h3>
              <CustomDropdown
                className='w-full ml-0'
                value={partnerBRole?.label}
                onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
                  setPartnerBRole(
                    dropdownOptions.find((f) => f.value === ev.target.value)
                  );
                }}
                options={dropdownOptions}
              />
            </div>
            {loggedUserRole === 'wedding_planner' && (
              <div className='w-full mr-0 md:ml-4'>
                <h3 className='text-sm'>{'Their email'}</h3>
                <CustomTextInput
                  className='w-full ml-0'
                  value={partnerBEmail}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    setPartnerBEmail(ev.target.value);
                  }}
                  type={'email'}
                  error={
                    (partnerAEmail &&
                      partnerBEmail &&
                      partnerAEmail === partnerBEmail) ||
                    (partnerBEmail &&
                      allEmails &&
                      allEmails.filter((e) => e === partnerBEmail).length > 1)
                      ? true
                      : false
                  }
                  helperText={
                    (partnerAEmail &&
                      partnerBEmail &&
                      partnerAEmail === partnerBEmail) ||
                    (partnerBEmail &&
                      allEmails &&
                      allEmails.filter((e) => e === partnerBEmail).length > 1)
                      ? 'This email is already used!'
                      : undefined
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className='mt-12 flex flex-row justify-center items-center'>
          <Button
            className='text-forest-primary bg-white mr-4'
            title='BACK'
            onClick={onBackClick}
          />
          <Button
            className='bg-forest-primary text-white'
            title={
              loggedUserRole !== MemberType.weddingPlanner ? 'COMPLETE' : 'NEXT'
            }
            disabled={
              !partnerAName ||
              !partnerBName ||
              !partnerARole ||
              !partnerBRole ||
              !areEmailsValid()
            }
            onClick={() =>
              onCompleteClick(
                partnerAName,
                partnerAEmail,
                getMemberType(partnerARole?.value.toLowerCase() || ''),
                partnerBName,
                partnerBEmail,
                getMemberType(partnerBRole?.value.toLowerCase() || '')
              )
            }
          />
        </div>
      </div>
    </Modal>
  );
};
