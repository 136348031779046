import { Typography } from '../v2/Typography/Typography';

type VowBuilderLockedItemProps = {
  borderSvgSource: string;
  iconSvgSource: string;
  text: string;
  shiftDown?: boolean;
  textColor?: string;
};

const LanderItem = (props: VowBuilderLockedItemProps) => {
  const { borderSvgSource, iconSvgSource, text, shiftDown, textColor } = props;
  return (
    <div
      className={`${
        shiftDown ? 'mt-10' : 'mb-10'
      } min-w-[250px] min-h-[250px] flex flex-col items-center justify-center`}
    >
      <img
        src={borderSvgSource}
        className='absolute max-w-[250px] max-h-250px'
      />
      <img className='relative max-w-[100px]' src={iconSvgSource} />
      <Typography
        className={`relative mt-6 max-w-[215px] text-center ${
          textColor ? textColor : ''
        }`}
        type='display-50'
      >
        {text}
      </Typography>
    </div>
  );
};

export default LanderItem;
