import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import bouquetSvg from '../../assets/images/image/bouquet.svg';
import flowerSvg from '../../assets/images/image/flower.svg';
import Switcher from '../../components/v2/Switcher/Switcher';
import useSubscriptionPlans from '../../hooks/useSubscriptionPlans';
import { usePlanner } from '../../provider/plannerProvider';

import PayPlanCard from './PayPlanCard';

export type PaymentModalSubscriptionContentProps = {
  placeOrder: (planId: number, price: number) => Promise<void>;
};

const PaymentModalSubscriptionContent = (
  props: PaymentModalSubscriptionContentProps
) => {
  const [subscriptionType, setSubscriptionType] = useState<
    'monthly' | 'yearly'
  >('yearly');
  const { placeOrder } = props;

  const { monthlySubscriptionPlans, yearlySubscriptionPlans } =
    useSubscriptionPlans();

  const switcherOnChange = (isChecked: boolean) => {
    if (isChecked) {
      setSubscriptionType('yearly');
    } else {
      setSubscriptionType('monthly');
    }
  };

  const featuresDescription =
    "Full access to Provenance's tools for crafting personalized ceremonies, vows, and toasts:";

  return (
    <>
      <Switcher
        onChange={switcherOnChange}
        text='Switch to Yearly & Save 20%'
        labelLeft='Monthly'
        labelRight='Yearly'
        className='w-[720px] max-w-[95vw] mb-8 px-3 border border-solid border-gray-500 rounded-lg'
      />
      {/* subscription plans */}
      {subscriptionType === 'monthly' && (
        <div className='flex flex-col md:flex-row justify-center gap-6'>
          <PayPlanCard
            planName='First Wedding'
            price='Free'
            imgSrc={flowerSvg}
            featuresDescription={featuresDescription}
            features={[
              {
                title: '1 Client',
                elevioBenefit: '',
              },
              {
                title: 'Ceremony Builder',
                elevioBenefit: 'ceremony-benefit',
              },
              {
                title: 'Vow Builder',
                elevioBenefit: 'vow-benefit',
              },
              {
                title: 'Toast Builder',
                elevioBenefit: 'toast-benefit',
              },
            ]}
            currentPlan={true}
          />
          {monthlySubscriptionPlans?.map((plan) => (
            <PayPlanCard
              key={plan.id}
              planName={plan.name}
              billingDetails='per month, billed monthly'
              price='$49.99'
              fullPrice=''
              imgSrc={bouquetSvg}
              featuresDescription={featuresDescription}
              features={[
                {
                  title: 'Unlimited Clients',
                  elevioBenefit: '',
                },
                {
                  title: 'Ceremony Builder',
                  elevioBenefit: 'ceremony-benefit',
                },
                {
                  title: 'Vow Builder',
                  elevioBenefit: 'vow-benefit',
                },
                {
                  title: 'Toast Builder',
                  elevioBenefit: 'toast-benefit',
                },
                {
                  title: '24/7 Support from Provenance Team',
                  elevioBenefit: '',
                },
              ]}
              mostPopular={true}
              onSelectClick={() => void placeOrder(plan.id, plan.price)}
            />
          ))}
        </div>
      )}
      {subscriptionType === 'yearly' && (
        <div>
          <div className='flex flex-col md:flex-row justify-center gap-6'>
            <PayPlanCard
              planName='First Wedding'
              price='Free'
              imgSrc={flowerSvg}
              featuresDescription={featuresDescription}
              features={[
                {
                  title: '1 Client',
                  elevioBenefit: '',
                },
                {
                  title: 'Ceremony Builder',
                  elevioBenefit: 'ceremony-benefit',
                },
                {
                  title: 'Vow Builder',
                  elevioBenefit: 'vow-benefit',
                },
                {
                  title: 'Toast Builder',
                  elevioBenefit: 'toast-benefit',
                },
              ]}
              currentPlan={true}
            />
            {yearlySubscriptionPlans?.map((plan, i) => (
              <PayPlanCard
                key={i}
                planName={plan.name}
                price='$39.99'
                fullPrice='$49.99'
                billingDetails='per month, billed annually'
                imgSrc={bouquetSvg}
                featuresDescription={featuresDescription}
                features={[
                  {
                    title: 'Unlimited Clients',
                    elevioBenefit: '',
                  },
                  {
                    title: 'Ceremony Builder',
                    elevioBenefit: 'ceremony-benefit',
                  },
                  {
                    title: 'Vow Builder',
                    elevioBenefit: 'vow-benefit',
                  },
                  {
                    title: 'Toast Builder',
                    elevioBenefit: 'toast-benefit',
                  },
                  {
                    title: '24/7 Support from Provenance Team',
                    elevioBenefit: '',
                  },
                ]}
                mostPopular={true}
                onSelectClick={() => void placeOrder(plan.id, plan.price)}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentModalSubscriptionContent;
