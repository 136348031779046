import { Ceremony } from '../../../api/ceremony';
import { redeemGiftcard } from '../../../api/giftcards';
import Button from '../../../components/v2/Button/Button';
import { Modal } from '../../../components/v2/Modal/Modal';
import { Typography } from '../../../components/v2/Typography/Typography';
import { getCeremonyTitle } from '../../../helpers/ceremonyHelper';
import { useDashboard } from '../../../provider/dashboardProvider';
import { useAccessibleModule } from '../../../provider/moduleAccessProvider';
import { useSnackbar } from '../../../provider/snackbarProvider';

import giftcardPng from './images/giftcard.png';

const RedeemGiftcardModal = () => {
  const {
    ceremony,
    couple1,
    couple2,
    giftcard,
    showRedeeemGiftcardModal,
    setShowRedeeemGiftcardModal,
    fetchGiftcard,
  } = useDashboard();

  const { fetchAccessibleModulesIdentifiers } = useAccessibleModule();

  const { openSnackBar } = useSnackbar();

  const handleRedeemClick = async () => {
    try {
      if (ceremony && giftcard && giftcard.id) {
        const result = await redeemGiftcard(giftcard.id, ceremony.id);
        if (result.success) {
          openSnackBar('Giftcard redeemed successfully');
          void fetchGiftcard();
          void fetchAccessibleModulesIdentifiers(ceremony.id.toString());
        }
      }
    } catch (error) {
      openSnackBar('Something went wrong', 'error');
    }
    setShowRedeeemGiftcardModal(false);
  };

  return (
    <Modal
      isOpen={showRedeeemGiftcardModal}
      onClose={() => {
        setShowRedeeemGiftcardModal(false);
      }}
    >
      <div className='flex flex-col justify-start items-start'>
        <img src={giftcardPng} alt='giftcard' />
        <Typography type='display-100' className='mt-6'>
          Redeem Gift Card
        </Typography>
        {couple1 && couple2 && (
          <Typography className='mt-4 w-[436px]' type='body-400'>
            {`You are about to redeem a gift card and unlock all the tools for ${getCeremonyTitle(
              couple1,
              couple2
            )}.`}
          </Typography>
        )}

        <div className='mt-8 w-full flex flex-row justify-between'>
          <Button variant='text'>Cancel</Button>
          <Button onClick={() => handleRedeemClick()}>Confirm</Button>
        </div>
      </div>
    </Modal>
  );
};

export default RedeemGiftcardModal;
