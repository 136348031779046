import { useEffect, useState } from 'react';

import { User } from '../../api/auth';
import { Member, MemberType } from '../../api/ceremony';
import Button from '../../components/button';
import CustomTextInput from '../../components/forms/CustomTextInput';
import { Modal } from '../../components/v2/Modal/Modal';
import AddOfficiantButton from '../Ceremony/BasicInfo/AddOfficiantButton';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onBackClick: () => void;
  onNextClick: () => void | Promise<void>;
  setOfficiants: (members: Member[]) => void;
  officiants: Member[];
  title: string;
  isSubmit?: boolean;
  allEmails?: string[];
};

export const EventAddOfficiantModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    onBackClick,
    onNextClick,
    setOfficiants,
    officiants,
    title,
    isSubmit = false,
    allEmails,
  } = props;

  const [numberOfFields, setNumberOfFields] = useState<number>(1);

  useEffect(() => {
    if (officiants.length > 0) {
      setNumberOfFields(officiants.length);
    }
  }, [officiants]);

  const setMemberName = (index: number, name: string) => {
    const newOfficiants = [...officiants];
    if (!newOfficiants[index]) newOfficiants.push({} as Member);
    newOfficiants[index].legal_name = name;
    newOfficiants[index].member_type = MemberType.officiant;
    setOfficiants(newOfficiants);
  };

  const setMemberEmail = (index: number, email: string) => {
    const newOfficiants = [...officiants];
    if (!newOfficiants[index]) newOfficiants.push({} as Member);
    newOfficiants[index].email = email;
    newOfficiants[index].member_type = MemberType.officiant;
    setOfficiants(newOfficiants);
  };

  const areEmailsValid = () => {
    let result = true;
    officiants.forEach((officiant) => {
      if (
        officiant.email &&
        allEmails &&
        allEmails.filter((e) => e === officiant.email).length > 1
      ) {
        result = false;
      }
    });
    return result;
  };

  const createFields = () => {
    const fields = [];
    for (let i = 0; i < numberOfFields; i++) {
      fields.push(
        <div className='flex flex-row' key={i}>
          <div className='flex-auto mr-8'>
            <h3 className='text-sm pl-2'>{'Full name'}</h3>
            <CustomTextInput
              onChange={(e: { target: { value: string } }) =>
                setMemberName(i, e.target.value)
              }
              value={officiants[i]?.legal_name || ''}
            />
          </div>
          <div className='flex-auto'>
            <h3 className='text-sm pl-2'>{'Email'}</h3>
            <CustomTextInput
              onChange={(e: { target: { value: string } }) =>
                setMemberEmail(i, e.target.value)
              }
              value={officiants[i]?.email || ''}
              error={
                officiants[i] &&
                officiants[i].email &&
                allEmails &&
                allEmails.filter((e) => e === officiants[i]?.email).length > 1
                  ? true
                  : false
              }
              helperText={
                officiants[i] &&
                officiants[i].email &&
                allEmails &&
                allEmails.filter((e) => e === officiants[i]?.email).length > 1
                  ? 'This email is already used!'
                  : ''
              }
            />
          </div>
        </div>
      );
    }

    return fields;
  };

  const onSkipClick = () => {
    setOfficiants([]);
    setNumberOfFields(1);
    void onNextClick();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='md:min-w-sm'>
        <>
          <h3 className='font-nanum text-2xl'>{title}</h3>
      
          {createFields()}
          <div className='w-full flex flex-row items-end justify-end'>
            <div className='hidden md:block min-w-3xs' />
            <AddOfficiantButton
              onClick={() => setNumberOfFields(numberOfFields + 1)}
            />
          </div>
          <div className='mt-12 flex flex-row justify-center items-center'>
            {!isSubmit && (
              <Button
                className='text-forest-primary bg-white mr-4'
                title='BACK'
                onClick={onBackClick}
              />
            )}
            <Button
              className='bg-forest-primary text-white'
              title={isSubmit ? 'SUBMIT' : 'NEXT'}
              onClick={onNextClick}
              disabled={!areEmailsValid()}
            />
          </div>
          {!isSubmit && (
            <div
              className='font-inter text-forest-500 flex-1 min-w-full text-center pt-4 cursor-pointer'
              onClick={onSkipClick}
            >
              Skip for now
            </div>
          )}
        </>
      </div>
    </Modal>
  );
};
