import { CheckCircleOutline } from '@mui/icons-material';
import { ReactElement } from 'react';

type ButtonPillProps = {
  text: string | ReactElement;
  selected?: boolean;
  className?: string;
  onClick?: () => void;
  icon?: string;
  size?: 'md' | 'sm' | 'xs'; 
  disabled?: boolean;
  fullWidth?: boolean;
  hideCheckIcon?: boolean;
};

const ButtonPill = (props: ButtonPillProps) => {
  const {
    icon,
    text,
    className,
    selected = false,
    onClick,
    size = 'md',
    disabled,
    fullWidth,
    hideCheckIcon = false,
  } = props;

  const getClasses = () => {
    //common classes
    let buttonClasses =
      'gap-2 flex flex-row text-center rounded-full border mr-2 capitalize ';

    // props dependant classes
    size == 'md' ? buttonClasses += ' py-3 px-6' : null;
    size == 'sm' ? (buttonClasses += ' py-2 px-6'): null;
    size == 'xs' ? (buttonClasses += ' py-2 px-4'): null;
    fullWidth ? (buttonClasses += ' w-full') : (buttonClasses += ' w-auto');

    disabled
      ? (buttonClasses += ' cursor-auto border-0 bg-neutral-300 text-gray-500')
      : (buttonClasses += ' cursor-pointer');

    selected
      ? (buttonClasses +=
          ' border-copper-primary border-[2px] text-copper-primary')
      : (buttonClasses += ' border-forest-500 text-forest-500');

    className ? (buttonClasses += ' ' + className) : null;

    return buttonClasses;
  };

  return (
    <button onClick={onClick} className={getClasses()} disabled={disabled}>
      {icon && (
        <img
          className='flex-1 max-w-[24px] max-h-[24px] -mr-[24px]'
          src={icon}
          alt='icon'
        />
      )}
      <span className='flex-1'>{text}</span>
      {selected && !hideCheckIcon && <CheckCircleOutline className='w-6 h-6' />}
    </button>
  );
};

export default ButtonPill;
