import classNames from 'classnames';

type SeparatorProps = {
  variant?: 'horizontal' | 'vertical';
  className?: string;
};

export const Separator = (props: SeparatorProps) => {
  const { variant = 'horizontal', className } = props;
  return (
    <div
      className={classNames('border', className, {
        'border-x-0 border-b-0': variant === 'horizontal',
        'border-y-0 border-r-0': variant === 'vertical',
      })}
    />
  );
};
