import Button from '../../../../components/v2/Button/Button';
import { CouplesNotes } from '../../../../components/v2/CouplesNotes/CouplesNotes';

export type ModuleSummaryProps = {
  moduleName: string;
  moduleDescription: React.ReactNode;
  numberOfQuestions?: number;
  estimatedTime?: number;
  showAsteriskMeaning?: boolean;
  couplesNote?: string;
  extraButton?: React.ReactNode;
};

export const ModuleSummary = ({
  moduleName,
  moduleDescription,
  numberOfQuestions = 0,
  estimatedTime = 0,
  couplesNote = '',
  extraButton,
}: ModuleSummaryProps) => {
  return (
    <div className='flex flex-col gap-6'>
      <div
        className={
          extraButton ? 'flex flex-row justify-between items-start' : ''
        }
      >
        <h2 className='font-recife text-[40px] leading-[48px] text-forest-500 font-[420]'>
          {moduleName}
        </h2>
        {extraButton}
      </div>
      <div className='flex flex-col gap-2'>
        <h3 className='font-grotesk text-sm text-forest-500 font-bold uppercase'>
          About
        </h3>
        <div className='font-grotesk text-lg text-forest-500'>
          {moduleDescription}
        </div>
      </div>
      {/* {showAsteriskMeaning && (
        <div className='flex flex-col gap-2'>
          <div className='font-grotesk text-sm text-forest-500'>
            Questions marked with a <span style={{ color: '#995D30' }}>*</span>{' '}
            are required in order for us to generate your vows.
          </div>
        </div>
      )} */}
      {couplesNote && <CouplesNotes text={couplesNote} />}
      <div className='flex flex-row gap-6 items-center'>
        {numberOfQuestions ? (
          <>
            <div className='flex flex-col gap-1'>
              <span className='font-recife text-2xl text-forest-500'>
                {numberOfQuestions}
              </span>
              <p className='font-grotesk text-sm text-forest-300 font-medium'>
                Number of questions
              </p>
            </div>
            <div className='h-10 w-px bg-forest-100'></div>
          </>
        ) : (
          ''
        )}
        <div className='flex flex-col gap-1'>
          <span className='font-recife text-2xl text-forest-500'>
            {estimatedTime} mins
          </span>
          <p className='font-grotesk text-sm text-forest-300 font-medium'>
            Est. time to completion
          </p>
        </div>
      </div>
    </div>
  );
};
