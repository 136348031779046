import saved from '../../assets/images/icons/Check.svg';
import saving from '../../assets/images/icons/PendingSave.svg';

type QuestionSaveStatusProps = {
  onClick?: (() => void) | (() => Promise<void>);
  title?: string;
  disabled?: boolean;
  className?: string;
};

const QuestionSaveStatus = ({
  onClick = () => undefined,
  title = '',
  disabled = false,
  ...props
}: QuestionSaveStatusProps) => {
  return (
    <div
      onClick={() => {
        if (!disabled) {
          void onClick();
        }
      }}
    >
      {title === 'Saving' ? (
        <div className='flex flex-row mx-2 mt-5 cursor-wait'>
          <img src={saving} alt={'fb'} className={'mr-2 max-w-[22px]'} />
          <h1 className='uppercase font-inter'>Saving...</h1>
        </div>
      ) : title === 'Save Changes' ? (
        <div className='flex flex-row mx-2 mt-5 cursor-pointer'>
          <img src={saved} alt={'fb'} className={'mr-2 max-w-[22px]'} />
          <h1 className='uppercase font-inter'>Save Changes</h1>
        </div>
      ) : (
        <div className='flex flex-row mx-2 mt-5 cursor-pointer'>
          <img src={saved} alt={'fb'} className={'mr-2 max-w-[22px]'} />
          <h1 className='uppercase font-inter'>saved</h1>
        </div>
      )}
    </div>
  );
};

export default QuestionSaveStatus;
