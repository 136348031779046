import { _request } from './request';

export type Declaration = {
  id: number;
  name: string;
  text: string;
  question_id?: number;
};

export type GetDeclarationsResponse = {
  success: boolean;
  message: string;
  data: Declaration[];
};

export const getDeclarations = async () => {
  const responseBody = await _request<GetDeclarationsResponse>({
    url: `declarations`,
    method: 'GET',
  });
  return responseBody;
};
