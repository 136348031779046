/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { set } from 'dot-object';
import React from 'react';

import { MemberType } from '../../../api/ceremony';
import { useDashboard } from '../../../provider/dashboardProvider';
import { Typography } from '../Typography/Typography';

import { ELEVIO_ARTICLES , ARTICLE_TYPES } from './data/elevioArticles';
import EyeSvg from './images/eye.svg';

type PreviewEmailProps = {
  text?: string;
  elevioId?: string | number;
  type?: ARTICLE_TYPES;
  inline?: boolean;
};

const PreviewEmail = ({
  text = 'Preview Email',
  elevioId,
  type,
  inline = false,
}: PreviewEmailProps) => {
  const { currentUser } = useDashboard();

  const [prefferedElevioId, setPrefferedElevioId] = React.useState<
    string | number | undefined
  >(undefined);

  //listen for elevio assistant close event
  React.useEffect(() => {
    return () => {
      if (window._elev) {
        window._elev.on('popup:closed', () => {
          document.body.classList.add('remove-elevio');
        });
      }
    };
  }, []);

  React.useEffect(() => {
    setPrefferedElevioId(getElevioId());
  }, [currentUser]);

  const getElevioId = () => {
    if (elevioId) {
      return elevioId;
    }

    if (!type) {
      return elevioId;
    }

    if (!currentUser) {
      return ELEVIO_ARTICLES[type].default;
    }

    const existingMemberTypes = Object.keys(ELEVIO_ARTICLES[type]);

    if (existingMemberTypes.includes(currentUser.member_type)) {
      return ELEVIO_ARTICLES[type][
        currentUser.member_type as MemberType & ARTICLE_TYPES
      ];
    }

    return ELEVIO_ARTICLES[type].default;
  };

  const handleShowElevioArticle = (elevioId: string | number) => {
    if (window._elev) {
      const target = document.getElementsByTagName('body')[0];

      const popup: any = window._elev.popupComponent(target, {
        articleId: elevioId.toString(),
      });
      popup.insert();
      window._elev.openPopup(elevioId.toString());
    }

    document.body.classList.remove('remove-elevio');
  };

  return (
    <>
      {prefferedElevioId && (
        <div
          className={`flex items-center cursor-pointer gap-2 ${inline ? 'inline' : 'mt-6'}`}
          onClick={() => {
            if (prefferedElevioId) handleShowElevioArticle(prefferedElevioId);
          }}
        >
          <img alt='preview-email' src={EyeSvg} />
          <Typography type='body-200' variant='functional-low' className='border-b border-dashed border-forest-500'>
            {text}
          </Typography>
          <span className='elevio-article-preview' />
        </div>
      )}
    </>
  );
};

export default PreviewEmail;
