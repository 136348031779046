import { Typography } from '../../../components/v2/Typography/Typography';
import { useReadings } from '../../../provider/readingsProvider';

import FiltersRow from './FiltersRow';

type FiltersMainProps = {
  setSelectedFilter: (
    value: 'cultures' | 'ritualTypes' | 'tags' | 'sortby'
  ) => void;
};
const FiltersMain = (props: FiltersMainProps) => {
  const { setSelectedFilter } = props;
  const {
    cultures,
    cultureValues,
    ritualTypes,
    ritualTypeValues,
    tags,
    tagValues,
    currentSort,
  } = useReadings();
  return (
    <>
      <Typography type='display-100' className='px-8 pb-4'>
        Sort & Filter
      </Typography>
      <hr className='block w-full border-t border-forest-100' />
      <div className='flex flex-col px-8'>
        <FiltersRow
          title='Sort By'
          onClick={() => setSelectedFilter('sortby')}
          selected={currentSort}
        />
        <FiltersRow
          title='Affiliation'
          onClick={() => setSelectedFilter('cultures')}
          selected={cultureValues.map(
            (cv) => cultures.find((c) => c.id.toString() === cv)?.name || ''
          )}
        />
        <FiltersRow
          title='Ceremony Placement'
          onClick={() => setSelectedFilter('ritualTypes')}
          selected={ritualTypeValues.map(
            (cv) => ritualTypes.find((c) => c.id.toString() === cv)?.name || ''
          )}
        />
        <FiltersRow
          title='Method'
          onClick={() => setSelectedFilter('tags')}
          selected={tagValues.map(
            (cv) => tags.find((c) => c.id.toString() === cv)?.name || ''
          )}
        />
      </div>
    </>
  );
};

export default FiltersMain;
