import { FieldProps } from 'formik';

import CustomRadioSelect from '../../../components/forms/CustomRadioSelect';

interface CustomRadioProps {
  options: [];
  simpleRadio?: boolean;
  setChanged: (ev: any) => void;
  className?: string;
  error?: boolean;
  helperText: string;
  readOnly: boolean;
  singleRow: boolean;
}

export const CustomFormikRadioButton = ({
  field, // { name, value, onChange, onBlur }
  form,
  className,
  setChanged,
  error,
  helperText,
  options,
  readOnly= false,
  simpleRadio = false,
  singleRow = false,
  ...props
}: // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
CustomRadioProps & FieldProps<string>) => {
  return (
    <CustomRadioSelect
      className={className}
      value={field.value}
      name={field.name}
      onChange={(ev: any) => {
        field.onChange(ev);
        if (setChanged) {
          
          setChanged(ev);
        }
      }}
      onBlur={(ev: any) => {
        field.onChange(ev);
        if (setChanged) {
          setChanged(ev);
        }
      }}

      simpleRadio = {simpleRadio}
      readOnly={readOnly}
      options={options}
      required={true}
      error={error}
      helperText={helperText}
      singleRow={singleRow}
      {...props}
    />
  );
};