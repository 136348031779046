import inviteCollaboratorsSvg from '../../assets/images/icons/invite-collaborators.svg';
import Button from '../v2/Button/Button';
import { Typography } from '../v2/Typography/Typography';

type InviteCollaboratorProps = {
  text: string;
  onClick: () => void;
  expanded: boolean;
};
export const InviteCollaborator = (props: InviteCollaboratorProps) => {
  const { text, onClick, expanded } = props;
  return (
    <div className='mb-2 flex flex-row cursor-pointer' onClick={onClick}>
      <div className='w-6 h-6 flex justify-center items-center border border-dashed rounded-xl border-forest-primary'>
        <img src={inviteCollaboratorsSvg} />
      </div>
      {expanded && (
        <Button className='ml-4' size='100' variant='text'>
          {text}
        </Button>
      )}
    </div>
  );
};
