const AddOfficiantButton = (props: { onClick: () => void, text?:string }) => {
  const { onClick, text } = props;
  return (
    
      <div onClick={onClick} className={`flex flex-row  cursor-pointer items-center text-base text-red-output`}>
        <div
          className={`bg-cosmicLatte-primary text-red-output add-to-speech-button flex justify-center items-center mr-2`}
        >
          +
        </div>
        <div className='font-bold font-inter text-sm uppercase'>
          {text || 'ADD ANOTHER OFFICIANT'}
          </div>
      </div>
  );
};
export default AddOfficiantButton;
