/* eslint-disable -- TODO: fix eslint errors */
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {
  CultureChip,
  MethodChip,
  PlacementChip,
} from '../../pages/Modules/common/chips/Chip';
import { FormHelperText, MenuList, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { Modal } from '../modal/Modal';
import CustomTextInput from './CustomTextInput';
import Button from '../button';
import { height } from '@mui/system';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type Props = {
  options: { label: string; value: string }[];
  values: string[];
  handleItemClick: (value: string) => void;
  handleClick?: () => void;
  handleAllClick?: () => void;
  handleAddNew?: (name: string) => Promise<any>;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  name?: string;
  className?: string;
  noSort?: boolean;
  readOnly?: boolean;
};

export const CustomMenuList = (props: Props) => {
  const {
    options,
    values,
    handleItemClick,
    name,
    className,
    error,
    helperText,
    noSort = false,
    readOnly,
    handleAddNew,
    handleAllClick,
  } = props;

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newOptionName, setNewOptionName] = useState('');

  const sortFunction = (a: any, b: any) => {
    if (a.label == 'Other') return 1;
    if (b.label == 'Other') return -1;
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  };

  return (
    <div>
      <Modal
        isOpen={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        title={'Add a new culture/religion?'}
      >
        <>
          <CustomTextInput
            className='w-full'
            value={newOptionName}
            readOnly={readOnly}
            placeholder={
              'What is the name of the culture/religion you want to add?'
            }
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              setNewOptionName(ev.target.value);
            }}
            type={'text'}
          />
          <div className='w-full ml-2'>
            <Button
              title={'Save'}
              className={`ml-auto mt-4 text-forest-primary bg-white`}
              onClick={() => {
                setAddModalOpen(false);
                if (handleAddNew && newOptionName) {
                  handleAddNew(newOptionName);
                }
              }}
            ></Button>
          </div>
        </>
      </Modal>
      <div>
        <MenuList
          sx={{
            maxWidth: '100%',
            height: '64vh',
            overflow: 'auto',
            borderRadius: 0,
          }}
        >
          {handleAddNew !== undefined && (
            <MenuItem key={`add-new`}>
              <div
                className='w-full pl-4'
                onClick={() => setAddModalOpen(true)}
              >
                Add Your Own Culture/Religion
              </div>
            </MenuItem>
          )}
          {handleAllClick && (
            <>
              <MenuItem value={'all'} onClick={handleAllClick}>
                <Checkbox checked={values.length === 0} />
                <div className='text-sm'>All</div>
              </MenuItem>
              <hr />
            </>
          )}

          {!noSort
            ? options
                .sort((a: any, b: any) => sortFunction(a, b))
                .map((option) => (
                  <MenuItem
                    key={`${option.label}-${option.value}`}
                    value={option.value}
                    onClick={() => handleItemClick(option.value)}
                  >
                    <Checkbox checked={values.indexOf(option.value) > -1} />
                    <div className='text-sm'>{option.label}</div>
                  </MenuItem>
                ))
            : options.map((option) => (
                <MenuItem
                  key={`${option.label}-${option.value}`}
                  value={option.value}
                  onClick={() => handleItemClick(option.value)}
                >
                  <Checkbox checked={values.indexOf(option.value) > -1} />
                  <div className='text-sm'>{option.label}</div>
                </MenuItem>
              ))}
        </MenuList>
      </div>
      {helperText && (
        <FormHelperText className='text-red-error ml-6'>
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
};
