import envelopeSvg from '../../../assets/images/svg/envelope-amber.svg';
import { Modal } from '../Modal/Modal';
import { Typography } from '../Typography/Typography';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    note: string;
    name: string;
};

export const NoteModal = (props: ModalProps) => {
    const { isOpen, onClose, note, name } = props;

    const getContent = () => {
       return <>
            <Typography type='display-400'>
                <> Your note to {name} </>
            </Typography>
            <div className='mt-4 p-4 rounded-xl bg-marigold-100 text-marigold-700'>
                <Typography type='body-600' variant='functional-high'>
                    <div className='flex items-center mb-4'>
                        <img
                        className='inline-block mr-2'
                        width={24}
                        src={envelopeSvg}
                        />
                        Note
                    </div>
                </Typography>
                <Typography type='body-600' variant='functional-low'>
                    {note}
                </Typography>
            </div>
        </>
    }
    
return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={true}
      title={''}
      maxWidth={'sm'}
    >
      {getContent()}
    </Modal>
  );
};