import checkmarkGray from '../../../assets/images/icons/checkmark-gray.png';
import checkmarkGreen from '../../../assets/images/icons/checkmark-green.png';

type RequirementProps = {
  text: string;
  done: boolean;
};

const Requirement = (props: RequirementProps) => {
  const { text, done } = props;

  return (
    <div className='flex flex-row text-sm text-forest-500'>
      <img className='flex-1 max-w-[11px] max-h-[8px] mt-[6px] mr-2' src={done ? checkmarkGreen : checkmarkGray} /> 
      <div className='flex-1'>{text}</div>
    </div>
  );
};

export default Requirement;
