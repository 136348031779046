import 'react-responsive-carousel/lib/styles/carousel.min.css';

import CheckMark from '../../assets/images/icons/Checkmark.svg';
import InfoCircle from '../../assets/images/icons/InfoCircle.svg';
import CustomTextInput from '../../components/forms/CustomTextInput';

import './index.scss';

import { Title } from '../../components/titles';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Container from '../../components/Container';
import Button from '../../components/button';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useState } from 'react';

import { PayOption } from '../../api/paywall';

type payeeDetailType = {
  name: string;
  email: string;
};
interface LocationState {
  plan: PayOption;
  payee: string;
  payeeDetail: payeeDetailType;
  success: boolean;
}
const PayStatus = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { plan, payee, payeeDetail, success } =
    (location.state as LocationState) || {};

  return (
    <div className='bg-containerbg min-h-4/5 flex flex-col space-between items-center justify-center overflow-x-hidden pb-10'>
      <Title
        className='text-copper-primary font-sans uppercase mt-8'
        h={2}
        text='Ceremony Builder'
      />
      <Title
        className={`${
          success ? 'text-forest-primary' : 'text-red-500'
        } font-nanum font-bold`}
        h={1}
        text={
          success
            ? payee === 'me'
              ? 'Payment Completed'
              : 'Payment link sent'
            : payee === 'me'
            ? 'Payment could not be processed'
            : 'Payment link could not be sent to ' +
              (payee === 'couple'
                ? 'Couples'
                : payee === 'officiant'
                ? 'Officiant'
                : payeeDetail.name)
        }
      />
      <Container className='content-center place-content-center mt-4 auto-rows-fr'>
        <div className='hidden md:block  md:col-span-1 lg:col-span-3'></div>
        <div className='col-span-4 md:col-span-4 lg:col-span-6'>
          <div className='border border-forest-100 p-6 bg-white flex flex-col items-center'>
            <div className='grid grid-cols-12 gap-x-10'>
              <div className='col-span-1'></div>
              {success ? (
                <div className='col-span-10 flex flex-col font-inter text-forest-primary text-center py-2'>
                  {payee !== 'me' && (
                    <span>
                      The payment link has been sent to{' '}
                      {payee === 'else'
                        ? `${payeeDetail.name} (${payeeDetail.email})`
                        : payee === 'couple'
                        ? 'Couples'
                        : 'Officiant'}
                      , once they complete the payment you will gain access to
                      all of the modules.
                    </span>
                  )}
                  {payee !== 'me' && (
                    <span className='mt-5'>
                      If for any reason you don’t want to wait for{' '}
                      {payee === 'else'
                        ? `${payeeDetail.name}`
                        : payee === 'couple'
                        ? 'Couples'
                        : 'Officiant'}
                      {payeeDetail.name}, you can pay for the service yourself
                      using the button below.
                    </span>
                  )}
                  {payee === 'me' && (
                    <span className=''>
                      All the invited collaborators will be able to use the
                      selected plan
                    </span>
                  )}
                </div>
              ) : (
                <div className='col-span-10 flex flex-col font-inter text-forest-primary text-center py-2'>
                  {payee !== 'me' && (
                    <span>
                      The payment link could not be sent to{' '}
                      {payee === 'else'
                        ? `${payeeDetail.name} (${payeeDetail.email})`
                        : payee === 'couple'
                        ? 'Couples'
                        : 'Officiant'}
                      , they need complete the payment in order to access to all
                      of the modules.
                    </span>
                  )}
                  {payee !== 'me' && (
                    <span className='mt-5'>
                      If for any reason you don’t want to wait for{' '}
                      {payee === 'else'
                        ? `${payeeDetail.name}`
                        : payee === 'couple'
                        ? 'Couples'
                        : 'Officiant'}
                      {payeeDetail.name}, you can pay for the service yourself
                      using the button below.
                    </span>
                  )}
                  {payee === 'me' && (
                    <span className=''>
                      Payment could not be processed. All the ceremony members
                      will be able to take advantages of the plan opted for only
                      after is payment is done.
                    </span>
                  )}
                </div>
              )}
            </div>
            {(payee !== 'me' || !success) && (
              <Button
                title={'Pay now'}
                onClick={() => {
                  navigate(`/pay-details/${params.ceremonyid ?? ''}`);
                }}
                className={`mt-6 px-2 md:px-3 lg:px-4 xl:px-6 2xl:px-8 
            py-2 md:py-2 lg:py-3 xl:py-3 2xl:py-4 
            min-w-b1 md:min-w-b1 lg:min-w-b2 xl:min-w-b3 2xl:min-w-b4
            uppercase text-base flex justify-center cursor-pointer mx-3 text-white bg-forest-primary`}
              />
            )}
          </div>
        </div>
      </Container>
      <Button
        title={'Go to dashboard'}
        onClick={() => {
          navigate(`/ceremony/${params.ceremonyid ?? ''}`);
        }}
        className={`mt-6 px-2 md:px-3 lg:px-4 xl:px-6 2xl:px-8 
            py-2 md:py-2 lg:py-3 xl:py-3 2xl:py-4 
            min-w-b1 md:min-w-b1 lg:min-w-b2 xl:min-w-b3 2xl:min-w-b4
            uppercase text-base flex justify-center cursor-pointer mx-3 border border-forest-primary bg-white text-forest-primary`}
      />
    </div>
  );
};

export default PayStatus;
