import { DeleteForeverOutlined, MoreVert } from '@mui/icons-material';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MemberType } from '../../../api/ceremony';
import { Speaker } from '../../../api/friends';
import lockIcon from '../../../assets/images/icons/lock-private.svg';
import { NewAvatar } from '../../../components';
import Button from '../../../components/v2/Button/Button';
import ButtonPill from '../../../components/v2/ButtonPill';
import { Modal } from '../../../components/v2/Modal/Modal';
import { RemoveGuestSpeakerModal } from '../CoupleOfficiantDashboard/RemoveGuestSpeakerModal';

import './PlannerItem.styles.scss';

import { useSpeakers } from '../../../hooks/useSpeakers';
import { capitalizeFirstLetter } from '../../../helpers/helper';
import { getTextDurationStringFromMinutes } from '../../../helpers/textHelper';
import { Typography } from '../../../components/v2/Typography/Typography';
import { useChecklist } from '../../../provider/checklistProvider';
import { ChecklistItemIdentifier } from '../../../helpers/checklistHelpers/identifiers';

export type PlannerItemProps = {
  title: string;
  icon?: string;
  role?: string;
  time: number;
  targetTime?: number;
  progress: number;
  allowExport: boolean;
  onSendWeddingReminder?: () => void | Promise<void>;
  onInvite?: () => void | Promise<void>;
  onExportPDF?: () => void | Promise<void>;
  showTime?: boolean;
  url?: string;
  isSpeaker?: boolean;
  email?: string;
  eventName?: string;
  onDeleteClick?: () => Promise<void> | void;
  name?: string;
  coupleName?: string;
  pendingInvitation?: boolean;
  notInvited?: boolean;
  isDeactivated?: boolean;
  isPrivate?: boolean;
  noVows?: boolean;
  speaker?: Speaker;
};

export const deactivatedButtonContent = (
  <div className='flex justify-center'>
    <img src={lockIcon} className='mr-2' alt='lock-icon' />
    Locked
  </div>
);

const privateButtonContent = (
  <div className='flex justify-center'>
    <img src={lockIcon} className='mr-2' alt='lock-icon' />
    Private
  </div>
);

const PlannerItemProgress = (props: PlannerItemProps) => {
  const { progress, pendingInvitation } = props;

  return (
    <span
      className={classNames(
        'px-2 py-1 border border-forest-400 text-xs font-medium rounded-lg select-none',
        {
          'text-orange-800 bg-marygold-400 border-0': pendingInvitation,
          'text-forest-400': progress === 0,
          'text-forest-500': progress > 0 && progress < 100,
          'text-white bg-system-green-400 border-0': progress >= 100,
        }
      )}
    >
      {pendingInvitation && 'Pending'}
      {progress == 0 && !pendingInvitation && 'Not Started'}
      {progress > 0 && progress < 100 && !pendingInvitation && 'In-progress'}
      {progress >= 100 && !pendingInvitation && 'Complete'}
    </span>
  );
};

const SpeakerPlannerItem = (props: PlannerItemProps) => {
  const {
    title,
    time,
    role,
    progress,
    onSendWeddingReminder,
    onDeleteClick,
    url,
    email,
    eventName,
    isDeactivated,
    pendingInvitation,
  } = props;

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [isRemoveGuestModalOpen, setIsRemoveGuestModalOpen] = useState(false);

  const handleNavigate = () => {
    if (url) {
      navigate(url);
    }
  };

  const getSpeakersButtons = () => {
    if (isDeactivated) {
      return <ButtonPill text={deactivatedButtonContent} disabled={true} />;
    }

    return (
      <>
        {progress !== 100 && onSendWeddingReminder && (
          // <Button
          //   variant='secondary'
          //   onClick={() => void onSendWeddingReminder()}
          // >
          //   Send Reminder
          // </Button>
          <></>
        )}

        {pendingInvitation && (
          <ButtonPill
            text='Send Reminder'
            size={'sm'}
            className='flex-1 text-center'
            onClick={onSendWeddingReminder}
          />
        )}

        {!pendingInvitation && (
          <ButtonPill
            text='Review'
            size={'sm'}
            className='flex-1 text-center'
            onClick={handleNavigate}
          />
        )}

        <IconButton
          size='small'
          disableRipple
          className='max-w-[24px] max-h-[24px]'
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <MoreVert />
        </IconButton>
        <Menu
          {...{ anchorEl, open: isMenuOpen }}
          MenuListProps={{ dense: true }}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              setIsRemoveGuestModalOpen(true);
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <DeleteForeverOutlined />
            </ListItemIcon>
            Remove guest speaker
          </MenuItem>
        </Menu>
        <RemoveGuestSpeakerModal
          isOpen={isRemoveGuestModalOpen}
          onClose={() => setIsRemoveGuestModalOpen(false)}
          {...{ fullName: title, eventName, email }}
          onDeleteClick={() => {
            setIsRemoveGuestModalOpen(false);
            if (typeof onDeleteClick === 'function') void onDeleteClick();
          }}
        />
      </>
    );
  };

  return (
    <div className='PlannerItem PlannerItem-root PlannerItem-speaker'>
      <div className='PlannerItem-header border-b border-forest-100 pb-4'>
        <div className='PlannerItem-header__avatar min-w-[0px]'>
          <div className='avatar'>
            <NewAvatar
              fullName={title?.split(' ')?.[0] || title}
              memberType={MemberType.officiant}
            />
          </div>
          <div className='flex flex-col min-w-[0px]'>
            <span className='font-recife text-base text-forest-primary font-medium text-ellipsis overflow-hidden'>
              {title}
            </span>
            <span className='font-grotesk text-sm text-forest-300 font-medium text-ellipsis overflow-hidden'>
              {email}
            </span>
          </div>
        </div>
        {/* <PlannerItemProgress {...props} /> */}
      </div>
      <div className='PlannerItem-content sm:flex-1'>
        <div className='flex flex-rows items-center'>
          <section className='PlannerItem-section flex-1 mr-1'>
            <span className='head'>{role}</span>
            <span className='body'>Role</span>
          </section>
          <section className='PlannerItem-section flex-1'>
            <span className='head'>{time} min</span>
            <span className='body'>Allotted time</span>
          </section>
        </div>
      </div>
      <div className='flex flex-col sm:flex-row gap-4 sm:gap-5 lg:gap-12 flex-1'>
        <div className='PlannerItem-footer sm:flex-1'>
          <div className='flex flex-row items-center gap-2 flex-1'>
            {getSpeakersButtons()}
          </div>
        </div>
      </div>
    </div>
  );
};

const PlannerItem = (props: PlannerItemProps) => {
  const {
    title,
    time,
    icon,
    onExportPDF,
    showTime = true,
    url,
    isSpeaker,
    name,
    coupleName,
    pendingInvitation,
    isDeactivated,
    isPrivate,
    noVows,
    speaker,
    onSendWeddingReminder,
    notInvited,
    onInvite,
  } = props;
  const navigate = useNavigate();

  const { saveMemberChecklistItemUsingIdentifier } = useChecklist();

  const [showPrivateModal, setShowPrivateModal] = useState(false);

  const { deleteSpeaker } = useSpeakers({
    ceremonyId: speaker?.member.ceremony_id || 0,
  });

  const handleNavigate = () => {
    if (url) {
      navigate(url);
    }
  };
  if (isSpeaker)
    return (
      <SpeakerPlannerItem
        pendingInvitation={pendingInvitation}
        onDeleteClick={() => {
          if (speaker?.id) {
            deleteSpeaker(speaker.id);
          }
        }}
        {...props}
      />
    );

  const getItemContent = () => {
    if (noVows) {
      return (
        <>
          <p>
            {coupleName} have decided not to write personal vows for their
            ceremony.
          </p>
        </>
      );
    } else {
      // standard case, this is what was before
      return (
        <>
          <div className='flex flex-rows items-center'>
            {showTime && (
              <section className='PlannerItem-section flex-1'>
                <span className='head'>
                  {`${getTextDurationStringFromMinutes(time)} minutes`}{' '}
                </span>
                <span className='body'>Current Length</span>
              </section>
            )}
          </div>
        </>
      );
    }
  };

  const getItemButtons = () => {
    if (isDeactivated) {
      return <ButtonPill text={deactivatedButtonContent} disabled={true} />;
    } else if (notInvited) {
      return (
        <ButtonPill
          text='Invite'
          size={'sm'}
          className='flex-1 text-center'
          onClick={() => {
            onInvite && void onInvite();
          }}
        />
      );
    } else if (noVows) {
      return <></>;
    } else if (isPrivate) {
      return (
        <ButtonPill
          text={privateButtonContent}
          disabled={true}
          onClick={() => {
            setShowPrivateModal(true);
          }}
        />
      );
    } else {
      return (
        <>
          <ButtonPill
            text='Download'
            onClick={async () => {
              
              onExportPDF && void onExportPDF();

              await saveMemberChecklistItemUsingIdentifier(
                {
                  completed: true,
                },
                ChecklistItemIdentifier.REVIEW_SPEECHES,
                true
              );
            }}
          />
          <ButtonPill
            text='Review'
            onClick={async () => {
              await saveMemberChecklistItemUsingIdentifier(
                {
                  completed: true,
                },
                ChecklistItemIdentifier.REVIEW_SPEECHES,
                true
              );

              handleNavigate();
            }}
            className='bg-forest-primary text-white'
          />
        </>
      );
    }
  };

  const PrivateModal = () => {
    return (
      <Modal
        isOpen={showPrivateModal}
        title={'These Vows are private (for now)'}
        subtitle={''}
        onClose={() => setShowPrivateModal(false)}
        height='auto'
      >
        <div className='flex flex-col items-end justify-right'>
          <p>
            By default, vows are only visible to their author. If they would
            like to share them with you, they can do so through the Vow Privacy
            settings in their account.
          </p>
          <ButtonPill
            text='Got it'
            onClick={() => setShowPrivateModal(false)}
            className='bg-forest-primary text-white'
          />
        </div>
      </Modal>
    );
  };

  const PlannerItem = (props: PlannerItemProps) => {
    return (
      <div className='PlannerItem PlannerItem-root'>
        <div className='flex'>
          <img src={icon} alt='lock' height={48} width={48}/>
          <div className='PlannerItem-header'>
            <Typography type='display-50'>
              {title}
            </Typography>
            <PlannerItemProgress {...props} />
          </div>
        </div>
        <div className='PlannerItem-content'>{getItemContent()}</div>
        <div className='PlannerItem-footer mt-auto'>{getItemButtons()}</div>

        <PrivateModal />
      </div>
    );
  }

  const PlannerItemPendingInvitation = (props: PlannerItemProps) => {
    return (
      <div className='PlannerItem PlannerItemPendingInvite-root'>
        <Typography type='display-50' className='mb-2'>
          {name ? name + " hasn’t accepted yet" : "Partner hasn’t accepted yet"}
        </Typography>
        <Typography type='body-400' className='mb-8'>
          {name ? name + " hasn’t accepted your invite to join Provenance yet and write their vows yet." : "Partner hasn’t accepted your invite to join Provenance yet and write their vows yet."} 
        </Typography>
        <Button
          size='150'
          className='w-fit mx-auto'
          onClick={() => {
            onSendWeddingReminder && void onSendWeddingReminder();
          }}
        >
          Resend invite
        </Button>
      </div>
    );
  }

  return pendingInvitation ? <PlannerItemPendingInvitation {...props} /> : <PlannerItem {...props} />;
};

export default PlannerItem;
