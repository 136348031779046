import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';

import { useReadings } from '../../../provider/readingsProvider';

const AutoSave = ({ debounceMs = 1000 }) => {
  const formik = useFormikContext();
  const { setSaved } = useReadings();
  const debouncedSubmit = useCallback(
    _.debounce(() => {
      return formik.submitForm().then(() => setSaved(true));
    }, debounceMs),
    [formik.submitForm, debounceMs]
  );

  useEffect(() => {
    void debouncedSubmit();
  }, [debouncedSubmit, formik.values]);

  return <div></div>;
};

export default AutoSave;
