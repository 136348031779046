/* eslint-disable -- TODO: fix eslint errors */

import { useCallback, useEffect, useState } from 'react';
import { Member } from '../api/ceremony';
import {
  CeremonySpeaker,
  getOutputData,
  getSpeakingEvents,
  getVowsForMember,
  outputModules,
  OutputResponse,
  SpeakingEventsResponse,
  VowsResponse,
  getWelcomeToast,
} from '../api/output';
import { getTextDurationInMinutes } from '../helpers/textHelper';
import { getAnswerFromOptionsQuestion } from '../helpers/questionsHelper';
import useSpeakingEvents from './useSpeakingEvents';
import { useAllQuestions } from '../provider/questionsProvider';
import { capitalizeFirstLetter } from '../helpers/helper';
import { Module } from './useActiveModule';
import useQuestions from './useQuestions';

const useToast = () => {
  const [ceremonyId, setCeremonyId] = useState<string | number>();
  const [guestId, setGuestId] = useState<string | number>();
  const [toast, setToast] = useState('');
  const [welcomeToast, setWelcomeToast] = useState('');
  const [toastDuration, setToastDuration] = useState(0);
  const [toastTone, setToastTone] = useState('');
  const [couplesNote, setCouplesNote] = useState('');
  const [allotedTime, setAllotedTime] = useState(0);
  const [filteredModules, setFilteredModules] = useState<outputModules[]>([]);
  const [isOldToastBuilder, setIsOldToastBuilder] = useState(false);
  const [isNewToastBuilder, setNewOldToastBuilder] = useState(false);
  const [showOldToastBuilder, setShowOldToastBuilder] = useState(false);
  const { allowedEvents, getSpeakingEventsForGuest } = useSpeakingEvents();

  const {
    questions: oldToastbuilderQuestions,
    fetchQuestions: fetchOldToastbuilderQuestions,
  } = useQuestions();
  const {
    questions: newToastbuilderQuestions,
    fetchQuestions: fetchNewToastbuilderQuestions,
    getQuestionByQuestionText,
  } = useQuestions();

  useEffect(() => {
    if (ceremonyId) {
      void fetchOldToastbuilderQuestions(
        Module.GuestToast,
        ceremonyId.toString()
      );
      void fetchNewToastbuilderQuestions(
        Module.GuestToastAi,
        ceremonyId.toString()
      );
    }
  }, [ceremonyId]);

  useEffect(() => {
    if (newToastbuilderQuestions) {
      const toastToneQuestion = getQuestionByQuestionText(
        'What tone would you like for your speech?'
      );
      if (
        toastToneQuestion &&
        toastToneQuestion.answers &&
        toastToneQuestion.answers.length > 0
      ) {
        const answer = toastToneQuestion.answers[0];
        const answerIds =
          answer && answer.option_id && answer.option_id.split(',');

        const options = toastToneQuestion.options?.filter(
          (option) => answerIds && answerIds.includes(option.id.toString())
        );

        //join all options
        const option = options
          ?.map((option) => capitalizeFirstLetter(option.option))
          .join(', ');
        setToastTone(option || '');
      }
    }
  }, [newToastbuilderQuestions]);

  useEffect(() => {
    if (
      oldToastbuilderQuestions &&
      oldToastbuilderQuestions?.length > 0 &&
      oldToastbuilderQuestions[0].answers &&
      oldToastbuilderQuestions[0].answers?.length > 0
    ) {
      setIsOldToastBuilder(
        oldToastbuilderQuestions.some((q) => {
          if (q.answers) {
            return q.answers?.length > 0;
          }
        })
      );
    } else {
      setNewOldToastBuilder(true);
    }
  }, [oldToastbuilderQuestions]);

  useEffect(() => {
    if (isNewToastBuilder) {
      setShowOldToastBuilder(false);
    } else if (isOldToastBuilder) {
      setShowOldToastBuilder(true);
    }
  }, [isOldToastBuilder, isNewToastBuilder]);

  const fetchToast = async (
    ceremonyId: string | number,
    guestId?: string | number
  ) => {
    setCeremonyId(ceremonyId);
    if (guestId) {
      setGuestId(guestId);
    }
    try {
      await getSpeakingEventsForGuest(ceremonyId, guestId);
    } catch (err) {
      console.log('error', err);
    }
  };

  const fetchWelcomeToast = async (
    ceremonyId: string | number,
  ) => {
    setCeremonyId(ceremonyId);
    try {
      let data = await getWelcomeToast(ceremonyId);
      if (data.success) {
        setWelcomeToast(data.data.content);
      }

    } catch (err) {
      console.log('error', err);
    }
  };


  const getOutput = useCallback(
    async (
      ceremonyId: string,
      filteredModules: outputModules[],
      guestId?: string | number
    ) => {
      if (ceremonyId && filteredModules && filteredModules.length > 0) {
        try {
          const outputResponse: OutputResponse = await getOutputData(
            ceremonyId,
            true,
            guestId
          );
          if (outputResponse.success) {
            if (
              outputResponse.data[0] &&
              filteredModules.indexOf(outputResponse.data[0].module) >= 0
            ) {
              setToast(outputResponse.data[0].content);
              setToastDuration(
                getTextDurationInMinutes(outputResponse.data[0].content)
              );
            }
          } else {
            console.log('error', outputResponse.message);
          }
        } catch (err) {
          console.log('error', err);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredModules]
  );

  useEffect(() => {
    if (allowedEvents) {
      const result: outputModules[] = [];
      if (allowedEvents.engagement_party) {
        result.push(outputModules.friends_engagement_toast_ai);
        setCouplesNote(allowedEvents.engagement_party.speech_note);
        setAllotedTime(allowedEvents.engagement_party.alloted_time);
      }
      if (allowedEvents.reception) {
        result.push(outputModules.friends_reception_toast_ai);
        setCouplesNote(allowedEvents.reception.speech_note);
        setAllotedTime(allowedEvents.reception.alloted_time);
      }
      if (allowedEvents.rehearsal_dinner) {
        result.push(outputModules.friends_rehearsal_toast_ai);
        setCouplesNote(allowedEvents.rehearsal_dinner.speech_note);
        setAllotedTime(allowedEvents.rehearsal_dinner.alloted_time);
      }
      if (allowedEvents.guest_standalone_toast) {
        result.push(outputModules.guest_standalone_toast_ai);
        setCouplesNote(allowedEvents.guest_standalone_toast.speech_note);
        setAllotedTime(allowedEvents.guest_standalone_toast.alloted_time);
      }
      setFilteredModules(result);
    }
  }, [allowedEvents]);

  useEffect(() => {
    if (ceremonyId && filteredModules) {
      void getOutput(ceremonyId.toString(), filteredModules, guestId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ceremonyId, guestId, filteredModules]);

  return {
    fetchToast,
    filteredModules,
    toast,
    toastTone,
    toastDuration,
    couplesNote,
    allotedTime,
    isOldToastBuilder,
    isNewToastBuilder,
    showOldToastBuilder,
    setShowOldToastBuilder,
    fetchWelcomeToast,
    welcomeToast,
  };
};

export default useToast;
