/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { generalLog } from '../../../api/ceremony';
import heartComment from '../../../assets/images/icons/heartComment.svg';
import Button from '../../../components/v2/Button/Button';
import { Module } from '../../../hooks/useActiveModule';
import useCeremony from '../../../hooks/useCeremony';
import useQuestions from '../../../hooks/useQuestions';
import useToast from '../../../hooks/useToast';
import { CTAProvider } from '../../../provider/ctaProvider';
import { useDashboard } from '../../../provider/dashboardProvider';
import { useAccessibleModule } from '../../../provider/moduleAccessProvider';
import { useToastBuilder } from '../../../provider/toastBuilderProvider';
import { Question } from '../../Modules/common/moduleQuestion/ModuleQuestion';
import { ModuleQuestion, ModuleWrapper } from '../components';
import { GuiltModal } from '../components/GuiltModal/GuiltModal';
import { ModuleStatus } from '../enum';

export const ToastModule = ({
  hideWrapper = false,
}: {
  hideWrapper?: boolean;
}) => {
  const navigate = useNavigate();
  const ceremonyId = useParams()?.ceremonyId;
  const [questionChanged, setQuestionChanged] = useState(false);
  const [requiredQuestions, setRequiredQuestions] = useState<number[]>([
    0, 1, 2, 3, 4,
  ]);
  const [openGuiltModal, setOpenGuiltModal] = useState(false);
  const [questionsValue, setQuestionsValue] = useState<Question[]>([]);
  const [speechFocusQuestion, setSpeechFocusQuestion] =
    useState<Question | null>();

  const {
    ceremony,
    couple1,
    couple2,
    officiant,
    currentUser,
    fetchCeremonyById,
  } = useCeremony();

  const members = { couple1, couple2, officiant, currentUser };

  const { setDraftingModalOpen, setGenerateDraftInitiated } = useToastBuilder();
  const { couplesNote, fetchToast } = useToast();
  const { questions, fetchQuestions } = useQuestions();
  const { fetchCeremony } = useDashboard();

  const generateUrlParam = new URLSearchParams(location.search).get('generate');

  const {
    hasAccessToToastBuilder,
    fetchAccessibleModulesIdentifiers,
  } = useAccessibleModule();

  useEffect(() => {
    void fetchAccessibleModulesIdentifiers(ceremonyId as string);
  }, []);

  useEffect(() => {
    if (ceremonyId) {
      void fetchCeremonyById(ceremonyId);
      void fetchCeremony(ceremonyId);
    }
  }, [ceremonyId]);

  useEffect(() => {
    if (questions && (!questionsValue || questionsValue.length === 0)) {
      setQuestionsValue(questions);
    } else if (questions && questionsValue && questionsValue.length > 0) {
      const wantedQuestion = questions.find(
        (question) =>
          question.question ===
          'Would you like your speech to focus on the couple as a whole or on one of partners?'
      );
      if (wantedQuestion) {
        setSpeechFocusQuestion(wantedQuestion);
      }
    }
  }, [questions]);

  const handleQuestionSaved = (questionText: string) => {
    if (
      questionText ===
      'Would you like your speech to focus on the couple as a whole or on one of partners?'
    ) {
      setQuestionChanged(true);
    }
  };

  useEffect(() => {
    if (ceremony) {
      void fetchQuestions(Module.GuestToastAi, ceremony.id.toString());
    }
  }, [ceremony]);

  useEffect(() => {
    if (ceremonyId && questionChanged === true) {
      setQuestionChanged(false);
      void fetchQuestions(Module.GuestToastAi, ceremonyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionChanged]);

  useEffect(() => {
    handleConditionallyMarkingQuestionsAsRequired();
  }, [speechFocusQuestion]);

  const handleConditionallyMarkingQuestionsAsRequired = () => {
    //if question 4 has an answer
    if (
      speechFocusQuestion &&
      speechFocusQuestion?.answers?.[0]?.option_id &&
      speechFocusQuestion.options
    ) {
      const wantedAnswerOptionId = speechFocusQuestion.answers[0].option_id;

      const optionText = speechFocusQuestion?.options.find(
        (option) => option.id === parseInt(wantedAnswerOptionId)
      )?.option;

      if (
        optionText ===
        'Mostly focus on {{PARTNER_A}}, but acknowledge {{PARTNER_B}}'
      ) {
        setRequiredQuestions([0, 1, 2, 3, 4, 5, 6, 7]);
      } else if (
        optionText ===
        'Mostly focus on {{PARTNER_B}}, but acknowledge {{PARTNER_A}}'
      ) {
        setRequiredQuestions([0, 1, 2, 3, 4, 5, 8, 9]);
      } else if (optionText === 'Speak about the couple as a whole') {
        setRequiredQuestions([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
      }
    } else {
      setRequiredQuestions([0, 1, 2, 3, 4]);
    }
  };

  useEffect(() => {
    if (ceremonyId) {
      void fetchCeremonyById(ceremonyId);
      void fetchQuestions(Module.GuestToastAi, ceremonyId);
      void fetchToast(ceremonyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ceremonyId]);

  useEffect(() => {

    if(generateUrlParam === 'true' && hasAccessToToastBuilder && ceremony?.id) {
      startGenerating();
    }

  }, [generateUrlParam, hasAccessToToastBuilder, ceremony]);

  const startGenerating = () => {
    if (!hasAccessToToastBuilder) {
      if (ceremony?.id) {
        location.href = `/pay-toastbuilder/${ceremony.id}`;
      }
      return;
    }

    if (ceremonyId) {
      setDraftingModalOpen(true);
      setGenerateDraftInitiated(true);
      void generalLog({
        ceremony_id: ceremonyId,
        activity: `Generate - ToastAI`,
      });
      navigate(`/ai-toast-builder/${ceremonyId}`);
    } else {
      navigate(-1);
    }
  };

  return (
    <CTAProvider
      totalQuestions={questions?.length || 0}
      requiredQuestions={requiredQuestions.length}
      module={Module.GuestToastAi}
    >
      <ModuleWrapper
        hideWrapper={hideWrapper}
        chapterProps={{
          renderIcon: () => <img src={heartComment} draggable={false} />,
        }}
        summaryProps={{
          moduleName: 'Toast Builder Questionnaire',
          moduleDescription: `Giving a toast is an incredible privilege, filled with both excitement and a touch of apprehension. Fear not, for our AI-powered questionnaire is here to help transform your personal anecdotes into a delightful toast that will undoubtedly inspire a chorus of clinking glasses.`,
          numberOfQuestions: 11,
          estimatedTime: 15,
          showAsteriskMeaning: true,
          couplesNote: couplesNote,
        }}
        questionsHeaderProps={{
          renderCenterButton: (
            moduleStatus: ModuleStatus,
            status: 'saving' | 'saved' | 'unsaved'
          ) => (
            <Button
              size='100'
              disabled={
                (moduleStatus !== ModuleStatus.completed &&
                  moduleStatus !== ModuleStatus.requiredAnswered) ||
                status !== 'saved'
              }
              onClick={() => {
                if (moduleStatus !== ModuleStatus.completed)
                  setOpenGuiltModal(true);
                else startGenerating();
              }}
            >
              Generate Toast
            </Button>
          ),
          renderRightButton: (moduleStatus: ModuleStatus) => (
            <Button
              size='100'
              variant='secondary'
              disabled={false}
              onClick={() => {
                if (ceremonyId) {
                  navigate(`/ceremony/${ceremonyId}/guest`);
                } else {
                  navigate(-1);
                }
              }}
            >
              Exit & Save
            </Button>
          ),
        }}
        noGap={true}
      >
        {ceremony &&
          questions &&
          questions.length > 0 &&
          requiredQuestions &&
          questions
            .filter((_questionData, index) => requiredQuestions.includes(index))
            .sort((a, b) => {
              if (a.order && b.order) {
                return a.order < b.order ? -1 : 1;
              } else if (a.order != null && b.order === null) {
                return -1;
              } else if (a.order === null && b.order != null) {
                return 1;
              } else {
                return -1;
              }
            })
            .map((questionData, questionIndex) => (
              <ModuleQuestion
                key={questionData.id}
                ceremonyId={ceremony.id}
                question={questionData}
                questionNumber={questionIndex + 1}
                numberOfQuestions={questions.length}
                members={members}
                required={true}
                onQuestionSaved={() =>
                  handleQuestionSaved(questionData.question)
                }
                shouldRerender={true}
              />
            ))}
        {ceremony &&
          questions &&
          questions.length > 0 &&
          requiredQuestions &&
          questions
            .filter(
              (_questionData, index) => !requiredQuestions.includes(index)
            )
            .sort((a, b) => {
              if (a.order && b.order) {
                return a.order < b.order ? -1 : 1;
              } else if (a.order != null && b.order === null) {
                return -1;
              } else if (a.order === null && b.order != null) {
                return 1;
              } else {
                return -1;
              }
            })
            .map((questionData, questionIndex) => (
              <ModuleQuestion
                key={questionData.id}
                ceremonyId={ceremony.id}
                question={questionData}
                questionNumber={questionIndex + requiredQuestions.length + 1}
                numberOfQuestions={questions.length}
                members={members}
                required={false}
                shouldRerender={true}
              />
            ))}

        <GuiltModal
          isOpen={openGuiltModal}
          totalQuestions={questions?.length || 0}
          onCancelClick={() => {
            setOpenGuiltModal(false);
          }}
          onContinue={startGenerating}
        />
      </ModuleWrapper>
    </CTAProvider>
  );
};
