import { User } from '../api/auth';
import { updateUserCustomField } from '../api/user';
import useUser from '../hooks/useUser';
import { useAuth } from '../provider/authProvider';

export const getUserCustomField = (user: User, field: string): boolean => {
  return (
    user.custom_fields.filter(
      (f: { key: string; value: string }) =>
        f.key === field && f.value === 'true'
    ).length > 0
  );
};

export const setUserCustomField = async (
  field: string,
  value: string
): Promise<boolean> => {
  await updateUserCustomField(field, value);

  return true;
};

