import { Question } from "../pages/Modules/common/moduleQuestion/ModuleQuestion";

export const getAnswerFromOptionsQuestion = (question: Question) => {
    if (question && question?.answers && question?.answers.length > 0) {

      //get selected option
      const selectedOption = question.options?.find(
        (option) => {
          if(option && option?.id && question?.answers && question?.answers[0]?.option_id){
            return option.id === parseInt(question.answers[0]?.option_id);
          }
        }
      );

      return selectedOption?.option;

    }
    return '';
  }