/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { QuestionType } from '../components';

import { Member } from '../../../api/ceremony';
import { TextQuestionProvider } from '../../../provider/questionProviders/textQuestionProvider';
import { QuestionText } from '../components/ModuleQuestion/QuestionTypes/QuestionText';
import { ModuleQuestionHeader } from '../components/ModuleQuestion/ModuleQuestionHeader';
import { interpolatePlaceholders } from '../../../helpers/placeholderHelper';
import { AddOutlined, DeleteOutline } from '@mui/icons-material';
import { deleteAnswer } from '../../../api/question';
import { Question } from '../../Modules/common/moduleQuestion/ModuleQuestion';
import { useCTA } from '../../../provider/ctaProvider';
import InfoWarning from '../../../components/v2/InfoWarning/InfoWarning';
import { capitalizeFirstLetter } from '../../../helpers/helper';

type LoveStoryQuestionProps = {
  remarksQuestionInitial: Question;
  couple2QuestionInitial: Question;
  members: {
    couple1: Member | undefined;
    couple2: Member | undefined;
    officiant: Member | undefined;
    currentUser: Member | undefined;
  };
  questionNumber: number;
  totalNumberOfQuestions: number;
  required?: boolean;
  ceremonyId: number;
  mainQuestionPrivate: boolean;
  couple2QuestionPrivate: boolean;
  createdByOfficiant: boolean;
};

const LoveStoryQuestionComponent = (props: LoveStoryQuestionProps) => {
  const {
    remarksQuestionInitial,
    couple2QuestionInitial,
    members,
    questionNumber,
    ceremonyId,
    totalNumberOfQuestions,
    required,
    mainQuestionPrivate,
    couple2QuestionPrivate,
    createdByOfficiant,
  } = props;
  const hasCouple2Question = !!couple2QuestionInitial?.id;
  const [questionInitiated, setQuestionInitiated] = useState(false);
  const [isWithPartnerStory, setIsWithPartnerStory] = useState(false);
  const [remarksQuestion, setRemarksQuestion] = useState<Question | null>(
    remarksQuestionInitial
  );
  const [couple2Question, setCouple2Question] = useState<Question | null>(
    couple2QuestionInitial
  );

  const { handleQuestionAnswered, handleQuestionNotAnswered } = useCTA();

  useEffect(() => {
    let isQuestionAnswered = false;
    if (remarksQuestion && couple2Question && questionInitiated) {
      const [mainAnswer] = remarksQuestion.answers || [];
      const [couple2Answer] = couple2Question.answers || [];

      if (isWithPartnerStory) {
        isQuestionAnswered =
          !!mainAnswer?.text_answer && !!couple2Answer?.text_answer;
      } else {
        isQuestionAnswered = !!mainAnswer?.text_answer;
      }
    }
    if (remarksQuestion) {
      if (isQuestionAnswered) {
        handleQuestionAnswered(remarksQuestion.id);
      } else {
        handleQuestionNotAnswered(remarksQuestion.id);
      }
    }
  }, [remarksQuestion, couple2Question, isWithPartnerStory, questionInitiated]);

  useEffect(() => {
    const hasCouple2Answer =
      hasCouple2Question &&
      !!couple2Question?.answers?.length &&
      !!couple2Question?.answers[0]?.text_answer;

    setIsWithPartnerStory(
      isWithPartnerStory ? isWithPartnerStory : hasCouple2Answer
    );
    setQuestionInitiated(true);
  }, [couple2Question]);

  const onSwitchMode = async (newMode: boolean) => {
    if (newMode === false && couple2Question?.answers) {
      const answer = {
        ...couple2Question?.answers[0],
        question_id: couple2Question.id,
      };
      if (answer.id) {
        await deleteAnswer(answer);
      }
      setCouple2Question({ ...couple2Question, answers: [] });
    }
    setIsWithPartnerStory(newMode);
  };
  return (
    <div className={`flex flex-col gap-6 sm:gap-8 pb-[120px]`}>
      <ModuleQuestionHeader
        type={remarksQuestionInitial.type as QuestionType}
        questionNumber={questionNumber}
        totalOfQuestions={totalNumberOfQuestions}
        question={interpolatePlaceholders({
          text: remarksQuestionInitial.question,
          data: members,
        })}
        hintText={remarksQuestionInitial.help_short_text}
        subtitle={''}
        infoText={remarksQuestionInitial.info_text}
        required={required}
      />
      {mainQuestionPrivate && (
        <InfoWarning
          text={
            createdByOfficiant
              ? 'Answers to these questions were set to private. Contact your officiant to change the privacy settings.'
              : 'Answers to these questions were set to private. Contact the account owner to change the privacy settings.'
          }
          variant='grey'
        />
      )}
      <div className='w-full'>
        {remarksQuestion && (
          <TextQuestionProvider
            question={remarksQuestion}
            ceremonyId={ceremonyId}
            onSaved={(answer) =>
              setRemarksQuestion({ ...remarksQuestion, answers: [answer] })
            }
            saveAnswered={false}
          >
            <QuestionText
              type={QuestionType.Text}
              value={
                remarksQuestion.answers && remarksQuestion.answers.length > 0
                  ? remarksQuestion?.answers[0]?.text_answer
                  : ''
              }
              placeholder={remarksQuestion.textfield_placeholder}
              label={`${capitalizeFirstLetter(
                members?.couple1?.preferred_name || ''
              )}'s Story`}
              suggestedMinWords={remarksQuestion.suggested_min_words}
              isPrivate={mainQuestionPrivate}
            />
          </TextQuestionProvider>
        )}
        {!!couple2Question?.id && (
          <>
            {isWithPartnerStory ? (
              <TextQuestionProvider
                question={couple2Question}
                ceremonyId={ceremonyId}
                onSaved={(answer) =>
                  setCouple2Question({ ...couple2Question, answers: [answer] })
                }
                saveAnswered={false}
              >
                <QuestionText
                  type={QuestionType.Text}
                  value={
                    couple2Question.answers &&
                    couple2Question.answers.length > 0
                      ? couple2Question?.answers[0]?.text_answer
                      : ''
                  }
                  placeholder={couple2Question.textfield_placeholder}
                  label={
                    <div className='flex flex-row items-center justify-between'>
                      <span className='font-grotesk text-forest-500 font-medium'>
                        {capitalizeFirstLetter(
                          members?.couple2?.preferred_name || ''
                        )}
                        's Story
                      </span>
                      <button
                        type='button'
                        onClick={() => void onSwitchMode(false)}
                      >
                        <DeleteOutline className='w-4 h-4' />
                      </button>
                    </div>
                  }
                  suggestedMinWords={couple2Question.suggested_min_words}
                  isPrivate={couple2QuestionPrivate}
                />
              </TextQuestionProvider>
            ) : (
              <button
                type='button'
                className='flex flex-row items-center self-center gap-1  mb-4'
                onClick={() => setIsWithPartnerStory(true)}
              >
                <AddOutlined className='w-6 h-6' />
                <span className='font-grotesk text-forest-500 underline decoration-1 decoration-dashed underline-offset-4'>
                  Add Partner's Response
                </span>
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const LoveStoryQuestion = React.memo(LoveStoryQuestionComponent);
