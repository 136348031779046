import classNames from 'classnames';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

import { Member, generalLog } from '../../../api/ceremony';
import { downloadOutput } from '../../../api/output';
import { OrderSources } from '../../../api/paywall';
import vowsSvg from '../../../assets/images/image/vows.svg';
import { capitalizeFirstLetter } from '../../../helpers/helper';
import { getWordsCount } from '../../../helpers/textHelper';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useDashboard } from '../../../provider/dashboardProvider';
import Button from '../Button/Button';
import { Typography } from '../Typography/Typography';

import vowsLockedSvg from './vows-locked.svg';
import './vowsDashboardItem.scss';
import toastIcon from './glasses-purple-circle.svg'

import ABTester from '../ABTester/ABTester';
import { Separator } from '../Separator/Separator';

type VowsDashboardItemProps = {
  variant: 'toast' | 'vows' | 'welcome_toast';
  canEditVows: boolean;
  showVows?: boolean;
  showTime?: boolean;
  showLength?: boolean;
  showTone?: boolean;
  vowsTime: number;
  vows?: string;
  className?: string;
  member: Member;
  ceremonyId: string;
  title: string;
  text?: string;
  onButtonClick?: () => Promise<void> | void;
  setIsPaywallModalOpen?: (isOpen: boolean) => void;
  hasAccess?: boolean;
  vowsTone?: string;
  vowsTargetLength?: number;
  inviteButtonText?: string;
  paywallSource?: OrderSources;
  onUpgradeButtonClick?: () => void;
};

export const VowsDashboardItem = (props: VowsDashboardItemProps) => {
  const {
    vowsTone,
    canEditVows,
    showVows,
    showLength = true,
    showTone = true,
    vows,
    vowsTime,
    member,
    ceremonyId,
    title,
    variant,
    text,
    onButtonClick,
    hasAccess = true,
    inviteButtonText,
    onUpgradeButtonClick,
  } = props;
  const navigate = useNavigate();
  const {
    setMemberType,
    setMemberForEditing,
    setAddModalOpen,
    setMobileMenuExpanded,
    ceremony,
  } = useDashboard();
  const isEmptyVow = vows?.length === 0 || vows === undefined;
  const size = useWindowSize();

  const handleDownloadPdf = async (
    downloadType: 'output' | 'toast' | 'vows' | 'welcome_toast'
  ) => {
    ReactGA.event({
      category: 'Output',
      action: 'Button click',
      label: 'Download PDF',
    });

    if (ceremonyId) {
      //showLoader();
      const blob = await downloadOutput(
        ceremonyId,
        downloadType,
        false,
        false,
        undefined,
        member.id
      );
      const url = window.URL.createObjectURL(blob);

      // window.open(url, '_blank'); // To open the pdf in a new tab

      const a = document.createElement('a');
      a.href = url;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment

      a.download = downloadType;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    }
  };

  const onClickHandler = (path: string) => {
    if (typeof onButtonClick === 'function') return void onButtonClick();
    navigate(path);
  };

  if ( variant === 'toast')
    return (
      <div className='VowsDashboardItemToast'>
        <div className='VowsDashboardItemToast-header'>
          <img src={toastIcon} alt='toast icon' width={48} height={48}/>
          <div className='VowsDashboardItemToast-title'>
            <Typography type='display-100'>Your Wedding Toast</Typography>
            {/* <Typography type='body-400'>Supporting copy that describes your vows more detail. The copy here shouldn’t exceed a sentence or two.</Typography> */}
          </div>

        </div>
        <div className='VowsDashboardItemToast-specifications'>
          <div className='VowsDashboardItemToast-specifications-item'>
            <Typography type='display-50'>{vowsTime ? `${vowsTime} mins` : 'TBD'}</Typography>
            <Typography type='body-200' variant='functional-low' className='text-forest-300'>Toast length</Typography>
          </div>
          <Separator variant='vertical' className='border-forest-100'/>
          <div className='VowsDashboardItemToast-specifications-item'>
            <Typography type='display-50'>{vows ? `${getWordsCount(vows)}` : 'TBD'}</Typography>
            <Typography type='body-200' variant='functional-low' className='text-forest-300'>Word Count</Typography>
          </div>
          <Separator variant='vertical' className='border-forest-100'/>
          <div className='VowsDashboardItemToast-specifications-item'>
            <Typography type='display-50'>{vowsTone ? capitalizeFirstLetter(vowsTone) : 'TBD'}</Typography>
            <Typography type='body-200' variant='functional-low' className='text-forest-300'>Intended tone</Typography>
          </div>

        </div>

        <div className='VowsDashboardItemToast-buttonsContainer'>
          <Button
            size={size.isMobile ? '100' : '200'}
            variant='secondary'
            className='w-full'
            onClick={() => handleDownloadPdf('toast')}
          >
            Download PDF
          </Button>

          <Button
            className='w-full'
            onClick={
              isEmptyVow
                ? () => onClickHandler(`/ceremony/${ceremonyId}/vows`)
                : () => onClickHandler(`/vow-builder-ai/${ceremonyId}`)
            }
            size={size.isMobile ? '100' : '200'}
          >
            {isEmptyVow ? 'Get Started' : 'Open Builder'}
          </Button>
          
        </div>
      </div>
  )
  else return (
    <div
      className={classNames('VowsDashboardItem-root', {
        'VowsDashboardItem-locked': !hasAccess || !showVows,
      })}
    >
      <div className='flex flex-col lg:flex-row'>
        {hasAccess && showVows ? (
          <img className='hidden lg:block' src={vowsSvg} />
        ) : (
          <img className='hidden lg:block' src={vowsLockedSvg} />
        )}
        {/*Javi Previously:  </div><div className='flex flex-col max-w-[300px] min-w-[300px] lg:mx-8'> */}
        <div className='flex flex-col max-w-[300px] lg:mx-8'>
          <Typography type='display-50'>{title}</Typography>
          <Typography className='mt-2 lg:mt-0' type='body-400'>
            {text}
          </Typography>
        </div>

        {(showLength || showTone) && (
          <div className='flex flex-row lg:flex-col lg:justify-between max-w-sm mt-4 lg:mt-0'>
            {showLength && (
              <div>
                <Typography type='display-50'>
                  {vowsTime ? `${vowsTime} mins` : 'TBD'}
                </Typography>
                <Typography
                  className={!hasAccess ? 'text-forest-400' : 'text-forest-300'}
                  type='body-200'
                >
                  Vow length
                </Typography>
              </div>
            )}
            {showTone && (
              <div className='border-l border-forest-100 lg:border-l-0 ml-4 pl-4 lg:ml-0 lg:pl-0'>
                <Typography type='display-50'>
                  {vowsTone ? capitalizeFirstLetter(vowsTone) : 'TBD'}
                </Typography>
                <Typography
                  className={!hasAccess ? 'text-forest-400' : 'text-forest-300'}
                  type='body-200'
                >
                  Intended tone
                </Typography>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={`flex flex-row justify-end items-center ${''}`}>
        {member.legal_name !== 'TBD TBD' && member.email ? (
          <>
            {canEditVows && hasAccess && (
              <div className='w-full mt-6 md:mt-0 flex flex-col md:flex-row justify-center md:justify-end items-center'>
                {!isEmptyVow && (
                  <Button
                    size={size.isMobile ? '100' : '200'}
                    variant='secondary'
                    className='md:mr-2 mb-2 md:mb-0 w-full md:w-auto'
                    onClick={() =>
                      void handleDownloadPdf(variant ? variant : 'toast')
                    }
                  >
                    Download PDF
                  </Button>
                )}

                <Button
                  className='w-full md:w-auto'
                  onClick={
                    isEmptyVow
                      ? () => onClickHandler(`/ceremony/${ceremonyId}/vows`)
                      : () => onClickHandler(`/vow-builder-ai/${ceremonyId}`)
                  }
                  size={size.isMobile ? '100' : '200'}
                >
                  {isEmptyVow ? 'Get Started' : 'Open Builder'}
                </Button>
              </div>
            )}
            {hasAccess && variant === 'vows' && !canEditVows && showVows && (
              <Button
                className='mt-6 md:mt-0 w-full md:w-auto'
                size={size.isMobile ? '100' : '200'}
                onClick={() =>
                  navigate(
                    `/preview-vows/${ceremonyId}/${member.id?.toString() || ''}`
                  )
                }
              >
                Preview Vows
              </Button>
            )}
            {!hasAccess && showVows && onUpgradeButtonClick && (
              <ABTester
                components={[
                  {
                    component: (
                      <Button
                        size={size.isMobile ? '100' : '200'}
                        className='mt-6 md:mt-0 w-full md:w-auto'
                        onClick={onUpgradeButtonClick}
                      >
                        Get Started
                      </Button>
                    ),
                    render: ceremony?.vowslanding_test_category === 'new_copy',
                  },
                  {
                    component: (
                      <Button
                        size={size.isMobile ? '100' : '200'}
                        className='mt-6 md:mt-0 w-full md:w-auto'
                        onClick={onUpgradeButtonClick}
                      >
                        Upgrade to Unlock
                      </Button>
                    ),
                    default: true,
                  },
                ]}
              />
            )}
            {!showVows && (
              <Button
                size={size.isMobile ? '100' : '200'}
                className='mt-6 md:mt-0 w-full md:w-auto'
                disabled
              >
                Locked
              </Button>
            )}
          </>
        ) : (
          <Button
            size={size.isMobile ? '100' : '200'}
            className='mt-6 md:mt-0 w-full md:w-auto'
            onClick={() => {
              setMemberType(member.member_type);
              setMemberForEditing(member);
              setAddModalOpen(true);
              generalLog({
                ceremony_id: ceremonyId,
                activity: `Invite Partner - VowBuilder - v1`,
              });
            }}
          >
            {inviteButtonText || 'Invite Your Partner'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default VowsDashboardItem;
