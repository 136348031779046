import classNames from 'classnames';

import { SelectOptionProps } from './QuestionSelect';

const SelectOptionWithDescription = ({
  label,
  isSelected,
  description,
  onClick,
  useHtmlDescription,
}: SelectOptionProps) => {
  const classes =
    'p-6 flex flex-col gap-2 justify-start rounded-3xl select-none';
  const unselectedClasses = 'border border-forest-primary';
  const selectedClasses =
    'border-[3px] border-copper-primary text-copper-primary';
  return (
    <button
      type='button'
      {...{ onClick }}
      className={classNames(classes, {
        [unselectedClasses]: !isSelected,
        [selectedClasses]: isSelected,
      })}
    >
      <span className='font-grotesk text-lg text-left'>{label}</span>
      {!!description && (
        <div
          className={classNames('font-grotesk font-medium text-sm text-left', {
            'text-forest-300': !isSelected,
          })}
          dangerouslySetInnerHTML={
            useHtmlDescription ? { __html: description as string } : undefined
          }
          children={!useHtmlDescription ? description : null}
        />
      )}
    </button>
  );
};

export default SelectOptionWithDescription;
