type ABTesterProps = {
    components: {
        component: JSX.Element,
        render?: boolean,
        default?: boolean
    }[]
}

const ABTester = (props: ABTesterProps) => {
    const {components} = props;

    if (components.length === 0) return <></>;
    if (components.length === 1) return components[0].component

    let componentToRender = null;

    for (let i = 0; i < components.length; i++) {
        if( components[i].default && !componentToRender)  componentToRender = components[i].component;
        
        if (components[i].render) {
            componentToRender = components[i].component;
            break;
        }
    }

    if (!componentToRender) componentToRender = <></>

    return <>{componentToRender}</>
}


export default ABTester;