import { useEffect, useState, Fragment } from 'react';

import { User } from '../../api/auth';
import { Member, MemberSubType, MemberType } from '../../api/ceremony';
import { EventType, rolesDropdown, Speaker } from '../../api/friends';
import Button from '../../components/button';
import CustomDropdown from '../../components/forms/CustomDropdown';
import CustomTextInput from '../../components/forms/CustomTextInput';
import { Modal } from '../../components/v2/Modal/Modal';
import AddOfficiantButton from '../Ceremony/BasicInfo/AddOfficiantButton';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onBackClick: () => void;
  onNextClick: () => Promise<void>;
  setSpeakers: (speakers: Speaker[]) => void;
  speakers: Speaker[];
  title: string;
  isSubmit?: boolean;
  allEmails?: string[];
};

export const EventAddSpeakerModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    onBackClick,
    onNextClick,
    setSpeakers,
    speakers,
    title,
    isSubmit = false,
    allEmails,
  } = props;

  const [numberOfFields, setNumberOfFields] = useState<number>(1);

  const events = [
    { value: 'engagement_party', label: 'Engagement Party' },
    { value: 'rehearsal_dinner', label: 'Rehearsal Dinner' },
    { value: 'reception', label: 'Reception' },
  ];

  useEffect(() => {
    if (speakers.length > 0) {
      setNumberOfFields(speakers.length);
    }
  }, [speakers]);

  const setSpeakerData = (
    index: number,
    property: string | number,
    value: string
  ) => {
    const newSpeakers = [...speakers];
    if (!newSpeakers[index])
      newSpeakers.push({ member: {} as Member } as Speaker);

    switch (property) {
      case 'legal_name':
        newSpeakers[index].member.legal_name = value;
        break;
      case 'email':
        newSpeakers[index].member.email = value;
        break;
      case 'member_sub_type':
        newSpeakers[index].member.member_sub_type = value as MemberSubType;
        break;
      case 'event':
        newSpeakers[index].event = value as EventType;
        break;
      case 'alloted_time':
        newSpeakers[index].alloted_time = value as unknown as number;
        break;
      case 'speech_note':
        newSpeakers[index].speech_note = value;
        break;
    }

    newSpeakers[index].member.member_type = MemberType.guests;
    setSpeakers(newSpeakers);
  };

  const areEmailsValid = () => {
    let result = true;
    speakers.forEach((speaker) => {
      if (
        speaker.member.email &&
        allEmails &&
        allEmails.filter((e) => e === speaker.member.email).length > 1
      ) {
        result = false;
      }
    });
    return result;
  };

  const createFields = () => {
    const fields = [];
    for (let i = 0; i < numberOfFields; i++) {
      fields.push(
        <Fragment key={i}>
          {i > 0 && <hr className='my-4' />}
          <div className='flex flex-col md:flex-row'>
            <div className='flex-auto md:mr-8 mr-2'>
              <h3 className='text-sm pl-2'>{'Full name'}</h3>
              <CustomTextInput
                onChange={(e: { target: { value: string } }) =>
                  setSpeakerData(i, 'legal_name', e.target.value)
                }
                value={speakers[i]?.member.legal_name || ''}
              />
            </div>
            <div className='flex-auto mr-2'>
              <h3 className='text-sm pl-2'>{'Email'}</h3>
              <CustomTextInput
                onChange={(e: { target: { value: string } }) =>
                  setSpeakerData(i, 'email', e.target.value)
                }
                value={speakers[i]?.member.email || ''}
                error={
                  speakers[i] &&
                  speakers[i].member.email &&
                  allEmails &&
                  allEmails.filter((e) => e === speakers[i].member.email)
                    .length > 1
                    ? true
                    : false
                }
                helperText={
                  speakers[i] &&
                  speakers[i].member.email &&
                  allEmails &&
                  allEmails.filter((e) => e === speakers[i].member.email)
                    .length > 1
                    ? 'This email is already used!'
                    : ''
                }
              />
            </div>
          </div>
          <div className='flex flex-col md:flex-row'>
            <div className='flex-1 md:mr-6 pl-2'>
              <h3 className='text-sm md:pl-2'>{'Their Role'}</h3>

              <CustomDropdown
                className='w-full min-w-b3 ml-0'
                value={speakers[i]?.member.member_sub_type || ''}
                onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
                  setSpeakerData(i, 'member_sub_type', ev.target.value);
                }}
                options={rolesDropdown}
              />
            </div>
            <div className='flex-1 md:mr-4 ml-2'>
              <h3 className='text-sm md:pl-2 '>{'The Event'}</h3>

              <CustomDropdown
                className='w-full min-w-b3 ml-0'
                value={speakers[i]?.event || ''}
                onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
                  setSpeakerData(i, 'event', ev.target.value);
                }}
                options={events}
              />
            </div>
            <div className='flex-1 mr-2'>
              <h3 className='text-sm pl-2'>{'Allotted Time'}</h3>
              <CustomTextInput
                onChange={(e: { target: { value: string } }) =>
                  setSpeakerData(i, 'alloted_time', e.target.value)
                }
                value={speakers[i]?.alloted_time || ''}
                type='number'
              />
            </div>
          </div>
          <div className='flex flex-col mr-2'>
            <h3 className='text-sm pl-2'>
              {'Is there anything you’d like them to focus on or not mention?'}
            </h3>
            <CustomTextInput
              onChange={(e: { target: { value: string } }) =>
                setSpeakerData(i, 'speech_note', e.target.value)
              }
              value={speakers[i]?.speech_note || ''}
            />
          </div>
        </Fragment>
      );
    }

    return fields;
  };

  const onSkipClick = () => {
    setSpeakers([]);
    setNumberOfFields(1);
    void onNextClick();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='md:min-w-sm'>
        <>
          <h3 className='font-nanum text-2xl'>{title}</h3>

          {createFields()}
          <div className='w-full flex flex-row items-end justify-end mt-3'>
            <div className='hidden md:block min-w-3xs' />
            <AddOfficiantButton
              onClick={() => setNumberOfFields(numberOfFields + 1)}
              text='Add another speaker'
            />
          </div>
          <div className='mt-12 flex flex-row justify-center items-center'>
            {!isSubmit && (
              <Button
                className='text-forest-primary bg-white mr-4'
                title='BACK'
                onClick={onBackClick}
              />
            )}
            <Button
              className='bg-forest-primary text-white'
              title={isSubmit ? 'SUBMIT' : 'COMPLETE'}
              onClick={onNextClick}
              disabled={!areEmailsValid()}
            />
          </div>
          {!isSubmit && (
            <div
              className='font-inter text-forest-500 flex-1 min-w-full text-center pt-4 cursor-pointer'
              onClick={onSkipClick}
            >
              Skip for now
            </div>
          )}
        </>
      </div>
    </Modal>
  );
};
