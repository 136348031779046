import GiftCardLayout from './Layout/Layout';

import { sizeHeight } from '@mui/system';
import {
  FormikProps,
  useFormik,
  Field,
  FormikErrors,
  getIn,
  Form,
  Formik,
} from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Giftcard, saveGiftcard } from '../../api/giftcards';
import {
  NewPaymentBody,
  OrderType,
  PayOrderBody,
  PayOrderResponse,
  PaymentMethod,
  newpaymentSession,
  placeNewOrder,
} from '../../api/paywall';
import Loader from '../../components/Loader/loader';
import Button from '../../components/v2/Button/Button';
import { Typography } from '../../components/v2/Typography/Typography';
import { getAPIErrorMessage } from '../../helpers/helper';
import useLoader from '../../hooks/useLoader';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useAuth } from '../../provider/authProvider';
import { useSnackbar } from '../../provider/snackbarProvider';
import { CustomFormikInput } from '../Modules/friendsAndFamily/CustomFormikInput';
import { CustomFormikRadioButton } from '../Modules/friendsAndFamily/CustomFormikRadioButton';
import { CustomFormikTextArea } from '../Modules/friendsAndFamily/CustomFormikTextArea';

import './GiftcardSend.scss';

import PreviewEmail from '../../components/v2/PreviewEmail/PreviewEmail';

import BundleIncludes, { IncludeOptions } from './Layout/BundleIncludes';
import GiftcardImg from './Layout/GiftCardImg';
import TestimonialDesktopImage from './Layout/images/testimonial-desktop.png';
import TestimonialMobileImage from './Layout/images/testimonial-mobile.png';

interface LocationState {
  email: string;
}

interface FormValues {
  gift: string;
  senderName: string;
  senderEmail: string;
  recipientName: string;
  recipientEmail: string;
  customMessage: string;
}

interface Gift {
  giftName: string;
  giftCode: string;
  giftPrice: string;
  giftIncludes: IncludeOptions[];
}

const GiftcardSend = () => {
  const [showWhatIncludes, setShowWhatIncludes] = useState<boolean>(false);
  const [selectedGiftCode, setSelectedGiftCode] = useState('digital_bundle');

  const size = useWindowSize();
  const { isShowing, showLoader, hideLoader } = useLoader();
  const { openSnackBar } = useSnackbar();

  const initialValues = {
    gift: selectedGiftCode,
    senderName: '',
    senderEmail: '',
    recipientName: '',
    recipientEmail: '',
    customMessage: '',
  };

  /* Add more gift options here */
  const gifts: Gift[] = [
    {
      giftName: 'Premium Bundle',
      giftCode: 'digital_bundle',
      giftPrice: '69.99',
      giftIncludes: [
        {
          title: 'Ceremony Builder',
          elevio: 'ceremony-benefit',
        },
        {
          title: 'Vow Builder',
          elevio: 'vow-benefit',
        },
        {
          title: 'Toast Builder',
          elevio: 'toast-benefit',
        },
        {
          title: 'Personalized Message',
        },
      ],
    },
  ];

  const selectedGift = gifts.find((gift) => gift.giftCode == selectedGiftCode);
  const bundleIncludes = selectedGift?.giftIncludes;

  useEffect(() => {
    document.title = 'Provenance App – Send Gift Card';
  });

  const SignInSchema = Yup.object().shape({
    senderEmail: Yup.string()
      .email('Email must be valid')
      .required('Email is required'),
    recipientEmail: Yup.string()
      .email('Email must be valid')
      .required('Email is required'),
    senderName: Yup.string().required("Sender's name is required"),
    recipientName: Yup.string().required("Recipient's name is required"),
    gift: Yup.string().required('You need to select one of the gift options'),
  });

  const placeOrder = async (values: FormValues) => {
    showLoader();
    try {
      const reqBody: PayOrderBody = {
        payment_method: PaymentMethod.card,
        type: OrderType.ceremony_designer,
        value: 69.99,
        currency: 'USD',
        is_giftcard: true,
      };

      const orderResponse: PayOrderResponse = await placeNewOrder(reqBody);
      if (orderResponse.success) {
        const giftcard: Giftcard = {
          from_email: values.senderEmail,
          from_name: values.senderName,
          to_email: values.recipientEmail,
          to_name: values.recipientName,
          custom_message: values.customMessage,
          order_id: orderResponse.data.order_id,
          redeemed: false,
        };
        const giftcardResponse = await saveGiftcard(giftcard);
        if (giftcardResponse.success) {
          await createPaymentSession(orderResponse.data.ref);
        } else {
          openSnackBar(orderResponse.message);
        }
      } else {
        openSnackBar(orderResponse.message);
        // navigate(`/pay-status/${params.ceremonyid ?? ''}`, {
        //   state: {
        //     plan: plan,
        //     payee: payee,
        //     payeeDetail: payeeDetail,
        //     success: false,
        //   },
        // });
      }
    } catch (err) {
      openSnackBar(getAPIErrorMessage(err as any));
    } finally {
      hideLoader();
    }
  };

  const createPaymentSession = async (ref: string) => {
    try {
      const payBody: NewPaymentBody = {
        ref: ref,
        currency: 'USD',
        isGiftcard: true,
      };
      const sessionResponse: PayOrderResponse = await newpaymentSession(
        payBody
      );
      if (sessionResponse.success) {
        window.location.href = sessionResponse.message;
        return;
      } else {
        openSnackBar(sessionResponse.message);
        // navigate(`/pay-status/${params.ceremonyid ?? ''}`, {
        //   state: {
        //     plan: plan,
        //     payee: payee,
        //     payeeDetail: payeeDetail,
        //     success: false,
        //   },
        // });
      }
    } catch (err) {
      openSnackBar(getAPIErrorMessage(err as any));
    } finally {
      hideLoader();
    }
  };

  const submitForm = (values: FormValues) => {
    void placeOrder(values);
  };

  const ErrorMessage = (props: { errorName: string; className?: string }) => (
    <Field
      name={props.errorName}
      render={(arg: { form: any }) => {
        const error = getIn(arg.form.errors, props.errorName);
        const touch = getIn(arg.form.touched, props.errorName);
        const className = props.className
          ? 'error-message ' + props.className
          : 'error-message';
        return touch && error ? <p className={className}>{error}</p> : null;
      }}
    />
  );

  const getExtraPhoneContent = () => {
    const buttonText = showWhatIncludes
      ? "Hide what's included"
      : "Show what's included";
    return (
      <div className='mb-5'>
        <GiftcardImg size='lg' preview={true} />
        <Button
          className='w-full display-block m-auto'
          size='100'
          variant='text'
          onClick={() => {
            setShowWhatIncludes(!showWhatIncludes);
          }}
        >
          <p>{buttonText}</p>
        </Button>

        {showWhatIncludes ? (
          <>
            <Typography variant='primary' type='body-400' className='mt-3'>
              Premium Bundle Includes:
            </Typography>
            <BundleIncludes includes={bundleIncludes} />
          </>
        ) : null}
        <img
          src={
            size.width < 1024 ? TestimonialMobileImage : TestimonialDesktopImage
          }
          className='mt-5'
        />
      </div>
    );
  };

  const getOptionLabel = (gift: Gift) => {
    return (
      <div className='w-full flex justify-between'>
        <span>{gift.giftName}</span>
        <span>${gift.giftPrice.toString()}</span>
      </div>
    );
  };

  const getContent = () => {
    return (
      <div className='flex justify-center max-w-[454px] mx-auto'>
        <Formik
          validateOnMount={true}
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={initialValues}
          onSubmit={(values) => {
            void submitForm(values);
          }}
          validationSchema={SignInSchema}
        >
          {({ values, isValid }: { values: FormValues; isValid: boolean }) => (
            <Form
              className='mt-6'
              style={{
                width: '100%',
                padding: '0 1rem',
              }}
            >
              <Typography variant='primary' type='display-600'>
                Send a Gift Card
              </Typography>

              <div className='input-container mb-5 mt-3'>
                <Field
                  name={`gift`}
                  simpleRadio={true}
                  row={true}
                  options={gifts.map((gift) => {
                    return {
                      label: getOptionLabel(gift),
                      value: gift.giftCode,
                    };
                  })}
                  component={CustomFormikRadioButton}
                  className={'w-full mx-0 h-fit'}
                  setChanged={(ev: any) => setSelectedGiftCode(ev.target.value)}
                  gap='gap-3'
                  ml='ml-0'
                />
                <ErrorMessage errorName={`email`} className={'rise'} />
              </div>

              {size.width < 1024 ? getExtraPhoneContent() : null}

              <Typography variant='primary' type='display-100' className='mt-2'>
                Who is it from?
              </Typography>

              <div className='input-container mt-3'>
                <Field
                  name={`senderName`}
                  placeholder='Your name'
                  type='text'
                  component={CustomFormikInput}
                  label='Name'
                  className={'w-full mx-0 h-fit'}
                />
                <ErrorMessage errorName={`senderName`} className={'rise'} />
              </div>
              <div className='input-container mt-2'>
                <Field
                  name={`senderEmail`}
                  placeholder='Your email address'
                  type='text'
                  component={CustomFormikInput}
                  label='Email'
                  className={'w-full mx-0 h-fit'}
                />
                <ErrorMessage errorName={`senderEmail`} className={'rise'} />
              </div>

              <Typography variant='primary' type='display-100' className='mt-3'>
                Who is it for?
              </Typography>

              <div className='input-container mt-3'>
                <Field
                  name={`recipientName`}
                  placeholder='Their first name'
                  type='text'
                  component={CustomFormikInput}
                  label='First Name'
                  className={'w-full mx-0 h-fit'}
                />
              </div>
              <div className='input-container mt-2'>
                <Field
                  name={`recipientEmail`}
                  placeholder='Their email address'
                  type='text'
                  component={CustomFormikInput}
                  label="Recipient's Email"
                  className={'w-full mx-0 h-fit'}
                />
              </div>
              <div className='input-container mt-2'>
                <Field
                  name={`customMessage`}
                  placeholder='Include a custom message if you wish...'
                  type='text'
                  component={CustomFormikTextArea}
                  label='Custom Message (Optional)'
                  className={'w-full mx-0'}
                />
                <PreviewEmail
                  text='See how the gift will look like to them'
                  type={'GIFTING'}
                />
              </div>

              <div className='my-5 set-bg'>
                <Button type='submit' className='my-2' disabled={!isValid}>
                  Proceed to payment
                </Button>
                <Typography type='body-100' variant='functional-low'>
                  <>
                    By purchasing a giftcard, you agree to Provenance’s
                    <Button
                      className='ml-1 mr-1'
                      size='100'
                      variant='text'
                      onClick={() => {
                        window.open(
                          'https://www.provenance.co/terms-of-service',
                          '_blank'
                        );
                      }}
                    >
                      <p>TOS</p>
                    </Button>
                    and
                    <Button
                      className='ml-1'
                      size='100'
                      variant='text'
                      onClick={() => {
                        window.open(
                          'https://www.provenance.co/privacy-policy',
                          '_blank'
                        );
                      }}
                    >
                      <p>Privacy policy</p>
                    </Button>
                    . Gift card is redeemable for Provenance's Premium Bundle
                    and unlocks full access to Provenance's suite of digital
                    tools. Gift card does not expire and is non-refundable.
                  </>
                </Typography>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <>
      <Loader isShowing={isShowing} hide={hideLoader} allowHide={false} />
      <GiftCardLayout
        content={getContent()}
        hideImageOnMobile={true}
        bundleIncludes={bundleIncludes}
      />
    </>
  );
};

export default GiftcardSend;
