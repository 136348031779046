import { useState } from 'react';

import checklistSvg from '../../../assets/images/svg/checklist.svg';
import CustomTextArea from '../../../components/forms/CustomTextArea';
import Button from '../../../components/v2/Button/Button';
import { Modal } from '../../../components/v2/Modal/Modal';
import { Typography } from '../../../components/v2/Typography/Typography';
import { useNPS } from '../../../provider/NPSProvider';


type FeedbackModalProps = {
  onCancelClick: () => void;
  onContinueClick: () => void;
};

export const FeedbackModal = (props: FeedbackModalProps) => {
  const { loadFeedback, feedback, setFeedback } = useNPS();
  const { onCancelClick, onContinueClick } = props;

  const submitResponse = () => {
    onContinueClick();
  };

  const handleChange = (e: any) => {
    setFeedback(e.target.value)
  }

  return (
    <Modal
      isOpen={loadFeedback}
      onClose={() => onCancelClick()}
      showCloseButton={true}
      maxWidth={'lg'}
    >
      <div className='self-center flex flex-col justify-center text-center '>
        <>
          <div className="text-copper-primary uppercase text-sm">
            While your script is downloading...
          </div>
          <Typography type='display-100' className='mb-4 mt-2'>
            What can we improve?
          </Typography>
          <div style={{minWidth: '50vw'}}>
            <CustomTextArea 
            value={feedback} 
            onChange={(e) =>handleChange(e)}
            placeholder='Additional feedback'
            >
            </CustomTextArea>
          </div>
          <div
            className={`flex flex-col-reverse md:flex-row justify-center`}
          >
            <Button
              className='mt-2 md:mt-0 md:mr-2 md:w-auto w-full'
              onClick={onCancelClick}
              variant='secondary'
            >
              Skip
            </Button>
            <Button className=' md:w-auto w-full' onClick={submitResponse}>
              Send Feedback
            </Button>
          </div>
        </>
      </div>
    </Modal>
  );
};
