/* eslint-disable */

import Button from '../../components/v2/Button/Button';
import { Typography } from '../../components/v2/Typography/Typography';
import { useWindowSize } from '../../hooks/useWindowSize';
import LanderItem from '../../components/LanderItem/LanderItem';

import background1Png from './bg1.png';
import background2Png from './bg2.png';
import background3Png from './bg3.png';
import background4Png from './bg4.png';
import icon1 from './icon1.png';
import icon2 from './icon2.png';
import icon3 from './icon3.png';
import icon4 from './icon4.png';

import { useDashboard } from '../../provider/dashboardProvider';

const SpeechwritingLander = () => {
  const size = useWindowSize();

  const {ceremony} = useDashboard();

  document.title = 'Speechwriting | Provenance';

  return (
    <div className=' flex flex-col items-center text-center gap-6 mt-10 lg:mt-31'>
    
      <div>
        <Typography type={size.isMobile ? 'display-600' : 'display-1000'}>
        Professional 
        </Typography>
        <Typography type={size.isMobile ? 'display-600' : 'display-1000'}>
        Services
        </Typography>
        <Typography type='body-600' className='max-w-xl mt-6'>
        Our roster of A+ professional speechwriters and public speaking coaches will give expert feedback and guidance on your vows, ceremony or welcome toast!
        </Typography>
      </div>

      <div className='w-full items-center justify-center flex flex-row gap-2 my-6 '>
        <a href={`/pay-merchandise/${ceremony?.id}/landing_page`}>
        <Button
          fullWidth={size.isMobile}
          
          variant='primary'
          className='vow-benefit-placeholder'
        >
          Get Pro Support
        </Button>
        </a>
       
      </div>
      <div className='w-full flex flex-col justify-center items-center md:flex-row gap-3 flex-wrap'>
        <LanderItem
          borderSvgSource={background1Png}
          iconSvgSource={icon1}
          text='Get matched with a Pro Speechwriter'
        />
        <LanderItem
          borderSvgSource={background2Png}
          iconSvgSource={icon2}
          text='Receive expert edits to improve your draft'
          shiftDown={size.isMobile ? false : true}
        />
        <LanderItem
          borderSvgSource={background3Png}
          iconSvgSource={icon3}
          text='Practice with a Public Speaking Coach'
        />
        <LanderItem
          borderSvgSource={background4Png}
          iconSvgSource={icon4}
          text='Speak with eloquence & confidence'
          shiftDown={size.isMobile ? false : true}
        />
      </div>
    </div>
  );
};

export default SpeechwritingLander;
