import { useState } from 'react';

import { CustomSwitch } from '../../forms/CustomSwitch';
import Button from '../Button/Button';
import { Modal } from '../Modal/Modal';
import { Typography } from '../Typography/Typography';

import checklistPng from './checklist.png';

type GenerationCompletedModalProps = {
  onCancelClick: () => void;
  onDownloadPDFClick: (includeVows?: boolean) => void;
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  title: string;
  description: string;
  showIncludeVowsToggle?: boolean;
};

export const GenerationCompletedModal = (
  props: GenerationCompletedModalProps
) => {
  const {
    onCancelClick,
    onDownloadPDFClick,
    modalOpen,
    setModalOpen,
    title,
    description,
    showIncludeVowsToggle,
  } = props;
  const [includeVows, setIncludeVows] = useState(true);

  return (
    <Modal
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      showCloseButton={true}
      title={''}
    >
      <div className='self-center max-w-md flex flex-col justify-center text-center '>
        <>
          <img className='self-center mb-6' src={checklistPng} />
          <Typography type='display-100'>{title}</Typography>
          <Typography type='body-400' className='mt-4'>
            {description}
          </Typography>
          {showIncludeVowsToggle && (
            <div className='mt-8 flex flex-row justify-center items-center'>
              <CustomSwitch
                checked={includeVows}
                onChange={() => setIncludeVows((old) => !old)}
              />
              <Typography type='body-400' className='ml-2'>
                Include vow content in the export
              </Typography>
            </div>
          )}
          <div
            className={`flex flex-col-reverse md:flex-row justify-center mt-8`}
          >
            <Button
              className='mt-2 md:mt-0 md:mr-2 md:w-auto w-full'
              onClick={onCancelClick}
              variant='secondary'
            >
              Exit without Download
            </Button>
            <Button
              className=' md:w-auto w-full'
              onClick={() => onDownloadPDFClick(includeVows)}
            >
              Download PDF
            </Button>
          </div>
        </>
      </div>
    </Modal>
  );
};
