import React, { useCallback, useContext } from 'react';

import { Module } from '../hooks/useActiveModule';
import { useRegenerationNeeded } from '../hooks/useRegenerationNeeded';

type CeremonyRegenerationNeededProps = {
  ceremonyDetailsOutputDataDate: Date | undefined;
  setCeremonyDetailsOutputDataDate: (date: Date) => void;
  ceremonyDetailsQuestionDate: Date | undefined;
  setCeremonyDetailsQuestionDate: (date: Date) => void;
  ceremonyDetailsRegenerationNeeded: boolean;
  readingsOutputDataDate: Date | undefined;
  setReadingsOutputDataDate: (date: Date) => void;
  readingsDetailsQuestionDate: Date | undefined;
  setReadingsDetailsQuestionDate: (date: Date) => void;
  readingsRegenerationNeeded: boolean;
  remarksOutputDataDate: Date | undefined;
  setRemarksOutputDataDate: (date: Date) => void;
  remarksDetailsQuestionDate: Date | undefined;
  setRemarksDetailsQuestionDate: (date: Date) => void;
  remarksRegenerationNeeded: boolean;
  declarationOutputDataDate: Date | undefined;
  setDeclarationOutputDataDate: (date: Date) => void;
  declarationDetailsQuestionDate: Date | undefined;
  setDeclarationDetailsQuestionDate: (date: Date) => void;
  declarationRegenerationNeeded: boolean;
  vowsCeremonyOutputDataDate: Date | undefined;
  setVowsCeremonyOutputDataDate: (date: Date) => void;
  vowsCeremonyDetailsQuestionDate: Date | undefined;
  setVowsCeremonyDetailsQuestionDate: (date: Date) => void;
  vowsCeremonyRegenerationNeeded: boolean;
  pronouncementOutputDataDate: Date | undefined;
  setPronouncementOutputDataDate: (date: Date) => void;
  pronouncementDetailsQuestionDate: Date | undefined;
  setPronouncementDetailsQuestionDate: (date: Date) => void;
  pronouncementRegenerationNeeded: boolean;
  setOutputDataDate: (module: Module, date: Date) => void;
  getOutputDate: (module: Module) => Date | undefined;
  setQuestionDate: (module: Module, date: Date) => void;
  getRegenerationNeeded: (module: Module) => boolean;
  resetAllDates: () => void;
};

export const CeremonyRegenerationNeededContext =
  React.createContext<CeremonyRegenerationNeededProps>({
    ceremonyDetailsOutputDataDate: new Date(),
    setCeremonyDetailsOutputDataDate: () => {},
    ceremonyDetailsQuestionDate: new Date(),
    setCeremonyDetailsQuestionDate: () => {},
    ceremonyDetailsRegenerationNeeded: false,
    readingsOutputDataDate: new Date(),
    setReadingsOutputDataDate: () => {},
    readingsDetailsQuestionDate: new Date(),
    setReadingsDetailsQuestionDate: () => {},
    readingsRegenerationNeeded: false,
    remarksOutputDataDate: new Date(),
    setRemarksOutputDataDate: () => {},
    remarksDetailsQuestionDate: new Date(),
    setRemarksDetailsQuestionDate: () => {},
    remarksRegenerationNeeded: false,
    declarationOutputDataDate: new Date(),
    setDeclarationOutputDataDate: () => {},
    declarationDetailsQuestionDate: new Date(),
    setDeclarationDetailsQuestionDate: () => {},
    declarationRegenerationNeeded: false,
    vowsCeremonyOutputDataDate: new Date(),
    setVowsCeremonyOutputDataDate: () => {},
    vowsCeremonyDetailsQuestionDate: new Date(),
    setVowsCeremonyDetailsQuestionDate: () => {},
    vowsCeremonyRegenerationNeeded: false,
    pronouncementOutputDataDate: new Date(),
    setPronouncementOutputDataDate: () => {},
    pronouncementDetailsQuestionDate: new Date(),
    setPronouncementDetailsQuestionDate: () => {},
    pronouncementRegenerationNeeded: false,
    setOutputDataDate: () => {},
    getOutputDate: () => new Date(),
    setQuestionDate: () => {},
    getRegenerationNeeded: () => false,
    resetAllDates: () => {},
  });

export const CeremonyRegenerationNeededProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    outputDataDate: ceremonyDetailsOutputDataDate,
    setOutputDataDate: setCeremonyDetailsOutputDataDate,
    questionDate: ceremonyDetailsQuestionDate,
    setQuestionDate: setCeremonyDetailsQuestionDate,
    regenerationNeeded: ceremonyDetailsRegenerationNeeded,
  } = useRegenerationNeeded();

  const {
    outputDataDate: readingsOutputDataDate,
    setOutputDataDate: setReadingsOutputDataDate,
    questionDate: readingsDetailsQuestionDate,
    setQuestionDate: setReadingsDetailsQuestionDate,
    regenerationNeeded: readingsRegenerationNeeded,
  } = useRegenerationNeeded();

  const {
    outputDataDate: remarksOutputDataDate,
    setOutputDataDate: setRemarksOutputDataDate,
    questionDate: remarksDetailsQuestionDate,
    setQuestionDate: setRemarksDetailsQuestionDate,
    regenerationNeeded: remarksRegenerationNeeded,
  } = useRegenerationNeeded();

  const {
    outputDataDate: declarationOutputDataDate,
    setOutputDataDate: setDeclarationOutputDataDate,
    questionDate: declarationDetailsQuestionDate,
    setQuestionDate: setDeclarationDetailsQuestionDate,
    regenerationNeeded: declarationRegenerationNeeded,
  } = useRegenerationNeeded();

  const {
    outputDataDate: vowsCeremonyOutputDataDate,
    setOutputDataDate: setVowsCeremonyOutputDataDate,
    questionDate: vowsCeremonyDetailsQuestionDate,
    setQuestionDate: setVowsCeremonyDetailsQuestionDate,
    regenerationNeeded: vowsCeremonyRegenerationNeeded,
  } = useRegenerationNeeded();

  const {
    outputDataDate: pronouncementOutputDataDate,
    setOutputDataDate: setPronouncementOutputDataDate,
    questionDate: pronouncementDetailsQuestionDate,
    setQuestionDate: setPronouncementDetailsQuestionDate,
    regenerationNeeded: pronouncementRegenerationNeeded,
  } = useRegenerationNeeded();

  const setOutputDataDate = (module: Module, date: Date) => {
    switch (module) {
      case Module.DirectionalQuestions:
        setCeremonyDetailsOutputDataDate(date);
        break;
      case Module.OfficiantRemarks:
        setRemarksOutputDataDate(date);
        break;
      case Module.RitualsAndReadings:
        setReadingsOutputDataDate(date);
        break;
      case Module.VowsCeremony:
        setVowsCeremonyOutputDataDate(date);
        break;
      case Module.DeclarationOfIntent:
        setDeclarationOutputDataDate(date);
        break;
      case Module.PronouncementCelebration:
        setPronouncementOutputDataDate(date);
        break;
      default:
        break;
    }
  };

  const getOutputDate = (module: Module) => {
    switch (module) {
      case Module.DirectionalQuestions:
        return ceremonyDetailsOutputDataDate;
      case Module.OfficiantRemarks:
        return remarksOutputDataDate;
      case Module.RitualsAndReadings:
        return readingsOutputDataDate;
      case Module.VowsCeremony:
        return vowsCeremonyOutputDataDate;
      case Module.DeclarationOfIntent:
        return declarationOutputDataDate;
      case Module.PronouncementCelebration:
        return pronouncementOutputDataDate;
      default:
        return new Date();
    }
  };

  const setQuestionDate = (module: Module, date: Date) => {
    switch (module) {
      case Module.DirectionalQuestions:
        setCeremonyDetailsQuestionDate(date);
        break;
      case Module.OfficiantRemarks:
        setRemarksDetailsQuestionDate(date);
        break;
      case Module.RitualsAndReadings:
        setReadingsDetailsQuestionDate(date);
        break;
      case Module.VowsCeremony:
        setVowsCeremonyDetailsQuestionDate(date);
        break;
      case Module.DeclarationOfIntent:
        setDeclarationDetailsQuestionDate(date);
        break;
      case Module.PronouncementCelebration:
        setPronouncementDetailsQuestionDate(date);
        break;
      default:
        break;
    }
  };

  const getRegenerationNeeded = (module: Module) => {
    switch (module) {
      case Module.DirectionalQuestions:
        return ceremonyDetailsRegenerationNeeded;
      case Module.OfficiantRemarks:
        return remarksRegenerationNeeded;
      case Module.RitualsAndReadings:
        return readingsRegenerationNeeded;
      case Module.VowsCeremony:
        return vowsCeremonyRegenerationNeeded;
      case Module.DeclarationOfIntent:
        return declarationRegenerationNeeded;
      case Module.PronouncementCelebration:
        return pronouncementRegenerationNeeded;
      default:
        return false;
    }
  };

  const resetAllDates = () => {
    setCeremonyDetailsOutputDataDate(undefined);
    setCeremonyDetailsQuestionDate(undefined);
    setReadingsOutputDataDate(undefined);
    setReadingsDetailsQuestionDate(undefined);
    setRemarksOutputDataDate(undefined);
    setRemarksDetailsQuestionDate(undefined);
    setDeclarationOutputDataDate(undefined);
    setDeclarationDetailsQuestionDate(undefined);
    setVowsCeremonyOutputDataDate(undefined);
    setVowsCeremonyDetailsQuestionDate(undefined);
    setPronouncementOutputDataDate(undefined);
    setPronouncementDetailsQuestionDate(undefined);
  };

  const value = {
    ceremonyDetailsOutputDataDate,
    setCeremonyDetailsOutputDataDate,
    ceremonyDetailsQuestionDate,
    setCeremonyDetailsQuestionDate,
    ceremonyDetailsRegenerationNeeded,
    readingsOutputDataDate,
    setReadingsOutputDataDate,
    readingsDetailsQuestionDate,
    setReadingsDetailsQuestionDate,
    readingsRegenerationNeeded,
    remarksOutputDataDate,
    setRemarksOutputDataDate,
    remarksDetailsQuestionDate,
    setRemarksDetailsQuestionDate,
    remarksRegenerationNeeded,
    declarationOutputDataDate,
    setDeclarationOutputDataDate,
    declarationDetailsQuestionDate,
    setDeclarationDetailsQuestionDate,
    declarationRegenerationNeeded,
    vowsCeremonyOutputDataDate,
    setVowsCeremonyOutputDataDate,
    vowsCeremonyDetailsQuestionDate,
    setVowsCeremonyDetailsQuestionDate,
    vowsCeremonyRegenerationNeeded,
    pronouncementOutputDataDate,
    setPronouncementOutputDataDate,
    pronouncementDetailsQuestionDate,
    setPronouncementDetailsQuestionDate,
    pronouncementRegenerationNeeded,
    setOutputDataDate,
    getOutputDate,
    setQuestionDate,
    getRegenerationNeeded,
    resetAllDates,
  };
  return (
    <CeremonyRegenerationNeededContext.Provider value={value}>
      {children}
    </CeremonyRegenerationNeededContext.Provider>
  );
};

export const useCeremonyRegenerationNeeded = () => {
  return useContext(CeremonyRegenerationNeededContext);
};
