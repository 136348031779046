import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  appToolOptions,
  NewPaymentBody,
  OrderType,
  PayOrderBody,
  PayOrderResponse,
  PaymentMethod,
  newpaymentSession,
  placeNewOrder,
} from '../../api/paywall';
import arrowLeftSvg from '../../assets/images/icons/arrow-left-circle.svg';
import speechwritingImg from '../../assets/images/image/drawing_pen.png';
import drawingSpeaker from '../../assets/images/image/drawing_speaker.png';
import Quotes from '../../assets/images/svg/quotes.svg';
import TestimonialImg from '../../assets/images/svg/testimonialImg.svg';
import Container from '../../components/Container';
import Loader from '../../components/Loader/loader';
import SnackbarComponent from '../../components/Snackbar/Snackbar';
import { Title } from '../../components/titles';
import Button from '../../components/v2/Button/Button';
import CampaignItem from '../../components/v2/CampaignItem/CampaignItem';
import { Modal } from '../../components/v2/Modal/Modal';
import { Review } from '../../components/v2/Review/Review';
import { Typography } from '../../components/v2/Typography/Typography';
import { sendCustomerIoEventHandler } from '../../helpers/customerIoHelper';
import { getAPIErrorMessage } from '../../helpers/helper';
import useLoader from '../../hooks/useLoader';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useDashboard } from '../../provider/dashboardProvider';
import {
  MerchandiseProduct,
  useMerchandise,
} from '../../provider/merchandisingProvider';
import { PayPlansList } from '../paywall/PayPlans';

const PaywallMerchandising = () => {
  const { setAppTool } = useMerchandise();
  const { appTool, ceremonyId } = useParams();
  const navigate = useNavigate();
  const { isShowing, hideLoader, showLoader } = useLoader();

  const { isActive, message, type, handleClose, openSnackBar } = useSnackbar();

  const [openModal, setOpenModal] = React.useState(false);

  const { products, source } = useMerchandise();

  const { ceremony, fetchCeremony } = useDashboard();

  function isappToolOptions(value: string): value is appToolOptions {
    return Object.values<string>(appToolOptions).includes(value);
  }

  useEffect(() => {
    if (!ceremony && ceremonyId) fetchCeremony(ceremonyId);

    if (appTool && isappToolOptions(appTool)) setAppTool(appTool);

    void sendCustomerIoEventHandler('Professional Service Paywall View', {
      speech_name: appTool,
    });
  }, []);

  const getButton = (product: MerchandiseProduct) => {
    const { status } = product;
    let text = '';
    let disabled = false;
    switch (status) {
      case 'purchased':
        text = 'Purchase again';
        break;
      default: //availabe
        text = 'Select';
        break;
    }

    if (product.isWeddingTooSoon()) {
      disabled = true;
      
    }

    return (
      <Button
        className='mt-3 w-full md:w-auto'
        onClick={() => {
          if (disabled) {
            setOpenModal(true);
          } else {
            proceedToPayWall(product);
          }
        }}
      >
        {text}
      </Button>
    );
  };

  const proceedToPayWall = async (selectedMerch: MerchandiseProduct) => {
    try {
      showLoader();
      if (selectedMerch === null) return;
      if (!appTool || !isappToolOptions(appTool)) return;

      void sendCustomerIoEventHandler('Professional Service Purchase Started', {
        service_name: selectedMerch.paywall_title,
        service_value: selectedMerch.price,
        speech_name: appTool,
      });

      const reqBody: PayOrderBody = {
        payment_method: PaymentMethod.card,
        type: OrderType.merchandising,
        value: selectedMerch.price,
        currency: 'USD',
        ceremony_id: ceremonyId,
        product_id: selectedMerch.id,
        app_tool: appTool,
        source: source,
      };

      const orderResponse: PayOrderResponse = await placeNewOrder(reqBody);
      if (orderResponse.success) {
        await createPaymentSession(orderResponse.data.ref);
      } else {
        openSnackBar(orderResponse.message);
      }
    } catch (err) {
      openSnackBar(getAPIErrorMessage(err as any));
    } finally {
      hideLoader();
    }
  };

  const createPaymentSession = async (ref: string) => {
    try {
      const payBody: NewPaymentBody = {
        ref: ref,
        currency: 'USD',
      };
      const sessionResponse: PayOrderResponse = await newpaymentSession(
        payBody
      );
      if (sessionResponse.success) {
        window.location.href = sessionResponse.message;
        return;
      } else {
        openSnackBar(sessionResponse.message);
      }
    } catch (err) {
      openSnackBar(getAPIErrorMessage(err as any));
    } finally {
      hideLoader();
    }
  };

  const getImgSrc = (productName: string) => {
    switch (productName) {
      case 'speechwriting':
        return speechwritingImg;
      case 'speechwriting+public_speaking':
        return drawingSpeaker;
      default:
        return speechwritingImg;
    }
  };

  return (
    <div
      className={`bg-cosmicLatte-primary w-full text-forest-primary min-h-4/5 flex flex-col space-between items-center overflow-x-hidden absolute top-0 z-10 min-h-full`}
    >
      <CampaignItem
        regularComponent={<></>}
        renderCampaignComponent={(discountPercentage, couponCode) => (
          <div className='w-full px-1 md:px-2 py-1 md:py-2 text-center bg-copper-primary text-white mb-2 cursor-pointer underline-offset-4'>
            {`GET ${discountPercentage}% OFF | USE CODE: `}
            <u>{couponCode}</u>
            {` | OFFER ENDS SOON!`}
          </div>
        )}
      />
      <div className='flex flex-row my-2 lg:my-4 self-start justify-between cursor-pointer px-4 lg:px-11 w-full'>
        <div
          className='flex flex-row grow align-center items-center'
          onClick={() => navigate(-1)}
        >
          <img alt='Go to dashboard' width={32} src={arrowLeftSvg} />
          <div className='ml-2'>Back</div>
        </div>

        <div className='flex flex-row self-end'></div>
      </div>

      <Loader isShowing={isShowing} hide={hideLoader} allowHide={false} />
      <SnackbarComponent
        isActive={isActive}
        message={message}
        type={type}
        handleClose={handleClose}
      />

      <Title
        className='text-forest-primary font-recife font-normal'
        h={1}
        text='Select a Service'
      />

      <Container className='w-full grid-flow-col content-between justify-center mt-4 auto-rows-fr lg:px-0'>
        <div className='self-center col-span-4 md:col-span-6 lg:col-span-12 w-full flex flex-row flex-wrap justify-center pb-6'>
          {products.map(
            (
              product: MerchandiseProduct,
              index: number,
              array: MerchandiseProduct[]
            ) => {
              return (
                <div
                  className={`w-full max-w-[450px] lg:min-w-[450px] px-2 mt-3`}
                  key={product.id}
                >
                  <div
                    className={`border-forest-primary border flex flex-col justify-between px-6 py-6 items-center h-full max-w-3xl mx-auto relative rounded-2xl`}
                  >
                    <div className='flex flex-col items-center w-full'>
                      <div className='flex flex-row w-full'>
                        <div className='flex-auto w-[80%]'>
                          <h3 className='font-recife font-normal leading-8 w-full text-left text-2xl'>
                            {product.paywall_title}
                          </h3>
                          <CampaignItem
                            regularComponent={
                              <>
                                <h1 className='font-recife inline font-normal text-3xl text-left w-full'>
                                  {`$${product.price}`}
                                </h1>

                                {product.original_price && (
                                  <h1 className='ml-2 inline font-recife font-normal text-xl text-left w-full line-through text-copper-primary'>
                                    {`$${product.original_price}`}
                                  </h1>
                                )}
                              </>
                            }
                            renderCampaignComponent={(discountPercentage) => (
                              <>
                                <h1 className='inline font-recife font-normal text-3xl text-left w-full'>
                                  {`$${(
                                    (product.price *
                                      (100 - discountPercentage)) /
                                    100
                                  ).toFixed(2)}`}
                                </h1>
                                <h1 className='ml-2 inline font-recife font-normal text-xl text-left w-full line-through text-copper-primary'>
                                  {`${product.price}`}
                                </h1>
                                <div className='flex flex-row justify-start items-center'>
                                  <div
                                    className={
                                      'bg-copper-primary text-white font-grotesk text-sm px-3 py-1 rounded-md'
                                    }
                                  >
                                    {`Save ${discountPercentage}%`}
                                  </div>
                                  <Typography className='ml-2'>
                                    Offer Ends Soon!
                                  </Typography>
                                </div>
                              </>
                            )}
                          />
                          <Typography
                            className='text-forest-300 text-sm font-recife'
                            variant='functional-low'
                          >
                            one-time fee
                          </Typography>
                        </div>
                        <div className='flex-auto text-right'>
                          <img
                            className='inline-block'
                            src={getImgSrc(product.name)}
                            alt='plan icon'
                          />
                        </div>
                      </div>
                      <div className='w-full flex flex-col pt-2'>
                        <p className='self-center font-recife text-sm text-forest-primary mb-2'>
                          {product.paywall_description || product.description}
                        </p>
                        <div
                          className={`grid ${
                            array.length === 1
                              ? 'grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2'
                              : 'grid-cols-1'
                          } w-full max-w-xl self-center`}
                        >
                          {product.paywall_attributes.map((feature, index) => {
                            return (
                              <PayPlansList
                                key={index}
                                title={feature}
                                className='text-sm'
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col gap-2 w-full'>
                      {getButton(product)}
                    </div>
                  </div>
                </div>
              );
            }
          )}

          <div className='w-full'>
            <Typography
              type='body-200'
              variant='functional-low'
              className='md:w-[50%] mt-6 text-center text-forest-primary text-sm mx-auto'
            >
              Draft & Survey must be submitted at least 5 business days (for editing) or 10 business days (for coaching), prior to speech date. Email speechwriting@provenance.co if you require a quicker turnaround (rush fees apply). 
            </Typography>
          </div>
        </div>
      </Container>

      <div className='flex flex-col px-24 max-w-[760px] my-6'>
        <Review
          stars={5}
          review="While I'm an incredibly extroverted person, giving speeches has never been my thing, so when I was asked to give a toast at a 200-person wedding, I went to Provenance for help [...] I think it went pretty well judging by the two moms who came up to me after asking if I'd meet their daughters."
          author='NELSON E'
          authorTitle='GROOMSMAN'
          date='SEPT 2022'
          className='mb-8'
        />

        <Review
          stars={5}
          review="I worked with one of Provenance's professional speechwriters to help craft the ceremony and officiants remarks for my best friend’s wedding. The whole thing was a home run. Working with Provenance also took a lot of stress off of me - and the couple - given their incredible attention to detail. They’ve got all your bases covered throughout the process."
          author='DENNIS S'
          authorTitle='OFFICIANT+GROOMSMAN'
          date='APR 2023'
          className='mb-8'
        />

        <Review
          stars={5}
          review='Provenance provided a service of excellence that took my voice and polished the vision to personalized perfection. I stood before my soon-to-be husband effortlessly expressing my heart. Our officiant said it was an act to follow...except we both utilized Provenance - it was terrific!'
          author='TAMARA C'
          authorTitle='BRIDE'
          date='APR 2023'
        />
      </div>
      <Modal
        maxWidth='sm'
        isOpen={
          openModal
        }
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <div className='flex flex-col gap-4'>
          <Typography type='display-100' className='text-center'>
            Contact us for quick turnarounds
          </Typography>
          <Typography
            type='body-200'
            variant='functional-low'
            className='text-center text-forest-primary'
          >
            <>
              The wedding date you indicated requires too short of a turnaround
              time for our standard services. We can still help! Submit a rush 
              request to
              <a href='mailto:speechwriting@provenance.co'>
                <span className='border-b cursor-pointer border-dashed border-forest-primary'>
                  {' '}
                  speechwriting@provenance.co
                </span>
              </a>{' '}
            </>
          </Typography>
          <Button
            type='button'
            variant='primary'
            onClick={() => setOpenModal(false)}
          >
            Got It
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default PaywallMerchandising;
