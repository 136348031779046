import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { MemberType, newCeremony } from '../../../api/ceremony';
import { WeddingPlannerStatus } from '../../../api/weddingPlanner';
import DiamondSvg from '../../../components/CeremonyChapters/diamond.svg';
import BasicCard from '../../../components/v2/BasicCard/BasicCard';
import Button from '../../../components/v2/Button/Button';
import ButtonPill from '../../../components/v2/ButtonPill';
import InfoWarning from '../../../components/v2/InfoWarning/InfoWarning';
import { Modal } from '../../../components/v2/Modal/Modal';
import useUser from '../../../hooks/useUser';
import { useAuth } from '../../../provider/authProvider';
import { usePlanner } from '../../../provider/plannerProvider';

import { AddCeremonyCard } from './AddCeremonyCard';
import './AddCeremonyModal.scss';

import { Form, Formik, FieldArray } from 'formik';

import SubmitCeremoniesButton from './SubmitCeremoniesButton';

import { Typography } from '../../../components/v2/Typography/Typography';
import ShareableLink from '../../../components/v2/ShareableLink/ShareableLink';
import Space from '../../../components/v2/Space/Space';
import PreviewEmail from '../../../components/v2/PreviewEmail/PreviewEmail';

import { ClassNames } from '@emotion/react';
import classNames from 'classnames';

import { useChecklist } from '../../../provider/checklistProvider';
import { ChecklistItemIdentifier } from '../../../helpers/checklistHelpers/identifiers';

export type Ceremony = {
  couple1FirstName: string;
  couple1LastName: string;
  couple1MemberType?: MemberType;
  couple1Email: string;
  couple2FirstName: string;
  couple2LastName: string;
  couple2MemberType?: MemberType;
  couple2Email?: string;

  weddingDate: string;
  weddingDateUnknown: boolean;
  venue: string;
  city: string;
  state: string;
  wedding_place: string;
  venueUnknown: boolean;
};

interface FormValues {
  ceremonies: Ceremony[];
}

export type CeremonyUpdate = {
  keys: {
    field: string;
    coupleIndex: number | undefined;
    coupleField: string | undefined;
    index: number;
  };
  value: any;
};

const addCeremonyDataSchema = Yup.object().shape({
  ceremonies: Yup.array().of(
    Yup.object().shape({
      venue: Yup.string()
        .ensure()
        .when('venueUnknown', {
          is: false,
          then: Yup.string().required(
            'Venue is required or select not sure yet'
          ),
        }),
      weddingDate: Yup.string()
        .nullable()
        .ensure()
        .test(
          'future-wedding',
          (e) => `Please, select a future date or select not sure yet`,
          (value) => moment(value).isAfter(moment(new Date())) || !value
        )
        .when('weddingDateUnknown', {
          is: false,
          then: Yup.string().required(
            'Wedding date is required or select not sure yet'
          ),
        }),
      venueUnknown: Yup.boolean(),
      weddingDateUnknown: Yup.boolean(),

      couple1FirstName: Yup.string().required('First name is required'),
      couple1LastName: Yup.string().required('Last name is required'),
      couple1MemberType: Yup.string().required('Role is required'),
      couple1Email: Yup.string()
        .email('Email not valid')
        .required('Email is required'),
      couple2FirstName: Yup.string().required('First name is required'),
      couple2LastName: Yup.string().required('Last name is required'),
      couple2MemberType: Yup.string().required('Role is required'),
      couple2Email: Yup.string()
        .email('Email not valid')
        //.required('Email is required')
        .notOneOf([Yup.ref('couple1Email')], 'Emails should be different'),
    })
  ),
});

type AddCeremonyModalProps = {
  refetch: () => void;
  weddingPlannerStatus: WeddingPlannerStatus;
  fullScreen?: boolean;
};

export const AddCeremonyModal = (props: AddCeremonyModalProps) => {
  const { saveMemberChecklistItemUsingIdentifier } = useChecklist();

  const { refetch, fullScreen } = props;
  const emptyCeremony: Ceremony = {
    couple1FirstName: '',
    couple1LastName: '',
    couple1MemberType: undefined,
    couple1Email: '',
    couple2FirstName: '',
    couple2LastName: '',
    couple2MemberType: undefined,
    couple2Email: '',
    weddingDate: '',
    weddingDateUnknown: false,
    venue: '',
    city: '',
    state: '',
    wedding_place: '',
    venueUnknown: false,
  };
  const navigate = useNavigate();

  const { user } = useAuth();

  const {
    weddingPlannerStatus,
    showAddWedModal,
    setShowAddWedModal,
    setNumberOfCeremoniesBeingAdded,
    setNumberOfCeremoniesToPayFor,
    setCeremonyIdsOfCreatedCeremonies,
    addClientsClicked,
    setAddClientsClicked,
    handlePayPerCeremony,
  } = usePlanner();

  const { user: userThatContainsActiveCeremonies, getUserData } = useUser();

  const getNewEmptyCeremony = () => {
    return { ...emptyCeremony };
  };

  const [initialVals, setInitialVals] = useState<FormValues>({
    ceremonies: [getNewEmptyCeremony()],
  });

  const isLimitExceeded = (index: number) => {
    if (!weddingPlannerStatus) return index > 0;

    return (
      weddingPlannerStatus.activatedWeddings + index + 1 >
      weddingPlannerStatus.maxWeddings
    );
  };

  const addWeddings = async (values: FormValues) => {
    const ceremoniesCount = values.ceremonies.length;
    setNumberOfCeremoniesBeingAdded(ceremoniesCount);

    await getUserData();

    const ceremonyIds: string[] = [];
    let freeWeddingAdded = false;
    const ceremoniesToPayFor = [];

    try {
      await Promise.all(
        values.ceremonies.map(async (ceremony, index) => {
          const shouldWeddingBeActive = (index: number) => {
            //prev condition: weddingPlannerStatus?.hasActiveSubscription || userThatContainsActiveCeremonies?.activeCeremoniesCount === 0
            // we should check that even if the user has active Subscription, they don't create more ceremonies that their subscription allows
            let result = false;
            if (
              userThatContainsActiveCeremonies?.activeCeremoniesCount === 0 &&
              !freeWeddingAdded
            ) {
              freeWeddingAdded = true;
              result = true;
            } else if (
              weddingPlannerStatus?.hasActiveSubscription &&
              !isLimitExceeded(index)
            ) {
              result = true;
            }

            if (result === false) {
              ceremoniesToPayFor.push(ceremony);
            }
            return result;
          };
          const response = await newCeremony({
            wedding_place: ceremony.venue,
            state: ceremony.state,
            city: ceremony.city,
            venue: ceremony.wedding_place,
            date: ceremony.weddingDate ?? undefined,
            members: [
              {
                legal_name: user.first_name + ' ' + user.last_name,
                email: user.email,
                member_type: MemberType.weddingPlanner,
                preferred_name: user.first_name,
              },
              {
                legal_name:
                  ceremony.couple1FirstName + ' ' + ceremony.couple1LastName,
                email: ceremony.couple1Email,
                member_type: ceremony.couple1MemberType || MemberType.other,
                preferred_name: ceremony.couple1FirstName,
              },
              {
                legal_name:
                  ceremony.couple2FirstName + ' ' + ceremony.couple2LastName,
                email: ceremony.couple2Email || '',
                member_type: ceremony.couple2MemberType || MemberType.other,
                preferred_name: ceremony.couple2FirstName,
              },
            ],
            officiants: [],
            speakers: [],
            shouldActivate: shouldWeddingBeActive(index),
            weddingPlannerCreated: true,
          });
          if (response.success && response.cer_id && !response.activated) {
            ceremonyIds.push(response.cer_id);
          }
          return response;
        })
      );
    } catch (err) {
      console.log('error', err);
    }
    setCeremonyIdsOfCreatedCeremonies(ceremonyIds);
    setNumberOfCeremoniesToPayFor(ceremonyIds.length);

    try {
      await saveMemberChecklistItemUsingIdentifier(
        {
          completed: true,
        },
        ChecklistItemIdentifier.INVITE_FIRST_CLIENT,
        true
      );
    } catch (err) {
      console.log('error', err);
    }

    //If user clicks on Add Clients button, we should redirect to paywall because expected behaviour is to pay now ( even if the user has active subscription )
    if (addClientsClicked) {
      setShowAddWedModal(false);
      setAddClientsClicked(false);
      void handlePayPerCeremony(ceremonyIds);
      void refetch();
    }

    if (
      !weddingPlannerStatus?.hasActiveSubscription &&
      (userThatContainsActiveCeremonies?.activeCeremoniesCount !== 0 ||
        ceremoniesToPayFor.length > 0)
    ) {
      setShowAddWedModal(false);
      navigate('/wp-pay-plans/');
    } else {
      navigate('/ceremony/planner/client-list');
    }
    void refetch();
  };

  return (
    <Formik
      validateOnMount={true}
      initialValues={initialVals}
      onSubmit={async (values, { resetForm }) => {
        await addWeddings(values);
        resetForm({ values: { ceremonies: [getNewEmptyCeremony()] } });
      }}
      validationSchema={addCeremonyDataSchema}
    >
      {({ values }: { values: FormValues }) => (
        <Form>
          <Modal
            isOpen={showAddWedModal}
            title={
              <div className='flex lg:items-center gap-3 lg:flex-row flex-col'>
                <div>Manually add a wedding</div>
                <PreviewEmail
                  inline={true}
                  text='Preview invite email'
                  elevioId={93}
                />
              </div>
            }
            subtitle={''}
            onClose={() => setShowAddWedModal(false)}
            fullWidth={true}
            fillScreen={true}
            maxWidth={fullScreen ? false : 'xl'}
            renderFooter={<SubmitCeremoniesButton></SubmitCeremoniesButton>}
          >
            <>
              <FieldArray
                name='ceremonies'
                render={(ArrayHelpers) => (
                  <div className='newCeremoniesContainer mb-3'>
                    {values.ceremonies.length > 0 &&
                      values.ceremonies.map(
                        (ceremony: Ceremony, index: number) => (
                          <AddCeremonyCard
                            index={index}
                            key={index}
                            removable={values.ceremonies.length > 1}
                            deleteCard={() => {
                              ArrayHelpers.remove(index);
                            }}
                            values={ceremony}
                            limitExceeded={isLimitExceeded(index)}
                          />
                        )
                      )}
                    {weddingPlannerStatus && (
                      <div className='w-full justify-center flex'>
                        <ButtonPill
                          text='Add another...'
                          onClick={() =>
                            ArrayHelpers.push(getNewEmptyCeremony())
                          }
                        ></ButtonPill>
                      </div>
                    )}
                  </div>
                )}
              />
              <Space size={4} />
              <div className='flex gap-2'>
                <div className='flex flex-row justify-between items-center'>
                  <Typography type='display-100'>Or, Share a link</Typography>
                </div>
                {weddingPlannerStatus?.hasActiveSubscription && (
                  <InfoWarning
                    text='Send this to one partner for both of them to sign up'
                    variant='grey'
                  />
                )}
                {!weddingPlannerStatus?.hasActiveSubscription && (
                  <Button
                    size='100'
                    leftImgSrc={DiamondSvg}
                    onClick={() => {
                      setShowAddWedModal(false);
                      navigate('/wp-pay-plans/');
                    }}
                  >
                    Subscribe to Unlock
                  </Button>
                )}
              </div>
              <Space size={1} />
              <BasicCard className='px-6 py-6 gap-6 relative'>
                {!weddingPlannerStatus?.hasActiveSubscription && (
                  <div
                    className='z-50 flex flex-row absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] cursor-pointer'
                    onClick={() => {
                      setShowAddWedModal(false);
                      navigate('/wp-pay-plans/');
                    }}
                  >
                    <img
                      src={DiamondSvg}
                      width={24}
                      alt='diamond'
                      className='inline-block mr-2 invert-[0.6]'
                    />
                    <Typography
                      type='body-600'
                      variant='functional-low'
                      className='text-forest-700 cursor-pointer'
                    >
                      Subscribe to unlock this feature
                    </Typography>
                  </div>
                )}
                <div
                  className={classNames(
                    'flex flex-row justify-between items-center gap-2 w-full',
                    {
                      'blur-md': !weddingPlannerStatus?.hasActiveSubscription,
                    }
                  )}
                >
                  <div className='flex flex-col gap-2 max-w-[500px]'>
                    <Typography type='display-50'>
                      <>Copy the code and send</>
                    </Typography>

                    <Typography type='body-400'>
                      Instead of manually adding a wedding, send your couple
                      this unique link for them to get started and sign up with
                      ease.
                    </Typography>
                  </div>

                  <ShareableLink type='wedding_planner_link' />
                </div>
              </BasicCard>
            </>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
