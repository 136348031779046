import { FormikState } from 'formik';

import { Speaker } from '../../../api/friends';
import { Modal } from '../Modal/Modal';
import { Typography } from '../Typography/Typography';

import AddGuestSpeakerForm, {
  AddGuestSpeakerFormValues,
} from './AddGuestSpeakerForm';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onButtonClick: (speaker: Speaker) => Promise<void>;
  initialSpeaker: Speaker;
  title: string;
};

export const AddGuestSpeakerModal = (props: Props) => {
  const { isOpen, onClose, onButtonClick, title, initialSpeaker } = props;

  const handleClose = () => {
    onClose();
  };

  const handleButtonClick = (
    values: AddGuestSpeakerFormValues,
    resetForm: (
      nextState?: Partial<FormikState<AddGuestSpeakerFormValues>> | undefined
    ) => void
  ) => {
    const speaker = {
      member: {
        legal_name: values.legal_name,
        email: values.email,
        member_sub_type: values.member_sub_type,
      },
      event: values.event,
      alloted_time: values.alloted_time,
      speech_note: values.speech_note,
    } as Speaker;
    void onButtonClick(speaker);
    resetForm();
    handleClose();
  };

  const initialVals = {
    legal_name: initialSpeaker.member.legal_name,
    email: initialSpeaker.member.email,
    member_sub_type: initialSpeaker.member.member_sub_type,
    event: initialSpeaker.event,
    alloted_time: initialSpeaker.alloted_time,
    speech_note: initialSpeaker.speech_note,
  } as AddGuestSpeakerFormValues;

  //TODO - add validation

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className='md:min-w-sm'>
        <>
          <Typography className='mb-8' type='display-100'>
            {title}
          </Typography>

          <AddGuestSpeakerForm
            handleSave={handleButtonClick}
            handleClose={handleClose}
            initialVals={initialVals}
          />
        </>
      </div>
    </Modal>
  );
};
