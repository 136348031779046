import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  NewPaymentBody,
  NewPaymentRequestBody,
  newpaymentSession,
  OrderType,
  PaymentMethod,
  PayOption,
  PayOrderBody,
  PayOrderResponse,
  placeNewOrder,
  sendPaymentRequest,
} from '../../api/paywall';
import Container from '../../components/Container';
import Loader from '../../components/Loader/loader';
import SnackbarComponent from '../../components/Snackbar/Snackbar';
import Button from '../../components/button';

import { useEffect, useState } from 'react';

import CustomTextInput from '../../components/forms/CustomTextInput';
import { Title } from '../../components/titles';
import useLoader from '../../hooks/useLoader';
import { useSnackbar } from '../../hooks/useSnackbar';

import './index.scss';

import useCeremony from '../../hooks/useCeremony';
import { getAPIErrorMessage } from '../../helpers/helper';

type payeeDetailType = {
  name: string;
  email: string;
};

interface LocationState {
  plan: PayOption;
  payee: string;
  payeeDetail: payeeDetailType;
  purchaseType: 'u' | 'n';
  purchasePrice: number;
}

const PaySummary = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { plan, payee, payeeDetail, purchaseType, purchasePrice } =
    (location.state as LocationState) || {};
  const { isShowing, showLoader, hideLoader } = useLoader();
  const { isActive, message, type, openSnackBar, handleClose } = useSnackbar();
  const { fetchCeremonyPlanById, ceremonyPlan } = useCeremony();
  const [coupon, setCoupon] = useState<string>('');
  const [couponApplied, setCouponAplied] = useState<boolean>(false);

  const _handleCoupon = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCoupon(e.target.value);
  };

  useEffect(() => {
    if (!plan) {
      navigate(`/pay-plans/${params.ceremonyid || ''}`);
    } else {
      void fetchCeremonyPlanById(params.ceremonyid || '');
    }
  }, [plan]);

  useEffect(() => {
    if (purchaseType === 'n' && ceremonyPlan && ceremonyPlan.value > 0) {
      showLoader();
      openSnackBar(
        'Something went wrong in payment. Try contacting to provenance team.',
        5000,
        'error'
      );
      setTimeout(() => {
        navigate(`/pay-plans/${params.ceremonyid || ''}`);
        hideLoader();
      }, 3000);
    }
  }, [ceremonyPlan]);

  const placeOrder = async () => {
    showLoader();
    try {
      const reqBody: PayOrderBody = {
        ceremony_id: params.ceremonyid || '',
        payment_method: PaymentMethod.card,
        type: OrderType.ceremony_designer,
        product_id: plan.id,
        value: purchasePrice,
      };
      const orderResponse: PayOrderResponse = await placeNewOrder(reqBody);
      if (orderResponse.success) {
        if (payee === 'me') {
          if (purchasePrice > 0) {
            await createPaymentSession(orderResponse.data.ref);
          } else {
            navigate(`/pay-status/${params.ceremonyid ?? ''}`, {
              state: {
                plan: plan,
                payee: payee,
                payeeDetail: payeeDetail,
                success: true,
              },
            });
          }
        } else {
          // write email sending logic here
          const payReqBody: NewPaymentRequestBody = {
            ref: orderResponse.data.ref,
            payee: payee,
            payeeDetail: payeeDetail,
          };
          const payReqResponse = await sendPaymentRequest(payReqBody);
          if (payReqResponse.success) {
            navigate(`/pay-status/${params.ceremonyid ?? ''}`, {
              state: {
                plan: plan,
                payee: payee,
                payeeDetail: payeeDetail,
                success: true,
              },
            });
          } else {
            navigate(`/pay-status/${params.ceremonyid ?? ''}`, {
              state: {
                plan: plan,
                payee: payee,
                payeeDetail: payeeDetail,
                success: false,
              },
            });
          }
        }
      } else {
        openSnackBar(orderResponse.message, 5000, 'error');
        navigate(`/pay-status/${params.ceremonyid ?? ''}`, {
          state: {
            plan: plan,
            payee: payee,
            payeeDetail: payeeDetail,
            success: false,
          },
        });
      }
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      openSnackBar(getAPIErrorMessage(err as any), 5000, 'error');
    } finally {
      hideLoader();
    }
  };

  const createPaymentSession = async (ref: string) => {
    try {
      const payBody: NewPaymentBody = {
        pid: plan.id,
        ref: ref,
      };
      const sessionResponse: PayOrderResponse = await newpaymentSession(
        payBody
      );
      if (sessionResponse.success) {
        window.location.href = sessionResponse.message;
        return;
      } else {
        openSnackBar(sessionResponse.message, 5000, 'error');
        navigate(`/pay-status/${params.ceremonyid ?? ''}`, {
          state: {
            plan: plan,
            payee: payee,
            payeeDetail: payeeDetail,
            success: false,
          },
        });
      }
    } catch (err) {
      openSnackBar(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getAPIErrorMessage(err as any),
        5000,
        'error'
      );
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      {plan && (
        <div className='bg-containerbg min-h-4/5 flex flex-col space-between items-center justify-center overflow-x-hidden pb-10'>
          <Loader isShowing={isShowing} hide={hideLoader} allowHide={false} />
          <SnackbarComponent
            isActive={isActive}
            message={message}
            type={type}
            handleClose={handleClose}
          />
          <Title
            className='text-copper-primary font-sans uppercase mt-8'
            h={2}
            text='Ceremony Builder'
          />
          <Title
            className='text-forest-primary font-nanum font-bold'
            h={1}
            text='Summary'
          />
          <Container className='content-center place-content-center mt-4 auto-rows-fr'>
            <div className='hidden md:block  md:col-span-1 lg:col-span-3'></div>
            <div className='col-span-4 md:col-span-4 lg:col-span-6'>
              <div className='border border-forest-100 p-6 bg-white'>
                <div className='grid grid-cols-12 gap-x-10'>
                  <div className='col-span-3 font-inter text-forest-light text-sm py-2'>
                    Service:
                  </div>
                  <div className='col-span-6 font-nanum text-forest-primary text-[18px] py-2'>
                    Ceremony Builder - {plan.name}
                    {purchaseType === 'u' && (
                      <p className='font-inter text-forest-light text-sm'>
                        Plan is being upgraded
                      </p>
                    )}
                  </div>

                  <div
                    className={`col-span-3 text-copper-primary font-nanum text-[18px] font-bold text-right`}
                  >
                    $ {purchasePrice === 0 ? 'Free' : purchasePrice}
                  </div>
                  <div className='col-span-3 font-inter text-forest-light text-sm py-2'>
                    To be paid by:
                  </div>

                  <div className='col-span-9 font-nanum text-forest-primary text-[18px] py-2 flex flex-col'>
                    <p>
                      {payee === 'me'
                        ? 'You'
                        : payee === 'couple'
                        ? 'Couples'
                        : `${payeeDetail.name} (${payeeDetail.email})`}
                    </p>
                    {payee !== 'me' && (
                      <p className='font-inter text-forest-light text-sm'>
                        We will send a payment link to this email. You will get
                        access to the Ceremony Builder modules once the payment
                        goes through.
                      </p>
                    )}
                  </div>
                </div>
                <hr className='mt-6' />
                {/* <div className='flex flex-row w-full mt-6'>
              <CustomTextInput
                name='coupon'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  _handleCoupon(e)
                }
                placeholder={'Gift card or discount code'}
                type={'text'}
                disabled={couponApplied}
                value={coupon}
              />
              <button
                onClick={() => {
                  setCouponAplied(!couponApplied);
                }}
                className={`mt-2 px-2 md:px-3 lg:px-4 xl:px-6 2xl:px-8 
      py-2 md:py-2 lg:py-3 xl:py-4 2xl:py-4 w-60 h-14
      uppercase text-base flex justify-center cursor-pointer mx-3 text-white bg-forest-primary`}
              >
                {couponApplied ? 'Remove Code' : 'Apply Code'}
              </button>
            </div> */}
                <div className='grid grid-cols-9 mt-8 gap-x-10'>
                  <div className='col-span-3 font-inter text-forest-light text-sm py-2'>
                    Total:
                  </div>

                  <div
                    className={`col-span-6 text-copper-primary font-nanum text-[20px] font-bold text-right`}
                  >
                    $ {purchasePrice === 0 ? 'Free' : purchasePrice}
                  </div>
                  <div className='col-span-3 font-inter text-forest-light text-sm py-2'>
                    You are paying:
                  </div>

                  <div
                    className={`col-span-6 text-copper-primary font-nanum text-[20px] font-bold text-right`}
                  >
                    $ {payee === 'me' ? purchasePrice : 0}
                  </div>
                </div>
              </div>
            </div>
          </Container>
          <Button
            title={'Confirm'}
            onClick={async () => {
              await placeOrder();
            }}
            className={`mt-6 px-2 md:px-3 lg:px-4 xl:px-6 2xl:px-8 
            py-2 md:py-2 lg:py-3 xl:py-3 2xl:py-4 
            min-w-b1 md:min-w-b1 lg:min-w-b2 xl:min-w-b3 2xl:min-w-b4
            uppercase text-base flex justify-center cursor-pointer mx-3 text-white bg-forest-primary`}
          />
        </div>
      )}
    </>
  );
};

export default PaySummary;
