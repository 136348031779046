import { useParams } from 'react-router-dom';

import {
  SaveWaitlistQABody,
  WaitListEnrollment,
  WaitListQuestions,
} from '../../../api/waitlist';
import toastBuilderLandingPlaceholder from '../../../assets/images/image/placeholders/speechwritingwelcome.png';
import toastBuilderLanding from '../../../assets/images/image/speechwritingwelcome.png';
import { Header } from '../Header/Header';
import WaitlistQuestions from '../WaitlistQuestions';

type PreMaritalCounsellingProps = {
  waitlist?: WaitListEnrollment;
  waitlistQuestions: WaitListQuestions[];
  joinWaitList: () => Promise<void>;
  answerQuestions: () => Promise<void>;
  setQA: React.Dispatch<React.SetStateAction<SaveWaitlistQABody[]>>;
  QA: SaveWaitlistQABody[];
};

const PreMaritalCounselling = (props: PreMaritalCounsellingProps) => {
  const params = useParams();
  const {
    waitlist,
    waitlistQuestions,
    QA,
    joinWaitList,
    answerQuestions,
    setQA,
  } = props;

  return (
    <div className='min-h-screen'>
      <div className='container w-full max-w-7xl self-center mx-auto'>
        <Header
          title={'Premarital Counseling'}
          text={
            'Premarital Counseling provides the steps, advice and guidance to build your relationship on a solid foundation. Marriage preparation will help you and your partner navigate issues relating to marriage and prepare mentally and emotionally for life together. Traditionally provided by clergy, we are designing a modern Premarital Counseling offering that meets all couples where they are.  Sign up below, and we’ll let you know when we launch the service.'
          }
          imageSrc={toastBuilderLanding}
          waitlist_title='Premarital Counseling'
          imagePlaceholderSrc={toastBuilderLandingPlaceholder}
          ceremonyId={params?.ceremonyId}
          waitlist={waitlist}
          waitlistQuestions={waitlistQuestions}
          onJoinClick={joinWaitList}
        />
        {waitlistQuestions && (
          <WaitlistQuestions
            questions={waitlistQuestions}
            waitlist={waitlist}
            answerQuestions={answerQuestions}
            setQA={setQA}
            QA={QA}
          />
        )}
      </div>
    </div>
  );
};

export default PreMaritalCounselling;
