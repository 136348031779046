import React from 'react';

const GenericCard = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className='border border-forest-primary rounded-3xl p-6'>
      {children}
    </div>
  );
};

export default GenericCard;
