/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState } from 'react';

import { Culture, getCultures } from '../api/culture';

type CultureContextType = {
  cultures: Culture[];
  fetchCultures: () => Promise<void>;
};

export const CultureContext = React.createContext<CultureContextType>({
  cultures: [],
  fetchCultures: async () => {},
});

export const CultureProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [cultures, setCultures] = useState<Culture[]>([]);

  const fetchCultures = async () => {
    try {
      const response = await getCultures();
      if (response.success) {
        setCultures(response.data);
      }
    } catch (error) {}
  };

  const value = {
    cultures,
    fetchCultures,
  };
  // @ts-ignore
  return (
    <CultureContext.Provider value={value}>{children}</CultureContext.Provider>
  );
};

export const useCultures = () => {
  return useContext(CultureContext);
};
