import { Field, FormikErrors, FormikTouched, getIn, FormikProps } from 'formik';

import infoIconSvg from '../../../assets/images/svg/info.svg';
import { VenueField } from '../../../components/EventSettingsModal/components/VenueField';
import { WeddingDateField } from '../../../components/EventSettingsModal/components/WeddingDateField';
import { Typography } from '../../../components/v2/Typography/Typography';
import { CustomFormikInput } from '../../Modules/friendsAndFamily/CustomFormikInput';
import { CustomFormikSelect } from '../../Modules/friendsAndFamily/CustomFormikSelect';


import { Ceremony } from './AddCeremonyModal';
import './AddCeremonyCard.scss';

import { MemberType } from '../../../api/ceremony';

type AddCeremonyCardProps = {
  index: number;
  values: Ceremony;
  removable: boolean;
  deleteCard: () => void;
  limitExceeded?: boolean;
};

const ErrorMessage = (props: { errorName: string; className?: string }) => (
  <Field
    name={props.errorName}
    render={(arg: { form: any }) => {
      const error = getIn(arg.form.errors, props.errorName);
      const touch = getIn(arg.form.touched, props.errorName);
      const className = props.className
        ? 'error-message ' + props.className
        : 'error-message';
      return touch && error ? <p className={className}>{error}</p> : null;
    }}
  />
);

export const AddCeremonyCard = (props: AddCeremonyCardProps) => {
  const { index, removable, deleteCard, limitExceeded } = props;

  const options = [
    {
      label: 'Bride',
      value: MemberType.bride,
    },
    {
      label: 'Groom',
      value: MemberType.groom,
    },
    {
      label: 'Partner',
      value: MemberType.other,
    },
  ];
  
  return (
    <>
      <div className='main-container px-3 py-5 mb-3 border-solid border border-forest-100 rounded-3xl'>
        {removable ? (
          <div className='w-full mb-3 title-container'>
            <Typography type='display-50'>{`Wedding #${index + 1}`}</Typography>
            <p
              className='delete'
              onClick={(e) => {
                deleteCard();
              }}
            >
              Remove
            </p>
          </div>
        ) : null}

        <div className='input-container full-size-4'>
          <Field
            name={`ceremonies.${index}.couple1FirstName`}
            placeholder="Partner A's first name"
            type='text'
            component={CustomFormikInput}
            label='First name'
            className={'w-full mx-0 h-fit'}
          />
          <ErrorMessage
            errorName={`ceremonies.${index}.couple1FirstName`}
            className={'rise'}
          />
        </div>
        <div className='input-container full-size-4'>
          <Field
            name={`ceremonies.${index}.couple1LastName`}
            placeholder="Partner A's last name"
            type='text'
            component={CustomFormikInput}
            label='Last name'
            className={'w-full mx-0 h-fit'}
          />
          <ErrorMessage
            errorName={`ceremonies.${index}.couple1LastName`}
            className={'rise'}
          />
        </div>
        <div className='input-container full-size-4'>
          <Field
            name={`ceremonies.${index}.couple1MemberType`}
            placeholder='Select...'
            type='text'
            component={CustomFormikSelect}
            options={options}
            label='Role'
            className={'w-full mx-0 h-fit'}
          />
          <ErrorMessage
            errorName={`ceremonies.${index}.couple1MemberType`}
            className={'rise'}
          />
        </div>
        <div className='input-container full-size-4'>
          <Field
            name={`ceremonies.${index}.couple1Email`}
            placeholder="Partner A's email address"
            type='text'
            component={CustomFormikInput}
            label='Email'
            className={'w-full mx-0 h-fit'}
          />
          <ErrorMessage
            errorName={`ceremonies.${index}.couple1Email`}
            className={'rise'}
          />
        </div>
        <div className='input-container full-size-4'>
          <Field
            name={`ceremonies.${index}.couple2FirstName`}
            placeholder="Partner B's first Name"
            type='text'
            component={CustomFormikInput}
            label='First name'
            className={'w-full mx-0 h-fit'}
          />
          <ErrorMessage
            errorName={`ceremonies.${index}.couple2FirstName`}
            className={'rise'}
          />
        </div>
        <div className='input-container full-size-4'>
          <Field
            name={`ceremonies.${index}.couple2LastName`}
            placeholder="Partner B's last Name"
            type='text'
            component={CustomFormikInput}
            label='Last name'
            className={'w-full mx-0 h-fit'}
          />
          <ErrorMessage
            errorName={`ceremonies.${index}.couple2LastName`}
            className={'rise'}
          />
        </div>
        <div className='input-container full-size-4'>
          <Field
            name={`ceremonies.${index}.couple2MemberType`}
            placeholder='Select...'
            type='text'
            component={CustomFormikSelect}
            options={options}
            label='Role'
            className={'w-full mx-0 h-fit'}
          />
          <ErrorMessage
            errorName={`ceremonies.${index}.couple2MemberType`}
            className={'rise'}
          />
        </div>
        <div className='input-container full-size-4'>
          <Field
            name={`ceremonies.${index}.couple2Email`}
            placeholder="Partner B's email address"
            type='text'
            component={CustomFormikInput}
            label='Email (optional)'
            className={'w-full mx-0 h-fit'}
          />
          <ErrorMessage
            errorName={`ceremonies.${index}.couple2Email`}
            className={'rise'}
          />
        </div>

        <div className='input-container full-size-2'>
          <WeddingDateField
            namePrefix={`ceremonies.${index}`}
            index={index}
          />
          <ErrorMessage
            errorName={`ceremonies.${index}.weddingDate`}
            className={'mt-2'}
          />
          <ErrorMessage
            errorName={`ceremonies.${index}.weddingDateUnknown`}
            className={'mt-2'}
          />
        </div>
        <div className='input-container full-size-2'>
          <VenueField namePrefix={`ceremonies.${index}`} index={index} />
          <ErrorMessage
            errorName={`ceremonies.${index}.venue`}
            className={'rise'}
          />
          <ErrorMessage
            errorName={`ceremonies.${index}.venueUnknown`}
            className={'rise'}
          />
        </div>

        <button className='hidden' type='submit'></button>
      </div>
      {
        limitExceeded ? (
          <div className='card-info-message flex mx-3 pb-5 pt-8 px-3 bg-neutral-300 rounded-b-3xl'>
            <img className='w-5 mx-3' alt='info' src={infoIconSvg} />
            <Typography
              variant='functional-low'
              type='body-400'
            >
            You will be asked to subscribe or pay per client during the next step. If you would prefer the free trial (one free client) for now, please only add one client at this time.            </Typography>
          </div>
        ) : null
      }
    </>
  );
};
