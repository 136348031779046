import { Formik, Form, Field, FieldArray } from 'formik';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';

import { Ceremony, Member } from '../../../api/ceremony';
import {
  getReaders,
  Reader,
  ReaderResponse,
  saveReaders,
  SaveReadersBody,
} from '../../../api/readers';
import { Ritual } from '../../../api/ritual';
import trashcanSvg from '../../../assets/images/icons/trash-can.svg';
import CustomTextInput from '../../../components/forms/CustomTextInput';
import { Typography } from '../../../components/v2/Typography/Typography';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useReadings } from '../../../provider/readingsProvider';
import SimpleSelectOption from '../../ModulesV2/components/ModuleQuestion/QuestionTypes/QuestionSelect/SimpleSelectOption';
import {
  Question,
  QuestionAnswer,
} from '../common/moduleQuestion/ModuleQuestion';
import { CustomFormikInput } from '../friendsAndFamily/CustomFormikInput';

import AutoSave from './AutoSave';

type QuestionProps = {
  ritual: Ritual;
  question: Question;
  readingsQuestion: Question;
  setQuestionChanged: (value: boolean) => void;
  couple1?: Member;
  couple2?: Member;
  officiant?: Member;
  currentUser?: Member;
  ceremony?: Ceremony;
  showTip?: boolean;
};

interface FormValues {
  readers: Reader[];
}

export const ReadersQuestion = (props: QuestionProps) => {
  const { openSnackBar } = useSnackbar();
  const {
    selectedRitual,
    question,
    ceremony,
    saved,
    setSaved,
    handleSave: handleSaveAnswer,
  } = useReadings();

  const [textAnswer, setTextAnswer] = useState<string>();
  const [addReaders, setAddReaders] = useState<boolean>();

  const [initialVals, setInitialVals] = useState<FormValues>({
    readers: [],
  });

  useEffect(() => {
    if (question && question.answers) {
      const answer = question.answers[0];
      if (answer) {
        setTextAnswer(answer.text_answer);
      }
    }
  }, [question]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useCallback(
    debounce(
      (
        question: Question,
        answer: QuestionAnswer,
        value?: string,
        ceremony?: Ceremony
      ) => {
        if (ceremony) {
          void handleSaveAnswer(question, ceremony, {
            ...answer,
            text_answer: value,
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    if (
      question &&
      question.answers &&
      question.answers.length > 0 &&
      question.answers[0] &&
      question.answers[0].text_answer !== textAnswer &&
      saved === false
    ) {
      debouncedCall(question, question.answers[0], textAnswer, ceremony);
    }
  }, [ceremony, question, textAnswer, debouncedCall]);

  useEffect(() => {
    const fetchReaders = async () => {
      if (ceremony && selectedRitual) {
        try {
          const response = await getReaders(ceremony?.id.toString());
          if (response.success) {
            const filtered = response.data.filter(
              (r) =>
                r.ritual_id &&
                r.ritual_id.split(',').indexOf(selectedRitual.id.toString()) >=
                  0
            );
            const values = {
              readers: filtered.map((r) => {
                const tempReader: Reader = {
                  id: r.member.id,
                  legal_name: r.member.legal_name || '',
                  email: r.member.legal_name || '',
                  ceremony_id: r.ceremony_id,
                  ritual_id: r.ritual_id ? r.ritual_id.split(',') : [],
                };
                return tempReader;
              }),
            };
            if (values.readers.length > 0) {
              setInitialVals(values);
            } else {
              setInitialVals({
                readers: [{ legal_name: '', email: '', ritual_id: [] }],
              });
            }
            if (filtered.length > 0) {
              setAddReaders(true);
            }
          }
        } catch (error) {}
      }
    };
    void fetchReaders();
  }, [ceremony, selectedRitual]);

  const handleTextChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setSaved(false);
    setTextAnswer(ev.target.value);
  };

  const handleError = () => {
    setSaved(false);
    openSnackBar('Unexpected error', 5000, 'error');
  };

  const handleSave = async (values: { readers: Reader[] }) => {
    if (ceremony && selectedRitual && saved === false) {
      setSaved(false);
      try {
        const body: SaveReadersBody = {
          readers: values.readers
            .filter((f) => f.legal_name)
            .map((f) => ({
              ...f,
              ceremony_id: ceremony.id,
              ritual_id: [selectedRitual.id.toString()],
            })),
          ritualId: selectedRitual.id.toString(),
          ceremonyId: ceremony.id.toString(),
        };
        const saveReadersResponse = await saveReaders(body);
        if (saveReadersResponse.success) {
          setSaved(true);
        } else {
          handleError();
        }
      } catch (error) {
        handleError();
      }
    }
  };

  return (
    <div className='mt-8'>
      <Typography type='display-50'>
        Will there be any special individuals participating in this
        reading/ritual?
      </Typography>
      <div className='flex flex-row gap-2 mt-4'>
        <SimpleSelectOption
          isSelected={addReaders === true}
          label='Yes'
          onClick={() => setAddReaders(true)}
        />
        <SimpleSelectOption
          isSelected={addReaders === false}
          label='No'
          onClick={() => {
            setAddReaders(false);
            void handleSave({ readers: [] });
          }}
        />
      </div>
      {initialVals && initialVals.readers.length > 0 && (
        <Formik
          initialValues={initialVals}
          enableReinitialize
          onSubmit={(values) => {
            //await new Promise((r) => setTimeout(r, 500));
            void handleSave(values);
          }}
        >
          {({ values }: { values: FormValues }) => (
            <Form>
              <AutoSave debounceMs={1000} />
              {addReaders && (
                <FieldArray name='readers'>
                  {({ insert, remove, push }) => (
                    <div>
                      {values.readers.length > 0 &&
                        values.readers.map((reader: Reader, index: number) => (
                          <div className='flex flex-col w-full' key={index}>
                            <div className='flex flex-row justify-between items-center mt-4'>
                              <Typography
                                variant='functional-low'
                                type='body-200'
                              >
                                Name of individual
                              </Typography>
                              {values.readers.length > 1 && (
                                <img
                                  onClick={() => {
                                    setSaved(false);
                                    remove(index);
                                  }}
                                  src={trashcanSvg}
                                />
                              )}
                            </div>
                            <Field
                              name={`readers.${index}.legal_name`}
                              placeholder='Name'
                              type='text'
                              component={CustomFormikInput}
                              setChanged={() => setSaved(false)}
                              className={'w-full mx-0 h-fit'}
                            />
                          </div>
                        ))}
                      <div className='w-fit ml-2 my-3'>
                        {selectedRitual && (
                          <div>
                            <Typography
                              onClick={() => {
                                push({
                                  name: '',
                                  email: '',
                                  ritual_id: [selectedRitual.id],
                                });
                                setSaved(false);
                              }}
                              variant='functional-low'
                              type='body-400'
                              className='border-0 border-b border-dashed border-forest-primary cursor-pointer mt-4'
                            >
                              + Add Another Individual
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </FieldArray>
              )}

              {question && question.has_textfield && (
                <CustomTextInput
                  className='mt-4'
                  multiline
                  rows={5}
                  value={textAnswer}
                  onChange={handleTextChange}
                  placeholder={question.textfield_placeholder}
                />
              )}
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
