/* eslint-disable -- TODO: fix eslint errors */
import arrowDisabledSvg from '../../../../assets/images/icons/arrow-down-disabled.svg';
import arrowSvg from '../../../../assets/images/icons/arrow-down.svg';
import learnMoreSvg from '../../../../assets/images/icons/learn-more.svg';
import lightbulbDisabledSvg from '../../../../assets/images/icons/lightbulb-disabled.svg';
import lightbulbSvg from '../../../../assets/images/icons/lightbulb.svg';
import './tip.scss';

type TipProps = {
  id: number;
  text: string;
  link?: string;
  expanded: boolean;
  setExpanded: (value: number) => void;
  className?: string;
};

export const Tip = (props: TipProps) => {
  const { id, text, link, expanded, setExpanded, className } = props;
  if (!text) {
    return <div className='w-72 h-1 ml-5 hidden lg:block' />;
  }

  const TipHeader = () => (
    <div
      className='flex w-full h-6 mb-4 flex-row justify-between items-center cursor-pointer'
      onClick={() => setExpanded(expanded ? 0 : id)}
    >
      <img
        className='w-8 brightness-0 invert'
        src={expanded ? lightbulbSvg : lightbulbDisabledSvg}
        alt='lightbulb'
      />
      <div className='flex justify-center items-center'>
        <img
          className={`w-3 brightness-0 invert tip-caret ${
            expanded && 'expanded'
          }`}
          src={expanded ? arrowSvg : arrowDisabledSvg}
          alt='arrow'
        />
      </div>
    </div>
  );

  const TipLink = (props: { url: string }) => {
    return (
      <div
        onClick={() => window.open(props.url, '_blank')}
        className='my-3 px-2 py-3 flex flex-row justify-between text-white border-b border-t border-white cursor-pointer'
      >
        Learn More
        <img alt='learn more' src={learnMoreSvg} />
      </div>
    );
  };

  return expanded ? (
    <div
      className={`hidden lg:block h-min mt-8 px-5 py-6 border border-transparent font-nanum font-normal text-sm text-primary-1 ${
        className ? className : ''
      }`}
    >
      <TipHeader />
      {text && (
        <div
          className='text-white font-nanum'
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
      {link && <TipLink url={link} />}
    </div>
  ) : (
    <div
      onClick={() => setExpanded(expanded ? 0 : id)}
      className={`hidden lg:block lg:col-span-3 h-20 mt-8 px-5 py-6 border border-transparent cursor-pointer ${
        className ? className : ''
      }`}
    >
      <TipHeader />
    </div>
  );
};
