import { useEffect, useState } from 'react';

export const useRegenerationNeeded = () => {
  const [outputDataDate, setOutputDataDate] = useState<Date>();
  const [questionDate, setQuestionDate] = useState<Date>();
  const [regenerationNeeded, setRegenerationNeeded] = useState(false);

  useEffect(() => {
    if (outputDataDate && questionDate) {
      const regenerationNeeded = outputDataDate < questionDate;
      setRegenerationNeeded(regenerationNeeded);
    } else {
      setRegenerationNeeded(false);
    }
  }, [outputDataDate, questionDate]);

  return {
    outputDataDate,
    setOutputDataDate,
    questionDate,
    setQuestionDate,
    regenerationNeeded,
  };
};
