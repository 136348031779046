/* eslint-disable -- TODO: fix eslint errors */

import { useEffect, useState } from 'react';

import {
  WeddingPlannerStatus,
  getWeddingPlannerStatus,
} from '../api/weddingPlanner';

const useWeddingPlannerStatus = () => {
  const [weddingPlannerStatus, setWeddingPlannerStatus] =
    useState<WeddingPlannerStatus>();

  const fetchWeddingPlannerStatus = async () => {
    try {
      const response = await getWeddingPlannerStatus();
      if (response.success) {
        const data = response.data;
        setWeddingPlannerStatus(data);
      }else{
        const data = {
          maxWeddings: 1,
          gracePeriod: 0,
          activatedWeddings: 0,
          subscriptionEndDate: new Date(),
          hasActiveSubscription: false,
          activeSubscription: null
        }
        setWeddingPlannerStatus(data); 
      }
    } catch (error) {}
  };

  return {
    weddingPlannerStatus,
    fetchWeddingPlannerStatus,
  };
};

export default useWeddingPlannerStatus;
