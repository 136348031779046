import { Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

export const ModulePageWrapperTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide direction='up' ref={ref} {...props} mountOnEnter unmountOnExit />
  );
});

export const ModulePageWrapperTransitionLeft = React.forwardRef(
  function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) {
    return (
      <Slide direction='left' ref={ref} {...props} mountOnEnter unmountOnExit />
    );
  }
);
