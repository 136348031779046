import Button from '../../../components/v2/Button/Button';
import { Modal } from '../../../components/v2/Modal/Modal';
import { Typography } from '../../../components/v2/Typography/Typography';

type ConfirmRemoveRitualModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleConfirm: () => void;
};

export const ConfirmRemoveRitualModal = (
  props: ConfirmRemoveRitualModalProps
) => {
  const { isOpen, onClose, handleConfirm } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <>
        <Typography type='display-100'>
          Are you sure you want to deselect?
        </Typography>
        <Typography type='body-400' className='mt-4'>
          If you deselect this option, you’ll loose any additional info you
          added to this specific reading/ritual.
        </Typography>
        <div className='mt-8 flex flex-row justify-end'>
          <Button
            variant='secondary'
            onClick={handleConfirm}
            className='mr-2 text-forest-primary'
          >
            Deselect
          </Button>
          <Button onClick={onClose}>Don't Deselect</Button>
        </div>
      </>
    </Modal>
  );
};
