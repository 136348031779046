/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState } from 'react';

import { OrderSources } from '../api/paywall';

export type PaywallContextType = {
  source: OrderSources | undefined;
  setSource: (value: OrderSources | undefined) => void
};

export const PaywallContext = React.createContext<PaywallContextType>({
  source: undefined,
  setSource: () => {},
});

export const PaywallProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [source, setSource] = useState<OrderSources>();
  
  const value = {
    source,
    setSource,
  };
  // @ts-ignore
  return (
    <PaywallContext.Provider value={value}>
      {children}
    </PaywallContext.Provider>
  );
};

export const usePaywall = () => {
  return useContext(PaywallContext);
};
