import { Member } from './ceremony';
import { _request } from './request';

export type Reader = {
  id?: number;
  legal_name: string;
  email: string;
  ceremony_id?: number;
  ritual_id: string[];
};
export type ReaderResponse = {
  id?: number;
  legal_name: string;
  email: string;
  ceremony_id?: number;
  ritual_id: string;
  member: Member;
};

export type SaveReadersResponse = {
  success: boolean;
  message: string;
};

export type GetReadersResponse = {
  success: boolean;
  message: string;
  data: ReaderResponse[];
};

export type SaveReadersBody = {
  readers: Reader[];
  ritualId: string;
  ceremonyId: string;
};

export type SaveReaderBody = {
  reader: Reader;
};

export type RemoveReaderBody = {
  readerId: string;
};

export const getReaders = async (ceremony_id: string) => {
  const responseBody = await _request<GetReadersResponse>({
    url: `readers?ceremony_id=${ceremony_id}`,
    method: 'GET',
    body: {
      ceremony_id,
    },
  });
  return responseBody;
};

export const saveReaders = async (body: SaveReadersBody) => {
  const responseBody = await _request<SaveReadersResponse>({
    url: `readers/save-readers`,
    method: 'POST',
    body,
  });
  return responseBody;
};

export const saveReader = async (body: SaveReaderBody) => {
  const responseBody = await _request<SaveReadersResponse>({
    url: `readers/save-reader`,
    method: 'POST',
    body,
  });
  return responseBody;
};

export const removeReader = async (readerId: string) => {
  const responseBody = await _request<SaveReadersResponse>({
    url: `readers/remove-reader`,
    method: 'POST',
    body: {
      readerId,
    },
  });
  return responseBody;
};
