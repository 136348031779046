import { Question } from '../pages/Modules/common/moduleQuestion/ModuleQuestion';

import { GenericResponse, Member } from './ceremony';
import { _request } from './request';

export type Friend = {
  id?: number;
  legal_name: string;
  email: string;
  user_id?: number;
  ceremony_id?: number;
};

export type FriendWithQuestions = {
  id?: number;
  legal_name: string;
  email: string;
  user_id?: number;
  ceremony_id?: number;
  questions: Question[];
};

export type SaveFriendsResponse = {
  success: boolean;
  message: string;
};

export type GetFriendsResponse = {
  success: boolean;
  message: string;
  data: Friend[];
};

export type GetSpeakersResponse = {
  success: boolean;
  message: string;
  data: Speaker[];
};

export type GetFriendsAndFamilyAnswersResponse = {
  success: boolean;
  message: string;
  data: FriendWithQuestions[];
};

export type SaveFriendsBody = Friend & {
  ceremony_id: number;
};

export type SpeakerBody = {
  speaker_alloted_time: number;
  speaker_email: string;
  speaker_name: string;
  speaker_note: string;
  speaker_event: string;
  speaker_role: string;
};

export type Speaker = {
  id: number;
  member_id?: number;
  event: EventType;
  speech_note: string;
  alloted_time: number;
  removed_from_ceremony?: boolean;
  deleted?: boolean;
  order: number;
  member: Member;
};

export enum EventType {
  engagementParty = 'engagement_party',
  rehearsalDinner = 'rehearsal_dinner',
  reception = 'reception'
}


export type SaveSpeakerBody = SpeakerBody & {
  ceremony_id: number;
};
export type RemoveMemberBody = {
  member_id: number;
};

export type RemoveSpeakerBody = {
  speaker_id: number;
};

export type reOrderMemberBody = {
  order: orderMember[];
};

export type orderMember = {
  sid: number;
  order: number;
};

export const rolesDropdown = [
  { label: 'Parent', value: 'parent' },
  { label: 'Sibling', value: 'sibling' },
  { label: 'Best Man', value: 'best_man' },
  { label: 'Friend', value: 'friend' },
  { label: 'Cousin', value: 'cousin' },
  { label: 'Grandparent', value: 'grandparent' },
  { label: 'Maid of Honor', value: 'maid_of_honor' },
  { label: 'Wedding Party', value: 'wedding_party' },
  { label: 'Aunt/Uncle', value: 'aunt_uncle' },
  { label: 'Mentor', value: 'mentor' },
  { label: 'Other', value: 'other' },
];

export const eventDropdown = [
  { label: 'Engagement Party', value: 'engagement_party' },
  { label: 'Rehearsal Dinner', value: 'rehearsal_dinner' },
  { label: 'Reception', value: 'reception' },
];

export const getFriends = async (ceremony_id: number) => {
  const responseBody = await _request<GetFriendsResponse>({
    url: `friends/${ceremony_id}`,
    method: 'GET',
  });
  return responseBody;
};

export const getSpeakers = async (ceremony_id: number) => {
  const responseBody = await _request<GetSpeakersResponse>({
    url: `friends/${ceremony_id}/speakers`,
    method: 'GET',
  });
  return responseBody;
};

export const getFriendsAndFamilyAnswers = async (ceremony_id: number) => {
  const responseBody = await _request<GetFriendsAndFamilyAnswersResponse>({
    url: `friends/survey/${ceremony_id}`,
    method: 'GET',
  });
  return responseBody;
};

export const saveFriends = async (body: SaveFriendsBody) => {
  const responseBody = await _request<SaveFriendsResponse>({
    url: `friends/save-friends`,
    method: 'POST',
    body,
  });
  return responseBody;
};

export const saveSpeakers = async (body: SaveSpeakerBody) => {
  const responseBody = await _request<SaveFriendsResponse>({
    url: `friends/save-speakers`,
    method: 'POST',
    body,
  });
  return responseBody;
};

export const removeMember = async (body: RemoveMemberBody) => {
  const responseBody = await _request<GenericResponse>({
    url: `friends/remove-member`,
    method: 'POST',
    body,
  });
  return responseBody;
};

export const removeSpeaker = async (body: RemoveSpeakerBody) => {
  const responseBody = await _request<GenericResponse>({
    url: `friends/remove-speaker`,
    method: 'POST',
    body,
  });
  return responseBody;
};

export const reorderSpeaker = async (body: reOrderMemberBody) => {
  const responseBody = await _request<GenericResponse>({
    url: `friends/order-speaker`,
    method: 'POST',
    body,
  });
  return responseBody;
};

export const sendSpeakerInvitation = async (ceremony_id: number) => {
  const responseBody = await _request<GenericResponse>({
    url: `friends/${ceremony_id}/send-speaker-invitation`,
    method: 'POST',
  });
  return responseBody;
};

export const sendFriendsInvitation = async (ceremony_id: number) => {
  const responseBody = await _request<GenericResponse>({
    url: `friends/${ceremony_id}/send-friends-invitation`,
    method: 'POST',
  });
  return responseBody;
};
