import { DraftingModal } from '../../components/v2/DraftingModal/DraftingModal';
import { useWelcomeToastBuilder } from '../../provider/welcomeToastBuilderProvider';

export const DraftingWelcomeToastModal = () => {
  const {
    textGenerating,
    textGenerateComplete,
    draftingModalOpen,
    setDraftingModalOpen,
    aiGeneratedTextIndex,
    generationError
  } = useWelcomeToastBuilder();
  return (
    <DraftingModal
      modalOpen={draftingModalOpen}
      generationError={generationError}
      setModalOpen={setDraftingModalOpen}
      textGenerateComplete={textGenerateComplete}
      textGenerating={textGenerating}
      aiGeneratedTextIndex={aiGeneratedTextIndex}
      titleDrafting={'Drafting your toast...'}
      descriptionDrafting={
        'We’re using your questionnaire responses to craft a jumping off point for your toast. Once complete, you’ll be able to view, edit and/or download your toast freely.'
      }
      titlePopulating={'Finishing up...'}
      descriptionPopulating={
        'We’re using your questionnaire responses to craft a jumping off point for your toast. Once complete, you’ll be able to view, edit and/or download your toast freely.'
      }
      titleComplete={'Your toast is ready to view!'}
      descriptionComplete={
        'Freely view, edit, and/or download your completed toast. If it doesn’t sound quite right, simply regenerate it.'
      }
      buttonTextComplete={'View My Toast'}
    />
  );
};