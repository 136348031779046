import { ProductionQuantityLimitsOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { OrderSources } from '../../../api/paywall';
import CustomTooltip from '../../../components/v2/Tooltip/Tooltip';
import {
    useMerchandise,
  } from '../../../provider/merchandisingProvider';
import { Typography } from '../Typography/Typography';


type CardProp = {
    productName: string;
    title: string | JSX.Element;
    description: string | JSX.Element;
    image?: JSX.Element;
    className?: string;
    source?: OrderSources;
    tooltip?: string;
}
export const MerchandisingCard = (props: CardProp) => {

    const { getButton, showTooltip } = useMerchandise();
    let classNames = props.className ? props.className : '';  
    const { productName, image, title, description, source, tooltip } = props;

    const button = getButton(productName, source);
    classNames += ' lg:text-center md:text-left sm:text-center '

    const anchorId = 'button-container-' + productName.replaceAll('_', '-').replaceAll('+', '-');
    
    return <div className={'merchandising-card ' + classNames}>
        { image !== undefined ? <div className='image-container text-center mb-3'>
                {image}
            </div>
            : <></>
        }
        <div>
        <Typography
          type='display-100'
          className=' text-cashmere-800 text-lg'
        >
          {title}
        </Typography>
        <Typography
          type='body-200'
          className=' text-cashmere-800 mt-3 mb-3'
        >
          {description}
        </Typography>
        <div id={anchorId}>
          <Typography type='body-200' className={' text-cashmere-800'} >
            {button}
          </Typography>
            {
              tooltip != undefined && showTooltip(productName)  ? (
                <div className='tooltip-container'>
                  <ReactTooltip anchorSelect={'#'+ anchorId} place='bottom' >
                    <div className='flex flex-col w-72'>
                      <Typography variant='functional-low' type='body-600'>
                        {tooltip}
                      </Typography>
                    </div>
                  </ReactTooltip>
                </div>
              ) : null
            }
        </div>


  </div>
    </div>
}
