import './blurOverlay.scss'; // Import CSS for styling (see below)

type BlurOverlayProps = {
  children: React.ReactNode;
  deductBorder?: boolean;
  overlayContent?: React.ReactNode;
  offsetTop?: boolean;
};

const BlurOverlay = (props: BlurOverlayProps) => {
  const { children, overlayContent, deductBorder, offsetTop } = props;
  return (
    <div className='blur-overlay'>
      {children}
      <div
        className={`overlay ${
          deductBorder
            ? `top-0 left-[1px] w-[calc(100%-2px)] h-[calc(100%-2px)]`
            : 'top-0 left-0 w-full h-full'
        } 
        ${offsetTop ? 'top-[100px]' : ''}`}
      >
        {overlayContent}
      </div>
    </div>
  );
};

export default BlurOverlay;
