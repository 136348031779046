/* eslint-disable -- TODO: fix eslint errors */

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Toolbar } from '@mui/material';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='down' ref={ref} {...props} />;
});

type ModalProps = {
  isOpen: boolean;
  title?: string;
  subtitle?: string;
  onClose: () => void;
  children: JSX.Element;
  onHeaderButtonClick?: () => void;
  headerButtonText?: string;
  fullPage?: boolean;
};

export const SlimModal = ({
  isOpen,
  onClose,
  title,
  subtitle,
  children,
  onHeaderButtonClick,
  headerButtonText,
  fullPage = false,

}: ModalProps) => {
  return (
    <>
    <div onClick={onClose}>
    
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: '#FFFFFF',
            boxShadow: 'none',
            borderRadius: 0,
            zIndex: 1
          },
        }}
        maxWidth={false}
        open={isOpen}
        TransitionComponent={Transition}
        onClose={onClose}
        fullScreen={fullPage}
      >

        {fullPage && (
           <Toolbar>
           <IconButton
             edge="start"
             color="inherit"
             onClick={onClose}
             aria-label="close"
           >
             <CloseIcon />
           </IconButton>
           
         </Toolbar>
         )}

        <DialogContent className={`${!fullPage ? 'p-4' : 'p-0'}`}>
          <div className=''>
          <div className='flex flex-row justify-between'>
              {subtitle && (
                <h4 className='text-lg font-nanum  text-forest-primary'>
                  {subtitle}
                </h4>
              )}
            </div>
            <div className='flex flex-row justify-between items-center'>
              {title && (
                <h2 className='text-5xl font-nanum  text-forest-primary'>
                  {title}
                </h2>
              )}
              {onHeaderButtonClick && (
                <div
                  className='px-8 h-11 flex justify-center items-center bg-white text-forest-primary cursor-pointer'
                  onClick={onHeaderButtonClick}
                >
                  {headerButtonText || ''}
                </div>
              )}
            </div>

            <div className=''>{children}</div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
    </>
  );
};
