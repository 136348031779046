import { User } from '../api/auth';

export const getFullName = (user?: User) => {
  let result = '';
  if (user && user.first_name) {
    result = user.first_name;
    if (user.last_name) {
      result = user.first_name + ' ' + user.last_name;
    }
  }
  return result;
};
