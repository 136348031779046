/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useEffect, useState } from 'react';

import { PaywallModalTypes } from '../components/PaywallModal/PaywallModal';

type PaywallModalContextType = {
  isPaywallModalOpen: boolean;
  setIsPaywallModalOpen: (value: boolean) => void;
  paywallModalReferrer: string;
  setPaywallModalReferrer: (value: string) => void;
  paywallModalType: PaywallModalTypes;
  setPaywallModalType: (value: PaywallModalTypes) => void;
};

export const PaywallModalContext = React.createContext<PaywallModalContextType>(
  {
    isPaywallModalOpen: false,
    setIsPaywallModalOpen: () => {},
    paywallModalReferrer: '',
    setPaywallModalReferrer: () => {},
    paywallModalType: 'ceremony',
    setPaywallModalType: () => {},
  }
);

export const PaywallModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState<boolean>(false);
  const [paywallModalReferrer, setPaywallModalReferrer] = useState<string>('');
  const [paywallModalType, setPaywallModalType] =
    useState<PaywallModalTypes>('ceremony');

  const value = {
    isPaywallModalOpen,
    setIsPaywallModalOpen,
    paywallModalReferrer,
    setPaywallModalReferrer,
    paywallModalType,
    setPaywallModalType,
  };
  // @ts-ignore
  return (
    <PaywallModalContext.Provider value={value}>
      {children}
    </PaywallModalContext.Provider>
  );
};

export const usePaywallModal = () => {
  return useContext(PaywallModalContext);
};
