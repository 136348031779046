import { Drawer } from '@mui/material';
import { Component, RefCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import closeIcon from '../../assets/images/icons/close-x-v2.svg';
import provenanceLogoSvg from '../../assets/images/svg/provenange-logo.svg';
import { useDashboard } from '../../provider/dashboardProvider';
import { AccountMenu } from '../v2/AccountMenu/AccountMenu';
import { MobileAccountMenu } from '../v2/AccountMenu/MobileAccountMenu';
import ReferFriends from '../v2/ReferFriend/ReferFriends';

import { MenuContent } from './MenuContent';
import {ViewWeddingMenuContent} from "./ViewWeddingMenuContent";
import barsSvg from './images/bars.svg';

type MobileMenuProps = {
  name: string;
};
export const MobileMenu = (props: MobileMenuProps) => {
  const { mobileMenuExpanded, setMobileMenuExpanded } = useDashboard();
  const params = useParams();
  const { name } = props; 

  useEffect(() => {
    setMobileMenuExpanded(false);
  }, [params]);

  const showWeddingMenu = () => {
    const result = false;
    
    if (location.pathname.indexOf(`/view-ceremonies`) >= 0 ) 
    {
      return true
    }

    return result
  }

  const Menu = showWeddingMenu() ? <ViewWeddingMenuContent expanded={true} /> : <MenuContent expanded={true} />
  
  return (
    <>
      <Drawer
        anchor='left'
        open={mobileMenuExpanded}
        onClose={() => setMobileMenuExpanded(false)}
        PaperProps={{
          className: 'rounded-r-3xl rounded-l-none bg-cosmicLatte-primary',
        }}
      >
        <div className='px-4 py-6 flex flex-col flex-1'>
          <div className='w-full flex flex-row justify-between mb-4'>
            <img
              className={`h-4 PageWrapper-header-text overflow-hidden w-auto`}
              src={provenanceLogoSvg}
            />
            <img onClick={() => setMobileMenuExpanded(false)} src={closeIcon} />
          </div>

          { Menu }
        </div>
      </Drawer>
      <div className='fixed z-10 top-0 p-4 w-full flex flex-row justify-between items-center bg-cosmicLatte-primary border-forest-100 border-b'>
        <div className='flex flex-row items-center'>
          <img
            onClick={() => setMobileMenuExpanded(!mobileMenuExpanded)}
            className='mr-4'
            src={barsSvg}
          />
          <img src={provenanceLogoSvg} />
        </div>
        <div className='flex flex-row items-center'>
          <div>
            <ReferFriends expanded={false} />
          </div>
          <div>
            <MobileAccountMenu
              name={name}
              expanded={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};
