/* eslint-disable -- TODO: fix eslint errors */

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const CustomRadioSelect = ({
  value,
  onChange,
  name,
  options = [],
  row = false,
  singleRow = false,
  className = '',
  variant = 'light',
  simpleRadio = false,
  readOnly,
  gap = 'gap-6',
  ml = 'ml-2'
}: any) => {
  return (
    <RadioGroup
      name={name}
      value={value}
      className={`w-full ${ml} mt-3 grid grid-${row ? 'rows' : 'cols'}-${
        singleRow ? '2' : '1'
      } md:grid-${row ? 'rows' : 'cols'}-${
        options.length <= 2 ? options.length : '2'
      } lg:grid-${row ? 'rows' : 'cols'}-${
        options.length <= 3 ? options.length : '3'
      } xl:grid-${row ? 'rows' : 'cols'}-${
        options.length <= 3 ? options.length : '3'
      } ${gap} ${className}`}
    >
      {options.map((option: any) => {
        return (
          <FormControlLabel
            onChange={!readOnly ? onChange : () => {}}
            key={option.value + option.label}
            value={option.value}
            label={simpleRadio ? option.label : ''}
            control={
              simpleRadio ? (
                <Radio />
              ) : (
                <Radio
                  sx={{
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                  className='w-full p-0 m-0'
                  disableRipple
                  color='default'
                  checked={option.value == value}
                  checkedIcon={
                    <OptionButton
                      icon={option.icon}
                      label={option.label}
                      selected={true}
                      variant={variant}
                    />
                  }
                  icon={
                    <OptionButton
                      icon={option.icon}
                      label={option.label}
                      selected={false}
                      variant={variant}
                    />
                  }
                />
              )
            }
            className='w-full'
          />
        );
      })}
    </RadioGroup>
  );
};

export const OptionButton = ({
  label,
  selected,
  onClick,
  variant = 'light',
  disabled = false,
  icon = '',
}: any) => {
  return (
    <>
      {variant === 'dark' ? (
        <div className='w-full'>
          {icon && <img src={`${icon}`} className='mx-auto mb-3' />}
          <div
            onClick={!disabled ? onClick : () => {}}
            className={`border-2 border-forest-primary w-full text-center font-semibold text-sm cursor-pointer ${
              selected ? 'bg-forest-primary text-white' : 'text-forest-primary'
            }`}
          >
            <h4 className='px-2 py-1'>{label}</h4>
          </div>
        </div>
      ) : (
        <div className='w-full'>
          {icon && <img src={`${icon}`} className='mx-auto mb-3' />}
          <div
            onClick={!disabled ? onClick : () => {}}
            className={`w-full text-center text-forest-primary font-semibold font-inter text-sm bg-rose-primary cursor-pointer ${
              selected ? 'border border-forest-primary' : 'bg-opacity-40	'
            }`}
          >
            <h4 className='px-2 py-1'>{label}</h4>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomRadioSelect;
