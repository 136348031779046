import { Typography } from '../Typography/Typography';

import envelopePng from './envelope-open-text 1.png';

export const CouplesNotes = (props: {
  text: string;
  variant?: 'dashboard';
}) => {
  const { text, variant } = props;
  return (
    <div
      className={`p-6 rounded-3xl  bg-marigold-100 ${
        variant == 'dashboard' ? 'pb-12 rounded-b-none mb-[-24px]' : ''
      }`}
    >
      <div className='flex flex-row justify-start items-center'>
        <img src={envelopePng} className='mr-4' />
        <Typography
          className='text-marigold-700'
          type='body-400'
          variant='functional-high'
        >
          A note from the couple
        </Typography>
      </div>
      <Typography
        type='body-400'
        variant='functional-low'
        className='text-marigold-700 mt-4'
      >
        {text}
      </Typography>
    </div>
  );
};
