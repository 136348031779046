import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';

import { Ceremony, Member } from '../../../../api/ceremony';
import { saveAnswer } from '../../../../api/question';
import tooltipArrow from '../../../../assets/images/icons/tooltip-arrow.png';
import QuestionSaveStatus from '../../../../components/QuestionSave';
import Button from '../../../../components/button';
import CustomRadioSelect from '../../../../components/forms/CustomRadioSelect';
import CustomTextInput from '../../../../components/forms/CustomTextInput';
import { SlimModal } from '../../../../components/modal/SlimModal';
import { replacePlaceholders } from '../../../../helpers/placeholderHelper';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { useActiveQuestion } from '../../../../provider/activeQuestionProvider';
import { Tip } from '../tip/Tip';

import { Question, QuestionAnswer } from './ModuleQuestion';
import './moduleQuestion.scss';

import { ClickAwayListener } from '@mui/material';

type QuestionProps = {
  question: Question;
  couple1?: Member;
  couple2?: Member;
  ceremony?: Ceremony;
  officiant?: Member;
  currentUser?: Member;
  showTip?: boolean;
  readOnly?: boolean;
  setExceptionQuestionChanged: (value: boolean) => void;
  setQuestionChanged: (value: boolean) => void;
  setWhichQuestionChanged?: (value: string) => void;
  className?: string;
  newReflectionQuestions?: boolean;
  numerotator?: number;
  drawerQuestion?: boolean;
  asterisk?: boolean;
};

export const OptionsQuestion = (props: QuestionProps) => {
  const { openSnackBar } = useSnackbar();

  const {
    question,
    couple1,
    couple2,
    officiant,
    ceremony,
    currentUser,
    showTip = true,
    readOnly,
    setExceptionQuestionChanged,
    setQuestionChanged,
    setWhichQuestionChanged,
    className,
    newReflectionQuestions,
    numerotator = 0,
    drawerQuestion,
    asterisk
  } = props;

  const [title, setTitle] = useState<string>('Save Changes');
  const [changed, setChanged] = useState<boolean>(false);
  const [tipExpanded, setTipExpanded] = useState<number>(0);
  const [optionId, setOptionId] = useState<string>();
  const [textAnswer, setTextAnswer] = useState<string>();
  const [reflectionQuestionModalShown, setReflectionQuestionModalShown] =
    useState<boolean>(false);
  const [
    reflectionQuestionSelectedAnswer,
    setReflectionQuestionSelectedAnswer,
  ] = useState<string>('');

  const { activeQuestionId, setActiveQuestionId } = useActiveQuestion();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const handleClickAwayTooltip = () => {
    setTooltipOpen(false);
  };

  useEffect(() => {
    if (question && question.answers) {
      const answer = question.answers[0];
      if (answer) {
        setOptionId(answer.option_id);
        setTextAnswer(answer.text_answer);
      }
    }
  }, [question]);

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (question.identifier === 'together_or_individual') {
      setReflectionQuestionSelectedAnswer(ev.target.value);
      if (question.answers && question.answers.length > 0) {
        setReflectionQuestionModalShown(true);
        return false;
      } else {
        handleReflectionQuestionModalConfirm();
      }
    }

    // if No is clicked show tooltip for ceremonyValidOnline question
    if (
      question.identifier === 'ceremonyValidOnline' &&
      ev.target.offsetParent?.textContent &&
      ev.target.offsetParent?.textContent.includes('No')
    ) {
      setTooltipOpen(true);
    }

    setChanged(true);
    setTitle('Save Changes');
    setOptionId(ev.target.value);
  };

  const handleReflectionQuestionModalConfirm = () => {
    setChanged(true);
    setTitle('Save Changes');
    setOptionId(reflectionQuestionSelectedAnswer);
  };

  const handleTextChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(true);
    setTitle('Save Changes');
    setTextAnswer(ev.target.value);
  };

  const handleSave = async (
    question: Question,
    ceremony?: Ceremony,
    optionId?: string,
    textAnswer?: string,
    override = false
  ) => {
    if (ceremony) {
      const answer: QuestionAnswer = {
        ceremony_id: ceremony.id,
        question_id: question.id,
        option_id: optionId,
        text_answer: textAnswer,
      };
      setChanged(false);
      setTitle('Saving');
      try {
        const saveResponse = await saveAnswer(answer);
        if (saveResponse.success) {
          setTitle('Saved');
          setQuestionChanged(true);

          if (question.identifier && setWhichQuestionChanged !== undefined) {
            setWhichQuestionChanged(question.identifier);
          }

          if (
            question.identifier &&
            question.identifier?.indexOf('willReadVows') >= 0
          )
            setExceptionQuestionChanged(true);
        } else {
          setChanged(true);
          openSnackBar(saveResponse.message, 5000, 'error');
          setTitle('Save Changes');
        }
      } catch (error) {
        setChanged(true);
        openSnackBar('Unexpected error', 5000, 'error');
        setTitle('Save Changes');
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useCallback(
    debounce(
      (
        question: Question,
        optionId?: string,
        textAnswer?: string,
        ceremony?: Ceremony
      ) => {
        void handleSave(question, ceremony, optionId, textAnswer);
      },
      question.identifier === 'together_or_individual' ? 200 : 2000
    ),
    []
  );

  useEffect(() => {
    if (changed === true) {
      debouncedCall(question, optionId, textAnswer, ceremony);
    }
  }, [ceremony, question, optionId, textAnswer, changed, debouncedCall]);

  useEffect(() => {
    if (activeQuestionId && activeQuestionId !== question.id) {
      setTipExpanded(0);
    } else if (activeQuestionId && activeQuestionId === question.id) {
      setTipExpanded(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuestionId]);

  const mappedOptions = question.options?.map((o) => ({
    label: replacePlaceholders(
      o.option,
      couple1?.preferred_name || couple1?.legal_name,
      couple2?.preferred_name || couple2?.legal_name,
      officiant?.preferred_name || officiant?.legal_name,
      currentUser?.preferred_name || currentUser?.legal_name
    ),
    value: o.id,
    icon: o.icon,
  }));

  const exceptionIdentifiers = [
    'together_or_individual',
    'how_will_you_be_completing_the_reflection_section',
  ];

  return (
    <div
      className={`relative col-span-4 md:col-span-6 lg:col-span-12 grid grid-cols-4 md:grid-cols-6 lg:grid-cols-12`}
    >
      {newReflectionQuestions && (
        <SlimModal
          isOpen={reflectionQuestionModalShown}
          onClose={() => setReflectionQuestionModalShown(false)}
        >
          <div className='max-w-full w-[768px] py-14'>
            <div className='flex flex-row items-center justify-center'>
              {optionId &&
              question.options &&
              question.options[0].id &&
              parseInt(optionId) > question.options[0].id ? (
                <>
                  <img
                    src='/assets/images/icons/icon-answer-individually.png'
                    alt='individually icon'
                    className='px-7'
                  />
                  <img
                    src='/assets/images/icons/arrow-icon.png'
                    alt='arrow icon'
                  />

                  <img
                    src='/assets/images/icons/icon-answer-together.png'
                    alt='togehter icon'
                    className='px-7'
                  />
                </>
              ) : (
                <>
                  <img
                    src='/assets/images/icons/icon-answer-together.png'
                    alt='togehter icon'
                    className='px-7'
                  />
                  <img
                    src='/assets/images/icons/arrow-icon.png'
                    alt='arrow icon'
                  />
                  <img
                    src='/assets/images/icons/icon-answer-individually.png'
                    alt='individually icon'
                    className='px-7'
                  />
                </>
              )}
            </div>

            <div
              className={`font-nanum text-2xl leading-8 text-center mx-auto my-4 max-w-[431px] `}
            >
              {optionId &&
              question.options &&
              question.options[0].id &&
              parseInt(optionId) > question.options[0].id
                ? 'You are switching from answering individually to answering together as a couple.'
                : 'You are switching from answering together as a couple to answering individually.'}
            </div>

            <div className='flex flex-row items-center justify-center font-inter text-sm text-redOchre-primary'>
              <img
                src='/assets/images/icons/alert-circle-icon.png'
                alt='alert icon icon'
                className='pr-2'
              />
              Please commit to your selection before answering the questions.
            </div>

            <div className='flex flex-row items-center justify-center'>
              <Button
                title='CHANGE'
                className='text-sm text-forest-500 font-inter font-bold py-3 md:py-3 lg:py-3 xl:py-3 2xl:py-3 mt-4'
                onClick={() => handleReflectionQuestionModalConfirm()}
              ></Button>
            </div>
          </div>
        </SlimModal>
      )}
      <div
        onClick={() => setActiveQuestionId(question.id)}
        className={`question-container mb-8 px-4 md:px-11 py-6 col-span-4 md:col-span-6 ${
          !newReflectionQuestions ? 'lg:col-span-9 border' : 'lg:col-span-12'
        } `}
      >
        <div
          className={`font-nanum ${
            drawerQuestion ? 'text-xl' : 'text-2xl'
          } leading-8 ${
            question?.identifier &&
            exceptionIdentifiers.includes(question?.identifier)
              ? 'text-center mx-auto max-w-[431px]'
              : 'mx-2 my-3'
          } `}
        >
          {replacePlaceholders(
            question.question,
            couple1?.preferred_name || couple1?.legal_name,
            couple2?.preferred_name || couple2?.legal_name,
            officiant?.preferred_name || officiant?.legal_name,
            currentUser?.preferred_name || currentUser?.legal_name
          )}
          { asterisk ? <span className='ml-1' style={{color: '#995D30'}}>*</span> : null }
        </div>
        {tooltipOpen && question.identifier === 'ceremonyValidOnline' && (
          <ClickAwayListener onClickAway={handleClickAwayTooltip}>
            <div className='tooltip bg-forest-primary py-3 px-4 absolute w-[240px] bottom-[150px] opacity-100 left-0 lg:left-[-80px] min-w-full z-50'>
              <div className='tooltip-text font-inter font-light text-gray-200 text-sm'>
                I want to know what county I need to get the license in and in
                what timeframe, apply for it myself in said county and time
                frame, go to the county clerk’s office, wait in line, get the
                license, remember to bring the license on my wedding day,
                remember to sign it on my wedding day after the ceremony but
                before I hit the dance floor, have our officiant remember to
                submit the final application in the legally required amount of
                time that I researched and before I go on my honeymoon, and hope
                we all didn’t mess the whole thing up.
              </div>
              <img
                src={tooltipArrow}
                className='absolute bottom-0 transform md:left-1/2 translate-x-[72px] translate-y-[12px]'
                alt='tooltip arrow'
              />
            </div>
          </ClickAwayListener>
        )}
        <CustomRadioSelect
          options={mappedOptions}
          onChange={handleChange}
          value={optionId || null}
          row={
            question.options &&
            question.options.length > 2 &&
            question.module !== 'remarks'
          }
          className={`question-options ${
            newReflectionQuestions ? 'ml-0' : ''
          } `}
          readOnly={readOnly}
        />
        {question.has_textfield && (
          <CustomTextInput
            className='mt-4'
            multiline
            rows={5}
            value={textAnswer}
            onChange={handleTextChange}
            placeholder={question.textfield_placeholder}
            disabled={optionId === ''}
            readOnly={readOnly}
          />
        )}
        {question.module !== 'remarks' && (textAnswer || optionId) && (
          <QuestionSaveStatus
            disabled={!changed}
            title={title}
            onClick={() => handleSave(question, ceremony, optionId, textAnswer)}
          ></QuestionSaveStatus>
        )}
      </div>
      {!newReflectionQuestions && (
        <Tip
          text={replacePlaceholders(
            question?.help_short_text,
            couple1?.preferred_name || couple1?.legal_name,
            couple2?.preferred_name || couple2?.legal_name,
            officiant?.preferred_name || officiant?.legal_name,
            currentUser?.preferred_name || currentUser?.legal_name
          )}
          id={1}
          expanded={tipExpanded === 1}
          link={question.help_detail_text}
          setExpanded={(id: number) => setTipExpanded(id)}
          className={'w-72 ml-5 bg-forest-primary'}
        />
      )}
    </div>
  );
};
