import moment from 'moment';
import { useEffect } from 'react';

import { Ceremony } from '../../../api/ceremony';
import { deactivateCeremony } from '../../../api/weddingPlanner';
import Button from '../../../components/v2/Button/Button';
import ButtonPill from '../../../components/v2/ButtonPill';
import { Modal } from '../../../components/v2/Modal/Modal';
import { Typography } from '../../../components/v2/Typography/Typography';

type DeactivateCeremonyModalProps = {
  ceremony: Ceremony;
  closeModal: () => void;
  refetch: () => void;
};

const DeactivateCeremonyModal = (props: DeactivateCeremonyModalProps) => {
  const { ceremony, closeModal, refetch } = props;
  const { coupleName, date, id } = ceremony;

  const dateString = date ? moment(date).format('MMM Do, YY') : 'TBD';

  const deactivate = async () => {
    await deactivateCeremony(id);
    void refetch();
    closeModal();
  };

  return (
    <Modal isOpen={true} onClose={() => closeModal()} height='auto'>
      <>
        <Typography className='mb-2' type='display-600'>
          Deactivate this wedding?
        </Typography>
        <Typography className='mb-8' type='body-400'>
          <>
            Are you sure that you want to deactivate{' '}
            <b>
              {coupleName} {dateString}?{' '}
            </b>{' '}
            Doing this will revoke access for you, as well as the couple and you
            won’t be able to view, edit or export any of the scripts.
          </>
        </Typography>
        {/* <Typography className='mb-8' type='body-400'>
          <>
            Learn more about
            <Button
              size='200'
              onClick={() => {}}
              variant='text'
              typographyVariant='primary'
              className='ml-1'
            >
              What happens when a wedding gets deactivated.
            </Button>
          </>
        </Typography> */}
        <div className='flex justify-end w-full'>
          <ButtonPill
            text='Deactivate'
            onClick={() => void deactivate()}
            className='text-red-800 border-red-800 mr-3'
          />
          <ButtonPill
            text='Keep as Active'
            onClick={closeModal}
            className='bg-forest-primary text-white'
          />
        </div>
      </>
    </Modal>
  );
};

export default DeactivateCeremonyModal;
