export type OnboardingStep = {
  url: string;
  title: string;
  title2?: string;
};

export type OnboardingSteps = {
  couple: OnboardingStep[];
  officiant: OnboardingStep[];
  guest: OnboardingStep[];
  wedding_planner: OnboardingStep[];
};

export const steps = {
  couple: [
    {
      url: '/signup-step1',
      title: 'What’s your role in the wedding?',
    },
    {
      url: '/signup-step2/couple',
      title: 'Congrats! What brings you here?',
    },
    {
      url: '/signup-step3/couple',
      title: 'What’s your name?',
    },
    {
      url: '/signup-step4/couple',
      title: 'What’s your partner’s name?',
    },
    {
      url: '/signup-step5/couple',
      title: 'Which best describes your roles in the wedding?',
    },
    {
      url: '/signup-step6/couple',
      title: 'When are you getting married?',
    },
    // {
    //   url: '/signup-step5/couple',
    //   title: 'Where are you getting married?',
    // },
  ],
  officiant: [
    {
      url: '/signup-step1',
      title: 'What’s your role in the wedding?',
    },
    {
      url: '/signup-step2/officiant',
      title: 'You got this! What brings you here?',
    },
    {
      url: '/signup-step3/officiant',
      title: 'What’s your name?',
    },
    {
      url: '/signup-step4/officiant',
      title: 'Who’s getting married?',
    },
    {
      url: '/signup-step5/officiant',
      title: 'Which best describes their role in the wedding?',
    },
    {
      url: '/signup-step6/officiant',
      title: 'When are they getting married?',
    },
    // {
    //   url: '/signup-step6/officiant',
    //   title: 'Where are they getting married?',
    // },
  ],
  guest: [
    {
      url: '/signup-step1',
      title: 'What’s your role in the wedding?', 
    },
    {
      url: '/signup-step2/guest',
      title: 'You got this! What brings you here?',
    },
    {
      url: '/signup-step3/guest',
      title: 'What’s your name?',
    },
    {
      url: '/signup-step4/guest',
      title: 'How do you know the couple? I\'m their...',
    },
    {
      url: '/signup-step5/guest',
      title: 'Who’s getting married?',
    },
    {
      url: '/signup-step6/guest',
      title: 'Which best describes their role in the wedding?',
    },
    {
      url: '/signup-step7/guest',
      title: 'When is the wedding?',
    },
    // {
    //   url: '/signup-step7/guest',
    //   title: 'Where are you getting married?',
    // },
  ],
  wedding_planner: [
    {
      url: '/signup-step1',
      title: 'What’s your role in the wedding?',
    },
    {
      url: '/signup-step2/wedding-planner',
      title: 'What’s your name?',
    },
    // {
    //   url: '/signup-step3/wedding-planner',
    //   title: 'Who’s getting married?',
    // },
    // {
    //   url: '/signup-step4/wedding-planner',
    //   title: 'What are their email addresses?',
    // },
    // {
    //   url: '/signup-step5/wedding-planner',
    //   title: 'Which best describes their role in the wedding?',
    // },
    // {
    //   url: '/signup-step6/wedding-planner',
    //   title: 'When are they getting married?',
    // },
    // {
    //   url: '/signup-step7/wedding-planner',
    //   title: 'Where are they getting married?',
    // },
  ],
};
