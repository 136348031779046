/* eslint-disable -- TODO: fix eslint errors */
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {
  CultureChip,
  MethodChip,
  PlacementChip,
} from '../../pages/Modules/common/chips/Chip';
import { FormHelperText } from '@mui/material';
import { useEffect, useState } from 'react';
import { Modal } from '../modal/Modal';
import CustomTextInput from './CustomTextInput';
import Button from '../button';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type Props = {
  options: { label: string; value: string }[];
  values: string[];
  handleChange: (event: SelectChangeEvent<string[]>) => void;
  handleClick?: () => void;
  handleAddNew?: (name: string) => Promise<any>;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  name?: string;
  className?: string;
  chipType?: string;
  noSort?: boolean;
  readOnly?: boolean;
  multiselect?: boolean;
};

export const CustomMultiselect = (props: Props) => {
  const {
    options,
    values,
    handleChange,
    handleClick,
    name,
    className,
    chipType,
    error,
    helperText,
    noSort = false,
    readOnly,
    handleAddNew,
    multiselect = true,
  } = props;

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newOptionName, setNewOptionName] = useState('');

  const getChip = (chipType: string, value: string, label: string) => {
    let result = <CultureChip key={value} label={label} />;
    if (chipType === 'placement') {
      result = <PlacementChip key={value} label={label} />;
    }
    if (chipType === 'method') {
      result = <MethodChip key={value} label={label} />;
    }
    return result;
  };

  const sortFunction = (a: any, b: any) => {
    if (a.label == 'Other') return 1;
    if (b.label == 'Other') return -1;
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  };

  return (
    <>
      <Modal
        isOpen={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        title={'Add a new culture/religion?'}
      >
        <>
          <CustomTextInput
            className='w-full'
            value={newOptionName}
            readOnly={readOnly}
            placeholder={
              'What is the name of the culture/religion you want to add?'
            }
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              setNewOptionName(ev.target.value);
            }}
            type={'text'}
          />
          <div className='w-full ml-2'>
            <Button
              title={'Save'}
              className={`ml-auto mt-4 text-forest-primary bg-white`}
              onClick={() => {
                setAddModalOpen(false);
                if (handleAddNew && newOptionName) {
                  handleAddNew(newOptionName);
                }
              }}
            ></Button>
          </div>
        </>
      </Modal>
      <Select
        readOnly={readOnly}
        onOpen={handleClick}
        error={error}
        name={name}
        fullWidth={true}
        variant='outlined'
        className={`h-14 min-w-b3 ${className}`}
        multiple={multiselect}
        value={values}
        onChange={handleChange}
        renderValue={(selected) =>
          options
            .filter((o) => selected.indexOf(o.value) >= 0)
            .map((o) =>
              chipType ? (
                getChip(chipType, o.value, o.label)
              ) : (
                <CultureChip key={o.value} label={o.label} />
              )
            )
        }
        MenuProps={MenuProps}
      >
        {handleAddNew !== undefined && (
          <MenuItem key={`add-new`}>
            <div className='w-full pl-4' onClick={() => setAddModalOpen(true)}>
              Add Your Own Culture/Religion
            </div>
          </MenuItem>
        )}
        {!noSort
          ? options
              .sort((a: any, b: any) => sortFunction(a, b))
              .map((option) => (
                <MenuItem
                  key={`${option.label}-${option.value}`}
                  value={option.value}
                >
                  <Checkbox checked={values.indexOf(option.value) > -1} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))
          : options.map((option) => (
              <MenuItem
                key={`${option.label}-${option.value}`}
                value={option.value}
              >
                <Checkbox checked={values.indexOf(option.value) > -1} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
      </Select>
      {helperText && (
        <FormHelperText className='text-red-error ml-6'>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};
