import { NavigateFunction } from 'react-router-dom';

import { Ceremony, Member } from '../../api/ceremony';
import {
  ChecklistItemDefinition,
  MemberChecklistItem,
} from '../../api/checklistItemDefinitions';
import { Module } from '../../hooks/useActiveModule';
import useUser from '../../hooks/useUser';
import { useAuth } from '../../provider/authProvider';
import { useDashboard } from '../../provider/dashboardProvider';
import {
  isWeddingPlannerInCeremony,
  usePlanner,
} from '../../provider/plannerProvider';
import { downloadFile } from '../downloadHelper';

import { ChecklistItemIdentifier } from './identifiers';

export const getMainOnClickHandler = (
  item: ChecklistItemDefinition,
  navigate: NavigateFunction,
  checkAccessToModule: (module: Module) => boolean,
  saveMemberChecklistItem: (
    memberChecklistItem: MemberChecklistItem,
    saveOnUserLevel?: boolean
  ) => Promise<void>,
  setGuestSpeakerModalOpen: (value: React.SetStateAction<boolean>) => void,
  handleShowNPSModal: () => void,
  setChooseCollaboratorModalOpen: (value: boolean) => void,
  ceremonyId?: number,
  currentUser?: Member,
  couple1?: Member,
  couple2?: Member,
  userCeremonies?: Ceremony[]
) => {
  const {
    setYoutubeVideoModalOpen,
    setYoutubeVideoUrl,
    setIsReferralModalOpen,
  } = useDashboard();

  const { weddingPlannerStatus } = usePlanner();
  const { user } = useAuth();

  switch (item.identifier) {
    case ChecklistItemIdentifier.GIFT_PROVENANCE:
      return () => {
       
        window.open('https://www.provenance.co/gifting', '_blank');
        void saveMemberChecklistItem({
          ceremony_id: ceremonyId,
          member_id: currentUser?.id,
          checklist_item_definition_id: item.id,
          completed: true,
        });
      };
    case ChecklistItemIdentifier.DOWNLOAD_PROCESSIONAL_GUIDE:
      return () => {
        if(checkAccessToModule(Module.WelcomeToast)){
          downloadFile(
            `${
              process.env.PUBLIC_URL || ''
            }/documents/Provenance_Processional_Order_Examples.pdf`,
            'Provenance Processional Order Examples.pdf'
          );
          void saveMemberChecklistItem({
            ceremony_id: ceremonyId,
            member_id: currentUser?.id,
            checklist_item_definition_id: item.id,
            completed: true,
          });
        }else{
          ceremonyId && navigate(`/pay-plans/${ceremonyId}?refferedFrom=processional_guide`);
        }
        
      };
    case ChecklistItemIdentifier.CHECKOUT_CEREMONY_BUILDER:
      return () => {
        if (ceremonyId) {
          navigate(`/ceremony-designer/${ceremonyId}`);
        }
      };
    case ChecklistItemIdentifier.INVITE_COLLABORATORS:
      return () => {
        setChooseCollaboratorModalOpen(true);
      };
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_ONE:
      return () => {
        if (ceremonyId) {
          if (checkAccessToModule(Module.DirectionalQuestions)) {
            navigate(`/ceremony/${ceremonyId}/logistics`);
          } else {
            navigate(`/ceremony-designer/${ceremonyId}`);
          }
        }
      };
    case ChecklistItemIdentifier.GATHER_REFLECTIONS:
      return () => {
        if (ceremonyId) {
          navigate(`/ceremony/${ceremonyId}/reflections`);
        }
      };
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_TWO:
      return () => {
        if (ceremonyId) {
          if (checkAccessToModule(Module.OfficiantRemarks)) {
            navigate(`/ceremony/${ceremonyId}/love-story`);
          } else {
            navigate(`/ceremony-designer/${ceremonyId}`);
          }
        }
      };
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_THREE:
      return () => {
        if (ceremonyId) {
          if (checkAccessToModule(Module.RitualsAndReadings)) {
            navigate(`/ceremony/${ceremonyId}/readings`);
          } else {
            navigate(`/ceremony-designer/${ceremonyId}`);
          }
        }
      };
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_FOUR:
      return () => {
        if (ceremonyId) {
          if (checkAccessToModule(Module.VowsCeremony)) {
            navigate(`/ceremony/${ceremonyId}/vows-ceremony`);
          } else {
            navigate(`/ceremony-designer/${ceremonyId}`);
          }
        }
      };
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_FIVE:
      return () => {
        if (ceremonyId) {
          if (checkAccessToModule(Module.DeclarationOfIntent)) {
            navigate(`/ceremony/${ceremonyId}/ring-exchange`);
          } else {
            navigate(`/ceremony-designer/${ceremonyId}`);
          }
        }
      };
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_SIX:
      return () => {
        if (ceremonyId) {
          if (checkAccessToModule(Module.PronouncementCelebration)) {
            navigate(`/ceremony/${ceremonyId}/pronouncement`);
          } else {
            navigate(`/ceremony-designer/${ceremonyId}`);
          }
        }
      };
    case ChecklistItemIdentifier.FINALIZE_CEREMONY_SCRIPT:
      return () => {
        if (ceremonyId) {
          navigate(`/ceremony-designer/${ceremonyId}`);
        }
      };
    case ChecklistItemIdentifier.START_VOWS:
      return () => {
        if (ceremonyId) {
          navigate(`/ceremony/${ceremonyId}/vowbuilder`);
        }
      };
    case ChecklistItemIdentifier.REVIEW_PARTNER_A_VOWS:
      return () => {
        if (ceremonyId && couple1) {
          navigate(`/preview-vows/${ceremonyId}/${couple1?.id || ''}`);
          void saveMemberChecklistItem({
            ceremony_id: ceremonyId,
            member_id: currentUser?.id,
            checklist_item_definition_id: item.id,
            completed: true,
          });
        }
      };
    case ChecklistItemIdentifier.REVIEW_PARTNER_B_VOWS:
      return () => {
        if (ceremonyId && couple2) {
          navigate(`/preview-vows/${ceremonyId}/${couple2?.id || ''}`);
          void saveMemberChecklistItem({
            ceremony_id: ceremonyId,
            member_id: currentUser?.id,
            checklist_item_definition_id: item.id,
            completed: true,
          });
        }
      };
    case ChecklistItemIdentifier.INVITE_PARTNER_TO_VOWS:
      return () => {
        if (ceremonyId) {
          navigate(`/ceremony/${ceremonyId}/vowbuilder`);
        }
      };
    case ChecklistItemIdentifier.WRITE_WELCOME_TOAST:
      return () => {
        if (ceremonyId) {
          navigate(`/ceremony/${ceremonyId}/toastbuilder`);
        }
      };
    case ChecklistItemIdentifier.GET_SPEECHWRITING_HELP:
      return () => {
        if (ceremonyId) {
          navigate(`/ceremony/${ceremonyId}/speechwriting`);
        }
      };
    case ChecklistItemIdentifier.GIVE_ACCESS_TO_TOASTBUILDER:
      return () => {
        if (ceremonyId) {
          if (checkAccessToModule(Module.InviteGuests)) {
            setGuestSpeakerModalOpen(true);
          } else {
            navigate(`/ceremony/${ceremonyId}/toastbuilder`);
          }
        }
      };
    case ChecklistItemIdentifier.REVIEW_SCRIPT_WITH_OFFICIANT:
      return () => {
        if (ceremonyId) {
          navigate(`/ceremony-designer/${ceremonyId}`);
        }
      };
    case ChecklistItemIdentifier.REVIEW_SCRIPT_WITH_COUPLE:
      return () => {
        if (ceremonyId) {
          navigate(`/ceremony-designer/${ceremonyId}`);
        }
      };
    case ChecklistItemIdentifier.DOWNLOAD_VOWS:
      return () => {
        if (ceremonyId) {
          navigate(`/ceremony/${ceremonyId}/vowbuilder`);
        }
      };
    case ChecklistItemIdentifier.DOWNLOAD_SCRIPT:
      return () => {
        if (ceremonyId) {
          navigate(`/ceremony-designer/${ceremonyId}`);
        }
      };
    case ChecklistItemIdentifier.GET_PUBLIC_SPEAKING_HELP:
      return () => {
        if (ceremonyId) {
          navigate(`/ceremony/${ceremonyId}/speechwriting`);
        }
      };
    case ChecklistItemIdentifier.DOWNLOAD_CHECKLIST_PDF:
      return () => {
        downloadFile(
          `${process.env.PUBLIC_URL || ''}/documents/ceremony_checklist.pdf`,
          "Provenance's_Day_of_Checklist.pdf"
        );
        void saveMemberChecklistItem({
          ceremony_id: ceremonyId,
          member_id: currentUser?.id,
          checklist_item_definition_id: item.id,
          completed: true,
        });
      };
    case ChecklistItemIdentifier.DOWNLOAD_PROCESSIONAL_TIPS_PDF:
      return () => {
        downloadFile(
          `${process.env.PUBLIC_URL || ''}/documents/processional_tips.pdf`,
          "Provenance'Processional_and_rehearsal_tips.pdf"
        );
        void saveMemberChecklistItem({
          ceremony_id: ceremonyId,
          member_id: currentUser?.id,
          checklist_item_definition_id: item.id,
          completed: true,
        });
      };
    case ChecklistItemIdentifier.REFER_FRIENDS:
      return () => {
        setIsReferralModalOpen(true);
      };
    case ChecklistItemIdentifier.WRITE_THANK_YOU_NOTES:
      return () => {
        if (ceremonyId) {
          navigate(`/ceremony/${ceremonyId}/thankyounotes`);
        }
      };
    case ChecklistItemIdentifier.GIVE_FEEDBACK:
      return () => {
        handleShowNPSModal();
      };
    case ChecklistItemIdentifier.BUY_WEDDING_BOOK:
      return () => {
        window.open('https://www.provenance.co/book', '_blank');
        void saveMemberChecklistItem({
          ceremony_id: ceremonyId,
          member_id: currentUser?.id,
          checklist_item_definition_id: item.id,
          completed: true,
        });
      };
    //WEEDING PLANNER
    case ChecklistItemIdentifier.PLANNER_PORTAL_DEMO:
      return () => {
        setYoutubeVideoUrl(item.URL);
        setYoutubeVideoModalOpen(true);

        void saveMemberChecklistItem({
          checklist_item_definition_id: item.id,
          completed: true,
        });
      };
    case ChecklistItemIdentifier.INVITE_FIRST_CLIENT:
      return () => {
        navigate('/wp-add-ceremony');
      };
    case ChecklistItemIdentifier.REVIEW_SPEECHES:
      return () => {
        const filteredCeremonies = userCeremonies?.filter((ceremony) =>
          isWeddingPlannerInCeremony(user, ceremony)
        );
        if (filteredCeremonies && filteredCeremonies.length > 0) {
          const firstCeremony = filteredCeremonies.sort(
            (a, b) => a.id - b.id
          )[0];
          navigate(`/ceremony/${firstCeremony.id}/planner`);
        } else {
          navigate('/ceremony/planner/client-list');
        }
      };

    case ChecklistItemIdentifier.INVITE_TOAST_GIVERS:
      return () => {
        const filteredCeremonies = userCeremonies?.filter((ceremony) =>
          isWeddingPlannerInCeremony(user, ceremony)
        );
        if (filteredCeremonies && filteredCeremonies.length > 0) {
          const firstCeremony = filteredCeremonies.sort(
            (a, b) => a.id - b.id
          )[0];
          navigate(`/ceremony/${firstCeremony.id}/planner`);
        } else {
          navigate('/ceremony/planner/client-list');
        }
      };

    case ChecklistItemIdentifier.EXPLORE_RESOURCES:
      return () => {
        void saveMemberChecklistItem({
          checklist_item_definition_id: item.id,
          completed: true,
        });

        navigate(`/ceremony/planner/client-resources`);
      };
    case ChecklistItemIdentifier.ADD_REST_OF_CLIENTS:
      return () => {
        if (weddingPlannerStatus?.hasActiveSubscription) {
          navigate(`/wp-add-ceremony`);
        } else {
          navigate(`/wp-pay-plans/`);
        }
      };

    case ChecklistItemIdentifier.UNIQUE_LINK:
      return () => {
        if (weddingPlannerStatus?.hasActiveSubscription) {
          navigate(`/wp-add-ceremony`);
        } else {
          navigate(`/wp-pay-plans/`);
        }
        window.open(
          'https://docs.google.com/document/d/1ThIavGEQcwC9EzOcaApTqpgqa0uijvGbdEc3qPons3M/edit#heading=h.e1r6pnw6fdln',
          '_blank'
        );
      };
    case ChecklistItemIdentifier.REFER_CLIENTS:
      return () => {
        setIsReferralModalOpen(true);
      };
    // GUEST
    case ChecklistItemIdentifier.WRITE_TOAST:
      return () => {
        if (ceremonyId) {
          navigate(`/ceremony/${ceremonyId}/guest`);
        }
      };
    case ChecklistItemIdentifier.GUEST_GET_SPEECHWRITING_HELP:
      return () => {
        if (ceremonyId) {
          navigate(`/ceremony/${ceremonyId}/speechwriting`);
        }
      };
    case ChecklistItemIdentifier.GUEST_GET_PUBLIC_SPEAKING_HELP:
      return () => {
        if (ceremonyId) {
          navigate(`/ceremony/${ceremonyId}/speechwriting`);
        }
      };
    case ChecklistItemIdentifier.REVIEW_TOAST_WITH_COUPLE:
      return () => {
        if (ceremonyId) {
          navigate(`/ceremony/${ceremonyId}/guest`);
        }
      };
    case ChecklistItemIdentifier.DOWNLOAD_TOAST:
      return () => {
        if (ceremonyId) {
          navigate(`/ceremony/${ceremonyId}/guest`);
        }
      };
    case ChecklistItemIdentifier.GUEST_GIVE_FEEDBACK:
      return () => {
        console.log('GUEST_GIVE_FEEDBACK');
        handleShowNPSModal();
      };
    case ChecklistItemIdentifier.GUEST_REFER_FRIENDS:
      return () => {
        setIsReferralModalOpen(true);
      };
    default:
      return () => {};
  }
};
