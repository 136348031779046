/* eslint-disable @typescript-eslint/no-empty-function */
import moment from 'moment';
import React, { useContext } from 'react';

import { BuilderContextType, useBuilder } from '../hooks/useBuilder';

export const BuilderContext = React.createContext<BuilderContextType>({
  generateDraftInitiated: false,
  setGenerateDraftInitiated: () => {},
  getQAStatus: async () => {},
  textGenerated: false,
  setTextGenerated: () => {},
  textGenerateComplete: false,
  setTextGenerateComplete: () => {},
  versionHistoryModalOpen: false,
  setVersionHistoryModalOpen: () => {},
  aiGeneratedText: '',
  setAiGeneratedText: () => {},
  aiGeneratedTextRendered: '',
  setAiGeneratedTextRendered: () => {},
  aiGeneratedTextIndex: 0,
  setAiGeneratedTextIndex: () => {},
  textGenerating: false,
  setTextGenerating: () => {},
  selectedVersionId: 0,
  setSelectedVersionId: () => {},
  versions: [],
  setVersions: () => {},
  totalMinutes: 0,
  setTotalMinutes: () => {},
  draftingModalOpen: false,
  setDraftingModalOpen: () => {},
  completedModalOpen: false,
  setCompletedModalOpen: () => {},
  generationError: false,
  setGenerationError: () => {},
  getMomentOfLatestVersion: () => moment(),
});

export const ToastBuilderProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    generateDraftInitiated,
    setGenerateDraftInitiated,
    getQAStatus,
    textGenerated,
    setTextGenerated,
    textGenerateComplete,
    setTextGenerateComplete,
    versionHistoryModalOpen,
    setVersionHistoryModalOpen,
    setSelectedVersionId,
    setTotalMinutes,
    aiGeneratedText,
    setAiGeneratedText,
    aiGeneratedTextRendered,
    setAiGeneratedTextRendered,
    aiGeneratedTextIndex,
    setAiGeneratedTextIndex,
    textGenerating,
    setTextGenerating,
    selectedVersionId,
    versions,
    setVersions,
    totalMinutes,
    draftingModalOpen,
    setDraftingModalOpen,
    completedModalOpen,
    setCompletedModalOpen,
    generationError,
    setGenerationError,
    getMomentOfLatestVersion,
  } = useBuilder();

  const value = {
    generateDraftInitiated,
    setGenerateDraftInitiated,
    getQAStatus,
    textGenerated,
    setTextGenerated,
    textGenerateComplete,
    setTextGenerateComplete,
    versionHistoryModalOpen,
    setVersionHistoryModalOpen,
    setSelectedVersionId,
    setTotalMinutes,
    aiGeneratedText,
    setAiGeneratedText,
    aiGeneratedTextRendered,
    setAiGeneratedTextRendered,
    aiGeneratedTextIndex,
    setAiGeneratedTextIndex,
    textGenerating,
    setTextGenerating,
    selectedVersionId,
    versions,
    setVersions,
    totalMinutes,
    draftingModalOpen,
    setDraftingModalOpen,
    completedModalOpen,
    setCompletedModalOpen,
    generationError,
    setGenerationError,
    getMomentOfLatestVersion,
  };
  // @ts-ignore
  return (
    <BuilderContext.Provider value={value}>{children}</BuilderContext.Provider>
  );
};

export const useToastBuilder = () => {
  return useContext(BuilderContext);
};
