import { Collapse } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { IoChevronDownOutline, IoChevronForwardOutline } from 'react-icons/io5';

import { OutputData } from '../../api/output';
import Button from '../../components/v2/Button/Button';
import { Separator } from '../../components/v2/Separator/Separator';
import { Typography } from '../../components/v2/Typography/Typography';

import { VersionHistoryConfirmModal } from './VersionHistoryConfirmModal';

type VersionHistoryItemProps = {
  version: OutputData;
  onConfirmSelect: () => void;
  variant?: 'toast' | 'vows' | 'ceremony' | 'welcomeToast';
  isCurrentVersion?: boolean;
};

const VersionHistoryItem = (props: VersionHistoryItemProps) => {
  const { version, onConfirmSelect, variant, isCurrentVersion } = props;
  const [expanded, setExpanded] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  return (
    <div
      className={`rounded-3xl ${
        isCurrentVersion
          ? 'border-[3px] border-copper-primary'
          : 'border-forest-100'
      }  p-6 border mt-2`}
    >
      <VersionHistoryConfirmModal
        isOpen={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        variant={variant}
        handleConfirm={() => {
          setConfirmModalOpen(false);
          onConfirmSelect();
        }}
      />
      <div className='flex flex-row w-full justify-between'>
        <div className='flex flex-row items-center'>
          <div className='mr-2' onClick={() => setExpanded((old) => !old)}>
            {expanded ? (
              <IoChevronDownOutline
                className={isCurrentVersion ? 'text-copper-primary' : ''}
              />
            ) : (
              <IoChevronForwardOutline
                className={isCurrentVersion ? 'text-copper-primary' : ''}
              />
            )}
          </div>
          <div>
            <Typography
              type='body-400'
              variant='functional-low'
              className={isCurrentVersion ? 'text-copper-primary' : ''}
            >
              {moment(version.updatedAt).format('MMM Do, YYYY')}
            </Typography>
            <Typography
              type='body-200'
              variant='functional-low'
              className={
                isCurrentVersion ? 'text-copper-primary' : 'text-forest-300'
              }
            >
              {isCurrentVersion
                ? 'Current Version'
                : moment(version.updatedAt).format('h:mm a')}
            </Typography>
          </div>
        </div>
        {!isCurrentVersion && (
          <Button variant='text' onClick={() => setConfirmModalOpen(true)}>
            Restore
          </Button>
        )}
      </div>
      <Collapse className='w-full' in={expanded} unmountOnExit>
        <Separator className='my-6 border-forest-100' />
        <Typography
          className='w-full '
          type='body-400'
          variant='functional-low'
        >
          <div
            className='w-full'
            dangerouslySetInnerHTML={{ __html: version.content }}
          />
        </Typography>
      </Collapse>
    </div>
  );
};

export default VersionHistoryItem;
