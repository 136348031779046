import React, { useEffect, useState } from 'react';

import { SaveWaitlistQABody, WaitListQuestions } from '../../../api/waitlist';
import CustomTextInput from '../../../components/forms/CustomTextInput';

type TextQuestionProps = {
  question: WaitListQuestions;
  updateAnswer: (qid: string, oid: string, textans: string) => void;
  answer?: SaveWaitlistQABody;
};
const TextQuestion = ({
  question,
  answer,
  updateAnswer,
}: TextQuestionProps) => {
  const handleTextChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    updateAnswer(question.id.toString(), answer?.oid ?? '', ev.target.value);
  };
  return (
    <div className='col-span-4 md:col-span-6 lg:col-span-12 w-full my-4'>
      <div className='mx-2 my-3 font-nanum text-2xl leading-8'>
        {question.question}
      </div>
      <CustomTextInput
        className='mt-4 bg-white'
        multiline
        rows={5}
        value={answer?.textans}
        onChange={handleTextChange}
        placeholder={'Type here...'}
      />
    </div>
  );
};

export default TextQuestion;
