/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

import {
  Member,
  MemberSubType,
  MemberType,
  newCeremony,
} from '../api/ceremony';
import { Speaker } from '../api/friends';
import { getAPIErrorMessage } from '../helpers/helper';
import { getFullName } from '../helpers/nameHelper';

import { useAuth } from './authProvider';
import { useSnackbar } from './snackbarProvider';

type CreateEventContextType = {
  memberType: string;
  allEmails: string[];
  officiants: Member[];
  setOfficiants: (value: Member[]) => void;
  speakers: Speaker[];
  setSpeakers: (value: Speaker[]) => void;
  eventRoleModalOpen: boolean;
  eventGuestRoleModalOpen: boolean;
  eventDateModalOpen: boolean;
  eventPartnerNamesModalOpen: boolean;
  eventAddOfficiantModalOpen: boolean;
  eventAddSpeakerModalOpen: boolean;
  setEventRoleModalOpen: (value: boolean) => void;
  setEventGuestRoleModalOpen: (value: boolean) => void;
  setEventDateModalOpen: (value: boolean) => void;
  setEventPartnerNamesModalOpen: (value: boolean) => void;
  setEventEventAddOfficiantModalOpen: (value: boolean) => void;
  setEventAddSpeakerModalOpen: (value: boolean) => void;
  handleAddOfficiantModalNextClick: () => void;
  handleAddOfficiantModalBackClick: () => void;
  handleAddSpeakerModalNextClick: () => Promise<void>;
  handleAddSpeakerModalBackClick: () => void;
  handleEventRoleModalNextClick: (memberType: string) => void;
  handleEventGuestRoleModalNextClick: (memberSubType: MemberSubType) => void;
  handleEventGuestRoleModaBackClick: () => void;
  handleEventDateModalNextClick: (date: string | null) => void;
  handleEventDateModalBackClick: () => void;
  handlePartnerNamesModalBackClick: () => void;
  handlePartnerNamesModalNextClick: (
    partnerAName: string,
    partnerAEmail: string,
    partnerARole: MemberType,
    partnerBName: string,
    partnerBEmail: string,
    partnerBRole: MemberType
  ) => void;
};

export const CreateEventContext = React.createContext<CreateEventContextType>({
  memberType: MemberType.bride,
  allEmails: [],
  officiants: [],
  setOfficiants: () => {},
  speakers: [],
  setSpeakers: () => {},
  eventRoleModalOpen: false,
  eventGuestRoleModalOpen: false,
  eventDateModalOpen: false,
  eventPartnerNamesModalOpen: false,
  eventAddOfficiantModalOpen: false,
  eventAddSpeakerModalOpen: false,
  setEventRoleModalOpen: () => {},
  setEventGuestRoleModalOpen: () => {},
  setEventDateModalOpen: () => {},
  setEventPartnerNamesModalOpen: () => {},
  setEventEventAddOfficiantModalOpen: () => {},
  setEventAddSpeakerModalOpen: () => {},
  handleAddOfficiantModalNextClick: () => {},
  handleAddOfficiantModalBackClick: () => {},
  handleAddSpeakerModalNextClick: async () => {},
  handleAddSpeakerModalBackClick: () => {},
  handleEventRoleModalNextClick: () => {},
  handleEventGuestRoleModalNextClick: () => {},
  handleEventGuestRoleModaBackClick: () => {},
  handleEventDateModalNextClick: () => {},
  handleEventDateModalBackClick: () => {},
  handlePartnerNamesModalBackClick: () => {},
  handlePartnerNamesModalNextClick: () => {},
});

export const CreateEventProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [eventRoleModalOpen, setEventRoleModalOpen] = useState(false);
  const [eventGuestRoleModalOpen, setEventGuestRoleModalOpen] = useState(false);
  const [eventDateModalOpen, setEventDateModalOpen] = useState(false);
  const [eventPartnerNamesModalOpen, setEventPartnerNamesModalOpen] =
    useState(false);
  const [eventAddOfficiantModalOpen, setEventEventAddOfficiantModalOpen] =
    useState(false);
  const [eventAddSpeakerModalOpen, setEventAddSpeakerModalOpen] =
    useState(false);
  const [members, setMembers] = useState<Member[]>([]);
  const [officiants, setOfficiants] = useState<Member[]>([]);
  const [speakers, setSpeakers] = useState<Speaker[]>([]);
  const [memberType, setMemberType] = useState<string>('');
  const [memberSubType, setMemberSubType] = useState<MemberSubType>();
  const [ceremonyDate, setCeremonyDate] = useState<string | null>(null);
  const [submitData, setSubmitData] = useState<boolean>(false);
  const [allEmails, setAllEmails] = useState<string[]>([]);

  const auth = useAuth();
  const navigate = useNavigate();
  const { openSnackBar } = useSnackbar();

  useEffect(() => {
    const result: string[] = [];
    if (members && members.length > 0) {
      members.forEach((m) => {
        const email = m.email;
        if (email) {
          result.push(email);
        }
      });
    }
    if (officiants && officiants.length > 0) {
      officiants.forEach((m) => {
        const email = m.email;
        if (email) {
          result.push(email);
        }
      });
    }
    if (speakers && speakers.length > 0) {
      speakers.forEach((m) => {
        const email = m.member.email;
        if (email) {
          result.push(email);
        }
      });
    }
    setAllEmails(result);
  }, [members, officiants, speakers]);

  // useEffect(() => {
  //   if (userCeremoniesLoaded && !userCeremonies) {
  //     setTimeout(() => {
  //       setEventRoleModalOpen(true);
  //     }, 1200);
  //   }
  // }, [userCeremonies, userCeremoniesLoaded]);

  useEffect(() => {
    if (submitData && members.length > 0) {
      void handleCompleteClick();
    }
  }, [submitData, members]);

  const handleEventRoleModalNextClick = (memberType: string) => {
    setMemberType(memberType);
    if (memberType === MemberType.guests) {
      setEventRoleModalOpen(false);
      setEventGuestRoleModalOpen(true);
    } else {
      setEventRoleModalOpen(false);
      setEventDateModalOpen(true);
    }
  };

  const handleEventGuestRoleModalNextClick = (memberSubType: MemberSubType) => {
    setMemberSubType(memberSubType);
    setEventGuestRoleModalOpen(false);
    setEventDateModalOpen(true);
  };

  const handleEventGuestRoleModaBackClick = () => {
    setEventGuestRoleModalOpen(false);
    setEventRoleModalOpen(true);
  };

  const handleEventDateModalNextClick = (date: string | null) => {
    setCeremonyDate(date);
    setEventDateModalOpen(false);

    setEventPartnerNamesModalOpen(true);
  };

  const handleEventDateModalBackClick = () => {
    if (memberType === MemberType.guests) {
      setEventDateModalOpen(false);
      setEventGuestRoleModalOpen(true);
    } else {
      setEventDateModalOpen(false);
      setEventRoleModalOpen(true);
    }
  };

  const handlePartnerNamesModalBackClick = () => {
    setEventPartnerNamesModalOpen(false);
    setEventDateModalOpen(true);
  };

  const handlePartnerNamesModalNextClick = (
    partnerAName: string,
    partnerAEmail: string,
    partnerARole: MemberType,
    partnerBName: string,
    partnerBEmail: string,
    partnerBRole: MemberType
  ) => {
    const members: Member[] = [
      {
        legal_name: partnerAName,
        preferred_name: partnerAName.split(' ')[0],
        member_type: partnerARole,
        ...(partnerAEmail && { email: partnerAEmail }),
      },
      {
        legal_name: partnerBName,
        preferred_name: partnerBName.split(' ')[0],
        member_type: partnerBRole,
        ...(partnerBEmail && { email: partnerBEmail }),
      },
    ];
    if (memberType === 'couple') {
      members[0].email = auth.user.email;
    } else {
      members.push({
        legal_name: getFullName(auth.user),
        preferred_name: auth.user.first_name,
        member_type:
          memberType === 'officiant'
            ? MemberType.officiant
            : memberType === 'wedding_planner'
            ? MemberType.weddingPlanner
            : MemberType.guests,
        email: auth.user.email,
        ...(memberType === MemberType.guests && {
          member_sub_type: memberSubType,
        }),
      });
    }

    setMembers(members);

    if (memberType === MemberType.weddingPlanner) {
      setEventEventAddOfficiantModalOpen(true);
    } else {
      setSubmitData(true);
    }

    setEventPartnerNamesModalOpen(false);
  };

  const handleCompleteClick = async () => {
    try {
      const response = await newCeremony({
        date: ceremonyDate ?? undefined,
        members: members,
        speakers: speakers,
        officiants: officiants,
      });
      if (response.success) {
        ReactGA.event({
          category: 'Ceremony',
          action: 'Create',
          label: 'Creation Complete',
        });

        openSnackBar('Event created successfully');
        await auth.getProfile(() => {
          navigate(
            response.cer_id ? `/ceremony/${response.cer_id}` : '/dashboard'
          );
        });
        //hideLoader();
      } else {
        //hideLoader();
        openSnackBar(response.message, 'error');
      }
    } catch (err) {
      //hideLoader();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      openSnackBar(getAPIErrorMessage(err as any), 'error');
    }
  };

  const handleAddOfficiantModalNextClick = () => {
    setEventEventAddOfficiantModalOpen(false);
    setEventAddSpeakerModalOpen(true);
  };

  const handleAddOfficiantModalBackClick = () => {
    setEventEventAddOfficiantModalOpen(false);
    setEventPartnerNamesModalOpen(true);
  };

  const handleAddSpeakerModalNextClick = async () => {
    setEventAddSpeakerModalOpen(false);
    await handleCompleteClick();
  };

  const handleAddSpeakerModalBackClick = () => {
    setEventAddSpeakerModalOpen(false);
    setEventEventAddOfficiantModalOpen(true);
  };

  const value = {
    memberType,
    allEmails,
    officiants,
    setOfficiants,
    speakers,
    setSpeakers,
    eventRoleModalOpen,
    eventGuestRoleModalOpen,
    eventDateModalOpen,
    eventPartnerNamesModalOpen,
    eventAddOfficiantModalOpen,
    eventAddSpeakerModalOpen,
    setEventRoleModalOpen,
    setEventGuestRoleModalOpen,
    setEventDateModalOpen,
    setEventPartnerNamesModalOpen,
    setEventEventAddOfficiantModalOpen,
    setEventAddSpeakerModalOpen,
    handleAddOfficiantModalNextClick,
    handleAddOfficiantModalBackClick,
    handleAddSpeakerModalNextClick,
    handleAddSpeakerModalBackClick,
    handleEventRoleModalNextClick,
    handleEventGuestRoleModalNextClick,
    handleEventGuestRoleModaBackClick,
    handleEventDateModalNextClick,
    handleEventDateModalBackClick,
    handlePartnerNamesModalBackClick,
    handlePartnerNamesModalNextClick,
  };
  // @ts-ignore
  return (
    <CreateEventContext.Provider value={value}>
      {children}
    </CreateEventContext.Provider>
  );
};

export const useCreateEvent = () => {
  return useContext(CreateEventContext);
};
