import './index.scss';

type ButtonProps = {
  onClick?: (() => void) | (() => Promise<void>);
  title?: string;
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  small?: boolean;
  whiteBorder?: boolean;
};

const Button = ({
  onClick = () => undefined,
  title = '',
  disabled = false,
  className = '',
  small = false,
  whiteBorder = false,
  ...props
}: ButtonProps) => {
  return (
    <button
      onClick={() => {
        if (!disabled) {
          void onClick();
        }
      }}
      disabled={disabled}
      className={`${
        small
          ? 'px-4 py-2 min-w-b1'
          : 'min-w-b1 md:min-w-b1 lg:min-w-b2 xl:min-w-b3 2xl:min-w-b4 px-4 xl:px-6 2xl:px-8  py-3 xl:py-4 2xl:py-5'
      } 
      uppercase text-base flex justify-center items-center border  ${
        disabled ? 'cursor-not-allowed bg-gray-700' : 'cursor-pointer '
      }  ${
        whiteBorder ? 'border-white' : 'border-forest-primary '
      } ${className}`}
      {...props}
    >
      {title}
    </button>
  );
};

export default Button;
