/* eslint-disable -- TODO: fix eslint errors */

import { useCallback, useState } from 'react';
import Loader from '../components/Loader/loader';
import { useLocalStorage } from './useLocalStorage';

export enum Module {
  DirectionalQuestions = 'directional',
  RitualsAndReadings = 'rituals',
  Vows = 'vows',
  DeclarationOfIntent = 'declaration',
  Pronouncement = 'pronouncement',
  FriendsAndFamily = 'friends',
  OfficiantRemarks = 'remarks',
  Couple1 = 'couple1',
  Couple2 = 'couple2',
  GuestFFQ = 'guests_ffq',
  GuestToast = 'guests_toast_builder',
  GuestToastAi = 'guests_toast_builder_ai',
  VowsReflectional = 'vows_reflectional',
  VowsPromises = 'vows_promises',
  VowsFuture = 'vows_future',
  VowsAi = 'vows_ai',
  InviteOfficiant = 'invite_officiant',
  InviteGuests = 'invite_guests',
  InviteWeddingPlanner = 'invite_wedding_planner',
  VowsCeremony = 'vows_ceremony',
  PronouncementCelebration = 'pronouncement_celebration',
  WelcomeToast= 'welcome_toast',
}

const useActiveModule = () => {
  const [activeModule, setModule] = useLocalStorage(
    'activeModule',
    Module.DirectionalQuestions
  );

  const setActiveModule = useCallback((module: string) => {
    setModule(module);
  }, []);

  return { activeModule, setActiveModule };
};

export default useActiveModule;
