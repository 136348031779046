import Button from '../../components/v2/Button/Button';
import { Modal } from '../../components/v2/Modal/Modal';
import { Typography } from '../../components/v2/Typography/Typography';

type VersionHistoryConfirmModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleConfirm: () => void;
  variant?: 'toast' | 'vows' | 'ceremony' | 'welcomeToast';
};

export const VersionHistoryConfirmModal = (
  props: VersionHistoryConfirmModalProps
) => {
  const { isOpen, onClose, handleConfirm, variant } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <>
        <Typography type='display-100'>
          <>
            {variant === 'vows' &&
              'Are you sure you want to restore your vows to this version?'}
            {variant === 'toast' &&
              'Are you sure you want to restore your toast to this version?'}
            {variant === 'ceremony' &&
              'Are you sure you want to restore your ceremony script to this version?'}
            {variant === 'welcomeToast' &&
              'Are you sure you want to restore your welcome toast to this version?'}
          </>
        </Typography>
        <Typography type='body-400' className='mt-4'>
          <>
            {variant === 'vows' &&
              'Restoring to a previous version will overwrite the current version of your vows.'}
            {variant === 'toast' &&
              'Restoring to a previous version will overwrite the current version of your toast.'}
            {variant === 'ceremony' &&
              'Restoring to a previous version will overwrite the current version of your ceremony script.'}
            {variant === 'welcomeToast' &&
              'Restoring to a previous version will overwrite the current version of your welcome toast.'}
          </>
        </Typography>
        <div className='mt-8 flex flex-row justify-end'>
          <Button
            variant='secondary'
            onClick={handleConfirm}
            className='mr-2 text-forest-primary'
          >
            Yes, Restore
          </Button>
          <Button onClick={onClose}>No, Don't Restore</Button>
        </div>
      </>
    </Modal>
  );
};
