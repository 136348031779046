/* eslint-disable */
import { useNavigate, useParams } from 'react-router-dom';

import { ModuleQuestion, ModuleWrapper } from '../components';

import shapesIcon from '../../../assets/images/icons/shapes.svg';
import useQuestions from '../../../hooks/useQuestions';
import useCeremony from '../../../hooks/useCeremony';
import { ModuleStatus } from '../enum';

import { useEffect } from 'react';
import Button from '../../../components/v2/Button/Button';
import { Module } from '../../../hooks/useActiveModule';
import { useCeremonyBuilder } from '../../../provider/ceremonyBuilderProvider';
import { generalLog } from '../../../api/ceremony';
import { CTAProvider } from '../../../provider/ctaProvider';

export const LogisticsModule = ({
  hideWrapper = false,
  onModuleStatusChange,
}: {
  hideWrapper?: boolean;
  onModuleStatusChange?: (status: ModuleStatus) => void;
}) => {
  const navigate = useNavigate();
  const ceremonyId = useParams()?.ceremonyId;

  const { setDraftingModalOpen, setSelectedModule, setGenerateDraftInitiated } =
    useCeremonyBuilder();
  const { questions, fetchQuestions } = useQuestions();
  const {
    ceremony,
    couple1,
    couple2,
    officiant,
    currentUser,
    fetchCeremonyById,
  } = useCeremony();

  const members = { couple1, couple2, officiant, currentUser };

  useEffect(() => {
    if (ceremonyId) {
      void fetchCeremonyById(ceremonyId);
    }
  }, [ceremonyId]);

  useEffect(() => {
    if (ceremony) {
      void fetchQuestions(Module.DirectionalQuestions, ceremony.id.toString());
    }
  }, [ceremony]);

  return (
    <CTAProvider
      totalQuestions={questions?.length || 0}
      requiredQuestions={questions?.length || 0}
      onModuleStatusChange={onModuleStatusChange}
      module={Module.DirectionalQuestions}
    >
      <ModuleWrapper
        hideWrapper={hideWrapper}
        chapterProps={{
          chapter: 'Chapter 1',
          renderIcon: () => <img src={shapesIcon} draggable={false} />,
        }}
        summaryProps={{
          moduleName: 'Welcome & Procession',
          moduleDescription:
            'A wedding ceremony isn’t one-size-fits-all. Every couple’s needs are unique – a combination of cultural tradition and personal nuance. Answering these initial questions below will help ensure that the all of these details come together into a cohesive script — down to the last detail.',
          numberOfQuestions: 7,
          estimatedTime: 5,
        }}
        questionsHeaderProps={{
          renderCenterButton: (
            moduleStatus: ModuleStatus,
            status: 'saving' | 'saved' | 'unsaved'
          ) => (
            <Button
              size='100'
              disabled={
                (moduleStatus !== ModuleStatus.completed &&
                  moduleStatus !== ModuleStatus.requiredAnswered) ||
                status !== 'saved'
              }
              onClick={() => {
                if (ceremonyId) {
                  setSelectedModule(Module.DirectionalQuestions);
                  setDraftingModalOpen(true);
                  setGenerateDraftInitiated(true);
                  generalLog({
                    ceremony_id: ceremonyId,
                    activity: `Generate - Welcome & Procession Module`,
                  });
                  navigate(`/ceremony-designer/${ceremonyId}`);
                } else {
                  navigate(-1);
                }
              }}
            >
              Generate Section
            </Button>
          ),
          renderRightButton: (moduleStatus: ModuleStatus) => (
            <Button
              variant='secondary'
              onClick={() => {
                if (ceremonyId) {
                  navigate(`/ceremony-designer/${ceremonyId}`);
                } else {
                  navigate(-1);
                }
              }}
            >
              Exit & Save
            </Button>
          ),
        }}
      >
        {ceremony &&
          questions &&
          questions.map((questionData, questionIndex) => {
            return (
              <ModuleQuestion
                key={`question-${questionData.id}`}
                ceremonyId={ceremony.id}
                question={questionData}
                questionNumber={questionIndex + 1}
                numberOfQuestions={questions?.length ?? 0}
                members={members}
              />
            );
          })}
      </ModuleWrapper>
    </CTAProvider>
  );
};
