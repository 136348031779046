import { ChecklistItemDefinition } from '../../api/checklistItemDefinitions';
import { Module } from '../../hooks/useActiveModule';

import { ChecklistItemIdentifier } from './identifiers';

export const shouldShowGem = (
  item: ChecklistItemDefinition,
  checkAccessToModule: (module: Module) => boolean
) => {

  

  let hasAccess = false;
  switch (item.identifier) {
    case ChecklistItemIdentifier.DOWNLOAD_PROCESSIONAL_GUIDE:
      hasAccess = checkAccessToModule(Module.WelcomeToast);
      break;
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_ONE:
      hasAccess = checkAccessToModule(Module.DirectionalQuestions);
      break;
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_TWO:
      hasAccess = checkAccessToModule(Module.OfficiantRemarks);
      break;
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_THREE:
      hasAccess = checkAccessToModule(Module.RitualsAndReadings);
      break;
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_FOUR:
      hasAccess = checkAccessToModule(Module.VowsCeremony);
      break;
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_FIVE:
      hasAccess = checkAccessToModule(Module.VowsAi);
      break;
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_SIX:
      hasAccess = checkAccessToModule(Module.PronouncementCelebration);
      break;
    case ChecklistItemIdentifier.START_VOWS:
      hasAccess = checkAccessToModule(Module.VowsAi);
      break;
    case ChecklistItemIdentifier.WRITE_WELCOME_TOAST:
      hasAccess = checkAccessToModule(Module.WelcomeToast);
      break;
    case ChecklistItemIdentifier.GIVE_ACCESS_TO_TOASTBUILDER:
      hasAccess = checkAccessToModule(Module.InviteGuests);
      break;
    case ChecklistItemIdentifier.ADD_REST_OF_CLIENTS:
      hasAccess = checkAccessToModule(Module.DirectionalQuestions);
      break;
    case ChecklistItemIdentifier.UNIQUE_LINK:
      hasAccess = checkAccessToModule(Module.DirectionalQuestions);
      break;
    case ChecklistItemIdentifier.WRITE_TOAST:
      hasAccess = checkAccessToModule(Module.DirectionalQuestions);
      break;
    default:
      hasAccess = false;
      break;
  }
  //show gem when item is paid and user does not have access
  return item.is_paid && !hasAccess;
};
