import { createPasswordApi, signIn, SignInUpResponse } from '../../../api/auth';
import { getAPIErrorMessage } from '../../../helpers/helper';
import { navigateToApp } from '../../../helpers/redirectToAppHelper';
import { useAuth } from '../../../provider/authProvider';
import { useDashboard } from '../../../provider/dashboardProvider';
import { useSnackbar } from '../../../provider/snackbarProvider';
import { Modal } from '../Modal/Modal';

import ResetPasswordForm, { ResetPasswordType } from './ResetPasswordForm';

const CreateNewPasswordModal = () => {
  const {
    createNewPasswordModalOpen,
    setCreateNewPasswordModalOpen,
    currentUser,
    redeemerApp,
    ceremony,
  } = useDashboard();

  const { openSnackBar } = useSnackbar();

  const auth = useAuth();

  const onSubmit = async (values: ResetPasswordType) => {
    try {
      const createPasswordResponse = await createPasswordApi(values);
      if (createPasswordResponse.success) {
        let loginResponse: SignInUpResponse;

        loginResponse = await signIn({
          email: currentUser?.email || '',
          password: values.password,
        });

        if (loginResponse.success) {
          await auth.signin(loginResponse.token, () => {
            if (redeemerApp && ceremony) {
              openSnackBar('Creating secure connection...', 'success');
              void navigateToApp(ceremony.id, redeemerApp);
            }
          });
        } else {
          //hideLoader();
          openSnackBar(loginResponse.message, 'error');
        }
      }
    } catch (err) {
      //hideLoader();
      openSnackBar(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getAPIErrorMessage(err as any),
        'error'
      );
    }

    setCreateNewPasswordModalOpen(false);
  };

  return (
    <Modal
      isOpen={createNewPasswordModalOpen}
      onClose={() => setCreateNewPasswordModalOpen(false)}
    >
      <ResetPasswordForm isReset={false} onSubmit={onSubmit} />
    </Modal>
  );
};

export default CreateNewPasswordModal;
