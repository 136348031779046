import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import './CircularProgressBar.scss';

import ChecklistCompleteCheckmark from '../../../assets/images/svg/checklist-complete-checkmark.svg';

import traingleSvg from './images/traingle.svg';

const circularProgressBar = (
  steps: number,
  totalSteps: number,
  showPercentage = false,
  isError = false
) => {
  const percentage = Math.round((steps / totalSteps) * 100);

  if (isError) {
    return (
      <div className={`${!showPercentage ? 'translate' : ''}`}>
        <CircularProgressbarWithChildren
          value={5}
          strokeWidth={3}
          styles={buildStyles({
            pathColor: '#994330',
            textColor: '#354740',
            trailColor: '#CCD1CF',
            textSize: '18px',
          })}
        >
          <img src={traingleSvg} alt='error triangle' />
        </CircularProgressbarWithChildren>
      </div>
    );
  }

  if (percentage !== 100) {
    return (
      <div className={`${!showPercentage ? 'translate' : ''}`}>
        <CircularProgressbar
          value={percentage}
          text={showPercentage ? `${steps}%` : `${steps}/${totalSteps}`}
          strokeWidth={3}
          styles={buildStyles({
            pathColor: '#354740',
            textColor: '#354740',
            trailColor: '#CCD1CF',
            textSize: '18px',
          })}
        />
        {!showPercentage && (
          <div className='CircularProgressBar__extra-text'>Complete</div>
        )}
      </div>
    );
  } else {
    return (
      <div className={`${!showPercentage ? 'translate' : ''}`}>
        <CircularProgressbar
          value={percentage}
          text={showPercentage ? `${steps}%` : ''}
          strokeWidth={3}
          styles={buildStyles({
            pathColor: '#354740',
            textColor: '#354740',
            trailColor: '#CCD1CF',
            textSize: '18px',
          })}
        />
        {!showPercentage && (
          <div className='CircularProgressBar__extra-text flex flex-col mt-[-25px]'>
            <img
              className='mb-2'
              src={ChecklistCompleteCheckmark}
              alt='Checklist Complete Checkmark'
            />

            <span>
              Checklist <br />
              Complete
            </span>
          </div>
        )}
      </div>
    );
  }
};

export default circularProgressBar;
