import { useState } from 'react';
import ReactGA from 'react-ga4';

import Button from '../../components/button';
import { Modal } from '../../components/v2/Modal/Modal';

import { ModalRoleItem } from './ModalRoleItem';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onNextClick: (role: string) => void;
  title: string;
};

export const EventRoleModal = (props: Props) => {
  const { isOpen, onClose, title, onNextClick } = props;
  const [selected, setSelected] = useState('');

  const handleClick = () => {
    ReactGA.event({
      category: 'Ceremony',
      action: 'Create',
      label: 'Start creation',
    });
    onNextClick(selected);
  };


  return (
    <Modal
    fullWidth
    isOpen={isOpen} onClose={onClose}>
      <div>
        <h3 className='font-nanum text-2xl'>{title}</h3>
        <ModalRoleItem
          text={`I'm getting married`}
          onClick={() => setSelected('couple')}
          selected={selected === 'couple'}
        />
        <ModalRoleItem
          text={`I'm an Officiant`}
          onClick={() => setSelected('officiant')}
          selected={selected === 'officiant'}
        />
        <ModalRoleItem
          text={`I'm a Guest`}
          onClick={() => setSelected('guest')}
          selected={selected === 'guest'}
        />
        <ModalRoleItem
          text={`I'm a Wedding Planner`}
          onClick={() => setSelected('wedding_planner')}
          selected={selected === 'wedding_planner'}
        />
        <div className='flex justify-center items-center'>
          <Button
            className='bg-forest-primary text-white mt-12'
            title='NEXT'
            disabled={!selected}
            onClick={() => handleClick()}
          />
        </div>
      </div>
    </Modal>
  );
};
