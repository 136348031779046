import arrowRightSvg from '../../../assets/images/icons/arrow-right-new.svg';
import { Typography } from '../../../components/v2/Typography/Typography';

type FilterRowProps = {
  title: string;
  onClick: () => void;
  selected?: string[];
};

const FiltersRow = (props: FilterRowProps) => {
  const { title, onClick, selected } = props;
  return (
    <div
      onClick={onClick}
      className='flex flex-row justify-between items-center py-8 border-b border-forest-100 cursor-pointer'
    >
      <div className='flex flex-col'>
        <div>{title}</div>

        {selected && selected.length > 0 && (
          <Typography
            variant='functional-low'
            type='body-200'
            className='text-forest-300'
          >
            {selected.join(', ')}
          </Typography>
        )}
      </div>
      <img src={arrowRightSvg} />
    </div>
  );
};

export default FiltersRow;
