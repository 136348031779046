import { DraftingModal } from '../../components/v2/DraftingModal/DraftingModal';
import { useCeremonyBuilder } from '../../provider/ceremonyBuilderProvider';

export const DraftingCeremonyModal = () => {
  const {
    textGenerating,
    textGenerateComplete,
    draftingModalOpen,
    setDraftingModalOpen,
    aiGeneratedTextIndex,
    generationError
  } = useCeremonyBuilder();
  return (
    <DraftingModal
      modalOpen={draftingModalOpen}
      generationError={generationError} 
      setModalOpen={setDraftingModalOpen}
      textGenerateComplete={textGenerateComplete}
      textGenerating={textGenerating}
      aiGeneratedTextIndex={aiGeneratedTextIndex}
      titleDrafting={'Drafting your ceremony...'}
      descriptionDrafting={
        'We’re using your questionnaire responses to craft a jumping off point for your ceremony script. Once complete, you’ll be able to view, edit and/or download your ceremony script freely.'
      }
      titlePopulating={'Finishing up...'}
      descriptionPopulating={
        'We’re using your questionnaire responses to craft a jumping off point for your ceremony script. Once complete, you’ll be able to view, edit and/or download your ceremony script freely.'
      }
      titleComplete={'Your ceremony script is ready to view!'}
      descriptionComplete={
        'Freely view, edit, and/or download your script at any time.'
      }
      buttonTextComplete={'View Now'}
    />
  );
};
