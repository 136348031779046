import { SelectChangeEvent } from '@mui/material';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';

import { Ceremony, Member } from '../../../../api/ceremony';
import { saveAnswer } from '../../../../api/question';
import QuestionSaveStatus from '../../../../components/QuestionSave';
import Button from '../../../../components/button';
import { CustomMultiselect } from '../../../../components/forms/CustomMultiselect';
import CustomTextInput from '../../../../components/forms/CustomTextInput';
import { capitalizeFirstLetter } from '../../../../helpers/helper';
import { replacePlaceholders } from '../../../../helpers/placeholderHelper';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { useActiveQuestion } from '../../../../provider/activeQuestionProvider';
import { Tip } from '../tip/Tip';

import { Question, QuestionAnswer } from './ModuleQuestion';
import './moduleQuestion.scss';

type QuestionProps = {
  question: Question;
  couple1?: Member;
  couple2?: Member;
  ceremony?: Ceremony;
  officiant?: Member;
  currentUser?: Member;
  setQuestionChanged: (value: boolean) => void;
  showTip?: boolean;
  readOnly?: boolean;
  drawerQuestion?: boolean;
  asterisk?: boolean;
};

export const DropdownQuestion = (props: QuestionProps) => {
  const {
    question,
    couple1,
    couple2,
    officiant,
    showTip = true,
    ceremony,
    currentUser,
    readOnly,
    setQuestionChanged,
    drawerQuestion,
    asterisk,
  } = props;
  const [title, setTitle] = useState<string>('Save Changes');
  const [changed, setChanged] = useState<boolean>(false);
  const [tipExpanded, setTipExpanded] = useState<number>(0);
  const [values, setValues] = useState<string[]>([]);
  const [textAnswer, setTextAnswer] = useState<string>();

  const { openSnackBar } = useSnackbar();
  const { activeQuestionId, setActiveQuestionId } = useActiveQuestion();

  useEffect(() => {
    if (question && question.answers) {
      const answer = question.answers[0];
      if (answer) {
        const options = answer.option_id;
        if (options) {
          setValues(
            // On autofill we get a stringified value.
            typeof options === 'string' ? options.split(',') : options
          );
        }

        setTextAnswer(answer.text_answer);
      }
    }
  }, [question]);

  const handleChange = (event: SelectChangeEvent<typeof values>) => {
    const {
      target: { value },
    } = event;
    setChanged(true);
    setTitle('Save Changes');
    setValues(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const handleSave = async (
    question: Question,
    values: string[],
    ceremony?: Ceremony,
    textAnswer?: string
  ) => {
    if (ceremony) {
      const answer: QuestionAnswer = {
        ceremony_id: ceremony.id,
        question_id: question.id,
        option_id: values.join(),
        text_answer: textAnswer,
      };
      setChanged(false);
      setTitle('Saving');
      try {
        const saveResponse = await saveAnswer(answer);
        if (saveResponse.success) {
          setTitle('Saved');
          setQuestionChanged(true);
        } else {
          setChanged(true);
          openSnackBar(saveResponse.message, 5000, 'error');
          setTitle('Save Changes');
        }
      } catch (error) {
        setChanged(true);
        openSnackBar('Unexpected error', 5000, 'error');
        setTitle('Save Changes');
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useCallback(
    debounce(
      (
        question: Question,
        values: string[],
        textAnswer?: string,
        ceremony?: Ceremony
      ) => {
        void handleSave(question, values, ceremony, textAnswer);
      },
      2000
    ),
    []
  );

  useEffect(() => {
    if (changed === true) {
      debouncedCall(question, values, textAnswer, ceremony);
    }
  }, [ceremony, question, values, textAnswer, changed, debouncedCall]);

  useEffect(() => {
    if (activeQuestionId && activeQuestionId !== question.id) {
      setTipExpanded(0);
    } else if (activeQuestionId && activeQuestionId === question.id) {
      setTipExpanded(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuestionId]);

  const handleTextChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(true);
    setTitle('Save Changes');
    setTextAnswer(ev.target.value);
  };

  return (
    <div
      className={
        drawerQuestion
          ? ''
          : 'col-span-4 md:col-span-6 lg:col-span-12 grid grid-cols-4 md:grid-cols-6 lg:grid-cols-12'
      }
    >
      <div
        onClick={() => setActiveQuestionId(question.id)}
        className={
          drawerQuestion
            ? ''
            : `question-container mb-8 px-4 md:px-11 py-6 border col-span-4 md:col-span-6 ${
                question?.module !== 'remarks'
                  ? 'lg:col-span-9'
                  : 'lg:col-span-12'
              }`
        }
      >
        <div
          className={`mx-2 my-3 font-nanum ${
            drawerQuestion ? 'text-xl' : 'text-2xl'
          } leading-8`}
        >
          {replacePlaceholders(
            question.question,
            couple1?.preferred_name || couple1?.legal_name,
            couple2?.preferred_name || couple2?.legal_name,
            officiant?.preferred_name || officiant?.legal_name,
            currentUser?.preferred_name || currentUser?.legal_name
          )}

          { asterisk ? <span className='ml-1' style={{color: '#995D30'}}>*</span> : null }
        </div>
        {question.options && (
          <CustomMultiselect
            className='mx-2 my-3'
            options={question.options.map((o) => ({
              label: capitalizeFirstLetter(
                replacePlaceholders(
                  o.option,
                  couple1?.preferred_name || couple1?.legal_name,
                  couple2?.preferred_name || couple2?.legal_name,
                  officiant?.preferred_name || officiant?.legal_name,
                  currentUser?.preferred_name || currentUser?.legal_name
                )
              ),
              value: o.id.toString(),
            }))}
            values={values}
            handleChange={handleChange}
            handleClick={() => setActiveQuestionId(question.id)}
            chipType='culture'
            noSort={question.identifier?.includes('no-sort')}
            readOnly={readOnly}
            multiselect={drawerQuestion ? false : true}
          />
        )}
        {question.has_textfield && (
          <CustomTextInput
            multiline
            rows={5}
            value={textAnswer}
            disabled={values.length === 0}
            placeholder={question.textfield_placeholder}
            onChange={handleTextChange}
            readOnly={readOnly}
          />
        )}
        {question.module !== 'remarks' && (values || textAnswer) && (
          <QuestionSaveStatus
            disabled={!changed}
            title={title}
            onClick={() => handleSave(question, values, ceremony, textAnswer)}
          ></QuestionSaveStatus>
        )}
      </div>
      {!drawerQuestion && showTip && question.module !== 'remarks' && (
        <Tip
          text={replacePlaceholders(
            question.help_short_text,
            couple1?.preferred_name || couple1?.legal_name,
            couple2?.preferred_name || couple2?.legal_name,
            officiant?.preferred_name || officiant?.legal_name,
            currentUser?.preferred_name || currentUser?.legal_name
          )}
          id={1}
          expanded={tipExpanded === 1}
          link={question.help_detail_text}
          setExpanded={(id: number) => setTipExpanded(id)}
          className={'w-72 ml-5 bg-forest-primary'}
        />
      )}
    </div>
  );
};
