import { PayPlansList } from '../../../pages/paywall/PayPlans';
import CustomCheckBox from '../../forms/CustomCheckBox';
import { Typography } from '../Typography/Typography';

type AddonType = {
  identifier: string;
  icon?: string;
  selected: boolean;
  onSelect: () => void;
};

const AddonCard = ({ identifier, icon, selected, onSelect }: AddonType) => {
  const addonsHardcoded = [
    {
      id: '1',
      title: 'Community Reflections',
      description:
        'Easily create a digital guestbook to collect words of wisdom and memories from your friends and family.',
      price: 9.99,
      oldPrice: 20,
      identifier: 'community-reflections',
      attributes: [
        'Pick (or Write) a Prompt',
        'Give Your Loved Ones a Place to Honor You',
        'Save a Beautiful Keepsake Forever',
      ],
    },
    {
      id: '2',
      title: 'Thank You Notes',
      description:
        'Save hours of stress by drafting all of your personalized Thank You Notes in minutes.',
      price: 14.99,
      oldPrice: 30,
      identifier: 'thank-you-notes',
      attributes: [
        'Upload Your List of Guests & Gifts',
        'Automatically Generate All Your Personal Notes',
        'Finalize & Send With Ease',
      ],
    },
  ];

  return (
    <div className='max-w-[436px] border border-forest-primary rounded-3xl p-6 cursor-pointer'
      onClick={onSelect}
    >
      <div className='flex items-center gap-4'>
        <div className='flex justify-between flex-1'>
          <div className='flex flex-col w-full'>
            <div className='flex flex-row justify-between w-full items-center'>
              <Typography variant='primary' type='display-50' className='pb-2'>
                {
                  addonsHardcoded.find(
                    (addon) => addon.identifier === identifier
                  )?.title
                }
              </Typography>
              <CustomCheckBox checked={selected} />
            </div>
            <Typography variant='functional-low' type='body-200' 
              className='mb-2'
            >
              {
                addonsHardcoded.find(
                  (addon) => addon.identifier === identifier
                )?.description
              } 
            </Typography>
            <div className='flex gap-2 mb-2'>
              <Typography
                variant='primary'
                type='display-400'
                className='text-[32px]'
              >
                {`+$${parseFloat(
                  (
                    addonsHardcoded.find(
                      (addon) => addon.identifier === identifier
                    )?.price || 0
                  ).toString()
                ).toFixed(2)}`}
              </Typography>
              <Typography
                variant='primary'
                type='display-50'
                className='text-forest-400 text-[16px] line-through self-end'
              >
                {`$${parseFloat(
                  (
                    addonsHardcoded.find(
                      (addon) => addon.identifier === identifier
                    )?.oldPrice || 0
                  ).toString()
                ).toFixed(0)}`}
              </Typography>
            </div>
            {/* <Typography
              variant='primary'
              type='body-200'
              className='text-forest-400 pb-4'
            >
              Billed once
            </Typography> */}

            
            <div>
              {addonsHardcoded.find(
                (addon) => addon.identifier === identifier
              )?.attributes
              .map((attribute, index) => (
                <PayPlansList key={index} title={attribute} small={true} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddonCard;
