import { MemberChecklistItem } from '../../api/checklistItemDefinitions';
import { ChecklistItemStatus } from '../../pages/Ceremony/CeremonyChecklist/v2/ChecklistItem';

export const getChecklistItemStatusFromDbStatus = (
  item: MemberChecklistItem
) => {
  if (!item) {
    return ChecklistItemStatus.notStarted;
  }
  if (item.completed) {
    return ChecklistItemStatus.completed;
  }
  if (item.skipped) {
    return ChecklistItemStatus.skipped;
  }
  return ChecklistItemStatus.notStarted;
};
