import { NonNullablePickerChangeHandler } from '@mui/x-date-pickers/internals/hooks/useViews';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import { FaLessThanEqual } from 'react-icons/fa';
import * as Yup from 'yup';

import {
  Ceremony,
  Member,
  MemberType,
  UpdateCeremonyVenueAndPlace,
} from '../../../api/ceremony';
import { downloadAnswers } from '../../../api/user';
import { newWeddingsDate } from '../../../constants/constants';
import { useAuth } from '../../../provider/authProvider';
import { useDashboard } from '../../../provider/dashboardProvider';
import { useAccessibleModule } from '../../../provider/moduleAccessProvider';
import { AlertBox } from '../../AlertBox';
import Button from '../../v2/Button/Button';
import ButtonPill from '../../v2/ButtonPill';

import { VenueField } from './VenueField';
import { WeddingDateField } from './WeddingDateField';

const eventSettingsDataSchema = Yup.object().shape({
  venue: Yup.string()
    .ensure()
    .when('venueUnknown', {
      is: false,
      then: Yup.string().required('Venue is required or select unknown'),
    }),
  weddingDate: Yup.string()
    .nullable(true)
    .ensure()
    .test(
      'future-wedding',
      (e) => `Please, select a future date or select not sure yet`,
      (value) => moment(value).isAfter(moment(new Date())) || !value,
    )
    .when('weddingDateUnknown', {
      is: false,
      then: Yup.string().required('Wedding date is required or select unknown'),
    }),
  venueUnknown: Yup.boolean(),
  weddingDateUnknown: Yup.boolean(),
});

export const EventSettingsForm = (props: {
  onSaveChanges: (value: UpdateCeremonyVenueAndPlace) => Promise<void> | void;
  onCancel: () => void;
  ceremony: Ceremony;
  currentUser: Member;
}) => {
  const { ceremony, onSaveChanges, currentUser, onCancel } = props;
  const {
    venue,
    city,
    state,
    wedding_place,
    date,
    id: ceremonyId,
    createdAt,
  } = ceremony;
  const initialVals = {
    venue: venue ?? '',
    city: city ?? '',
    state: state ?? '',
    wedding_place: wedding_place ?? '',
    weddingDate: date ?? '',
    venueUnknown: venue ? false : true,
    weddingDateUnknown: date ? false : true,
  };

  const { user } = useAuth();

  const isLegacyCeremony =
    createdAt && moment(createdAt).isBefore(moment(newWeddingsDate));

  const { hasAccessToOfficiantRemarks, hasAccessToVowsAi } =
    useAccessibleModule();

  const handleDownloadAnswers = async () => {
    const data = ceremonyId && (await downloadAnswers(ceremonyId));

    if (data) {
      // Create a temporary link element to download the file
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Provenance Answers.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  const canDeactivateEvent = () => {
    const isPaidCeremony = hasAccessToOfficiantRemarks || hasAccessToVowsAi;

    const wasCreatedByUser = ceremony.created_by === currentUser.user_id;

    const hasMoreThan1Ceremony =
      user?.ceremonies?.length && user.ceremonies.length > 1;

    return !isPaidCeremony && wasCreatedByUser && hasMoreThan1Ceremony;
  };

  const { setShowDeactivateModal } = useDashboard();

  const removeEvent = () => {
    onCancel();
    setShowDeactivateModal(true);
  };

  return (
    <div className='EventSettingsModal__tab-container'>
      <div className='EventSettingsModal__section-container'>
        <Formik
          validateOnMount={true}
          initialValues={initialVals}
          onSubmit={(values) => {
            if (typeof onSaveChanges === 'function')
              void onSaveChanges({ ...values, id: ceremonyId });
          }}
          validationSchema={eventSettingsDataSchema}
        >
          {({ values }) => {
            const hasVenue =
              !!values.city ||
              (!!values.wedding_place && !!values.state) ||
              (!!values.wedding_place && !values.state);
            const isUnknownVenue = values.venueUnknown;
            return (
              <Form>
                <VenueField />
                <WeddingDateField />
                <div className={`flex flex-row justify-between pt-6`}>
                  <Button onClick={onCancel} variant='text'>
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      (!hasVenue && !isUnknownVenue) ||
                      (!values.weddingDateUnknown && !(values.weddingDate && moment(values.weddingDate).isAfter(moment(new Date()))))
                    }
                    type='submit'
                  >
                    Update
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {currentUser.member_type !== MemberType.guests && (
        <div className='EventSettingsModal__section-container pt-6 border border-x-0 border-b-0 border-b-forest-100'>
          <h5>
            {isLegacyCeremony ? 'Legacy Answers' : 'Download All Answers'}{' '}
          </h5>
          <AlertBox
            alwaysOpen
            variant='info'
            message={
              isLegacyCeremony
                ? 'We’re excited to share our new and improved Ceremony Builder with you! If you had previously filled out answers to questions that are no longer in our tool, have no fear; we have backed up and saved them for you. Simply click the button below to download all of your answers.'
                : 'All of your Ceremony Builder responses can be found and edited in the Ceremony Script section. If you prefer to download your answers, click the button below:'
            }
            buttons={[
              { label: 'Download Answers', onClick: handleDownloadAnswers },
            ]}
          />
        </div>
      )}
      {canDeactivateEvent() ? (
        <div className='pt-6'>
          <h5> Remove Event </h5>
          <ButtonPill
            text='Remove this event'
            onClick={() => removeEvent()}
            className='mt-3 text-red-800 border-red-800'
          />
        </div>
      ) : null}
    </div>
  );
};
