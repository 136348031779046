/* eslint-disable -- TODO: fix eslint errors */
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { FormHelperText } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomTextInput from '../../forms/CustomTextInput';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type Props = {
  options: { label: string; value: string }[];
  values: string[];
  handleChange: (event: SelectChangeEvent<string[]>) => void;
  handleClick?: () => void;
  handleAllClick?: () => void;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  name?: string;
  className?: string;
  chipType?: string;
  noSort?: boolean;
  readOnly?: boolean;
  multiselect?: boolean;
  buttonPillAppearance?: boolean;
};

export const DropdownMultiselect = (props: Props) => {
  const {
    options,
    values,
    handleChange,
    handleClick,
    handleAllClick,
    name,
    className,
    chipType,
    error,
    helperText,
    noSort = false,
    readOnly,
    multiselect = true,
    buttonPillAppearance = false,
  } = props;

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newOptionName, setNewOptionName] = useState('');

  const sortFunction = (a: any, b: any) => {
    if (a.label == 'Other') return 1;
    if (b.label == 'Other') return -1;
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  };

  const getInputText = (
    selected: string[],
    options: { label: string; value: string }[]
  ) => {
    let result = 'All';
    if (selected && selected.length > 0) {
      if (selected.length === 1) {
        const selectedOption = options.find((o) => o.value === selected[0]);
        if (selectedOption) {
          result = selectedOption?.label;
        }
      } else if (selected.length > 1) {
        result = `${selected.length} selected`;
      }
    }
    return result;
  };

  const buttonPillAppearanceClasses = "rounded-full overflow-hidden border-[3px] border-copper-primary text-copper-primary"

  return (
    <>
      <Select
        readOnly={readOnly}
        onOpen={handleClick}
        displayEmpty
        error={error}
        name={name}
        fullWidth={true}
        variant='outlined'
        className={`h-14 min-w-b3 ${className} ${buttonPillAppearance ? buttonPillAppearanceClasses : ''} `}
        multiple={multiselect}
        value={values}
        onChange={handleChange}
        renderValue={(selected) =>
          multiselect ? getInputText(selected, options) : selected
        }
        MenuProps={MenuProps}
        sx={ buttonPillAppearance ? {
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
        }: null}
      >
        {handleAllClick && (
          <MenuItem value={'all'} onClick={handleAllClick}>
            <Checkbox checked={values.length === 0} />
            <div className='text-sm'>All</div>
          </MenuItem>
        )}

        {!noSort
          ? options
              .sort((a: any, b: any) => sortFunction(a, b))
              .map((option) => (
                <MenuItem
                  key={`${option.label}-${option.value}`}
                  value={option.value}
                >
                  <Checkbox checked={values.indexOf(option.value) > -1} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))
          : options.map((option) => (
              <MenuItem
                key={`${option.label}-${option.value}`}
                value={option.value}
              >
                <Checkbox checked={values.indexOf(option.value) > -1} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
      </Select>
      {helperText && (
        <FormHelperText className='text-red-error ml-6'>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};
