import GiftCardLayout from "./Layout"

import bundleImg from '../../../assets/images/image/bundle-green.png'
import infoIcon from '../../../assets/images/svg/info.svg';
import { Typography } from "../../../components/v2/Typography/Typography"


import './GiftCardImg.scss'

type GiftCardProps = {
    size: 'lg' | 'md',
    preview?: boolean
    classNames?: string
    mAuto?: boolean
}

const GiftcardImg = (props: GiftCardProps) => {
    const {size = 'lg', classNames = '', preview = false, mAuto= true} = props
    let cardClasses = 'cardBody py-3 px-10 rounded-3xl flex justify-center items-center flex-col' + classNames
    if (mAuto) cardClasses += ' m-auto'
    const width = size == 'lg' ? '400px' : 'auto'
    const height = size == 'lg' ? '250px' : 'auto'
    const maxWidth = '100%'
    return (
    <>
     <div className={cardClasses} style={{width, height, maxWidth}}> 
        <Typography
            variant='primary'
            type='display-200'
            className="text-neutral-50"
        >
            Provenance
        </Typography>
        <img src={bundleImg} style={{display: 'inline'}} />
        <Typography
            variant='primary'
            type='display-200'
            className="text-neutral-50"
        >
            Premium Bundle
        </Typography>
     </div>
     {
        preview ? (
            <div className='text-center'>
            <img src={infoIcon} style={{display: 'inline'}}/>
            <Typography
                variant="functional-low"
                type='body-100'
                className="inline ml-1"
            >
                Preview of how the card will look like to the recipient
            </Typography>
            </div>
        ) : null 
     }
    </>
        )
}

export default GiftcardImg