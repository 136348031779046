import { User } from './auth';
import { GenericResponse, Ceremony } from './ceremony';
import { _request } from './request';

export type SignInBody = {
  role: string;
  full_name: string;
  email: string;
  password?: string;
};

export type SignInResponse = {
  token: string;
  success: boolean;
};

export type DashboardUser = User & {
  ceremonies: Ceremony[];
};

export type DashboardUserResponse = {
  success: boolean;
  user: DashboardUser;
};

export type SurveyReminderResponse = {
  success: boolean;
  message: string;
};

export type ReferEmailResponse = {
  success: boolean;
  message: string;
  data?: Array<string>;
  hideManageReferralsLink?: boolean;
};

export type ReferSchemaBody = {
  email: string | Array<string>;
  ceremony_id?: number;
  hindersReward?: boolean;
};

export const getMe = async (body: SignInBody) => {
  const responseBody = await _request<SignInResponse>({
    url: 'user/me',
    method: 'GET',
    body,
  });
  return responseBody;
};

export const getDashboardUser = async () => {
  const responseBody = await _request<DashboardUserResponse>({
    url: 'users/dashboard',
    method: 'GET',
  });
  return responseBody;
};

export const sendSurveyReminder = async (ceremonyId: string) => {
  const responseBody = await _request<SurveyReminderResponse>({
    url: `users/send-survey-reminder/${ceremonyId}`,
    method: 'POST',
  });
  return responseBody;
};

export const sendReferEmail = async (body: ReferSchemaBody) => {
  const responseBody = await _request<ReferEmailResponse>({
    url: `users/send-refer-email`,
    method: 'POST',
    body,
  });
  return responseBody;
};

export const downloadAnswers = async (ceremonyId: string | number) => {
  const responseBody = await _request<BlobPart>({
    url: `users/download-answers/${ceremonyId}`,
    method: 'GET',
  });
  return responseBody;
};

export const updateUserCustomField = async (key: string, value: string) => {
  const responseBody = await _request<GenericResponse>({
    url: `users/update-custom-fields`,
    method: 'POST',
    body: {
      key,
      value,
    },
  });
  return responseBody;

}

export const deactivateUser = async () => {
 const responseBody = await _request<GenericResponse>({
   url: `users/deactivate`,
   method: 'POST',
 });
 return responseBody;
};
