import { useState } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';

import { Member } from '../../../api/ceremony';
import { Speaker } from '../../../api/friends';
import { addSpeaker } from '../../../api/speakers';
import draggableIcon from '../../../assets/images/icons/elipsis-double.svg';
import { NoteModal } from '../../../components/v2/NoteModal/NoteModal';
import { sendCustomerIoEventHandler } from '../../../helpers/customerIoHelper';
import { getAPIErrorMessage } from '../../../helpers/helper';
import useLoader from '../../../hooks/useLoader';
import { useSnackbar } from '../../../hooks/useSnackbar';
import {
  useSpeakers,
  Speaker as EventSpeaker,
  Event,
} from '../../../hooks/useSpeakers';
import { AddGuestSpeakerModal } from '../AddGuestSpeakerModal/AddGuestSpeakerModal';
import Button from '../Button/Button';
import { Separator } from '../Separator/Separator';
import { SpeakerCard } from '../SpeakerCard/SpeakerCard';
import { Typography } from '../Typography/Typography';

type DraggableToastListProps = {
  ceremonyId: number;
  title?: string;
  addSpeakerBlocked?: boolean;
};

export const DraggableToastList = (props: DraggableToastListProps) => {
  const { ceremonyId, title = 'All Toasts' } = props;

  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showAddGuestModal, setShowAddGuestModal] = useState(false);
  const [note, setNote] = useState<string>('');
  const [currentSpeaker, setCurrentSpeaker] = useState<Speaker>({
    member: {} as Member,
  } as Speaker);

  const {
    speakerEvents,
    fetchSpeakers,
    setSpeakerEvents,
    deleteSpeaker,
    saveSpeakersInBulk,
  } = useSpeakers({
    ceremonyId,
  });
  const { openSnackBar } = useSnackbar();
  const { showLoader, hideLoader } = useLoader();
  const navigate = useNavigate();

  const guestToastSortingFn = (a: EventSpeaker, b: EventSpeaker) => {
    if (!b.order) return 1;
    else if (!a.order) return -1;

    return a.order - b.order;
  };

  const rehersalDinnerEvents = speakerEvents.filter(
    (toast) => toast.id === 'rehearsal_dinner'
  );

  rehersalDinnerEvents[0]?.speakers.sort((a, b) => guestToastSortingFn(a, b));

  const receptionEvents = speakerEvents.filter(
    (toast) => toast.id === 'reception'
  );

  receptionEvents[0]?.speakers.sort((a, b) => guestToastSortingFn(a, b));

  const engagementPartyEvents = speakerEvents.filter(
    (toast) => toast.id === 'engagement_party'
  );

  engagementPartyEvents[0]?.speakers.sort((a, b) => guestToastSortingFn(a, b));

  const getSpeakerArray = (eventId: string) => {
    if (eventId == 'rehearsal_dinner') return rehersalDinnerEvents[0].speakers;
    if (eventId == 'reception') return receptionEvents[0].speakers;
    if (eventId == 'engagement_party') return engagementPartyEvents[0].speakers;
    else return null;
  };

  const handleReorderSpeaker = async (param: DropResult) => {
    const { destination, source } = param;

    if (!destination || !ceremonyId) return;

    /* retrieving dragging params */
    const destinationEventId = destination.droppableId.split('-')[1];
    const destinationIndex = destination.index;
    const sourceEventId = source.droppableId.split('-')[1];
    const sourceIndex = source.index;

    if (destinationEventId == sourceEventId && destinationIndex == sourceIndex)
      return;

    const destinationSpeakerArray = getSpeakerArray(destinationEventId);
    const sourceSpeakerArray = getSpeakerArray(sourceEventId);

    if (!destinationSpeakerArray || !sourceSpeakerArray) return;

    // this updates the destinationArray and the sourceArray
    destinationSpeakerArray.splice(
      destinationIndex,
      0,
      sourceSpeakerArray.splice(sourceIndex, 1)[0]
    );

    // creating the newArrays to be saved in DB.
    const speakersToBeSavedDestination: EventSpeaker[] =
      destinationSpeakerArray.map((speaker, index) => {
        return {
          ...speaker,
          order: index,
          event: destinationEventId,
        };
      });

    let speakersToBeSavedSource = [] as EventSpeaker[];

    if (destinationEventId != sourceEventId) {
      speakersToBeSavedSource = [
        ...sourceSpeakerArray.map((speaker, index) => {
          return {
            ...speaker,
            order: index,
            event: sourceEventId,
          };
        }),
      ];
    }

    const speakersToBeSaved = [
      ...speakersToBeSavedDestination,
      ...speakersToBeSavedSource,
    ];

    setSpeakerEvents(
      speakerEvents.map((event) => {
        if (event.id == destinationEventId)
          return { ...event, speakers: speakersToBeSavedDestination };
        if (event.id == sourceEventId && speakersToBeSavedSource.length > 0)
          return { ...event, speakers: speakersToBeSavedSource };
        return event;
      })
    );

    openSnackBar('Saving changes...');

    try {
      const success = await saveSpeakersInBulk(
        ceremonyId.toString(),
        speakersToBeSaved
      );

      if (success) {
        openSnackBar('Saved successfully');
      } else openSnackBar('Error saving changes', 5000, 'error');
      fetchSpeakers();
    } catch (e) {
      console.log(e);
      openSnackBar('Error saving changes', 5000, 'error');
    }
  };

  const handleAddSpeakerFn = async (speaker: Speaker) => {
    await handleAddSpeaker(speaker);
    fetchSpeakers();
  };

  const handleAddSpeaker = async (speaker: Speaker) => {
    setShowAddGuestModal(false);
    if (ceremonyId) {
      try {
        const response = await addSpeaker(ceremonyId.toString(), speaker);
        if (response.success) {
          void sendCustomerIoEventHandler('Guest Invited', {
            name: speaker.member.legal_name,
            email: speaker.member.email,
            relation: speaker.member.member_sub_type,
            event: speaker.event,
            speaking_duration: speaker.alloted_time,
          });
          openSnackBar('Saved successfully');
          hideLoader();
          void fetchSpeakers();
        } else {
          hideLoader();
          openSnackBar(response.message, 5000, 'error');
        }
      } catch (err) {
        console.log(err);
        hideLoader();
        openSnackBar(getAPIErrorMessage(err), 5000, 'error');
      }
    }
  };

  return (
    <div className='mt-10 flex flex-col gap-4'>
      <div className='flex justify-between items-center'>
        <Typography type='display-400'>{title}</Typography>

        <div className='mt-3 flex lg:mt-0'>
          {speakerEvents.length > 0 && (
            <>
              {/* <ButtonPill
              text = 'Download Toasts Order'
              className='text-sm'
              size='xs'
              onClick={() => {
                console.log('downloading guest list');
              }}
            /> */}
              <Button
                size='100'
                onClick={() => {
                  setCurrentSpeaker({ member: {} as Member } as Speaker);
                  setShowAddGuestModal(true);
                }}
              >
                Invite new Speaker
              </Button>
            </>
          )}
        </div>
      </div>

      <Separator className='border-forest-100 mb-4' />

      <DragDropContext
        onDragEnd={(param) => {
          handleReorderSpeaker(param);
        }}
      >
        {speakerEvents.map((speakerEvent) => {
          return (
            <>
              <div className='flex'>
                <Typography type='display-50'>{speakerEvent.name}</Typography>
                <Typography
                  type='body-600'
                  variant='functional-low'
                  className='text-[#677570] mt-1 ml-2'
                >
                  {' (' + speakerEvent.speakers.length.toString() + ')'}
                </Typography>
              </div>

              <Droppable droppableId={'droppable-' + speakerEvent.id}>
                {(provided, _) => (
                  <div
                    className='flex flex-col gap-4'
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {speakerEvent.speakers
                      .sort((a, b) => guestToastSortingFn(a, b))
                      .map(
                        (
                          {
                            id,
                            name: fullName,
                            email,
                            role,
                            alloted_time,
                            originalSpeakerData,
                            member_id,
                          },
                          index
                        ) => (
                          <Draggable
                            key={id}
                            draggableId={'draggable-' + id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <SpeakerCard
                                  key={`speaker-${id}`}
                                  removeSpeakerHandler={() => deleteSpeaker(id)}
                                  onManageInviteClick={() => {
                                    setCurrentSpeaker(originalSpeakerData);
                                    setShowAddGuestModal(true);
                                  }}
                                  onViewToastClick={() => {
                                    if (!member_id) return;
                                    navigate(
                                      `/preview-toast/${ceremonyId}/${member_id}`
                                    );
                                  }}
                                  onOpenNote={
                                    originalSpeakerData.speech_note
                                      ? () => {
                                          setNote(
                                            originalSpeakerData.speech_note
                                          );
                                          setCurrentSpeaker(
                                            originalSpeakerData
                                          );
                                          setShowNoteModal(true);
                                        }
                                      : undefined
                                  }
                                  inviteAccepted={
                                    originalSpeakerData.member.user_id !=
                                    undefined
                                  }
                                  {...{
                                    fullName,
                                    email,
                                    role,
                                    eventName: speakerEvent.name,
                                    allotedTime: alloted_time,
                                    speakingEvent: speakerEvent.name,
                                  }}
                                  draggingHandle={
                                    <div
                                      className='mr-8'
                                      {...provided.dragHandleProps}
                                    >
                                      <img src={draggableIcon} />
                                    </div>
                                  }
                                />
                              </div>
                            )}
                          </Draggable>
                        )
                      )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </>
          );
        })}
      </DragDropContext>

      <AddGuestSpeakerModal
        title='Add Guest Speaker'
        isOpen={showAddGuestModal}
        onClose={() => setShowAddGuestModal(false)}
        initialSpeaker={currentSpeaker}
        onButtonClick={handleAddSpeakerFn}
      />

      <NoteModal
        note={note}
        name={currentSpeaker.member.legal_name || ''}
        isOpen={showNoteModal}
        onClose={() => setShowNoteModal(false)}
      />
    </div>
  );
};
