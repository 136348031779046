import { useState } from 'react';

import { Speaker } from '../api/friends';
import {
  CeremonySpeechesDetailsData,
  CeremonySpeechesDetailsResponse,
  getCeremonySpeechesDetails,
} from '../api/planner';
import { useSnackbar } from '../provider/snackbarProvider';

export type SpeakersByEvent = {
  engagement_party: Speaker[];
  rehearsal_dinner: Speaker[];
  reception: Speaker[];
};

const sortFunction = (a: Speaker, b: Speaker) => {
  if (a.order == null) return 1;
  if (b.order == null) return -1;
  if (a.order < b.order) return -1;
  if (a.order > b.order) return 1;
  return 0;
};

const useSpeechData = () => {
  const [speechData, setSpeechData] = useState<CeremonySpeechesDetailsData>();
  const [speakersByEvent, setSpeakersByEvent] = useState<SpeakersByEvent>({
    engagement_party: [],
    rehearsal_dinner: [],
    reception: [],
  });
  const { openSnackBar } = useSnackbar();
  const getCeremonySpeeches = async (ceremonyId: number) => {
    if (ceremonyId) {
      try {
        const ceremonyResponse: CeremonySpeechesDetailsResponse =
          await getCeremonySpeechesDetails(ceremonyId);
        if (ceremonyResponse.success) {
          setSpeechData(ceremonyResponse.data);
          const reception: Speaker[] = [];
          const rehearsal_dinner: Speaker[] = [];
          const engagement_party: Speaker[] = [];

          ceremonyResponse.data.speakers.forEach((speaker: Speaker) => {
            switch (speaker.event) {
              case 'reception':
                reception.push(speaker);
                break;
              case 'rehearsal_dinner':
                rehearsal_dinner.push(speaker);
                break;
              case 'engagement_party':
                engagement_party.push(speaker);
                break;
              default:
                break;
            }
          });
          const speaker: SpeakersByEvent = {
            engagement_party: engagement_party.sort((a: Speaker, b: Speaker) =>
              sortFunction(a, b)
            ),
            reception: reception.sort((a: Speaker, b: Speaker) =>
              sortFunction(a, b)
            ),
            rehearsal_dinner: rehearsal_dinner.sort((a: Speaker, b: Speaker) =>
              sortFunction(a, b)
            ),
          };
          setSpeakersByEvent(speaker);
        }
      } catch (err) {
        openSnackBar('Something went wrong', 'error');
      }
    }
  };
  return {
    getCeremonySpeeches,
    speechData,
    speakersByEvent,
  };
};

export default useSpeechData;
