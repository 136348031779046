/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState } from 'react';

import { Culture, getCultures } from '../api/culture';
import {
  getQuestionAnswerStatus,
  GetQuestionAnswerStatusResponse,
  QuestionAnswerStatusObj,
} from '../api/question';
import { Module } from '../hooks/useActiveModule';

type QAStatusContextType = {
  QAStatus: QuestionAnswerStatusObj | null;
  fetchQaStatus: (ceremonyId: string) => Promise<void>;
  getPercentageByModule: (module: Module) => number;
  getNumberOfQuestionsByModule: (module: Module) => number;
  getNumberOfAnsweredQuestionsByModule: (module: Module) => number;
  getLastAnswerDateByModule: (module: Module) => Date | null;
};

export const QAStatusContext = React.createContext<QAStatusContextType>({
  QAStatus: null,
  fetchQaStatus: async (ceremonyId: string) => {},
  getPercentageByModule: (module: Module) => {
    return 0;
  },
  getNumberOfQuestionsByModule: (module: Module) => {
    return 0;
  },
  getNumberOfAnsweredQuestionsByModule: (module: Module) => {
    return 0;
  },
  getLastAnswerDateByModule: (module: Module) => {
    return null;
  },
});

export const QAStatusProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [QAStatus, setQAStatus] = useState<QuestionAnswerStatusObj | null>(
    null
  );

  const fetchQaStatus = async (ceremonyId: string) => {
    try {
      const qaStatusResponse: GetQuestionAnswerStatusResponse =
        await getQuestionAnswerStatus(ceremonyId);
      if (qaStatusResponse.success) {
        setQAStatus(qaStatusResponse.data);
      }
    } catch (error) {}
  };

  const getPercentageByModule = (type: Module) => {
    try {
      if (QAStatus) {
        return Math.floor(
          (QAStatus[type].totalAnswered / QAStatus[type].totalQuestion) * 100
        );
      } else {
        return 0;
      }
    } catch {
      return 0;
    }
  };

  const getNumberOfQuestionsByModule = (type: Module) => {
    try {
      if (QAStatus) {
        return QAStatus[type].totalQuestion;
      } else {
        return 0;
      }
    } catch {
      return 0;
    }
  };

  const getNumberOfAnsweredQuestionsByModule = (type: Module) => {
    try {
      if (QAStatus) {
        return QAStatus[type].totalAnswered;
      } else {
        return 0;
      }
    } catch {
      return 0;
    }
  };

  const getLastAnswerDateByModule = (type: Module) => {
    try {
      if (QAStatus) {
        return QAStatus[type].lastAnswerDate;
      } else {
        return null;
      }
    } catch {
      return null;
    }
  };

  const value = {
    QAStatus,
    fetchQaStatus,
    getPercentageByModule,
    getNumberOfQuestionsByModule,
    getNumberOfAnsweredQuestionsByModule,
    getLastAnswerDateByModule,
  };
  // @ts-ignore
  return (
    <QAStatusContext.Provider value={value}>
      {children}
    </QAStatusContext.Provider>
  );
};

export const useQAStatus = () => {
  return useContext(QAStatusContext);
};
