import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ReactFbPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import ReactPinterestTag from 'react-pinterest-tag';
import { StickyContainer } from 'react-sticky';
import TiktokPixel from 'tiktok-pixel';

import packageInfo from '../package.json';

import './App.scss';
import CustomerIOTracker from './components/CustomerIoTracker';
import Loader from './components/Loader/loader';
import { SnackbarProvider } from './components/Snackbar';
import { SnackbarComponentNew } from './components/Snackbar/Snackbar';
import useLoader from './hooks/useLoader';
import { NPSProvider } from './provider/NPSProvider';
import { QAStatusProvider } from './provider/QAStatusProvider';
import { ActiveQuestionProvider } from './provider/activeQuestionProvider';
import { AuthProvider } from './provider/authProvider';
import { BuilderCTAProvider } from './provider/builderCTAprovider';
import { CampaignProvider } from './provider/campaignProvider';
import { CeremonyBuilderProvider } from './provider/ceremonyBuilderProvider';
import { CeremonyRegenerationNeededProvider } from './provider/ceremonyRegenerationNeededProvider';
import { ChecklistProvider } from './provider/checklistProvider';
import { CreateEventProvider } from './provider/createEventProvider';
import { CultureProvider } from './provider/cultureProvider';
import { DashboardProvider } from './provider/dashboardProvider';
import { GenerateModuleProvider } from './provider/generateModuleProvider';
import { MerchandiseProvider } from './provider/merchandisingProvider';
import { ModuleAccessProvider } from './provider/moduleAccessProvider';
import { OnboardingProvider } from './provider/onboardingProvider';
import { OutputProvider } from './provider/outputProvider';
import { PaywallModalProvider } from './provider/paywallModalProvider';
import { PaywallProvider } from './provider/paywallProvider';
import { PlannerProvider } from './provider/plannerProvider';
import { AllQuestionsProvider } from './provider/questionsProvider';
import { CustomSnackbarProvider } from './provider/snackbarProvider';
import { ToastBuilderProvider } from './provider/toastBuilderProvider';
import { UserTokensProvider } from './provider/userTokensProvider';
import { VowBuilderProvider } from './provider/vowBuilderProvider';
import { WelcomeToastBuilderProvider } from './provider/welcomeToastBuilderProvider';
import AppRoutes from './routes';

import CacheBuster from 'react-cache-buster';
import { createTheme, ThemeProvider } from '@mui/material';

hotjar.initialize(3247340, 6);

const TRACKING_ID = 'G-DES2THG89D';
ReactGA.initialize(TRACKING_ID);
//ReactGA.send("pageview");

const theme = createTheme({
  palette: {
    primary: { main: '#354740' }, // Purple and green play nicely together.
    secondary: { main: '#11cb5f' }, // This is just green.A700 as hex.
  },
});

const options = {
  autoConfig: true,
  debug: false,
};

ReactFbPixel.init('468054584933612', undefined, options);
ReactFbPixel.pageView();

const tikTokOptions = {
  debug: false, // enable logs
};
TiktokPixel.init('CCTLS7BC77UDU62J7ALG', undefined, tikTokOptions);

// eslint-disable-next-line
ReactPinterestTag.init('2613598259931');
// eslint-disable-next-line
ReactPinterestTag.pageView();

function App() {
  const version = packageInfo.version;

  const { isShowing, hideLoader } = useLoader();
  const isProduction =
    process.env.REACT_APP_ENV === 'production' ||
    process.env.REACT_APP_ENV === 'staging';

  const Loading = () => {
    //centered loader with text using tailwind css
    return (
      <div className='flex flex-col justify-center items-center h-screen'>
        <div className='flex flex-col justify-center items-center'>
          <div className='loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32 mb-4'></div>
          <h2 className='text-center text-xl text-gray-700'>
            Installing updates...
          </h2>
        </div>
      </div>
    );
  };

  return (
    <>
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >
        <StickyContainer>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <AuthProvider>
                  <OnboardingProvider>
                    <ActiveQuestionProvider>
                      <GenerateModuleProvider>
                        <CultureProvider>
                          <QAStatusProvider>
                            <ModuleAccessProvider>
                              <OutputProvider>
                                <AllQuestionsProvider>
                                  <UserTokensProvider>
                                    <ChecklistProvider>
                                      <DashboardProvider>
                                        <CreateEventProvider>
                                          <CustomSnackbarProvider>
                                            <VowBuilderProvider>
                                              <CeremonyBuilderProvider>
                                                <PaywallProvider>
                                                  <PlannerProvider>
                                                    <MerchandiseProvider>
                                                      <ToastBuilderProvider>
                                                        <WelcomeToastBuilderProvider>
                                                          <NPSProvider>
                                                            <CampaignProvider>
                                                              <BuilderCTAProvider>
                                                                <CeremonyRegenerationNeededProvider>
                                                                  <PaywallModalProvider>
                                                                    <div className='root-content'>
                                                                      {/* <Navbar /> */}
                                                                      <Loader
                                                                        isShowing={
                                                                          isShowing
                                                                        }
                                                                        hide={
                                                                          hideLoader
                                                                        }
                                                                      />
                                                                      <SnackbarComponentNew />
                                                                      <AppRoutes />
                                                                      {/* <Footer /> */}
                                                                    </div>
                                                                  </PaywallModalProvider>
                                                                </CeremonyRegenerationNeededProvider>
                                                              </BuilderCTAProvider>
                                                            </CampaignProvider>
                                                          </NPSProvider>
                                                        </WelcomeToastBuilderProvider>
                                                      </ToastBuilderProvider>
                                                    </MerchandiseProvider>
                                                  </PlannerProvider>
                                                </PaywallProvider>
                                              </CeremonyBuilderProvider>
                                            </VowBuilderProvider>
                                          </CustomSnackbarProvider>
                                        </CreateEventProvider>
                                      </DashboardProvider>
                                    </ChecklistProvider>
                                  </UserTokensProvider>
                                </AllQuestionsProvider>
                              </OutputProvider>
                            </ModuleAccessProvider>
                          </QAStatusProvider>
                        </CultureProvider>
                      </GenerateModuleProvider>
                    </ActiveQuestionProvider>
                  </OnboardingProvider>
                </AuthProvider>
              </LocalizationProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </StickyContainer>
      </CacheBuster>
      <CustomerIOTracker />
    </>
  );
}

export default App;
