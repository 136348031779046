import { _request } from './request';

export type NPSResponse = {
  success: boolean;
  message: string;
  data: null | {
    id:number;
    user_id: number
    ceremony_id: number;
    npsValue: number | undefined;
    feedback: string | undefined;
    type_of_feedback: string | undefined;
    createdAt: string;
    updatedAt: string | undefined;
  };
  errors?: unknown;
}

export const sendNPStoAPI = async (body: {
  user_id: number,
  ceremony_id: number, 
  npsValue: number,
  feedback?: string
  type_of_feedback?: string
}) => {
  const responseBody = await _request<NPSResponse>({
    url: `nps/send_nps`,
    method: 'POST',
    body
  });
  return responseBody;
};

export const getSubmittedNPS = async (data: {
  user_id: number,
  ceremony_id: number, 
}) =>  {
  const {user_id, ceremony_id} = data
  const responseBody = await _request<NPSResponse>({
    url: `nps/submitted_nps/${user_id}/${ceremony_id}`,
    method: 'GET'
  });

  return responseBody.data;
}
