import ABTester from '../../../components/v2/ABTester/ABTester';
import Button from '../../../components/v2/Button/Button';
import { Typography } from '../../../components/v2/Typography/Typography';
import { useDashboard } from '../../../provider/dashboardProvider';

import lockBigPng from './lock-big.png';

type ModuleLockedProps = {
  onUpgradeClick: () => void;
};

const ModuleLocked = (props: ModuleLockedProps) => {
  const { onUpgradeClick } = props;

  const { ceremony } = useDashboard();

  return (
    <div className='p-5 rounded-lg bg-forest-100 flex flex-row justify-between items-center'>
      <ABTester
        components={[
          {
            component: (
              <div>
                <Button variant='text' onClick={onUpgradeClick}>
                  Click here to discover premium features
                </Button>
              </div>
            ),
            render: ceremony?.vowslanding_test_category === 'new_copy',
          },
          {
            component: (
              <>
                {' '}
                <div>
                  <Typography type='body-400'>
                    This chapter is currently locked. Upgrade to access more
                    questions and generate this part of the script.
                  </Typography>
                  <Button variant='text' onClick={onUpgradeClick}>
                    Upgrade to unlock
                  </Button>
                </div>
                <img src={lockBigPng} />
              </>
            ),
            default: true,
          },
        ]}
      />
    </div>
  );
};
export default ModuleLocked;
