import { _request } from './request';

export type EmailValidationResponse = {
  success: boolean;
  data: {
    isValid: boolean;
  };
};

export const validateEmail = async (email: string) => {
  const responseBody = await _request<EmailValidationResponse>({
    url: 'validation/email',
    method: 'POST',
    body: { email },
  });
  return responseBody;
};
