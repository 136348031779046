import React, { useEffect, useState } from 'react';

import { SaveWaitlistQABody, WaitListQuestions } from '../../../api/waitlist';
import CustomCheckBox from '../../../components/forms/CustomCheckBox';
import CustomSelect from '../../../components/forms/CustomSelect';
import CustomTextInput from '../../../components/forms/CustomTextInput';

type SelectQuestionProps = {
  question: WaitListQuestions;
  updateAnswer: (qid: string, oid: string, textans: string) => void;
  answer?: SaveWaitlistQABody;
};

const SelectQuestion = ({
  question,
  answer,
  updateAnswer,
}: SelectQuestionProps) => {
  const handleTextChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    updateAnswer(question.id.toString(), answer?.oid ?? '', ev.target.value);
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    let options: string[] = answer?.oid.split(',') || [];

    if (ev.target.checked) {
      options = options.concat(ev.target.value);
    } else {
      options = options.filter((x) => {
        return x !== ev.target.value;
      });
    }
    updateAnswer(
      question.id.toString(),
      options.toString(),
      answer?.textans ?? ''
    );
  };

  return (
    <div className='col-span-4 md:col-span-6 lg:col-span-12 w-full my-4'>
      <div className='mx-2 my-3 font-nanum text-2xl leading-8'>
        {question.question}
      </div>
      {question.options.map((option) => {
        return (
          <CustomCheckBox
            key={option.id}
            label={option.option}
            value={option.id}
            name={'question'}
            onChange={handleChange}
            checked={
              answer?.oid.split(',').includes(option.id.toString()) ?? false
            }
          />
        );
      })}
      {question.has_textfield && (
        <CustomTextInput
          className='mt-4 bg-white'
          multiline
          rows={5}
          value={answer?.textans}
          onChange={handleTextChange}
          placeholder={'Type here...'}
        />
      )}
    </div>
  );
};

export default SelectQuestion;
