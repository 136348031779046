import classNames from 'classnames';
import { useRef, useState } from 'react';

import { useWindowSize } from '../../../../hooks/useWindowSize';
import { useCTA } from '../../../../provider/ctaProvider';
import { ModuleStatus } from '../../enum';
import { ModuleChapterHeader } from '../ModuleChapterHeader';
import { ModulePageWrapper } from '../ModulePageWrapper';
import { ModuleQuestionsHeader } from '../ModuleQuestionsHeader';
import { ModuleSummary } from '../ModuleSummary';

import { CollapseButton } from './CollapseButton';
import { CollapseContainer } from './CollapseContainer';
import './ModuleWrapper.scss';
import { ModuleWrapperProps } from './typings.d';

export const ModuleWrapper = ({
  children,
  chapterProps,
  summaryProps,
  questionsHeaderProps,
  noGap = true,
  hideWrapper = false,
}: ModuleWrapperProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showLeftContainer, setShowLeftContainer] = useState(true);
  const size = useWindowSize();
  const columnWidth = size.width / 3;
  const { renderIcon, chapter } = chapterProps;
  const { renderCenterButton, renderRightButton, setModuleStatus } = questionsHeaderProps;
  return (
    <>
      {hideWrapper && children}
      {!hideWrapper && (
        <ModulePageWrapper>
          <div className='ModuleWrapper ModuleWrapper-root' ref={containerRef}>
            <CollapseContainer
              collapsed={!showLeftContainer}
              columnWidth={columnWidth}
            >
              <ModuleChapterHeader renderIcon={renderIcon} chapter={chapter} />
              <ModuleSummary {...summaryProps} />
            </CollapseContainer>
            <div
              className={classNames('ModuleWrapper-questionsWrapper', {
                'ModuleWrapper-questionsWrapper__bordered': showLeftContainer,
              })}
              // style={{ minWidth: columnWidth || 0 }}
            >
              <CollapseButton
                onClick={() => setShowLeftContainer((old) => !old)}
                collapsed={!showLeftContainer}
                text='Overview'
              />
              <ModuleQuestionsHeader
                className='fixed-position'
                renderCenterButton={renderCenterButton}
                renderRightButton={renderRightButton}
                setModuleStatus={setModuleStatus}
              />
              <div
                className={classNames(
                  'ModuleWrapper-questionsContentContainer',
                  {
                    'gap-32': noGap === false,
                  }
                )}
              >
                {children}
              </div>
            </div>
          </div>
        </ModulePageWrapper>
      )}
    </>
  );
};
