/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState } from 'react';

type ActiveQuestionContextType = {
  activeQuestionId: number | null;
  setActiveQuestionId: (value: number | null) => void;
};

export const ActiveQuestionContext =
  React.createContext<ActiveQuestionContextType>({
    activeQuestionId: null,
    setActiveQuestionId: (id: number | null) => {},
  });

export const ActiveQuestionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [activeQuestionId, setId] = useState<number | null>(null);

  const setActiveQuestionId = (id: number | null) => {
    setId(id);
  };

  const value = {
    activeQuestionId,
    setActiveQuestionId,
  };
  // @ts-ignore
  return (
    <ActiveQuestionContext.Provider value={value}>
      {children}
    </ActiveQuestionContext.Provider>
  );
};

export const useActiveQuestion = () => {
  return useContext(ActiveQuestionContext);
};
