/* eslint-disable -- TODO: fix eslint errors */

import { useState, useEffect } from 'react';
import { Pronouncement, getPronouncements } from '../api/pronouncement';
import {
  SubscriptionPlan,
  getSubscriptionPlans,
} from '../api/subscriptionPlans';

const useSubscriptionPlans = () => {
  const [subscriptionPlans, setSubscriptionPlans] =
    useState<SubscriptionPlan[]>();
  const [monthlySubscriptionPlans, setMonthlySubscriptionPlans] =
    useState<SubscriptionPlan[]>();
  const [yearlySubscriptionPlans, setYearlySubscriptionPlans] =
    useState<SubscriptionPlan[]>();
  useEffect(() => {
    const fetchSubscriptionPlans = async () => {
      try {
        const response = await getSubscriptionPlans();
        if (response.success) {
          setSubscriptionPlans(response.data);
        }
        const monthlyPlans = response.data.filter(
          (plan) => plan.billing_period === 'monthly'
        );
        setMonthlySubscriptionPlans(monthlyPlans);
        const yearlyPlans = response.data.filter(
          (plan) => plan.billing_period === 'yearly'
        );
        setYearlySubscriptionPlans(yearlyPlans);
      } catch (error) {}
    };
    fetchSubscriptionPlans();
  }, []);

  const payPerCeremonyData = {
    pricePerCeremony: 69.99,
    fullPricePerCeremony: 89.99,
  }

  return {
    subscriptionPlans,
    monthlySubscriptionPlans,
    yearlySubscriptionPlans,
    payPerCeremonyData,
  };
};

export default useSubscriptionPlans;
