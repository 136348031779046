import infoIconMarygold from '../../../assets/images/svg/info-marygold.svg'
import infoIcon from '../../../assets/images/svg/info.svg'
import { Typography, TypographyProps } from '../Typography/Typography';

import './InfoWarning.scss';

type InfoWarningProps = {
    text: string | React.ReactElement<any, any> ;
    variant: 'yellow' | 'grey' | 'late'
    className?: string;
    typographyProps?: TypographyProps;
};

const InfoWarning = (props: InfoWarningProps) => {
    const {text, variant, className, typographyProps} = props;

    let infoIconSrc = infoIcon

    let containerClasses = 'info-container flex my-2 p-2 rounded-md' + (className ? ` ${className}` : '')  

    if (variant === 'yellow') {
        containerClasses += ' text-amber-800 bg-marygold'
        infoIconSrc = infoIconMarygold
    } 
    else if (variant === 'late') {
        containerClasses += ' bg-late-100'
    }
    else if ( variant === 'grey') {
        containerClasses += ' bg-neutral-300'
    }

    return (
        <div className={containerClasses}>
            <img src={infoIconSrc} className='mr-2 w-4' alt='info icon' />
            <div>
                <Typography
                    variant='functional-low'
                    type='body-200'
                >
                    {text}
                </Typography>
            </div>
        </div>
    )
};

export default InfoWarning;