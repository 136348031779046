import Button from '../../../../components/v2/Button/Button';
import { Typography } from '../../../../components/v2/Typography/Typography';

import './ClientsResourcesItem.scss';

export type ClientResourcesItemsProps = {
  title: string;
  description: string;
  image: string;
  buttonText: 'Watch' | 'Download' | 'View';
  link?: string;
  onClick?: () => void;
};

export const ClientResourcesItem = (props: ClientResourcesItemsProps) => {
  const { title, description, image, buttonText, link, onClick } = props;
  return (
    <div className='ClientResourcesItemContainer'>
      <div className='ClientResourcesItemImage'>
        <img src={image} alt={title + ' icon'} />
      </div>
      <Typography type='body-600'>{title}</Typography>
      <Typography type='body-200' className='mt-2'>
        {description}
      </Typography>

      {link && (
        <a href={link} target='_blank'>
          <Button className='mt-6' size='100' fullWidth={true}>
            {buttonText}
          </Button>
        </a>
      )}

      {!link && (
        <Button className='mt-6' size='100' fullWidth={true} onClick={onClick}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};
