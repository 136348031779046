import classNames from 'classnames';

import './CeremonyBasicCard.scss';

import { Menu, MenuItem } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Ceremony,
  CeremonyWeddingPlannerStatus,
  updateCeremonyWeddingPlannerStatus,
} from '../../../api/ceremony';
import { activateCeremony } from '../../../api/weddingPlanner';
import elipsisSvg from '../../../assets/images/icons/elipsis.svg';
import Button from '../../../components/v2/Button/Button';
import { Typography } from '../../../components/v2/Typography/Typography';
import { getVenueName } from '../../../helpers/helper';
import useCeremonyProgress from '../../../hooks/useCeremonyProgress';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { usePlanner } from '../../../provider/plannerProvider';
import { useSnackbar } from '../../../provider/snackbarProvider';

import DeactivateCeremonyModal from './DeactivateCeremonyModal';

type MenuType = 'progress' | 'more';

type CeremonyBasicCardProps = {
  ceremony: Ceremony;
  refetch: () => void;
};

export default function CeremonyBasicCard(props: CeremonyBasicCardProps) {
  const { refetch, ceremony } = props;
  const { wedding_planner_status, isPlannerActivated, hasAccessTo } = ceremony;
  const { openSnackBar } = useSnackbar();
  const {
    setNumberOfCeremoniesBeingAdded,
    setCeremonyIdsOfCreatedCeremonies,
    handlePayPerCeremony,
  } = usePlanner();

  const [QAStatusIsSet, setQAStatusIsSet] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuType, setMenuType] = React.useState<MenuType>('more');
  const [ceremonyStatus, setCeremonyStatus] =
    useState<CeremonyWeddingPlannerStatus | null>(null);
  const open = Boolean(anchorEl);
  const size = useWindowSize();

  const navigate = useNavigate();

  const {  fetchQaStatus } =
    useCeremonyProgress();

  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  useEffect(() => {
    wedding_planner_status &&
      setCeremonyStatus(wedding_planner_status as CeremonyWeddingPlannerStatus);
  }, [wedding_planner_status]);

  const Badge = (text: string, variant: 'green' | 'gray') => {
    return (
      <div
        className='w-[118px] cursor-pointer introJSWP_step2'
      >
        <span
          className={classNames(
            'px-2 py-1 border border-forest-400 text-xs font-medium rounded-lg select-none text-center cursor-pointer',
            {
              'text-forest-400': variant == 'gray',
              'text-white bg-system-green-400 border-0': variant == 'green',
            }
          )}
        >
          {text}
        </span>
      </div>
    );
  };

  const getCardBody = (ceremony: Ceremony) => {
    const { coupleName, date, id } = ceremony;

    let dateString = '';
    if (date && moment(date).isValid()) {
      dateString = moment.utc(ceremony?.date).format('MMM Do, YYYY');
    } else if (date !== 'Date') {
      dateString = 'TBD';
    }

    const venuePlace = getVenueName(
      ceremony?.venue ? ceremony.venue : '-',
      ceremony?.city,
      ceremony?.state,
      ceremony?.wedding_place
    );

    let CoupleInitials = '';
    if (coupleName) {
      const names = coupleName.split('&').map((name) => name.trim());
      CoupleInitials = (names[0][0] + names[names.length - 1][0]).toUpperCase();
    }
    
    const paidByWp = isPlannerActivated ? true : false;
    const paidByClient = !isPlannerActivated && hasAccessTo && hasAccessTo.length > 0;

    const getNavigateUrl = (ceremonyId: number) => {
      const urlCeremonyId = location.pathname.split('/')[2];
      return ceremonyId.toString() === urlCeremonyId
        ? `/ceremony/${ceremonyId}/planner`
        : `/ceremony/${ceremonyId}`;
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      setMenuType('more');
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleUpdateStatus = async (status: CeremonyWeddingPlannerStatus) => {
      await updateCeremonyWeddingPlannerStatus({
        ceremonyId: id,
        status: status,
      });
    };

    const activate = async () => {
      try {
        const response = await activateCeremony(id);
        if (response.success) {
          void refetch();
          handleClose();
          openSnackBar('Ceremony activated', 'success');
        } else {
          setNumberOfCeremoniesBeingAdded(1);
          openSnackBar('You do not have an active subscription', 'error');
          navigate('/wp-pay-plans/');
        }
      } catch (err) {
        setNumberOfCeremoniesBeingAdded(1);
        openSnackBar('You do not have an active subscription', 'error');
        navigate('/wp-pay-plans/');
      }
    };

    const showActionsMenu =
      isPlannerActivated ||
      (!isPlannerActivated &&
        hasAccessTo &&
        (hasAccessTo.length === 0 ||
          (hasAccessTo.length > 0 && hasAccessTo.indexOf('Bundle') < 0)));

    return (
      <>
        <Typography type='body-200' variant='functional-low' className="basicCardCell clientsCellName">
          <>
            <div className='initialsAvatar'>
              <Typography variant='functional-low' type='body-200'>
                {CoupleInitials}
              </Typography>
            </div>
            {ceremony.coupleName}
            {
              ceremony.isPlannerFreeTrial && (
                Badge('Free Trial', 'gray')
              )
            }
          </>
        </Typography>
        <Typography type='body-200' variant='functional-low' className="basicCardCell clientsCellDate">
          {dateString}
        </Typography>
        <Typography type='body-200' variant='functional-low' className="basicCardCell clientsCellLocation">
            {venuePlace == '-' ? 'TBD' : venuePlace}
        </Typography>
        <Typography type='body-200' variant='functional-low' className="basicCardCell clientsCellPaidStatus">
            { paidByWp || paidByClient ? Badge('Paid', 'green') : Badge('Unpaid', 'gray')}
        </Typography>
        <Typography type='body-200' variant='functional-low' className="basicCardCell clientsCellPaidBy">
          {paidByWp ? 'You' : paidByClient ? 'Client' : 'TBD'}
        </Typography>

        <div className={'clientsCellOptions'}>
          
          <Button
            variant='text'
            onClick={() => navigate(getNavigateUrl(id))}
          >
            View Wedding
          </Button>

          {showActionsMenu ? (
            <img
              className='inline cursor-pointer'
              onClick={(ev) => {
                if (size.isMobile) {
                  handleClick(ev);
                } else {
                  handleClick(ev);
                }
              }}
              src={elipsisSvg}
            />
          ) : (
            <div className='w-6 h-6 inline' />
          )}

          <Menu
            PaperProps={{
              style: {
                backgroundColor: '#EFEDE7',
                boxShadow:
                  '0px 1px 8px rgba(53, 71, 64, 0.16), 0px 2px 3px rgba(53, 71, 64, 0.08)',
                borderRadius: 0,
                borderWidth: '1px',
                borderColor: '#9AA3A0',
              },
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {menuType === 'more' && (
              <div>
                {isPlannerActivated && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setShowDeactivateModal(true);
                    }}
                  >
                    <Typography
                      variant='functional-low'
                      type='body-200'
                      className='text-red-800'
                    >
                      Deactivate this wedding
                    </Typography>
                  </MenuItem>
                )}
                {isPlannerActivated ||
                  (!isPlannerActivated &&
                    hasAccessTo &&
                    (hasAccessTo.length === 0 ||
                      (hasAccessTo.length > 0 &&
                        hasAccessTo.indexOf('Bundle') < 0)) && (
                      <>
                        <MenuItem
                          onClick={() => {
                            void activate();
                          }}
                        >
                          <Typography
                            variant='functional-low'
                            type='body-200'
                            className='text-red-800'
                          >
                            Activate this wedding
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setCeremonyIdsOfCreatedCeremonies([
                              ceremony.id.toString(),
                            ]);
                            void handlePayPerCeremony([ceremony.id.toString()]);
                          }}
                        >
                          <Typography
                            variant='functional-low'
                            type='body-200'
                            className='text-forest-primary'
                          >
                            Pay for this wedding
                          </Typography>
                        </MenuItem>
                      </>
                    ))}
              </div>
            )}

            {menuType === 'progress' && (
              <div>
                <MenuItem
                  onClick={() => {
                    void handleUpdateStatus(
                      CeremonyWeddingPlannerStatus.InProgress
                    );
                    setCeremonyStatus(CeremonyWeddingPlannerStatus.InProgress);
                    handleClose();
                  }}
                >
                  <Typography
                    variant='functional-low'
                    type='body-200'
                    className=''
                  >
                    Update to In-progress
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    void handleUpdateStatus(
                      CeremonyWeddingPlannerStatus.NotStarted
                    );
                    setCeremonyStatus(CeremonyWeddingPlannerStatus.NotStarted);
                    handleClose();
                  }}
                >
                  <Typography
                    variant='functional-low'
                    type='body-200'
                    className=''
                  >
                    Update to Not Started
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    void handleUpdateStatus(
                      CeremonyWeddingPlannerStatus.Completed
                    );
                    setCeremonyStatus(CeremonyWeddingPlannerStatus.Completed);
                    handleClose();
                  }}
                >
                  <Typography
                    variant='functional-low'
                    type='body-200'
                    className=''
                  >
                    Update to Complete
                  </Typography>
                </MenuItem>
              </div>
            )}
          </Menu>
        </div>
      </>
    );
  };

  useEffect(() => {
    const f = async () => {
      if (ceremony && ceremony.id && ceremony.id != -1) {
        const response = await fetchQaStatus(ceremony.id.toString());
        if (response) setQAStatusIsSet(true);
      }
    };

    void f();
  }, []);

  let titleClass = 'PlannerItem CeremonyBasicCard flex-wrap md:flex-nowrap';

  if (ceremony.id == -1) {
    titleClass += ' TitleCard hidden md:flex';
  } else {
    titleClass += ' introJSWP_step1';
  }

  return (
    <div className={titleClass}>
      {getCardBody(ceremony)}
      {showDeactivateModal ? (
        <DeactivateCeremonyModal
          ceremony={ceremony}
          closeModal={() => setShowDeactivateModal(false)}
          refetch={refetch}
        />
      ) : null}
    </div>
  );
}
