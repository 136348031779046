import { Question } from '../pages/Modules/common/moduleQuestion/ModuleQuestion';

import { _request } from './request';

export type TipsResponse = {
  success: boolean;
  message: string;
  data: TipsIdeas[];
};

export type OutputResponse = {
  success: boolean;
  message: string;
  data: OutputData[];
};

export type VowsResponse = {
  success: boolean;
  message: string;
  data: {
    content: string;
    type: 'regular' | 'ai';
    tone: Question;
    targetLength: Question;
  };
};

export type WelcomeToastResponse = {
  success: boolean;
  message: string;
  data: {
    content: string;
  }
}

export enum outputModules {
  'welcome' = 'welcome',
  'officiant-address' = 'officiant-address',
  'readings-rituals' = 'readings-rituals',
  'vows' = 'vows',
  'declaration' = 'declaration',
  'pronouncement' = 'pronouncement',
  'friends_reception_toast' = 'friends_reception_toast',
  'friends_engagement_toast' = 'friends_engagement_toast',
  'friends_rehearsal_toast' = 'friends_rehearsal_toast',
  'guest_standalone_toast' = 'guest_standalone_toast',
  'friends_reception_toast_ai' = 'friends_reception_toast_ai',
  'friends_engagement_toast_ai' = 'friends_engagement_toast_ai',
  'friends_rehearsal_toast_ai' = 'friends_rehearsal_toast_ai',
  'vows_reflectional' = 'vows_reflectional',
  'vows_promises' = 'vows_promises',
  'vows_future' = 'vows_future',
  'vows_ai' = 'vows_ai',
  'guest_standalone_toast_ai' = 'guest_standalone_toast_ai',
  'welcome_toast' = 'welcome_toast',
}

export interface OutputData {
  generated: boolean;
  id: number;
  module: outputModules;
  ceremony_id: number;
  content: string;
  member_id?: number;
  output_complete: boolean;
  createdAt: string;
  updatedAt: string;
}

export enum TipsIdeasType {
  'tips' = 'tips',
  'ideas' = 'ideas',
}

export enum SpeakingEventType {
  engagement = 'engagement_party',
  rehearsal = 'rehearsal_dinner',
  reception = 'reception',
  standalone = 'guest_standalone_toast',
}

export interface TipsIdeas {
  id: number;
  module: outputModules;
  type: TipsIdeasType;
  title: string;
  content: string;
  createdAt: string;
  updatedAt: string;
}

export interface SaveOutputBody {
  ceremony_id: string;
  module: outputModules;
  content: string;
  saveToVersionHistory?: boolean;
  generated?: boolean;
}

export type SaveOutputResponse = {
  success: boolean;
  message: string;
};

export type CeremonySpeaker = {
  id: number;
  member_id: number;
  event:
    | 'engagement_party'
    | 'rehearsal_dinner'
    | 'reception'
    | 'guest_standalone_toast';
  speech_note: string;
  alloted_time: number;
  removed_from_ceremony: boolean;
  order?: number;
  createdAt: Date;
  updatedAt: Date;
};
export type SpeakingEventsResponse = {
  success: boolean;
  message: string;
  data: CeremonySpeaker[];
};

export type SpeechOutputCompletionBody = {
  module: string;
  status: boolean;
  mid?: number;
};

export const getTips = async () => {
  const responseBody = await _request<TipsResponse>({
    url: `output/get-ideas`,
    method: 'GET',
  });
  return responseBody;
};

export const getOutputData = async (
  ceremonyId: string,
  withFiltering = false,
  guestId?: string | number
) => {
  if (guestId) {
    return await _request<OutputResponse>({
      url: `output/get-output/${ceremonyId}/${guestId}`,
      method: 'GET',
    });
  }

  return await _request<OutputResponse>({
    url: `output/get-output/${ceremonyId}${withFiltering ? `/filtered` : ''}`,
    method: 'GET',
  });
};

export const getVowsForMember = async (
  ceremonyId: string,
  memberId: string
) => {
  const responseBody = await _request<VowsResponse>({
    url: `output/get-vows/${ceremonyId}/${memberId}`,
    method: 'GET',
  });
  return responseBody;
};

export const changeSpeechOutputCompletionStatus = async (
  ceremonyId: number,
  body: SpeechOutputCompletionBody
) => {
  const responseBody = await _request<OutputResponse>({
    url: `output/mark-completed/${ceremonyId}`,
    method: 'POST',
    body: body,
  });
  return responseBody;
};

export const saveOutputData = async (body: SaveOutputBody) => {
  const responseBody = await _request<SaveOutputResponse>({
    url: `output/save-output`,
    method: 'POST',
    body,
  });
  return responseBody;
};

export const downloadOutput = async (
  ceremony_id: string | number,
  type: 'output' | 'toast' | 'vows' | 'welcome_toast',
  showTitle: boolean,
  showTime: boolean,
  readingsTime?: number,
  userId?: number,
  includeVows?: boolean
) => {
  const responseBody = await _request<Blob>({
    url: `output/get-pdf/${type}/${ceremony_id}`,
    method: 'POST',
    body: {
      showTitle,
      showTime,
      readingsTime,
      userId,
      includeVows,
    },
  });
  return responseBody;
};

export const getSpeakingEvents = async (
  ceremony_id: string | number,
  guestId: string | number = ''
) => {
  const responseBody = await _request<SpeakingEventsResponse>({
    url: `output/get-speaking-events/${ceremony_id}${
      guestId ? `/${guestId}` : ''
    }`,
    method: 'GET',
  });
  return responseBody;
};

export const getWelcomeToast = async (
  ceremony_id: string | number,
) => {
  const responseBody = await _request<WelcomeToastResponse>({
    url: `output/get-welcome-toast/${ceremony_id}`,
    method: 'GET',
  });
  return responseBody;
}
