import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import renderText from '../../../helpers/renderText';
import { Modal } from '../../modal/Modal';
import Button from '../Button/Button';

import InfoSvg from './images/info.svg';

type ReferFriendModalThankYouProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ReferFriendModalThankYou = (
  props: ReferFriendModalThankYouProps
) => {
  const { isOpen, onClose } = props;
  const navigate = useNavigate();
  // const [btnText, setBtnText] = useState<string>('Done');

  // const copy = () => {
  //   navigator.clipboard.writeText('CHEERS20');
  //   setBtnText('Copied!');
  // };

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className='max-w-[500px] text-forest-primary'>
          <div className='flex flex-col items-center justify-between'>
            <header>
              <h4 className='font-recife text-forest-primary text-3xl text-center w-full'>
                Your referrals were sent!
              </h4>
            </header>

            <div className='font-recife font-light text-base text-forest-primary w-full text-center pt-2 pb-6'>
              {renderText('refer_friend_modal_thank_you_text')}
            </div>

            <div className='text-sm font-[500] font-grotesk mb-2 py-4 px-6 bg-cosmicLatte-dark rounded-3xl flex flex-row'>
              <img src={InfoSvg} alt='info' className='inline mr-2 mt-1 self-start' />
              <span>
                Referrals can be managed from the referrals tab in your account
                settings. {` `}
                <span
                  //underline dotted
                  className='border-b border-dotted border-forest-primary cursor-pointer'
                  onClick={() => navigate(`/settings?tab=Referrals`) }
                >
                  View my referrals.
                </span>
              </span>
            </div>

            <div className='flex flex-row justify-center'>
              <Button className='py-3 mt-6' size='200' onClick={() => {
                onClose();
              }}>
                Done
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
