/* eslint-disable -- TODO: fix eslint errors */

import { useEffect, useState } from 'react';
import {
  Ceremony,
  CeremonyByIdResponse,
  getCeremonyById,
} from '../api/ceremony';
import { getModuleQuestions } from '../api/question';
import { getRituals, Ritual } from '../api/ritual';
import { Question } from '../pages/Modules/common/moduleQuestion/ModuleQuestion';
import { Module } from './useActiveModule';
import useCeremony from '../hooks/useCeremony';

const useRituals = () => {
  const { ceremony } = useCeremony();

  const [rituals, setRituals] = useState<Ritual[]>([]);

  const fetchRituals = async () => {
    try {
      const response = await getRituals();
      if (response.success) {
        setRituals(response.data);
      }
    } catch (error) {}
  };

  const getFilteredRituals = async (ritualIds: string[]) => {
    let result: Ritual[] = [];
    if (!rituals || rituals.length === 0) {
      await fetchRituals();
    }
    result = rituals
      .filter(
        (r) =>
          r.tags?.map((t) => t.name.toLowerCase()).indexOf('reading') === -1
      )
      .filter(
        (r) => r.tags?.map((t) => t.name.toLowerCase()).indexOf('poetry') === -1
      )
      .filter((r) => ritualIds.indexOf(r.id.toString()) >= 0);
    return result;
  };

  return {
    rituals,
    fetchRituals,
    getFilteredRituals,
  };
};

export default useRituals;
