/* eslint-disable -- TODO: fix eslint errors */

import * as React from 'react';
import { isValidElement } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import closeIcon from '../../../assets/images/icons/close-x-v2.svg';
import { Breakpoint } from '@mui/material';
import { Typography } from '../Typography/Typography';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { Drawer } from '../../../pages/ModulesV2/components/Drawer/Drawer';
import classNames from 'classnames';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='down' ref={ref} {...props} />;
});

type ModalTitleProps = {
  isCentered?: boolean;
};

type ModalProps = {
  isOpen: boolean;
  title?: string | JSX.Element;
  titleProps?: ModalTitleProps;
  subtitle?: string;
  onClose: () => void;
  children: JSX.Element;
  onHeaderButtonClick?: () => void;
  headerButtonText?: string;
  showCloseButton?: boolean;
  maxWidth?: Breakpoint | false;
  fullWidth?: boolean;
  backgroundColor?: string;
  fillScreen?: boolean;
  renderFooter?: React.ReactElement | false;
  height?: 'auto' | '100%';
};

export const Modal = ({
  isOpen,
  onClose,
  title,
  titleProps,
  subtitle,
  children,
  onHeaderButtonClick,
  headerButtonText,
  showCloseButton = true,
  maxWidth = 'sm',
  fullWidth,
  backgroundColor = '#efede7',
  fillScreen = false,
  renderFooter = false,
  height = 'auto',
}: ModalProps) => {
  const size = useWindowSize();

  React.useEffect(() => {
    if (isOpen) {
      //Timeout is added to prevent the elevio widget from showing up on the next modal if they are chained
      setTimeout(() => {
        document.body.classList.add('remove-elevio');
      }, 300);
    } else {
      //window._elev.closeAllPopups();
      document.body.classList.remove('remove-elevio');
    }
    return () => {
      //window._elev.closeAllPopups();
      document.body.classList.remove('remove-elevio');
    };
  }, [isOpen]);

  let paperProps = {
    style: {
      backgroundColor,
      boxShadow: 'none',
      borderRadius: 24,
      margin: 32,
      width: 'auto',
      maxHeight: 'max-height: calc(100% - 64px)',
      height: height,
    },
  };
  if (fillScreen) {
    paperProps.style.borderRadius = 0;
    paperProps.style.margin = 0;
    paperProps.style.width = '100vw';
    paperProps.style.maxHeight = '100%';
  }

  let containerClassName = isValidElement(renderFooter)
    ? `pt-8 pr-8 pl-8 flex flex-col relative text-forest-primary`
    : `p-8 relative text-forest-primary`;
  let childrenContainerClassName = isValidElement(renderFooter)
    ? 'py-4 flex-auto'
    : 'py-4';

  if (fillScreen) containerClassName += ' min-h-screen';

  if (size.width < 768)
    childrenContainerClassName += ' flex flex-col justify-center';

  return size.width > 768 ? (
    <Dialog
      PaperProps={paperProps}
      maxWidth={maxWidth ? maxWidth : false}
      fullWidth={fullWidth}
      open={isOpen}
      TransitionComponent={Transition}
      onClose={showCloseButton ? () => onClose() : () => {}}
    >
      <DialogContent
        style={{
          minWidth: 500,
          padding: 0,
        }}
      >
        <div className={containerClassName}>
          <div className='flex flex-row justify-between'>
            {showCloseButton && (
              <div
                className='cursor-pointer ml-auto absolute right-[16px] top-[16px]'
                onClick={onClose}
              >
                <img alt='close' src={closeIcon} />
              </div>
            )}
          </div>
          {title && 
          <div className={classNames(
            'flex flex-row justify-between items-center'
          )}>
            <Typography className={
              classNames({
                'text-center w-full': titleProps?.isCentered
                })
            } type='display-100'>{title}</Typography>
          </div>}
          {subtitle && 
          <div className='flex flex-row justify-between items-center mt-2 mb-4'>
            <Typography type='body-400'>{subtitle}</Typography>
          </div> }

          <div className={childrenContainerClassName}>{children}</div>
          {renderFooter}
        </div>
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={isOpen} onClose={onClose}>
      <div className={containerClassName}>
        <div className='flex flex-row justify-between'>
          {showCloseButton && (
            <div
              className='cursor-pointer ml-auto absolute right-[16px] top-[16px]'
              onClick={onClose}
            >
              <img alt='close' src={closeIcon} />
            </div>
          )}
        </div>
        <div className='flex flex-row justify-between items-center'>
          {title && <Typography type='display-100'>{title}</Typography>}
        </div>

        <div className={childrenContainerClassName}>{children}</div>
        {renderFooter}
      </div>
    </Drawer>
  );
};
