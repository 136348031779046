import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';

import { Ceremony } from '../../../api/ceremony';
import { Ritual } from '../../../api/ritual';
import QuestionSaveStatus from '../../../components/QuestionSave';
import CustomTextInput from '../../../components/forms/CustomTextInput';
import { Typography } from '../../../components/v2/Typography/Typography';
import useCeremony from '../../../hooks/useCeremony';
import { useReadings } from '../../../provider/readingsProvider';
import {
  Question,
  QuestionAnswer,
} from '../common/moduleQuestion/ModuleQuestion';

import { ReadersQuestion } from './ReadersQuestion';

type QuestionProps = {
  question: Question;
  readersQuestion: Question;
  ceremony?: Ceremony;
  answer: QuestionAnswer;
  ritual?: Ritual;
  handleSave: (
    question: Question,
    ceremony: Ceremony,
    answer: QuestionAnswer
  ) => void | Promise<void>;
  handleRemove: (ritual: Ritual) => void;
  handleRitualClick: (ritual: Ritual) => void;
  setQuestionChanged: (value: boolean) => void;
};

export const ReadingsOptionQuestion = (props: QuestionProps) => {
  const {
    question,
    ceremony,
    answer,
    ritual,
    handleSave,
    readersQuestion,
    setQuestionChanged,
  } = props;
  const [value, setValue] = useState('');

  const { saved, setSaved } = useReadings();

  const { currentUser, fetchCeremonyById } = useCeremony();

  const handleTextChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setSaved(false);
    setValue(ev.target.value);
  };

  useEffect(() => {
    if (answer && answer.text_answer) {
      setValue(answer.text_answer);
    }
  }, [answer]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useCallback(
    debounce(
      (
        question: Question,
        answer: QuestionAnswer,
        value?: string,
        ceremony?: Ceremony
      ) => {
        if (ceremony) {
          void handleSave(question, ceremony, {
            ...answer,
            text_answer: value,
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    if (saved === false) {
      debouncedCall(question, answer, value, ceremony);
    } else {
      //setTitle('Saved');
    }
  }, [ceremony, question, answer, value, debouncedCall]);

  useEffect(() => {
    if (!currentUser && ceremony?.id){
      fetchCeremonyById(ceremony.id.toString())
    }
  }, [ceremony])

  const subHeading = currentUser && currentUser.member_type == 'officiant' ? 'If applicable, please have the couple answer the following questions about their selection to provide more context and detail to inform your script.' : 'If applicable, please answer the following questions about your selection to provide more context and detail to your officiant.'

  const questionText = currentUser && currentUser.member_type == 'officiant' ? 'What makes this reading/ritual significant to the couple?' : 'What makes this reading/ritual significant to you?'

  const questionPlaceHolder = currentUser && currentUser.member_type == 'officiant' ? '' : 'This will help your officiant provide context during the ceremony'

  return (
    <>
      <div className='p-6 mb-10 w-full border border-forest-100 rounded-3xl'>
        <Typography type='display-50'>Additional Info</Typography>
        <Typography type='body-400' className='mt-2'>
          {subHeading}
        </Typography>
        <hr className='block my-6 w-full border-t border-forest-100' />
        <Typography type='display-50'>
          {questionText}
        </Typography>
        <Typography variant='functional-low' type='body-200' className='mt-4'>
          Please explain
        </Typography>
        <CustomTextInput
          className='mx-0'
          multiline
          rows={5}
          value={value}
          onChange={handleTextChange}
          placeholder={questionPlaceHolder}
        />
        {ritual && (
          <ReadersQuestion
            setQuestionChanged={() => setSaved(false)}
            ritual={ritual}
            question={readersQuestion}
            ceremony={ceremony}
            readingsQuestion={question}
            showTip={false}
          />
        )}
      </div>
    </>
  );
};
