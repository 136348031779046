import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MemberType } from '../../../api/ceremony';
import { OrderSources, appToolOptions } from '../../../api/paywall';
import drawingPen from '../../../assets/images/image/drawing_pen.png';
import drawingSpeaker from '../../../assets/images/image/drawing_speaker.png';
import { CouplesNotes } from '../../../components/v2/CouplesNotes/CouplesNotes';
import { MerchandisingCard } from '../../../components/v2/MerchandisingCards/MerchandisingCard';
import { MerchandisingModal } from '../../../components/v2/MerchandisingModal/MerchandisingModal';
import { Typography } from '../../../components/v2/Typography/Typography';
import { VowsDashboardItem } from '../../../components/v2/VowsDashboardItem/VowsDashboardItem';
import { setCookie } from '../../../helpers/helper';
import { Module } from '../../../hooks/useActiveModule';
import useToast from '../../../hooks/useToast';
import { useActiveModule } from '../../../provider/activeModuleProvider';
import { useDashboard } from '../../../provider/dashboardProvider';
import { useMerchandise } from '../../../provider/merchandisingProvider';

import { GuestDashboardWarning } from './GuestDashboardWarning';

const GuestDashboard = () => {
  const { ceremony, currentUser, currentRole, speakingEvents } = useDashboard();

  const {
    couplesNote,
    toast,
    toastDuration,
    toastTone,
    isNewToastBuilder,
    isOldToastBuilder,
    showOldToastBuilder,
    fetchToast,
    setShowOldToastBuilder,
  } = useToast();

  const navigate = useNavigate();

  if (currentRole && currentRole !== MemberType.guests) {
    navigate(`/ceremony/${ceremony?.id.toString() || ''}`);
  }

  const { setActiveModule } = useActiveModule();

  const { setAppTool } = useMerchandise();

  const onClickHandler = (module: Module) => {
    if (ceremony) {
      setActiveModule(module);
      navigate(`/modules/${ceremony.id}`);
    }
  };

  useEffect(() => {
    if (ceremony?.id) {
      setCookie('last_active_ceremony', ceremony.id.toString(), 365);
      void fetchToast(ceremony.id.toString());
      setAppTool(appToolOptions.toast_builder);
    }
  }, [ceremony]);

  useEffect(() => {
    if (isNewToastBuilder) {
      setShowOldToastBuilder(false);
    } else if (isOldToastBuilder) {
      setShowOldToastBuilder(true);
    }
  }, [isOldToastBuilder, isNewToastBuilder]);

  const handleButtonClick = () => {
    if (ceremony) {
      let toastBuilderUrl = '';
      if (!showOldToastBuilder && isNewToastBuilder) {
        if (toast?.length === 0 || toast === undefined) {
          toastBuilderUrl = `/ceremony/${ceremony?.id}/toast`;
        } else {
          toastBuilderUrl = `/ai-toast-builder/${ceremony?.id}`;
        }
      } else if (showOldToastBuilder && isOldToastBuilder) {
        toastBuilderUrl = `/toast-builder/${ceremony?.id}`;
      }
      navigate(toastBuilderUrl);
    }
  };

  return (
    <div className='CeremonyChecklist-root'>
      
      {speakingEvents.length > 0 &&
        showOldToastBuilder &&
        isOldToastBuilder &&
        !isNewToastBuilder && (
          <GuestDashboardWarning
            message='Experience our Innovative Toast Builder'
            onClick={() => setShowOldToastBuilder(false)}
            buttonLabel='Try now'
          />
        )}

      {speakingEvents.length > 0 &&
        !showOldToastBuilder &&
        isOldToastBuilder &&
        !isNewToastBuilder && (
          <GuestDashboardWarning
            message='Want to switch back to the original toast builder?'
            onClick={() => setShowOldToastBuilder(true)}
            buttonLabel='Revert'
          />
        )}
      
      <div className='CeremonyChecklist-header'>
        <div>
            <Typography type='display-400' className='pb-2'>
              Toast Builder
            </Typography>
            {/* <Typography type='body-400' className='max-w-[540px]'>
              This is a short description of the current page. It shouldn’t exceed two sentences or lines of text on any page that it exists.
            </Typography> */}
          </div>
      </div>

      <div className='flex flex-col gap-5'>
        <div className='flex flex-col'>
          {/* {currentUser && currentUser.contribute_to_wedding && ceremony && (
            <VowsDashboardItem
              variant='toast'
              member={currentUser}
              text='Stressed about giving a wedding speech?
              Answer some questions, then watch as your responses get woven together into a great first draft that you can edit until the big day.'
              showVows={true}
              showTime={true}
              vowsTime={2}
              canEditVows={false}
              title={'Friend and Family Questionnaire'}
              ceremonyId={ceremony.id.toString()}
              onButtonClick={() => onClickHandler(Module.GuestFFQ)}
              paywallSource={OrderSources.tb_landing}
              hasAccess={true}
            />
          )} */}

          {speakingEvents.length > 0 && currentUser && ceremony && (
            <React.Fragment>
              {showOldToastBuilder &&
                isOldToastBuilder &&
                !isNewToastBuilder && (
                  <VowsDashboardItem
                    variant='toast'
                    member={currentUser}
                    showVows={true}
                    showTime={true}
                    vowsTime={2}
                    canEditVows={false}
                    title={'Wedding Toast'}
                    ceremonyId={ceremony.id.toString()}
                    onButtonClick={() => onClickHandler(Module.GuestToast)}
                    paywallSource={OrderSources.tb_landing}
                  />
                )}
              {couplesNote && (
                <CouplesNotes variant='dashboard' text={couplesNote} />
              )}
              <VowsDashboardItem
                variant='toast'
                member={currentUser}
                vows={toast}
                showVows={true}
                showTime={true}
                vowsTime={toastDuration}
                vowsTargetLength={toastDuration}
                canEditVows={true}
                hasAccess={true}
                title={'Your Toast'}
                vowsTone={toastTone || ''}
                text={''}
                ceremonyId={ceremony.id.toString()}
                onButtonClick={handleButtonClick}
                paywallSource={OrderSources.tb_landing}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default GuestDashboard;
