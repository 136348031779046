import { _request } from './request';

export type Culture = {
  id: number;
  name: string;
};

export type CultureBody = {
  //for saving a new culture
  name: string;
  created_for: number;
};

export type SaveCultureResponse = {
  success: boolean;
  message: string;
  data: Culture;
};

export type GetCulturesResponse = {
  success: boolean;
  message: string;
  data: Culture[];
};

export const getCultures = async () => {
  const responseBody = await _request<GetCulturesResponse>({
    url: `cultures`,
    method: 'GET',
  });
  return responseBody;
};

export const saveCulture = async (culture: CultureBody) => {
  const responseBody = await _request<SaveCultureResponse>({
    url: 'cultures/save-culture',
    method: 'POST',
    body: { culture },
  });
  return responseBody;
};
