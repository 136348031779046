import { MerchandiseProduct } from '../provider/merchandisingProvider';

import { appToolOptions, OrderResponse } from './paywall';
import { _request } from './request';

export type MerchandiseResponse = {
  success: boolean;
  message: string;
  data: null | MerchandiseProduct[]
  errors?: unknown;
}

export const getMerchandiseProducts = async (ceremony_id: string, app_tool: appToolOptions) => {
  const responseBody = await _request<MerchandiseResponse>({
    url: `merchandising/products/` + ceremony_id + '/' + app_tool,
    method: 'GET',
  });
  return responseBody;
};

