import { useActiveCampaign } from '../../../provider/campaignProvider';

type CampaignItemProps = {
  renderCampaignComponent: (
    discountPercentage: number,
    couponCode: string,
    campaignText?: string
  ) => JSX.Element;
  regularComponent?: JSX.Element;
};

const CampaignItem = (props: CampaignItemProps) => {
  const { renderCampaignComponent, regularComponent } = props;
  const { hasActiveCampaign, campaignText, couponCode, discountPercentage } =
    useActiveCampaign();
  if (!hasActiveCampaign) {
    if (regularComponent) {
      return regularComponent;
    } else {
      return <></>;
    }
  } else {
    return renderCampaignComponent(
      discountPercentage,
      couponCode,
      campaignText
    );
  }
};

export default CampaignItem;
