import { GenericResponse } from './ceremony';
import { _request } from './request';

export type Giftcard = {
  id?: number;
  from_email: string;
  from_name: string;
  to_email: string;
  to_name: string;
  custom_message: string;
  order_id: number;
  redeemed: boolean;
};

export type SaveGiftcardResponse = {
  success: boolean;
  message: string;
};

export type GetGiftcardResponse = {
  success: boolean;
  message: string;
  data: Giftcard;
};

export const saveGiftcard = async (giftcard: Giftcard) => {
  const responseBody = await _request<SaveGiftcardResponse>({
    url: `giftcards/save-giftcard`,
    method: 'POST',
    body: giftcard,
  });
  return responseBody;
};

export const getGiftcard = async () => {
  const responseBody = await _request<GetGiftcardResponse>({
    url: `giftcards/get-giftcard`,
    method: 'GET',
  });
  return responseBody;
};

export const redeemGiftcard = async (
  giftcardId: number,
  ceremonyId: number
) => {
  const responseBody = await _request<GenericResponse>({
    url: `giftcards/redeem-giftcard`,
    method: 'POST',
    body: {
      giftcardId,
      ceremonyId,
    },
  });
  return responseBody;
};
