import classNames from 'classnames';

import star from '../../../assets/images/svg/star.svg'
import { Typography } from '../Typography/Typography';


type ReviewProps = {
  stars: number
  review: string;
  author: string;
  authorTitle?: string;
  date?: string;
  className?: string;
};

export const Review = (props: ReviewProps) => {
    const {stars, author, authorTitle, date, className} = props;

    const getStars = () => {
        const starsArray = [];
        for (let i = 0; i < stars; i++) {
            starsArray.push(<img key={i} src={star} className='w-[40px] h-[40px]' />)
        }
        return starsArray;
    }

    const getFootNote = () => {
        let text = author;
        if (authorTitle) text += ` | ${authorTitle}`;
        if (date) text += ` | ${date}`;
        return text;
    }


  return (
    <div className={className}>
        <div className={'flex flex-row justify-center'}>
            {getStars()}
        </div>

        <Typography 
            variant='functional-low' 
            type='display-400' 
            className='mt-2 font-recife text-xl text-center font-light'
        >
            {props.review}
        </Typography>

        <Typography 
            variant='functional-low' 
            type='display-400' 
            className='mt-2 text-md text-center '
        >
            {getFootNote() }
        </Typography>


    </div>
  )
}