import { Module } from '../hooks/useActiveModule';

import { _request, _requestWithRetry } from './request';

type GenerateSpeechTextRequest = {
  ceremonyId: number;
};

type GenerateCeremonyDesignerTextRequest = {
  ceremonyId: number | string;
  selectedModule: Module;
  saveResultOnBE?: boolean;
};

type GenerateSpeechTextResponse = {
  success: boolean;
  text: string;
};

export const generateToastbuilderText = async (
  body: GenerateSpeechTextRequest
): Promise<GenerateSpeechTextResponse> => {
  const responseBody = await _requestWithRetry<GenerateSpeechTextResponse>({
    url: `ai/toastbuilder`,
    method: 'POST',
    body,
  });
  return responseBody;
};

export const generateVowbuilderText = async (
  body: GenerateSpeechTextRequest
) => {
  const responseBody = await _request<GenerateSpeechTextResponse>({
    url: `ai/vowbuilder`,
    method: 'POST',
    body,
  });
  return responseBody;
};

export const generateWelcomeToastbuilderText = async (
  body: GenerateSpeechTextRequest
): Promise<GenerateSpeechTextResponse> => {
  const responseBody = await _requestWithRetry<GenerateSpeechTextResponse>({
    url: `ai/welcometoastbulider`,
    method: 'POST',
    body,
  });
  return responseBody;
};


export const generateCeremonyScriptText = async (
  body: GenerateCeremonyDesignerTextRequest
) => {
  const responseBody = await _request<GenerateSpeechTextResponse>({
    url: `ai/ceremony-script`,
    method: 'POST',
    body,
  });
  return responseBody;
};
