import { GenerationCompletedModal } from '../../components/v2/GenerationCompletedModal/GenerationCompletedModal';
import { useToastBuilder } from '../../provider/toastBuilderProvider';

type ToastCompletedModalProps = {
  onCancelClick: () => void;
  onDownloadPDFClick: () => void;
};

export const ToastCompletedModal = (props: ToastCompletedModalProps) => {
  const { completedModalOpen, setCompletedModalOpen } = useToastBuilder();
  const { onCancelClick, onDownloadPDFClick } = props;
  return (
    <GenerationCompletedModal
      onCancelClick={onCancelClick}
      onDownloadPDFClick={onDownloadPDFClick}
      modalOpen={completedModalOpen}
      setModalOpen={setCompletedModalOpen}
      title='Congrats! Your toast is complete'
      description='You can download a PDF version of your toast here, or on the Toast Builder dashboard. If at any point you need to adjust your toast, simply return to the Toast Builder.'
    />
  );
};
