import { Typography } from '../../../components/v2/Typography/Typography';
import { getFullName } from '../../../helpers/nameHelper';
import { useReadings } from '../../../provider/readingsProvider';
import { ReadingsOptionQuestion } from '../../Modules/ritualsAndReadings/ReadingsOptionQuestion';

const DetailView = () => {
  const {
    selectedRitual,
    tempAnswers,
    allReadings,
    question,
    readersQuestion,
    handleSave,
    ceremony,
    setCultureValues,
    setRitualTypeValues,
    setTagValues,
    setSelectedRitual,
  } = useReadings();
  if (!selectedRitual) {
    return null;
  }
  return (
    <div>
      <div>
        {allReadings &&
          question &&
          readersQuestion &&
          allReadings.length > 0 &&
          tempAnswers
            .filter((ta) => ta.option_id === selectedRitual.id.toString())
            .map((a) => (
              <ReadingsOptionQuestion
                key={a.option_id}
                question={question}
                readersQuestion={readersQuestion}
                ceremony={ceremony}
                answer={a}
                ritual={allReadings.find(
                  (r) => r.id.toString() === a.option_id
                )}
                handleSave={handleSave}
                handleRemove={() => {}}
                handleRitualClick={() => {}}
                setQuestionChanged={() => {}}
              />
            ))}
      </div>
      <Typography variant='primary' type='display-200' className='mb-2'>
        {selectedRitual.name}
      </Typography>
      <div className='py-3 flex flex-wrap flex-row flex-shrink-0'>
        <div>
          <Typography
            variant={'functional-low'}
            type='body-200'
            className='text-forest-300'
          >
            <div className='mr-2'>
              {(selectedRitual.author || selectedRitual.createdBy) &&
                `By ${
                  selectedRitual.author ||
                  (selectedRitual.createdBy
                    ? getFullName(selectedRitual.createdBy)
                    : '')
                }   ·   `}
              {selectedRitual.time && `${selectedRitual.time} min   ·`}
            </div>
          </Typography>
        </div>
        <div className=' flex flex-row'>
          {selectedRitual?.cultures.map((c) => (
            <Typography
              key={c.id}
              variant={'functional-low'}
              type='body-200'
              className='underline decoration-dashed mr-2 cursor-pointer'
              onClick={() => {
                setCultureValues([c.id.toString()]);
                setRitualTypeValues([]);
                setTagValues([]);
                setSelectedRitual(undefined);
              }}
            >
              {c.name}
            </Typography>
          ))}
          {selectedRitual?.ritual_types.map((c) => (
            <Typography
              key={c.id}
              variant={'functional-low'}
              type='body-200'
              className='underline decoration-dashed mr-2 cursor-pointer'
              onClick={() => {
                setCultureValues([]);
                setRitualTypeValues([c.id.toString()]);
                setTagValues([]);
                setSelectedRitual(undefined);
              }}
            >
              {c.name}
            </Typography>
          ))}
          {selectedRitual?.tags?.map((c) => (
            <Typography
              key={c.id}
              variant={'functional-low'}
              type='body-200'
              className='underline decoration-dashed mr-2 cursor-pointer'
              onClick={() => {
                setCultureValues([]);
                setRitualTypeValues([]);
                setTagValues([c.id.toString()]);
                setSelectedRitual(undefined);
              }}
            >
              {c.name}
            </Typography>
          ))}
        </div>
      </div>
      <div className='mt-10'>
        <Typography variant='functional-high' type='body-400'>
          {/* {selectedRitual.text} */}
          <span dangerouslySetInnerHTML={{ __html: selectedRitual.text }} />
        </Typography>
      </div>
    </div>
  );
};

export default DetailView;
