/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useEffect, useState } from 'react';

import { Member, MemberType } from '../../../api/ceremony';
import GiftIcon from '../../../assets/images/svg/gift.svg';
import renderText from '../../../helpers/renderText';
import { useDashboard } from '../../../provider/dashboardProvider';
import { useAccessibleModule } from '../../../provider/moduleAccessProvider';
import Button from '../Button/Button';
import { ReferFriendModal } from '../ReferFriendModal/ReferFriendModal';
import { ReferFriendModalWP } from '../ReferFriendModal/ReferFriendsModalWP';

const ReferFriends = ({ expanded }: { expanded: boolean }) => {

  const { ceremony, currentUser, isReferralModalOpen, setIsReferralModalOpen } = useDashboard();

  const {
    hasAccessToToastBuilder,
    accessibleModuleIdentifiersFetched,
    fetchAccessibleModulesIdentifiers,
  } = useAccessibleModule();

  useEffect(() => {
    if (!accessibleModuleIdentifiersFetched && ceremony?.id) {
      fetchAccessibleModulesIdentifiers(ceremony.id);
    }
  }, [ceremony, currentUser, accessibleModuleIdentifiersFetched]);

  const conditionallyRenderModal = (
    currentUser: Member | undefined,
    isOpen: boolean,
    setIsOpen: any
  ) => {


    if (currentUser && currentUser.member_type === MemberType.weddingPlanner) {
      return (
        <ReferFriendModalWP isOpen={isOpen} onClose={() => setIsOpen(false)} />
      );
    }



    return (
      <ReferFriendModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        ceremonyId={ceremony?.id}
      />
    );
  };

  return (
    <>
      {expanded && currentUser && (
        <>
          <Button
            size='100'
            onClick={() => setIsReferralModalOpen(true)}
            className='bg-transparent hover:bg-transparent text-forest-500 font-grotesk font-bold text-sm border border-forest-500 w-full'
          >
            <div className='flex justify-center'>
              <img src={GiftIcon} className='mr-2' alt='gift-icon' />
              {renderText('refer_friends_button_text')}
            </div> 
          </Button>
        </>
      )}

      {!expanded && currentUser && (
        <>
          <Button
            size='100'
            onClick={() => setIsReferralModalOpen(true)}
            className='bg-transparent text-forest-500 font-grotesk font-bold text-sm border border-forest-500 w-[44px] block mx-auto'
          >
            <div className='flex justify-center'>
              <img src={GiftIcon} alt='gift-icon' />
            </div>
          </Button>
        </>
      )}
      {conditionallyRenderModal(currentUser, isReferralModalOpen, setIsReferralModalOpen)}
    </>
  );
};

export default ReferFriends;
