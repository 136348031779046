/* eslint-disable -- TODO: fix eslint errors */

import debounce from 'lodash.debounce';
import { useCallback, useRef, useState } from 'react';
import { outputModules, SaveOutputBody, saveOutputData } from '../api/output';


type useEditorProps = {
  ceremony_id: string;
  module: outputModules;
  initialValue?: string;
  setSavedCallback?: (value: boolean) => void;
  setSavingCallback?: (value: boolean) => void;
};
const useEditor = ({
  ceremony_id,
  module,
  setSavedCallback,
  setSavingCallback,
}: useEditorProps) => {
  const [forceRender, setForceRender] = useState<boolean>(false);
  const textRef = useRef('');
  const generatedRef = useRef(false);

  const handleSetValue = useCallback(
    (text: string,  generated?: boolean, skipAutosave?: boolean, saveToVersionHistory?: boolean) => {

      textRef.current = text;
      generatedRef.current = generated ? true : false;
      setForceRender(!forceRender);

      if (!skipAutosave) {
        if (setSavedCallback) {
          setSavedCallback(true);
        }
        if (saveToVersionHistory) {
          callDebouncedSaveAPI(
            textRef.current,
            ceremony_id,
            module,
            saveToVersionHistory, 
            generatedRef.current,
          );
        } else {
          debouncedCall(
            textRef.current,
            ceremony_id,
            module,
            saveToVersionHistory,
            generatedRef.current,
          );
        }
      }
    },
    []
  );

  const insertText = (text: string) => {
    textRef.current = textRef.current + `<p>${text}<p>`;
    const generated = true;
    callDebouncedSaveAPI(textRef.current, ceremony_id, module, generated);
  };

  const callDebouncedSaveAPI = async (
    val: string,
    ceremony_id: string,
    module: outputModules,
    saveToVersionHistory?: boolean,
    generated?: boolean,
  ) => {
    if (setSavingCallback) {
      setSavingCallback(true);
    }
    let body: SaveOutputBody = {
      content: val,
      ceremony_id: ceremony_id,
      module: module,
      saveToVersionHistory: saveToVersionHistory,
      generated, 
    };
    let response = await saveOutputData(body);
    if (response.success) {
      if (setSavedCallback) {
        setSavedCallback(true);
      }
      if (setSavingCallback) {
        setSavingCallback(false);
      }
    }
  };

  const debouncedCall = useCallback(
    debounce((val, ceremony_id, module, saveToVersionHistory?, generated?) => {
      callDebouncedSaveAPI(val, ceremony_id, module, saveToVersionHistory, generated);
    }, 2000),
    []
  );

  return {
    value: textRef.current,
    setValue: handleSetValue,
    saving: false,
    saved: true,
    insertText,
    debouncedSave: debouncedCall,
    generated: generatedRef.current,
  };
};

export default useEditor;
