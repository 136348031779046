import { CheckCircleOutline } from '@mui/icons-material';
import classNames from 'classnames';

import { SelectOptionProps } from './QuestionSelect';

const SimpleSelectOption = ({
  label,
  isSelected = false,
  onClick,
}: SelectOptionProps) => {
  const normalClasses =
    'px-6 py-4 flex flex-row items-center gap-2 rounded-full select-none';
  const unselectedClasses = 'border border-forest-primary';
  const selectedClasses =
    'border-[3px] border-copper-primary text-copper-primary';
  return (
    <button
      type='button'
      {...{ onClick }}
      className={classNames(normalClasses, {
        [unselectedClasses]: !isSelected,
        [selectedClasses]: isSelected,
      })}
    >
      <span
        className={classNames('font-grotesk text-base', {
          'text-forest-primary': !isSelected,
        })}
      >
        {label}
      </span>
      {isSelected && <CheckCircleOutline className='w-6 h-6' />}
    </button>
  );
};

export default SimpleSelectOption;
