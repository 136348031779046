/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';

import { Speaker } from '../../../api/friends';
import drag from '../../../assets/images/icons/Drag.svg';

export interface DraggingStyle {
  position: 'fixed';
  top: number;
  left: number;
  boxSizing: 'border-box';
  width: number;
  height: number;
  transition: 'none';
  transform?: string | undefined;
  zIndex: number;
  opacity?: number | undefined;
  pointerEvents: 'none';
}

export interface NotDraggingStyle {
  transform?: string | undefined;
  transition?: 'none' | undefined;
}

type SpeakerAccordianProps = {
  speaker: Speaker;
  expanded: number;
  setExpanded: (val: number) => void;
  onRemove: (val: number) => void;
  role: string;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
};
const SpeakerAccordian = ({
  speaker,
  expanded,
  setExpanded,
  role,
  onRemove,
  provided,
  snapshot,
}: SpeakerAccordianProps) => {
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={`${
        snapshot.draggingOver ? 'cursor-pointer' : 'cursor-move'
      } mt-3`}
    >
      <Accordion
        className='border border-forest-100 shadow-none rounded-none py-3'
        expanded={expanded === speaker.id}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMore className='text-copper-primary' fontSize='large' />
          }
          onClick={() => {
            if (expanded === speaker.id) {
              setExpanded(0);
            } else {
              setExpanded(speaker.id);
            }
          }}
        >
          <div className='flex flex-row items-center'>
            <img
              src={drag}
              alt='drag'
              className={'bg-transparent w-8 h-8 mr-1 cursor-move'}
            />
            <h3 className='font-nanum font-bold text-forest-primary text-2.5xl'>
              {speaker.member.legal_name}
            </h3>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div
            className={`grid grid-cols-4 md:grid-cols-6 lg:grid-cols-12 px-2 gap-2 content-center place-content-center w-full`}
          >
            <div className='col-span-4 md:col-span-2 lg:col-span-6'>
              <h3 className='uppercase font-inter text-copper-primary text-sm'>
                Email
              </h3>
              <h1 className='font-nanum text-forest-primary font-bold py-1'>
                {speaker.member.email}
              </h1>
            </div>
            <div className='col-span-4 md:col-span-2 lg:col-span-3'>
              <h3 className='uppercase font-inter text-copper-primary text-sm'>
                Allotted time
              </h3>
              <h1 className='font-nanum text-forest-primary font-bold py-1'>
                {speaker.alloted_time} minutes
              </h1>
            </div>
            <div className='col-span-4 md:col-span-2 lg:col-span-3'>
              <h3 className='uppercase font-inter text-copper-primary text-sm'>
                Role
              </h3>
              <h1 className='font-nanum text-forest-primary font-bold py-1'>
                {role}
              </h1>
            </div>
            <div className='col-span-4 md:col-span-6 lg:col-span-12 mt-3'>
              <h3 className='uppercase font-inter text-copper-primary text-sm'>
                Note for speech
              </h3>
              <h1 className='font-nanum text-forest-primary font-bold py-1'>
                {speaker.speech_note}
              </h1>
            </div>

            <div className='col-span-4 md:col-span-6 lg:col-span-12'>
              <h3
                className='uppercase font-inter text-copper-primary text-sm text-right cursor-pointer'
                onClick={() => {
                  onRemove(speaker.id);
                }}
              >
                Remove Speaker
              </h3>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default SpeakerAccordian;
