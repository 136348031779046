import {
  SaveWaitlistQABody,
  WaitListEnrollment,
  WaitListQuestions,
} from '../../../api/waitlist';

import RadioQuestion from './RadioQuestion';
import SelectQuestion from './SelectQuestion';
import TextQuestion from './TextQuestion';

type WaitlistQuestionsProps = {
  questions: WaitListQuestions[];
  waitlist?: WaitListEnrollment;
  answerQuestions: () => Promise<void>;
  setQA: React.Dispatch<React.SetStateAction<SaveWaitlistQABody[]>>;
  QA: SaveWaitlistQABody[];
};

const WaitlistQuestions = (props: WaitlistQuestionsProps) => {
  const { questions, waitlist, QA, answerQuestions, setQA } = props;

  const updateAnswer = (qid: string, oid: string, textans: string) => {
    const answer: SaveWaitlistQABody = { qid, oid, textans };
    const filteredAnswers: SaveWaitlistQABody[] = QA.filter(
      (item) => item.qid !== qid
    );
    filteredAnswers.push(answer);
    setQA(filteredAnswers);
  };

  return (
    <div className='container max-w-[912px] grid grid-cols-4 md:grid-cols-6 lg:grid-cols-12 px-4 mx-auto'>
      {questions
        .sort((a, b) => {
          if (a.order && b.order) {
            return a.order < b.order ? -1 : 1;
          } else if (a.order != null && b.order === null) {
            return -1;
          } else if (a.order === null && b.order != null) {
            return 1;
          } else {
            return -1;
          }
        })
        .map((question) => {
          const answer = QA.find((item) => item.qid === question.id.toString());
          if (question.type === 'select') {
            return (
              <SelectQuestion
                question={question}
                updateAnswer={updateAnswer}
                answer={answer}
                key={question.id}
              />
            );
          } else if (question.type === 'radio') {
            return (
              <RadioQuestion
                question={question}
                updateAnswer={updateAnswer}
                answer={answer}
                key={question.id}
              />
            );
          } else if (question.type === 'text') {
            return (
              <TextQuestion
                question={question}
                updateAnswer={updateAnswer}
                answer={answer}
                key={question.id}
              />
            );
          } else {
            return '';
          }
        })}
      <div className='col-span-4 md:col-span-6 lg:col-span-12 w-full my-4 flex flex-row justify-end'>
        <button
          onClick={() => {
            void answerQuestions();
          }}
          className={`px-2 md:px-3 lg:px-4 xl:px-6 2xl:px-8 
            py-2 md:py-2 lg:py-3 xl:py-4 2xl:py-5 mt-4 bg-forest-primary text-white
            w-full max-w-sm uppercase text-base flex justify-center border border-forest-primary cursor-pointer`}
        >
          {waitlist ? 'Submit' : 'Submit & Join Waitlist'}
        </button>
      </div>
    </div>
  );
};

export default WaitlistQuestions;
