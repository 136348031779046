import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  appToolOptions,
  NewPaymentBody,
  OrderType,
  PayOrderBody,
  PayOrderResponse,
  PaymentMethod,
  newpaymentSession,
  placeNewOrder,
} from '../../api/paywall';
import arrowLeftSvg from '../../assets/images/icons/arrow-left-circle.svg';
import speechwritingImg from '../../assets/images/image/drawing_pen.png';
import drawingSpeaker from '../../assets/images/image/drawing_speaker.png';
import Container from '../../components/Container';
import Loader from '../../components/Loader/loader';
import SnackbarComponent from '../../components/Snackbar/Snackbar';
import Button from '../../components/v2/Button/Button';
import CampaignItem from '../../components/v2/CampaignItem/CampaignItem';
import { Review } from '../../components/v2/Review/Review';
import { Typography } from '../../components/v2/Typography/Typography';
import { sendCustomerIoEventHandler } from '../../helpers/customerIoHelper';
import { getAPIErrorMessage } from '../../helpers/helper';
import useLoader from '../../hooks/useLoader';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useDashboard } from '../../provider/dashboardProvider';
import { PayPlansList } from '../paywall/PayPlans';

export type ToastBuilderProduct = {
  id: number;
  name: string;
  title: string;
  description: string;
  price: number;
  paywall_title: string;
  paywall_description: string;
  paywall_attributes: string[];
};

const PaywallToastBuilder = () => {
  const products: ToastBuilderProduct[] = [
    {
      id: 1,
      name: 'guest_toast_builder',
      title: 'Guest Toast Builder',
      description:
        'Our professional speechwriters will craft a heartfelt, memorable speech for your special occasion.',
      price: 29.99,
      paywall_title: 'Toast Builder',
      paywall_description:
        'Generate a first draft of your heartfelt toast to the couple that will be remembered by the couple for years to come.',
      paywall_attributes: [
        'Toast Questionnaire',
        'Unlimited Generations of Your Speech',
        'Unlimited Revisions Thereafter',
      ],
    },
  ];

  const { appTool, ceremonyId } = useParams();
  const navigate = useNavigate();
  const { isShowing, hideLoader, showLoader } = useLoader();

  const { isActive, message, type, handleClose, openSnackBar } = useSnackbar();

  const { ceremony, fetchCeremony } = useDashboard();

  function isappToolOptions(value: string): value is appToolOptions {
    return Object.values<string>(appToolOptions).includes(value);
  }

  useEffect(() => {

    void sendCustomerIoEventHandler('Guest Payment Process Started');

    if (!ceremony && ceremonyId) fetchCeremony(ceremonyId);
  }, []);

  const proceedToPayWall = async (product: ToastBuilderProduct) => {
    try {
      showLoader();

      const reqBody: PayOrderBody = {
        payment_method: PaymentMethod.card,
        type: OrderType.guest_toast_builder,
        value: product.price,
        currency: 'USD',
        ceremony_id: ceremonyId,
        product_id: product.id,
      };

      const orderResponse: PayOrderResponse = await placeNewOrder(reqBody);

      void sendCustomerIoEventHandler('Guest Plan Selected');

      if (orderResponse.success) {
        await createPaymentSession(orderResponse.data.ref);
      } else {
        openSnackBar(orderResponse.message);
      }
    } catch (err) {
      openSnackBar(getAPIErrorMessage(err as any));
    } finally {
      hideLoader();
    }
  };

  const createPaymentSession = async (ref: string) => {
    try {
      const payBody: NewPaymentBody = {
        ref: ref,
        currency: 'USD',
      };
      const sessionResponse: PayOrderResponse = await newpaymentSession(
        payBody
      );
      if (sessionResponse.success) {
        window.location.href = sessionResponse.message;
        return;
      } else {
        openSnackBar(sessionResponse.message);
      }
    } catch (err) {
      openSnackBar(getAPIErrorMessage(err as any));
    } finally {
      hideLoader();
    }
  };

  const getImgSrc = (productName: string) => {
    switch (productName) {
      case 'speechwriting':
        return speechwritingImg;
      case 'speechwriting+public_speaking':
        return drawingSpeaker;
      default:
        return speechwritingImg;
    }
  };

  return (
    <div
      className={`bg-cosmicLatte-primary w-full text-forest-primary min-h-4/5 flex flex-col space-between items-center overflow-x-hidden absolute top-0 z-10 min-h-full`}
    >
      <CampaignItem
        regularComponent={<></>}
        renderCampaignComponent={(discountPercentage, couponCode) => (
          <div className='w-full px-1 md:px-2 py-1 md:py-2 text-center bg-copper-primary text-white mb-2 cursor-pointer underline-offset-4'>
            {`GET ${discountPercentage}% OFF | USE CODE: `}
            <u>{couponCode}</u>
            {` | OFFER ENDS SOON!`}
          </div>
        )}
      />
      <div className='flex flex-row my-2 lg:my-4 self-start justify-between cursor-pointer px-4 lg:px-11 w-full'>
        <div
          className='flex flex-row grow align-center items-center'
          onClick={() => navigate(-1)}
        >
          <img alt='Go to dashboard' width={32} src={arrowLeftSvg} />
          <div className='ml-2'>Back</div>
        </div>

        <div className='flex flex-row self-end'></div>
      </div>

      <Loader isShowing={isShowing} hide={hideLoader} allowHide={false} />
      <SnackbarComponent
        isActive={isActive}
        message={message}
        type={type}
        handleClose={handleClose}
      />

      {/* <Title
        className='text-forest-primary font-recife font-normal'
        h={1}
        text='Sed haec quis possit intrepidus aestimare'
      /> */}

      {/* <Typography variant='primary' type='body-600'>
        Quam temere in vitiis, legem sancimus haerenti. Qui ipsorum lingua
        Celtae, nostra Galli appellan fabio vel iudice vincam, sunt.
      </Typography> */}

      <Container className='flex-1 w-full grid-flow-col content-between justify-center mt-4 auto-rows-fr lg:px-0'>
        <div className='self-center col-span-4 md:col-span-6 lg:col-span-12 w-full flex flex-row flex-wrap justify-center pb-6'>
          {products.map(
            (
              product: ToastBuilderProduct,
              index: number,
              array: ToastBuilderProduct[]
            ) => {
              return (
                <div
                  className={`w-full max-w-[450px] lg:min-w-[450px] px-2 mt-3`}
                  key={product.id}
                >
                  <div
                    className={`border-forest-primary border flex flex-col justify-between px-6 py-6 items-center h-full max-w-3xl mx-auto min-h-[617px] relative rounded-2xl`}
                  >
                    <div className='flex flex-col items-center w-full'>
                      <div className='flex flex-row w-full'>
                        <div className='flex-auto w-[80%]'>
                          <h3 className='font-recife font-normal leading-8 w-full text-left text-2xl'>
                            {product.paywall_title}
                          </h3>
                          <CampaignItem
                            regularComponent={
                              <>
                                <h1 className='font-recife inline font-normal text-3xl text-left w-full'>
                                  {`$${product.price}`}
                                </h1>
                              </>
                            }
                            renderCampaignComponent={(discountPercentage) => (
                              <>
                                <h1 className='inline font-recife font-normal text-3xl text-left w-full'>
                                  {`$${(
                                    (product.price *
                                      (100 - discountPercentage)) /
                                    100
                                  ).toFixed(2)}`}
                                </h1>
                                <h1 className='ml-2 inline font-recife font-normal text-xl text-left w-full line-through text-copper-primary'>
                                  {`${product.price}`}
                                </h1>
                                <div className='flex flex-row justify-start items-center'>
                                  <div
                                    className={
                                      'bg-copper-primary text-white font-grotesk text-sm px-3 py-1 rounded-md'
                                    }
                                  >
                                    {`Save ${discountPercentage}%`}
                                  </div>
                                  <Typography className='ml-2'>
                                    Offer Ends Soon!
                                  </Typography>
                                </div>
                              </>
                            )}
                          />
                          <Typography
                            className='text-forest-300 text-sm font-recife'
                            variant='functional-low'
                          >
                            one-time fee
                          </Typography>
                        </div>
                        <div className='flex-auto text-right'>
                          <img
                            className='inline-block'
                            src={getImgSrc(product.name)}
                            alt='plan icon'
                          />
                        </div>
                      </div>
                      <div className='w-full flex flex-col pt-2'>
                        <p className='self-center font-recife text-sm text-forest-primary mb-2'>
                          {product.paywall_description || product.description}
                        </p>
                        <div
                          className={`grid grid-cols-1 w-full max-w-xl self-center`}
                        >
                          {product.paywall_attributes.map((feature, index) => {
                            return (
                              <PayPlansList
                                key={index}
                                title={feature}
                                className='text-sm'
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <Button
                      className='w-full'
                      onClick={() => proceedToPayWall(products[0])}
                    >
                      Select
                    </Button>
                    {/* <button
                      type='button'
                      onClick={() => {
                        //silence is golden
                      }}
                      className='text-forest-primary font-base underline underline-offset-4 decoration-dotted'
                    >
                      Learn more
                    </button> */}
                  </div>
                </div>
              );
            }
          )}
        </div>
      </Container>

      <div className='flex flex-col px-24 max-w-[760px] my-6'>
        <Review
          stars={5}
          review='I’m not much of a writer and I had no idea what to say in my Maid of Honor speech… The Toast Builder 100% saved me. It took all my thoughts/stories and generated a fantastic draft of my speech.'
          author='LAUREN F'
          authorTitle='MAID OF HONOR'
          // date='SEPT 2022'
          className='mb-8'
        />
      </div>
    </div>
  );
};

export default PaywallToastBuilder;
