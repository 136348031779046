import { useState } from 'react';

import {
  CeremonySpeaker,
  SpeakingEventsResponse,
  getSpeakingEvents,
} from '../api/output';

export type AllowedEvent = {
  rehearsal_dinner: CeremonySpeaker | null;
  engagement_party: CeremonySpeaker | null;
  reception: CeremonySpeaker | null;
  guest_standalone_toast: CeremonySpeaker | null;
};

const useSpeakingEvents = () => {
  const [allowedEvents, setAllowedEvents] = useState<AllowedEvent>({
    rehearsal_dinner: null,
    engagement_party: null,
    reception: null,
    guest_standalone_toast: null,
  });

  const getSpeakingEventsForGuest = async (
    ceremonyId: string | number,
    guestId?: string | number
  ) => {
    try {
      const eventsResponse: SpeakingEventsResponse = await getSpeakingEvents(
        ceremonyId,
        guestId
      );
      if (eventsResponse.success) {
        eventsResponse.data.forEach((event) => {
          setAllowedEvents({ ...allowedEvents, [event.event]: event });
        });
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  return {
    getSpeakingEventsForGuest,
    allowedEvents,
  };
};

export default useSpeakingEvents;
