import * as Icon from 'react-icons/fa';

type CommandButton = {
  icon: React.ReactNode;
  command: string;
  type?: undefined;
  commands?: undefined;
  dropdownOnMobile?: boolean;
  maxCommandsOnMobile?: number;
}

type GroupedCommandButton = {
  type: string;
  commands: CommandButton[];
  dropdownOnMobile?: boolean;
  maxCommandsOnMobile?: number;
  icon?: undefined;
  command?: undefined;
}

export type Command = GroupedCommandButton | CommandButton;

export const COMMANDS: Command[] = [
  {
    type: 'group',
    dropdownOnMobile: false,
    maxCommandsOnMobile: 2,
    commands: [
      { command: 'bold', icon: <Icon.FaBold /> },
      { command: 'italic', icon: <Icon.FaItalic /> },
      { command: 'underline', icon: <Icon.FaUnderline /> },
      { command: 'strikeThrough', icon: <Icon.FaStrikethrough /> },
    ],
  },
  {
    type: 'group',
    dropdownOnMobile: true,
    commands: [
      { command: 'justifyLeft', icon: <Icon.FaAlignLeft /> },
      { command: 'justifyCenter', icon: <Icon.FaAlignCenter /> },
      { command: 'justifyRight', icon: <Icon.FaAlignRight /> },
      { command: 'justifyFull', icon: <Icon.FaAlignJustify /> },
    ],
  },
  {
    type: 'group',
    dropdownOnMobile: true,
    commands: [
      { command: 'insertOrderedList', icon: <Icon.FaListOl /> },
      { command: 'insertUnorderedList', icon: <Icon.FaListUl /> },
    ],
  },
  {
    type: 'group',
    commands: [
      { command: 'indent', icon: <Icon.FaIndent /> },
      { command: 'outdent', icon: <Icon.FaOutdent /> },
    ],
  },
  {
    type: 'group',
    maxCommandsOnMobile: -1,
    commands: [
      { command: 'removeFormat', icon: <Icon.FaRemoveFormat /> },
    ],
  },
];
