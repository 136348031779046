/* eslint-disable -- TODO: fix eslint errors */

import './index.scss';
import logo from '../../assets/images/icons/Provenance_Logo_Light.svg';
import fb from '../../assets/images/icons/facebook.svg';
import insta from '../../assets/images/icons/instagram.svg';
import linkedin from '../../assets/images/icons/linkedin.svg';
import pinterest from '../../assets/images/icons/pinterest.svg';
import tiktok from '../../assets/images/icons/tiktok.svg';
import Button from '../v2/Button/Button';
import { Typography } from '../v2/Typography/Typography';

const Footer = () => {
  return (
    <div
    >
      <div className='flex md:flex-row flex-col justify-center md:justify-between items-center removeElevioOnMobile'>
        <div>
          <Button
            className='mr-4'
            variant='text'
            onClick={() => {
              window.open('https://www.provenance.co/privacy-policy', '_blank');
            }}
          >
            Privacy policy
          </Button>
          <Button
            className='mr-4'
            variant='text'
            onClick={() => {
              window.open(
                'https://www.provenance.co/terms-of-service',
                '_blank'
              );
            }}
          >
            TOS
          </Button>
          <Button
            className='mr-4'
            variant='text'
            onClick={() => {
              window.open('https://provenance.elevio.help/en', '_blank');
            }}
          >
            Help Center
          </Button>
          <Button
            className='mr-4'
            variant='text'
            onClick={() => {
              // @ts-ignore
              window?._elev?.open();
            }}
            data-elevio-module='3'
          >
            Contact Us
          </Button>
        </div>

        <div>
          <div className='flex flex-row'>
            <a
              href='https://www.linkedin.com/company/ourprovenance/'
              target={'_blank'}
            >
              <img src={linkedin} alt={'linkedin'} className={'mr-5'} />
            </a>
            <a href='https://tiktok.com/@ourprovenance' target={'_blank'}>
              <img src={tiktok} alt={'insta'} className={'mr-5'} />
            </a>
            <a href='https://pinterest.com/ourprovenance' target={'_blank'}>
              <img src={pinterest} alt={'insta'} className={'mr-5'} />
            </a>
            <a href='https://www.facebook.com/OurProvenance/' target={'_blank'}>
              <img src={fb} alt={'fb'} className={'mr-5'} />
            </a>
            <a
              href='https://www.instagram.com/ourprovenance/'
              target={'_blank'}
            >
              <img src={insta} alt={'insta'} className={'mr-5'} />
            </a>
          </div>
        </div>
        <div>
          <Typography
            variant='functional-low'
            type='body-200'
            className='text-forest-300'
          >
            <>All Rights Reserved © Provenance {new Date().getFullYear()}</>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Footer;
