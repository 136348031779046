/* eslint-disable */
import { InfoOutlined } from '@mui/icons-material';
import { Typography } from '../../../components/v2/Typography/Typography';

type InfoBoxProps = {
  text: string;
  secondaryText?: string;
  renderFooter?: () => JSX.Element;
  className?: string;
};

export const InfoBox = (props: InfoBoxProps) => {
  const { text, secondaryText, renderFooter, className } = props;
  return (
    <div
      className={`flex flex-row gap-2 p-2 mb-4 w-fit items-start rounded-lg bg-cosmicLatte-dark ${className}`}
    >
      <InfoOutlined fontSize='small' />
      <div className='flex flex-col'>
        <Typography variant='functional-low' type='body-200'>
          {text}
        </Typography>
        {secondaryText && (
          <Typography className='mt-6' variant='functional-low' type='body-200'>
            {secondaryText}
          </Typography>
        )}
        {renderFooter && renderFooter()}
      </div>
    </div>
  );
};
