/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Ceremony, Member, MemberType } from '../api/ceremony';
import { FriendWithQuestions } from '../api/friends';
import {
  getTips,
  outputModules,
  TipsIdeas,
  TipsIdeasType,
  TipsResponse,
} from '../api/output';
import { Reader } from '../api/readers';
import { getAPIErrorMessage } from '../helpers/helper';
import { mapOutputModuleToQuestionModule } from '../helpers/modulesHelper';
import { Module } from '../hooks/useActiveModule';
import useCeremony from '../hooks/useCeremony';
import useFriends from '../hooks/useFriends';
import useLoader from '../hooks/useLoader';
import useReaders from '../hooks/useReaders';
import { useSnackbar } from '../hooks/useSnackbar';

type OutputContextType = {
  readOnly: boolean;
  tipsAndIdeas: TipsIdeas[];
  readers: Reader[];
  getTipsByType: (module: outputModules) => TipsIdeas[];
  getIdeasByType: (module: outputModules) => TipsIdeas[];
  activeModule: outputModules;
  secondActiveModule: Module;
  setActiveModule: (value: outputModules) => void;
  setSecondActiveModule: (value: Module) => void;
  fetchCeremony: (ceremonyId: string) => Promise<void>;
  ceremony?: Ceremony;
  couple1?: Member;
  couple2?: Member;
  officiant?: Member;
  currentUser?: Member;
  weddingPlanner?: Member;
  currentRole?: MemberType;
  friendsWithQuestions: FriendWithQuestions[];
  fetchFriendsWithQuestions: (ceremonyId: string) => void;
  saving: boolean;
  setSaving: (value: boolean) => void;
  saved: boolean;
  setSaved: (value: boolean) => void;
  wereChangesMade: boolean;
  setWereChangesMade: (value: boolean) => void;
};

export const OutputContext = React.createContext<OutputContextType>({
  readOnly: true,
  tipsAndIdeas: [],
  readers: [],
  getTipsByType: (module: outputModules) => [],
  getIdeasByType: (module: outputModules) => [],
  activeModule: outputModules.welcome,
  secondActiveModule: Module.DirectionalQuestions,
  setActiveModule: (outputModules) => {},
  setSecondActiveModule: (Module) => {},
  fetchCeremony: async () => {},
  ceremony: undefined,
  couple1: undefined,
  couple2: undefined,
  officiant: undefined,
  currentUser: undefined,
  weddingPlanner: undefined,
  currentRole: undefined,
  friendsWithQuestions: [],
  fetchFriendsWithQuestions: () => {},
  saving: false,
  setSaving: () => {},
  saved: false,
  setSaved: () => {},
  wereChangesMade: false,
  setWereChangesMade: () => {},
});

export const OutputProvider = ({ children }: { children: React.ReactNode }) => {
  const { showLoader, hideLoader } = useLoader();
  const { openSnackBar } = useSnackbar();

  const [activeModule, setModule] = useState<outputModules>(
    outputModules.welcome
  );
  const [secondActiveModule, setSecondModule] = useState<Module>(
    Module.DirectionalQuestions
  );
  const [saving, setSavingFn] = useState(false);
  const [saved, setSavedFn] = useState(true);
  const [wereChangesMade, setWereChangesMade] = useState(false);

  const {
    ceremony,
    couple1,
    couple2,
    officiant,
    weddingPlanner,
    currentRole,
    currentUser,
    fetchCeremonyById,
  } = useCeremony();

  const { friendsWithQuestions, fetchFriendsWithQuestions } = useFriends();
  const { readers, fetchReaders } = useReaders();

  useEffect(() => {
    if (ceremony) {
      void getTipsIdeas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ceremony]);

  useEffect(() => {
    if (currentRole === MemberType.weddingPlanner) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
  }, [currentRole]);

  useEffect(() => {
    if (ceremony) {
      void fetchReaders(ceremony.id.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ceremony]);

  const [tipsAndIdeas, setTipsAndIdeas] = useState<TipsIdeas[]>([]);
  const [readOnly, setReadOnly] = useState(true);

  const setActiveModule = (activeModule: outputModules) => {
    setModule(activeModule);
    const mappedModule = mapOutputModuleToQuestionModule(activeModule);
    if (mappedModule) {
      setSecondActiveModule(mappedModule);
    }
  };

  const setSecondActiveModule = (module: Module) => {
    setSecondModule(module);
  };

  const getTipsByType = (module: outputModules) => {
    const tips: TipsIdeas[] =
      tipsAndIdeas?.filter((s: TipsIdeas) => {
        return s.module === module && s.type === TipsIdeasType.tips;
      }) ?? [];

    return tips;
  };

  const getIdeasByType = (module: outputModules) => {
    const ideas: TipsIdeas[] =
      tipsAndIdeas?.filter((s: TipsIdeas) => {
        return s.module === module && s.type === TipsIdeasType.ideas;
      }) ?? [];

    return ideas;
  };

  const fetchCeremony = async (ceremonyId: string) => {
    await fetchCeremonyById(ceremonyId);
  };

  const getTipsIdeas = useCallback(async () => {
    showLoader();
    try {
      const tipsResponse: TipsResponse = await getTips();
      if (tipsResponse.success) {
        hideLoader();
        setTipsAndIdeas(tipsResponse.data);
      } else {
        hideLoader();
        openSnackBar(tipsResponse.message, 5000, 'error');
      }
    } catch (err) {
      hideLoader();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      openSnackBar(getAPIErrorMessage(err as any), 5000, 'error');
    }
  }, [hideLoader, openSnackBar, showLoader]);

  const setSaving = (val: boolean) => {
    setSavingFn(val);
    setWereChangesMade(true);
  };

  const setSaved = (val: boolean) => {
    setSavedFn(val);
    setWereChangesMade(true);
  };

  const value = {
    readOnly,
    tipsAndIdeas,
    readers,
    getTipsByType,
    getIdeasByType,
    activeModule,
    setActiveModule,
    secondActiveModule,
    setSecondActiveModule,
    fetchCeremony,
    ceremony,
    couple1,
    couple2,
    officiant,
    currentUser,
    weddingPlanner,
    currentRole,
    friendsWithQuestions,
    fetchFriendsWithQuestions,
    saving,
    setSaving,
    saved,
    setSaved,
    wereChangesMade,
    setWereChangesMade,
  };
  // @ts-ignore
  return (
    <OutputContext.Provider value={value}>{children}</OutputContext.Provider>
  );
};

export const useOutput = () => {
  return useContext(OutputContext);
};
