import { useState } from 'react';

import CustomDropdown from '../../../components/forms/CustomDropdown';
import Button from '../../../components/v2/Button/Button';
import { Modal } from '../../../components/v2/Modal/Modal';
import { Typography } from '../../../components/v2/Typography/Typography';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useNPS } from '../../../provider/NPSProvider';

type ModalProps = {
  onContinueHigh: () => void;
  onContinueLow: () => void;
  threshold: number;
};

export const NPSModal = (props: ModalProps) => {
  const { loadNPS, npsValue, setNpsValue, setLoadNPS } = useNPS();
  const { onContinueHigh, onContinueLow, threshold } = props;
  const values = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [showError, setShowError] = useState(false);
  const { isMobile } = useWindowSize()

  const submitResponse = () => {
    if (npsValue === undefined) {
      setShowError(true);
      return;
    }

    setShowError(false);
    if (npsValue >= threshold) onContinueHigh();
    else onContinueLow();
  };

  const getContent = () => {
    return <div className='w-max mt-5'>
    {values.map((value, index) => {
      let className = 'm-1 rounded-lg w-10 h-10 p-0';
      !index ? (className += ' ml-0') : '';
      index == values.length - 1 ? (className += ' mr-0') : '';
      value == npsValue ? (className += ' selected') : '';
      return (
        <Button
          key={index}
          className={className}
          onClick={() => setNpsValue(value)}
          variant='secondary'
        >
          {value.toString()}
        </Button>
      );
    })}
    <div className='w-full mt-5 flex justify-between'>
      <Typography type='body-200' variant='functional-low'>
        Very unlikely
      </Typography>
      {showError ? (
        <Typography
          type='body-200'
          variant='functional-low'
          className='error-message'
        >
          Please select a number before submitting
        </Typography>
      ) : null}
      <Typography type='body-200' variant='functional-low'>
        Very likely
      </Typography>
    </div>
  </div>
  }

  const getMobileContent = () => { 
    return <div className='mt-5'>
    <CustomDropdown 
      options={values.map((value, index) => {
        const option = { label: value.toString(), value: value }
        if (index == 0) option.label += ' (Not likely)'
        if (index == values.length - 1) option.label += ' (Very likely)'
        return option
      })}
      values={values}
      onChange={(e: any) => setNpsValue(e.target.value)}
      error={showError}
      noSort = {true}
    />
    {showError ? (
      <Typography
        type='body-200'
        variant='functional-low'
        className='error-message'
      >
        Please select a number before submitting
      </Typography>
    ) : null}
  </div>
  }

  return (
    <Modal
      isOpen={loadNPS}
      onClose={() => setLoadNPS(false)}
      showCloseButton={true}
      title={''}
      maxWidth={'sm'}
    >
      <div className='self-center flex flex-col justify-center text-center '>
        <>
          <div className='text-copper-primary uppercase text-sm'>
            While your script is downloading...
          </div>
          <Typography type='display-100'>
            How likely are you to recommend Provenance to a friend?
          </Typography>
          { isMobile ? getMobileContent() : getContent() }
          <div
            className={`flex flex-col-reverse md:flex-row justify-center mt-8`}
          >
            <Button
              className='mt-2 md:mt-0 md:mr-2 md:w-auto w-full'
              onClick={() => setLoadNPS(false)}
              variant='secondary'
            >
              Skip
            </Button>
            <Button className=' md:w-auto w-full' onClick={submitResponse}>
              Submit
            </Button>
          </div>
        </>
      </div>
    </Modal>
  );
};
