import classNames from 'classnames';
import React from 'react';

import { Ceremony, Member, UpdateCeremonyVenueAndPlace } from '../../api/ceremony';
import { Typography } from '../v2/Typography/Typography';

import './EventSettingsModal.scss';
import { EventSettingsForm } from './components/EventSettingsForm';

import { Modal } from '../v2/Modal/Modal';

type EventSettingsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  members?: Member[];
  ceremony: Ceremony;
  partner?: Member;
  currentUser?: Member;
  weddingPlanner?: Member;
  onSaveChanges: (value: UpdateCeremonyVenueAndPlace) => Promise<void> | void;
};

export const EventSettingsModal = (props: EventSettingsModalProps) => {
  const { isOpen, onClose, onSaveChanges, ceremony, currentUser } = props;

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className={classNames('EventSettingsModal')}>
          <Typography type='display-100'>Event Settings</Typography>

          {currentUser && (
            <EventSettingsForm
              onCancel={onClose}
              ceremony={ceremony}
              currentUser={currentUser}
              onSaveChanges={onSaveChanges}
            />
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
};
