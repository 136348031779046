/* eslint-disable -- TODO: fix eslint errors */

import { Alert, Icon, IconButton, Snackbar } from '@mui/material';
import { useSnackbar } from '../../provider/snackbarProvider';

const SnackbarComponent = ({
  isActive,
  message,
  hideDuration,
  type,
  handleClose,
}: any) => {
  return (
    <Snackbar
      onClose={handleClose}
      open={isActive}
      autoHideDuration={hideDuration}
      message={message}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      itemType={'success'}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: type === 'success' ? '#EFEDE7' : '#EFEDE7',
          color: '#354740',
          fontWeight: 400,
          boxShadow:
            '0px 1px 8px rgba(53, 71, 64, 0.16), 0px 2px 3px rgba(53, 71, 64, 0.08)',
          border: '1px solid #9AA3A0',
        },
        '& .MuiAlert-icon': {
          color: '#354740',
        },
        '& .MuiSvgIcon-root': {
          color: '#354740',
        },
      }}
      action={[
        <IconButton
          key='close'
          aria-label='close'
          color='inherit'
          onClick={handleClose}
        >
          <Icon>close</Icon>
        </IconButton>,
      ]}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export const SnackbarComponentNew = () => {
  const { isActive, message, hideDuration, type, handleClose } = useSnackbar();
  return (
    <Snackbar
      onClose={handleClose}
      open={isActive}
      autoHideDuration={hideDuration}
      message={message}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      itemType={'success'}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: type === 'success' ? '#EFEDE7' : '#EFEDE7',
          color: '#354740',
          fontWeight: 400,
          boxShadow:
            '0px 1px 8px rgba(53, 71, 64, 0.16), 0px 2px 3px rgba(53, 71, 64, 0.08)',
          border: '1px solid #9AA3A0',
        },
        '& .MuiAlert-icon': {
          color: '#354740',
        },
        '& .MuiSvgIcon-root': {
          color: '#354740',
        },
      }}
      action={[
        <IconButton
          key='close'
          aria-label='close'
          color='inherit'
          onClick={handleClose}
        >
          <Icon>close</Icon>
        </IconButton>,
      ]}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
