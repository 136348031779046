/* eslint-disable */
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { ModuleCollapseButtonProps } from './typings';
import './collapseButton.scss';

export const CollapseButton = ({
  collapsed,
  text,
  className,
  ...buttonProps
}: ModuleCollapseButtonProps) => {
  const buttonText = collapsed ? `Show ${text}` : `Hide ${text}`;

  return (
    <button
      {...buttonProps}
      type='button'
      className={`CollapseButton-root ${className}`}
    >
      {collapsed && <ArrowDownward className='w-4 h-4' />}
      {!collapsed && <ArrowUpward className='w-4 h-4' />}
      <span className='underline underline-offset-4 decoration-dashed'>
        {buttonText}
      </span>
    </button>
  );
};
