import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import checkIcon from '../../../assets/images/icons/Checkmark-green.svg'
import closeIcon from '../../../assets/images/icons/close-x-new.svg';
import signupWelcomeImg from '../../../assets/images/image/signupWelcome.png';
import { Title } from '../../../components/titles';
import InfoIcon from '../../../components/v2/InfoIcon/InfoIcon';
import { Typography } from '../../../components/v2/Typography/Typography';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useOnboarding } from '../../../provider/onboardingProvider';

import BundleIncludes, { IncludeOptions } from './BundleIncludes';
import GiftcardImg from './GiftCardImg';
import './index.scss';
import TestimonialDesktopImage from './images/testimonial-desktop.png';



type GiftCardLayoutProps = {
  content: React.ReactNode;
  hideImageOnMobile?: boolean;
  bundleIncludes?: IncludeOptions[]
};

const GiftCardLayout = (props: GiftCardLayoutProps) => {
  const { content, bundleIncludes, hideImageOnMobile = false } = props;

  const size = useWindowSize();

  const navigate = useNavigate();

  return (
    <div
      className={`absolute bg-cosmicLatte-primary top-0 bottom-0 left-0 right-0 w-screen min-h-screen h-screen md:h-screen z-50 
      ${size.width < 1024 ? 'pb-52' : ''}`}
    >
      <div className='w-full flex flex-col lg:flex-row-reverse mx-auto'>
        <div className='h-full bg-cosmicLatte-primary absolute left-0 top-0 bottom-0 w-[50%] hidden z-20'></div>
        <div
          className={classNames(
            'relative lg:flex-1 flex flex-col items-center justify-center bg-cosmicLatte-primary lg:bg-white', 
            {
                'hidden lg:block': hideImageOnMobile && size.width < 1024
            }
          )}
        >
          <div>
            <GiftcardImg preview={true} classNames='' size='lg'/>
            {
                bundleIncludes && bundleIncludes.length ? (
                    <div className='w-full'>
                        <Typography 
                            variant='primary'
                            type='body-400'
                            className='mt-5'
                        >
                                Premium Bundle Includes:
                        </Typography>
                        
                        <BundleIncludes includes={bundleIncludes}/> 
                        <img src={TestimonialDesktopImage} className='mt-5 max-w-[428px]' />
                    </div>
                ) : null
            }
          </div>


          <button
            className='cursor-pointer border-0 absolute right-7 top-7'
            onClick={() => window.location.href = "https://www.provenance.co/gifting"}
          >
            <img alt='close' src={closeIcon} />
          </button>
        </div>
        <div className='lg:flex-1 bg-cosmicLatte-primary z-40 min-h-[100vh] pb-20 lg:pb-2'>
          {content}
        </div>
      </div>
    </div>
  );
};

export default GiftCardLayout;
