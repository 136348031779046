import { GenericResponse } from './ceremony';
import { Speaker } from './friends';
import { _request } from './request';

export const addSpeaker = async (ceremonyId: string, speaker: Speaker) => {
  const body = { ceremonyId, speaker };
  const responseBody = await _request<GenericResponse>({
    url: 'speaker/add-speaker',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const addSpeakers = async (ceremonyId: string, speakers: Speaker[]) => {
  const body = { ceremonyId, speakers };
  const responseBody = await _request<GenericResponse>({
    url: 'speaker/add-speakers',
    method: 'POST',
    body,
  });
  return responseBody;
}

export const editSpeaker = async (ceremonyId: string, speaker: Speaker) => {
  const body = { ceremonyId, speaker };
  const responseBody = await _request<GenericResponse>({
    url: 'speaker/edit-speaker',
    method: 'POST',
    body,
  });
  return responseBody;
};
