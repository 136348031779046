import Button from '../../../components/v2/Button/Button';
import { Modal } from '../../../components/v2/Modal/Modal';
import { Typography } from '../../../components/v2/Typography/Typography';
import renderText from '../../../helpers/renderText';

type TourModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onStartTourClick: () => void;
  text: string;
  title: string;
};

const TourModal = (props: TourModalProps) => {
  const { isOpen, onClose, onStartTourClick, title, text } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <>
        <Typography type={'display-50'} className='text-center mb-3'>
          {title || 'Welcome to Provenance'}
        </Typography>
        <img />
        <Typography type={'body-400'} className='text-center'>
          {text}
        </Typography>
        <div className={`flex flex-row justify-between mt-3`}>
          <Button variant='text' onClick={onClose}>
            Skip for now
          </Button>
          <Button onClick={onStartTourClick}>Start Tour</Button>
        </div>
      </>
    </Modal>
  );
};

export default TourModal;
