import classNames from 'classnames';

import loader from '../../../assets/images/icons/spinner.png';

import './button.scss';

import { Typography } from '../Typography/Typography';

type ButtonProps = {
  children: React.ReactElement | string;
  className?: string;
  onClick?: () => void | Promise<void>;
  variant?: 'primary' | 'secondary' | 'text' | 'select' | 'deselect';
  size?: '100' | '150' | '200' | '400';
  disabled?: boolean;
  showLoader?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  typographyVariant?: 'primary' | 'functional-high' | 'functional-low';
  eventClick?: (event: React.MouseEvent<HTMLElement>) => void | Promise<void>;
  fullWidth?: boolean;
  leftImgSrc?: string;
  rightImgSrc?: string;
  renderRightIcon?: () => React.ReactElement;
  renderLeftIcon?: () => React.ReactElement;
};

const Button = (props: ButtonProps) => {
  const {
    children,
    className,
    onClick,
    variant = 'primary',
    size = '200',
    disabled = false,
    showLoader = false,
    type = 'button',
    typographyVariant = 'functional-low',
    eventClick,
    fullWidth,
    leftImgSrc,
    rightImgSrc,
    renderRightIcon,
    renderLeftIcon,
  } = props;

  const classes = classNames(
    {
      typography: true,
      [`button-variant-${variant}`]: variant,
      [`button-size-${size}`]: size,
      [`w-full`]: fullWidth,
    },
    className
  );

  const getTypographyType = (size: string) => {
    let result: 'body-100' | 'body-200' | 'body-400' | 'body-600' = 'body-200';
    if (size === '200') {
      result = 'body-400';
    } else if (size === '400') {
      result = 'body-600';
    }
    return result;
  };

  return (
    <button
      className={`${classes}  hover:cursor-pointer`}
      onClick={eventClick ? eventClick : onClick}
      disabled={disabled}
      type={type}
    >
      <span className='flex flex-row items-center justify-center text-center w-full'>
        {showLoader && (
          <span className='inline-block translate-y-[6px] mr-2'>
            <img
              src={loader}
              alt='loader'
              className=' animate-spin max-w-[24px]'
              height={24}
              width={24}
            />
          </span>
        )}
        {renderLeftIcon && <div className='mr-2'>{renderLeftIcon()}</div>}
        {leftImgSrc && (
          <img
            src={leftImgSrc}
            alt='left-image'
            className='inline mr-2 max-w-[24px]'
            height={24}
            width={24}
          />
        )}
        <Typography
          className='inline'
          variant={typographyVariant}
          type={getTypographyType(size)}
        >
          {children}
        </Typography>
        {rightImgSrc && (
          <img
            src={rightImgSrc}
            alt='left-image'
            className='inline ml-2 max-w-[24px]'
            height={24}
            width={24}
          />
        )}
        {renderRightIcon && <div className='ml-2'>{renderRightIcon()}</div>}
      </span>
    </button>
  );
};

export default Button;
