import {
  generateAuthToken,
  generateExternalApiKey,
  RedeemerApps,
} from '../api/auth';

export const getAppUrlByType = (appType: RedeemerApps) => {
  switch (appType) {
    case RedeemerApps.REFLECTIONS:
      return process.env.REACT_APP_REFLECTIONS_URL || '';
    case RedeemerApps.BIRTHS:
      return process.env.REACT_APP_BIRTHS_URL || '';
    case RedeemerApps.THANKYOU:
      return process.env.REACT_APP_THANKYOUNOTES_URL || '';
    default:
      return '';
  }
};

export const getAppTitleByType = (appType: RedeemerApps) => {
  switch (appType) {
    case RedeemerApps.REFLECTIONS:
      return 'Community Reflections';
    case RedeemerApps.BIRTHS:
      return 'Baby Letter Builder';
    case RedeemerApps.THANKYOU:
      return 'Thank You Notes';
    default:
      return '';
  }
};

export const navigateToApp = async (
  ceremonyId: number,
  redeemerApp: RedeemerApps
) => {
  const tokenData = await generateAuthToken({
    redeemerApp: redeemerApp,
  });

  const appApiKey = await generateExternalApiKey({
    ceremonyId: ceremonyId,
    redeemerApp: redeemerApp,
  });

  //navigate to window
  window.location.href = `${getAppUrlByType(redeemerApp)}/signin?token=${
    tokenData.token
  }&externalApiKey=${appApiKey.token}&externalEventId=${ceremonyId}`;
};
