import { Member } from '../../../api/ceremony';
import { Declaration } from '../../../api/declaration';
import arrowSvg from '../../../assets/images/icons/arrow-down-copper.svg'; 
import { AddToSpeechButton } from '../../../components/AddButton/AddToSpeechButton';
import { replacePlaceholders } from '../../../helpers/placeholderHelper';

type Props = {
  declaration: Declaration;
  onUseDeclarationClick?: (declarationId: number) => void;
  insertText?: (text: string) => void;
  expanded: boolean;
  setExpanded: (declarationId: number) => void;
  couple1: Member;
  couple2: Member;
};

export const DeclarationItem = (props: Props) => {
  const {
    declaration,
    onUseDeclarationClick,
    expanded,
    setExpanded,
    insertText,
    couple1,
    couple2,
  } = props;

  return (
    <div>
      <div
        className='w-full bg-white py-5 mb-4 border-b border-forest-100 cursor-pointer'
        onClick={() => setExpanded(declaration.id)}
      >
        <div className='relative'>
        <span className='font-nanum text-forest-500 text-2xl font-bold'>{declaration.name}</span>

<div className={`absolute right-0 top-0 mt-4 mr-4 ${expanded ? 'rotate-180' : '' } `}>
  <img

    src={arrowSvg}
    alt='arrow'
  />
</div>
        </div>

        {expanded && (
          <div className='relative'>
            <div className=' my-4 border border-t-0 border-forest-primary' />
            <div className='flex flex-col'>
              <div
                dangerouslySetInnerHTML={{
                  __html: replacePlaceholders(
                    declaration.text,
                    couple1.preferred_name || couple1.legal_name,
                    couple2.preferred_name || couple2.legal_name
                  ),
                }}
              />
              {onUseDeclarationClick && (
                <div
                  className='mt-5 ml-auto py-4 h-15 w-52 flex justify-center items-center bg-forest-primary text-white cursor-pointer'
                  onClick={() => onUseDeclarationClick(declaration.id)}
                >
                  USE DECLARATION
                </div>
              )}

              {insertText && (
                <div className='mt-5 ml-auto py-4'>
                  <AddToSpeechButton
                    onClick={() =>
                      insertText(
                        replacePlaceholders(
                          declaration.text,
                          couple1.preferred_name || couple1.legal_name,
                          couple2.preferred_name || couple2.legal_name
                        )
                      )
                    }
                    reverseColors={true}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
