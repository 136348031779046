import React, { useState } from 'react';

import { GuestSpeakerCard, GuestSpeakerCardProps } from '../..';
import { RemoveGuestSpeakerModal } from '../../../pages/Ceremony/CoupleOfficiantDashboard/RemoveGuestSpeakerModal';

type Props = GuestSpeakerCardProps & {
  eventName: string;
  draggable?: boolean;
  removeSpeakerHandler?: () => Promise<void>;
  viewToastHandler?: () => void;
};

export const SpeakerCard = (props: Props) => {
  const { fullName, email, eventName, removeSpeakerHandler } =
    props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <React.Fragment>
      <GuestSpeakerCard
        {...props}
        onRemoveClick={() => {
          setIsOpen(true);
        }}
      />
      <RemoveGuestSpeakerModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        {...{ fullName: fullName, eventName, email }}
        onDeleteClick={() => {
          setIsOpen(false);
          if (typeof removeSpeakerHandler === 'function')
            void removeSpeakerHandler();
        }}
      />
    </React.Fragment>
  );
};
