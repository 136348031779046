type Props = {
  text: string;
  onClick: () => void;
  selected: boolean;
  disabled?: boolean;
};
export const ModalRoleItem = (props: Props) => {
  const { text, onClick, selected, disabled } = props;
  return (
    <div
      onClick={disabled ? () => {} : onClick}
      className={`${
        disabled ? 'cursor-not-allowed line-through ' : 'cursor-pointer'
      } border ${
        selected ? 'bg-forest-primary text-white' : ''
      } border-forest-100 flex justify-center items-center py-4 mt-4`}
    >
      {text}
    </div>
  );
};
