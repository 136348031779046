/* eslint-disable -- TODO: fix eslint errors */

export function getApiToken() {
  try {
    return JSON.parse(localStorage.getItem('PROVENANCE_TOKEN') ?? '');
  } catch (err) {
    localStorage.clear();
  }
}

export function capitalizeFirstLetter(string: string) {
  if (typeof string === 'undefined') return string;

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeFirstLetterOfEachWord(string: string) {
  if (typeof string === 'undefined') return string;

  return string
    .split(' ')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ');
}

//Example Greg S
export function formatFormalName(name: string | undefined) {
  if (typeof name === 'undefined') return name;

  let nameParts = name?.split(' ');
  if (nameParts.length > 1) return nameParts[0] + ' ' + nameParts[1][0];

  return name;
}

export function getFutureDate(days: number) {
  var date = new Date();
  date.setDate(date.getDate() + days);
  return date;
}

export function getAPIErrorMessage(
  err: any,
  sendCustom = true,
  customMessage = ''
) {
  if (customMessage) {
    return customMessage;
  }
  let message = 'Something went wrong. Please contact support';
  if (sendCustom) {
    if (err?.errors?.length > 0) {
      message = '';
      err.errors.forEach((error: any) => {
        message += error?.msg + ' ';
      });
    } else if (err.message) {
      message = err.message;
    }
  }

  return message;
}

export function getVenueName(
  venue: string,
  city?: string,
  state?: string,
  wedding_place?: string
) {
  let message = '';
  
  if (city || state || wedding_place) {
    if (city) message = city + (state || wedding_place ? ', ' : '');
    if (state) message += state + (wedding_place ? ', ' : '');
    if (wedding_place) message += wedding_place;

    //overwrite if venue is not empty
    //This is only returning the country - I'm removing it for now
    if (venue)
      message = venue + ', ' + (city || state || wedding_place ? '' : '');

    return message;
  } else {
    return venue;
  }
}

export function getValueById(id: string, objectList: any[]) {
  return objectList.find((x) => x.id.toString() === id);
}

export function onlyUnique(value: string, index: number, self: any) {
  return self.indexOf(value) === index;
}

export function getCookie(name: string) {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(';');

  // Loop through the array elements
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split('=');

    /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
    if (name == cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
}

export function setCookie(name: string, value: string, ttl?: number) {
  // Encode value in order to escape semicolons, commas, and whitespace
  var cookie = name + '=' + encodeURIComponent(value) + ';path=/';

  if (ttl) cookie += '; max-age=' + ttl * 24 * 60 * 60; // expires after ttl days

  document.cookie = cookie;
}

export function getAPIExceptionMessage(
  err: Error | Record<string, unknown> | unknown,
  defaultMessage = 'Something went wrong'
) {
  if (err instanceof Error) return defaultMessage;
  const isFailure = (err as Record<string, unknown>)?.success === false;
  const errorMessage = (err as Record<string, unknown>)?.message as
    | string
    | undefined;
  return isFailure ? errorMessage || defaultMessage : defaultMessage;
}

export function delay(seconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}

export function numbersToWords (number: number) {
  const words = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
  return words[number];
}

export const queryParamsBuilder = (params: Record<any, any>) => {
  const esc = encodeURIComponent;
  return Object.keys(params)
    .map((k) => esc(k) + '=' + esc(params[k]))
    .join('&');
}
