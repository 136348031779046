/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useEffect, useState } from 'react';

type BuilderCTAContextType = {
  status: 'saving' | 'saved' | 'unsaved';
  setStatus: (status: 'saving' | 'saved' | 'unsaved') => void;
};

export const BuilderCTAContext = React.createContext<BuilderCTAContextType>({
  status: 'saved',
  setStatus: () => {},
});

export const BuilderCTAProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [status, setStatus] = useState<'saving' | 'saved' | 'unsaved'>('saved');

  const value = {
    status,
    setStatus,
  };
  // @ts-ignore
  return (
    <BuilderCTAContext.Provider value={value}>
      {children}
    </BuilderCTAContext.Provider>
  );
};

export const useBuilderCTA = () => {
  return useContext(BuilderCTAContext);
};
