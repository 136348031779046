/* eslint-disable -- TODO: fix eslint errors */

import { useState } from 'react';
import { Member } from '../api/ceremony';
import { getVowsForMember, VowsResponse } from '../api/output';
import { getTextDurationInMinutes } from '../helpers/textHelper';
import { getAnswerFromOptionsQuestion } from '../helpers/questionsHelper';

const useVows = () => {
  const [vowsCouple1, setVowsCouple1] = useState('');
  const [vowsCouple2, setVowsCouple2] = useState('');
  const [vowsTypeCouple1, setVowsTypeCouple1] = useState('');
  const [vowsTypeCouple2, setVowsTypeCouple2] = useState('');
  const [vowsCouple1Duration, setVowsCouple1Duration] = useState(0);
  const [vowsCouple2Duration, setVowsCouple2Duration] = useState(0);
  const [vowsCouple1Tone, setVowsCouple1Tone] = useState<string | undefined>(
    ''
  );
  const [vowsCouple2Tone, setVowsCouple2Tone] = useState<string | undefined>(
    ''
  );
  const [vowsCouple1TargetLength, setVowsCouple1TargetLength] =
    useState<number>();
  const [vowsCouple2TargetLength, setVowsCouple2TargetLength] =
    useState<number>();

  const getVows = async (
    ceremonyId: string,
    couple1: Member,
    couple2: Member
  ) => {
    if (ceremonyId) {
      try {
        if (couple1 && couple1?.id) {
          const response: VowsResponse = await getVowsForMember(
            ceremonyId,
            couple1.id.toString()
          );
          if (response.success) {
            setVowsCouple1(response.data.content);
            setVowsTypeCouple1(response.data.type);
            setVowsCouple1Duration(
              getTextDurationInMinutes(response.data.content)
            );
            setVowsCouple1Tone(
              getAnswerFromOptionsQuestion(response.data.tone)
            );
            const targetLengthAnswer = getAnswerFromOptionsQuestion(
              response.data.targetLength
            );
            const mins =
              targetLengthAnswer && !isNaN(parseInt(targetLengthAnswer[0]))
                ? parseInt(targetLengthAnswer[0])
                : 0;

            setVowsCouple1TargetLength(mins);
          }
        }
        if (couple2 && couple2?.id) {
          const response: VowsResponse = await getVowsForMember(
            ceremonyId,
            couple2.id.toString()
          );
          if (response.success) {
            setVowsCouple2(response.data.content);
            setVowsTypeCouple2(response.data.type);
            setVowsCouple2Duration(
              getTextDurationInMinutes(response.data.content)
            );
            setVowsCouple2Tone(
              getAnswerFromOptionsQuestion(response.data.tone)
            );
            const targetLengthAnswer = getAnswerFromOptionsQuestion(
              response.data.targetLength
            );
            const mins =
              targetLengthAnswer && !isNaN(parseInt(targetLengthAnswer[0]))
                ? parseInt(targetLengthAnswer[0])
                : 0;

            setVowsCouple2TargetLength(mins);
          }
        }
      } catch (err) {}
    }
  };
  return {
    getVows,
    vowsCouple1,
    vowsCouple2,
    vowsTypeCouple1,
    vowsTypeCouple2,
    vowsCouple1Duration,
    vowsCouple2Duration,
    vowsCouple1Tone,
    vowsCouple2Tone,
    vowsCouple1TargetLength,
    vowsCouple2TargetLength,
  };
};

export default useVows;
