import classNames from 'classnames';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChecklistItemDefinition } from '../../../../api/checklistItemDefinitions';
import Button from '../../../../components/v2/Button/Button';
import { Typography } from '../../../../components/v2/Typography/Typography';
import VideoModal from '../../../../components/v2/VideoModal/VideoModal';
import { getGroupChecklistItem } from '../../../../helpers/checklistHelpers/checklistItemHelper';
import { Module } from '../../../../hooks/useActiveModule';
import useUser from '../../../../hooks/useUser';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { useAuth } from '../../../../provider/authProvider';
import { useChecklist } from '../../../../provider/checklistProvider';
import { useDashboard } from '../../../../provider/dashboardProvider';
import { usePlanner } from '../../../../provider/plannerProvider';
import ChecklistGroup, {
  ChecklistGroups,
  GroupChecklistItem,
} from '../../CeremonyChecklist/v2/ChecklistGroup';

export const PlannerOnboarding = () => {
  const { user } = useAuth();
  const { setWPUserAsCurrentUser } = useDashboard();
  const { isMobile } = useWindowSize();
  const { userCeremonies } = useUser();

  const { fetchChecklistItems, checklistItems, saveWpMemberChecklistItem } =
    useChecklist();
  const { weddingPlannerStatus, fetchWeddingPlannerStatus } = usePlanner();
  const navigate = useNavigate();

  useEffect(() => {
    fetchWeddingPlannerStatus();
  }, []);

  useEffect(() => {
    if (user) setWPUserAsCurrentUser(user);

    if (user?.id) {
      fetchChecklistItems(null, true);
    }
  }, [user]);

  const checkAccess = () => {
    return weddingPlannerStatus?.hasActiveSubscription || false;
  };

  const getChecklistItemsByGroup = (
    checklistItems: ChecklistItemDefinition[],
    group: ChecklistGroups
  ) => {
    const items = checklistItems?.filter((item) => item.group === group);
    const result = [] as GroupChecklistItem[];
    items.map((item) => {
      const mapped = getGroupChecklistItem(
        item,
        navigate,
        checkAccess,
        saveWpMemberChecklistItem,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        userCeremonies
      );
      if (mapped) {
        result.push(mapped);
      }
    });
    return result;
  };

  return (
    <>
      <div className='PlannerDashboard PlannerDashboard-root'>
        <div className='PlannerDashboard-section flex flex-col gap-8'>
          <div className='PlannerDashboard-section__header'>
            <Typography type='display-400'>
              Your Onboarding Checklist
            </Typography>
          </div>

          <ChecklistGroup
            title='Start here!'
            checklistItems={getChecklistItemsByGroup(
              checklistItems,
              ChecklistGroups.WEDDING_PLANNER
            )}
            endTimelineInLastItem={true}
          />
        </div>
      </div>
      <VideoModal />
    </>
  );
};
