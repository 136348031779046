/* eslint-disable */
import classNames from 'classnames';

import { Collapse } from '@mui/material';
import { IoChevronDownOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { useEffect, useState } from 'react';

import { SelectOptionProps } from './QuestionSelect';

const CollapsibleSelectOption = ({
  label,
  description,
  useHtmlDescription,
  isSelected,
  onClick,
}: SelectOptionProps) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(true);
  }, [isSelected]);
  const classes =
    'p-6 flex flex-col gap-2 justify-start rounded-3xl select-none';
  const unselectedClasses = 'border border-forest-primary';
  const selectedClasses =
    'border-[3px] border-copper-primary text-copper-primary';
  return (
    <button
      type='button'
      onClick={() => {
        onClick?.();
        setExpanded((old) => !old);
      }}
      className={classNames(classes, {
        [unselectedClasses]: !isSelected,
        [selectedClasses]: isSelected,
      })}
    >
      <div className='flex flex-row items-center gap-2'>
        {expanded && isSelected ? (
          <IoChevronDownOutline />
        ) : (
          <IoChevronForwardOutline />
        )}
        <span className='text-lg underline underline-offset-2 decoration-dashed decoration-1'>
          {label}
        </span>
      </div>
      <Collapse in={expanded && isSelected} unmountOnExit>
      
        <div
          className='font-grotesk font-medium text-sm md:text-base text-left pt-6 mt-6 border-t border-copper-primary text-forest-500'
          dangerouslySetInnerHTML={
            useHtmlDescription ? { __html: description as string } : undefined
          }
          children={!useHtmlDescription ? description : null}
        />
      </Collapse>
    </button>
  );
};

export default CollapsibleSelectOption;
