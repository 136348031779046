import debounce from 'lodash.debounce';
import React, { useCallback, useContext, useState } from 'react';

import { saveAnswer } from '../../api/question';
import {
  Question,
  QuestionAnswer,
} from '../../pages/Modules/common/moduleQuestion/ModuleQuestion';
import { useCTA } from '../ctaProvider';

export type TextQuestionContextType = {
  text: string;
  setText: (value: string, isInitial?: boolean) => void;
};

export const TextQuestionContext = React.createContext<TextQuestionContextType>(
  {
    text: '',
    setText: () => {},
  }
);

export const TextQuestionProvider = ({
  children,
  question,
  ceremonyId,
  onSaved,
  saveAnswered = true,
}: {
  children: React.ReactNode;
  question: Question;
  ceremonyId: number;
  onSaved?: (updatedAnswer: QuestionAnswer) => void;
  saveAnswered?: boolean;
}) => {
  const [textValue, setTextValue] = useState('');
  const {
    handleQuestionSaved,
    handleQuestionSaving,
    handleQuestionUnsaved,
    handleQuestionAnswered,
    handleQuestionNotAnswered,
  } = useCTA();

  const debouncedSaveFunction = async (
    value: string,
    ceremonyId: number,
    questionId: number
  ) => {
    const answer: QuestionAnswer = {
      ceremony_id: ceremonyId,
      question_id: question.id,
      text_answer: value,
    };
    try {
      handleQuestionSaving(questionId);
      const saved = await saveAnswer(answer);
      if (saved) {
        handleQuestionSaved(questionId);
        onSaved && onSaved(saved.data);
        if (saveAnswered) {
          if (answer.text_answer) {
            handleQuestionAnswered(questionId);
          } else {
            handleQuestionNotAnswered(questionId);
          }
        }
      } else {
        handleQuestionUnsaved(questionId);
      }
    } catch (error) {
      handleQuestionUnsaved(questionId);
    }
  };

  const debouncedCall = useCallback(
    debounce((val: string, questionId: number) => {
      ceremonyId && void debouncedSaveFunction(val, ceremonyId, questionId);
    }, 2000),
    [ceremonyId, question]
  );

  const setText = (value: string, isInitial?: boolean) => {
    setTextValue(value);
    if (isInitial && value && saveAnswered) {
      handleQuestionAnswered(question.id);
    }
    if (!isInitial) {
      handleQuestionUnsaved(question.id);
      debouncedCall(value, question.id);
    }
  };

  const value = {
    text: textValue,
    setText,
  };

  return (
    <>
      <TextQuestionContext.Provider value={value}>
        {children}
      </TextQuestionContext.Provider>
    </>
  );
};

export const useTextQuestion = () => {
  return useContext(TextQuestionContext);
};
