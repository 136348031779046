import classNames from 'classnames';
import React from 'react';

const BasicCard = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        'px-3 py-5 mb-3 border-solid border border-forest-100 rounded-3xl flex',
        className
      )}
    >
      {children}
    </div>
  );
};

export default BasicCard;
