import infoIcon from '../../../assets/images/svg/info.svg';

import './InfoIcon.scss';

type InfoIconProps = {
    info?: string
    className?: string;
};

const InfoIcon = (props: InfoIconProps) => {
    const {info, className} = props;

    let containerClasses = 'info-container '
    if (!className) containerClasses += 'absolute top-0 right-0'
    else containerClasses += className

    return (
        <div className={containerClasses}>
            <img src={infoIcon} className='mr-2 w-5' alt='info-icon' />
            {
                info ? (
                    <div className = 'absolute top-0 p-2 info-text'>
                        {info}
                    </div>
                ) : null
            }
        </div>
    )
};

export default InfoIcon;