import { _request } from './request';

export type SubscriptionPlan = {
  id: number;
  name: string;
  price: number;
  description: string;
  category: string;
  active: boolean;
  billing_period: 'monthly' | 'yearly';
  max_weddings: number;
  product_id: string;
};

export interface SubscriptionPlansResponse {
  success: boolean;
  message: string;
  data: SubscriptionPlan[];
}

export const getSubscriptionPlans = async () => {
  const responseBody = await _request<SubscriptionPlansResponse>({
    url: `subscriptionplans`,
    method: 'GET',
  });
  return responseBody;
};
