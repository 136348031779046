import { Culture } from '../../../api/culture';
import { RitualType } from '../../../api/ritualType';
import { Tag } from '../../../api/tags';
import arrowLeftSvg from '../../../assets/images/icons/arrow-left-long.svg';
import { CustomMenuList } from '../../../components/forms/CustomMenuList';
import { Typography } from '../../../components/v2/Typography/Typography';
import { Sort } from '../../../provider/readingsProvider';

type FilterDetailViewProps = {
  title: string;
  onBackClick: () => void;
  options: { label: string; value: string }[];
  values: string[];
  handleItemClick: (value: string | Sort) => void;
  handleAllClick?: () => void;
};

const FilterDetailView = (props: FilterDetailViewProps) => {
  const {
    title,
    onBackClick,
    options,
    values,
    handleItemClick,
    handleAllClick,
  } = props;
  return (
    <div>
      <div className='flex flex-row gap-4 justify-start items-center px-8 pb-4'>
        <img
          onClick={onBackClick}
          src={arrowLeftSvg}
          className='cursor-pointer'
        />
        <Typography type='display-100'>{title}</Typography>
      </div>
      <CustomMenuList
        options={options}
        values={values}
        handleItemClick={handleItemClick}
        handleAllClick={handleAllClick}
      />
    </div>
  );
};

export default FilterDetailView;
