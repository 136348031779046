import { _request } from './request';

export type Tag = {
  id: number;
  name: string;
};

export type GetTagResponse = {
  success: boolean;
  message: string;
  data: Tag[];
};

export const getTags = async () => {
  const responseBody = await _request<GetTagResponse>({
    url: `tags`,
    method: 'GET',
  });
  return responseBody;
};
