/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useEffect, useState } from 'react';

type SnackbarContextType = {
  isActive: boolean;
  message: string;
  hideDuration: number;
  type: 'error' | 'success';
  openSnackBar: (message: string, type?: 'error' | 'success') => void;
  handleClose: () => void;
};

export const SnackbarContext = React.createContext<SnackbarContextType>({
  isActive: false,
  message: '',
  hideDuration: 5000,
  type: 'success',
  openSnackBar: () => {},
  handleClose: () => {},
});

export const CustomSnackbarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const hideDuration = 5000;
  const [type, setType] = useState<'error' | 'success'>('success');

  useEffect(() => {
    if (isActive === true) {
      setTimeout(() => {
        setIsActive(false);
      }, hideDuration);
    }
  }, [isActive, hideDuration]);

  const handleClose = () => {
    setIsActive(false);
  };

  const openSnackBar = (
    message = '',
    type: 'error' | 'success' = 'success'
  ) => {
    setMessage(message);
    setIsActive(true);
    setType(type);
  };

  const value = {
    isActive,
    message,
    type,
    hideDuration,
    openSnackBar,
    handleClose,
  };
  // @ts-ignore
  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};
