import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  getWaitlistQuestionAnswers,
  JoinWaitlistBody,
  joinWaitlistByModule,
  saveWaitlistQA,
  SaveWaitlistQABody,
  SaveWaitlistQARequest,
  WaitListEnrollment,
  WaitlistModule,
  WaitListQuestions,
} from '../../api/waitlist';
import Loader from '../../components/Loader/loader';
import SnackbarComponent from '../../components/Snackbar/Snackbar';
import { getAPIErrorMessage } from '../../helpers/helper';
import useCeremony from '../../hooks/useCeremony';
import useLoader from '../../hooks/useLoader';
import { useSnackbar } from '../../hooks/useSnackbar';

import LegalServiceGuidance from './LegalServiceGuidance';
import PreMaritalCounselling from './PreMaritalCounselling';

const Waitlist = () => {
  const { isShowing, showLoader, hideLoader } = useLoader();
  const { isActive, message, type, openSnackBar, handleClose } = useSnackbar();
  const [waitlistModule, setWaitlistModule] = useState<WaitlistModule>();
  const params = useParams();
  const [waitlist, setWaitlist] = useState<WaitListEnrollment>();
  const [QA, setQA] = useState<SaveWaitlistQABody[]>([]);
  const [waitlistQuestions, setWaitlistQuestions] =
    useState<WaitListQuestions[]>();
  const { fetchCeremonyById, currentUser } = useCeremony();

  useEffect(() => {
    void fetchCeremonyById(params.ceremonyId!);
  }, [params]);

  useEffect(() => {
    if (params.module) {
      if (params.module === 'pre-marital-counselling') {
        setWaitlistModule(WaitlistModule.PreMaritalCounselling);
      } else if (params.module === 'legal-service-guidance') {
        setWaitlistModule(WaitlistModule.LegalServiceGuidance);
      }
    }
  }, [params]);

  useEffect(() => {
    void fetchQuestionsAnswers();
  }, [waitlistModule]);

  const fetchQuestionsAnswers = async () => {
    try {
      if (waitlistModule) {
        const response = await getWaitlistQuestionAnswers(waitlistModule);
        if (response.success) {
          setWaitlist(response.data.enrollment);
          setWaitlistQuestions(response.data.questions);

          const qas: SaveWaitlistQABody[] = [];
          response.data.questions.forEach((question) => {
            if (question.answer) {
              qas.push({
                qid: question.answer.question_id.toString(),
                oid: question.answer.option_id ?? '',
                textans: question.answer.text_answer ?? '',
              });
            }
          });
          setQA(qas);
        } else {
          hideLoader();
          openSnackBar(response.message, 5000, 'error');
        }
      }
    } catch (err) {
      hideLoader();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      openSnackBar(getAPIErrorMessage(err as any), 5000, 'error');
    }
  };

  const joinWaitlist = async () => {
    try {
      if (waitlistModule) {
        const reqBody: JoinWaitlistBody = {
          cid: params.ceremonyId ?? '',
          mid: currentUser?.id?.toString() ?? '',
        };
        const response = await joinWaitlistByModule(waitlistModule, reqBody);
        if (response.success) {
          void fetchQuestionsAnswers();
          openSnackBar('You have joined the waitlist', 5000, 'success');
        } else {
          hideLoader();
          openSnackBar(response.message, 5000, 'error');
        }
      }
    } catch (err) {
      hideLoader();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      openSnackBar(getAPIErrorMessage(err as any), 5000, 'error');
    }
  };

  const answerQuestions = async () => {
    try {
      if (waitlistModule) {
        const reqBody: SaveWaitlistQARequest = {
          data: QA,
          cid: params.ceremonyId ?? '',
          mid: currentUser?.id?.toString() ?? '',
        };
        const response = await saveWaitlistQA(waitlistModule, reqBody);
        if (response.success) {
          if (!waitlist) {
            void fetchQuestionsAnswers();
          }
          openSnackBar('Answers submitted successfully!', 5000, 'success');
        } else {
          hideLoader();
          openSnackBar(response.message, 5000, 'error');
        }
      }
    } catch (err) {
      hideLoader();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      openSnackBar(getAPIErrorMessage(err as any), 5000, 'error');
    }
  };

  return (
    <div className='min-h-screen50 w-full items-center pb-20 bg-cosmicLatte-secondary module-container'>
      <SnackbarComponent
        isActive={isActive}
        message={message}
        type={type}
        handleClose={handleClose}
      />
      <Loader isShowing={isShowing} hide={hideLoader} allowHide={false} />
      {waitlistQuestions &&
        waitlistModule === WaitlistModule.PreMaritalCounselling && (
          <PreMaritalCounselling
            waitlist={waitlist}
            waitlistQuestions={waitlistQuestions}
            joinWaitList={joinWaitlist}
            answerQuestions={answerQuestions}
            setQA={setQA}
            QA={QA}
          />
        )}
      {waitlistQuestions &&
        waitlistModule === WaitlistModule.LegalServiceGuidance && (
          <LegalServiceGuidance
            waitlist={waitlist}
            waitlistQuestions={waitlistQuestions}
            joinWaitList={joinWaitlist}
            answerQuestions={answerQuestions}
            setQA={setQA}
            QA={QA}
          />
        )}
    </div>
  );
};

export default Waitlist;
