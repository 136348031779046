import { _request } from './request';


export type HearABoutOption = {
  name: string;
  id: number;
  allowComment: boolean | undefined;
}

export type HearAboutOptionsResponse = {
  success: boolean;
  message: string;
  data: null | HearABoutOption[]
  errors?: unknown;
}

export const getHearAboutOptions = async () => {
  const responseBody = await _request<HearAboutOptionsResponse>({
    url: `hearAbout/options/`,
    method: 'GET',
  });
  
  return responseBody;
};

export const submitHearAboutResponse = async (body: {option_id?: number, comment?: string}) => {
  const responseBody = await _request<HearAboutOptionsResponse>({
    url: `hearAbout/submit_answer/`,
    method: 'POST',
    body
  });
  
  return responseBody;
};


