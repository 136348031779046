import { useState } from 'react';

import './add-to-speech-button.scss';

type AddToSpeechButtonProps = {
  onClick: () => void;
  reverseColors?: boolean;
};

export const AddToSpeechButton = (props: AddToSpeechButtonProps) => {
  const { onClick, reverseColors } = props;
  const [clicked, setClicked] = useState(false);

  const handleOnClick = () => {
    if (clicked === false) {
      setClicked(true);
      onClick();
    }
    setTimeout(() => {
      setClicked(false);
    }, 3000);
  };
  return (
    <div
      className='flex flex-row flex-1 cursor-pointer items-center text-base text-red-output'
      onClick={handleOnClick}
    >
      <div
        className={`${
          reverseColors
            ? 'bg-red-output text-cosmicLatte-primary'
            : 'bg-cosmicLatte-primary text-red-output'
        } add-to-speech-button flex justify-center items-center mr-2`}
      >
        +
      </div>
      <div className='md:w-20 text-sm'>
        {clicked ? 'ADDED' : 'ADD TO MY SPEECH'}
      </div>
    </div>
  );
};
