import classNames from 'classnames';
import {
  ChangeEvent,
  FocusEventHandler,
  InputHTMLAttributes,
  MouseEventHandler,
} from 'react';

import './customTextInput.scss';
import './customTextArea.scss';

import { Typography } from '../v2/Typography/Typography';

interface CustomTextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  name?: string;
  helperText?: string;
  error?: boolean;
  readOnly?: boolean;
  customSize?: 200 | 400;
  fullWidth?: boolean;
}

const CustomTextArea = ({
  label,
  className,
  disabled,
  type,
  placeholder,
  onClick,
  name,
  readOnly,
  helperText,
  error,
  size = 400,
  fullWidth = false,
  onChange,
  ...props
}: CustomTextAreaProps) => {

  const {customSize} = props;
  if (customSize) size = customSize;
  
  return (
    <>
      {label && (
        <div className='mb-2'>
          <label>
            <Typography variant='functional-low' type='body-200'>
              <>{label}</>
            </Typography>
          </label>
        </div>
      )}
      <div className={`mb-4 ${fullWidth ? 'w-full' : ''}`}>
        <textarea
          /* {...props} */
          className={classNames(
            'custom-text-input',
            `custom-text-area-size-${size}`,
            className,
            {
              'custom-text-input-error': !!error,
            }
          )}
          onClick={onClick}
          disabled={disabled}
          
          placeholder={placeholder}
          name={name || ''}
          readOnly={readOnly}
          onChange={onChange}
          onWheel={(e) => (e.target as HTMLElement).blur()}
        />
        {error && helperText && (
          <div className='mt-2'>
            <Typography
              className='text-red-primary'
              variant='functional-low'
              type='body-100'
            >
              <>{helperText}</>
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomTextArea;
