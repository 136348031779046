/* eslint-disable */

import Button from '../../components/v2/Button/Button';
import { Typography } from '../../components/v2/Typography/Typography';
import { useWindowSize } from '../../hooks/useWindowSize';
import LanderItem from '../../components/LanderItem/LanderItem';

import background1Png from './background-1.png';
import background2Png from './background-2.png';
import background3Png from './background-3.png';
import checklistPng from './checklist.png';
import planePng from './plane.png';
import bookPng from './book.png';
import crIcon from './cr.png';
import {
  RedeemerApps,
  generateAuthToken,
  generateExternalApiKey,
} from '../../api/auth';
import { useSnackbar } from '../../provider/snackbarProvider';

import { useDashboard } from '../../provider/dashboardProvider';
import { useChecklist } from '../../provider/checklistProvider';
import { ChecklistItemIdentifier } from '../../helpers/checklistHelpers/identifiers';
import { navigateToApp } from '../../helpers/redirectToAppHelper';

const ReflectionsLander = () => {
  const size = useWindowSize();

  const { openSnackBar } = useSnackbar();

  const { ceremony, currentUser, setEnterPasswordModalOpen, setRedeemerApp } =
    useDashboard();

  const { saveMemberChecklistItemUsingIdentifier } = useChecklist();

  const onGetStartedClick = async () => {
    if (!ceremony) return openSnackBar('Ceremony not found', 'error');
    if (ceremony && currentUser) {
      void saveMemberChecklistItemUsingIdentifier(
        {
          ceremony_id: ceremony?.id,
          member_id: currentUser?.id,
          completed: true,
        },
        ChecklistItemIdentifier.GATHER_REFLECTIONS
      );
    }
    try {
      openSnackBar('Creating secure connection...', 'success');
      await navigateToApp(ceremony.id, RedeemerApps.REFLECTIONS);
    } catch (err) {
      console.log('err', err);
      setRedeemerApp(RedeemerApps.REFLECTIONS);
      setEnterPasswordModalOpen(true);
    }
  };

  return (
    <div className=' flex flex-col items-center text-center gap-6 mt-10 lg:mt-0'>
      <img className='h-16 w-16' alt='baby' src={crIcon} />
      <div>
        <Typography type={size.isMobile ? 'display-600' : 'display-1000'}>
          Community
        </Typography>
        <Typography type={size.isMobile ? 'display-600' : 'display-1000'}>
          Reflections
        </Typography>
        <Typography type='body-600' className='max-w-xl mt-6'>
          Create a meaningful collection of advice and wisdom from friends and
          family and turn it into a special memento for this next chapter!
        </Typography>
      </div>

      <div className='w-full items-center justify-center flex flex-col gap-2 my-6 '>
        <Button
          fullWidth={size.isMobile}
          renderRightIcon={() => (
            <svg
              width='18'
              height='18'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M20 4.8315V15.9697C20 16.4108 19.6422 16.7678 19.2 16.7678C18.7578 16.7678 18.4 16.4108 18.4 15.9697V6.76185L5.36489 19.7656C5.20938 19.9202 5.00438 20 4.79988 20C4.59538 20 4.39048 19.9221 4.23438 19.7662C3.92187 19.4544 3.92187 18.9491 4.23438 18.6379L17.2795 5.62419L7.99941 5.59652C7.5554 5.59466 7.1984 5.23553 7.1999 4.79658C7.20147 4.35544 7.5593 4 7.99991 4L19.2 4.03273C19.645 4.03541 20 4.39256 20 4.8315Z'
                fill='#efede7'
              />
            </svg>
          )}
          //variant='secondary'
          className='vow-benefit-placeholder'
          onClick={onGetStartedClick}
        >
          New Feature Unlocked
        </Button>
        <Typography type='body-100' className='mt-2 text-[#9AA3A0]'>
          You will be redirected to the Community Reflections.
        </Typography>
      </div>
      <div className='w-full flex flex-col justify-center items-center md:flex-row gap-3 flex-wrap'>
        <LanderItem
          borderSvgSource={background1Png}
          iconSvgSource={checklistPng}
          text='Pick (Or Write) A Prompt'
          textColor='text-[#6B2E25]'
        />
        <LanderItem
          borderSvgSource={background2Png}
          iconSvgSource={planePng}
          text='Invite Friends And Family'
          shiftDown={size.isMobile ? false : true}
          textColor='text-[#6B2E25]'
        />
        <LanderItem
          borderSvgSource={background3Png}
          iconSvgSource={bookPng}
          text='We Create A Keepsake for You'
          textColor='text-[#6B2E25]'
        />
      </div>
    </div>
  );
};

export default ReflectionsLander;
