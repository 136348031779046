import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { WaitListEnrollment, WaitListQuestions } from '../../../api/waitlist';
import arrowLeftSvg from '../../../assets/images/icons/arrow-left-circle.svg';
import infoCircleSvg from '../../../assets/images/icons/info-circle.svg';
import useProgressiveImg from '../../../hooks/useProgressiveImage';

import './header.scss';

type HeaderProps = {
  title: string;
  text: string;
  waitlist_title: string;
  imageSrc?: string;
  imagePlaceholderSrc?: string;
  ceremonyId?: string;
  waitlistQuestions: WaitListQuestions[];
  waitlist?: WaitListEnrollment;
  onJoinClick?: () => Promise<void>;
};
export const Header = (props: HeaderProps) => {
  const {
    title,
    text,
    imageSrc,
    waitlist_title,
    imagePlaceholderSrc,
    ceremonyId,
    waitlist,
    waitlistQuestions,
    onJoinClick,
  } = props;
  const [questionAnswered, setQuestionAnswered] = useState<boolean>(true);
  const [src, blur] = useProgressiveImg(
    imagePlaceholderSrc || '',
    imageSrc || ''
  );

  useEffect(() => {
    let hasAnswer = false;
    if (waitlistQuestions) {
      waitlistQuestions.forEach((waitlist) => {
        if (waitlist.answer) {
          hasAnswer = true;
        }
      });
    }
    setQuestionAnswered(hasAnswer);
  }, [waitlistQuestions]);

  const navigate = useNavigate();
  return (
    <div className='mx-4 mt-10 pb-8 flex flex-col md:flex-row-reverse max-w-7xl border-b border-forest-primary'>
      <div
        className='flex md:hidden flex-row cursor-pointer'
        onClick={() =>
          navigate(ceremonyId ? `/ceremony/${ceremonyId}` : `/dashboard`)
        }
      >
        <img alt='Go to dashboard' src={arrowLeftSvg} />
        <div className='ml-2'>Back to dashboard</div>
      </div>
      <div className='block md:hidden mt-9 pr-8 font-nanum text-copper-primary uppercase'>
        {'Coming Soon'}
      </div>
      <div className='block md:hidden title-container pl-4 mt-3 pr-8 w-fit bg-white text-3xl font-nanum text-forest-primary'>
        {title}
      </div>
      <div className=' flex md:justify-start justify-center items-center md:items-start'>
        {imageSrc && (
          <img
            className='w-92 max-h-fit'
            alt={`${title}-img`}
            src={typeof src === 'string' ? src : ''}
            style={{
              filter: blur ? 'blur(20px)' : 'none',
              transition: blur ? 'none' : 'filter 0.3s ease-out',
            }}
          />
        )}
      </div>
      <div className='flex-1'>
        <div
          className='hidden md:flex flex-row cursor-pointer'
          onClick={() =>
            navigate(ceremonyId ? `/ceremony/${ceremonyId}` : `/dashboard`)
          }
        >
          <img alt='Go to dashboard' src={arrowLeftSvg} />
          <div className='ml-2'>Back to dashboard</div>
        </div>
        <div className='mx-4 md:mx-0'>
          <div className='hidden md:block mt-9 pr-8 font-nanum text-copper-primary uppercase'>
            {'Coming Soon'}
          </div>
          <div className='hidden md:block title-container mt-3 pl-4 pr-8 w-fit bg-white text-5xl font-nanum text-forest-primary'>
            {title}
          </div>

          <div className='text-center md:text-left mt-6 text-base font-nanum lg:w-5/6 '>
            {text}
          </div>
          <div className='text-center md:text-left mt-6 text-2xl font-nanum lg:w-5/6 '>
            Are you interested in joining the waitlist for {waitlist_title}?
          </div>
          {onJoinClick && (
            <button
              onClick={() => {
                if (!waitlist) void onJoinClick();
              }}
              className={`px-2 md:px-3 lg:px-4 xl:px-6 2xl:px-8 
            py-2 md:py-2 lg:py-3 xl:py-4 2xl:py-5 mx-auto md:mx-0 mt-4 bg-forest-primary text-white
            w-full max-w-xl uppercase text-base flex justify-center border border-forest-primary ${
              waitlist ? 'cursor-not-allowed' : 'cursor-pointer'
            }`}
            >
              {waitlist ? 'WAITLIST JOINED' : 'YES, JOIN WAITLIST'}
            </button>
          )}
          {waitlist && !questionAnswered && (
            <div className='mt-5 w-full items-start bg-rose-100 flex flex-row lg:w-5/6 p-5 mb-5'>
              <img
                alt='Info Icon'
                src={infoCircleSvg}
                className={'pr-5 mt-1'}
              />

              <p className='text-copper-primary font-inter'>
                Thanks for joining the waitlist! If you have a minute, please
                answer the below questions so we can ensure we’re creating a
                legal services experience that best meets your needs.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
