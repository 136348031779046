import { useEffect, useMemo, useState } from 'react';

import { ChecklistItemIdentifier } from '../../../helpers/checklistHelpers/identifiers';
import useUser from '../../../hooks/useUser';
import { useChecklist } from '../../../provider/checklistProvider';
import CheckmarkSvg from '../ReferFriendModal/images/checkmark.svg';
import CopySvg from '../ReferFriendModal/images/copy.svg';
import { Typography } from '../Typography/Typography';

const SharableLink = ({
  type,
}: {
  type: 'wedding_planner_link' | 'link' | 'sms';
}) => {
  const linkTypes = {
    wedding_planner_link: 'w',
    link: 'l',
    sms: 's',
  };

  const { user } = useUser();
  const {saveMemberChecklistItemUsingIdentifier} = useChecklist();
  let referalLink = '';

  const [showCheckmark, setShowCheckmark] = useState<boolean>(false); 

  const copy = () => {
    navigator.clipboard.writeText(referalLink);
    setShowCheckmark(true);

    saveMemberChecklistItemUsingIdentifier(
      {
        completed: true,
      },
      ChecklistItemIdentifier.UNIQUE_LINK,
      true
    );

    //remove checkmark after 2 seconds
    setTimeout(() => {
      setShowCheckmark(false);
    }, 2000);
  };

  const hostname = window.location.hostname.includes('localhost') ? `${window.location.hostname}:3000` : window.location.hostname;

  referalLink = useMemo(() => {
    if (user?.first_name) {
      return `${hostname}/refer/${user?.first_name}-${linkTypes[type]}-${user?.id}`;
    }

    return '';
  }, [user]);

  return (
    <div className='flex flex-col w-full max-w-2xl'>
      <Typography
        type='body-200'
        variant='functional-low'
        className='font-grotesk text-forest-400 mb-2'
      >
        Share Your Link
      </Typography>
      <div className='bg-forest-100 p-4 flex flex-row justify-between items-center'>
        {user?.first_name && (
          <Typography type='body-400' variant='functional-low'>
            {referalLink}
          </Typography>
        )}

        <img
          src={showCheckmark ? CheckmarkSvg : CopySvg}
          alt='copy'
          className='ml-2 cursor-pointer'
          onClick={() => {
            copy();
          }}
        />
      </div>
    </div>
  );
};

export default SharableLink;
