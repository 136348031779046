import React, { useEffect, useState } from 'react';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

import { MemberType } from '../../../api/ceremony';
import { validateEmail } from '../../../api/emailValidation';
import { ReferSchemaBody, sendReferEmail } from '../../../api/user';
import { ChecklistItemIdentifier } from '../../../helpers/checklistHelpers/identifiers';
import { sendCustomerIoEventHandler } from '../../../helpers/customerIoHelper';
import renderText from '../../../helpers/renderText';
import useUser from '../../../hooks/useUser';
import { useChecklist } from '../../../provider/checklistProvider';
import Button from '../Button/Button';
import ButtonPill from '../ButtonPill';
import { Modal } from '../Modal/Modal';
import { Typography } from '../Typography/Typography';

import { ReferFriendModalThankYou } from './ReferFriendModalThankYou';
import ArrowLeftSvg from './images/arrow-left.svg';
import CheckmarkSvg from './images/checkmark.svg';
import CommentSvg from './images/comment.svg';
import CopySvg from './images/copy.svg';
import EnvelopeSvg from './images/envelope.svg';
import PaperAirplanePNG from './images/paper-airplane.png';
import './style/style.scss';

import PreviewEmail from '../PreviewEmail/PreviewEmail';
import { useDashboard } from '../../../provider/dashboardProvider';

type ReferFriendModalProps = {
  isOpen: boolean;
  onClose: () => void;
  dissabledEmails?: string[];
  ceremonyId?: number;
};
type InviteMethods = 'email' | 'link' | 'sms';

type ReferralLink = {
  link: string;
  email: string;
  sms: string;
};

export const ReferFriendModal = (props: ReferFriendModalProps) => {
  const { user } = useUser();

  const { saveMemberChecklistItemUsingIdentifier } = useChecklist();

  const { isOpen, onClose } = props;
  const [emails, setEmails] = useState<string[]>([]);
  const [inputValue, setinputValue] = useState<string>('');
  const [erroredEmails, setErroredEmails] = useState<string[]>([]); //emails that are not valid
  const [triggerAddEmail, setTriggerAddEmail] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [openThankYou, setOpenThankYou] = useState<boolean>(false);
  const [inviteMethod, setInviteMethod] = useState<InviteMethods>('link');
  const [referalLink, setReferalLink] = useState<ReferralLink>();
  const [showCheckmark, setShowCheckmark] = useState<boolean>(false); //show checkmark after copy
  const { dissabledEmails, ceremonyId } = props;
  const { currentUser } = useDashboard();

  useEffect(() => {
    if (isOpen) {
      setEmails([]);
      setErrors([]);
      setInviteMethod('link');

      const template = currentUser?.member_type == MemberType.guests ? ChecklistItemIdentifier.GUEST_REFER_FRIENDS : ChecklistItemIdentifier.REFER_FRIENDS
      
      void saveMemberChecklistItemUsingIdentifier(
        {
          //this is passed jist for the refetching of the checklist
          ceremony_id: ceremonyId,
          completed: true,
        },
        template,
        false,
        true
      );
    }
  }, [isOpen]);

  useEffect(() => {
    if (triggerAddEmail) {
      setTriggerAddEmail(false);
    }
  }, [triggerAddEmail]);

  useEffect(() => {
    const filtered = erroredEmails.filter((email) => {
      return emails.includes(email);
    });
    if (filtered.length === 0) {
      setErrors([]);
    }
    setErroredEmails(filtered);
  }, [emails]);

  useEffect(() => {
    if (user?.first_name) {
      const currentDomainName = window.location.hostname;
      const protocol = window.location.protocol;

      setReferalLink({
        email: `${protocol}//${currentDomainName}/refer/${user?.first_name}-e-${user?.id}`,
        link: `${protocol}//${currentDomainName}/refer/${user?.first_name}-l-${user?.id}`,
        sms: `${protocol}//${currentDomainName}/refer/${user?.first_name}-s-${user?.id}`,
      });
    }
  }, [user]);

  useEffect(() => {
    if (erroredEmails.length > 0) {
      //find .react-tagsinput-tag class which is the tag container that contains the errored email
      const EmailsElements = document.querySelectorAll('.react-tagsinput-tag');

      //foreach element in the EmailsElements array get the innerText which is the email
      //if the erroredEmails array contains the email then add the class .error to the element
      EmailsElements.forEach((element: Element) => {
        const email = (element as HTMLElement).innerText;
        if (erroredEmails.includes(email)) {
          element.classList.add('error');
        }
      });

      //.react-tagsinput add the class .error to the input element
      const inputElement = document.querySelector('.react-tagsinput');
      if (inputElement) {
        inputElement.classList.add('error');
      }
    } else {
      const EmailsElements = document.querySelectorAll('.react-tagsinput-tag');
      EmailsElements.forEach((element: Element) => {
        element.classList.remove('error');
      });
      const inputElement = document.querySelector('.react-tagsinput');
      if (inputElement) {
        inputElement.classList.remove('error');
      }
    }
  }, [erroredEmails]);

  const sendReferal = async () => {
    try {
      const emailsValidSyntax = emails.every(async (email) => {
        let isValid = false;
        if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
          isValid = true;
        }
        if (isValid) {
          isValid = await checkIsEmailValid(email);
        }
        return isValid;
      });

      const emailNotDissabled = emails.every((email) => {
        return !dissabledEmails?.includes(email);
      });

      const emailsIsNotEmpty = emails.length > 0;

      const uniqueEmails = [...new Set(emails)];

      if (!emailsValidSyntax) {
        setErrors(['Please enter valid email addresses']);
        return;
      }

      if (!emailNotDissabled) {
        if (dissabledEmails?.length === 1)
          setErrors(['This email is not valid: ' + dissabledEmails[0]]);
        else if (dissabledEmails !== undefined)
          setErrors([
            'These emails are not valid: ' + dissabledEmails.join(', '),
          ]);
        return;
      }

      if (!emailsIsNotEmpty) {
        setErrors(['Please enter at least one email address']);
        return;
      }

      const response = await sendReferEmail({
        email: uniqueEmails,
        ceremony_id: ceremonyId,
      } as ReferSchemaBody);

      if (response.success) {
        for (const email of uniqueEmails) {
          void sendCustomerIoEventHandler(
            'Referral Received',
            {
              recipient_email: email,
              sender_name: `${user?.first_name ?? ''} ${user?.last_name ?? ''}`,
              sender_type: user?.member_type ?? '',
              referral_status: 'pending',
              link: referalLink?.link,
            },
            email
          );

          void sendCustomerIoEventHandler('Referral Sent', {
            recipient_email: email,
            sender_name: `${user?.first_name ?? ''} ${user?.last_name ?? ''}`,
            sender_type: user?.member_type ?? '',
            referral_status: 'pending',
            link: referalLink?.link,
          });
        }
        onClose();
        setOpenThankYou(true);
      } else if (response.success === false && response.message) {
        setErrors([response.message]);
        if (response.data) {
          setErroredEmails(response.data);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const title = renderText('refer_friend_modal_title');

  const subtitle = renderText('refer_friend_modal_subtitle');

  const emailButtonText = renderText('refer_friend_modal_email_button_text');

  const checkIsEmailValid = async (email: string) => {
    const response = await validateEmail(email);
    if (response.success) {
      return response.data.isValid;
    }
    return false;
  };

  useEffect(() => {
    if (emails.length > 0) {
      const email = emails[emails.length - 1];
      void checkIsEmailValid(email).then((isValid) => {
        if (!isValid) {
          setErrors(['Please enter valid email addresses']);
          setErroredEmails([...erroredEmails, email]);
        }
      });
    }
  }, [emails]);

  const emailInviteComponent = (
    <>
      <div className='flex lg:flex-row flex-col gap-3 lg:items-center  mb-2'>
        <div className='text-sm font-grotesk font-[500]'>Invite via Emails</div>
        <PreviewEmail
          type='REFERRAL'
          text='Preview Referral Email'
          inline={true}
        />
      </div>
      <TagsInput
        value={emails}
        onChange={setEmails}
        inputValue={inputValue}
        onChangeInput={(value: string) => {
          setinputValue(value);
          if (erroredEmails.length === 0) {
            setErrors([]);
          }
        }}
        inputProps={{
          placeholder: 'Enter email address',
        }}
        //also add comma
        addKeys={[9, 13, 32, 186, 188]}
        addOnBlur={true}
        validationRegex={/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i}
        onValidationReject={(e) => {
          setErrors(['Email address is not valid']);
        }}
      />
      {errors.length > 0 && (
        <Typography
          variant='functional-low'
          type='body-100'
          className='text-sm text-red-primary mt-1'
        >
          <>
            {/* You've already shared with one or more people in this list. Please
            remove them to continue.{' '} */}
            {errors[0] + '. '}
            <span
              className='cursor-pointer underline decoration-dashed'
              onClick={() => {
                window.location.href = '/settings?tab=referrals';
              }}
            >
              Manage all referrals here.
            </span>
          </>
        </Typography>
      )}
      <Button
        size='100'
        onClick={sendReferal}
        className='mx-auto block mt-6'
        disabled={emails.length === 0 || errors.length > 0}
      >
        Send Referrals
      </Button>
    </>
  );

  const copy = () => {
    navigator.clipboard.writeText(referalLink?.link || '');
    setShowCheckmark(true);

    //remove checkmark after 2 seconds
    setTimeout(() => {
      setShowCheckmark(false);
    }, 2000);
  };

  const linkInviteComponent = (
    <>
      <div className='text-sm font-grotesk font-[500] mb-2'>
        Share Your Link
      </div>
      <div className='bg-forest-100 p-4 flex flex-row justify-between items-center'>
        {user?.first_name && (
          <Typography type='body-400' variant='functional-low'>
            {referalLink?.link}
          </Typography>
        )}

        <img
          src={showCheckmark ? CheckmarkSvg : CopySvg}
          alt='copy'
          className='ml-2 cursor-pointer'
          onClick={() => {
            copy();
          }}
        />
      </div>
      <div className='w-full h-px bg-forest-100 my-7 relative'>
        <span className='text-forest-500 text-lg font-light font-recife absolute -top-[13px] left-1/2 -translate-x-1/2 bg-cosmicLatte-primary px-2'>
          Or
        </span>
      </div>
      <div className='flex md:flex-row flex-col justify-between gap-3'>
        <ButtonPill
          onClick={() => setInviteMethod('email')}
          className='w-full'
          text={emailButtonText}
          icon={EnvelopeSvg}
        />
        <ButtonPill
          onClick={() => {
            //trigger SMS app
            window.open(
              `sms:?&body="Check out Provenance's tools to write your ceremony script and vows. Get 20% off with code 20CHEERSTOYOU at this link: ${
                referalLink?.sms || ''
              }."`,
              '_blank'
            );
          }}
          className='w-full'
          text={`Text Your Link`}
          icon={CommentSvg}
        />
      </div>
    </>
  );

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} onClose={onClose} maxWidth='md'>
        <>
          {inviteMethod !== 'link' && (
            <img
              src={ArrowLeftSvg}
              alt='arrow left'
              className='mb-6 absolute top-4 left-4 cursor-pointer'
              onClick={() => {
                setInviteMethod('link');
              }}
            />
          )}
          <div className='max-w-[650px] text-forest-primary'>
            <div>
              <img
                src={PaperAirplanePNG}
                alt='paper airplane'
                className='mx-auto max-w-[126px] pb-6'
              />
              <header>
                <h4 className='font-recife text-forest-primary text-3xl text-center w-full'>
                  {title}
                </h4>
              </header>

              <div className='font-recife font-light text-base text-forest-primary w-full text-center pt-4 pb-6'>
                {subtitle}
                <>
                  {' '}
                  <a
                    href='https://provenance.elevio.help/en/articles/57-how-do-referrals-work-on-provenance'
                    className='text-forest-primary underline'
                    target={'_blank'}
                  >
                    Learn how referrals work.
                  </a>
                </>
              </div>

              {inviteMethod === 'email' && emailInviteComponent}

              {inviteMethod === 'link' && linkInviteComponent}
            </div>
          </div>
        </>
      </Modal>

      <ReferFriendModalThankYou
        isOpen={openThankYou}
        onClose={() => setOpenThankYou(false)}
      />
    </React.Fragment>
  );
};
