import {
  Question,
  QuestionAnswer,
} from '../pages/Modules/common/moduleQuestion/ModuleQuestion';

import { _request } from './request';

export type QuestionAnswers = {
  answers: QuestionAnswer[];
};

export type SaveAnswerResponse = {
  success: boolean;
  message: string;
  data: QuestionAnswer;
};

export const saveAnswer = async (body: QuestionAnswer) => {
  const responseBody = await _request<SaveAnswerResponse>({
    url: 'questions/save-answer',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const deleteAnswer = async (body: QuestionAnswer) => {
  const responseBody = await _request<SaveAnswerResponse>({
    url: 'questions/delete-answer',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const saveAnswers = async (body: QuestionAnswers) => {
  const responseBody = await _request<SaveAnswerResponse>({
    url: 'questions/save-batch-answer',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const saveAnswersIndividuals = async (body: QuestionAnswers) => {
  const responseBody = await _request<SaveAnswerResponse>({
    url: 'questions/save-batch-answer-individuals',
    method: 'POST',
    body,
  });

  return responseBody;
};

export type GetModuleQuestionsResponse = {
  success: boolean;
  message: string;
  data: Question[];
};

export type GetQuestionAnswerStatusResponse = {
  success: boolean;
  message: string;
  data: QuestionAnswerStatusObj;
};

export type QuestionAnswerStatusObj = {
  [key: string]: QuestionAnswerStatus;
};

export interface QuestionAnswerStatus {
  totalAnswered: number;
  totalQuestion: number;
  completed: boolean;
  lastAnswerDate: Date;
}

export const getModuleQuestions = async (
  module: string,
  ceremonyId: string
) => {
  const responseBody = await _request<GetModuleQuestionsResponse>({
    url: `questions?module=${module}&ceremonyid=${ceremonyId}`,
    method: 'GET',
  });
  return responseBody;
};

export const getAllQuestions = async (ceremonyId: string) => {
  const responseBody = await _request<GetModuleQuestionsResponse>({
    url: `questions?ceremonyid=${ceremonyId}`,
    method: 'GET',
  });
  return responseBody;
};

export const getQuestionAnswerStatus = async (ceremonyId: string) => {
  const responseBody = await _request<GetQuestionAnswerStatusResponse>({
    url: `questions/get-survey-status/${ceremonyId}`,
    method: 'GET',
  });
  return responseBody;
};

export const guestSurveyCompletion = async (ceremonyId: string) => {
  const responseBody = await _request<GetQuestionAnswerStatusResponse>({
    url: `friends/friend-survey-completion/${ceremonyId}`,
    method: 'POST',
  });
  return responseBody;
};
