import { useState } from 'react';

import { Member } from '../../../api/ceremony';
import { Declaration } from '../../../api/declaration';
import { Modal } from '../../../components/modal/Modal';

import { DeclarationItem } from './DeclarationItem';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onUseDeclarationClick?: (declarationId: number) => void;
  declarations?: Declaration[];
  insertText?: (text: string) => void;
  couple1: Member;
  couple2: Member;
  title: string;
};

export const DeclarationsModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    declarations,
    onUseDeclarationClick,
    insertText,
    couple1,
    couple2,
    title,
  } = props;
  const [expanded, setExpanded] = useState<number | null>(null);

  const handleSetExpanded = (declarationId: number) => {
    if (expanded === declarationId) {
      setExpanded(null);
    } else {
      setExpanded(declarationId);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title}>
      <div className='readings-modal px-2'>
        {declarations &&
          declarations.map((d: Declaration) => (
            <DeclarationItem
              key={d.id}
              expanded={d.id === expanded || declarations.length === 1}
              setExpanded={handleSetExpanded}
              declaration={d}
              onUseDeclarationClick={onUseDeclarationClick}
              insertText={insertText}
              couple1={couple1}
              couple2={couple2}
            />
          ))}
      </div>
    </Modal>
  );
};
