import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { generalLog } from '../../api/ceremony';
import heartComment from '../../assets/images/icons/heartComment.svg';
import Button from '../../components/v2/Button/Button';
import { Module } from '../../hooks/useActiveModule';
import useCeremony from '../../hooks/useCeremony';
import useQuestions from '../../hooks/useQuestions';
import { CTAProvider } from '../../provider/ctaProvider';
import { useWelcomeToastBuilder } from '../../provider/welcomeToastBuilderProvider';
import { ModuleQuestion, ModuleWrapper } from '../ModulesV2/components';
import { GuiltModal } from '../ModulesV2/components/GuiltModal/GuiltModal';
import { ModuleStatus } from '../ModulesV2/enum';

export const WelcomeToastModule = ({
  hideWrapper = false,
}: {
  hideWrapper?: boolean;
}) => {
  const navigate = useNavigate();
  const ceremonyId = useParams()?.ceremonyId;
  const requiredQuestions = 5;
  const [openGuiltModal, setOpenGuiltModal] = useState(false);

  const {
    ceremony,
    couple1,
    couple2,
    officiant,
    currentUser,
    fetchCeremonyById,
  } = useCeremony();

  const members = { couple1, couple2, officiant, currentUser };

  const { setDraftingModalOpen, setGenerateDraftInitiated } =
    useWelcomeToastBuilder();
  const welcomeToastModule = useQuestions.Modules.WelcomeToast;
  const { questions, fetchQuestions } = useQuestions({
    ceremonyId,
    module: welcomeToastModule,
  });

  useEffect(() => {
    if (ceremonyId) {
      void fetchCeremonyById(ceremonyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ceremonyId]);

  useEffect(() => {
    if (ceremonyId) {
      void fetchQuestions(Module.WelcomeToast, ceremonyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ceremonyId]);

  const startGenerating = () => {
    if (ceremonyId) {
      setDraftingModalOpen(true);
      setGenerateDraftInitiated(true);

      void generalLog({
        ceremony_id: ceremonyId,
        activity: `Generate - WelcomeToast`,
      });

      navigate(`/welcome-toast-builder-ai/${ceremonyId}`);
    } else {
      navigate(-1);
    }
  };

  return (
    <CTAProvider
      totalQuestions={questions?.length || 0}
      requiredQuestions={questions?.length || 0}
      module={Module.WelcomeToast}
    >
      <ModuleWrapper
        hideWrapper={hideWrapper}
        chapterProps={{
          renderIcon: () => <img src={heartComment} draggable={false} />,
        }}
        summaryProps={{
          moduleName: 'Welcome Toast Questionnaire',
          moduleDescription: `Giving a toast is an incredible privilege, filled with both excitement and a touch of apprehension. Fear not, for our AI-powered questionnaire is here to help transform your personal anecdotes into a delightful toast that will undoubtedly inspire a chorus of clinking glasses.`,
          numberOfQuestions: 5,
          estimatedTime: 10,
        }}
        questionsHeaderProps={{
          renderCenterButton: (
            moduleStatus: ModuleStatus,
            status: 'saving' | 'saved' | 'unsaved'
          ) => (
            <Button
              size='100'
              disabled={
                (moduleStatus !== ModuleStatus.completed &&
                  moduleStatus !== ModuleStatus.requiredAnswered) ||
                status !== 'saved'
              }
              onClick={() => {
                if (moduleStatus !== ModuleStatus.completed)
                  setOpenGuiltModal(true);
                else startGenerating();
              }}
            >
              Generate Toast
            </Button>
          ),
          renderRightButton: (moduleStatus: ModuleStatus) => (
            <Button
              size='100'
              variant='secondary'
              disabled={false}
              onClick={() => {
                if (ceremonyId) {
                  navigate(`/ceremony/${ceremonyId}/toastbuilder`);
                } else {
                  navigate(-1);
                }
              }}
            >
              Exit & Save
            </Button>
          ),
        }}
        noGap={true}
      >
        {ceremony &&
          questions &&
          questions.length > 0 &&
          questions
            .slice(0, requiredQuestions)
            .sort((a, b) => {
              if (a.order && b.order) {
                return a.order < b.order ? -1 : 1;
              } else if (a.order != null && b.order === null) {
                return -1;
              } else if (a.order === null && b.order != null) {
                return 1;
              } else {
                return -1;
              }
            })
            .map((questionData, questionIndex) => (
              <ModuleQuestion
                key={`question-${questionData.id}`}
                ceremonyId={ceremony.id}
                question={questionData}
                questionNumber={questionIndex + 1}
                numberOfQuestions={questions.length}
                members={members}
                required={true}
              />
            ))}
        {ceremony &&
          questions &&
          questions.length > 0 &&
          questions
            .slice(requiredQuestions)
            .sort((a, b) => {
              if (a.order && b.order) {
                return a.order < b.order ? -1 : 1;
              } else if (a.order != null && b.order === null) {
                return -1;
              } else if (a.order === null && b.order != null) {
                return 1;
              } else {
                return -1;
              }
            })
            .map((questionData, questionIndex) => (
              <ModuleQuestion
                key={`question-${questionData.id}`}
                ceremonyId={ceremony.id}
                question={questionData}
                questionNumber={questionIndex + requiredQuestions + 1}
                numberOfQuestions={questions.length}
                members={members}
                required={false}
              />
            ))}
        <GuiltModal
          isOpen={openGuiltModal}
          totalQuestions={questions?.length || 0}
          onCancelClick={() => {
            setOpenGuiltModal(false);
          }}
          onContinue={startGenerating}
        />
      </ModuleWrapper>
    </CTAProvider>
  );
};
