/* eslint-disable -- TODO: fix eslint errors */

import { useEffect, useState } from 'react';

import {
  getQuestionAnswerStatus,
  GetQuestionAnswerStatusResponse,
  QuestionAnswerStatusObj,
} from '../api/question';

import { Module } from './useActiveModule';

const useCeremonyProgress = () => {
  const [QAStatus, setQAStatus] = useState<QuestionAnswerStatusObj | null>(
    null
  );

  const fetchQaStatus = async (ceremonyId: string) => {
    try {
      const qaStatusResponse: GetQuestionAnswerStatusResponse =
        await getQuestionAnswerStatus(ceremonyId);
      if (qaStatusResponse.success) {
        setQAStatus(qaStatusResponse.data);
        return 1;
      }
    } catch (error) {
      return 0;
    }
  };

  const getPercentageByModule = (type: Module) => {
    try {
      if (QAStatus) {
        return Math.floor(
          (QAStatus[type].totalAnswered / QAStatus[type].totalQuestion) * 100
        );
      } else {
        return 0;
      }
    } catch {
      return 0;
    }
  };

  const getNumberOfQuestionsByModule = (type: Module) => {
    try {
      if (QAStatus) {
        return QAStatus[type].totalQuestion;
      } else {
        return 0;
      }
    } catch {
      return 0;
    }
  };

  const getNumberOfAnsweredQuestionsByModule = (type: Module) => {
    try {
      if (QAStatus) {
        return QAStatus[type].totalAnswered;
      } else {
        return 0;
      }
    } catch {
      return 0;
    }
  };

  const getBasicCeremonyProgress = <Number,>() => {
    if (!QAStatus) {
      //console.log('you should fetch the QA status before calling getBasicCeremony')
      return 0;
    }

    const directionalPercentage = getPercentageByModule(
      Module.DirectionalQuestions
    );
    const remarksPercentage = getPercentageByModule(Module.OfficiantRemarks);
    const readingsPercentage = getPercentageByModule(Module.RitualsAndReadings);
    const declarationPercentage = getPercentageByModule(
      Module.DeclarationOfIntent
    );

    if (
      directionalPercentage === 100 &&
      remarksPercentage === 100 &&
      readingsPercentage === 100 &&
      declarationPercentage === 100
    ) {
      return 100;
    } else if (
      directionalPercentage === 0 &&
      remarksPercentage === 0 &&
      readingsPercentage === 0 &&
      declarationPercentage === 0
    ) {
      return 0;
    } else {
      return (
        (directionalPercentage +
          remarksPercentage +
          readingsPercentage +
          declarationPercentage) /
        4
      );
    }
  };

  return {
    QAStatus,
    fetchQaStatus,
    getPercentageByModule,
    getNumberOfQuestionsByModule,
    getNumberOfAnsweredQuestionsByModule,
    getBasicCeremonyProgress,
  };
};

export default useCeremonyProgress;
