
import eyeIcon from '../../../assets/images/svg/eyeIcon.svg';

import './EyeIcon.scss';

type EyeIconProps = {
    className?: string;
    onClick: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const EyeIcon = (props: EyeIconProps) => {
    const { className, onClick} = props;

    let containerClasses = 'eye-container '
    if (!className) containerClasses += 'absolute top-5 right-0'
    else containerClasses += className

    return (
        <div 
        className={containerClasses}
        onClick={(ev) => onClick(ev)}
        >
            <img src={eyeIcon} className='mr-2 w-5' alt='Eye-icon' />
        </div>
    )
};

export default EyeIcon;