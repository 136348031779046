import { useEffect, useState } from 'react';

import { ModuleStatus } from '../pages/ModulesV2/enum';

export const useCeremonyChapterStatus = () => {
  const [ceremonyDetailsStatus, setCeremonyDetailsStatus] =
    useState<ModuleStatus>(ModuleStatus.notStarted);
  const [readingsStatus, setReadingsStatus] = useState<ModuleStatus>(
    ModuleStatus.notStarted
  );
  const [remarksStatus, setRemarksStatus] = useState<ModuleStatus>(
    ModuleStatus.notStarted
  );

  const [remarksMinNumQuestionsAnswered, setRemarksMinNumQuestionsAnswered] = useState(false);

  const [declarationStatus, setDeclarationStatus] = useState<ModuleStatus>(
    ModuleStatus.notStarted
  );
  const [vowsCeremonyStatus, setVowsCeremonyStatus] = useState<ModuleStatus>(
    ModuleStatus.notStarted
  );
  const [pronouncementStatus, setPronouncementStatus] = useState<ModuleStatus>(
    ModuleStatus.notStarted
  );

  return {
    ceremonyDetailsStatus,
    setCeremonyDetailsStatus,
    readingsStatus,
    setReadingsStatus,
    remarksStatus,
    setRemarksStatus,
    remarksMinNumQuestionsAnswered,
    setRemarksMinNumQuestionsAnswered,
    declarationStatus,
    setDeclarationStatus,
    vowsCeremonyStatus,
    setVowsCeremonyStatus,
    pronouncementStatus,
    setPronouncementStatus,
  };
};
