import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { CustomFormikInput } from '../../../pages/Modules/friendsAndFamily/CustomFormikInput';
import Button from '../Button/Button';
import Requirement from '../Requirement';
import { Typography } from '../Typography/Typography';

export type ResetPasswordType = {
  password: string;
  confirmPassword: string;
};

const initialValues = {
  password: '',
  confirmPassword: '',
};

const passwordRequirements = [
  '(?=.{8,})',
  '(?=.*[a-z])(?=.*[A-Z])',
  "(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}[|:;'<>,.?/_₹])",
];

const SignInSchema = Yup.object().shape({
  password: Yup.string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹])(?=.{8,})/,
      'Min 8 character and one Uppercase, Lowercase, Number and Special Character'
    ),
  confirmPassword: Yup.string()
    .required('Please Confirm your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹])(?=.{8,})/,
      'Min 8 character and one Uppercase, Lowercase, Number and Special Character'
    ),
});

type ResetPasswordFormProps = {
  onSubmit: (values: ResetPasswordType) => Promise<void>;
  isReset: boolean;
};

const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const { onSubmit, isReset } = props;

  return (
    <div className='container flex flex-col justify-center max-w-md px-4 sm:px-0 py-4'>
      <Typography type='display-600' className='mt-6 text-center'>
        {isReset ? 'Reset your password' : 'Create a new password'}
      </Typography>
      <Typography type='body-400' className='mt-2 mb-10 text-center'>
        Choose a new and secure password that meets our password criteria below.
      </Typography>
      <Formik
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={initialValues}
        onSubmit={(values) => {
          void onSubmit(values);
        }}
        validationSchema={SignInSchema}
      >
        {({
          values,
          isValid,
        }: {
          values: ResetPasswordType;
          isValid: boolean;
        }) => (
          <Form className='w-full mt-6'>
            <div className='input-container full-size-1'>
              <Field
                name={`password`}
                placeholder='Enter a password'
                type='password'
                component={CustomFormikInput}
                label='Password'
                className={'w-full mx-0 h-fit'}
                eyeIcon={true}
              />
              <div className='flex flex-col w-full ml-1 mb-6'>
                <Requirement
                  text='At least 8 characters'
                  done={Boolean(values.password.match(passwordRequirements[0]))}
                />
                <Requirement
                  text='Uppercase and lowercase'
                  done={Boolean(values.password.match(passwordRequirements[1]))}
                />
                <Requirement
                  text='Numbers and special characters'
                  done={Boolean(values.password.match(passwordRequirements[2]))}
                />
              </div>
              <Field
                name={`confirmPassword`}
                placeholder='Reenter your password'
                type='password'
                component={CustomFormikInput}
                label='Confirm Your Password'
                className={'w-full mx-0 h-fit'}
                eyeIcon={true}
              />
            </div>

            <Button
              fullWidth
              className='mt-6'
              type='submit'
              disabled={!isValid}
            >
              {isReset ? 'Update Password' : 'Create password'}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPasswordForm;
