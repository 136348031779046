import {
  DeleteOutline,
  PersonOutline,
  PetsOutlined,
} from '@mui/icons-material';

import { SelectField, TextField } from '../../../../components';

type IndividualPersonFormProps = {
  name?: string;
  type?: string;
  personType?: 'individual' | 'vendor';
  description?: string;
  onChange?: (field: string, value: string) => Promise<void> | void;
  onDelete?: () => Promise<void> | void;
  showDeleteButton?: boolean;
};

type labelType = {
  name: string;
  type: string;
  typeOptions: { value: string; label: string }[];
  description: string;
  icon: JSX.Element;
};

type labelMasterType = {
  individual: labelType;
  vendor: labelType;
  '': labelType;
};

export const IndividualPersonForm = ({
  name,
  type,
  personType = 'individual',
  description,
  onChange,
  onDelete,
  showDeleteButton = true,
}: IndividualPersonFormProps) => {
  const defaultLabel: labelType = {
    name: 'Name of individual',
    type: 'Who are they?',
    typeOptions: [
      { label: 'A relative', value: 'relative' },
      { label: 'A friend', value: 'friend' },
      { label: 'A pet', value: 'pet' },
    ],
    description: 'What makes this individual special?',
    icon: <PersonOutline />,
  };

  const labelMaster: labelMasterType = {
    '': defaultLabel,
    individual: defaultLabel,
    vendor: {
      name: 'Name of vendor',
      type: 'Vendor Type',
      typeOptions: [
        { value: 'food', label: 'Food' },
        { value: 'photographer', label: 'Photographer' },
        { value: 'videographer', label: 'Videographer' },
        { value: 'florist', label: 'Florist' },
        { value: 'caterer', label: 'Caterer' },
        { value: 'musician', label: 'Musician' },
        { value: 'wedding_planner', label: 'Wedding Planner' },
      ],
      description: 'What makes this vendor special?',
      icon: <PersonOutline />,
    },
  };

  const getIcon = () => {
    switch (type) {
      case 'pet':
        return <PetsOutlined />;

      default:
        return labelMaster[personType]?.icon;
    }
  };

  return (
    <div className='flex flex-col p-6 mt-6 border border-[#CCD1CF] rounded-3xl gap-6 relative'>
      {showDeleteButton && (
        <button className='absolute right-5 top-5' onClick={onDelete}>
          <DeleteOutline fontSize='small' />
        </button>
      )}
      <div className='flex flex-row items-center gap-4'>
        <div className='flex w-12 h-12 rounded-full bg-[#CCD1CF] justify-center items-center'>
          {getIcon()}
        </div>
        <span className='font-recife text-lg text-forest-500 font-[310]'>
          {name}
        </span>
      </div>
      <div className='flex flex-col sm:flex-row gap-6 sm:gap-2'>
        <TextField
          label={labelMaster[personType].name}
          value={name}
          onChange={(evt) => void onChange?.('name', evt.target.value)}
          labelVariant='outside'
          wrapperProps={{ className: 'flex-1' }}
          fontVariant='grotesk'
          fullWidth
        />
        <SelectField
          label={labelMaster[personType].type}
          options={labelMaster[personType].typeOptions}
          value={type}
          onChange={(newValue) => void onChange?.('type', newValue as string)}
          wrapperProps={{ className: 'flex-1' }}
          labelVariant='outside'
          fontVariant='grotesk'
          fullWidth
        />
      </div>
      <TextField
        label={labelMaster[personType].description}
        value={description}
        onChange={(evt) => void onChange?.('description', evt.target.value)}
        fontVariant='grotesk'
        fullWidth
        labelVariant='outside'
        minRows={3}
        multiline
      />
    </div>
  );
};
