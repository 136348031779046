import { useParams } from 'react-router-dom';

import {
  SaveWaitlistQABody,
  WaitListEnrollment,
  WaitListQuestions,
} from '../../../api/waitlist';
import toastBuilderLandingPlaceholder from '../../../assets/images/image/placeholders/speechwritingwelcome.png';
import toastBuilderLanding from '../../../assets/images/image/speechwritingwelcome.png';
import { Header } from '../Header/Header';
import WaitlistQuestions from '../WaitlistQuestions';

type LegalServiceGuidanceProps = {
  waitlist?: WaitListEnrollment;
  waitlistQuestions: WaitListQuestions[];
  joinWaitList: () => Promise<void>;
  answerQuestions: () => Promise<void>;
  setQA: React.Dispatch<React.SetStateAction<SaveWaitlistQABody[]>>;
  QA: SaveWaitlistQABody[];
};
const LegalServiceGuidance = (props: LegalServiceGuidanceProps) => {
  const {
    waitlist,
    waitlistQuestions,
    QA,
    joinWaitList,
    answerQuestions,
    setQA,
  } = props;
  const params = useParams();

  return (
    <div className='min-h-screen'>
      <div className='container w-full max-w-7xl self-center mx-auto'>
        <Header
          title={'Legal Services and Guidance'}
          text={
            'Have legal questions ahead of your wedding? You’re not alone. We’ve talked to couples like you who wished they had more support on this front, so we are building a service to meet those needs. Please answer the 1-minute questionnaire below so we can create something that’s both helpful and effective.'
          }
          imageSrc={toastBuilderLanding}
          waitlist_title='Legal Services'
          imagePlaceholderSrc={toastBuilderLandingPlaceholder}
          ceremonyId={params?.ceremonyId}
          waitlist={waitlist}
          waitlistQuestions={waitlistQuestions}
          onJoinClick={joinWaitList}
        />
        {waitlistQuestions && (
          <WaitlistQuestions
            questions={waitlistQuestions}
            waitlist={waitlist}
            answerQuestions={answerQuestions}
            setQA={setQA}
            QA={QA}
          />
        )}
      </div>
    </div>
  );
};

export default LegalServiceGuidance;
