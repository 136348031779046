import checkIcon from '../../../assets/images/icons/Checkmark-green.svg'
import InfoIcon from '../../../components/v2/InfoIcon/InfoIcon';
import { Typography } from "../../../components/v2/Typography/Typography"

import './GiftCardImg.scss'

export type IncludeOptions = {
    title: string
    elevio?: string
}

type BundleIncludesProps = {
    includes: IncludeOptions[] | undefined
    classNames?: string
}

const BundleIncludes = (props: BundleIncludesProps) => {
    const { includes, classNames = ''} = props
    if (!includes) return <></>
    
    return (
        <>
     {
         includes.map( includeOption => {
            const containerClass = includeOption.elevio ? `mt-3 relative flex align-center max-w-max ` + includeOption.elevio : `mt-3 relative flex align-center max-w-max `
            return <div className={containerClass}>
            <img src={checkIcon} className='mx-5'></img>
            <Typography 
                variant='primary'
                type='body-400'
            >
                {includeOption.title}
            </Typography>
            {
                includeOption.elevio ? <InfoIcon className='ml-3'/> : null
            }
        </div>
        })
     }
    </>
        )
}

export default BundleIncludes