import { _request } from './request';

export type RitualType = {
  id: number;
  name: string;
};

export type GetRitualTypeResponse = {
  success: boolean;
  message: string;
  data: RitualType[];
};

export const getRitualTypes = async () => {
  const responseBody = await _request<GetRitualTypeResponse>({
    url: `ritualtypes`,
    method: 'GET',
  });
  return responseBody;
};
