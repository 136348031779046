/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState } from 'react';

type ActiveModuleContextType = {
  activeModule: string | null;
  setActiveModule: (value: string | null) => void;
};

export const ActiveModuleContext =
  React.createContext<ActiveModuleContextType>({
    activeModule: null,
    setActiveModule: (id: string | null) => {},
  });

export const ActiveModuleProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [activeModule, setId] = useState<string | null>(null);

  const setActiveModule = (id: string | null) => {
    setId(id);
  };

  const value = {
    activeModule,
    setActiveModule,
  };
  // @ts-ignore
  return (
    <ActiveModuleContext.Provider value={value}>
      {children}
    </ActiveModuleContext.Provider>
  );
};

export const useActiveModule = () => {
  return useContext(ActiveModuleContext);
};
