import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Ceremony } from '../../../../api/ceremony';
import Button from '../../../../components/v2/Button/Button';
import EmptyState from '../../../../components/v2/EmptyState/EmptyState';
import { Typography } from '../../../../components/v2/Typography/Typography';
import useUser from '../../../../hooks/useUser';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { useAuth } from '../../../../provider/authProvider';
import { useDashboard } from '../../../../provider/dashboardProvider';
import {
  isWeddingPlannerInCeremony,
  usePlanner,
} from '../../../../provider/plannerProvider';

import { ClientsListFilters } from './ClientsListFilters';
import './ClientsList.scss';
import { ClientsTable } from './ClientsTable';

import classNames from 'classnames';

export const ClientsList = () => {
  const { currentUser } = useDashboard();
  const [ceremoniesToShow, setCeremoniesToShow] = useState<Ceremony[]>();
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();

  const { user } = useAuth();

  const {
    viewWeddingSection,
    setViewWeddingSection,
    fetchWeddingPlannerStatus,
  } = usePlanner();

  const { userCeremonies, fetchCeremonies } = useUser();

  const filterOptions = [
    {
      text: 'All Weddings',
    },
    {
      text: 'Upcoming Weddings',
    },
    {
      text: 'Past Weddings',
    },
  ];

  const filterCeremony = (ceremony: Ceremony) => {
    if (viewWeddingSection === 'Upcoming Weddings') {
      return moment(ceremony.date).isAfter(moment()) || !ceremony.date;
    }

    if (viewWeddingSection === 'Past Weddings') {
      return moment(ceremony.date).isBefore(moment());
    }
    return ceremony;
  };

  useEffect(() => {
    if (userCeremonies && userCeremonies.length) {
      setCeremoniesToShow(
        userCeremonies.filter((ceremony) => {
          return (
            isWeddingPlannerInCeremony(user, ceremony) &&
            filterCeremony(ceremony)
          );
        })
      );
    }
  }, [viewWeddingSection, userCeremonies, currentUser, user]);

  const loadEmptyState = () => {
    return (
      <div className='emptyStateContainer'>
        <EmptyState
          title='You haven’t added any weddings yet'
          text='Manage and view all your clients’ weddings  from this dashboard.'
          buttonText='Add New Wedding'
          onClick={() => {
            navigate('/wp-add-ceremony');
          }}
        />
      </div>
    );
  };

  const loadClientsList = () => {
    return (
      <>
        <ClientsListFilters
          filterOptions={filterOptions}
          selectedFilter={viewWeddingSection}
          setSelectedFilter={setViewWeddingSection}
        />
        <ClientsTable
          clientsCeremonies={ceremoniesToShow || []}
          refetch={() => {
            void fetchCeremonies();
            void fetchWeddingPlannerStatus();
          }}
        />
      </>
    );
  };

  return (
    <div className='PlannerDashboard PlannerDashboard-root'>
      <div className='PlannerDashboard-section flex flex-col'>
        <div className='PlannerDashboard-section__header'>
          <Typography type='display-400'>Your Client List</Typography>
          <div>
            {userCeremonies && (
              <Button
                onClick={() => navigate('/wp-add-ceremony')}
                fullWidth={false}
                size='100'
                className={classNames({
                  'absolute top-2 right-1': !isMobile,
                })}
              >
                Add New Wedding
              </Button>
            )}
          </div>
          {!isMobile && (
            <Typography type='body-400'>
              Resources to enhance consultations & sales, to onboard clients,
              and to help them create impressive ceremonies, vows, and toasts.
            </Typography>
          )}
        </div>

        {!userCeremonies || userCeremonies.length == 0
          ? loadEmptyState()
          : loadClientsList()}
      </div>
    </div>
  );
};
