/* eslint-disable -- TODO: fix eslint errors */

import { useEffect, useState } from 'react';
import {
  Ceremony,
  CeremonyByIdResponse,
  getCeremonyById,
} from '../api/ceremony';
import { getModuleQuestions } from '../api/question';
import { getReaders, Reader } from '../api/readers';
import { getRituals, Ritual } from '../api/ritual';
import { Question } from '../pages/Modules/common/moduleQuestion/ModuleQuestion';
import { Module } from './useActiveModule';

const useReaders = () => {
  const [readers, setReaders] = useState<Reader[]>([]);

  const fetchReaders = async (ceremonyId: string) => {
    try {
      const response = await getReaders(ceremonyId);
      if (response.success) {
        const values = response.data.map((r) => {
          let tempReader: Reader = {
            id: r.member.id,
            legal_name: r.member.legal_name!,
            email: r.member.legal_name!,
            ceremony_id: r.ceremony_id,
            ritual_id: r.ritual_id?.split(','),
          };
          return tempReader;
        });
        setReaders(values);
      }
    } catch (error) {}
  };

  return {
    readers,
    fetchReaders,
  };
};

export default useReaders;
