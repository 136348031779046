import { Member } from '../../api/ceremony';
import {
  ChecklistItemDefinition,
  MemberChecklistItem,
} from '../../api/checklistItemDefinitions';

import { ChecklistItemIdentifier } from './identifiers';

const itemsThatCanBeMarkedComplete = [
  ChecklistItemIdentifier.FINALIZE_CEREMONY_SCRIPT,
  ChecklistItemIdentifier.REVIEW_SCRIPT_WITH_OFFICIANT,
  ChecklistItemIdentifier.REVIEW_SCRIPT_WITH_COUPLE,
  
  // Guest
  ChecklistItemIdentifier.REVIEW_TOAST_WITH_COUPLE,
];

export const getMarkCompleteOnClickHandler = (
  item: ChecklistItemDefinition,
  saveMemberChecklistItem: (
    memberChecklistItem: MemberChecklistItem
  ) => Promise<void>,
  ceremonyId?: number,
  currentUser?: Member
) => {
  if (
    itemsThatCanBeMarkedComplete.includes(
      item.identifier as ChecklistItemIdentifier
    )
  ) {
    return async () => {
      const newMemberChecklistItem: MemberChecklistItem = {
        ceremony_id: ceremonyId,
        member_id: currentUser?.id,
        checklist_item_definition_id: item.id,
        completed: true,
      };
      await saveMemberChecklistItem(newMemberChecklistItem);
    };
  } else {
    return undefined;
  }
};
