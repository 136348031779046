/* eslint-disable @typescript-eslint/no-empty-function */
import debounce from 'lodash.debounce';
import React, { useCallback, useContext, useState } from 'react';

import { saveAnswer } from '../../api/question';
import {
  Question,
  QuestionAnswer,
} from '../../pages/Modules/common/moduleQuestion/ModuleQuestion';
import { useCTA } from '../ctaProvider';

export type OptionsQuestionContextType = {
  text: string;
  setText: (value: string, isInitial?: boolean) => void;
  selectedOptionIds: string[];
  setSelectedOptionIds: (value: string[], isInitial?: boolean) => void;
};

export const OptionsQuestionContext =
  React.createContext<OptionsQuestionContextType>({
    text: '',
    setText: () => {},
    selectedOptionIds: [],
    setSelectedOptionIds: () => {},
  });

export const OptionsQuestionProvider = ({
  children,
  question,
  ceremonyId,
  onChange,
}: {
  children: React.ReactNode;
  question: Question;
  ceremonyId: number;
  onChange?: () => void;
}) => {
  const [textValue, setTextValue] = useState('');
  const [selectedOptionIdsValue, setSelectedOptionIdsValue] = useState<
    string[]
  >([]);
  const {
    handleQuestionSaved,
    handleQuestionSaving,
    handleQuestionUnsaved,
    handleQuestionAnswered,
    handleQuestionNotAnswered,
  } = useCTA();

  const debouncedSaveFunction = async (
    textValue: string,
    selectedOptionIdsValue: string[],
    ceremonyId: number,
    questionId: number
  ) => {
    const answer: QuestionAnswer = {
      ceremony_id: ceremonyId,
      question_id: question.id,
      text_answer: textValue,
      option_id: selectedOptionIdsValue.join(','),
    };
    try {
      handleQuestionSaving(questionId);
      const saved = await saveAnswer(answer);
      if (saved) {
        handleQuestionSaved(questionId);
        onChange && void onChange();
        if (answer.text_answer || answer.option_id) {
          handleQuestionAnswered(questionId);
        } else {
          handleQuestionNotAnswered(questionId);
        }
      } else {
        handleQuestionUnsaved(questionId);
      }
    } catch (error) {
      handleQuestionUnsaved(questionId);
    }
  };

  const debouncedCall = useCallback(
    debounce(
      (
        textValue: string,
        selectedOptionIds: string[],
        ceremony_id: number,
        question_id: number
      ) => {
        void debouncedSaveFunction(
          textValue,
          selectedOptionIds,
          ceremony_id,
          question_id
        );
      },
      2000
    ),
    [ceremonyId]
  );

  const setText = (value: string, isInitial?: boolean) => {
    setTextValue(value);
    if (isInitial && value) {
      handleQuestionAnswered(question.id);
    }
    if (!isInitial) {
      handleQuestionUnsaved(question.id);
      debouncedCall(value, selectedOptionIdsValue, ceremonyId, question.id);
    }
  };

  const setSelectedOptionIds = (optionIds: string[], isInitial?: boolean) => {
    setSelectedOptionIdsValue(optionIds);
    if (optionIds.length > 0) {
      handleQuestionAnswered(question.id);
    } else {
      handleQuestionNotAnswered(question.id);
    }
    if (!isInitial) {
      handleQuestionUnsaved(question.id);
      debouncedCall(textValue, optionIds, ceremonyId, question.id);
    }
  };

  const value = {
    text: textValue,
    setText,
    selectedOptionIds: selectedOptionIdsValue,
    setSelectedOptionIds,
  };
  // @ts-ignore
  return (
    <>
      <OptionsQuestionContext.Provider value={value}>
        {children}
      </OptionsQuestionContext.Provider>
    </>
  );
};

export const useOptionsQuestion = () => {
  return useContext(OptionsQuestionContext);
};
