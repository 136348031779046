/* eslint-disable */
import { CheckCircleOutline } from '@mui/icons-material';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { QuestionSelectValue } from '../typings';
import { SelectField } from '../../../../../components';
import { useDropdownQuestion } from '../../../../../provider/questionProviders/dropdownQuestionProvider';
import { Question } from '../../../../Modules/common/moduleQuestion/ModuleQuestion';
import { getOptionLabel } from '../helpers';
import { Member } from '../../../../../api/ceremony';

export type QuestionDropdownProps = {
  question: Question;
  members: {
    couple1: Member | undefined;
    couple2: Member | undefined;
    officiant: Member | undefined;
    currentUser: Member | undefined;
  };
  renderFooter?: () => React.ReactNode;
  selectedOptionIdsInitial: string[];
};

export const QuestionDropdown = (props: QuestionDropdownProps) => {
  const { question, members, renderFooter, selectedOptionIdsInitial } = props;

  const { selectedValues, setSelectedValues } = useDropdownQuestion();

  useEffect(() => {
    if (selectedOptionIdsInitial && selectedOptionIdsInitial.length > 0) {
      setSelectedValues(selectedOptionIdsInitial, true);
    }
  }, [selectedOptionIdsInitial]);

  return (
    <div className='flex flex-col gap-4'>
      <SelectField
        multiple
        fullWidth
        value={selectedValues}
        placeholder='Select an option'
        options={
          question.options
            ? //sort alphabetically
              question.options
                .sort((a, b) => a.option.localeCompare(b.option))
                .map((option) => {
                  const value = option.id.toString();
                  const label =
                    getOptionLabel(option, members) || String(value);
                  return { label, value, ...option };
                })
            : []
        }
        onChange={(selectedOption) => setSelectedValues(selectedOption as any)}
        renderValue={(selectedValue: any) => {
          if (selectedValue.length === 0) return 'Select an option';
          const selectedStringValue = (selectedValue as Array<string | number>)
            .map((value) =>
              question.options?.find((opt) => opt.id.toString() === value)
            )
            .filter(Boolean)
            .map((opt) =>
              opt ? getOptionLabel(opt, members) || opt?.option : ''
            )
            .join(', ');
          return selectedStringValue;
        }}
        getOptionDisabled={(option: any) => {
          const isSelected = selectedValues.includes(option.id.toString());
          return (
            !isSelected && selectedValues.length >= (question.max_options || 1)
          );
        }}
      />
      {typeof renderFooter === 'function' && (
        <div className='flex flex-col'>{renderFooter()}</div>
      )}
    </div>
  );
};
