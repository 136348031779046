import React from 'react';

import { Typography } from '../v2/Typography/Typography';

import './sideMenu.scss';

type SideMenuProps = {
  expanded: boolean;
  children: React.ReactNode;
  title: string;
};
export const SideMenu = (props: SideMenuProps) => {
  const { children, expanded, title } = props;
  return (
    <div className='SideMenu-root'>
      {expanded && (
        <Typography
          variant='functional-high'
          type='body-100'
          className='px-[19px] mb-2'
        >
          {title}
        </Typography>
      )}
      {children}
    </div>
  );
};
