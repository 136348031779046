import { useEffect, useRef, useState } from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { useNavigate } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';

import { outputModules } from '../../../api/output';
import { OrderSources } from '../../../api/paywall';
import clockSvg from '../../../assets/images/icons/clock.svg';
import { PaywallModal } from '../../../components/PaywallModal/PaywallModal';
import BlurOverlay from '../../../components/v2/BlurOverlay/BlurOverlay';
import Button from '../../../components/v2/Button/Button';
import { Typography } from '../../../components/v2/Typography/Typography';
import { calculateTime, getModuleTitle } from '../../../helpers/modulesHelper';
import { Module } from '../../../hooks/useActiveModule';
import { useOutput } from '../../../provider/outputProvider';
import { useAllQuestions } from '../../../provider/questionsProvider';

import ModuleLocked from './ModuleLocked';
import './mainContentModule.scss';

import { usePaywall } from '../../../provider/paywallProvider';

import lockBigPng from './lock-big.png';

import classNames from 'classnames';

type MainContentModuleProps = {
  module: outputModules;
  ceremonyId: string;
  readOnly?: boolean;
  onChange?: (ev: ContentEditableEvent) => void;
  insertText?: (module: outputModules, value: string) => void;
  showTitle?: boolean;
  showTimeEstimates?: boolean;
  fullHeight?: boolean;
  value?: string;
  setValue?: (val: string) => void;
  onVersionHistoryClick?: () => void;
  hasAccess?: boolean;
  paywallModalReferrer?: string;
  setQuestionnaireOpen?: (val: boolean) => void;
  moduleNumber?: number;
  renderExtraSection?: () => JSX.Element;
  disabled?: boolean;
  hideFooterButtons?: boolean;
  isPrivate?: boolean;
  createdByOfficiant?: boolean;
  showVowsPaywall?: boolean;
};
export const MainContentModule = (props: MainContentModuleProps) => {
  const {
    module,
    ceremonyId,
    value,
    setValue = () => {},
    readOnly,
    showTitle = true,
    showTimeEstimates = true,
    fullHeight = false,
    onVersionHistoryClick,
    hasAccess = true,
    paywallModalReferrer = '',
    setQuestionnaireOpen,
    moduleNumber,
    renderExtraSection,
    disabled = false,
    hideFooterButtons = false,
    isPrivate = false,
    createdByOfficiant = false,
    showVowsPaywall = false,
  } = props;

  const { activeModule, setActiveModule, setSaved } = useOutput();
  const { setSource } = usePaywall();
  const { ritualMinutes } = useAllQuestions();
  const [triggerRerender, setTriggerRerender] = useState(false);

  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);

  const navigate = useNavigate();

  const divRef = useRef<HTMLElement>(null);

  const sanitizeConf = {
    allowedTags: ['div', 'p', 'br', 'ul', 'ol', 'b', 'strong', 'i', 'em', 'li'],
    parseStyleAttributes: false,
    allowedAttributes: {
      '*': ['style'],
    },
  };

  useEffect(() => {
    if (activeModule === module && divRef.current) {
      divRef.current.focus();
      divRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeModule]);

  useEffect(() => {
    if (!value) {
      setTriggerRerender(!triggerRerender);
    }
  }, [value]);

  const renderTitle = (module: outputModules, moduleNumber?: number) => (
    <div className='flex flex-row'>
      {moduleNumber && (
        <div className='flex flex-row justify-center text-2xl w-[35px] font-recife italic'>
          {`${moduleNumber}. `}
        </div>
      )}
      {
        <div className='text-2xl font-recife italic'>
          {getModuleTitle(module)}
        </div>
      }
    </div>
  );

  const renderContentEditable = () => (
    <ContentEditable
      placeholder={
        getModuleTitle(module)
          ? `Start Typing the ${getModuleTitle(module)}`
          : ''
      }
      innerRef={divRef}
      style={{
        caretColor: '#995d30',
        outline: 'none',
        whiteSpace: 'pre-line',
        padding: '16px 0px 16px 35px',
        minHeight: fullHeight ? '75vh' : 0,
      }}
      disabled={readOnly || !value || value.length === 0}
      onChange={(ev) => {
        if (
          sanitizeHtml(value ? value : '', sanitizeConf) !==
            sanitizeHtml(ev.target.value) &&
          hasAccess
        ) {
          setValue(ev.target.value);
          setSaved(false);
          //debouncedCall(ev.target?.value, ceremonyId, module);
        }

        if (!hasAccess) {
          setIsPaywallModalOpen(true);
        }
      }}
      html={
        value
          ? value
          : showVowsPaywall
          ? 'Answer the mandatory questions in order to generate your vows.'
          : 'Complete this chapter to edit this section'
      }
    />
  );
  return (
    <>
      {hasAccess ? (
        <div
          onClick={() => {
            if (!readOnly && !isPrivate) {
              setActiveModule(module);
            }
          }}
          className={classNames(
            `${showTitle ? 'py-5' : ''} flex flex-col ${
              fullHeight ? 'min-h-screen80' : ''
            } ${activeModule === module && !readOnly ? 'cursor-text' : ''}`,
            { 'opacity-50': !value || value.length === 0 }
          )}
        >
          <div className='flex flex-row justify-between'>
            <div
              className={`font-recife text-2xl ${
                disabled ? 'text-forest-400' : ''
              }`}
            >
              {showTitle && renderTitle(module, moduleNumber)}
            </div>
            <div className='flex flex-row justify-center items-center'>
              {showTitle && showTimeEstimates && !disabled && (
                <>
                  <img alt='time' src={clockSvg} className='w-4 mr-2' />
                  <div>
                    {`${calculateTime(module, value || '', ritualMinutes)} min`}
                  </div>
                </>
              )}
            </div>
          </div>

          {showVowsPaywall && value && (
            <BlurOverlay
              deductBorder={true}
              offsetTop={true}
              overlayContent={
                <div className='flex flex-col items-center justify-center max-w-xs text-center'>
                  <img src={lockBigPng} />
                  <Typography type='display-50' className='mt-2'>
                    Premium Feature Locked
                  </Typography>
                  <Typography type='body-400' className='mt-2'>
                  Upgrade to access your draft, regenerate unlimited times, and make edits directly.
                  </Typography>
                  <Button
                    className='mt-4'
                    size='200'
                    variant='primary'
                    onClick={() => {
                      setSource(OrderSources.vb_editor);
                      navigate(`/pay-plans/${ceremonyId}?refferedFrom=openVows`);
                    }}
                  >
                    Unlock Vows
                  </Button>
                </div>
              }
            >
              {renderContentEditable()}
            </BlurOverlay>
          )}

          {showVowsPaywall && !value && renderContentEditable()}

          <div className='font-recife text-base font-thin'>
            {!disabled && !showVowsPaywall && (
              <>
                {isPrivate ? (
                  <BlurOverlay
                    overlayContent={
                      <div className='flex flex-col items-center justify-center max-w-xs text-center'>
                        <img src={lockBigPng} />
                        <Typography type='display-50' className='mt-2'>
                          This chapter is set to private
                        </Typography>
                        <Typography type='body-400' className='mt-2'>
                          {createdByOfficiant
                            ? 'Contact your officiant to change the privacy settings.'
                            : 'Contact the account owner to change the privacy settings.'}
                        </Typography>
                      </div>
                    }
                  >
                    {renderContentEditable()}
                  </BlurOverlay>
                ) : (
                  renderContentEditable()
                )}
              </>
            )}
          </div>

          {renderExtraSection && renderExtraSection()}
          {!hideFooterButtons && (
            <div className='flex flex-row justify-between mt-6 pl-[35px]'>
              {activeModule === module &&
                activeModule.indexOf('toast') < 0 &&
                activeModule !== outputModules.vows_ai &&
                !disabled &&
                !readOnly &&
                hasAccess && (
                  <Button
                    size='100'
                    onClick={() => {
                      if (setQuestionnaireOpen) {
                        setQuestionnaireOpen(true);
                      }
                    }}
                    variant='secondary'
                  >
                    Go to questions
                  </Button>
                )}
              {activeModule === module &&
                !disabled &&
                !readOnly &&
                onVersionHistoryClick && (
                  <Button
                    size='100'
                    onClick={onVersionHistoryClick}
                    variant='text'
                  >
                    Version History
                  </Button>
                )}
            </div>
          )}
        </div>
      ) : (
        <div>
          {showTitle && renderTitle(module, moduleNumber)}
          <div className='pl-[35px]'>
            <ModuleLocked
              onUpgradeClick={() => {
                setSource(OrderSources.cb_editor);
                setIsPaywallModalOpen(true);
              }}
            />
          </div>
        </div>
      )}
      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={() => setIsPaywallModalOpen(false)}
        type={'ceremony'}
        referrer={paywallModalReferrer}
        ceremonyId={parseInt(ceremonyId)}
        isCeremonyBuilder={true}
      />
    </>
  );
};
