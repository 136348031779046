/* eslint-disable -- TODO: fix eslint errors */

import { GoogleOAuthProvider } from '@react-oauth/google';
import { createContext, useContext } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { getMe, MeResponse, User } from '../api/auth';
import Heartbeat from '../components/Heartbeat';
import { useLocalStorage } from '../hooks/useLocalStorage';

interface AuthContextType {
  isAuthenticated: () => boolean;
  user: User;
  token: string;
  signin: (token: string, callback: VoidFunction) => Promise<void>;
  setUserData: (user: User) => void;
  signout: VoidFunction;
  getProfile: (callback: VoidFunction) => Promise<void>;
  useAuth: () => AuthContextType;
  RequireAuth: () => JSX.Element;
}

const AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useLocalStorage('PROVENANCE_USER', null);
  const [token, setToken] = useLocalStorage('PROVENANCE_TOKEN', null);

  const getProfile = async (callback: VoidFunction) => {
    const meResponse: MeResponse = await getMe();
    if (meResponse.success && meResponse.user) {
      callback();
      setUserData(meResponse.user);
    } else {
      throw Error('something went wrong');
    }
  };

  const signin = async (token: string, callback: VoidFunction) => {
    setToken(token);
    await getProfile(callback);
  };

  const signout = () => {
    setUser(null);
    setToken(null);
  };

  const setUserData = (newUser: User) => {
    // email and hash are prerequisites to load elevio
    if (
      Boolean(window?._elev) &&
      Boolean(newUser.hash) &&
      Boolean(newUser.email)
    ) {
      window._elev.on('load', function (_elev: Window['_elev']) {
        _elev.setUser({
          first_name: newUser.first_name,
          last_name: newUser.last_name || '',
          email: newUser.email,
          user_hash: newUser.hash,
          registered_at: new Date(newUser.createdAt).getTime(),
        });
      });
    }
    setUser(JSON.stringify(newUser));
  };

  const isAuthenticated = () => {
    return token ? true : false;
  };

  const value = {
    user: JSON.parse(user),
    isAuthenticated,
    token,
    signin,
    signout,
    setUserData,
    getProfile,
    useAuth,
    RequireAuth,
  };

  if (!process.env.REACT_APP_OAUTH2_GOOGLE_PROVIDE_CLIENT_ID){
    console.log('You cannot use <GoogleOAuthProvider.../> without correctly setting it up.')
  }
  
  const googleOAuthProvider = process.env.REACT_APP_OAUTH2_GOOGLE_PROVIDE_CLIENT_ID ? (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_OAUTH2_GOOGLE_PROVIDE_CLIENT_ID}
    >
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    </GoogleOAuthProvider>
  ) : (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );

  return (
    googleOAuthProvider
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const RequireAuth = () => {
  const auth = useAuth();
  const location = useLocation();
  const url = location.pathname;
  const params = location.search;

  if (!auth.isAuthenticated() && !auth.user) {
    return <Navigate to='/signin' state={{ redirect: params ? url + params : url }} />;
  }

  // if (
  //   auth.user &&
  //   !auth.user.onboarding_completed &&
  //   location.pathname.indexOf('/onboard') < 0
  // ) {
  //   return <Navigate to='/onboard' state={{ from: location }} />;
  // }

  //return <Navigate to='/dashboard' state={{ from: location }} />;
  return (
    <Heartbeat>
      <Outlet />
    </Heartbeat>
  );
};

export const NonAuth = () => {
  const auth = useAuth();
  const location = useLocation();
  if (auth.isAuthenticated() && auth.user) {
    return <Navigate to='/dashboard' state={{ from: location }} />;
  }
  return <Outlet />;
};
