import classNames from 'classnames';

import ForestCheckmark from '../../../assets/images/svg/forest-checkmark.svg';
import Button from '../Button/Button';
import { Typography } from '../Typography/Typography';

import GenericCard from './GenericCard';

type AddonSingleSelectCardTitleProps = {
  icon: string;
  info: string;
};

type AddonSingleSelectCardProps = {
  title: string;
  titleProps?: AddonSingleSelectCardTitleProps;
  price: string | number;
  description?: string;
  attributes?: string[];
  buttonTitle: string;
  onSelect: () => void;
};

const AddonSingleSelectCard = ({
  title,
  titleProps,
  price,
  description,
  attributes,
  buttonTitle = 'Purchase This Service',
  onSelect,
}: AddonSingleSelectCardProps) => {
  return (
    <GenericCard>
      <div className={classNames('flex flex-col')}>
        <div className={classNames('flex flex-row')}>
          <Typography type='display-50' variant='primary'>
            {title}
          </Typography>
          {titleProps && titleProps.icon && (
            <img src={titleProps?.icon} alt='addon-icon' />
          )}
        </div>
        <Typography type='display-600' variant='primary' className='mb-4'>
          <>${price.toString()}</>
        </Typography>
        {description && (
          <Typography type='body-400'  variant='functional-high' className='mb-2'>
            {description}
          </Typography>
        )}
        {attributes?.map((attribute, index) => {
          return (
            <Typography key={index} type='body-400' variant='primary'>
              <div className='flex flex-row gap-1 items-center'>
                <img
                  height={'auto'}
                  width={16}
                  src={ForestCheckmark}
                  alt='checkmark'
                />{' '}
                <span>{attribute}</span>
              </div>
            </Typography>
          );
        })}
        <Button 
            variant='primary'
            size='100'
            onClick={onSelect}
            fullWidth
            className='mt-4'
        >
            {buttonTitle}
        </Button>
      </div>
    </GenericCard>
  );
};

export default AddonSingleSelectCard;
