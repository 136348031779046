
export const introJsSteps = [
    {
      element: '.CeremonyDetailsCard-root',
      tooltipClass: 'customTooltip customTooltipProvenance firstTooltip',
      title: 'Manage ceremony details',
      intro:
        "We know plans can change, so here is where you can make updates to your event settings (or create a new event if you are speaking at another wedding).",
      position: 'right'
    },
    {
      element: '.Collaborators-root',
      tooltipClass: 'customTooltip customTooltipProvenance',
      title: 'Include Key Participants',
      intro:
        "Provenance's tools are best when used together! Add in key participants so that you all can collaborate across our entire suite of tools. Then, you can check back here to see if they have accepted their invites (and give them an extra nudge if they haven't yet).",
      position: 'right',
    },
    {
      element: '.SideMenu-root',
      tooltipClass: 'customTooltip customTooltipProvenance',
      title: 'Explore our offerings',
      intro:
        "Here you will find everything you need to craft your ceremony script, vows, and toasts.",
      position: 'right',
    },
    {
      element: '.CeremonyChecklist-root',
      tooltipClass: 'customTooltip customTooltipProvenance',
      title: 'Track your progress',
      intro:
        'Stay organized with your Provenance Checklist that will help make sure you are all set with these important moments for your big day.',
    },
  ];
