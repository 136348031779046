/* eslint-disable -- TODO: fix eslint errors */

import 'react-responsive-carousel/lib/styles/carousel.min.css';

import CheckMark from '../../assets/images/icons/Checkmark.svg';
import InfoCircle from '../../assets/images/icons/InfoCircle.svg';
import CustomTextInput from '../../components/forms/CustomTextInput';

import './index.scss';

import { Title } from '../../components/titles';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Container from '../../components/Container';
import Button from '../../components/button';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  NewPaymentBody,
  NewPaymentRequestBody,
  newpaymentSession,
  OrderType,
  PaymentMethod,
  PayOption,
  PayOrderBody,
  PayOrderResponse,
  placeNewOrder,
  sendPaymentRequest,
} from '../../api/paywall';
import useCeremony from '../../hooks/useCeremony';
import Loader from '../../components/Loader/loader';
import SnackbarComponent from '../../components/Snackbar/Snackbar';
import useLoader from '../../hooks/useLoader';
import { useSnackbar } from '../../hooks/useSnackbar';
import { MemberType } from '../../api/ceremony';
import { getAPIErrorMessage } from '../../helpers/helper';
import { usePaywall } from '../../provider/paywallProvider';

type payeeDetailType = {
  name: string;
  email: string;
};

interface LocationState {
  plan: PayOption;
  purchaseType: 'u' | 'n';
  purchasePrice: number;
}

const PayDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { plan, purchaseType, purchasePrice } =
    (location.state as LocationState) || {};
  const {
    fetchCeremonyPlanById,
    fetchCeremonyById,
    ceremonyPlan,
    currentRole,
  } = useCeremony();
  const [error, setError] = useState<string>('');
  const { isShowing, showLoader, hideLoader } = useLoader();
  const { isActive, message, type, openSnackBar, handleClose } = useSnackbar();
  const [coupon, setCoupon] = useState<string>('');
  const [couponApplied, setCouponAplied] = useState<boolean>(false);
  const [payee, setPayee] = useState<string>('me');
  const [payeeDetail, setPayeeDetail] = useState<payeeDetailType>({
    name: '',
    email: '',
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment

  const { source } = usePaywall();

  const locallyHandlepayeeeChange = (who: string) => {
    setPayee(who);
    setError('');
  };

  useEffect(() => {
    if (!plan) {
      navigate(`/pay-plans/${params.ceremonyid || ''}`);
    } else {
      fetchCeremonyPlanById(params.ceremonyid || '');
      fetchCeremonyById(params.ceremonyid || '');
    }
  }, [plan]);

  useEffect(() => {
    if (purchaseType === 'n' && ceremonyPlan && ceremonyPlan.value > 0) {
      showLoader();
      openSnackBar(
        'Something went wrong in payment. Try contacting to provenance team.',
        5000,
        'error'
      );
      setTimeout(() => {
        navigate(`/pay-plans/${params.ceremonyid || ''}`);
        hideLoader();
      }, 3000);
    }
  }, [ceremonyPlan]);

  useEffect(() => {
    showLoader();
    void placeOrder();
  }, []);

  const _handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: 'name' | 'email'
  ) => {
    const newPayee = { ...payeeDetail };

    // @ts-ignore
    newPayee[name] = e.target.value;

    setPayeeDetail(newPayee);
  };
  const _handleCoupon = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCoupon(e.target.value);
  };

  const placeOrder = async () => {
    showLoader();
    try {
      const reqBody: PayOrderBody = {
        ceremony_id: params.ceremonyid || '',
        payment_method: PaymentMethod.card,
        type: OrderType.ceremony_designer,
        product_id: plan.id,
        value: purchasePrice,
        currency: params.selectedCurrency || 'USD',
        source: source,
        payment_type: 'one_time_payment',
      };

      const orderResponse: PayOrderResponse = await placeNewOrder(reqBody);
      if (orderResponse.success) {
        if (payee === 'me') {
          if (purchasePrice > 0) {
            await createPaymentSession(orderResponse.data.ref);
          } else {
            navigate(`/pay-status/${params.ceremonyid ?? ''}`, {
              state: {
                plan: plan,
                payee: payee,
                payeeDetail: payeeDetail,
                success: true,
              },
            });
          }
        } else {
          // write email sending logic here
          const payReqBody: NewPaymentRequestBody = {
            ref: orderResponse.data.ref,
            payee: payee,
            payeeDetail: payeeDetail,
          };
          const payReqResponse = await sendPaymentRequest(payReqBody);
          if (payReqResponse.success) {
            navigate(`/pay-status/${params.ceremonyid ?? ''}`, {
              state: {
                plan: plan,
                payee: payee,
                payeeDetail: payeeDetail,
                success: true,
              },
            });
          } else {
            navigate(`/pay-status/${params.ceremonyid ?? ''}`, {
              state: {
                plan: plan,
                payee: payee,
                payeeDetail: payeeDetail,
                success: false,
              },
            });
          }
        }
      } else {
        openSnackBar(orderResponse.message, 5000, 'error');
        navigate(`/pay-status/${params.ceremonyid ?? ''}`, {
          state: {
            plan: plan,
            payee: payee,
            payeeDetail: payeeDetail,
            success: false,
          },
        });
      }
    } catch (err) {
      openSnackBar(getAPIErrorMessage(err as any), 5000, 'error');
    } finally {
      hideLoader();
    }
  };

  const createPaymentSession = async (ref: string) => {
    try {
      const payBody: NewPaymentBody = {
        pid: plan.id,
        ref: ref,
        currency: params.selectedCurrency || 'USD',
      };
      const sessionResponse: PayOrderResponse = await newpaymentSession(
        payBody
      );
      if (sessionResponse.success) {
        window.location.href = sessionResponse.message;
        return;
      } else {
        openSnackBar(sessionResponse.message, 5000, 'error');
        navigate(`/pay-status/${params.ceremonyid ?? ''}`, {
          state: {
            plan: plan,
            payee: payee,
            payeeDetail: payeeDetail,
            success: false,
          },
        });
      }
    } catch (err) {
      openSnackBar(getAPIErrorMessage(err as any), 5000, 'error');
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Loader isShowing={isShowing} hide={hideLoader} allowHide={false} />
      <SnackbarComponent
        isActive={isActive}
        message={message}
        type={type}
        handleClose={handleClose}
      />
      {plan && (
        <div className='bg-containerbg min-h-4/5 flex flex-col space-between items-center justify-center overflow-x-hidden pb-10 hidden'>
          <Title
            className='text-copper-primary font-sans uppercase mt-8'
            h={2}
            text='Ceremony Builder'
          />
          <Title
            className='text-forest-primary font-nanum font-bold'
            h={1}
            text='Payment details'
          />

          <Container className='content-center place-content-center mt-4 auto-rows-fr'>
            <div className='hidden md:block  md:col-span-1 lg:col-span-3'></div>
            <div className='col-span-4 md:col-span-4 lg:col-span-6'>
              <div className='border border-forest-100 p-8 bg-white'>
                <div className='text-forest-primary font-nanum font-bold text-2xl py-4'>
                  Who will be paying
                </div>
                <FormControl>
                  <RadioGroup name='chosenPayee'>
                    <FormControlLabel
                      onChange={() => {
                        locallyHandlepayeeeChange('me');
                      }}
                      className={'py-2'}
                      value={'me'}
                      control={<Radio />}
                      checked={payee === 'me'}
                      label={
                        <span className='text-[18px] capitalize'>
                          I will Pay
                        </span>
                      }
                    />
                    {currentRole && (
                      <FormControlLabel
                        onChange={() => {
                          locallyHandlepayeeeChange(
                            currentRole === MemberType.officiant
                              ? 'couple'
                              : 'officiant'
                          );
                        }}
                        className={'py-2'}
                        value={
                          currentRole === MemberType.officiant
                            ? 'couple'
                            : 'officiant'
                        }
                        control={<Radio />}
                        checked={payee === 'couple' || payee === 'officiant'}
                        label={
                          <span className='text-[18px] capitalize'>
                            {currentRole === MemberType.officiant
                              ? 'The couple will pay'
                              : 'The officiant will pay'}
                          </span>
                        }
                      />
                    )}
                    <FormControlLabel
                      onChange={() => {
                        locallyHandlepayeeeChange('else');
                      }}
                      className={'py-2'}
                      value={'else'}
                      control={<Radio />}
                      checked={payee === 'else'}
                      label={
                        <span className='text-[18px] capitalize'>
                          Someone else will pay...
                        </span>
                      }
                    />
                  </RadioGroup>
                  {error.length > 0 && (
                    <FormHelperText className='text-red-600'>
                      {error}
                    </FormHelperText>
                  )}
                </FormControl>
                {payee === 'else' && (
                  <div className='pl-8 grid grid-cols-12 gap-x-4'>
                    <div className='col-span-5'>
                      <InputLabel className='font-inter text-forest-light text-sm pl-2'>
                        Their Name
                      </InputLabel>
                      <CustomTextInput
                        name='name'
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => _handleChange(e, 'name')}
                        type={'text'}
                        value={payeeDetail.name}
                        size={200}
                      />
                    </div>

                    <div className='col-span-5'>
                      <InputLabel className='font-inter text-forest-light text-sm pl-2'>
                        Their Email
                      </InputLabel>
                      <CustomTextInput
                        name='email'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          _handleChange(e, 'email')
                        }
                        type={'text'}
                        value={payeeDetail.email}
                        size={200}
                      />
                    </div>
                    <p className='col-span-10 pl-2 font-inter text-[14px] text-forest-primary'>
                      We will send a payment link to this email. You will get
                      access to the Ceremony Builder modules once the payment
                      goes through.
                    </p>
                  </div>
                )}
                <hr className='mt-6' />
                {/* <div className='flex flex-row w-full mt-6'>
              <CustomTextInput
                name='coupon'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  _handleCoupon(e)
                }
                placeholder={'Gift card or discount code'}
                type={'text'}
                disabled={couponApplied}
                value={coupon}
              />
              <button
                onClick={() => {
                  setCouponAplied(!couponApplied);
                }}
                className={`mt-2 px-2 md:px-3 lg:px-4 xl:px-6 2xl:px-8 
      py-2 md:py-2 lg:py-3 xl:py-4 2xl:py-4 w-60 h-14
      uppercase text-base flex justify-center cursor-pointer mx-3 text-white bg-forest-primary`}
              >
                {couponApplied ? 'Remove Code' : 'Apply Code'}
              </button>
            </div> */}
                <div className='grid grid-cols-9 mt-8 gap-x-10'>
                  <div className='col-span-3 font-inter text-forest-light text-sm py-2'>
                    Total:
                  </div>

                  <div
                    className={`col-span-6 text-copper-primary font-nanum text-[20px] font-bold text-right`}
                  >
                    $ {purchasePrice === 0 ? 'Free' : purchasePrice}
                  </div>
                  <div className='col-span-3 font-inter text-forest-light text-sm py-2'>
                    You are paying:
                  </div>

                  <div
                    className={`col-span-6 text-copper-primary font-nanum text-[20px] font-bold text-right`}
                  >
                    $ {payee === 'me' ? purchasePrice : 0}
                  </div>
                </div>
              </div>
            </div>
          </Container>
          <Button
            // title={'View Summary'}
            title={'Confirm'}
            onClick={async () => {
              await placeOrder();
            }}
            // onClick={() => {
            //   navigate(`/pay-summary/${params.ceremonyid ?? ''}`, {
            //     state: {
            //       plan: plan,
            //       payee: payee,
            //       payeeDetail: payeeDetail,
            //       purchaseType: purchaseType,
            //       purchasePrice: purchasePrice,
            //     },
            //   });
            // }}
            className={`mt-6 px-2 md:px-3 lg:px-4 xl:px-6 2xl:px-8 
            py-2 md:py-2 lg:py-3 xl:py-3 2xl:py-4 
            min-w-b1 md:min-w-b1 lg:min-w-b2 xl:min-w-b3 2xl:min-w-b4
            uppercase text-base flex justify-center cursor-pointer mx-3 text-white bg-forest-primary`}
          />
        </div>
      )}
    </>
  );
};

export default PayDetails;
