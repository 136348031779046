import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';

import { Ceremony, Member } from '../../../../api/ceremony';
import { saveAnswer } from '../../../../api/question';
import QuestionSaveStatus from '../../../../components/QuestionSave';
import Button from '../../../../components/button';
import CustomTextInput from '../../../../components/forms/CustomTextInput';
import { replacePlaceholders } from '../../../../helpers/placeholderHelper';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { useActiveQuestion } from '../../../../provider/activeQuestionProvider';
import { Tip } from '../tip/Tip';

import { Question, QuestionAnswer } from './ModuleQuestion';
import './moduleQuestion.scss';

type QuestionProps = {
  question: Question;
  setQuestionChanged: (value: boolean) => void;
  couple1?: Member;
  couple2?: Member;
  officiant?: Member;
  currentUser?: Member;
  ceremony?: Ceremony;
  showTip?: boolean;
  readOnly?: boolean;
  showQuestionText?: boolean;
  newReflectionQuestions?: boolean;
  numerotator?: number;
  drawerQuestion?: boolean;
  asterisk?: boolean;
};

export const TextQuestion = (props: QuestionProps) => {
  const { openSnackBar } = useSnackbar();

  const {
    question,
    ceremony,
    couple1,
    showTip = true,
    couple2,
    officiant,
    currentUser,
    readOnly,
    setQuestionChanged,
    showQuestionText,
    newReflectionQuestions = false,
    numerotator = 0,
    drawerQuestion,
    asterisk
  } = props;
  const [title, setTitle] = useState<string>('Save Changes');
  const [changed, setChanged] = useState<boolean>(false);
  const [tipExpanded, setTipExpanded] = useState<number>(0);
  const [textAnswer, setTextAnswer] = useState<string>();
  const { activeQuestionId, setActiveQuestionId } = useActiveQuestion();

  const remarksModules = ['remarks', 'couple1', 'couple2'];

  useEffect(() => {
    if (question && question.answers) {
      const answer = question.answers[0];
      if (answer) {
        setTextAnswer(answer.text_answer);
      }
    }
  }, [question]);

  const handleTextChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(true);
    setTitle('Save Changes');
    setTextAnswer(ev.target.value);
  };

  const handleSave = async (
    question: Question,
    ceremony?: Ceremony,
    textAnswer?: string
  ) => {
    if (ceremony) {
      const answer: QuestionAnswer = {
        ceremony_id: ceremony.id,
        question_id: question.id,
        text_answer: textAnswer,
      };
      
      setTitle('Saving');
      try {
        const saveResponse = await saveAnswer(answer);
        if (saveResponse.success) {
          setTitle('Saved');
          setChanged(false);
          setQuestionChanged(true);
        } else {
          openSnackBar(saveResponse.message, 5000, 'error');
          setTitle('Save Changes');
        }
      } catch (error) {
        openSnackBar('Unexpected error', 5000, 'error');
        setTitle('Save Changes');
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useCallback(
    debounce((question: Question, ceremony?: Ceremony, textAnswer?: string) => {
      void handleSave(question, ceremony, textAnswer);
    }, 2000),
    []
  );

  useEffect(() => {
    if (changed === true) {
      debouncedCall(question, ceremony, textAnswer);
    }
  }, [question, ceremony, textAnswer, changed, debouncedCall]);

  useEffect(() => {
    if (activeQuestionId && activeQuestionId !== question.id) {
      setTipExpanded(0);
    } else if (activeQuestionId && activeQuestionId === question.id) {
      setTipExpanded(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuestionId]);

  return (
    <div
      className={
        drawerQuestion
          ? ''
          : 'col-span-4 md:col-span-6 lg:col-span-12 grid grid-cols-4 md:grid-cols-6 lg:grid-cols-12'
      }
    >
      <div
        onClick={() => setActiveQuestionId(question.id)}
        className={
          drawerQuestion
            ? ''
            : `question-container px-4 md:px-11 col-span-4 md:col-span-6 ${
                !newReflectionQuestions
                  ? 'lg:col-span-9 border py-6 mb-8'
                  : 'lg:col-span-12 py-1 mb-2'
              }`
        }
      >
        {showQuestionText && (
          <div
            className={` my-3 font-nanum ${
              drawerQuestion ? 'text-xl' : 'text-2xl'
            } leading-8 ${newReflectionQuestions ? 'font-bold' : 'mx-2'}`}
          >
            {numerotator > 0 ? `${numerotator}. ` : ''}
            {replacePlaceholders(
              question.question,
              couple1?.preferred_name || couple1?.legal_name,
              couple2?.preferred_name || couple2?.legal_name,
              officiant?.preferred_name || officiant?.legal_name,
              currentUser?.preferred_name || currentUser?.legal_name
            )}

            { asterisk ? <span className='ml-1' style={{color: '#995D30'}}>*</span> : null }
          </div>
        )}
        {question.has_textfield && (
          <CustomTextInput
            className='mt-4 mx-0'
            multiline
            rows={5}
            value={textAnswer}
            onChange={handleTextChange}
            placeholder={question.textfield_placeholder}
            readOnly={readOnly}
          />
        )}

        {textAnswer && (
          <QuestionSaveStatus
            disabled={!changed}
            title={title}
            onClick={() => handleSave(question, ceremony, textAnswer)}
          ></QuestionSaveStatus>
        )}
      </div>
      {!newReflectionQuestions && (
        <Tip
          text={replacePlaceholders(
            question.help_short_text,
            couple1?.preferred_name || couple1?.legal_name,
            couple2?.preferred_name || couple2?.legal_name,
            officiant?.preferred_name || officiant?.legal_name,
            currentUser?.preferred_name || currentUser?.legal_name
          )}
          id={1}
          expanded={tipExpanded === 1}
          setExpanded={(id: number) => setTipExpanded(id)}
          link={question.help_detail_text}
          className={'w-72 ml-5 bg-forest-primary'}
        />
      )}
    </div>
  );
};
