import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';

import checklistSvg from '../../../components/PageWrapper/images/checklist.svg';
import Button from '../Button/Button';
import circularProgressBar from '../CircularProgressBar/CircularProgressBar';
import { Modal } from '../Modal/Modal';
import { Typography } from '../Typography/Typography';

type DraftingModalProps = {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  textGenerateComplete: boolean;
  textGenerating: boolean;
  aiGeneratedTextIndex: number;
  titleDrafting: string;
  descriptionDrafting: string;
  titlePopulating: string;
  descriptionPopulating: string;
  titleComplete: string;
  descriptionComplete: string;
  buttonTextComplete: string;
  generationError?: boolean;
};

export const DraftingModal = (props: DraftingModalProps) => {
  const {
    modalOpen,
    setModalOpen,
    textGenerateComplete,
    textGenerating,
    aiGeneratedTextIndex,
    titleDrafting,
    descriptionDrafting,
    titlePopulating,
    descriptionPopulating,
    titleComplete,
    descriptionComplete,
    buttonTextComplete,
    generationError,
  } = props;

  const [progress, setProgress] = React.useState(0);
  const [secondProgress, setSecondProgress] = React.useState(92);
  const [step, setStep] = React.useState(1);

  let timer: NodeJS.Timeout;
  let timer2: NodeJS.Timeout;

  useEffect(() => {
    setProgress(0);
    setStep(1);
    setSecondProgress(92);
    clearInterval(timer);
    clearInterval(timer2);
    timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 92 ? 92 : prevProgress + 1
      );
    }, 300);

    return () => {
      clearInterval(timer);
    };
  }, [modalOpen]);

  useEffect(() => {
    if (aiGeneratedTextIndex > 0) {
      setStep(2);
    }
  }, [aiGeneratedTextIndex]);

  useEffect(() => {
    if (step === 2) {
      setProgress(90);
      timer2 = setInterval(() => {
        setSecondProgress((prevProgress) =>
          prevProgress >= 99 ? 99 : prevProgress + 1
        );
      }, 500);
    }

    return () => {
      clearInterval(timer2);
    };
  }, [step]);

  return (
    <Modal
      isOpen={modalOpen}
      // isOpen={true}
      onClose={() => setModalOpen(false)}
      showCloseButton={textGenerateComplete}
      title={''}
    >
      <div className='max-w-md text-center flex flex-col'>
        {!generationError && (
          <>
            {textGenerating && aiGeneratedTextIndex === 0 && (
              <>
                <div className='mr-4 w-[88px] mb-6 self-center'>
                  {circularProgressBar(progress, 100, true)}
                </div>
                <Typography type='display-100'>{titleDrafting}</Typography>
                <Typography type='body-400' className='mt-4'>
                  {descriptionDrafting}
                </Typography>
              </>
            )}
            {textGenerating && aiGeneratedTextIndex > 0 && (
              <>
                <div className='mr-4 w-[88px] mb-6 self-center'>
                  {circularProgressBar(secondProgress, 100, true)}
                </div>
                <Typography type='display-100'>{titlePopulating}</Typography>
                <Typography type='body-400' className='mt-4'>
                  {descriptionPopulating}
                </Typography>
              </>
            )}
            {!textGenerating && textGenerateComplete && (
              <>
                <div className='mr-4 w-[88px] mb-6 self-center'>
                  {circularProgressBar(100, 100, true)}
                </div>
                <Typography type='display-100'>{titleComplete}</Typography>
                <Typography type='body-400' className='mt-4'>
                  {descriptionComplete}
                </Typography>
                <Button className='mt-8' onClick={() => setModalOpen(false)}>
                  {buttonTextComplete}
                </Button>
              </>
            )}
          </>
        )}

        {generationError && (
          <>
            <div className='mr-4 w-[88px] mb-6 self-center'>
              {circularProgressBar(0, 0, true, true)}
            </div>
            <Typography type='display-100'> Something went wrong</Typography>
            <Typography type='body-400' className='mt-4'>
              We weren’t able to generate your toast at this time. You can try
              to generate your toast again, or come back later.
            </Typography>
            <div className='flex justify-center gap-10'>
              <Button
                variant='secondary'
                className='mt-8'
                onClick={() => setModalOpen(false)}
              >
                Close
              </Button>
              {/* <Button className='mt-8' onClick={() => setModalOpen(false)}>
                Try again
              </Button> */}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
