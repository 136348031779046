import { useEffect, useState } from 'react';

import { Typography } from '../../../components/v2/Typography/Typography';
import { usePlanner } from '../../../provider/plannerProvider';
import InfoIcon from '../InfoIcon/InfoIcon';
import ProgressBar from '../ProgressBar/ProgressBar';

import './ActiveWeddingsCounter.scss';

const ActiveWeddingsCounter = () => {
  const {
    weddingPlannerStatus,
    fetchWeddingPlannerStatus,
  } = usePlanner();

  const [activeWeddings, setActiveWeddings] = useState<number>(0);
  const [totalWeddings, setTotalWeddings] = useState<number>(0);
  const [hasActiveSubscription, setHasActiveSubscription] =
    useState<boolean>(false);

  useEffect(() => {
    if (weddingPlannerStatus) {
      setActiveWeddings(weddingPlannerStatus.activatedWeddings);
      setTotalWeddings(weddingPlannerStatus.maxWeddings);
      setHasActiveSubscription(weddingPlannerStatus.hasActiveSubscription);
    }
  }, [weddingPlannerStatus]);

  useEffect(() => {
    if (!weddingPlannerStatus) {
      fetchWeddingPlannerStatus();
    }
  }, []);

  const redirectToWPInfoPage = () => {
    window.open('https://provenance.elevio.help/en/articles/63-how-does-provenance-pro-for-wedding-planners-work', '_blank');
  }

  return hasActiveSubscription &&
    activeWeddings !== undefined &&
    totalWeddings !== undefined ? (
    <div className='activeWeddingsCounterContainer introJSWP_step3'>
      <Typography type='display-100'>
        {activeWeddings.toString() + ' / ' + totalWeddings.toString()}
      </Typography>
      <Typography type='body-200' variant='functional-low' className='mt-1 mb-3 flex items-center'>
        <>
        Active Weddings 
        <span className='ml-2' onClick={redirectToWPInfoPage}>
          <InfoIcon className='pro-benefit cursor-pointer inline' />
        </span>
        </>
      </Typography>
      <ProgressBar progress={activeWeddings} total={totalWeddings} />
    </div>
  ) : null
  ;
};

export default ActiveWeddingsCounter;
