/* eslint-disable -- TODO: fix eslint errors */

import { useEffect, useState } from 'react';
import {
  Ceremony,
  CeremonyByIdResponse,
  CeremonyPlanResponse,
  getCeremonyAccessibleModules,
  getCeremonyById,
  Member,
  MemberType,
  PricingOption,
} from '../api/ceremony';
import {
  CeremonySpeaker,
  getSpeakingEvents,
  SpeakingEventsResponse,
} from '../api/output';
import { Order } from '../api/paywall';
import { useAuth } from '../provider/authProvider';
import { User } from '../api/auth';

type UseCeremonyProps = {
  ceremonyId?: number | string;
};

const useCeremony = (props?: UseCeremonyProps) => {
  const [ceremony, setCeremony] = useState<Ceremony>();
  const [ceremonyPlan, setCeremonyPlan] = useState<Order>();
  const [ceremonyAccessibleModules, setCeremonyAccessibleModules] =
    useState<PricingOption>();
  const [couple1, setCouple1] = useState<Member>();
  const [couple2, setCouple2] = useState<Member>();
  const [officiant, setOfficiant] = useState<Member>();
  const [currentUser, setCurrentUser] = useState<Member>();
  const [weddingPlanner, setWeddingPlanner] = useState<Member>();
  const [currentRole, setCurrentRole] = useState<MemberType>();
  const [speakingEvents, setSpeakingEvents] = useState<CeremonySpeaker[]>([]);
  const auth = useAuth();

  let coupleNamesString = '';
  if (couple1 && couple2 && couple2.legal_name !== 'TBD TBD') {
    coupleNamesString = `${couple1?.preferred_name} & ${couple2?.preferred_name}`;
  } else if (couple1) {
    coupleNamesString = couple1?.preferred_name;
  } else if (couple2) {
    coupleNamesString = couple2?.preferred_name;
  }

  const getSpeakingCeremonies = async (ceremonyId: string) => {
    //
    setSpeakingEvents([]);
    try {
      const eventsResponse: SpeakingEventsResponse = await getSpeakingEvents(
        ceremonyId
      );
      if (eventsResponse.success) {
        setSpeakingEvents(eventsResponse.data);
      }
    } catch (err) {}
  };

  const fetchCeremonyById = async (ceremonyId: string) => {
    setCouple1(undefined);
    setCouple2(undefined);
    setOfficiant(undefined);
    setCurrentUser(undefined);
    setWeddingPlanner(undefined);
    setCurrentRole(undefined);
    try {
      const ceremonyByIdResponse: CeremonyByIdResponse = await getCeremonyById(
        ceremonyId
      );
      if (ceremonyByIdResponse.success) {
        setCeremony(ceremonyByIdResponse.data);
        const { members } = ceremonyByIdResponse.data;
        const coupleMembers = members!
          .filter(
            (m) =>
              m.member_type === MemberType.bride ||
              m.member_type === MemberType.groom ||
              m.member_type === MemberType.other
          )
          .sort((a, b) => (a.id && b.id && a.id < b.id ? -1 : 1));
        setCouple1(coupleMembers[0]);
        setCouple2(coupleMembers[1]);

        let officiantMembers = members!.filter(
          (m) => m.member_type === MemberType.officiant
        );
        if (officiantMembers && officiantMembers.length > 0) {
          setOfficiant(officiantMembers[0]);
        }
        let weddingPlannerMember = members!.find(
          (m) => m.member_type === MemberType.weddingPlanner
        );
        if (weddingPlannerMember) {
          setWeddingPlanner(weddingPlannerMember);
        }

        members!.forEach((member) => {
          if (
            auth.user.id === member.user_id &&
            member.member_type !== MemberType.reader
          ) {
            setCurrentUser(member);
            if (member.member_type === MemberType.officiant) {
              setOfficiant(member);
            }
            setCurrentRole(member.member_type);
            if (member.member_type === MemberType.guests) {
              getSpeakingCeremonies(ceremonyId);
            }
          }
        });
      }
    } catch (error) {}
  };

  const fetchCeremonyPlanById = async (ceremonyId: string) => {
    try {
      const ceremonyPlanResponse: CeremonyPlanResponse =
        await getCeremonyAccessibleModules(ceremonyId);
      if (ceremonyPlanResponse.success) {
        setCeremonyPlan(ceremonyPlanResponse.data.latestSubscription);
        setCeremonyAccessibleModules(
          ceremonyPlanResponse.data.accessibleModules
        );
      }
    } catch (error) {}
  };

  const setWPUserAsCurrentUser = (user: User) => {
    setCurrentRole(MemberType.weddingPlanner);

    const currentUser: Member = {
      user_id: user.id,
      member_type: MemberType.weddingPlanner,
      legal_name: user.first_name,
      preferred_name: user.first_name,
      email: user.email,
      createdAt: user.createdAt,
      updatedAt: user.updatedAt,
    };

    setCurrentUser(currentUser)
  }

  useEffect(() => {
    if (!!props?.ceremonyId) fetchCeremonyById(String(props.ceremonyId));
  }, [props?.ceremonyId]);

  return {
    ceremony,
    couple1,
    couple2,
    officiant,
    weddingPlanner,
    fetchCeremonyById,
    fetchCeremonyPlanById,
    ceremonyPlan,
    ceremonyAccessibleModules,
    currentRole,
    auth,
    currentUser,
    setWPUserAsCurrentUser,
    coupleNamesString,
    speakingEvents,
  };
};

export default useCeremony;
