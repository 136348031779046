import { useEffect } from 'react';

import { setLastActive } from '../api/auth';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Heartbeat = (props: { children: any }) => {
  useEffect(() => {
    const heartbeatFunction = () => {
      try {
        void setLastActive();
      } catch (error) {}
    };
    heartbeatFunction();
    const interval = setInterval(() => heartbeatFunction(), 60000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return <>{props.children}</>;
};

export default Heartbeat;
