/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Tooltip as ReactTooltip } from 'react-tooltip';

import './tooltip.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomTooltip = (props: any) => {
  
  return (
    <ReactTooltip
      clickable={true}
      offset={10}
      isOpen={true}
      className='custom-tooltip'
      {...props}
    >
      {props.children}
    </ReactTooltip>
  );
};

export default CustomTooltip;
