import { Field, Form, Formik, FormikState } from 'formik';
import * as Yup from 'yup';

import { MemberSubType } from '../../../api/ceremony';
import { EventType, rolesDropdown } from '../../../api/friends';
import { CustomFormikInput } from '../../../pages/Modules/friendsAndFamily/CustomFormikInput';
import { CustomFormikSelect } from '../../../pages/Modules/friendsAndFamily/CustomFormikSelect';
import Button from '../Button/Button';
import PreviewEmail from '../PreviewEmail/PreviewEmail';

type AddGuestSpeakerFormProps = {
  handleSave: (
    values: AddGuestSpeakerFormValues,
    resetForm: (
      nextState?: Partial<FormikState<AddGuestSpeakerFormValues>> | undefined
    ) => void
  ) => void;
  handleClose: () => void;
  initialVals?: AddGuestSpeakerFormValues;
};

export type AddGuestSpeakerFormValues = {
  legal_name: string;
  email: string;
  member_sub_type?: MemberSubType;
  event?: EventType;
  alloted_time?: number;
  speech_note: string;
};

const AddGuestSpeakerForm = (props: AddGuestSpeakerFormProps) => {
  const { handleSave, handleClose, initialVals } = props;

  const formInitialVals: AddGuestSpeakerFormValues = initialVals || {
    legal_name: '',
    email: '',
    member_sub_type: undefined,
    event: undefined,
    alloted_time: undefined,
    speech_note: '',
  };

  const newSpeakerSchema = Yup.object().shape({
    legal_name: Yup.string().required('Please enter full name'),
    email: Yup.string().email('Please enter valid email').required('Required'),
    member_sub_type: Yup.string().required('Please choose a role'),
    event: Yup.string().required('Please choose an event'),
    alloted_time: Yup.number()
      .min(1, 'Please enter a number greater than 0')
      .required('Please enter allotted time'),
  });

  const events = [
    { value: 'engagement_party', label: 'Engagement Party' },
    { value: 'rehearsal_dinner', label: 'Rehearsal Dinner' },
    { value: 'reception', label: 'Reception' },
  ];
  return (
    <Formik
      initialValues={formInitialVals}
      validationSchema={newSpeakerSchema}
      validateOnBlur={true}
      validateOnChange={true}
      validateOnMount={false}
      onSubmit={(values, { resetForm }) => {
        handleSave(values, resetForm);
      }}
    >
      {({ values, errors, setTouched, touched }) => (
        <Form>
          <Field
            name={`legal_name`}
            label='Full Name'
            placeholder="Enter guest's full name"
            type='text'
            component={CustomFormikInput}
            setChanged={() => setTouched({ ...touched, legal_name: true })}
            error={touched.legal_name && !!errors.legal_name}
            helperText={touched.legal_name ? errors.legal_name : undefined}
          />
          <Field
            name={`email`}
            label='Email'
            placeholder="Enter guest's email address"
            type='text'
            component={CustomFormikInput}
            setChanged={() => setTouched({ ...touched, email: true })}
            error={touched.email && !!errors.email}
            helperText={touched.email ? errors.email : undefined}
          />
          <Field
            name={`member_sub_type`}
            placeholder='Select one option'
            label={'Who are they to you?'}
            type='text'
            component={CustomFormikSelect}
            setChanged={() => setTouched({ ...touched, member_sub_type: true })}
            options={rolesDropdown}
            error={touched.member_sub_type && !!errors.member_sub_type}
            helperText={
              touched.member_sub_type ? errors.member_sub_type : undefined
            }
          />
          <Field
            name={`event`}
            placeholder='Select one option'
            label='When will they be speaking?'
            type='text'
            component={CustomFormikSelect}
            setChanged={() => setTouched({ ...touched, event: true })}
            noSort={true}
            options={events}
            error={touched.event && !!errors.event}
            helperText={touched.event ? errors.event : undefined}
          />
          <Field
            name={`alloted_time`}
            placeholder='Enter time in minutes'
            label={'How long do they get to speak?'}
            type='number'
            component={CustomFormikInput}
            setChanged={() => setTouched({ ...touched, alloted_time: true })}
            value={values.alloted_time}
            error={touched.alloted_time && !!errors.alloted_time}
            helperText={touched.alloted_time ? errors.alloted_time : undefined}
          />

          <Field
            name={`speech_note`}
            label='What should they focus on or not mention?'
            placeholder="Write as little or as much as you'd like"
            type='text'
            component={CustomFormikInput}
            setChanged={() => setTouched({ ...touched, speech_note: true })}
            required={false}
          />

          <PreviewEmail type='INVITE_GUEST' />

          <div className={`flex flex-row justify-between mt-6`}>
            <Button variant='text' onClick={handleClose}>
              Cancel
            </Button>
            <Button type='submit'>{initialVals? 'Update':'Send Invite'}</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddGuestSpeakerForm;
