/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useEffect, useState } from 'react';

import { Module } from '../hooks/useActiveModule';
import { ModuleStatus } from '../pages/ModulesV2/enum';

import { useBuilderCTA } from './builderCTAprovider';
import { useCeremonyRegenerationNeeded } from './ceremonyRegenerationNeededProvider';

type CTAContextType = {
  status: 'saving' | 'saved' | 'unsaved';
  moduleStatus: ModuleStatus;
  handleQuestionSaved: (questionId: number) => void;
  handleQuestionSaving: (questionId: number) => void;
  handleQuestionUnsaved: (questionId: number) => void;
  handleQuestionAnswered: (questionId: number) => void;
  handleQuestionNotAnswered: (questionId: number) => void;
  setStatus: (status: 'saving' | 'saved' | 'unsaved') => void;
  answeredQuestionsCount: number;
};

export const CTAContext = React.createContext<CTAContextType>({
  status: 'saved',
  moduleStatus: ModuleStatus.notStarted,
  handleQuestionSaved: () => {},
  handleQuestionSaving: () => {},
  handleQuestionUnsaved: () => {},
  handleQuestionAnswered: () => {},
  handleQuestionNotAnswered: () => {},
  setStatus: () => {},
  answeredQuestionsCount: 0,
});

export const CTAProvider = ({
  children,
  requiredQuestions,
  totalQuestions,
  ctaStatus,
  onModuleStatusChange,
  module,
}: {
  children: React.ReactNode;
  requiredQuestions: number;
  totalQuestions: number;
  ctaStatus?: 'saving' | 'saved' | 'unsaved';
  onModuleStatusChange?: (status: ModuleStatus) => void;
  module: Module;
}) => {
  const [status, setStatus] = useState<'saving' | 'saved' | 'unsaved'>('saved');
  const [moduleStatus, setModuleStatus] = useState(ModuleStatus.notStarted);
  const [answeredQuestions, setAnsweredQuestions] = useState<number[]>([]);
  const [savedQuestions, setSavedQuestions] = useState<number[]>([]);
  const [unsavedQuestions, setUnsavedQuestions] = useState<number[]>([]);
  const [savingQuestions, setSavingQuestions] = useState<number[]>([]);

  const { setStatus: setBuilderStatus } = useBuilderCTA();
  const { setQuestionDate } = useCeremonyRegenerationNeeded();

  useEffect(() => {
    if (savingQuestions.length > 0) {
      setStatus('saving');
      setBuilderStatus('saving');
    } else if (unsavedQuestions.length > 0) {
      setStatus('unsaved');
      setBuilderStatus('unsaved');
      setQuestionDate(module, new Date());
    } else {
      setStatus('saved');
      setBuilderStatus('saved');
    }
  }, [savingQuestions, unsavedQuestions, savedQuestions]);

  useEffect(() => {
    if (ctaStatus) {
      setStatus(ctaStatus);
    }
  }, [ctaStatus]);

  useEffect(() => {
    if (onModuleStatusChange && moduleStatus) {
      onModuleStatusChange(moduleStatus);
    }
  }, [moduleStatus]);

  useEffect(() => {
    if (answeredQuestions.length === 0) {
      setModuleStatus(ModuleStatus.notStarted);
    } else if (
      answeredQuestions.length > 0 &&
      answeredQuestions.length < requiredQuestions
    ) {
      setModuleStatus(ModuleStatus.inProgress);
    } else if (answeredQuestions.length >= requiredQuestions) {
      if (answeredQuestions.length < totalQuestions) {
        setModuleStatus(ModuleStatus.requiredAnswered);
      } else {
        setModuleStatus(ModuleStatus.completed);
      }
    } else if (answeredQuestions.length === totalQuestions) {
      setModuleStatus(ModuleStatus.completed);
    }
  }, [answeredQuestions, requiredQuestions, totalQuestions]);

  const handleQuestionAnswered = (questionId: number) => {
    setAnsweredQuestions((old) => [
      ...old.filter((id) => id !== questionId),
      questionId,
    ]);
  };
  const handleQuestionNotAnswered = (questionId: number) => {
    setAnsweredQuestions((old) => [...old.filter((id) => id !== questionId)]);
  };

  const handleQuestionSaved = (questionId: number) => {
    setSavedQuestions((old) => [
      ...old.filter((id) => id !== questionId),
      questionId,
    ]);
    setSavingQuestions((old) => old.filter((id) => id !== questionId));
    setUnsavedQuestions((old) => old.filter((id) => id !== questionId));
  };

  const handleQuestionSaving = (questionId: number) => {
    setSavingQuestions((old) => [...old, questionId]);
    setUnsavedQuestions((old) => old.filter((id) => id !== questionId));
  };

  const handleQuestionUnsaved = (questionId: number) => {
    setUnsavedQuestions((old) => [...old, questionId]);
    setSavingQuestions((old) => old.filter((id) => id !== questionId));
  };

  const value = {
    answeredQuestionsCount: answeredQuestions.length,
    status,
    moduleStatus,
    handleQuestionSaved,
    handleQuestionSaving,
    handleQuestionUnsaved,
    handleQuestionAnswered,
    handleQuestionNotAnswered,
    setStatus,
  };
  // @ts-ignore
  return <CTAContext.Provider value={value}>{children}</CTAContext.Provider>;
};

export const useCTA = () => {
  return useContext(CTAContext);
};
