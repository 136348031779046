import { Collapse } from '@mui/material';

import { useWindowSize } from '../../../../hooks/useWindowSize';

import { CollapseContainerProps } from './typings.d';

export const CollapseContainer = ({
  collapsed,
  children,
  columnWidth,
}: CollapseContainerProps) => {
  const size = useWindowSize();
  const isMobile = size.width <= 768;
  const orientation = isMobile ? 'vertical' : 'horizontal';
  const inProp = !collapsed;
  return (
    <Collapse
      {...{ in: inProp, orientation }}
      className='ModuleWrapper-leftContainer'
      sx={{
        '&.MuiCollapse-entered': { flex: !columnWidth ? 1 : undefined },
        '.MuiCollapse-wrapperInner': { maxWidth: '500px' },
        '.MuiCollapse-wrapper': { width: columnWidth, maxWidth: '500px' },
      }}
    >
      <div className='ModuleWrapper-leftContainerWrapper'>{children}</div>
    </Collapse>
  );
};
