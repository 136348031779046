/* eslint-disable */
import { useNavigate, useParams } from 'react-router-dom';

import { ModuleQuestion, ModuleWrapper } from '../components';

import unionIcon from '../../../assets/images/icons/union.svg';
import useQuestions from '../../../hooks/useQuestions';
import useCeremony from '../../../hooks/useCeremony';
import { ModuleStatus } from '../enum';
import Button from '../../../components/v2/Button/Button';
import { Module } from '../../../hooks/useActiveModule';
import { useCeremonyBuilder } from '../../../provider/ceremonyBuilderProvider';
import { useEffect, useState } from 'react';
import { useAllQuestions } from '../../../provider/questionsProvider';
import { generalLog } from '../../../api/ceremony';
import { CTAProvider } from '../../../provider/ctaProvider';
import { Question } from '../../Modules/common/moduleQuestion/ModuleQuestion';
import { Typography } from '@mui/material';

type VowsCeremonyModuleProps = {
  hideWrapper?: boolean;
  onModuleStatusChange?: (status: ModuleStatus) => void;
};

export const VowsCeremonyModule = (props: VowsCeremonyModuleProps) => {
  const { hideWrapper = false, onModuleStatusChange } = props;
  const ceremonyId = useParams()?.ceremonyId;

  const { setDraftingModalOpen, setSelectedModule, setGenerateDraftInitiated } =
    useCeremonyBuilder();

  const { questions, fetchQuestions } = useQuestions();

  const { fetchAllQuestions } = useAllQuestions();

  const [questionChanged, setQuestionChanged] = useState(false);
  const navigate = useNavigate();

  const {
    ceremony,
    couple1,
    couple2,
    officiant,
    currentUser,
    fetchCeremonyById,
  } = useCeremony();
  const members = { couple1, couple2, officiant, currentUser };

  useEffect(() => {
    if (ceremonyId) {
      void fetchCeremonyById(ceremonyId);
    }
  }, [ceremonyId]);

  useEffect(() => {
    if (ceremony) {
      void fetchQuestions(Module.VowsCeremony, ceremony.id.toString());
    }
  }, [ceremony]);

  useEffect(() => {
    if (ceremonyId && questionChanged === true) {
      setQuestionChanged(false);
      void fetchQuestions(Module.VowsCeremony, ceremonyId);
      void fetchAllQuestions(ceremonyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionChanged]);

  const shouldRenderQuestion = (
    questionForRendering: Question,
    questions: Question[]
  ) => {
    let result = false;
    if (questionForRendering.identifier === 'addReadings') {
      result = false;
    } else if (
      questionForRendering.identifier?.includes('vowsWillBeRead') ||
      questionForRendering.identifier?.includes('vowsDuration')
    ) {
      const wantedQuestion = questions.find((q) =>
        q.identifier?.includes('willReadVows')
      );
      if (wantedQuestion) {
        const wantedOptionIds = wantedQuestion.options
          ?.filter((o) => o.option !== 'No')
          .map((opt) => opt.id.toString());
        if (
          wantedQuestion.answers &&
          wantedQuestion.answers.find(
            (a) =>
              a.option_id &&
              wantedOptionIds &&
              wantedOptionIds.indexOf(a.option_id) >= 0
          )
        ) {
          result = true;
        }
      }
    } else {
      result = true;
    }
    return result;
  };

  const getQuestionBanner = (question: Question) => {
    if (question.identifier?.includes("willReadVows")) {
      const answerId = question.answers?.[0]?.option_id;

      const option = question.options?.find((o) => o.id.toString() === answerId);

      if (option?.option === 'Yes') {
        return {
          type: 'yellow',
          text: <>
            <b>Doing personalized vows?</b> Check out our Vow Builder from your checklist.
          </>
        };
      }
      else if (option?.option === 'No') {
        return {
          type: 'yellow',
          text: <>
            <b>Not doing personalized vows?</b> After generating this chapter, continue to chapter 5 to select your traditional “I do’s”.
          </>
        };
      }
    }
    return undefined;
  }

  return (
    <CTAProvider
      totalQuestions={
        questions?.filter((q) => shouldRenderQuestion(q, questions)).length || 0
      }
      requiredQuestions={
        questions?.filter((q) => shouldRenderQuestion(q, questions)).length || 0
      }
      onModuleStatusChange={onModuleStatusChange}
      module={Module.VowsCeremony}
    >
      <ModuleWrapper
        hideWrapper={hideWrapper}
        chapterProps={{
          chapter: 'Chapter 4',
          renderIcon: () => <img src={unionIcon} draggable={false} />,
          iconWrapperProps: { className: 'bg-cashmere-600' },
        }}
        summaryProps={{
          moduleName: 'Vows Logistics',
          moduleDescription:
            'Whether saying them in private or sharing them in front of witnesses and guests, the majority of couples today are writing their own vows. Answer these quick questions to inform the ceremony script, and then use the Vow Builder to get started writing.',
          numberOfQuestions: 2,
          estimatedTime: 3,
        }}
        questionsHeaderProps={{
          renderCenterButton: (
            moduleStatus: ModuleStatus,
            status: 'saving' | 'saved' | 'unsaved'
          ) => (
            <Button
              size='100'
              disabled={
                moduleStatus !== ModuleStatus.completed || status !== 'saved'
              }
              onClick={() => {
                if (ceremonyId) {
                  setSelectedModule(Module.VowsCeremony);
                  setDraftingModalOpen(true);
                  setGenerateDraftInitiated(true);
                  generalLog({
                    ceremony_id: ceremonyId,
                    activity: `Generate - Vows Module`,
                  });
                  navigate(`/ceremony-designer/${ceremonyId}`);
                } else {
                  navigate(-1);
                }
              }}
            >
              Generate Section
            </Button>
          ),
          renderRightButton: () => (
            <Button
              variant='secondary'
              onClick={() => {
                if (ceremonyId) {
                  navigate(`/ceremony-designer/${ceremonyId}`);
                } else {
                  navigate(-1);
                }
              }}
            >
              Exit & Save
            </Button>
          ),
        }}
      >
        {ceremony &&
          questions &&
          questions
            .filter((q) => shouldRenderQuestion(q, questions))
            .map((questionData, questionIndex) => {
              const banner = getQuestionBanner(questionData);
              return (
                <ModuleQuestion
                  key={`question-${questionData.id}`}
                  ceremonyId={ceremony.id}
                  question={questionData}
                  questionNumber={questionIndex + 1}
                  numberOfQuestions={questions?.length ?? 0}
                  members={members}
                  onChange={() => setQuestionChanged(true)}
                  banner={banner}
                  shouldRerender={banner !== undefined}
                />
              );
            })}
      </ModuleWrapper>
    </CTAProvider>
  );
};
