import { Dialog, DialogContent } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ModulePageWrapperTransition,
  ModulePageWrapperTransitionLeft,
} from '../ModulePageWrapper/Transition';

import './styles.scss';

type DrawerProps = {
  open?: boolean;
  onClose?: () => Promise<void> | void;
  fullHeight?: boolean;
  horizontal?: boolean;
};

export const Drawer = ({
  children,
  open,
  onClose,
  fullHeight = false,
  horizontal = false,
}: React.PropsWithChildren<DrawerProps>) => {
  return (
    <Dialog
      className={`custom-drawer ${fullHeight ? 'full-height' : ''}`}
      fullWidth
      maxWidth={false}
      onClose={onClose}
      open={open || false}
      TransitionComponent={
        horizontal
          ? ModulePageWrapperTransitionLeft
          : ModulePageWrapperTransition
      }
    >
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
