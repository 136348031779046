import { _request } from './request';

export type Campaign = {
  campaignText: string;
  couponCode: string;
  discountPercentage: number;
};

export type GetActiveCampaignResponse = {
  success: boolean;
  message: string;
  data: Campaign;
};

export const getActiveCampaign = async () => {
  const responseBody = await _request<GetActiveCampaignResponse>({
    url: `campaign`,
    method: 'GET',
  });
  return responseBody;
};
