import { useState } from 'react';

import arrowRightSvg from '../../../assets/images/icons/arrow-right-new.svg';
import closeSvg from '../../../assets/images/icons/close-x-new.svg';
import Button from '../../../components/v2/Button/Button';
import { Typography } from '../../../components/v2/Typography/Typography';
import { Sort, useReadings } from '../../../provider/readingsProvider';
import { Drawer } from '../../ModulesV2/components/Drawer/Drawer';

import FilterDetailView from './FilterDetailView';
import FiltersMain from './FiltersMain';

type FilterRowProps = {
  title: string;
  onClick: () => void;
};

const FiltersRow = (props: FilterRowProps) => {
  const { title, onClick } = props;
  return (
    <div
      onClick={onClick}
      className='flex flex-row justify-between items-center py-8 border-b border-forest-100 cursor-pointer'
    >
      <div>{title}</div>
      <img src={arrowRightSvg} />
    </div>
  );
};

const FiltersDrawer = () => {
  const [selectedFilter, setSelectedFilter] = useState<
    'cultures' | 'ritualTypes' | 'tags' | 'sortby'
  >();

  const {
    showFilters,
    setShowFilters,
    cultures,
    cultureValues,
    setCultureValues,
    ritualTypes,
    ritualTypeValues,
    setRitualTypeValues,
    tags,
    tagValues,
    setTagValues,
    handleClearFilters,
    currentSort,
    setCurrentSort,
  } = useReadings();

  const handleClose = () => {
    setSelectedFilter(undefined);
    setShowFilters(false);
  };

  const handleCultureChange = (value: string) => {
    const oldValues = cultureValues;
    if (cultureValues.indexOf(value) >= 0) {
      setCultureValues(oldValues.filter((v) => v !== value));
    } else setCultureValues([...oldValues, value]);
  };

  const handleRitualTypeChange = (value: string) => {
    const oldValues = ritualTypeValues;
    if (ritualTypeValues.indexOf(value) >= 0) {
      setRitualTypeValues(oldValues.filter((v) => v !== value));
    } else setRitualTypeValues([...oldValues, value]);
  };

  const handleTagsChange = (value: string) => {
    const oldValues = tagValues;
    if (tagValues.indexOf(value) >= 0) {
      setTagValues(oldValues.filter((v) => v !== value));
    } else setTagValues([...oldValues, value]);
  };

  const handleClearFiltersClick = () => {
    handleClearFilters();
    setSelectedFilter(undefined);
    setShowFilters(false);
  };

  const handleApplyClick = () => {
    setSelectedFilter(undefined);
    setShowFilters(false);
  };

  const getNumberOfFilters = () => {
    let result = 0;
    if (cultureValues && cultureValues.length > 0) {
      result += cultureValues.length;
    }
    if (ritualTypeValues && ritualTypeValues.length > 0) {
      result += ritualTypeValues.length;
    }
    if (tagValues && tagValues.length > 0) {
      result += tagValues.length;
    }
    return result;
  };
  return (
    <Drawer fullHeight={true} open={showFilters} onClose={handleClose}>
      <div className='flex flex-col h-full justify-between'>
        <div className='h-full flex flex-col'>
          <div className='w-full p-4'>
            <img
              onClick={handleClose}
              className='ml-auto cursor-pointer'
              src={closeSvg}
            />
          </div>
          {!selectedFilter && (
            <FiltersMain setSelectedFilter={setSelectedFilter} />
          )}
          {selectedFilter === 'sortby' && (
            <FilterDetailView
              title='Sort By'
              onBackClick={() => setSelectedFilter(undefined)}
              options={Object.values(Sort).map((r) => ({
                label: r,
                value: r,
              }))}
              values={currentSort}
              handleItemClick={(sort) => {
                setCurrentSort(
                  // On autofill we get a stringified value.
                  [sort] as Sort[]
                );
              }}
              // handleAllClick={() => {
              //   if (ritualTypeValues && ritualTypeValues.length > 0) {
              //     setRitualTypeValues([]);
              //   }
              // }}
            />
          )}
          {selectedFilter === 'cultures' && (
            <FilterDetailView
              title='Affiliation'
              onBackClick={() => setSelectedFilter(undefined)}
              options={cultures.map((r) => ({
                label: r.name,
                value: r.id.toString(),
              }))}
              values={cultureValues}
              handleItemClick={handleCultureChange}
              handleAllClick={() => {
                if (cultureValues && cultureValues.length > 0) {
                  setCultureValues([]);
                }
              }}
            />
          )}
          {selectedFilter === 'ritualTypes' && (
            <FilterDetailView
              title='Ceremony Placement'
              onBackClick={() => setSelectedFilter(undefined)}
              options={ritualTypes.map((r) => ({
                label: r.name,
                value: r.id.toString(),
              }))}
              values={ritualTypeValues}
              handleItemClick={handleRitualTypeChange}
              handleAllClick={() => {
                if (ritualTypeValues && ritualTypeValues.length > 0) {
                  setRitualTypeValues([]);
                }
              }}
            />
          )}
          {selectedFilter === 'tags' && (
            <FilterDetailView
              title='Method'
              onBackClick={() => setSelectedFilter(undefined)}
              options={tags.map((r) => ({
                label: r.name,
                value: r.id.toString(),
              }))}
              values={tagValues}
              handleItemClick={handleTagsChange}
              handleAllClick={() => {
                if (tagValues && tagValues.length > 0) {
                  setRitualTypeValues([]);
                }
              }}
            />
          )}
        </div>
        <div>
          <hr className='block w-full' />
          <div className='pt-4 pb-5 pl-8 pr-24 flex flex-row justify-between items-center'>
            <Button
              disabled={getNumberOfFilters() === 0}
              onClick={handleClearFiltersClick}
            >
              Clear Filters
            </Button>
            <Button
              disabled={getNumberOfFilters() === 0}
              onClick={handleApplyClick}
            >
              {`Apply ${
                getNumberOfFilters() > 0
                  ? `${getNumberOfFilters()} Filters`
                  : ''
              }`}
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default FiltersDrawer;
