/* eslint-disable @typescript-eslint/no-empty-function */
import { SelectChangeEvent } from '@mui/material';
import React, { useContext, useEffect, useState, useRef } from 'react';

import { Ceremony, Member } from '../api/ceremony';
import { Culture } from '../api/culture';
import { deleteAnswer, saveAnswer } from '../api/question';
import { Ritual, getRituals, saveRitual } from '../api/ritual';
import { RitualType, getRitualTypes } from '../api/ritualType';
import { Tag, getTags } from '../api/tags';
import { Module } from '../hooks/useActiveModule';
import useCeremony from '../hooks/useCeremony';
import { useOutsideClick } from '../hooks/useOutsideClick';
import useQuestions from '../hooks/useQuestions';
import { useWindowSize } from '../hooks/useWindowSize';
import {
  Question,
  QuestionAnswer,
} from '../pages/Modules/common/moduleQuestion/ModuleQuestion';

import { useCeremonyRegenerationNeeded } from './ceremonyRegenerationNeededProvider';
import { useCultures } from './cultureProvider';
import { useSnackbar } from './snackbarProvider';

type ReadingsContextType = {
  question?: Question;
  setQuestion: (question: Question) => void;
  readersQuestion?: Question;
  setReadersQuestion: (question: Question) => void;
  ceremony?: Ceremony;
  showFilters: boolean;
  setShowFilters: (value: boolean) => void;
  showResults: boolean;
  setShowResults: (value: boolean) => void;
  searchValue: string | undefined;
  setSearchValue: (value: string) => void;
  selectedRitual: Ritual | undefined;
  setSelectedRitual: (ritual: Ritual | undefined) => void;
  cultureValues: string[];
  setCultureValues: (values: string[]) => void;
  ritualTypeValues: string[];
  setRitualTypeValues: (values: string[]) => void;
  tagValues: string[];
  setTagValues: (values: string[]) => void;
  filteredReadings: Ritual[];
  setFilteredReadings: (rituals: Ritual[]) => void;
  ritualTypes: RitualType[];
  tags: Tag[];
  cultures: Culture[];
  handleClearFilters: () => void;
  handleTagChange: (event: SelectChangeEvent<string[]>) => void;
  handleRitualTypeChange: (event: SelectChangeEvent<string[]>) => void;
  handleCultureChange: (event: SelectChangeEvent<string[]>) => void;
  handleRemoveCulture: (value: string) => void;
  handleRemoveRitualType: (value: string) => void;
  handleRemoveTag: (value: string) => void;
  handleUseRitual: (ritualId: number) => void;
  handleSave: (
    question: Question,
    ceremony: Ceremony,
    answer: QuestionAnswer
  ) => void;
  handleReviewRitual: (ritual: Ritual) => void;
  handleSaveNewRitual: (
    title: string,
    cultureValues: string[],
    ritualTypeValues: string[],
    tagValues: string[],
    description: string,
    time?: number
  ) => Promise<void>;
  addModalOpen: boolean;
  setAddModalOpen: (value: boolean) => void;
  fetchCeremony: (ceremonyId: string) => void;
  tempAnswers: QuestionAnswer[];
  setTempAnswers: (value: QuestionAnswer[]) => void;
  allReadings: Ritual[];
  initRemoveRitual: (ritual: Ritual) => void;
  removeRitual: (ritualId: number) => void;
  confirmRemoveOpen: boolean;
  ritualForRemoval?: Ritual;
  setConfirmRemoveOpen: (value: boolean) => void;
  saved: boolean;
  setSaved: (value: boolean) => void;
  addRitual: (ritualId: number, ceremonyId: number, questionId: number) => void;
  currentUser: Member | undefined;
  currentSort: Sort[];
  setCurrentSort: (value: Sort[]) => void;
  showSortTooltip: boolean;
  setShowSortTooltip: (value: boolean) => void;
  lastRitualId: number;
  setLastRitualId: (value: number) => void;
  scrollRef: React.MutableRefObject<any>;
};

export enum Sort {
  MostPopular = 'Most Popular',
  RecentlyAdded = 'Recently Added',
  AtoZ = 'A to Z',
  ZtoA = 'Z to A',
}

export const ReadingsContext = React.createContext<ReadingsContextType>({
  question: undefined,
  setQuestion: () => {},
  readersQuestion: undefined,
  setReadersQuestion: () => {},
  ceremony: undefined,
  showFilters: false,
  setShowFilters: () => {},
  showResults: false,
  setShowResults: () => {},
  searchValue: '',
  setSearchValue: () => {},
  selectedRitual: undefined,
  setSelectedRitual: () => {},
  cultureValues: [],
  setCultureValues: () => {},
  ritualTypeValues: [],
  setRitualTypeValues: () => {},
  tagValues: [],
  setTagValues: () => {},
  filteredReadings: [],
  setFilteredReadings: () => {},
  ritualTypes: [],
  tags: [],
  cultures: [],
  handleClearFilters: () => {},
  handleTagChange: () => {},
  handleRitualTypeChange: () => {},
  handleCultureChange: () => {},
  handleRemoveCulture: () => {},
  handleRemoveRitualType: () => {},
  handleRemoveTag: () => {},
  handleUseRitual: () => {},
  handleSave: () => {},
  handleReviewRitual: () => {},
  handleSaveNewRitual: async () => {},
  addModalOpen: false,
  setAddModalOpen: () => {},
  fetchCeremony: () => {},
  tempAnswers: [],
  setTempAnswers: () => {},
  allReadings: [],
  initRemoveRitual: () => {},
  removeRitual: () => {},
  confirmRemoveOpen: false,
  ritualForRemoval: undefined,
  setConfirmRemoveOpen: () => {},
  saved: true,
  setSaved: () => {},
  addRitual: () => {},
  currentUser: undefined,
  currentSort: [Sort.MostPopular],
  setCurrentSort: () => {},
  showSortTooltip: false,
  setShowSortTooltip: () => {},
  lastRitualId: 0,
  setLastRitualId: () => {},
  scrollRef: { current: null },
});

export const ReadingsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [question, setQuestion] = useState<Question | undefined>();
  const [readersQuestion, setReadersQuestion] = useState<
    Question | undefined
  >();
  const [saved, setSaved] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string>();
  const [selectedRitual, setSelectedRitual] = useState<Ritual | undefined>();
  const [cultureValues, setCultureValues] = useState<string[]>([]);
  const [ritualTypeValues, setRitualTypeValues] = useState<string[]>([]);
  const [tagValues, setTagValues] = useState<string[]>([]);
  const [filteredReadings, setFilteredReadings] = useState<Ritual[]>([]);
  const [confirmRemoveOpen, setConfirmRemoveOpen] = useState(false);
  const [ritualForRemoval, setRitualForRemoval] = useState<Ritual>();
  const [tempAnswers, setTempAnswers] = useState<QuestionAnswer[]>([]);
  const [currentSort, setCurrentSort] = useState<Sort[]>([Sort.MostPopular]);
  const [showSortTooltip, setShowSortTooltip] = useState(false);
  const [lastRitualId, setLastRitualId] = useState(0);

  const [rituals, setRituals] = useState<Ritual[]>([]);
  const [ritualTypes, setRitualTypes] = useState<RitualType[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const { cultures, fetchCultures } = useCultures();

  const { ceremony, fetchCeremonyById, currentUser } = useCeremony();
  const { questions, fetchQuestions } = useQuestions();
  const { openSnackBar } = useSnackbar();
  const { setReadingsDetailsQuestionDate } = useCeremonyRegenerationNeeded();

  const scrollRef = useRef<any>(null);

  useEffect(() => {
    if (ceremony) {
      void fetchQuestions(Module.RitualsAndReadings, ceremony.id.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ceremony]);

  useEffect(() => {
    const readersQuestion =
      questions?.find((q) => q.identifier === 'readers') || undefined;
    if (readersQuestion) {
      setReadersQuestion(readersQuestion);
    }
    const mainReadingsQuestion =
      questions?.find((q) => q.type === 'readings') || undefined;
    if (mainReadingsQuestion) {
      setQuestion(mainReadingsQuestion);
    }
    if (
      mainReadingsQuestion &&
      mainReadingsQuestion.answers &&
      mainReadingsQuestion.answers.length > 0
    ) {
      setTempAnswers(mainReadingsQuestion.answers);
    }
  }, [questions]);

  const fetchRituals = async () => {
    try {
      const response = await getRituals();
      if (response.success) {
        setFilteredReadings(sortRituals(currentSort[0], response.data));
        setRituals(response.data);
      }
    } catch (error) {}
  };

  const sortRituals = (sort: Sort, rituals: Ritual[]) => {
    switch (sort) {
      case Sort.MostPopular:
        return rituals.sort((a, b) => {
          if (a.popularity_count > b.popularity_count) {
            return -1;
          } else if (a.popularity_count < b.popularity_count) {
            return 1;
          } else {
            return 0;
          }
        });
      case Sort.RecentlyAdded:
        return rituals.sort((a, b) => {
          if (a.createdAt > b.createdAt) {
            return -1;
          } else if (a.createdAt < b.createdAt) {
            return 1;
          } else {
            return 0;
          }
        });
      case Sort.AtoZ:
        return rituals.sort((a, b) => (a.name > b.name ? 1 : -1));
      case Sort.ZtoA:
        return rituals.sort((a, b) => (a.name > b.name ? -1 : 1));
      default:
        return rituals;
    }
  };

  useEffect(() => {
    const fetchRitualTypes = async () => {
      try {
        const response = await getRitualTypes();
        if (response.success) {
          setRitualTypes(response.data);
        }
      } catch (error) {}
    };
    const fetchTags = async () => {
      try {
        const response = await getTags();
        if (response.success) {
          setTags(response.data);
        }
      } catch (error) {}
    };

    void fetchRituals();
    void fetchCultures();
    void fetchRitualTypes();
    void fetchTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // setShowResults(true);
    let filtered: Ritual[] = rituals;

    if (cultureValues.length > 0) {
      filtered = filtered.filter(
        (r) =>
          r.cultures.filter((c) => cultureValues.indexOf(c.id.toString()) >= 0)
            .length > 0
      );
    }
    if (ritualTypeValues.length > 0) {
      filtered = filtered.filter(
        (r) =>
          r.ritual_types.filter(
            (c) => ritualTypeValues.indexOf(c.id.toString()) >= 0
          ).length > 0
      );
    }
    if (tagValues.length > 0) {
      filtered = filtered.filter(
        (r) =>
          r.tags &&
          r.tags.filter((c) => tagValues.indexOf(c.id.toString()) >= 0).length >
            0
      );
    }
    if (searchValue && searchValue.length >= 1) {
      filtered = filtered.filter(
        (r) =>
          r.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
          r.cultures.find(
            (c) => c.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
          ) ||
          r.ritual_types.find(
            (c) => c.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
          )
      );
    }
    setFilteredReadings(sortRituals(currentSort[0], filtered));
  }, [
    ritualTypeValues,
    cultureValues,
    tagValues,
    rituals,
    searchValue,
    currentSort,
  ]);

  const fetchCeremony = (ceremonyId: string) => {
    if (ceremonyId && !isNaN(parseInt(ceremonyId ?? ''))) {
      void fetchCeremonyById(ceremonyId);
    }
  };

  const addRitual = (
    ritualId: number,
    ceremonyId: number,
    questionId: number
  ) => {
    const newQuestionAnswer: QuestionAnswer = {
      ceremony_id: ceremonyId,
      question_id: questionId,
      option_id: ritualId.toString(),
      text_answer: '',
    };
    const answers = [...tempAnswers, newQuestionAnswer];
    setTempAnswers(answers);
    if (ceremony && question) {
      void handleSave(question, ceremony, newQuestionAnswer);
    }
  };

  const initRemoveRitual = (ritual: Ritual) => {
    setRitualForRemoval(ritual);
    setConfirmRemoveOpen(true);
  };

  const removeRitual = async (ritualId: number) => {
    if (!ceremony || !question) {
      return;
    }
    setSaved(false);
    setConfirmRemoveOpen(false);
    setRitualForRemoval(undefined);
    setSelectedRitual(undefined);
    const answers = tempAnswers.filter(
      (ta) => ta.option_id !== ritualId.toString()
    );
    setTempAnswers(answers);
    //check if the removed answer is from the DB
    const answerFromDb = question.answers?.find(
      (a: QuestionAnswer) => a.option_id === ritualId.toString()
    );
    if (answerFromDb) {
      try {
        await deleteAnswer({
          ...answerFromDb,
          ceremony_id: ceremony.id,
          question_id: question.id,
        });
        setSaved(true);
        setReadingsDetailsQuestionDate(new Date());
        openSnackBar('Ritual has been removed!');
      } catch (error) {
        openSnackBar('An error occurred', 'error');
      }
    }
  };

  const handleUseRitual = (ritualId: number) => {
    if (ceremony && question) {
      //setSelectedRitual(undefined);
      addRitual(ritualId, ceremony.id, question.id);
      openSnackBar('Your reading/ritual was added!');
    }
  };

  const handleReviewRitual = (ritual: Ritual) => {
    setSelectedRitual(ritual);
  };

  const handleCultureChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    if (value.indexOf('all') >= 0) {
      setCultureValues([]);
    } else {
      setCultureValues(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
      );
    }
  };

  const handleRemoveCulture = (value: string) => {
    if (cultureValues.indexOf(value) >= 0) {
      setCultureValues([...cultureValues.filter((v) => v !== value)]);
    }
  };

  const handleRitualTypeChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    if (value.indexOf('all') >= 0) {
      setRitualTypeValues([]);
    } else {
      setRitualTypeValues(
        // On autofill we get a stringified value.
        typeof value === 'string'
          ? value.split(',').filter((v) => v !== 'all')
          : value
      );
    }
  };
  const handleRemoveRitualType = (value: string) => {
    if (ritualTypeValues.indexOf(value) >= 0) {
      setRitualTypeValues([...ritualTypeValues.filter((v) => v !== value)]);
    }
  };

  const handleTagChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    if (value.indexOf('all') >= 0) {
      setTagValues([]);
    } else {
      setTagValues(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
      );
    }
  };
  const handleRemoveTag = (value: string) => {
    if (tagValues.indexOf(value) >= 0) {
      setTagValues([...tagValues.filter((v) => v !== value)]);
    }
  };

  const handleSaveNewRitual = async (
    title: string,
    cultureValues: string[],
    ritualTypeValues: string[],
    tagValues: string[],
    description: string,
    time?: number
  ) => {
    if (!ceremony) {
      return;
    }
    setAddModalOpen(false);
    try {
      const response = await saveRitual({
        name: title,
        text: description,
        cultures: cultureValues,
        ritual_types: ritualTypeValues,
        created_for: ceremony.id,
        tags: tagValues,
        time: time,
      });
      if (response.data) {
        await fetchRituals();
        handleUseRitual(response.data.id);
      }
    } catch (error) {}
  };

  const handleSave = async (
    question: Question,
    ceremony: Ceremony,
    answer: QuestionAnswer
  ) => {
    if (ceremony) {
      setSaved(false);
      try {
        const saveResponse = await saveAnswer({
          ...answer,
          question_id: question.id,
          ceremony_id: ceremony.id,
        });
        if (saveResponse.success) {
          setSaved(true);
          setReadingsDetailsQuestionDate(new Date());
          void fetchRituals();
          void fetchQuestions(
            Module.RitualsAndReadings,
            ceremony.id.toString()
          );
        }
      } catch (error) {
        openSnackBar('An error occurred!');
      }
    }
  };

  const handleClearFilters = () => {
    setTagValues([]);
    setRitualTypeValues([]);
    setCultureValues([]);
  };
  // @ts-ignore
  const value = {
    question,
    setQuestion,
    readersQuestion,
    setReadersQuestion,
    ceremony,
    showFilters,
    setShowFilters,
    showResults,
    setShowResults,
    searchValue,
    setSearchValue,
    selectedRitual,
    setSelectedRitual,
    cultureValues,
    setCultureValues,
    ritualTypeValues,
    setRitualTypeValues,
    tagValues,
    setTagValues,
    filteredReadings: sortRituals(currentSort[0], filteredReadings),
    setFilteredReadings,
    cultures,
    tags,
    ritualTypes,
    addRitual,
    handleReviewRitual,
    handleClearFilters,
    handleTagChange,
    handleRitualTypeChange,
    handleCultureChange,
    handleRemoveCulture,
    handleRemoveRitualType,
    handleRemoveTag,
    handleUseRitual,
    handleSave,
    handleSaveNewRitual,
    addModalOpen,
    setAddModalOpen,
    fetchCeremony,
    tempAnswers,
    setTempAnswers,
    allReadings: rituals,
    initRemoveRitual,
    removeRitual,
    confirmRemoveOpen,
    ritualForRemoval,
    setConfirmRemoveOpen,
    saved,
    setSaved,
    currentUser,
    currentSort,
    setCurrentSort,
    showSortTooltip,
    setShowSortTooltip,
    lastRitualId,
    setLastRitualId,
    scrollRef,
  };

  return (
    <ReadingsContext.Provider value={value}>
      {children}
    </ReadingsContext.Provider>
  );
};

export const useReadings = () => {
  return useContext(ReadingsContext);
};
