/* eslint-disable -- TODO: fix eslint errors */

import { useEffect, useState } from 'react';
import { Ceremony, Member, MemberType } from '../api/ceremony';
import {
  DashboardUser,
  DashboardUserResponse,
  getDashboardUser,
} from '../api/user';
import { getCoupleName } from '../helpers/ceremonyHelper';
import { hotjar } from 'react-hotjar';

const useUser = () => {
  const [user, setUser] = useState<DashboardUser>();
  const [userCeremonies, setUserCeremonies] = useState<Array<Ceremony>>();
  const [isWeddingPlanner, setIsWeddingPlanner] = useState<Boolean>();
  const [userCeremoniesLoaded, setUserCeremoniesLoaded] = useState<Boolean>();

  useEffect(() => {
    if (!user) {
      getUserData();
    }
  }, []);

  //function scope is to find the type of member user is in ceremony
  const getUserCeremonies = async (user: DashboardUser) => {
    const ceremonies: Array<Ceremony> = [];

    user.ceremonies.forEach((ceremony, index) => {
      const { members, ceremony_planner_status } = ceremony;
      const coupleMembers = members!
        .filter(
          (m) =>
            m.member_type === MemberType.bride ||
            m.member_type === MemberType.groom ||
            m.member_type === MemberType.other
        )
        .sort((a, b) => (a.id && b.id && a.id < b.id ? -1 : 1));

      ceremony.couple = [];

      ceremony.couple = [];

      ceremony.isPlannerActivated = false;
      if (ceremony_planner_status && ceremony_planner_status.length > 0) {
        const lastStatus = ceremony_planner_status.sort((a, b) =>
          a.id > b.id ? -1 : 1
        )[0];
        if (lastStatus && lastStatus.status === 'active') {
          ceremony.isPlannerActivated = true;
        }
      }

      ceremonies.push(ceremony);

      ceremony.members?.forEach((member) => {
        if (member.user_id === user?.id) {
          ceremonies[index]['member_type'] = member.member_type;

          if (hotjar.initialized()) {
            hotjar.identify(String(member.id), {
              member_type: member.member_type,
              is_creator: member.user_id === member.created_by,
              created_at: member.createdAt,
              created_at_unix_timestamp: Math.floor(
                new Date(member.createdAt as Date).getTime() / 1000
              ),
              ceremony_id: ceremony.id,
              is_partner_invited: coupleMembers.length > 1,
              is_officiant_invited:
                ceremony.members &&
                ceremony.members.filter(
                  (m) => m.member_type === MemberType.officiant
                ).length > 0,
              is_wp_invited:
                ceremony.members &&
                ceremony.members.filter(
                  (m) => m.member_type === MemberType.weddingPlanner
                ).length > 0,
              ceremony_date: ceremony.date,
              ceremony_date_unix_timestamp:
                ceremony.date &&
                Math.floor(new Date(ceremony.date).getTime() / 1000),
              ceremony_created_at: ceremony.createdAt,
              ceremony_created_at_unix_timestamp: Math.floor(
                new Date(ceremony.createdAt as Date).getTime() / 1000
              ),
              has_access_to: ceremony.hasAccessTo?.join(','),
            });
          }
        }

        if (
          member.member_type === MemberType.bride ||
          member.member_type === MemberType.groom ||
          member.member_type === MemberType.other
        ) {
          ceremonies[index]['couple']?.push(member);
        }

        ceremonies[index]['coupleName'] = getCoupleName(
          coupleMembers[0],
          coupleMembers[1]
        );
      });
    });
    setUserCeremonies(ceremonies);
    setUserCeremoniesLoaded(true);
  };

  const getUserData = async () => {
    const response: DashboardUserResponse = await getDashboardUser();
    if (response.success) {
      setUser(response.user);

      if (response.user.ceremonies && response.user.ceremonies.length > 0) {
        getUserCeremonies(response.user); // this is because dashboard already works using the logic below *second comm
      } else {
        setUserCeremoniesLoaded(true);
      }
    }
  };

  const fetchCeremonies = () => {
    getUserData();
  };

  return {
    user,
    userCeremonies,
    fetchCeremonies,
    userCeremoniesLoaded,
    getUserData,
  };
};

export default useUser;
