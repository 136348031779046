import { Ritual } from '../../../api/ritual';
import { Typography } from '../../../components/v2/Typography/Typography';
import { getFullName } from '../../../helpers/nameHelper';
import { useReadings } from '../../../provider/readingsProvider';

import './readingSearchItem.scss';

type ReadingSearchItemProps = {
  ritual: Ritual;
  isSelected: boolean;
};
const ReadingSearchItem = (props: ReadingSearchItemProps) => {
  const { ritual, isSelected } = props;

  const { setSelectedRitual, setLastRitualId, lastRitualId, scrollRef } = useReadings();
  
  return (
    <div
      ref={ lastRitualId == ritual.id ? scrollRef : null}
      className={`reading-search-item flex flex-col justify-between items-start ${
        isSelected
          ? 'border-[3px] border-copper-primary text-copper-primary'
          : 'border-[1px] border-forest-primary text-forest-primary'
      }`}
      /* onClick={() => {
        setSelectedRitual(ritual)
        setLastRitualId(ritual.id)
      }} */
    >
      <div>
        <Typography variant='functional-low' type='body-600' className='mb-1'>
          {ritual.name}
        </Typography>
        {(ritual.author || ritual.createdBy) && (
          <Typography
            variant='functional-low'
            type='body-200'
            className={'mb-2 ' + (isSelected ? 'text-copper-primary' : 'text-forest-300')}
          >
            {`contributed by ${
              ritual.author ||
              (ritual.createdBy ? getFullName(ritual.createdBy) : '')
            }`}
          </Typography>
        )}
      </div>
      <div>
        <Typography
          onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            event.stopPropagation();
            setSelectedRitual(ritual);
            setLastRitualId(ritual.id)
          }}
          variant='functional-low'
          type='body-200'
          className={`border-0 border-b border-dashed ${
            isSelected ? 'border-copper-primary' : 'border-forest-primary'
          }  cursor-pointer`}
        >
          {isSelected ? 'View and edit details' : 'View details'}
        </Typography>
      </div>
    </div>
  );
};

export default ReadingSearchItem;
