import React, { useContext, useEffect, useState } from 'react';

import { getActiveCampaign } from '../api/campaign';

type CampaignContextType = {
  hasActiveCampaign: boolean;
  campaignText: string;
  couponCode: string;
  discountPercentage: number;
  fetchCampaign: () => void;
};

export const CampaignContext = React.createContext<CampaignContextType>({
  hasActiveCampaign: false,
  campaignText: '',
  couponCode: '',
  discountPercentage: 0,
  fetchCampaign: () => {},
});

export const CampaignProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [hasActiveCampaign, setHasActiveCampaign] = useState<boolean>(false);
  const [campaignText, setCampaignText] = useState<string>('');
  const [couponCode, setCouponCode] = useState<string>('');
  const [discountPercentage, setDiscountPercentage] = useState<number>(0);

  const fetchCampaign = async () => {
    try {
      const response = await getActiveCampaign();
      if (response.success && response.data) {
        setHasActiveCampaign(true);
        setCampaignText(response.data.campaignText);
        setCouponCode(response.data.couponCode);
        setDiscountPercentage(response.data.discountPercentage);
      }
    } catch (error) {}
  };

  const value = {
    hasActiveCampaign,
    campaignText,
    couponCode,
    discountPercentage,
    fetchCampaign,
  };
  // @ts-ignore
  return (
    <CampaignContext.Provider value={value}>
      {children}
    </CampaignContext.Provider>
  );
};

export const useActiveCampaign = () => {
  return useContext(CampaignContext);
};
