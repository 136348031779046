import { Menu, MenuItem } from '@mui/material';
import React, { MouseEventHandler, useState } from 'react';

type Option = {
  value: string;
  label: React.ReactNode;
}

type Props = {
  label: React.ReactNode;
  options: Option[];
  onChange?: (command: string) => Promise<void> | void;
}

export const RichTextCommandDropdown = ({ label, options, onChange }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const closeHandler = () => setAnchorEl(null);
  const openHandler: MouseEventHandler<HTMLElement> =
    evt => setAnchorEl(evt.currentTarget); 
  const fixedDimensions = {
    minHeight: 40,
    maxHeight: 40,
    minWidth: 40,
    maxWidth: 40,
  };

  return (
    <React.Fragment>
      <button
        className='RichTextToolbar-command-button dropdown'
        onMouseDown={evt => {
          evt.preventDefault();
          openHandler(evt);
        }}
      >
        {label}
      </button>
      <Menu
        {...{ open, anchorEl, onClose: closeHandler }}
        MenuListProps={{ sx: { paddingX: 1 } }}
      >
        {options.map(({ value, label }) => (
          <MenuItem
            key={value}
            className='RichTextToolbar-command-button'
            sx={{ paddingX: 0, ...fixedDimensions }}
            onMouseDown={evt => {
              evt.preventDefault()
              if (typeof onChange === 'function')
                void onChange(value);
              closeHandler();
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
