import React, { memo, useCallback, useEffect, useState } from 'react';

import { TextField } from '../../../../../components';
import InfoWarning from '../../../../../components/v2/InfoWarning/InfoWarning';
import { Typography } from '../../../../../components/v2/Typography/Typography';
import { getWordsCount } from '../../../../../helpers/textHelper';
import { useTextQuestion } from '../../../../../provider/questionProviders/textQuestionProvider';
import { QuestionType } from '../ModuleQuestion';

export type QuestionTextProps = {
  type: QuestionType.Text;
  value?: string | null;
  onChange?: (value: string) => Promise<void> | void;
  placeholder?: string;
  label?: string | React.ReactNode;
  renderFooter?: (value?: string | null) => React.ReactNode;
  suggestedMinWords?: number;
  isPrivate?: boolean;
  shouldRerender?: boolean;
};

export const QuestionText = memo(
  (props: QuestionTextProps) => {
    const {
      value,
      placeholder,
      label,
      renderFooter,
      suggestedMinWords,
      onChange,
      isPrivate,
    } = props;
    const [valueState, setValueStateFn] = useState<string | null | undefined>(
      value
    );
    const [wordsCount, setWordsCount] = useState<number>(0);
    const { setText } = useTextQuestion();

    const setValueState = (
      newValue: string | null | undefined,
      isInitial?: boolean
    ) => {
      setValueStateFn(newValue);
      setText(newValue || '', isInitial);
      setWordsCount(getWordsCount(newValue));
    };

    const changeHandler = useCallback(
      (newValue: string) => {
        setValueState(newValue);
        void onChange?.(newValue);
      },
      [valueState]
    );

    useEffect(() => {
      setValueState(value, true);
    }, [value]);

    const infoText = `In order for us to generate the best possible draft, we require a ${
      suggestedMinWords || 0
    }+ word response for this question.`;

    return (
      <div className='flex flex-col gap-4'>
        {suggestedMinWords && wordsCount < suggestedMinWords ? (
          <InfoWarning
            text={
              <Typography
                variant='functional-low'
                color='error'
                type='body-200'
                className='text-gray-700'
              >
                {infoText}
              </Typography>
            }
            variant='grey'
          />
        ) : null}
        {/* <input
        {...{ label, placeholder }}
        value={valueState || ""}
        onChange={(evt) => changeHandler(evt.target.value)}
      /> */}
        <TextField
          fontVariant='grotesk'
          {...{ label, placeholder }}
          labelVariant='outside'
          minRows={3}
          fullWidth
          multiline
          value={valueState || ''}
          onChange={(evt) => changeHandler(evt.target.value)}
          isPrivate={isPrivate}
        />
        <div className='w-full text-right'>
          <Typography
            variant='functional-low'
            type='body-200'
            className='text-forest-300'
          >
            {`${getWordsCount(valueState || '')}  words`}
          </Typography>
        </div>

        {typeof renderFooter === 'function' && (
          <div className='flex flex-col'>{renderFooter(valueState)}</div>
        )}
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (nextProps.shouldRerender) {
      return false;
    }
    return true;
  }
);
