/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState } from 'react';

import { getChangeEmailTokens, UserToken } from '../api/userTokens';

type UserTokensContextType = {
  userTokens: UserToken[];
  fetchUserTokens: () => void;
};

export const UserTokensContext = React.createContext<UserTokensContextType>({
  userTokens: [],
  fetchUserTokens: () => {},
});

export const UserTokensProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [userTokens, setUserTokens] = useState<UserToken[]>([]);

  const fetchUserTokens = async () => {
    try {
      const response = await getChangeEmailTokens();
      if (response.success) {
        setUserTokens(response.data);
      }
    } catch (error) {}
  };

  const value = {
    userTokens,
    fetchUserTokens,
  };
  // @ts-ignore
  return (
    <UserTokensContext.Provider value={value}>
      {children}
    </UserTokensContext.Provider>
  );
};

export const useUserTokens = () => {
  return useContext(UserTokensContext);
};
