import React from 'react';

type Containerprops = {
  children: React.ReactChild | React.ReactChild[];
  className?: string;
  fullWidth?: boolean;
};
const Container = (props: Containerprops) => {
  return (
    <div
      className={`grid grid-cols-4 md:grid-cols-6 lg:grid-cols-12 gap-4 px-4 md:px-16 ${!props.fullWidth ? 'lg:px-24' : 'lg:px-2'} w-full ${
        props.className ? props.className : ''
      }`}
    >
      {props.children}
    </div>
  );
};

export default Container;
