import './ProgressBar.scss';

type ProgressBarProps = {
    progress: number;
    total: number;
};



const ProgressBar = (props: ProgressBarProps) => {
    
    const getPercentage = () => {
        return String(props.progress * 100 / props.total) + '%'
    }
    
    return (
        <div className="progress-wrap progress bg-neutral-300 rounded-full">
            <div className="progress-bar progress bg-forest-primary"  style={{width: getPercentage()}}></div>
        </div>
    )
};

export default ProgressBar;