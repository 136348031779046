import { _request } from './request';

export type UserToken = {
  id: number;
};

export type GetUserTokenResponse = {
  success: boolean;
  message: string;
  data: UserToken[];
};

export const getChangeEmailTokens = async () => {
  const responseBody = await _request<GetUserTokenResponse>({
    url: `usertokens`,
    method: 'GET',
  });
  return responseBody;
};
