/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-assignment */
import { capitalizeFirstLetter } from '../../../helpers/helper';
import { interpolatePlaceholders } from '../../../helpers/placeholderHelper';
import { Question } from '../../Modules/common/moduleQuestion/ModuleQuestion';
import {
  ModuleQuestionProps,
  QuestionSelectVariantProp,
  QuestionType,
} from '../components';

export const parseModuleQuestionTypeProps = (
  question: Question
): QuestionType => {
  const selectTypes = ['options', 'declaration', 'pronouncement'];

  if (!!question.type && selectTypes.includes(question.type))
    return QuestionType.Select;

  if (question.type === 'text') return QuestionType.Text;

  if (question.type === 'dropdown') return QuestionType.Dropdown;

  return QuestionType.Select;
};

export const parseModuleQuestionVariant = (
  question: Question
): QuestionSelectVariantProp | undefined => {
  const collapsibleQuestionsTypes = ['declaration', 'pronouncement'];
  if (!!question.type && collapsibleQuestionsTypes.includes(question.type))
    return 'collapsible';
  if (question.identifier?.includes('ceremonyDuration')) return 'grid';
  return undefined;
};

type GetModuleQuestionPropsParams = {
  question: Question;
  placeholders?: Record<string, any>;
};

export const parseOptionProps = ({
  placeholders,
  question,
}: GetModuleQuestionPropsParams): Partial<ModuleQuestionProps> => {
  const getOptionLabel = (option: any) => {
    const value = option?.name || option?.option;
    return typeof value === 'string'
      ? capitalizeFirstLetter(
          interpolatePlaceholders({ text: value, data: placeholders })
        )
      : value;
  };

  const getOptionDescription = (option: any) => {
    const value = option?.description || option?.text;
    return typeof value === 'string'
      ? interpolatePlaceholders({ text: value, data: placeholders })
      : value;
  };

  return {
    getOptionLabel,
    getOptionDescription,
    isOptionEqualToValue: (option: any, value: any | null) =>
      option.id === value?.id,
    options: question.options?.sort((a,b) => a.id - b.id) || [],
    maxOptions: question.max_options,
  };
};

export const parseDropdownProps = ({
  question,
  placeholders,
}: GetModuleQuestionPropsParams): Partial<ModuleQuestionProps> => {
  if (question.type !== 'dropdown') return {};

  const getOptionLabel = (option: any) => {
    const value = option?.name || option?.option;
    return typeof value === 'string'
      ? capitalizeFirstLetter(
          interpolatePlaceholders({ text: value, data: placeholders })
        )
      : value;
  };

  const getOptionDescription = (option: any) => {
    const value = option?.description || option?.text;
    return typeof value === 'string'
      ? interpolatePlaceholders({ text: value, data: placeholders })
      : value;
  };

  const getOptionValue = (option: any) => {
    return option?.id;
  };

  return {
    getOptionLabel,
    getOptionDescription,
    getOptionValue,
    isOptionEqualToValue: (option: any, value: any | null) =>
      option.id === value?.id,
    options: question.options || [],
    maxOptions: question.max_options || 1,
  };
};

export const parseSubtitle = (question: Question) => {
  if (question.max_options) {
    const maxOptions = question.max_options || 1;
    return maxOptions >= 2
      ? `Select 1-${maxOptions} options from the list below`
      : null;
  }
  return null;
};

export const getModuleQuestionProps = (
  props: GetModuleQuestionPropsParams
): Partial<ModuleQuestionProps> => {
  const { question: questionData, placeholders } = props;
  const type = parseModuleQuestionTypeProps(questionData);
  const multiple = questionData.type === 'dropdown' || questionData.max_options != undefined;
  const variant = parseModuleQuestionVariant(questionData);
  const [question, hintText] = [
    interpolatePlaceholders({
      text: questionData.question,
      data: placeholders,
    }),
    interpolatePlaceholders({
      text: questionData.help_short_text || '',
      data: placeholders,
    }),
  ];
  return {
    type,
    multiple,
    variant,
    question,
    hintText,
    subtitle: parseSubtitle(questionData),
    ...parseOptionProps(props),
    ...parseDropdownProps(props),
  } as ModuleQuestionProps;
};
