import classNames from 'classnames';
import { HTMLAttributes } from 'react';

import { useCTA } from '../../../../provider/ctaProvider';
import { ModuleStatus } from '../../enum';

export type ModuleChapterHeaderProps = {
  renderIcon?: () => React.ReactNode;
  iconWrapperProps?: Partial<HTMLAttributes<HTMLDivElement>>;
  chapter?: React.ReactNode;
};

export const ModuleChapterHeader = ({
  renderIcon,
  chapter,
}: ModuleChapterHeaderProps) => {
  const { moduleStatus } = useCTA();
  const isInProgress =
    moduleStatus === ModuleStatus.inProgress ||
    moduleStatus === ModuleStatus.requiredAnswered;
  const isCompleted = moduleStatus === ModuleStatus.completed;
  const statusText = isInProgress
    ? 'In-progress'
    : isCompleted
    ? 'Completed'
    : 'Not Started';

  const hasIcon = typeof renderIcon === 'function';
  return (
    <div className='flex flex-row items-center gap-4 mt-10 md:mt-0'>
      {!!hasIcon && renderIcon()}
      {chapter && (
        <span className='flex-1 font-grotesk font-lg text-forest-500'>
          {chapter}
        </span>
      )}
      <span
        className={classNames(
          'px-2 py-1 border border-forest-400 text-xs font-medium rounded-lg select-none',
          {
            'text-forest-400': !isInProgress,
            'text-forest-500': isInProgress,
            'text-white bg-system-green-400 border-0': isCompleted,
          }
        )}
      >
        {statusText}
      </span>
    </div>
  );
};
