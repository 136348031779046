import { useMediaQuery } from '@mui/material';
import React, { useMemo } from 'react';

import { RichTextCommandDropdown } from './RichTextCommandDropdown';
import './rich-text-toolbar.scss';

export type Command = {
  icon: React.ReactNode;
  command: string;
};

export type Props = {
  command?: Command | Command[];
  grouped?: boolean;
  onClick?: (command: string) => Promise<void> | void;
  useDropdownOnMobile?: boolean;
  maxCommandsOnMobile?: number;
};

export const RichTextCommandButtonToolbar = ({
  command: commandProp,
  grouped = false,
  onClick,
  useDropdownOnMobile,
  maxCommandsOnMobile,
}: Props) => {
  const isMobile = true;
  const shouldRenderMobileDropdown = isMobile && useDropdownOnMobile === true;
  const command = useMemo(() => {
    if (Array.isArray(commandProp) && isMobile && maxCommandsOnMobile)
      return commandProp.slice(0, maxCommandsOnMobile);
    return commandProp;
  }, [commandProp, isMobile, maxCommandsOnMobile]);
  
  if (grouped && Array.isArray(command)) {
    return (
      <div className='RichTextToolbar-command-group'>
        {shouldRenderMobileDropdown && (
          <RichTextCommandDropdown
            label={command[0].icon}
            options={command.map((c) => ({ value: c.command, label: c.icon }))}
            onChange={onClick}
          />
        )}
        {!shouldRenderMobileDropdown &&
          command.map((command) => (
            <RichTextCommandButtonToolbar
              key={`grouped-buttom-command-${command.command}`}
              {...{ command, onClick }}
            />
          ))}
      </div>
    );
  }

  const { icon, command: commandValue } = command as Command;
  const className = 'RichTextToolbar-command-button';

  const clickHandler = () => {
    if (typeof onClick === 'function') void onClick(commandValue);
  }

  return (
    <button {...{ className, onClick: clickHandler }}>
      {icon}
    </button>
  );
};
