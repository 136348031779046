import { Ceremony, Member } from '../../../../api/ceremony';
import { DeclarationsQuestion } from '../../declarationOfIntent/DeclarationsQuestion';
import { CultureQuestion } from '../../directionalQuestions/CultureQuestion';
import { CeremonySpeakersQuestion } from '../../friendsAndFamily/CeremonySpeakersQuestion';
import { FriendsAndFamilyQuestion } from '../../friendsAndFamily/FriendsAndFamilyQuestion';
import { PronouncementsQuestion } from '../../pronouncement/PronouncementsQuestion';

import { DropdownQuestion } from './DropdownQuestion';
import { OptionsQuestion } from './OptionsQuestion';
import { TextQuestion } from './TextQuestion';
import './moduleQuestion.scss';

enum QuestionType {
  Dropdown = 'dropdown',
  Options = 'options',
  Text = 'text',
  Date = 'date',
  Readings = 'readings',
  Declaration = 'declaration',
  Pronouncement = 'pronouncement',
  Email = 'email',
}

export enum QuestionModule {
  directional = 'directional',
  rituals = 'rituals',
  vows = 'vows',
  declaration = 'declaration',
  pronouncement = 'pronouncement',
  friends = 'friends',
  remarks = 'remarks',
  couple1 = 'couple1',
  couple2 = 'couple2',
  guestsFFQ = 'guests_ffq',
  guestToast = 'guests_toast_builder',
  guestToastAi = 'guests_toast_builder_ai',
  vowsReflectional = 'vows_reflectional',
  vowsPromises = 'vows_promises',
  vowsFuture = 'vows_future',
  vowsAi = 'vows_ai',
}

export type Question = {
  id: number;
  module: QuestionModule;
  type?: string;
  question: string;
  help_short_text: string;
  help_detail_text?: string;
  info_text?: string;
  textfield_placeholder: string;
  identifier?: string;
  order?: number;
  has_textfield?: boolean;
  status?: boolean;
  options?: QuestionOptions[];
  answers?: QuestionAnswer[];
  max_options?: number;
  suggested_min_words?: number;
  individual_form_type?: 'individual' | 'vendor';
};

export type QuestionOptions = {
  icon: string;
  option: string;
  option_id?: string;
  description: string;
  id: number;
};

export type QuestionAnswer = {
  id?: number;
  ceremony_id: number;
  question_id: number;
  option_id?: string;
  option_answer?: string;
  text_answer?: string;
  name_answer?: string;
};

type QuestionProps = {
  questions: Question[];
  question: Question;
  couple1?: Member;
  couple2?: Member;
  officiant?: Member;
  ceremony?: Ceremony;
  currentUser?: Member;
  showTip?: boolean;
  readOnly?: boolean;
  setExceptionQuestionChanged?: (value: boolean) => void;
  setQuestionChanged?: (value: boolean) => void;
  setWhichQuestionChanged?: (value: string) => void;
  review?: boolean;
  className?: string;
  showQuestionText?: boolean;
  newReflectionQuestions?: boolean;
  numerotator?: number;
  drawerQuestion?: boolean;
  asterisk?: boolean;
};

export const ModuleQuestion = (props: QuestionProps) => {
  const {
    question,
    questions,
    couple1,
    couple2,
    officiant,
    ceremony,
    currentUser,
    setExceptionQuestionChanged,
    setQuestionChanged,
    setWhichQuestionChanged,
    showTip = true,
    readOnly,
    className,
    showQuestionText = true,
    newReflectionQuestions = false,
    numerotator = 0,
    drawerQuestion = false,
    asterisk = false,
  } = props;
  if (question.type === QuestionType.Options) {
    return (
      <OptionsQuestion
        question={question}
        couple1={couple1}
        couple2={couple2}
        officiant={officiant}
        ceremony={ceremony}
        currentUser={currentUser}
        setExceptionQuestionChanged={setExceptionQuestionChanged!}
        setQuestionChanged={setQuestionChanged!}
        setWhichQuestionChanged={setWhichQuestionChanged}
        showTip={showTip}
        newReflectionQuestions={newReflectionQuestions}
        readOnly={readOnly}
        className={className}
        numerotator={numerotator}
        drawerQuestion={drawerQuestion}
        asterisk={asterisk}
      />
    );
  } else if (question.type === QuestionType.Readings) {
    return null;
    // <ReadingsQuestion
    //   question={question}
    //   addReadingsQuestion={
    //     questions.find((q) => q.identifier === 'addReadings') || undefined
    //   }
    //   readersQuestion={
    //     questions.find((q) => q.identifier === 'readers') || undefined
    //   }
    //   couple1={couple1}
    //   couple2={couple2}
    //   officiant={officiant}
    //   ceremony={ceremony}
    //   currentUser={currentUser}
    //   setExceptionQuestionChanged={setExceptionQuestionChanged!}
    //   setQuestionChanged={setQuestionChanged!}
    //   showTip={showTip}
    //   readOnly={readOnly}
    // />
    // );
  } else if (question.type === QuestionType.Dropdown) {
    if (question.identifier === 'cultures') {
      return (
        <CultureQuestion
          question={question}
          couple1={couple1}
          couple2={couple2}
          officiant={officiant}
          ceremony={ceremony}
          currentUser={currentUser}
          showTip={showTip}
          readOnly={readOnly}
          setQuestionChanged={setQuestionChanged!}
          asterisk={asterisk}
        />
      );
    } else {
      return (
        <DropdownQuestion
          question={question}
          couple1={couple1}
          couple2={couple2}
          officiant={officiant}
          ceremony={ceremony}
          currentUser={currentUser}
          showTip={showTip}
          setQuestionChanged={setQuestionChanged!}
          readOnly={readOnly}
          drawerQuestion={drawerQuestion}
          asterisk={asterisk}
        />
      );
    }
  } else if (question.type === QuestionType.Text) {
    return (
      <TextQuestion
        question={question}
        couple1={couple1}
        couple2={couple2}
        officiant={officiant}
        ceremony={ceremony}
        currentUser={currentUser}
        showTip={showTip}
        setQuestionChanged={setQuestionChanged!}
        readOnly={readOnly}
        showQuestionText={showQuestionText}
        newReflectionQuestions={newReflectionQuestions}
        numerotator={numerotator}
        drawerQuestion={drawerQuestion}
        asterisk={asterisk}
      />
    );
  } else if (question.type === QuestionType.Declaration) {
    return (
      <DeclarationsQuestion
        question={question}
        couple1={couple1}
        couple2={couple2}
        officiant={officiant}
        ceremony={ceremony}
        currentUser={currentUser}
        showTip={showTip}
        setQuestionChanged={setQuestionChanged!}
        readOnly={readOnly}
        asterisk={asterisk}
      />
    );
  } else if (question.type === QuestionType.Pronouncement) {
    return (
      <PronouncementsQuestion
        question={question}
        couple1={couple1}
        couple2={couple2}
        officiant={officiant}
        ceremony={ceremony}
        currentUser={currentUser}
        showTip={showTip}
        setQuestionChanged={setQuestionChanged!}
        readOnly={readOnly}
        asterisk={asterisk}
      />
    );
  } else if (question.type === QuestionType.Email) {
    if (question.identifier === 'ceremonySpeakers') {
      return (
        <CeremonySpeakersQuestion
          question={question}
          couple1={couple1}
          couple2={couple2}
          officiant={officiant}
          ceremony={ceremony}
          currentUser={currentUser}
          showTip={showTip}
          setQuestionChanged={setQuestionChanged!}
          readOnly={readOnly}
          asterisk={asterisk}
        />
      );
    }
    if (question.identifier === 'friends') {
      return (
        <FriendsAndFamilyQuestion
          question={question}
          couple1={couple1}
          couple2={couple2}
          officiant={officiant}
          ceremony={ceremony}
          currentUser={currentUser}
          showTip={showTip}
          setQuestionChanged={setQuestionChanged!}
          readOnly={readOnly}
          asterisk={asterisk}
        />
      );
    }

    // else if (question.identifier === 'readers') {
    //   const readingsQuestion = questions.find(
    //     (q) => q.type === QuestionType.Readings
    //   );
    //   if (
    //     readingsQuestion !== undefined &&
    //     readingsQuestion.answers &&
    //     readingsQuestion.answers.length > 0
    //   ) {
    //     return (
    //       <ReadersQuestion
    //         question={question}
    //         ceremony={ceremony}
    //         readingsQuestion={readingsQuestion}
    //       />
    //     );
    //   } else {
    //     return null;
    //   }
    // }
  }
  return null;
};
