/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslist-disable @typescript-eslint/no-unsafe-call */
import React, { useContext, useEffect, useState } from 'react';
import ReactFbPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import TiktokPixel from 'tiktok-pixel';

import { SignInUpResponse, signUp, SignUpRequestBody } from '../api/auth';
import {
  generalLog,
  Member,
  MemberSubType,
  MemberType,
  newCeremony,
} from '../api/ceremony';
import {
  customerIoIdentify,
  sendCustomerIoEventHandler,
} from '../helpers/customerIoHelper';
import { getAPIErrorMessage, getCookie, setCookie } from '../helpers/helper';
import { _sendMetaEvent } from '../helpers/metaHelper';
import { steps } from '../helpers/onboardingHelper';

import { useAuth } from './authProvider';

type OnboardingContextType = {
  memberType: MemberType | string;
  setMemberType: (value: MemberType | string) => void;
  memberSubType: MemberSubType | string;
  setMemberSubType: (value: MemberSubType | string) => void;
  firstName: string;
  setFirstName: (value: string) => void;
  lastName: string;
  setLastName: (value: string) => void;
  partnerAName: string;
  setPartnerAName: (value: string) => void;
  partnerBName: string;
  setPartnerBName: (value: string) => void;
  partnerBFirstName: string;
  setPartnerBFirstName: (value: string) => void;
  partnerBLastName: string;
  setPartnerBLastName: (value: string) => void;
  partnerAEmail: string;
  setPartnerAEmail: (value: string) => void;
  partnerBEmail: string;
  setPartnerBEmail: (value: string) => void;
  partnerARole: string;
  setPartnerARole: (value: string) => void;
  partnerBRole: string;
  setPartnerBRole: (value: string) => void;
  weddingDate: string | undefined;
  setWeddingDate: (value: string | undefined) => void;
  weddingLocation: string;
  setWeddingLocation: (value: string) => void;
  weddingCity: string;
  setWeddingCity: (value: string) => void;
  weddingState: string;
  setWeddingState: (value: string) => void;
  weddingPlace: string;
  setWeddingPlace: (value: string) => void;
  email: string;
  setEmail: (value: string) => void;
  password: string;
  setPassword: (value: string) => void;
  subscribeEmail: boolean;
  setSubscribeEmail: (value: boolean) => void;
  createAccount: () => Promise<void>;
  currentStep: number;
  setCurrentStep: (value: number) => void;
  totalSteps: number;
  setTotalSteps: (value: number) => void;
  error: string;
  setError: (value: string) => void;
  avoidRedirect: boolean;
  setAvoidRedirect: (value: boolean) => void;
  createMembers: () => void;
  createCeremony: () => void;
  cleanMemebers: () => void;
  provSource: string;
  setProvSource: (value: string) => void;
  setLoggedInByGoogle: (value: boolean) => void;
  desiredTools: string[];
  setDesiredTools: (value: string[]) => void;
  userCountryCode: string;
  setUserCountryCode: (value: string) => void;
};

export const OnboardingContext = React.createContext<OnboardingContextType>({
  memberType: '',
  setMemberType: (value: MemberType | string) => {},
  memberSubType: '',
  setMemberSubType: (value: MemberSubType | string) => {},
  firstName: '',
  setFirstName: (value: string | null) => {},
  lastName: '',
  setLastName: (value: string | null) => {},
  partnerAName: '',
  setPartnerAName: (value: string) => {},
  partnerBName: '',
  partnerBFirstName: '',
  setPartnerBFirstName: (value: string) => {},
  partnerBLastName: '',
  setPartnerBLastName: (value: string) => {},
  setPartnerBName: (value: string) => {},
  partnerAEmail: '',
  setPartnerAEmail: (value: string) => {},
  partnerBEmail: '',
  setPartnerBEmail: (value: string) => {},
  partnerARole: '',
  setPartnerARole: (value: string) => {},
  partnerBRole: '',
  setPartnerBRole: (value: string) => {},
  weddingDate: '',
  setWeddingDate: (value: string | undefined) => {},
  weddingLocation: '',
  setWeddingLocation: (value: string) => {},
  weddingCity: '',
  setWeddingCity: (value: string) => {},
  weddingState: '',
  setWeddingState: (value: string) => {},
  weddingPlace: '',
  setWeddingPlace: (value: string) => {},
  email: '',
  setEmail: (value: string) => {},
  password: '',
  setPassword: (value: string) => {},
  subscribeEmail: false,
  setSubscribeEmail: (value: boolean) => {},
  createAccount: () => new Promise(() => {}),
  currentStep: 0,
  setCurrentStep: (value: number) => {},
  totalSteps: 0,
  setTotalSteps: (value: number) => {},
  error: '',
  setError: (value: string | null) => {},
  avoidRedirect: false,
  setAvoidRedirect: (value: boolean) => {},
  createMembers: () => {},
  createCeremony: () => {},
  cleanMemebers: () => {},
  provSource: '',
  setProvSource: (value: string) => {},
  setLoggedInByGoogle: (value: boolean) => {},
  desiredTools: [],
  setDesiredTools: (value: string[]) => {},
  userCountryCode: '',
  setUserCountryCode: (value: string) => {},
});

export const OnboardingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [memberType, setMemberType] = useState<MemberType | string>('');
  const [memberSubType, setMemberSubType] = useState<MemberSubType | string>(
    ''
  );
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [partnerBFirstName, setPartnerBFirstName] = useState<string>('');
  const [partnerBLastName, setPartnerBLastName] = useState<string>('');
  const [partnerAName, setPartnerAName] = useState('');
  const [partnerBName, setPartnerBName] = useState('');
  const [partnerAEmail, setPartnerAEmail] = useState('');
  const [partnerBEmail, setPartnerBEmail] = useState('');
  const [partnerARole, setPartnerARole] = useState('');
  const [partnerBRole, setPartnerBRole] = useState('');
  const [weddingDate, setWeddingDate] = useState<string | undefined>('');
  const [weddingLocation, setWeddingLocation] = useState('');
  const [weddingCity, setWeddingCity] = useState('');
  const [weddingState, setWeddingState] = useState('');
  const [weddingPlace, setWeddingPlace] = useState('');
  const [subscribeEmail, setSubscribeEmail] = useState(true);
  const [members, setMembers] = useState<Array<Member>>([]);
  const [officiants, setOfficiants] = useState<Array<Member>>([]);
  const [token, setToken] = useState<string>('');
  const [currentStep, setCurrentStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(7);
  const [error, setError] = useState<string>('');
  const [avoidRedirect, setAvoidRedirect] = useState<boolean>(false);
  const [provSource, setProvSource] = useState<string>('');
  const [loggedInByGoogle, setLoggedInByGoogle] = useState<boolean>(false);
  const [desiredTools, setDesiredTools] = useState<string[]>([]);
  const [userCountryCode, setUserCountryCode] = useState<string>('');

  useEffect(() => {
    if (
      (members.length >= 2 ||
        (memberType === 'couple' && members.length >= 1)) &&
      !avoidRedirect &&
      (token || loggedInByGoogle)
    ) {
      if (memberType !== 'wedding_planner') {
        void (async () => {
          await createCeremony();
        })();
      } else {
        navigate(`/ceremony/planner/dashboard`);
      }
    }
  }, [members, token, loggedInByGoogle]);

  useEffect(() => {
    if (memberType === 'couple') {
      setPartnerAName(firstName.trim() + ' ' + lastName.trim());
      setPartnerAEmail(email);
    }
  }, [memberType, firstName, lastName, email]);

  useEffect(() => {
    memberType &&
      setTotalSteps(steps[memberType as keyof typeof steps].length + 1);
  }, [memberType]);

  const createAccount = async () => {
    try {
      //get cookie
      const prov_source = getCookie('prov_source');
      const referral_id = getCookie('referId');

      if (
        partnerAEmail.length > 0 &&
        partnerAEmail.toLocaleLowerCase() == partnerBEmail.toLocaleLowerCase()
      ) {
        setError("Please introduce a different email than your partner's.");
        return;
      }

      const registerLoginBody: SignUpRequestBody = {
        email: email,
        password: password,
        full_name: `${firstName.trim()} ${lastName.trim()}`,
        subscribe_email: subscribeEmail,
        terms: true,
        source: prov_source,
        member_type: memberType as MemberType,
        referral_id: referral_id ? referral_id : undefined,
        desired_tools: desiredTools,
        country_code: userCountryCode,
      };
      const registerResponse: SignInUpResponse = await signUp(
        registerLoginBody
      );
      if (registerResponse.success) {
        setCookie('prov_source', '', 0);

        setToken(registerResponse.token);

        customerIoIdentify({ email });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        ReactFbPixel.track('CompleteRegistration');
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        TiktokPixel.track('CompleteRegistration');
        // ReactPinterestTag.track('Signup');

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        ReactGA.event({
          category: 'Signup',
          action: 'User',
          label: 'Finished',
        });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        ReactGA.event({
          category: 'Signup',
          action: 'SignupFinished',
          label: 'SignupFinished',
        });

        // ReactGA.event('sign_up');

        await auth.signin(registerResponse.token, () => {
          void sendCustomerIoEventHandler('Signup Completed', {});
          // _sendMetaEvent('CompleteRegistration');
        });

        createMembers();
      } else {
        setError(registerResponse.message + '. Maybe user already exists.');
      }
    } catch (err) {
      setError(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getAPIErrorMessage(err as any) + '. Maybe user already exists.'
      );
    }
  };

  const createMembers = () => {
    //empty members
    setMembers([]);
    //empty officiants
    setOfficiants([]);

    let tempMembers: Array<Member> = [];

    if (memberType === 'officiant') {
      setOfficiants([
        {
          legal_name: firstName.trim() + ' ' + lastName.trim(),
          email: email,
          member_type: memberType as MemberType,
          preferred_name: firstName.trim(),
        },
      ]);
    }

    if (memberType === 'wedding_planner') {
      //add to members
      tempMembers = [
        ...members,
        {
          legal_name: firstName.trim() + ' ' + lastName.trim(),
          email: email,
          member_type: memberType as MemberType,
          preferred_name: firstName.trim(),
        },
      ];
    }

    if (memberType === 'guest') {
      //add to members
      tempMembers = [
        ...members,
        {
          legal_name: firstName.trim() + ' ' + lastName.trim(),
          email: email,
          member_type: memberType as MemberType,
          member_sub_type: memberSubType as MemberSubType,
          preferred_name: firstName.trim(),
        },
      ];
    }

    tempMembers = [
      ...tempMembers,
      {
        legal_name: partnerAName,
        email: memberType === 'couple' ? email : partnerAEmail,
        member_type: partnerARole as MemberType,
        preferred_name: partnerAName.split(' ')[0],
      },
      {
        legal_name: partnerBName.length > 1 ? partnerBName : 'TBD TBD',
        member_type: (partnerBRole as MemberType) || 'other',
        preferred_name: partnerBName.split(' ')[0] || 'TBD',
        email: partnerBEmail || '',
      },
    ];

    setMembers(tempMembers);
  };

  const getProvSourceURL = (cer_id: string) => {
    let provRedirect = '';

    if (provSource == 'ceremony_builder')
      provRedirect = `/ceremony-designer/${cer_id}`;
    else if (provSource == 'vows')
      provRedirect = `/ceremony/${cer_id}/vowbuilder`;
    else if (provSource == 'paywall_vows')
      provRedirect = `/pay-plans/${cer_id}?refferedFrom=vows`;
    else if (provSource == 'paywall_ceremony')
      provRedirect = `/pay-plans/${cer_id}?refferedFrom=CeremonyBuilderBanner`;

    return provRedirect;
  };

  const createCeremony = async () => {
    try {
      const referral_id = getCookie('referId');
      const urlParams = new URLSearchParams(window.location.search);
      const redirectVows = urlParams.get('redirect') === 'vows';

      const response = await newCeremony({
        wedding_place: weddingLocation,
        state: weddingState,
        city: weddingCity,
        venue: weddingPlace,
        date: weddingDate ?? undefined,
        members: members,
        officiants: officiants,
        speakers: [],
        referral_id: referral_id ?? '',
      });

      if (response.success && !avoidRedirect) {
        setCookie('referId', '', 0);

        if (redirectVows && response?.cer_id) {

          void generalLog({
            ceremony_id: response.cer_id,
            activity: `Signup - Vows`,
          });

          return navigate(`/ceremony/${response.cer_id}/vows`);
        }

        if (!response.cer_id) {
          navigate('/dashboard');
        } else if (provSource) {
          navigate(getProvSourceURL(response.cer_id));
        } else {
          
          navigate('/dashboard');
        }
      }
      return response;
    } catch (err) {
      console.log(err);
      setError(getAPIErrorMessage(err as any));
    }
  };

  const cleanMemebers = () => {
    setMembers([]);
  };

  const value = {
    memberType,
    setMemberType,
    memberSubType,
    setMemberSubType,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    partnerAName,
    setPartnerAName,
    partnerBName,
    setPartnerBName,
    partnerBFirstName,
    setPartnerBFirstName,
    partnerBLastName,
    setPartnerBLastName,
    partnerAEmail,
    setPartnerAEmail,
    partnerBEmail,
    setPartnerBEmail,
    partnerARole,
    setPartnerARole,
    partnerBRole,
    setPartnerBRole,
    weddingDate,
    setWeddingDate,
    weddingLocation,
    setWeddingLocation,
    weddingCity,
    setWeddingCity,
    weddingState,
    setWeddingState,
    weddingPlace,
    setWeddingPlace,
    email,
    setEmail,
    password,
    setPassword,
    subscribeEmail,
    setSubscribeEmail,
    createAccount,
    currentStep,
    setCurrentStep,
    totalSteps,
    setTotalSteps,
    error,
    setError,
    avoidRedirect,
    setAvoidRedirect,
    createMembers,
    createCeremony,
    cleanMemebers,
    provSource,
    setProvSource,
    setLoggedInByGoogle,
    desiredTools,
    setDesiredTools,
    userCountryCode,
    setUserCountryCode,
  };

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => {
  return useContext(OnboardingContext);
};
