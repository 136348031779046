import './YoutubeEmbed.scss'

const YoutubeEmbed = ({ embedId }: { embedId: string }) => (
  <div className='video-responsive YoutubeEmbed mb-10 lg:mb-0'>
    <iframe
      className='w-full h-full min-h-[204px] lg:min-w-[576px] lg:min-h-[324px]'
      src={`https://www.youtube.com/embed/${embedId}?autoplay=1`}
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    />
  </div>
);

export default YoutubeEmbed;
