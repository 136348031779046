import CloseIcon from '@mui/icons-material/Close';
import { Chip } from '@mui/material';

type ChipProps = {
  label: string;
  onDeleteClick?: (value: string) => void;
};

export const CultureChip = (props: ChipProps) => {
  const { label } = props;
  return (
    <Chip
      className='sm:mx-2 text-xs h-6 w-full my-1 sm:w-fit'
      label={label.toUpperCase()}
      style={{ backgroundColor: '#DAA78C', color: '#ffffff' }}
    />
  );
};

export const PlacementChip = (props: ChipProps) => {
  const { label } = props;
  return (
    <Chip
      className='sm:mx-2 text-xs h-6 w-full my-1 sm:w-fit'
      label={label.toUpperCase()}
      style={{ backgroundColor: '#B67D00', color: '#ffffff' }}
    />
  );
};

export const MethodChip = (props: ChipProps) => {
  const { label } = props;
  return (
    <Chip
      className='sm:mx-2 text-xs h-6 w-full my-1 sm:w-fit'
      label={label.toUpperCase()}
      style={{ backgroundColor: '#354740', color: '#ffffff' }}
    />
  );
};

export const FilterChip = (props: ChipProps) => {
  const { label, onDeleteClick } = props;
  return (
    <Chip
      sx={{
        '& .MuiChip-deleteIcon': {
          color: '#354740',
          width: 14,
          height: 14,
          ':hover': {
            color: '#354740',
          },
        },
      }}
      variant='outlined'
      deleteIcon={<CloseIcon />}
      className='sm:mr-2 text-sm h-8 w-full my-1 sm:w-fit'
      label={label}
      // style={{
      //   border: '1px solid #9AA3A0',
      //   color: '#354740',
      //   backgroundColor: 'transparent',
      // }}
      onDelete={onDeleteClick}
    />
  );
};
