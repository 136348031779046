import { useNavigate } from 'react-router-dom';

import { Ceremony, Member , deactivateCeremony } from '../../../api/ceremony';
import { getCeremonyTitleFromCoupleName } from '../../../helpers/ceremonyHelper';
import { getAPIErrorMessage } from '../../../helpers/helper';
import useDate from '../../../hooks/useDate';
import useLoader from '../../../hooks/useLoader';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useDashboard } from '../../../provider/dashboardProvider';
import Button from '../../button';
import ButtonPill from '../ButtonPill';
import { Modal } from '../Modal/Modal';
import { Typography } from '../Typography/Typography';

type Props = {
  userId: string;
  ceremony: Ceremony;
};

export const DeactivatingModal = (props: Props) => {
  const { userId } = props;
  const { 
    showDeactivateModal,
    setShowDeactivateModal, 
    couple1, 
    couple2, 
    ceremony 
  } = useDashboard()

  const navigate = useNavigate()
  const { showLoader, hideLoader } = useLoader()
  const { openSnackBar } = useSnackbar();

  let coupleNamesString = '';
  let coupleNamesStringShort = '';
  if (couple1 && couple2 && couple2.legal_name !== 'TBD TBD') {
    coupleNamesString = `${couple1?.preferred_name} & ${couple2?.preferred_name}`;
    coupleNamesStringShort = `${couple1?.preferred_name[0]}&${couple2?.preferred_name[0]}`;
  } else if (couple1) {
    coupleNamesString = couple1?.preferred_name;
    coupleNamesStringShort = couple1?.preferred_name[0];
  } else if (couple2) {
    coupleNamesString = couple2?.preferred_name;
    coupleNamesStringShort = couple2?.preferred_name[0];
  }

  const onClose = () => {
    setShowDeactivateModal(false)
  }
  const removeEvent = async () => {
    setShowDeactivateModal(false)
    showLoader()

    try {
      if (!ceremony) return
      const response = await deactivateCeremony(ceremony.id.toString())
      if (response.success) navigate('/dashboard')
      else openSnackBar(response.message, 5000, 'error');
    }
    catch (error){
      openSnackBar(getAPIErrorMessage(error),5000, 'error');
    }
    finally {
      hideLoader()
    }
  }

  const _formatDate = (date: string | undefined) => {
    const formattedDate = useDate(date).formattedDateMonthDayYear;
    return formattedDate ? ' (' + formattedDate + ')' : '';
  };

  return (
    <Modal isOpen={showDeactivateModal} onClose={onClose} title={''}>
      <div>
        <Typography
            type='display-400'
        >
            Remove this event? 
        </Typography>
        <Typography
            type='body-400'
        >
            <p>Your are about to remove <b>{getCeremonyTitleFromCoupleName(coupleNamesString)} {_formatDate(ceremony?.date)}</b> from your account. This will also remove it for anyone who was added to this wedding.</p>
        </Typography>
        <div className='flex w-full justify-end mt-6'>
          <ButtonPill
            text='Remove this event'
            onClick={removeEvent}
            className='text-white border-red-800 bg-red-800'
          />
          <ButtonPill
            text='Keep Event'
            onClick={onClose}
          />

        </div>
      </div>
    </Modal>
  );
};