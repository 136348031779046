import { useState } from 'react';

import { Pronouncement } from '../../../api/pronouncement';
import { Modal } from '../../../components/modal/Modal';

import { PronouncementItem } from './PronouncementItem';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onUsePronouncementClick?: (PronouncementId: number) => void;
  pronouncements?: Pronouncement[];
};

export const PronouncementsModal = (props: Props) => {
  const { isOpen, onClose, pronouncements, onUsePronouncementClick } = props;
  const [expanded, setExpanded] = useState<number | null>(null);

  const handleSetExpanded = (PronouncementId: number) => {
    if (expanded === PronouncementId) {
      setExpanded(null);
    } else {
      setExpanded(PronouncementId);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={'Pronouncements'}>
      <div className='readings-modal'>
        {pronouncements &&
          pronouncements.map((d: Pronouncement) => (
            <PronouncementItem
              key={d.id}
              expanded={d.id === expanded || pronouncements.length === 1}
              setExpanded={handleSetExpanded}
              pronouncement={d}
              onUsePronouncementClick={onUsePronouncementClick}
            />
          ))}
      </div>
    </Modal>
  );
};
