/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Formik, Form, Field } from 'formik';
import { useEffect, useState } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import * as Yup from 'yup';

import { Ceremony, Member } from '../../../api/ceremony';
import {
  SpeakerBody,
  saveSpeakers,
  getSpeakers,
  Speaker,
  GetSpeakersResponse,
  removeSpeaker,
  RemoveSpeakerBody,
  reorderSpeaker,
  orderMember,
  reOrderMemberBody,
  sendSpeakerInvitation,
  rolesDropdown,
  eventDropdown,
} from '../../../api/friends';
import { saveAnswer } from '../../../api/question';
import SnackbarComponent from '../../../components/Snackbar/Snackbar';
import Button from '../../../components/button';
import CustomRadioSelect from '../../../components/forms/CustomRadioSelect';
import CustomTextInput from '../../../components/forms/CustomTextInput';
import { getAPIErrorMessage, getValueById } from '../../../helpers/helper';
import { replacePlaceholders } from '../../../helpers/placeholderHelper';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useActiveQuestion } from '../../../provider/activeQuestionProvider';
import {
  Question,
  QuestionAnswer,
} from '../common/moduleQuestion/ModuleQuestion';
import { Tip } from '../common/tip/Tip';

import { CustomFormikInput } from './CustomFormikInput';
import { CustomFormikSelect } from './CustomFormikSelect';
import SpeakerAccordian from './SpeakerAccordian';

type QuestionProps = {
  question: Question;
  setQuestionChanged: (value: boolean) => void;
  couple1?: Member;
  couple2?: Member;
  officiant?: Member;
  currentUser?: Member;
  ceremony?: Ceremony;
  showTip?: boolean;
  readOnly?: boolean;
  asterisk?: boolean;
};

type speakersByEvent = {
  engagement_party: Speaker[];
  rehearsal_dinner: Speaker[];
  reception: Speaker[];
  engagement_party_time: number;
  rehearsal_dinner_time: number;
  reception_time: number;
};

export const CeremonySpeakersQuestion = (props: QuestionProps) => {
  const { isActive, message, type, openSnackBar, handleClose } = useSnackbar();
  const { activeQuestionId, setActiveQuestionId } = useActiveQuestion();
  const [expanded, setExpanded] = useState<number>(0);

  const {
    question,
    ceremony,
    showTip = true,
    couple1,
    couple2,
    officiant,
    currentUser,
    readOnly,
    setQuestionChanged,
    asterisk
  } = props;
  const [title, setTitle] = useState<string>('Add speaker');
  const [changed, setChanged] = useState<boolean>(false);
  const [tipExpanded, setTipExpanded] = useState<number>(0);
  const [optionId, setOptionId] = useState<string | null>(null);
  const [textAnswer, setTextAnswer] = useState<string>('');
  const [showAdditionOption, setShowAdditionOption] = useState<boolean>(false);
  const [speakersFromDb, setSpeakersFromDb] = useState<Speaker[]>([]);
  const [speakersByEvent, setSpeakersByEvent] = useState<speakersByEvent>({
    engagement_party: [],
    rehearsal_dinner: [],
    reception: [],
    engagement_party_time: 0,
    reception_time: 0,
    rehearsal_dinner_time: 0,
  });
  const initialVals = {
    speaker_alloted_time: 1,
    speaker_email: '',
    speaker_name: '',
    speaker_note: '',
    speaker_event: '',
    speaker_role: '',
  };

  const newSpeakerSchema = Yup.object().shape({
    speaker_alloted_time: Yup.number()
      .required('Required')
      .min(0, 'minimum 1 minute required')
      .max(100, 'maximum 100 minutes'),
    speaker_email: Yup.string().email('Invalid email').required('Required'),
    speaker_name: Yup.string().required('Speaker Name required'),
    speaker_note: Yup.string(),
    speaker_event: Yup.string().required('Please select an event'),
    speaker_role: Yup.string().required('Please select a role'),
  });

  useEffect(() => {
    if (question && question.answers) {
      const answer = question.answers[0];
      if (answer && answer.option_id) {
        setOptionId(answer.option_id);
        setShowAdditionOption(
          getValueById(answer.option_id, question.options || []).option ===
            'Yes'
            ? true
            : false
        );
        setTextAnswer(answer.text_answer || '');
      }
    }
  }, [question]);

  useEffect(() => {
    void fetchSpeakers();
  }, [ceremony]);

  useEffect(() => {
    if (speakersFromDb.length > 0) {
      let reception_time = 0;
      const reception: Speaker[] = [];
      let rehearsal_dinner_time = 0;
      const rehearsal_dinner: Speaker[] = [];
      let engagement_party_time = 0;
      const engagement_party: Speaker[] = [];

      speakersFromDb.forEach((speaker: Speaker) => {
        switch (speaker.event) {
          case 'reception':
            reception.push(speaker);
            reception_time = reception_time + speaker.alloted_time;
            break;
          case 'rehearsal_dinner':
            rehearsal_dinner.push(speaker);
            rehearsal_dinner_time =
              rehearsal_dinner_time + speaker.alloted_time;
            break;
          case 'engagement_party':
            engagement_party.push(speaker);
            engagement_party_time =
              engagement_party_time + speaker.alloted_time;
            break;
          default:
            break;
        }
      });
      const speaker: speakersByEvent = {
        engagement_party: engagement_party.sort((a: Speaker, b: Speaker) =>
          sortFunction(a, b)
        ),
        reception: reception.sort((a: Speaker, b: Speaker) =>
          sortFunction(a, b)
        ),
        rehearsal_dinner: rehearsal_dinner.sort((a: Speaker, b: Speaker) =>
          sortFunction(a, b)
        ),
        reception_time: reception_time,
        rehearsal_dinner_time: rehearsal_dinner_time,
        engagement_party_time: engagement_party_time,
      };
      setSpeakersByEvent(speaker);
    } else {
      setSpeakersByEvent({
        engagement_party: [],
        rehearsal_dinner: [],
        reception: [],
        engagement_party_time: 0,
        reception_time: 0,
        rehearsal_dinner_time: 0,
      });
    }
  }, [speakersFromDb]);

  useEffect(() => {
    if (activeQuestionId && activeQuestionId !== question.id) {
      setTipExpanded(0);
    } else if (activeQuestionId && activeQuestionId === question.id) {
      setTipExpanded(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuestionId]);

  const sortFunction = (a: Speaker, b: Speaker) => {
    if (a.order == null) return 1;
    if (b.order == null) return -1;
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  };

  const fetchSpeakers = async () => {
    if (ceremony) {
      try {
        const response: GetSpeakersResponse = await getSpeakers(ceremony?.id);
        if (response.success && response.data) {
          setSpeakersFromDb(response.data);
        }
      } catch (error) {}
    }
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(true);
    setTitle('Add Speaker');
    setOptionId(ev.target.value);
    setShowAdditionOption(
      getValueById(ev.target.value, question.options || []).option === 'Yes'
        ? true
        : false
    );
    void saveQuestionAnswer(ev.target.value);
  };

  const saveQuestionAnswer = async (optionId: string) => {
    if (ceremony) {
      const answer: QuestionAnswer = {
        ceremony_id: ceremony.id,
        question_id: question.id,
        option_id: optionId,
        text_answer: textAnswer,
      };
      try {
        const saveResponse = await saveAnswer(answer);
        if (saveResponse.success) {
          openSnackBar('Answer saved', 5000, 'success');
        } else {
          handleError();
        }
      } catch (err) {
        handleError();
      }
    }
  };

  const handleTextChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(true);
    setTitle('Add Speaker');
    setTextAnswer(ev.target.value);
  };

  const handleError = (message = 'Unexpected error') => {
    setChanged(true);
    openSnackBar(message, 5000, 'error');
    setTitle('Add speaker');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/require-await
  const handleSave = async (values: SpeakerBody, resetForm: any) => {
    if (ceremony) {
      const answer: QuestionAnswer = {
        ceremony_id: ceremony.id,
        question_id: question.id,
        option_id: optionId || undefined,
        text_answer: textAnswer,
      };
      setChanged(false);
      setTitle('Adding Speaker');
      try {
        const saveResponse = await saveAnswer(answer);
        if (saveResponse.success) {
          try {
            const saveSpeakerResponse = await saveSpeakers({
              ...values,
              ceremony_id: ceremony.id,
            });
            if (saveSpeakerResponse.success) {
              setTitle('Add Speaker');
              setQuestionChanged(true);
              await fetchSpeakers();
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              resetForm();
            } else {
              handleError(saveSpeakerResponse.message);
            }
          } catch (error) {
            handleError(getAPIErrorMessage(error as any));
          }
        } else {
          handleError(saveResponse.message);
        }
      } catch (error) {
        handleError(getAPIErrorMessage(error as any));
      }
    }
  };

  const onRemove = async (speakerId: number) => {
    // Todo
    try {
      const removeBody: RemoveSpeakerBody = {
        speaker_id: speakerId,
      };
      const saveResponse = await removeSpeaker(removeBody);
      if (saveResponse.success) {
        try {
          await fetchSpeakers();
          openSnackBar('Member removed.', 5000, 'success');
        } catch (error) {
          handleError();
        }
      } else {
        handleError();
      }
    } catch (error) {
      handleError();
    }
  };

  const mappedOptions = question.options?.map((o) => ({
    label: o.option,
    value: o.id,
  }));

  const onDragEnd = (result: DropResult, array: Speaker[], event: string) => {
    const newItems = Array.from(array);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result?.destination?.index || 0, 0, removed);
    const order: orderMember[] = [];
    newItems.forEach((speaker, index) => {
      order.push({ sid: speaker.id, order: index + 1 });
    });
    order.length > 1 && void onReorder(order);
    setSpeakersByEvent({ ...speakersByEvent, [event]: newItems });
  };

  const onReorder = async (order: orderMember[]) => {
    // Todo
    try {
      const removeBody: reOrderMemberBody = {
        order: order,
      };
      const saveResponse = await reorderSpeaker(removeBody);
      if (saveResponse.success) {
        try {
          openSnackBar('Speaker order changed.', 5000, 'success');
        } catch (error) {
          handleError();
        }
      } else {
        handleError();
      }
    } catch (error) {
      handleError();
    }
  };

  const sendSpeakerInvitations = async () => {
    if (ceremony) {
      try {
        const saveResponse = await sendSpeakerInvitation(ceremony.id);
        if (saveResponse.success) {
          try {
            openSnackBar(
              'Invitations have been sent succesfully.',
              5000,
              'success'
            );
          } catch (error) {
            handleError();
          }
        } else {
          handleError();
        }
      } catch (error) {
        handleError();
      }
    }
  };

  return (
    <div className='col-span-4 md:col-span-6 lg:col-span-12 grid grid-cols-4 md:grid-cols-6 lg:grid-cols-12'>
      <SnackbarComponent
        isActive={isActive}
        message={message}
        type={type}
        handleClose={handleClose}
      />
      <div
        onClick={() => setActiveQuestionId(question.id)}
        className='question-container mb-8 px-4 md:px-11 py-6 border col-span-4 md:col-span-6 lg:col-span-9'
      >
        <div className='mx-2 my-3 font-nanum text-2xl leading-8'>
          {replacePlaceholders(
            question.question,
            couple1?.preferred_name || couple1?.legal_name,
            couple2?.preferred_name || couple2?.legal_name,
            officiant?.preferred_name || officiant?.legal_name,
            currentUser?.preferred_name || currentUser?.legal_name
          )}
          { asterisk ? <span className='ml-1' style={{color: '#995D30'}}>*</span> : null }
        </div>
        <CustomRadioSelect
          options={mappedOptions}
          onChange={handleChange}
          value={optionId}
          name={'ceremonySpeaker'}
          row={question.options && question.options.length > 2}
          readOnly={readOnly}
        />

        <Formik
          initialValues={initialVals}
          validationSchema={newSpeakerSchema}
          onSubmit={async (values, { resetForm }) => {
            await handleSave(values, resetForm);
          }}
        >
          {({ values, errors, touched }) => (
            <Form>
              {showAdditionOption && (
                <>
                  <div>
                    <div className='flex flex-col md:flex-row w-full ml-2'>
                      <Field
                        name={`speaker_name`}
                        placeholder='Name'
                        type='text'
                        setChanged={() => setChanged(true)}
                        component={CustomFormikInput}
                        readOnly={readOnly}
                        className={'mt-4 h-fit'}
                        error={touched.speaker_name && !!errors.speaker_name}
                        helperText={touched.speaker_name && errors.speaker_name}
                      />
                      <Field
                        name={`speaker_email`}
                        placeholder='Email'
                        type='email'
                        className={'mt-4 h-fit'}
                        component={CustomFormikInput}
                        setChanged={() => setChanged(true)}
                        readOnly={readOnly}
                        error={touched.speaker_email && !!errors.speaker_email}
                        helperText={
                          touched.speaker_email && errors.speaker_email
                        }
                      />
                    </div>
                    <div className='flex flex-col md:flex-row w-full ml-2'>
                      <Field
                        name={`speaker_role`}
                        placeholder='Role'
                        type='text'
                        component={CustomFormikSelect}
                        setChanged={() => setChanged(true)}
                        readOnly={readOnly}
                        options={rolesDropdown}
                        className={'w-full md:w-1/3 mt-4 h-fit'}
                        error={touched.speaker_role && !!errors.speaker_role}
                        helperText={touched.speaker_role && errors.speaker_role}
                      />
                      <Field
                        name={`speaker_event`}
                        placeholder='Event'
                        type='text'
                        component={CustomFormikSelect}
                        setChanged={() => setChanged(true)}
                        readOnly={readOnly}
                        noSort={true}
                        options={eventDropdown}
                        className={'w-full md:w-1/3 mt-4 h-fit'}
                        error={touched.speaker_event && !!errors.speaker_event}
                        helperText={
                          touched.speaker_event && errors.speaker_event
                        }
                      />
                      <Field
                        name={`speaker_alloted_time`}
                        placeholder='Alloted Time (minutes)'
                        type='number'
                        component={CustomFormikInput}
                        setChanged={() => setChanged(true)}
                        readOnly={readOnly}
                        value={values.speaker_alloted_time}
                        className={'w-full md:w-1/3 mt-4 h-fit'}
                        error={
                          touched.speaker_alloted_time &&
                          !!errors.speaker_alloted_time
                        }
                        helperText={
                          touched.speaker_alloted_time &&
                          errors.speaker_alloted_time
                        }
                      />
                    </div>
                    <div className='flex flex-row w-full ml-2'>
                      <Field
                        name={`speaker_note`}
                        placeholder='Is there anything you would like them to focus on or not mention in their speech?'
                        type='text'
                        className={'mt-4 h-fit w-full'}
                        component={CustomFormikInput}
                        setChanged={() => setChanged(true)}
                        readOnly={readOnly}
                        error={touched.speaker_email && !!errors.speaker_email}
                        helperText={
                          touched.speaker_email && errors.speaker_email
                        }
                      />
                    </div>
                  </div>

                  {question.has_textfield && (
                    <CustomTextInput
                      className='mt-4'
                      multiline
                      rows={5}
                      value={textAnswer}
                      onChange={handleTextChange}
                      placeholder={question.textfield_placeholder}
                      disabled={optionId === ''}
                      readOnly={readOnly}
                    />
                  )}
                </>
              )}
              {showAdditionOption && (
                <Button
                  disabled={!changed}
                  title={title}
                  type={'submit'}
                  className={'mt-4 ml-2 px-2 py-1'}
                ></Button>
              )}
            </Form>
          )}
        </Formik>
        {/* render friends here */}

        {speakersByEvent.engagement_party.length > 0 && (
          <div className='mx-2 mt-10'>
            <div className='flex flex-row justify-between items-center w-full'>
              <h1 className='font-nanum text-bold text-2xl text-forest-primary'>
                Speakers at the engagement party
              </h1>
              <div>
                <h3 className='font-nanum text-lg text-forest-primary text-right'>
                  {speakersByEvent.engagement_party_time} min
                </h3>
                <p className='text-copper-primary text-xxs uppercase text-right'>
                  Total speaker time
                </p>
              </div>
            </div>
            <DragDropContext
              onDragEnd={(result) =>
                onDragEnd(
                  result,
                  speakersByEvent.engagement_party,
                  'engagement_party'
                )
              }
            >
              <Droppable droppableId='speakersByEvent.engagement_party'>
                {(provided) => (
                  <div {...provided.innerRef} ref={provided.innerRef}>
                    {speakersByEvent.engagement_party.map((speaker, index) => (
                      <Draggable
                        key={speaker.id}
                        draggableId={speaker.id.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <>
                            <SpeakerAccordian
                              speaker={speaker}
                              expanded={expanded}
                              setExpanded={(val: number) => {
                                setExpanded(val);
                              }}
                              onRemove={(val: number) => {
                                void onRemove(val);
                              }}
                              role={
                                rolesDropdown.find(
                                  (x) =>
                                    x.value === speaker.member.member_sub_type
                                )?.label || 'TBD'
                              }
                              provided={provided}
                              snapshot={snapshot}
                            />
                          </>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}

        {speakersByEvent.rehearsal_dinner.length > 0 && (
          <div className='mx-2 mt-10'>
            <div className='flex flex-row justify-between items-center w-full'>
              <h1 className='font-nanum text-bold text-2xl text-forest-primary'>
                Speakers at the rehearsal dinner
              </h1>
              <div>
                <h3 className='font-nanum text-lg text-forest-primary text-right'>
                  {speakersByEvent.rehearsal_dinner_time} min
                </h3>
                <p className='text-copper-primary text-xxs uppercase text-right'>
                  Total speaker time
                </p>
              </div>
            </div>
            <DragDropContext
              onDragEnd={(result) =>
                onDragEnd(
                  result,
                  speakersByEvent.rehearsal_dinner,
                  'rehearsal_dinner'
                )
              }
            >
              <Droppable droppableId='speakersByEvent.rehearsal_dinner'>
                {(provided) => (
                  <div {...provided.innerRef} ref={provided.innerRef}>
                    {speakersByEvent.rehearsal_dinner.map((speaker, index) => (
                      <Draggable
                        key={speaker.id}
                        draggableId={speaker.id.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <>
                            <SpeakerAccordian
                              speaker={speaker}
                              expanded={expanded}
                              setExpanded={(val: number) => {
                                setExpanded(val);
                              }}
                              onRemove={(val: number) => {
                                void onRemove(val);
                              }}
                              role={
                                rolesDropdown.find(
                                  (x) =>
                                    x.value === speaker.member.member_sub_type
                                )?.label || 'TBD'
                              }
                              provided={provided}
                              snapshot={snapshot}
                            />
                          </>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}

        {speakersByEvent.reception.length > 0 && (
          <div className='mx-2 mt-10'>
            <div className='flex flex-row justify-between items-center w-full'>
              <h1 className='font-nanum text-bold text-2xl text-forest-primary'>
                Speakers at the reception
              </h1>
              <div>
                <h3 className='font-nanum text-lg text-forest-primary text-right'>
                  {speakersByEvent.reception_time} min
                </h3>
                <p className='text-copper-primary text-xxs uppercase text-right'>
                  Total speaker time
                </p>
              </div>
            </div>
            <DragDropContext
              onDragEnd={(result) =>
                onDragEnd(result, speakersByEvent.reception, 'reception')
              }
            >
              <Droppable droppableId='speakersByEvent.reception'>
                {(provided) => (
                  <div {...provided.innerRef} ref={provided.innerRef}>
                    {speakersByEvent.reception.map((speaker, index) => (
                      <Draggable
                        key={speaker.id}
                        draggableId={speaker.id.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <>
                            <SpeakerAccordian
                              speaker={speaker}
                              expanded={expanded}
                              setExpanded={(val: number) => {
                                setExpanded(val);
                              }}
                              onRemove={(val: number) => {
                                void onRemove(val);
                              }}
                              role={
                                rolesDropdown.find(
                                  (x) =>
                                    x.value === speaker.member.member_sub_type
                                )?.label || 'TBD'
                              }
                              provided={provided}
                              snapshot={snapshot}
                            />
                          </>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
        {speakersFromDb.length > 0 && (
          <Button
            className='bg-forest-primary text-white mt-5 mx-2'
            onClick={() => sendSpeakerInvitations()}
            title='Send invitations'
          />
        )}
      </div>
      {showTip && (
        <Tip
          text={replacePlaceholders(
            question.help_short_text,
            couple1?.preferred_name || couple1?.legal_name,
            couple2?.preferred_name || couple2?.legal_name,
            officiant?.preferred_name || officiant?.legal_name,
            currentUser?.preferred_name || currentUser?.legal_name
          )}
          id={1}
          expanded={tipExpanded === 1}
          setExpanded={(id: number) => setTipExpanded(id)}
          link={question.help_detail_text}
          className={'w-72 ml-5 bg-forest-primary'}
        />
      )}
    </div>
  );
};
