import React, { useEffect, useState } from 'react';

import { Ceremony, MemberType } from '../../../api/ceremony';
import { CustomSwitch } from '../../../components/forms/CustomSwitch';
import Button from '../../../components/v2/Button/Button';
import { Modal } from '../../../components/v2/Modal/Modal';
import { Typography } from '../../../components/v2/Typography/Typography';

type PrivacySettingsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDoneClick: (
    hideOfficiantAddress: boolean,
    hidePartnerResponses: boolean
  ) => Promise<void>;
  currentRole: MemberType;
  ceremony: Ceremony;
};

const PrivacySettingsModal = (props: PrivacySettingsModalProps) => {
  const { isOpen, onClose, onDoneClick, currentRole, ceremony } = props;

  const handleDoneClick = () => {
    void onDoneClick(hideOfficiantAddress, hidePartnerResponses);
  };

  const [hideOfficiantAddress, setHideOfficiantAddress] = useState(false);
  const [hidePartnerResponses, setHidePartnerResponses] = useState(false);

  useEffect(() => {
    if (ceremony) {
      setHideOfficiantAddress(ceremony.hide_officiant_address || false);
      setHidePartnerResponses(ceremony.hide_partner_responses || false);
    }
  }, [ceremony]);
  
  return (
    <Modal isOpen={isOpen} onClose={onClose} maxWidth={'sm'}>
      <>
        <Typography type='display-100'>Chapter Privacy</Typography>
        <Typography type='body-400' className='mt-6'>
          {currentRole === MemberType.officiant
            ? 'Use these settings to determine what the couple can and cannot see for the Officiant’s Address.'
            : 'Use these settings to determine what your partner can and cannot see for the Officiant’s Address.'}
        </Typography>
        <div className='mt-4 flex flex-row justify-between items-center'>
          <Typography variant='functional-low' type='body-400'>
            {currentRole === MemberType.officiant
              ? 'Hide officiant address from couple'
              : 'Hide officiant address in script'}
          </Typography>
          <CustomSwitch
            checked={hideOfficiantAddress}
            onChange={(val) => setHideOfficiantAddress(val)}
          />
        </div>
        <div className='mt-4 flex flex-row justify-between items-center'>
          <Typography variant='functional-low' type='body-400'>
            {currentRole === MemberType.officiant
              ? 'Hide couple’s question responses from each other'
              : 'Hide my answers from my partner'}
          </Typography>
          <CustomSwitch
            checked={hidePartnerResponses}
            onChange={(val) => setHidePartnerResponses(val)}
          />
        </div>
        <div className='mt-4 flex flex-row justify-center items-center'>
          <Button onClick={handleDoneClick}>Done</Button>
        </div>
      </>
    </Modal>
  );
};

export default PrivacySettingsModal;
