import React, { useEffect, useState } from 'react';

import { ChecklistItemIdentifier } from '../../../helpers/checklistHelpers/identifiers';
import { useChecklist } from '../../../provider/checklistProvider';
import Button from '../Button/Button';
import { Modal } from '../Modal/Modal';

import { ReferFriendModalThankYou } from './ReferFriendModalThankYou';

type ReferFriendModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ReferFriendModalWP = (props: ReferFriendModalProps) => {
  const { isOpen, onClose } = props;
  const [triggerAddEmail, setTriggerAddEmail] = useState<boolean>(false);
  const [openThankYou, setOpenThankYou] = useState<boolean>(false);

  const { saveMemberChecklistItemUsingIdentifier } = useChecklist();

  useEffect(() => {
    if (triggerAddEmail) {
      setTriggerAddEmail(false);
    }
  }, [triggerAddEmail]);

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className='max-w-[500px] text-forest-primary'>
          <div>
            <header>
              <h4 className='font-recife text-forest-primary text-3xl text-center w-full'>
                Join Provenance’s Affiliate Program
              </h4>
            </header>

            <div className='font-recife font-light text-base text-forest-primary w-full text-center pt-2 pb-6'>
              Refer fellow wedding professionals to Provenance and you’ll make
              uncapped 10% commissions on initial subscriptions to Provenance’s
              digital services. You can also refer brides/grooms/officiants
              directly with your content!
            </div>
          </div>

          <Button
            size='200'
            onClick={() => {
              saveMemberChecklistItemUsingIdentifier(
                {
                  completed: true,
                },
                ChecklistItemIdentifier.REFER_CLIENTS,
                true
              );

              window.open(
                'https://www.shareasale.com/shareasale.cfm?merchantID=135962 ',
                '_blank'
              );
            }}
            className='mx-auto block mt-6'
          >
            Join Partner Program
          </Button>
          {/* <Button variant='text' className='mx-auto block mt-6'>
            Learn More
          </Button> */}
        </div>
      </Modal>
      <ReferFriendModalThankYou
        isOpen={openThankYou}
        onClose={() => setOpenThankYou(false)}
      />
    </React.Fragment>
  );
};
