import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

import {
  getGooglePlaceDetail,
  GooglePlaceDetailResponse,
} from '../../../api/ceremony';
import renderText from '../../../helpers/renderText';
import CustomTextInput from '../../forms/CustomTextInput';
import { Checkbox } from '../../v2/Checkbox';
import { TextField } from '../../v2/TextField';

type Props = {
  namePrefix?: string;
  index?: number;
};

export const VenueField = (props: Props) => {
  const [hidden, setHidden] = useState(true);
  const { values, setFieldValue, setTouched, handleBlur } =
    useFormikContext<any>();

  const { namePrefix, index } = props;

  useEffect(() => {
    if (values.venueUnknown) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  }, [values.venueUnknown]);

  const fieldNameVenue = namePrefix ? namePrefix + '.venue' : 'venue';
  const fieldNameCity = namePrefix ? namePrefix + '.city' : 'city';
  const fieldNameState = namePrefix ? namePrefix + '.state' : 'state';
  const fieldNameWeddingPlace = namePrefix
    ? namePrefix + '.wedding_place'
    : 'wedding_place';
  const filedNameUnknownVenue = namePrefix
    ? namePrefix + '.venueUnknown'
    : 'venueUnknown';

  const getPlaceDetail = async (
    placeId: string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const response: GooglePlaceDetailResponse = await getGooglePlaceDetail(
      placeId
    );
    setFieldValue(fieldNameVenue, response.data.formatted_address);
    setFieldValue(fieldNameCity, response.data.city);
    setFieldValue(fieldNameState, response.data.region);
    setFieldValue(fieldNameWeddingPlace, response.data.country);
  };

  let venuesValue: any = values.venue ? values.venue : '';

  if (
    index !== undefined &&
    namePrefix &&
    namePrefix.indexOf('ceremonies.' + index.toString()) != -1
  ) {
    venuesValue = values.ceremonies[index].venue
      ? values.ceremonies[index].venue
      : '';
  }

  const isVenueUnknown: () => boolean = () => {
    if (
      index !== undefined &&
      filedNameUnknownVenue.indexOf('ceremonies.' + index.toString()) != -1
    ) {
      return values.ceremonies[index].venueUnknown ? true : false;
    }
    return values.venueUnknown ? true : false;
  };

  const isVenueValue: () => boolean = () => {
    if (
      index !== undefined &&
      fieldNameVenue.indexOf('ceremonies.' + index.toString()) != -1
    ) {
      return values.ceremonies[index].venue ? true : false;
    }
    return values.venue ? true : false;
  };
  const labelText = renderText('not_sure_yet')

  return (
    <PlacesAutocomplete
      value={venuesValue}
      onChange={(address) => {
        setFieldValue(fieldNameVenue, address);
        setFieldValue(filedNameUnknownVenue, false);
        if (address === '') {
          setFieldValue(filedNameUnknownVenue, true);
          setFieldValue(fieldNameCity, '');
          setFieldValue(fieldNameState, '');
          setFieldValue(fieldNameWeddingPlace, '');
        }
        setTouched({ venue: true });
      }}
      onSelect={(address) => {
        setFieldValue(fieldNameVenue, address);
        setTouched({ venue: true });
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className='mb-6 relative'>
          <div className='flex flex-col'>
            <CustomTextInput
              {...getInputProps({
                placeholder: 'Search Places ...',
              })}
              placeholder='Search Places ...'
              onClick={() => {
                if (!isVenueUnknown()) {
                  setHidden(false);
                }
              }}
              onBlur={handleBlur}
              label='Venue'
            />
            <Checkbox
              label={labelText}
              checked={!!isVenueUnknown() } //|| !isVenueValue()
              onChange={(_, checked) => {
                setFieldValue(filedNameUnknownVenue, checked);
                if (checked) {
                  setFieldValue(fieldNameVenue, '');
                  setFieldValue(fieldNameCity, '');
                  setFieldValue(fieldNameWeddingPlace, '');
                  setFieldValue(fieldNameState, '');
                }
              }}
            />
          </div>
          <div
            className={classNames(
              'autocomplete-dropdown-container absolute top-[84px] w-full z-50 drop-shadow-frame',
              {
                hidden: hidden,
                block: !hidden,
              }
            )}
          >
            {loading && (
              <div className='suggestion-item p-4 bg-white'>Loading...</div>
            )}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? 'suggestion-item--active p-4'
                : 'suggestion-item p-4';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {
                    backgroundColor: '#fafafa',
                    cursor: 'pointer',
                  }
                : {
                    backgroundColor: '#ffffff',
                    cursor: 'pointer',
                  };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  key={suggestion.placeId}
                  onClick={() => {
                    void getPlaceDetail(suggestion.placeId, setFieldValue);
                    setHidden(true);
                  }}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};
