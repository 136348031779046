import { GenericResponse } from './ceremony';
import { Speaker } from './friends';
import { Order } from './paywall';
import { _request } from './request';


export type WeddingPlannerStatus = {
  maxWeddings: number;
  gracePeriod: number;
  activatedWeddings: number;
  subscriptionEndDate: Date;
  hasActiveSubscription: boolean;
  activeSubscription: Order | null;
};

export type WeddingPlannerStatusResponse = {
  success: boolean;
  message: string;
  data: WeddingPlannerStatus;
};

export const getWeddingPlannerStatus = async () => {
  const responseBody = await _request<WeddingPlannerStatusResponse>({
    url: 'weddingPlanner/status',
    method: 'GET',
  });
  return responseBody;
};

export const activateCeremony = async (ceremonyId: number) => {
  const responseBody = await _request<GenericResponse>({
    url: `weddingPlanner/activate/${ceremonyId}`,
    method: 'POST',
  });
  return responseBody;
};

export const deactivateCeremony = async (ceremonyId: number) => {
  const responseBody = await _request<GenericResponse>({
    url: `weddingPlanner/deactivate/${ceremonyId}`,
    method: 'POST',
  });
  return responseBody;
};

export const activateAllCeremonies = async () => {
  const responseBody = await _request<GenericResponse>({
    url: `weddingPlanner/activateAll`,
    method: 'POST',
  });
  return responseBody;
};
