import { CloseOutlined } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import { useState } from 'react';

import handPointUp from '../../../../assets/images/icons/hand-pointing-up.svg';
import InfoWarning from '../../../../components/v2/InfoWarning/InfoWarning';
import { Typography } from '../../../../components/v2/Typography/Typography';

import { QuestionType } from './ModuleQuestion';

type ModuleQuestionHeaderProps = {
  questionNumber: number;
  totalOfQuestions: number;
  question: React.ReactNode;
  hintText?: string;
  type: QuestionType;
  subtitle?: React.ReactNode | (() => React.ReactNode);
  className?: string;
  required?: boolean;
  infoText?: string;
};

export const ModuleQuestionHeader = (props: ModuleQuestionHeaderProps) => {
  const {
    questionNumber,
    totalOfQuestions,
    question,
    hintText,
    subtitle,
    required,
    infoText,
  } = props;

  const questionNumberText = `Question ${questionNumber} of ${totalOfQuestions}`;
  const [showHint, setShowHint] = useState(false);

  return (
    <div className='flex flex-col gap-4 relative'>
      <div className='flex flex-col'>
        <div className='flex flex-row items-center gap-4'>
          <span className='font-grotesk font-medium text-sm text-forest-300'>
            {questionNumberText}
            {required ? (
              <span className='ml-1' style={{ color: '#995D30' }}>
                {' '}
                (Required){' '}
              </span>
            ) : null}
          </span>
          {!!hintText && (
            <button
              className='text-sm text-forest-500 font-medium font-grotesk underline underline-offset-4 decoration-dashed'
              onClick={() => setShowHint((old) => !old)}
            >
              Need a hint?
            </button>
          )}
        </div>
        <Collapse in={showHint}>
          <div className='flex flex-row items-start mt-4 py-6 pl-6 rounded-3xl bg-cosmicLatte-dark gap-6 relative'>
            <div className='hidden w-12 h-12 rounded-full border border-dashed border-forest-500 sm:flex flex-row justify-center items-center'>
              <img src={handPointUp} alt='Hand Pointing Up' />
            </div>
            <div
              className='flex-1 font-grotesk text-base text-forest-500 text-left pr-14'
              dangerouslySetInnerHTML={{ __html: hintText || '' }}
            />

            <div></div>
            <button
              className='absolute right-5 top-5'
              onClick={() => setShowHint(false)}
            >
              <CloseOutlined className='h-4 w-4' />
            </button>
          </div>
        </Collapse>
      </div>
      <div className='flex flex-col gap-2'>
        <h3 className='font-recife font-[420] text-2xl sm:text-[28px] sm:leading-[36px] text-forest-primary'>
          {question}
        </h3>
        {typeof subtitle === 'function' && subtitle()}
        {typeof subtitle !== 'function' && (
          <div className='font-recife font-[310] text-forest-500'>
            {subtitle}
          </div>
        )}

        {infoText ? (
          <InfoWarning
            text={
              <Typography
                variant='functional-low'
                color='error'
                type='body-200'
                className='text-gray-700'
              >
                {infoText}
              </Typography>
            }
            variant='grey'
          />
        ) : null}
      </div>
    </div>
  );
};
