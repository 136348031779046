export const maskEmail = (email: string) => {
  if (!email) return '';
  // Split the email into local part and domain part
  const [localPart, domainPart] = email.split('@');

  // Determine the length to be masked
  const localPartLength = localPart.length;
  let maskedLocalPart;

  if (localPartLength <= 4) {
    // If the local part is very short, just show the first and last letter
    maskedLocalPart =
      localPart[0] +
      '*'.repeat(localPartLength - 2) +
      localPart[localPartLength - 1];
  } else {
    // Show the first two and last two characters, mask the rest
    maskedLocalPart =
      localPart.slice(0, 2) +
      '*'.repeat(localPartLength - 4) +
      localPart.slice(localPartLength - 2);
  }

  // Combine the masked local part with the domain part
  return maskedLocalPart + '@' + domainPart;
};
