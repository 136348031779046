import Moment from 'moment';
import { useEffect, useState } from 'react';

const useDate = (props?: string) => {
  const date = props;

  const [formattedDate, setFormattedDate] = useState<string>();
  const [formattedDateMonthDay, setFormattedDateMonthDay] = useState<string>();
  const [formattedDateMonthDayYear, setFormattedDateMonthDayYear] =
    useState<string>();

  useEffect(() => {
    formatDate(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDate = (date?: string) => {
    if (!date || typeof date === 'undefined') return false;

    Moment.locale('en');

    setFormattedDate(Moment.utc(date).format('d MMM'));
    setFormattedDateMonthDay(Moment.utc(date).format('MMM Do'));
    setFormattedDateMonthDayYear(Moment.utc(date).format('MMMM D YYYY'));
  };

  return {
    formattedDate,
    formattedDateMonthDay,
    formattedDateMonthDayYear,
  };
};

export default useDate;
