import { Ceremony } from "../../../../api/ceremony"
import InfoIcon from "../../../../components/v2/InfoIcon/InfoIcon"
import { Separator } from "../../../../components/v2/Separator/Separator"
import { Typography } from "../../../../components/v2/Typography/Typography"
import CeremonyBasicCard from "../CeremonyBasicCard"

import './ClientsTable.scss'

import '../CeremonyBasicCard.scss'


type ClientsTableProps = {
  clientsCeremonies: Ceremony[];
  refetch: () => void;
}

export const ClientsTable = (props: ClientsTableProps) => {
  const { clientsCeremonies, refetch } = props;

  const redirectToWPInfoPage = () => {
    window.open('https://provenance.elevio.help/en/articles/63-how-does-provenance-pro-for-wedding-planners-work', '_blank');
  }

  return <div className="clientsTableScroller">
    <div className="clientsTableContainer">
      <div className="CeremonyBasicCard clientsTableHeader">
        <Typography type='body-200' variant='functional-low' className="basicCardCell clientsCellName">
          Name
        </Typography>
        <Typography type='body-200' variant='functional-low' className="basicCardCell clientsCellDate">
          Wedding Date
        </Typography>
        <Typography type='body-200' variant='functional-low' className="basicCardCell clientsCellLocation">
          Location
        </Typography>
        <Typography type='body-200' variant='functional-low' className="basicCardCell clientsCellPaymentStatus">
          <>
            Payment Status
            <span className='ml-2' onClick={redirectToWPInfoPage}>
              <InfoIcon className='cursor-pointer h-4 w-4' />
            </span>
          </>
        </Typography>
        <Typography type='body-200' variant='functional-low' className="basicCardCell clientsCellPaidBy">
          <>
            Paid By
            <span className='ml-2' onClick={redirectToWPInfoPage}>
              <InfoIcon className='cursor-pointer h-4 w-4' />
            </span>
          </>
        </Typography>
        <div className='clientsCellOptions'>
        </div>
      </div>
      {
        clientsCeremonies.map((ceremony, index) => (
          <>
            {index ? <Separator className="border-forest-100" /> : null}
            <CeremonyBasicCard 
              key={index}
              ceremony={ceremony}
              refetch={refetch}
            />
          </>
        ))
      }
    </div>
  </div>
}