import moment from 'moment';

import { Ceremony, Member } from '../api/ceremony';

export const getCeremonyTitle = (couple1: Member, couple2: Member) => {
  let result = '';
  const coupleNamesString = getCoupleName(couple1, couple2);
  if (coupleNamesString) {
    result = coupleNamesString;
    const lastLetter = coupleNamesString[coupleNamesString.length - 1];
    if (lastLetter.toLowerCase() === 's') {
      result += "' Wedding";
    } else {
      result += "'s Wedding";
    }
  }
  return result;
};

export const getCeremonyTitleFromFirstNames = (
  couple1FirstName: string,
  couple2FirstName: string
) => {
  let result = '';
  const coupleNamesString = `${couple1FirstName} and ${couple2FirstName}`;
  if (coupleNamesString) {
    result = coupleNamesString;
    const lastLetter = coupleNamesString[coupleNamesString.length - 1];
    if (lastLetter.toLowerCase() === 's') {
      result += "' Wedding";
    } else {
      result += "'s Wedding";
    }
  }
  return result;
};

export const getVowsTitle = (member: Member, currentMember: Member) => {
  let result = '';
  const name = member.preferred_name || member.legal_name;
  if (name) {
    result = name;
    if (name.includes('TBD')) {
      result = "Your Partner's Vows";
    } else if (member.id === currentMember.id) {
      result = 'Your Vows';
    } else {
      const lastLetter = name[name.length - 1];
      if (lastLetter.toLowerCase() === 's') {
        result += "' Vows";
      } else {
        result += "'s Vows";
      }
    }
  }
  return result;
};

export const getMemberName = (member: Member) => {
  let result = '';
  const name = member.preferred_name || member.legal_name;
  if (name) {
    result = name;

    if (name.includes('TBD')) {
      result = 'Partner'; // ask Emil or Plamen what text should we add if the person's name includes TBD
    }
  } else {
    // what should we do if there is no preferred_name or legal_name ?
  }
  return result;
};

export const getCeremonyTitleFromCoupleName = (
  coupleName: string | undefined
) => {
  let result = '';
  if (coupleName) {
    result = coupleName;
    const lastLetter = result[result.length - 1];
    if (lastLetter.toLowerCase() === 's') {
      result += "' Wedding";
    } else {
      result += "'s Wedding";
    }
  }
  return result;
};

export const getCoupleName = (couple1: Member, couple2: Member) => {
  let coupleNamesString = '';
  if (couple1 && couple2 && couple2.legal_name !== 'TBD TBD') {
    coupleNamesString = `${couple1?.preferred_name} & ${couple2?.preferred_name}`;
  } else if (couple1) {
    coupleNamesString = couple1?.preferred_name;
  } else if (couple2) {
    coupleNamesString = couple2?.preferred_name;
  }
  return coupleNamesString;
};

export const getCeremonyDateString = (ceremonyDate?: string) => {
  const weddingDate = ceremonyDate ? moment.utc(ceremonyDate) : '';
  const dateString = weddingDate
    ? `, ${weddingDate.format('MMM D, YYYY')}`
    : '';
  return dateString;
};
