import React from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

import { generalLog } from '../../api/ceremony';
import { useDashboard } from '../../provider/dashboardProvider';
import ABTester from '../v2/ABTester/ABTester';
import Button from '../v2/Button/Button';
import CampaignItem from '../v2/CampaignItem/CampaignItem';
import { Modal } from '../v2/Modal/Modal';
import { Typography } from '../v2/Typography/Typography';

import CheckMark from './images/checkmark.svg';

export type PaywallModalTypes =
  | 'ceremony'
  | 'vows'
  | 'officiant'
  | 'weddingPlanner'
  | 'guests';

type PaywallModalProps = {
  isOpen: boolean;
  onClose: () => void;
  type: PaywallModalTypes;
  referrer: string;
  ceremonyId: number;
  isCeremonyBuilder?: boolean;
};

export const PaywallModal = (props: PaywallModalProps) => {
  const { isOpen, onClose, type, referrer, ceremonyId, isCeremonyBuilder } =
    props;

  const navigate = useNavigate();

  const { ceremony } = useDashboard();

  const redirectToPaywall = async () => {
    if (ceremonyId) {
      ReactGA.event({
        category: 'PayWall',
        action: 'Unlock',
        label: `${referrer}`,
      });
      await generalLog({
        ceremony_id: ceremonyId,
        activity: `Unlock - ${referrer}${
          isCeremonyBuilder ? ' - Ceremony Builder' : ''
        }`,
      });
      navigate(`/pay-plans/${ceremonyId}?refferedFrom=${referrer}`);
    }
  };

  //AB Test to skip modal here
  if (isOpen && ceremony?.vowslanding_test_category === 'noModal') {
    onClose();
    redirectToPaywall();
    return <></>;
  }

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className={`flex flex-col max-w-[480px] self-center`}>
          <span
            className={`text-sm font-grotesk text-white bg-copper-primary px-3 py-1 rounded-lg self-center mb-4`}
          >
            <CampaignItem
              regularComponent={
                <ABTester
                  components={[
                    {
                      component: <>Premium Feature</>,
                      render:
                        ceremony?.vowslanding_test_category === 'new_copy',
                    },
                    {
                      component: <>Paid Feature</>,
                      default: true,
                    },
                  ]}
                />
              }
              renderCampaignComponent={(
                discountPercentage,
                couponCode,
                campaignText
              ) => <>{`GET ${discountPercentage}% OFF TODAY!`}</>}
            />
          </span>
          <Typography
            type='display-600'
            className='text-[28px] lg:text-4xl pb-4 self-center text-center'
          >
            <CampaignItem
              regularComponent={
                <ABTester
                  components={[
                    {
                      component: <>Get full access to Provenance’s tools</>,
                      render:
                        ceremony?.vowslanding_test_category === 'new_copy',
                    },
                    {
                      component: <>Upgrade for full access</>,
                      default: true,
                    },
                  ]}
                />
              }
              renderCampaignComponent={(
                discountPercentage,
                couponCode,
                campaignText
              ) => <>{campaignText}</>}
            />
          </Typography>

          <span className='text-center pb-4'>
            <ABTester
              components={[
                {
                  component: (
                    <>
                      Discover all of the prompts and resources you need to
                      generate your full, personalized ceremony script
                    </>
                  ),
                  render: ceremony?.vowslanding_test_category === 'new_copy',
                },
                {
                  component: (
                    <>
                      {type === 'ceremony' && (
                        <>
                          Unlock access to all of the prompts and resources you
                          need to generate your full, personalized Ceremony
                          Script.
                        </>
                      )}
                      {type === 'vows' && (
                        <>
                          Unlock Vow Builder access to generate your personal
                          vows and ensure they match your partner’s in length
                          and tone.{' '}
                        </>
                      )}
                    </>
                  ),
                  default: true,
                },
              ]}
            />
          </span>

          <div className='flex flex-col max-w-[381px] self-center pb-6'>
            {type === 'weddingPlanner' && (
              <CheckMarkItem text='Officiant + Wedding Planner Access and Support' />
            )}
            {type === 'guests' && referrer === 'guests' && (
              <CheckMarkItem text='Invite Unlimited Guest Speakers' />
            )}

            {
              type === 'guests' && referrer === 'toast' && (
                <CheckMarkItem text='Generate Your Welcome Toast' />
              )
            }
            {(type === 'ceremony' ||
              type === 'officiant' ||
              type === 'weddingPlanner' ||
              type === 'guests') && (
              <>
                <CheckMarkItem text='Access Every Question of the Ceremony Builder and Vow Builder' />

                <CheckMarkItem text='Generate Your Full Ceremony Script and Personal Vows' />

                <CheckMarkItem text='Edit, Download & Export Your Custom Drafts' />
              </>
            )}
            {type === 'ceremony' ||
              (type === 'officiant' && (
                <CheckMarkItem text='Officiant Access and Support' />
              ))}

            
            {type === 'vows' && (
              <>
                <CheckMarkItem text='Answer Questions About Your Relationship & Love Story' />
                <CheckMarkItem text='Generate, Edit & Download Your Personal Vows' />
                <CheckMarkItem text='See the Length & Tone of Your Partner’s Vows' />
              </>
            )}
          </div>

          <Button onClick={() => redirectToPaywall()} className='self-center'>
            <CampaignItem
              regularComponent={
                <ABTester
                  components={[
                    {
                      component: <>Tell me more</>,
                      render:
                        ceremony?.vowslanding_test_category === 'new_copy',
                    },
                    {
                      component: <>Upgrade Now</>,
                      default: true,
                    },
                  ]}
                />
              }
              renderCampaignComponent={() => <>Claim Discount</>}
            />
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export const CheckMarkItem = ({
  text,
  className = '',
}: {
  text: string;
  className?: string;
}) => {
  return (
    <div className={'flex flex-row pb-2 ' + className}>
      <img src={CheckMark} alt='checkmark' className='mr-5 w-4' />
      <span className='font-recife text-forest-500'>{text}</span>
    </div>
  );
};
