import { SelectChangeEvent } from '@mui/material';

import { Culture } from '../../../api/culture';
import { RitualType } from '../../../api/ritualType';
import { Tag } from '../../../api/tags';
import { CustomMenuList } from '../../../components/forms/CustomMenuList';
import { CustomMultiselect } from '../../../components/forms/CustomMultiselect';
import { DropdownMultiselect } from '../../../components/v2/Dropdown/DropdownMultiselect';
import { Typography } from '../../../components/v2/Typography/Typography';
import { useReadings } from '../../../provider/readingsProvider';
import { FilterChip } from '../common/chips/Chip';
import { MenuListHeader } from '../common/menuListHeaders/MenuListHeader';

export const SearchFilters = () => {
  const {
    cultures,
    ritualTypes,
    tags,
    cultureValues,
    ritualTypeValues,
    tagValues,
    handleCultureChange,
    handleRitualTypeChange,
    handleTagChange,
    handleRemoveCulture,
    handleRemoveRitualType,
    handleRemoveTag,
    handleClearFilters,
    filteredReadings,
    setAddModalOpen,
    setCultureValues,
    setRitualTypeValues,
    setTagValues,
  } = useReadings();

  return (
    <div className='hidden md:flex sm:flex-col'>
      <div className=' flex flex-row flex-wrap mt-6'>
        {cultures && (
          <div className='flex-1 w-full md:w-1/3 md:mr-2'>
            <Typography
              variant='functional-low'
              type='body-200'
              className='mb-2'
            >
              Affiliation
            </Typography>
            <DropdownMultiselect
              options={cultures.map((r) => ({
                label: r.name,
                value: r.id.toString(),
              }))}
              values={cultureValues}
              handleChange={handleCultureChange}
              handleAllClick={() => {
                if (cultureValues && cultureValues.length > 0) {
                  setCultureValues([]);
                }
              }}
              chipType='culture'
            />
          </div>
        )}
        {ritualTypes && (
          <div className='flex-1 w-full md:w-1/3 md:mr-2'>
            <Typography
              variant='functional-low'
              type='body-200'
              className='mb-2'
            >
              Ceremony Placement
            </Typography>
            <DropdownMultiselect
              options={ritualTypes.map((r) => ({
                label: r.name,
                value: r.id.toString(),
              }))}
              values={ritualTypeValues}
              handleChange={handleRitualTypeChange}
              handleAllClick={() => {
                if (ritualTypeValues && ritualTypeValues.length > 0) {
                  setRitualTypeValues([]);
                }
              }}
              chipType='culture'
            />
          </div>
        )}
        {tags && (
          <div className='flex-1 w-full md:w-1/3'>
            <Typography
              variant='functional-low'
              type='body-200'
              className='mb-2'
            >
              Method
            </Typography>
            <DropdownMultiselect
              options={tags.map((r) => ({
                label: r.name,
                value: r.id.toString(),
              }))}
              values={tagValues}
              handleChange={handleTagChange}
              handleAllClick={() => {
                if (tagValues && tagValues.length > 0) {
                  setRitualTypeValues([]);
                }
              }}
              chipType='culture'
            />
          </div>
        )}
      </div>
      <div className='flex flex-row justify-start items-center flex-wrap mt-2'>
        {cultureValues.map((cv) => (
          <FilterChip
            label={cultures.find((c) => c.id.toString() === cv)?.name || ''}
            onDeleteClick={() => handleRemoveCulture(cv)}
          />
        ))}
        {ritualTypeValues.map((cv) => (
          <FilterChip
            label={ritualTypes.find((c) => c.id.toString() === cv)?.name || ''}
            onDeleteClick={() => handleRemoveRitualType(cv)}
          />
        ))}
        {tagValues.map((cv) => (
          <FilterChip
            label={tags.find((c) => c.id.toString() === cv)?.name || ''}
            onDeleteClick={() => handleRemoveTag(cv)}
          />
        ))}
        {(cultureValues.length > 0 ||
          ritualTypeValues.length > 0 ||
          tagValues.length > 0) && (
          <div onClick={handleClearFilters} className='cursor-pointer'>
            <Typography
              variant='functional-low'
              type='body-200'
              className='border-0 border-b border-dashed border-forest-primary'
            >
              Clear Filters
            </Typography>
          </div>
        )}
        <hr className='block mt-12 mb-6 w-full border-t border-forest-100' />
      </div>
    </div>
  );
};
