import { eventDropdown, rolesDropdown } from '../api/friends';
import { Question } from '../pages/Modules/common/moduleQuestion/ModuleQuestion';

import { capitalizeFirstLetterOfEachWord } from './helper';

export const getDropdownValues = (question: Question, message = '') => {
  const optionIds =
    question.answers && question.answers[0] && question.answers[0].option_id
      ? question.answers[0].option_id.split(',')
      : [];
  const selectedOptions = question.options?.filter(
    (o) => optionIds.indexOf(o.id.toString()) >= 0
  );

  return selectedOptions && selectedOptions.length > 0
    ? selectedOptions.map((o) => o.option).join(', ')
    : message;
};

export const getRoleTitle = (role: string) => {
  return rolesDropdown.find((item) => item.value === role)?.label || role;
};

export const getEventName = (event: string) => {
  return eventDropdown.find((item) => item.value === event)?.label || event;
};

export const getMemberTypeTitle = (type?: string) => {
  const memberType: Record<string, string> = {
    officiant: 'Officiant',
    bride: 'Bride',
    groom: 'Groom',
    other: 'Partner',
    wedding_planner: 'Wedding Planner',
    guest: 'Guest',
    'ritual-member': 'Ritual Member',
    reader: 'Reader',
  };
  if (!type) return '';
  return memberType[type] || type;
};

export const getMemberSubTypeTitle = (type?: string) => {

  if (!type) return '';

  return capitalizeFirstLetterOfEachWord(type.replaceAll('_',' '));
}
