import { PayPlansList } from '../../../pages/paywall/PayPlans';
import CustomCheckBox from '../../forms/CustomCheckBox';
import { Typography } from '../Typography/Typography';

type UpsellCardType = {
  title: string;
  price: number;
  originalPrice: number;
  basePlaneTitle: string;
  attributes: string[];
  selected: boolean;
  onSelect: () => void;
};

const UpsellCard = ({
  title,
  price,
  originalPrice,
  basePlaneTitle,
  attributes,
  selected,
  onSelect
}: UpsellCardType) => {

  

  return (
    <div className='max-w-[436px] border border-forest-primary rounded-3xl p-6 cursor-pointer'
      onClick={onSelect}
    >
      <div className='flex items-center gap-4'>
        <div className='flex justify-between flex-1'>
          <div className='flex flex-col w-full'>
           <div className='flex flex-row justify-between w-full items-center'>
           <Typography variant='primary' type='display-50' className='pb-2'>
              {title}
            </Typography>
            <CustomCheckBox checked={selected} />
           </div>
            <div className='flex gap-2 mb-2'>
              <Typography
                variant='primary'
                type='display-400'
                className='text-[32px]'
              >
                {`+$${parseFloat(price.toString()).toFixed(2)}`}
              </Typography>
              <Typography
                variant='primary'
                type='display-50'
                className='text-forest-400 text-[16px] line-through self-end'
              >
                {`$${parseFloat(originalPrice.toString()).toFixed(0)}`}
              </Typography>
            </div>
            {/* <Typography
              variant='primary'
              type='body-200'
              className='text-forest-400 pb-4'
            >
              Billed once
            </Typography> */}

            <Typography variant='functional-high' type='body-200' className='pb-2'>
              <> Everything in {basePlaneTitle}, plus:</>
            </Typography>
            <div>
              {attributes.map((attribute, index) => (
                <PayPlansList key={index} title={attribute} small={true} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpsellCard;
