import { useNavigate } from 'react-router-dom';

import Button from '../../../components/v2/Button/Button';
import { Typography } from '../../../components/v2/Typography/Typography';
import { useMerchandise } from '../../../provider/merchandisingProvider';
import { CheckMarkItem } from '../../PaywallModal/PaywallModal';
import CampaignItem from '../CampaignItem/CampaignItem';
import { Modal } from '../Modal/Modal';

type ModalProps = {
  ceremonyId: string;
  userId: string;
  ceremonyDate: string | undefined;
};

export const MerchandisingModal = (props: ModalProps) => {
  const {
    showMerchandisingModal,
    setShowMerchandisingModal,
    selectedMerch,
    appTool,
  } = useMerchandise();

  const { ceremonyId } = props;

  const navigate = useNavigate();
 
  const isWeddingTooSoon = () => {
    if (!props.ceremonyDate) return false;
    if (props.ceremonyDate === 'TBD') return false;
    if (selectedMerch === null) return false;
    const ceremonyDate = new Date(props.ceremonyDate);
    const today = new Date();
    const diffTime = ceremonyDate.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return selectedMerch.days_prior_speech > diffDays;
  };

  const onAddToCartClick = () => {
    navigate('/pay-merchandise/' + ceremonyId + '/' + appTool)
  }

  const getContent = () => {
    if (selectedMerch === null) return <></>;
    else if (isWeddingTooSoon()) {
      return (
        <div className='self-center flex flex-col justify-center text-center '>
          <>
            <Typography type='display-800'>
              This is a quick turnaround! Email us
            </Typography>

            <div className='mt-5 flex flex-col justify-between'>
              <Typography
                type='body-200'
                className='text-[16px] font-light'
                variant='primary'
              >
                <>
                  The wedding date you indicated requires too short of a
                  turnaround time for our standard services. We can still help!
                  Submit a rush request to{' '}
                  <span className='underline'>speechwriting@provenance.co</span>
                </>
              </Typography>
            </div>
            <div
              className={`flex flex-col-reverse md:flex-row justify-end m-8`}
            >
              <Button
                className=' md:w-auto w-full'
                onClick={() => setShowMerchandisingModal(false)}
              >
                Got it
              </Button>
            </div>
          </>
        </div>
      );
    } else {
      return (
        <div className='self-center flex flex-col justify-center text-center '>
          <>
            <CampaignItem
              regularComponent={<>Paid Feature</>}
              renderCampaignComponent={(
                discountPercentage,
                couponCode,
                campaignText
              ) => (
                <>
                  <span
                    className={`text-sm font-grotesk text-white bg-copper-primary px-3 py-1 rounded-lg self-center mb-4`}
                  >
                    {`GET ${discountPercentage}% OFF TODAY!`}
                  </span>
                </>
              )}
            />

            <Typography type='display-800'>{selectedMerch.title}</Typography>

            <div className='mt-5 flex flex-col justify-between'>
              <Typography
                type='body-200'
                className='text-[16px] font-light'
                variant='primary'
              >
                {selectedMerch.description}
              </Typography>

              <div className='mt-5 px-8'>
                {selectedMerch.attributes.map((attribute, index) => {
                  return (
                    <CheckMarkItem
                      className='text-[16px] font-light text-left mt-3'
                      text={attribute}
                      key={index}
                    />
                  );
                })}
              </div>
            </div>
            <div
              className={`flex flex-col-reverse md:flex-row justify-center m-8`}
            >
              <Button className=' md:w-auto w-full' onClick={onAddToCartClick}>
                Get Access
              </Button>
            </div>
            <Typography type='body-200' variant='functional-low'>
              <p style={{ color: '#677570' }}>
                Draft & Survey must be submitted at least{' '}
                {selectedMerch.days_prior_speech} days prior to speech date.
                Email speechwriting@provenance.co if you require a quicker
                turnaround (rush fees apply).
              </p>
            </Typography>
          </>
        </div>
      );
    }
  };

  return (
    <Modal
      isOpen={showMerchandisingModal && selectedMerch !== null}
      onClose={() => setShowMerchandisingModal(false)}
      showCloseButton={true}
      title={''}
      maxWidth={'sm'}
    >
      {getContent()}
    </Modal>
  );
};
