import { WarningAmber, InfoOutlined } from '@mui/icons-material';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import './alert-box.scss';
import { AlertBoxProps, AlertBoxRefHandlers } from './typings.d';

const AlertBoxComponent = forwardRef<AlertBoxRefHandlers, AlertBoxProps>(
  (
    {
      message,
      title,
      buttons,
      alwaysOpen,
      autoHide = true,
      autoHideTimeout = 3,
      variant = 'warning',
      ...divProps
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(alwaysOpen);
    const [currentMessage, setCurrentMessage] = useState(message);

    useEffect(() => {
      if (autoHide !== true || alwaysOpen === true) return;

      const _id = setTimeout(() => {
        setIsOpen(false);
      }, autoHideTimeout * 1000);

      return () => clearTimeout(_id);
    }, [isOpen, autoHideTimeout, autoHide, alwaysOpen]);

    useEffect(() => {
      setCurrentMessage(message || '');
    }, [message]);

    const closeHandler = useCallback(() => {
      setIsOpen(false);
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        open: (newMessage) => {
          setCurrentMessage(newMessage);
          setIsOpen(true);
        },
        close: closeHandler,
        updateMessage: (newMessage) => setCurrentMessage(newMessage),
      }),
      [closeHandler]
    );

    if (!isOpen) return null;

    return (
      <div
        {...divProps}
        className={['alert-box-container', divProps.className].join(' ')}
      >
        <div>
          {variant === 'warning' && <WarningAmber fontSize='medium' />}
          {variant === 'info' && <InfoOutlined fontSize='medium' />}
        </div>
        <div className='flex flex-col flex-auto gap-4'>
          <div>
            {title && <p className='text-lg'>{title}</p>}
            <p>{currentMessage}</p>
          </div>
          {buttons && buttons.length > 0 && (
            <div className='alert-box-buttons-container'>
              {buttons.map((buttonProps) => (
                <button
                  key={buttonProps.label}
                  className='alert-box-button'
                  onClick={() => {
                    if (typeof buttonProps.onClick === 'function')
                      Promise.resolve()
                        .then(() => buttonProps?.onClick?.())
                        .catch((err) => console.error(err));
                    if (buttonProps.closeOnClick === true) closeHandler();
                  }}
                >
                  {buttonProps.label}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
);

export const AlertBox = React.memo(AlertBoxComponent);
