import classNames from 'classnames';
import React from 'react';

import './typography.scss';

export type TypographyProps = {
  className?: string;
  children: React.ReactElement<any, any> | string | null | undefined;
  variant?: 'primary' | 'functional-high' | 'functional-low';
  type?:
    | 'display-1000'
    | 'display-800'
    | 'display-600'
    | 'display-400'
    | 'display-200'
    | 'display-100'
    | 'display-50'
    | 'body-600'
    | 'body-400'
    | 'body-200'
    | 'body-100';

  align?: string;
  color?: string;
  wordWrap?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const Typography = (props: TypographyProps) => {
  const {
    className = '',
    children,
    variant = 'primary',
    type = 'body-600',
    align = 'inherit',
    color = 'inherit',
    wordWrap = false,
    onClick,
  } = props;

  const newType = type;
  let smType = newType.replaceAll('400', '200');
  smType = smType.replaceAll('600', '400');

  smType = smType.replaceAll('800', '600');

  const classes = classNames(
    {
      typography: true,
      [`typography-variant-${variant}`]: variant,
      [`lg:typography-type-${type}`]: type,
      [`typography-color-${color}`]: color,
      [`typography-align-${align}`]: align,
      [`typography-word-wrap`]: wordWrap,
      [`typography-type-${smType}`]: smType,
    },
    className
  );

  return onClick ? (
    <div onClick={onClick} className={classes}>
      {children}
    </div>
  ) : (
    <div className={classes}>{children}</div>
  );
};

export { Typography };
