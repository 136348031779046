import { InfoOutlined, CheckCircleOutline } from '@mui/icons-material';
import classNames from 'classnames';

import './ModuleQuestionsHeader.scss';

import { CircularProgress } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';

import { useCTA } from '../../../../provider/ctaProvider';
import { ModuleStatus } from '../../enum';
import { GenerationIncentiveModal } from '../GenerationIncentiveModal/GenerationIncentiveModal';

export type ModuleQuestionsHeaderProps = {
  renderCenterButton: (
    moduleStatus: ModuleStatus,
    status: 'saving' | 'saved' | 'unsaved'
  ) => ReactNode;
  renderRightButton: (
    moduleStatus: ModuleStatus,
    status: 'saving' | 'saved' | 'unsaved'
  ) => ReactNode;
  setModuleStatus?: (status: ModuleStatus) => void;
  className?: string;
};

export const ModuleQuestionsHeader = ({
  renderCenterButton,
  renderRightButton,
  setModuleStatus,
  className,
}: ModuleQuestionsHeaderProps) => {
  const { status, moduleStatus } = useCTA();

  useEffect(() => {
    typeof setModuleStatus === 'function' && setModuleStatus(moduleStatus);
  }, [moduleStatus]);

  const isSaved = status === 'saved';
  const isSaving = status === 'saving';
  const statusText = isSaved ? 'Saved' : isSaving ? 'Saving' : 'Unsaved';
  return (
    <div
      className={`ModuleQuestionsHeader ModuleQuestionsHeader-root ${
        className || ''
      }`}
    >
      {renderRightButton(moduleStatus, status)}
      {renderCenterButton(moduleStatus, status)}
      <div
        className={classNames('flex flex-row gap-1 items-center', {
          'flex-row-reverse': isSaved,
        })}
      >
        {isSaving && <CircularProgress size={16} className='text-forest-400' />}
        <span
          className={classNames('font-grotestk text-sm sm:text-base', {
            'text-forest-400': !isSaved,
            'text-system-green-400': isSaved,
          })}
        >
          {statusText}
        </span>
        {isSaved && (
          <CheckCircleOutline className='w-6 h-6 text-system-green-400' />
        )}
        {!isSaved && !isSaving && (
          <InfoOutlined className='w-4 h-4 text-forest-400' />
        )}
      </div>
    </div>
  );
};
