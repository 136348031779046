import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '../v2/Typography/Typography';

import './sideMenuItem.scss';

type SideMenuItemProps = {
  renderIcon: () => React.ReactNode;
  renderSelectedIcon: () => React.ReactNode;
  onClick?: () => void;
  text: string;
  link?: string;
  expanded: boolean;
  selected: boolean;
  display?: boolean;
};
export const SideMenuItem = (props: SideMenuItemProps) => {
  const navigate = useNavigate();
  const { renderIcon, renderSelectedIcon, text, link, expanded, selected, display = true } =
    props;

  if (!display) {
    return null;
  }

  return (
    <div
      className={classNames('SideMenuItem-root', {
        'SideMenuItem--selected': selected,
        'px-[19px] expanded': expanded,
        'justify-center items-center': !expanded
      })}
      onClick={() => (link ? navigate(link) : props.onClick && props.onClick())}
    >
      {selected ? (
        <div className='w-5 flex justify-center items-center'>
          {renderSelectedIcon && renderSelectedIcon()}
        </div>
      ) : (
        <div className='w-5 flex justify-center items-center'>
          {renderIcon && renderIcon()}
        </div>
      )}

      {expanded && (
        <Typography
          className='ml-[18px]'
          variant='functional-low'
          type='body-400'
        >
          {text}
        </Typography>
      )}
    </div>
  );
};
