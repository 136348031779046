/* eslint-disable */
import { generalLog, getLog } from '../../../api/ceremony';
import ABTester from '../../../components/v2/ABTester/ABTester';
import Button from '../../../components/v2/Button/Button';
import { Typography } from '../../../components/v2/Typography/Typography';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useDashboard } from '../../../provider/dashboardProvider';

import LanderItem from '../../../components/LanderItem/LanderItem';
import border1Svg from './border-1.svg';
import border2Svg from './border-2.svg';
import border3Svg from './border-3.svg';
import border4Svg from './border-4.svg';
import gemSvg from './gem.svg';
import image1Png from './image-1.png';
import image2Png from './image-2.png';
import image3Png from './image-3.png';
import image4Png from './image-4.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type VowBuilderLockedProps = {
  onUpgradeButtonClick?: () => void;
};

const VowBuilderLocked = (props: VowBuilderLockedProps) => {
  const { onUpgradeButtonClick } = props;
  const size = useWindowSize();
  const { ceremony } = useDashboard();
  const navigation = useNavigate();

  const [isVbVisible, setIsVbVisible] = useState(false);

  useEffect(() => {
    handleCheckVbUnlocked();
  }, []);

  const handleCheckVbUnlocked = async () => {

    if(!ceremony?.id) return false;

    try{
      const response = await getLog('Signup - Vows', ceremony?.id.toString());

      if(response?.data){
        setIsVbVisible(true);
      }

    }catch(e){
      console.log(e);
    }
  }

  return (
    <div className=' flex flex-col items-center text-center gap-8 mt-10 lg:mt-0'>
      <div>
        <Typography type={size.isMobile ? 'display-600' : 'display-1000'}>
          Vow writing.
        </Typography>
        <Typography type={size.isMobile ? 'display-600' : 'display-1000'}>
          Made effortless.
        </Typography>
        <Typography type='body-600' className='max-w-xl mt-6'>
          Struggling to find the words for your vows? Our Vow Builder is here to
          help express your unique love for your partner while ensuring that
          both partners' vows strike the perfect balance in tone and length.
        </Typography>
      </div>

      <div className='w-full items-center justify-center flex flex-col md:flex-row gap-2 mt-6 mb-10 md:mt-8 md:mb-20'>

        {
          isVbVisible && (
            <Button
                  fullWidth={size.isMobile}
                  onClick={() => {
                    navigation(`/ceremony/${
                      ceremony?.id
                    }/vows`);
                  }}
                >
                  Generate Your Vows
                </Button>
          )
        }

        {!isVbVisible && (
          <ABTester
          components={[
            {
              component: (
                <Button
                  fullWidth={size.isMobile}
                  onClick={() => {
                    typeof onUpgradeButtonClick === 'function' &&
                      onUpgradeButtonClick();
                  }}
                >
                  Get Access
                </Button>
              ),
              render: ceremony?.vowslanding_test_category === 'new_copy',
            },
            {
              component: (
                <Button
                  fullWidth={size.isMobile}
                  leftImgSrc={gemSvg}
                  onClick={() => {
                    typeof onUpgradeButtonClick === 'function' &&
                      onUpgradeButtonClick();
                  }}
                >
                  Upgrade to Premium
                </Button>
              ),
              default: true,
            },
          ]}
        />
        )}
        <Button
          fullWidth={size.isMobile}
          renderRightIcon={() => (
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M20 4.8315V15.9697C20 16.4108 19.6422 16.7678 19.2 16.7678C18.7578 16.7678 18.4 16.4108 18.4 15.9697V6.76185L5.36489 19.7656C5.20938 19.9202 5.00438 20 4.79988 20C4.59538 20 4.39048 19.9221 4.23438 19.7662C3.92187 19.4544 3.92187 18.9491 4.23438 18.6379L17.2795 5.62419L7.99941 5.59652C7.5554 5.59466 7.1984 5.23553 7.1999 4.79658C7.20147 4.35544 7.5593 4 7.99991 4L19.2 4.03273C19.645 4.03541 20 4.39256 20 4.8315Z'
                fill='#354740'
              />
            </svg>
          )}
          variant='secondary'
          className='vow-benefit-placeholder'
          onClick={() => {
            if (ceremony?.id) {
              generalLog({
                ceremony_id: ceremony.id,
                activity: `LearnMore - VowBuilder - v2`,
              });
              //This is done because elevio stops propagation of the click event
              const button = document.querySelector('.vow-benefit-placeholder');
              const popup = window._elev.popupComponent(button, {
                articleId: '52',
              }) as any;
              popup.insert();
            }
          }}
        >
          Learn More
        </Button>
      </div>
      <div className='w-full flex flex-col justify-center items-center md:flex-row gap-3 flex-wrap'>
        <LanderItem
          borderSvgSource={border1Svg}
          iconSvgSource={image1Png}
          text='Complete our tailored questionnaire'
        />
        <LanderItem
          borderSvgSource={border2Svg}
          iconSvgSource={image2Png}
          text='Generate your personalized draft'
          shiftDown={size.isMobile ? false : true}
        />
        <LanderItem
          borderSvgSource={border3Svg}
          iconSvgSource={image3Png}
          text='Balance tone and length with partner'
        />
        <LanderItem
          borderSvgSource={border4Svg}
          iconSvgSource={image4Png}
          text='Keep your vows private or share them'
          shiftDown={size.isMobile ? false : true}
        />
      </div>
    </div>
  );
};

export default VowBuilderLocked;
