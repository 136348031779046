import { useFormikContext } from 'formik';

import Button from '../../../components/v2/Button/Button';

export default function SubmitCeremoniesButton() {
  const { submitForm, isSubmitting } = useFormikContext();

  return (
    <div className='text-center p-3 sticky bottom-0 set-border set-bg z-50'>
      <Button
        type='submit'
        disabled={isSubmitting}
        onClick={() => submitForm()}
      >
        Add Wedding(s) and Send Invites
      </Button>
    </div>
  );
}
