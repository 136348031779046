import React, { useEffect, useState } from 'react';

import { generateCeremonyScriptText } from '../api/textGenerationAi';
import { Module } from '../hooks/useActiveModule';
import { useSnackbar } from '../hooks/useSnackbar';

type GenerateModuleContextType = {
  isDoneGenerating: boolean;
  setIsDoneGenerating: (value: boolean) => void;
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  chapter: string;
  setChapter: (value: string) => void;
  nextChapterSlug: string;
  setNextChapterSlug: (value: string) => void;
  ceremonyId: string;
  setCeremonyId: (value: string) => void;
  triggerGenerateScript: boolean;
  setTriggerGenerateScript: (value: boolean) => void;
  module: Module | undefined;
  setModule: (value: Module | undefined) => void;
  resetState: () => void;
};

export const GenerateModuleContext =
  React.createContext<GenerateModuleContextType>({
    isDoneGenerating: false,
    setIsDoneGenerating: (value: boolean) => {},
    isModalVisible: false,
    setIsModalVisible: (value: boolean) => {},
    chapter: '',
    setChapter: (value: string) => {},
    nextChapterSlug: '',
    setNextChapterSlug: (value: string) => {},
    ceremonyId: '',
    setCeremonyId: (value: string) => {},
    triggerGenerateScript: false,
    setTriggerGenerateScript: (value: boolean) => {},
    module: undefined,
    setModule: (value: Module | undefined) => {},
    resetState: () => {},
  });

export const GenerateModuleProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDoneGenerating, setIsDoneGenerating] = useState(false);
  const [chapter, setChapter] = useState('');
  const [module, setModule] = useState<Module>();
  const [nextChapterSlug, setNextChapterSlug] = useState('');
  const [ceremonyId, setCeremonyId] = useState('');
  const [triggerGenerateScript, setTriggerGenerateScript] = useState(false);

  const { openSnackBar } = useSnackbar();

  useEffect(() => {
    if (triggerGenerateScript) {
      generateScript();
    }
  }, [triggerGenerateScript, ceremonyId, module, nextChapterSlug, chapter]);

  const resetState = () => {
    setIsDoneGenerating(false);
    setChapter('');
    setNextChapterSlug('');
    setCeremonyId('');
    setModule(undefined);
    setTriggerGenerateScript(false);
    setIsModalVisible(false);
  };

  const generateScript = async () => {
    setIsModalVisible(true);
    setIsDoneGenerating(false);

    if (ceremonyId && module) {
      try {
        const result = await generateCeremonyScriptText({
          ceremonyId: ceremonyId,
          selectedModule: module,
          saveResultOnBE: true,
        });

        if (module === Module.DirectionalQuestions) {
          setModule(Module.Vows);
        } else if (module === Module.DeclarationOfIntent) {
          setModule(Module.Pronouncement);
        } else if (result.success) {
          setTimeout(() => {
            setIsDoneGenerating(true);
            openSnackBar('Draft generated', 5000, 'success');
          }, 3000);
        }
      } catch (err) {
        console.log(err);
        // setIsDoneGenerating(true);
        openSnackBar('Error generating draft', 5000, 'error');
      } finally {
        if (
          module !== Module.DirectionalQuestions &&
          module !== Module.DeclarationOfIntent
        ) {
          setTriggerGenerateScript(false);
        }
      }
    }
  };

  const value = {
    isDoneGenerating,
    setIsDoneGenerating,
    isModalVisible,
    setIsModalVisible,
    chapter,
    setChapter,
    nextChapterSlug,
    setNextChapterSlug,
    ceremonyId,
    setCeremonyId,
    triggerGenerateScript,
    setTriggerGenerateScript,
    module,
    setModule,
    resetState,
  };
  // @ts-ignore
  return (
    <GenerateModuleContext.Provider value={value}>
      {children}
    </GenerateModuleContext.Provider>
  );
};

export const useGenerateModule = () => {
  return React.useContext(GenerateModuleContext);
};
