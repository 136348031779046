import { ModuleStatus } from '../pages/ModulesV2/enum';

import { _request } from './request';

export enum ChecklistItemType {
  ceremonyDesigner = 'ceremony_designer',
  vows = 'vows',
  toasts = 'toasts',
  speechwritingSupport = 'speechwriting_support',
  publicSpeaking = 'public_speaking',
  premaritalCounseling = 'premarital_counseling',
  legalServices = 'legal_services',
  weddingDate = 'wedding_date',
  invitePartner = 'invite_partner',
  invitePartnerA = 'invite_partner_a',
  invitePartnerB = 'invite_partner_b',
  inviteOfficiant = 'invite_officiant',
  inviteWeddingPlanner = 'invite_wedding_planner',
  includeVenue = 'include_venue',
  startVows = 'start_vows',
  completeVows = 'complete_vows',
  confirmTone = 'confirm_tone',
  inviteGuests = 'invite_guests',
  inviteGuestsReherseal = 'invite_guests_reherseal',
  prenump = 'prenump',
  getOrdained = 'get_ordained',
  welcome_toast = 'welcome_toast',
}

export type ChecklistSubItemProps = {
  title: string;
  description?: string;
  status?: ModuleStatus;
  buttonTitle?: string;
  onButtonClick?: () => Promise<void> | void;
  textButtonTitle?: string;
  onTextButtonClick?: () => Promise<void> | void;
  hidden?: boolean;
};

export type ChecklistItem = {
  type: ChecklistItemType;
  ceremony_id: number;
  member_id: number;
  skipped?: boolean;
};

export type SaveChecklistItemsResponse = {
  success: boolean;
  message: string;
  data: ChecklistItem;
};

export type GetChecklistItemsResponse = {
  success: boolean;
  message: string;
  data: ChecklistItem[];
};

export const getChecklistItemsForCeremony = async (ceremonyId: string) => {
  const responseBody = await _request<GetChecklistItemsResponse>({
    url: `checklistitems/${ceremonyId}`,
    method: 'GET',
  });
  return responseBody;
};

export const saveChecklistItem = async (checklistItem: ChecklistItem) => {
  const responseBody = await _request<SaveChecklistItemsResponse>({
    url: 'checklistitems/save',
    method: 'POST',
    body: checklistItem,
  });
  return responseBody;
};
