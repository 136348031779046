/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { GenericResponse } from './ceremony';
import { Speaker } from './friends';
import { CeremonyAccessibleModules, Order } from './paywall';
import { _request } from './request';

export interface CeremonySpeechesDetailsResponse {
  success: boolean;
  message: string;
  data: CeremonySpeechesDetailsData;
}

export interface CeremonySpeechesDetailsData {
  ceremony_status: number;
  vows_status_couple1: number;
  vows_status_couple2: number;
  speakers: Speaker[];
}

export const getCeremonySpeechesDetails = async (ceremonyId: number) => {
  const responseBody = await _request<CeremonySpeechesDetailsResponse>({
    url: `planner/output-status/${ceremonyId}`,
    method: 'GET',
  });
  return responseBody;
};

export const sendOutputReminder = async (ceremonyId: number) => {
  const responseBody = await _request<GenericResponse>({
    url: `planner/send-output-reminder/${ceremonyId}`,
    method: 'POST',
  });
  return responseBody;
};

export const sendSpeechReminder = async (
  ceremonyId: number,
  speaker_id: number
) => {
  const responseBody = await _request<GenericResponse>({
    url: `planner/send-guests-reminder/${speaker_id}/${ceremonyId}`,
    method: 'POST',
  });
  return responseBody;
};

export const sendWeddingApproachingReminder = async (memberId:number, ceremonyId: number) => {
  const responseBody = await _request<GenericResponse>({
    url: `planner/send-wedding-reminder/${memberId}/${ceremonyId}`,
    method: 'POST',
  });
  return responseBody;
};
