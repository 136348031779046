import { WORDS_PER_MINUTE } from '../constants/constants';

export const getTextDurationInMinutes = (
  value: string,
  extraDurtion?: number
) => {
  let result = 0;
  const divElement = document.createElement('div');
  divElement.innerHTML = value;
  const text = divElement.textContent || divElement.innerText;
  const words = text.split(/[ \n]+/).length - 1;
  result = Math.round(words / (WORDS_PER_MINUTE || 250));
  if (extraDurtion) {
    result += extraDurtion;
  }
  return result;
};

export const getTextDurationString = (text: string, extraDurtion?: number) => {
  //This should be the only function used for calculating duration on FE
  const minutes = getTextDurationInMinutes(text, extraDurtion);
  const result = getTextDurationStringFromMinutes(minutes);
  return result;
};

export const getTextDurationStringFromMinutes = (minutes: number) => {
  if (minutes > 0) {
    return minutes.toString();
  } else {
    return '<1';
  }
};

export const extractTextFromHtml = (html: string) => {
  const span = document.createElement('span');
  span.innerHTML = html;
  return span.textContent || span.innerText;
};

export const getWordsCount = (text: string | null | undefined) => {
  text = text?.trim();
  if (!text) return 0;
  const words = text.split(' ');
  return words.length;
};