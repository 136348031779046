import { redeemGiftcard } from '../../../api/giftcards';
import Button from '../../../components/v2/Button/Button';
import { Typography } from '../../../components/v2/Typography/Typography';
import { useDashboard } from '../../../provider/dashboardProvider';

import giftSvg from './images/gift.svg';

const GiftcardBanner = () => {
  const { setShowRedeeemGiftcardModal } = useDashboard();
  return (
    <div className='flex flex-row justify-between pl-8 pr-6 py-3 rounded-lg bg-forest-100 mb-6'>
      <div className='flex flex-col justify-center items-start'>
        <Typography type='display-100'>You have a gift card!</Typography>
        <Typography type='body-400' className='mt-1'>
          You have a gift card that you can redeem to unlock full access to this
          event.
        </Typography>
        <Button
          className='mt-3'
          variant='text'
          onClick={() => setShowRedeeemGiftcardModal(true)}
        >
          Redeem Now
        </Button>
      </div>
      <img src={giftSvg} />
    </div>
  );
};

export default GiftcardBanner;
