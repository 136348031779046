import { DeleteForeverOutlined, EditOutlined } from '@mui/icons-material';
import React, { useState } from 'react';

import { GuestSpeakerCard, GuestSpeakerCardProps } from '../../../components';
import { NewButton } from '../../../components/NewButton';
import Button from '../../../components/v2/Button/Button';
import { Modal } from '../../../components/v2/Modal/Modal';

type Props = {
  isOpen?: boolean;
  isDeleting?: boolean;
  onDeleteClick?: () => Promise<void> | void;
  onClose?: () => Promise<void> | void;
  fullName?: string;
  email?: string;
  eventName?: string;
};

export const RemoveGuestSpeakerModal = ({
  isOpen = false,
  onClose,
  fullName,
  eventName,
  email,
  onDeleteClick,
  isDeleting,
}: Props) => {
  const deleteModalProps = { isOpen, onClose: () => onClose?.() };
  return (
    <Modal {...deleteModalProps}>
      <div className='flex flex-col max-w-[328px] sm:max-w-[680px] md:max-w-[734px] gap-8'>
        <div className='flex flex-col gap-2'>
          <h1 className='font-nanum font-bold text-2xl sm:text-[28px] text-forest-primary'>
            Delete guest speaker
          </h1>
          <p className='font-inter text-sm sm:text-base text-forest-300'>
            Are you sure that you want to remove{' '}
            <strong>
              {fullName || 'N/A'} ({email || 'N/A'})
            </strong>{' '}
            from the <strong>{eventName}</strong> guest speaker list? This
            action can't be undone.
          </p>
        </div>

        {/* <div className='flex flex-col-reverse sm:flex-row justify-end gap-4'>
          <NewButton
            variant='outlined'
            onClick={onClose}
            disabled={isDeleting}
          >
            Cancel
          </NewButton>
          <NewButton
            className='bg-redOchre-primary'
            disabled={isDeleting}
            onClick={() => {
              if (typeof onDeleteClick === 'function')
                void onDeleteClick();
            }}
          >
            {isDeleting ? 'Deleting...' : 'Yes, Delete Guest'}
          </NewButton>
        </div> */}
        <div className={`flex flex-row justify-between pt-6`}>
          <Button onClick={onClose} disabled={isDeleting} variant='text'>
            Cancel
          </Button>
          <Button
            disabled={isDeleting}
            onClick={() => {
              if (typeof onDeleteClick === 'function') void onDeleteClick();
            }}
          >
            {isDeleting ? 'Deleting...' : 'Yes, Delete Guest'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
