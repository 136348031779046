import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import {
  closeSnackbar,
  SnackbarProvider as NotiSnackbarProvider,
} from 'notistack';
import React from 'react';

export const SnackbarProvider = ({ children }: React.PropsWithChildren) => (
  <NotiSnackbarProvider
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    action={(snackbarId) => (
      <IconButton onClick={() => closeSnackbar(snackbarId)} color='inherit'>
        <CloseIcon fontSize="small" />
      </IconButton>
    )}
  >
    {children}
  </NotiSnackbarProvider>
);
