import { MemberType } from "../../../../api/ceremony"

export const ELEVIO_ARTICLES = {
    'INVITE_OFFICIANT': {
        default: 88,
        [MemberType.weddingPlanner]: 94,
    },
    'INVITE_PARTNER_A':{
        default: 90,
        [MemberType.officiant]: 90,
        [MemberType.weddingPlanner]: 93,
    },
    'INVITE_PARTNER_B':{
        default: 86,
        [MemberType.officiant]: 90,
        [MemberType.weddingPlanner]: 93,
    },
    'INVITE_PLANNER':{
        default: 87,
        [MemberType.officiant]: 91,
    },
    'INVITE_GUEST':{
        default: 89,
        [MemberType.officiant]: 92,
        [MemberType.weddingPlanner]: 95,
    },
    'REMINDER_TO_GUEST':{
        default: 96,
        [MemberType.weddingPlanner]: 96,
    },
    'REFERRAL':{
        default: 97,
    },
    'GIFTING':{
        default: 98,
    },
}

export type ARTICLE_TYPES = keyof typeof ELEVIO_ARTICLES