/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useEffect, useState } from 'react';

import { getAllQuestions } from '../api/question';
import { capitalizeFirstLetter } from '../helpers/helper';
import useRituals from '../hooks/useRituals';
import { Question } from '../pages/Modules/common/moduleQuestion/ModuleQuestion';

type QuestionsContextType = {
  questions: Question[];
  fetchAllQuestions: (ceremonyId: string) => Promise<void>;
  ritualMinutes: number;
  willReadVows: boolean;
  willReadFirst: 'couple1' | 'couple2' | undefined;
  vowsTargetMinutes: number;
  ceremonyTargetMinutes: number;
  ceremonyTone: string;
};

export const AllQuestionsContext = React.createContext<QuestionsContextType>({
  questions: [],
  fetchAllQuestions: async () => {},
  ritualMinutes: 0,
  willReadVows: true,
  willReadFirst: undefined,
  vowsTargetMinutes: 0,
  ceremonyTargetMinutes: 0,
  ceremonyTone: '',
});

export const AllQuestionsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [ritualMinutes, setRitualMinutes] = useState(0);
  const [willReadVows, setWillReadVows] = useState(true);
  const [willReadFirst, setWillReadFirst] = useState<'couple1' | 'couple2'>();
  const [vowsTargetMinutes, setVowsTargetMinutes] = useState(0);
  const [ceremonyTargetMinutes, setCeremonyTargetMinutes] = useState(0);
  const [ceremonyTone, setCeremonyTone] = useState('');
  const [toastTone, setToastTone] = useState('');

  const { getFilteredRituals } = useRituals();

  const fetchAllQuestions = async (ceremonyId: string) => {
    try {
      const response = await getAllQuestions(ceremonyId);
      if (response.success) {
        setQuestions(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const vowsWillBeReadQuestion = questions.find(
      (q) => q.identifier && q.identifier?.indexOf('willReadVows') >= 0
    );
    if (
      vowsWillBeReadQuestion &&
      vowsWillBeReadQuestion.answers &&
      vowsWillBeReadQuestion.answers.length > 0
    ) {
      const answer = vowsWillBeReadQuestion.answers[0];
      const option = vowsWillBeReadQuestion.options?.find(
        (o) => o.id.toString() === answer.option_id
      );
      if (option) {
        if (option.option === 'No') {
          setWillReadVows(false);
        } else if (option.option === 'Yes') {
          setWillReadVows(true);
        }
      }
    }
    const whoWillReadFirstQuestion = questions.find(
      (q) => q.identifier && q.identifier?.indexOf('whoWillReadFirst') >= 0
    );
    if (
      whoWillReadFirstQuestion &&
      whoWillReadFirstQuestion.answers &&
      whoWillReadFirstQuestion.answers.length > 0
    ) {
      const answer = whoWillReadFirstQuestion.answers[0];
      const option = whoWillReadFirstQuestion.options?.find(
        (o) => o.id.toString() === answer.option_id
      );
      if (option) {
        if (option.option === '{{PARTNER_A}}') {
          setWillReadFirst('couple1');
        } else if (option.option === '{{PARTNER_B}}') {
          setWillReadFirst('couple2');
        }
      }
    }
    // set the target duration of couple's vows
    const vowsDurationQuestion = questions.find(
      (q) =>
        q.identifier &&
        q.identifier?.indexOf('Duration') >= 0 &&
        q.identifier?.indexOf('vows') >= 0
    );
    if (
      vowsDurationQuestion &&
      vowsDurationQuestion.answers &&
      vowsDurationQuestion.answers.length > 0
    ) {
      const answer = vowsDurationQuestion.answers[0];
      const option = vowsDurationQuestion.options?.find(
        (o) => o.id.toString() === answer.option_id
      );
      if (option) {
        if (option.option === '1 minute (~140 words)') {
          setVowsTargetMinutes(1);
        } else if (option.option === '2 minutes (~280 words)') {
          setVowsTargetMinutes(2);
        } else if (option.option === '3 minutes (~420 words)') {
          setVowsTargetMinutes(3);
        } else if (option.option === '4 minutes (~560 words)') {
          setVowsTargetMinutes(4);
        }
      }
    }
    //set the target duration of the whole ceremony
    const ceremonyDurationMinutes = questions.find(
      (q) => q.identifier === 'ceremonyDuration'
    );
    if (
      ceremonyDurationMinutes &&
      ceremonyDurationMinutes.answers &&
      ceremonyDurationMinutes.answers.length > 0
    ) {
      const answer = ceremonyDurationMinutes.answers[0];
      const option = ceremonyDurationMinutes.options?.find(
        (o) => o.id.toString() === answer.option_id
      );
      if (option) {
        if (option.option === '10 mins') {
          setCeremonyTargetMinutes(10);
        } else if (option.option === '20 mins') {
          setCeremonyTargetMinutes(20);
        } else if (option.option === '30+ mins') {
          setCeremonyTargetMinutes(30);
        }
      }
    }

    //set the tone of the ceremony
    const weddingToneQuestion = questions.find(
      (q) => q.identifier === 'weddingToneLogistics'
    );
    if (
      weddingToneQuestion &&
      weddingToneQuestion.answers &&
      weddingToneQuestion.answers.length > 0
    ) {
      const answer = weddingToneQuestion.answers[0];
      // const option = weddingToneQuestion.options?.find(
      //   (o) => o.id.toString() === answer.option_id
      // );
      const answerIds =
        answer && answer.option_id && answer.option_id.split(',');

      const options = weddingToneQuestion.options?.filter(
        (option) => answerIds && answerIds.includes(option.id.toString())
      );

      //join all options
      const option = options
        ?.map((option) => capitalizeFirstLetter(option.option))
        .join(', ');
      setCeremonyTone(option || '');
    }

    const setMinutes = async () => {
      //set the total time of the chosen rituals
      const readingsQuestion = questions.find((q) => q.type === 'readings');
      if (readingsQuestion && readingsQuestion.answers) {
        const readingIds = readingsQuestion.answers.map((a) =>
          a.option_id ? a.option_id : null
        );

        if (readingIds && readingIds.length > 0) {
          const filtered: string[] = [];
          readingIds.forEach((el) => {
            if (el !== null) {
              filtered.push(el);
            }
          });
          const rituals = await getFilteredRituals(filtered);
          if (rituals && rituals.length > 0) {
            const durations = rituals.map((r) => r.time || 0);
            const sumWithInitial = durations.reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              0
            );
            setRitualMinutes(sumWithInitial);
          }
        }
      }
    };

    void setMinutes();
  }, [getFilteredRituals, questions]);

  const value = {
    questions,
    fetchAllQuestions,
    ritualMinutes,
    willReadVows,
    willReadFirst,
    vowsTargetMinutes,
    ceremonyTargetMinutes,
    ceremonyTone,
  };
  // @ts-ignore
  return (
    <AllQuestionsContext.Provider value={value}>
      {children}
    </AllQuestionsContext.Provider>
  );
};

export const useAllQuestions = () => {
  return useContext(AllQuestionsContext);
};
