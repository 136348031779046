import gemSvg from './images/gem-3.svg';

const GemButton = () => {
  return (
    <div className='bg-marigold-500 rounded-lg p-1 ml-2 min-w-[24px]'>
      <img alt='diamond' src={gemSvg} />
    </div>
  );
};

export default GemButton;
