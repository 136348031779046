import { AddOutlined } from '@mui/icons-material';
import debounce from 'lodash.debounce';
import React, { memo, useCallback, useEffect, useState } from 'react';

import { saveAnswersIndividuals } from '../../../../api/question';
import { useCTA } from '../../../../provider/ctaProvider';
import { Question } from '../../../Modules/common/moduleQuestion/ModuleQuestion';

import { IndividualPersonForm } from './IndividualPersonForm';

export type Individual = {
  id?: number;
  name: string;
  type: string;
  description: string;
};

type QuestionIndividualPersonProps = {
  value?: Individual[];
  mainQuestion: Question;
  ceremonyId: number;
  optionId: string;
  personType?: 'individual' | 'vendor';
};

export const QuestionIndividualPerson = memo(
  ({
    value: valueProp,
    mainQuestion,
    ceremonyId,
    optionId,
    personType = 'individual',
  }: QuestionIndividualPersonProps) => {
    const { setStatus } = useCTA();
    const [individuals, setIndividuals] = useState<Individual[]>([
      { name: '', type: '', description: '' },
    ]);

    useEffect(() => {
      const items = valueProp || [];
      if (items.length) setIndividuals(items);
    }, [valueProp]);

    const saveAnswerHandler = useCallback(
      debounce(async (individualItems?: Individual[]) => {
        // trigger on start saving to parent.
        void setStatus('saving');

        const individualsAsAnswers = (individualItems || []).map(
          (individual) => ({
            option_id: optionId,
            id: individual.id,
            option_answer: individual.type || '',
            text_answer: individual.description || '',
            name_answer: individual.name || '',
            question_id: mainQuestion.id,
            ceremony_id: ceremonyId,
          })
        );

        if (individualItems?.length)
          void (await saveAnswersIndividuals({
            answers: [...individualsAsAnswers],
          }));

        // trigger saved event to parent.
        void setStatus('saved');
      }, 1000),
      []
    );

    const mergeIndividuals = (newIndividual?: Individual) => {
      if (newIndividual) return [...individuals, newIndividual];
      return individuals;
    };

    const updateIndividual = (
      index: number,
      field: string,
      value: string | number
    ) => {
      return individuals.map((individual, i) => {
        if (i === index) return { ...individual, [field]: value };
        return individual;
      });
    };

    const deleteIndividualAtIndex = (index: number) =>
      individuals.filter((_, i) => i !== index);
    return (
      <div className='flex flex-col gap-4'>
        {individuals.map(({ name, type, description }, individualIndex) => (
          <IndividualPersonForm
            key={`individual-${individualIndex}`}
            {...{ name, type, description }}
            showDeleteButton={individuals.length > 1}
            onDelete={() => {
              const newIndividuals = deleteIndividualAtIndex(individualIndex);
              setIndividuals(newIndividuals);
              void saveAnswerHandler(newIndividuals);
            }}
            onChange={(field, value) => {
              const newIndividuals = updateIndividual(
                individualIndex,
                field,
                value
              );
              setIndividuals(newIndividuals);
              void saveAnswerHandler(newIndividuals);
            }}
            personType={personType}
          />
        ))}
        <button
          className='flex flex-row items-center gap-1 self-center'
          onClick={() => {
            const newIndividual: Individual = {
              name: '',
              type: '',
              description: '',
            };
            const newIndividuals = mergeIndividuals(newIndividual);
            setIndividuals(newIndividuals);
            //void saveAnswerHandler(newIndividuals);
          }}
        >
          <AddOutlined />
          <span className='font-grotesk text-forest-500 underline underline-offset-4 decoration-1 decoration-dashed'>
            Add Another
          </span>
        </button>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return true;
  }
);
