import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { Member } from '../../../../../../api/ceremony';
import { Declaration } from '../../../../../../api/declaration';
import { Pronouncement } from '../../../../../../api/pronouncement';
import { TextField } from '../../../../../../components';
import { useOptionsQuestion } from '../../../../../../provider/questionProviders/optionsQuestionProvider';
import {
  Question,
  QuestionOptions,
} from '../../../../../Modules/common/moduleQuestion/ModuleQuestion';
import {
  Individual,
  QuestionIndividualPerson,
} from '../../../../Logistics/QuestionIndividualPerson';
import {
  getOptionDescription,
  getOptionLabel,
  getQuestionSelectType,
  QuestionSelectOptionType,
} from '../../helpers';

import CollapsibleSelectOption from './CollapsibleSelectOption';
import SelectOptionWithDescription from './SelectOptionWithDescription';
import SimpleSelectOption from './SimpleSelectOption';

export type SelectOptionProps = {
  label: React.ReactNode;
  isSelected?: boolean;
  description?: React.ReactNode;
  onClick?: () => Promise<void> | void;
  useHtmlDescription?: boolean;
};

export type QuestionSelectProps = {
  textValueInitial?: string | null;
  selectedOptionIdsInitial: string[];
  onChange?: (value: string) => Promise<void> | void;
  onTextChange?: (value: string) => Promise<void> | void;
  placeholder?: string;
  label?: string;
  options: QuestionOptions[];
  renderFooter?: () => React.ReactNode;
  suggestedMinWords?: number;
  useHtmlDescription?: boolean;
  maxOptions?: number;
  question: Question;
  members: {
    couple1: Member | undefined;
    couple2: Member | undefined;
    officiant: Member | undefined;
    currentUser: Member | undefined;
  };
  ceremonyId: number;
};

export const QuestionSelect = (props: QuestionSelectProps) => {
  const {
    textValueInitial,
    selectedOptionIdsInitial,
    placeholder,
    renderFooter,
    useHtmlDescription,
    options,
    question,
    members,
    ceremonyId,
  } = props;

  const { text, setText, selectedOptionIds, setSelectedOptionIds } =
    useOptionsQuestion();
  const [showTextField, setShowTextField] = useState(false);

  useEffect(() => {
    if (textValueInitial) {
      setText(textValueInitial, true);
    }
    if (selectedOptionIdsInitial) {
      setSelectedOptionIds(selectedOptionIdsInitial, true);
    }
  }, [textValueInitial, selectedOptionIdsInitial]);

  useEffect(() => {
    setShowTextField(shouldRenderTextField());
  }, [selectedOptionIds, options]);

  const shouldRenderTextField = useCallback(() => {
    const hasIndividualForm = !!question.identifier?.includes('individual');
    const isSpecialAnnouncements = !!question.identifier?.includes(
      'specialAnnouncements'
    );
    const hasTextfield = question.has_textfield === true;
    const currentValue =
      options &&
      selectedOptionIds &&
      options.find(
        (option) => option.id?.toString() === selectedOptionIds[0]?.toString()
      );
    if (
      isSpecialAnnouncements &&
      !!currentValue &&
      currentValue?.option !== 'None'
    )
      return true;

    if (hasIndividualForm) return false;

    return hasTextfield && currentValue?.option === 'Yes';
  }, [selectedOptionIds, options]);

  const shouldRenderIndividualForm = useCallback(() => {
    const currentValue =
      options &&
      selectedOptionIds &&
      options.find(
        (option) => option.id?.toString() === selectedOptionIds[0]?.toString()
      );
    const result =
      (question.identifier?.includes('individual') ||
        question.individual_form_type) &&
      currentValue &&
      currentValue.option === 'Yes';
    return result;
  }, [question.identifier, selectedOptionIds]);

  //   parseQuestion({ question, createManagedQuestion, index: questionIndex }) {
  //     const hasIndividualForm = !!question?.identifier?.includes('individual');
  //     const individuals = hasIndividualForm
  //       ? question?.answers?.map((answer, answerIdx) => ({
  //           id: answer.id,
  //           name: answer.name_answer,
  //           description: answer.text_answer,
  //           type: answer.option_answer,
  //         }))
  //       : [];
  //     return {
  //       ...question,
  //       additionalQuestions: {
  //         individuals,
  //       },
  //     };
  //   },
  const getIndividuals = (question: Question) => {
    const filteredAnswers = question.answers?.filter(
      (answer) =>
        answer.id &&
        answer.name_answer &&
        answer.text_answer &&
        answer.option_answer
    );
    const individuals: Individual[] = filteredAnswers
      ? filteredAnswers.map((answer) => ({
          id: answer.id!,
          name: answer.name_answer!,
          description: answer.text_answer!,
          type: answer.option_answer!,
        }))
      : [];
    return individuals;
  };

  const handleSetSelectedOptionsIds = (optionId: string) => {
    if (selectedOptionIds?.includes(optionId) && selectedOptionIds.length > 1) {
      setSelectedOptionIds(selectedOptionIds.filter((id) => id !== optionId));
    } else {
      if (
        question.max_options &&
        selectedOptionIds.length >= question.max_options
      ) {
        return;
      } else if (!question.max_options && selectedOptionIds.length >= 1) {
        setSelectedOptionIds([optionId]);
      } else {
        setSelectedOptionIds([...selectedOptionIds, optionId]);
      }
    }
  };

  return (
    <div className='flex flex-col pb-6 sm:pb-8'>
      <div
        className={classNames({
          'flex flex-row flex-wrap gap-2':
            getQuestionSelectType(question) === 'inline',
          'grid gap-2 grid-cols-2 sm:grid-cols-3':
            getQuestionSelectType(question) === 'grid',
          'flex flex-col gap-2':
            getQuestionSelectType(question) === 'collapsible',
        })}
      >
        {options.map((option, index) => {
          const label = getOptionLabel(option, members);
          const description = getOptionDescription(option, members);
          const isSelected = selectedOptionIds?.some?.((val: string) => {
            return val != null && val?.toString() === option.id?.toString();
          });
          const isSimpleSelect = !description;
          const isCollapsible =
            getQuestionSelectType(question) ===
            QuestionSelectOptionType.Collapsible;
          if (isCollapsible)
            return (
              <CollapsibleSelectOption
                key={`simple-select-${index}`}
                {...{ label, isSelected, description, useHtmlDescription }}
                onClick={() =>
                  handleSetSelectedOptionsIds(option.id.toString())
                }
              />
            );

          if (isSimpleSelect)
            return (
              <SimpleSelectOption
                key={`simple-select-${index}`}
                {...{ isSelected, label }}
                onClick={() =>
                  handleSetSelectedOptionsIds(option.id.toString())
                }
              />
            );

          return (
            <SelectOptionWithDescription
              key={`select-with-description-${index}`}
              {...{ label, isSelected, description, useHtmlDescription }}
              onClick={() => handleSetSelectedOptionsIds(option.id.toString())}
            />
          );
        })}
      </div>

      {showTextField && (
        <div className='flex flex-col mt-2'>
          <TextField
            placeholder={placeholder}
            value={text}
            onChange={(evt) => {
              setText(evt.target.value);
            }}
            fontVariant='grotesk'
            label='Please explain'
            labelVariant='outside'
            minRows={3}
            fullWidth
            multiline
          />
        </div>
      )}
      {shouldRenderIndividualForm() && (
        <QuestionIndividualPerson
          value={getIndividuals(question)}
          mainQuestion={question}
          ceremonyId={ceremonyId}
          optionId={selectedOptionIds[0] || ''}
          personType={question.individual_form_type || 'individual'}
        />
      )}

      {typeof renderFooter === 'function' && (
        <div className='flex flex-col'>{renderFooter()}</div>
      )}
    </div>
  );
};
