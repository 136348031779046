import { useDashboard } from '../../../provider/dashboardProvider';
import { Modal } from '../Modal/Modal';
import YoutubeEmbed from '../YoutubeEmbed/YoutubeEmbed';


const VideoModal = () => {
  const { youtubeVideoModalOpen, setYoutubeVideoModalOpen, youtubeVideoUrl } = useDashboard();
  return (
    <Modal
      isOpen={youtubeVideoModalOpen}
      onClose={() => setYoutubeVideoModalOpen(false)}
      fullWidth={false}
      fillScreen={false}
      maxWidth='lg'
    >
      <YoutubeEmbed
        embedId={youtubeVideoUrl}
      />
    </Modal>
  );
};

export default VideoModal;
