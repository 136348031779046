import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';

import { MemberSubType, MemberType } from '../api/ceremony';
import {
  getSpeakers,
  Speaker as APISpeaker,
  removeSpeaker,
} from '../api/friends';
import { addSpeakers } from '../api/speakers';
import { getEventName } from '../helpers/dropdownHelper';
import { getAPIExceptionMessage } from '../helpers/helper';

export type Speaker = {
  id: number;
  name: string;
  email: string;
  role: MemberType | MemberSubType;
  event: string;
  progress: Record<string, number>;
  alloted_time: number; // in minutes
  order: number | null;
  originalSpeakerData: APISpeaker;
  member_id?: number;
};

export type Event = {
  id: string;
  name: string;
  speakers: Speaker[];
};

type UseSpeakersProps = {
  ceremonyId: number | string;
};

function groupByEvent(data: Speaker[]): Event[] {
  const eventKeys = data.reduce<Record<string, Speaker[]>>((acc, speaker) => {
    const eventKey = speaker.event;
    if (!acc[eventKey]) acc[eventKey] = [];
    acc[eventKey].push(speaker);
    return acc;
  }, {});
  return Object.entries(eventKeys).map(([eventKey, speakers]) => ({
    id: eventKey,
    name: getEventName(eventKey),
    speakers,
  }));
}

function parseSpeaker(data: APISpeaker): Speaker {
  const { id, alloted_time, member, event, order } = data;
  const { legal_name, preferred_name, email, member_sub_type, member_type } =
    member;
  const name = preferred_name || legal_name;
  const role = member_sub_type || member_type;
  return {
    id,
    name,
    email,
    role,
    alloted_time,
    member_id: member.id,
    event,
    originalSpeakerData: data,
    order,
    progress: data.member?.output?.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.module]: curr.output_complete ? 100 : 50,
      }),
      {}
    ),
  } as Speaker;
}

function parseAPISpeaker(data: Speaker): APISpeaker {
  const { id, alloted_time, event, order, originalSpeakerData } = data;

  return {
    ...originalSpeakerData,
    id,
    alloted_time,
    event,
    order,
  } as APISpeaker;
}

export const useSpeakers = (props: UseSpeakersProps) => {
  const { ceremonyId } = props;
  const [loading, setLoading] = useState(true);
  const [speakers, setSpeakers] = useState<Speaker[]>([]);
  const [speakerEvents, setSpeakerEvents] = useState<Event[]>([]);
  const { enqueueSnackbar: openSnackbar } = useSnackbar();

  const fetchSpeakers = async () => {
    setLoading(true);
    try {
      const res = await getSpeakers(Number(ceremonyId));
      setSpeakers(res.data.map(parseSpeaker));
    } catch (err) {
      const errorMessage = getAPIExceptionMessage(err);
      openSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const deleteSpeaker = useCallback(async (id: string | number) => {
    try {
      await removeSpeaker({ speaker_id: Number(id) });
      setSpeakers((oldSpeakers) =>
        oldSpeakers.filter((s) => s.id !== Number(id))
      );
      openSnackbar('Member removed.', { variant: 'success' });
    } catch (err) {
      const errorMessage = getAPIExceptionMessage(err);
      openSnackbar(errorMessage, { variant: 'error' });
    }
  }, []);

  const saveSpeakersInBulk = async (
    ceremonyId: string,
    speakers: Speaker[]
  ) => {
    try {
      const response = await addSpeakers(
        ceremonyId,
        speakers.map(parseAPISpeaker)
      );

      return response.success;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // trigger init
    void fetchSpeakers();
  }, [ceremonyId]);

  useEffect(() => {
    if (speakers.length) setSpeakerEvents(groupByEvent(speakers));
    else setSpeakerEvents([]);
  }, [speakers]);

  return {
    speakerEvents,
    setSpeakerEvents,
    loading,
    deleteSpeaker,
    fetchSpeakers,
    saveSpeakersInBulk,
  };
};
