import classNames from 'classnames';

import { useDashboard } from '../../../provider/dashboardProvider';
import ABTester from '../ABTester/ABTester';
import Button from '../Button/Button';
import { Typography } from '../Typography/Typography';

import emptyBoxSvg from './emptyBox.svg';
import './EmptyState.scss';

type EmptyStateProps = {
  title: string;
  text: string;
  textChildren?: React.ReactNode;
  buttonText?: string;
  onClick?: () => void;
  customIcon?: string;
  customIconWidth?: string;
  background?: 'no-bg' | 'grey';
  className?: string;
};
const EmptyState = (props: EmptyStateProps) => {
  const {
    title,
    text,
    buttonText,
    textChildren,
    customIcon,
    customIconWidth,
    onClick,
    background = 'no-bg',
    className = '',
  } = props;

  const { ceremony } = useDashboard();

  return (
    <ABTester
      components={[
        {
          component: (
            <div className='flex flex-1 flex-col w-full h-full items-center justify-center'>
              <Typography type='display-50' className='mb-2'>
                {title}
              </Typography>
              <Typography type='body-400' className='mb-8 max-w-xs text-center'>
                <>{textChildren || text}</>
              </Typography>
              {buttonText && onClick && (
                <Button onClick={onClick}>Get Started</Button>
              )}
            </div>
          ),
          render: ceremony?.vowslanding_test_category === 'new_copy',
        },
        {
          component: (
            <div className={classNames(className + ' flex flex-1 flex-col w-full h-full items-center justify-center', {
              'empty-state-bg-grey': background === 'grey',
            })}>
              <img
                className={`${customIconWidth || 'w-24'}`}
                src={customIcon || emptyBoxSvg}
              />
              <Typography type='display-50' className='mb-2'>
                {title}
              </Typography>
              <Typography type='body-400' className='mb-8 max-w-xs text-center'>
                <>{textChildren || text}</>
              </Typography>
              {buttonText && onClick && (
                <Button onClick={onClick}>{buttonText}</Button>
              )}
            </div>
          ),
          default: true,
        },
      ]}
    />
  );
};

export default EmptyState;
