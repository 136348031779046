import React from 'react';

import PaywallContent from './PaywallContent';

export const WPPayPlans = () => {

    return (
        <div
            className={`bg-cosmicLatte-primary w-full text-forest-primary overflow-x-hidden pt-4 md:pt-10 min-h-[100vh]`}
        >
            <PaywallContent />
        </div>
    )
}