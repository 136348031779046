import React, { useContext, useEffect, useState } from 'react';

import {
  ChecklistItemDefinition,
  getChecklistItemsForCeremony,
  MemberChecklistItem,
  saveChecklistItem,
} from '../api/checklistItemDefinitions';
import { ChecklistItemIdentifier } from '../helpers/checklistHelpers/identifiers';
import { getChecklistItemStatusFromDbStatus } from '../helpers/checklistHelpers/statusHelper';
import { ChecklistItemStatus } from '../pages/Ceremony/CeremonyChecklist/v2/ChecklistItem';

type ChecklistContextType = {
  checklistItems: ChecklistItemDefinition[];
  completedChecklistItemsCount: number;
  fetchChecklistItems: (
    ceremonyId: number | null,
    isWeddingPlanner: boolean | undefined
  ) => Promise<void>;
  saveMemberChecklistItem: (
    memberChecklistItem: MemberChecklistItem,
    saveOnUserLevel?: boolean
  ) => Promise<void>;
  saveWpMemberChecklistItem: (
    memberChecklistItem: MemberChecklistItem
  ) => Promise<void>;
  saveMemberChecklistItemUsingIdentifier: (
    memberChecklistItem: Partial<MemberChecklistItem>,
    identifier: ChecklistItemIdentifier,
    isWp?: boolean,
    isUserLevel?: boolean
  ) => Promise<void>;
};

export const ChecklistContext = React.createContext<ChecklistContextType>({
  checklistItems: [],
  completedChecklistItemsCount: 0,
  fetchChecklistItems: async () => {},
  saveMemberChecklistItem: async () => {},
  saveWpMemberChecklistItem: async () => {},
  saveMemberChecklistItemUsingIdentifier: async () => {},
});

export const ChecklistProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [checklistItems, setChecklistItems] = useState<
    ChecklistItemDefinition[]
  >([]);

  const [completedChecklistItemsCount, setCompletedChecklistItemsCount] =
    useState(0);

  useEffect(() => {
    let temp = 0;
    checklistItems.map((item) => {
      const status = getChecklistItemStatusFromDbStatus(
        item.member_checklist_item
      );
      if (
        status === ChecklistItemStatus.completed ||
        status === ChecklistItemStatus.skipped
      ) {
        temp++;
      }
    });
    setCompletedChecklistItemsCount(temp);
  }, [checklistItems]);

  const fetchChecklistItems = async (
    ceremonyId: number | null = null,
    isWeddingPlanner: boolean | null = false
  ) => {
    try {
      const response = await getChecklistItemsForCeremony(
        ceremonyId,
        isWeddingPlanner || false
      );
      if (response.success) {
        setChecklistItems(response.data);
      }
    } catch (error) {}
  };

  const saveWpMemberChecklistItem = async (
    memberChecklistItem: MemberChecklistItem
  ) => {
    await saveMemberChecklistItem(memberChecklistItem, true);
  };

  const saveMemberChecklistItem = async (
    memberChecklistItem: MemberChecklistItem,
    isWp = false,
    isUserLevel = false
  ) => {
    try {
      const response = await saveChecklistItem(
        memberChecklistItem,
        isWp,
        isUserLevel
      );
      if (response.success) {
        if (isWp) {
          void fetchChecklistItems(null, true);
          return;
        }

        if (memberChecklistItem.ceremony_id) {
          void fetchChecklistItems(memberChecklistItem.ceremony_id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const saveMemberChecklistItemUsingIdentifier = async (
    memberChecklistItem: Partial<MemberChecklistItem>,
    identifier: ChecklistItemIdentifier,
    isWp = false,
    isUserLevel = false
  ) => {
    if (!checklistItems || checklistItems.length === 0) {
      const response = await getChecklistItemsForCeremony(
        memberChecklistItem.ceremony_id,
        isWp
      );

      if (response.success) {
        setChecklistItems(response.data);
        const checklistItem = response.data.find(
          (item) => item.identifier === identifier
        );

        if (checklistItem) {
          await saveMemberChecklistItem(
            {
              ...memberChecklistItem,
              checklist_item_definition_id: checklistItem.id,
            },
            isWp,
            isUserLevel
          );
        }
      }
    } else {
      const checklistItem = checklistItems.find(
        (item) => item.identifier === identifier
      );

      if (checklistItem) {
        await saveMemberChecklistItem(
          {
            ...memberChecklistItem,
            checklist_item_definition_id: checklistItem.id,
          },
          isWp,
          isUserLevel
        );
      }
    }
  };

  const value = {
    checklistItems,
    completedChecklistItemsCount,
    fetchChecklistItems,
    saveMemberChecklistItem,
    saveWpMemberChecklistItem,
    saveMemberChecklistItemUsingIdentifier,
  };
  // @ts-ignore
  return (
    <ChecklistContext.Provider value={value}>
      {children}
    </ChecklistContext.Provider>
  );
};

export const useChecklist = () => {
  return useContext(ChecklistContext);
};
