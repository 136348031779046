import { Pronouncement } from '../../../api/pronouncement';

type Props = {
  pronouncement: Pronouncement;
  onUsePronouncementClick?: (pronouncementId: number) => void;
  expanded: boolean;
  setExpanded: (pronouncementId: number) => void;
};

export const PronouncementItem = (props: Props) => {
  const { pronouncement, onUsePronouncementClick, expanded, setExpanded } = props;

  return (
    <div>
      <div
        className='w-full bg-white px-6 py-5 mb-4'
        onClick={() => setExpanded(pronouncement.id)}
      >
        {pronouncement.name}
        {expanded && (
          <div className='relative'>
            <div className=' my-4 border border-t-0 border-forest-primary' />
            <div className='flex flex-col'>
              <div dangerouslySetInnerHTML={{ __html: pronouncement.text }} />
              {onUsePronouncementClick && (
                <div
                  className='mt-5 ml-auto py-4 h-15 w-52 flex justify-center items-center bg-forest-primary text-white cursor-pointer'
                  onClick={() => onUsePronouncementClick(pronouncement.id)}
                >
                  USE PRONOUNCEMENT
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
