import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MemberType } from '../../../api/ceremony';
import { ChecklistItemDefinition } from '../../../api/checklistItemDefinitions';
import { CustomSwitch } from '../../../components/forms/CustomSwitch';
import Button from '../../../components/v2/Button/Button';
import circularProgressBar from '../../../components/v2/CircularProgressBar/CircularProgressBar';
import { ReferFriendModal } from '../../../components/v2/ReferFriendModal/ReferFriendModal';
import { Typography } from '../../../components/v2/Typography/Typography';
import VideoModal from '../../../components/v2/VideoModal/VideoModal';
import { getGroupChecklistItem } from '../../../helpers/checklistHelpers/checklistItemHelper';
import useToast from '../../../hooks/useToast';
import { useDashboard } from '../../../provider/dashboardProvider';
import { useAccessibleModule } from '../../../provider/moduleAccessProvider';
import '../CeremonyChecklist/ceremonyChecklistDashboard.scss';
import { useChecklist } from '../../../provider/checklistProvider';
import ChecklistGroup, {
  ChecklistGroups,
  GroupChecklistItem,
} from '../CeremonyChecklist/v2/ChecklistGroup';
import { useCreateEvent } from '../../../provider/createEventProvider';
import { setCookie } from '../../../helpers/helper';

import classNames from 'classnames';

import { useWindowSize } from '../../../hooks/useWindowSize';
import { Module } from '../../../hooks/useActiveModule';
import { NPSModal } from '../../Export/Modals/NPSModal';
import { FeedbackModal } from '../../Export/Modals/FeedbackModal';
import { useNPS } from '../../../provider/NPSProvider';

const GuestChecklist = () => {
  const { ceremony, currentUser, currentRole } = useDashboard();

  const { completedChecklistItemsCount, checklistItems, saveMemberChecklistItem, fetchChecklistItems} = useChecklist();
  const [showCompleted, setShowCompleted] = React.useState(true);

  const {
    isNewToastBuilder,
    isOldToastBuilder,
    setShowOldToastBuilder,
  } = useToast();

  const {
    loadInvites,
    setLoadInvites,
    showInvites,
    showFeedback,
    userSubmittedNPS,
    finishFeedback,
    showNPSModal,
    setNPSUserId,
    setNPSCeremonyId,
    setNPSSection,
  } = useNPS();

  const navigate = useNavigate();
  const { setEventRoleModalOpen } = useCreateEvent()
  const { isMobile } = useWindowSize()

  const { hasAccessToToastBuilder} = useAccessibleModule();

  if (currentRole && currentRole !== MemberType.guests) {
    navigate(`/ceremony/${ceremony?.id.toString() || ''}`);
  }

  const asyncFetchChecklistItems = async () => {
    if (ceremony?.id) {
      await fetchChecklistItems(ceremony.id, false);
    }
  }

  useEffect(() => {
    setNPSSection('checklist');
  }, []);

  useEffect(() => {
    if (currentUser && currentUser.user_id) setNPSUserId(currentUser.user_id);
  }, [currentUser]);

  useEffect(() => {
    if (ceremony?.id) {
      setNPSCeremonyId(ceremony.id);
      setCookie('last_active_ceremony', ceremony.id.toString(), 365);
    }
    asyncFetchChecklistItems()
  }, [ceremony]);

  useEffect(() => {
    if (isNewToastBuilder) {
      setShowOldToastBuilder(false);
    } else if (isOldToastBuilder) {
      setShowOldToastBuilder(true);
    }
  }, [isOldToastBuilder, isNewToastBuilder]);

  const handleShowNPSModal = () => {
    if (!userSubmittedNPS) {
      showNPSModal();
    }
  };
  
  const checkAccess = (module: Module) => {
    return hasAccessToToastBuilder
  };
  
  const getChecklistItemsByGroup = (
    checklistItems: ChecklistItemDefinition[],
    group: ChecklistGroups
  ) => {
    const items = checklistItems?.filter((item) => item.group === group).sort((a, b) => a.order - b.order);
    const result = [] as GroupChecklistItem[];
    items.map((item) => {
      const mapped = getGroupChecklistItem(
        item,
        navigate,
        checkAccess,
        saveMemberChecklistItem,
        ()=>{},
        ()=>{},
        handleShowNPSModal,
        ceremony?.id,
        currentUser,
      );
      if (mapped) {
        result.push(mapped);
      }
    });
    
    return result;
  };

  return (
    <div className='CeremonyChecklist-root'>
      <div className='CeremonyChecklist-header'>
        <div>
          <Typography type='display-400' className='pb-2'>
            Your Onboarding Checklist
          </Typography>
          {/* <Typography type='body-400' className='max-w-[540px]'>
            Unam incolunt Belgae, aliam Aquitani, tertiam. Quisque placerat facilisis egestas cillum dolore. Non equidem invideo, miror magis posuere velit.
          </Typography> */}
        </div>
        {currentRole !== MemberType.officiant && (
          <div className='block'>
            <div className='flex items-center'>
              <div className='mr-4 w-[75px]'>
                {circularProgressBar(
                  completedChecklistItemsCount,
                  checklistItems.length
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='flex flex-row justify-end'>
        <div className='flex flex-row'>
          <CustomSwitch
            checked={showCompleted}
            onChange={() => setShowCompleted((old) => !old)}
          />
          <Typography type='body-400' className='ml-2'>
            Show completed
          </Typography>
        </div>
      </div>
      <div className={showCompleted ? '' : 'hide-completed'}>
        {ceremony && (
          <>
            <ChecklistGroup
              title='Welcome to your onboarding checklist, lets get started!'
              checklistItems={getChecklistItemsByGroup(
                checklistItems,
                ChecklistGroups.GUEST
              )}
            />
            <ChecklistGroup
              title='Your job here is done! Good luck'
              checklistItems={[]}
            />
            <div className={classNames('mt-2',{
              'ml-10': !isMobile
            })}>
              <Typography type='body-400' className='max-w-[540px]'>
                Are you speaking at another wedding soon? Get started by creating a new toast for the new event.
              </Typography>
              <Button
                onClick={() => setEventRoleModalOpen(true)}
                className='mt-4'
              >
                Write a new toast
              </Button>
            </div>

          </>
        )}
      </div>
      <NPSModal
        onContinueHigh={showInvites}
        onContinueLow={showFeedback}
        threshold={8}
      />

      <FeedbackModal
        onCancelClick={finishFeedback}
        onContinueClick={finishFeedback}
      />
      <ReferFriendModal
        isOpen={loadInvites}
        onClose={() => setLoadInvites(false)}
        ceremonyId={ceremony?.id ? ceremony?.id : undefined}
      />
      <VideoModal />
    </div>
  );
};

export default GuestChecklist;
