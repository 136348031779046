/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { Formik, Form, Field, FieldArray } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { Ceremony, Member } from '../../../api/ceremony';
import {
  Friend,
  getFriends,
  removeMember,
  RemoveMemberBody,
  saveFriends,
  SaveFriendsBody,
  sendFriendsInvitation,
} from '../../../api/friends';
import { saveAnswer } from '../../../api/question';
import profileIcon from '../../../assets/images/icons/Profile.svg';
import QuestionSaveStatus from '../../../components/QuestionSave';
import SnackbarComponent from '../../../components/Snackbar/Snackbar';
import Button from '../../../components/button';
import CustomRadioSelect, {
  OptionButton,
} from '../../../components/forms/CustomRadioSelect';
import CustomTextInput from '../../../components/forms/CustomTextInput';
import { getValueById } from '../../../helpers/helper';
import { replacePlaceholders } from '../../../helpers/placeholderHelper';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useActiveQuestion } from '../../../provider/activeQuestionProvider';
import {
  Question,
  QuestionAnswer,
} from '../common/moduleQuestion/ModuleQuestion';
import { Tip } from '../common/tip/Tip';

import { CustomFormikInput } from './CustomFormikInput';

type QuestionProps = {
  question: Question;
  setQuestionChanged: (value: boolean) => void;
  couple1?: Member;
  couple2?: Member;
  officiant?: Member;
  currentUser?: Member;
  ceremony?: Ceremony;
  showTip?: boolean;
  readOnly?: boolean;
  asterisk?: boolean;
};

interface FormValues {
  friends: Friend[];
}

export const FriendsAndFamilyQuestion = (props: QuestionProps) => {
  const { isActive, message, type, openSnackBar, handleClose } = useSnackbar();
  const { activeQuestionId, setActiveQuestionId } = useActiveQuestion();

  const {
    question,
    ceremony,
    showTip = true,
    couple1,
    couple2,
    officiant,
    currentUser,
    readOnly,
    setQuestionChanged,
    asterisk,
  } = props;
  const [title, setTitle] = useState<string>('Send Invitations');
  const [changed, setChanged] = useState<boolean>(false);
  const [tipExpanded, setTipExpanded] = useState<number>(0);
  const [optionId, setOptionId] = useState<string | null>(null);
  const [textAnswer, setTextAnswer] = useState<string>('');
  const [friendsFromDb, setFriendsFromDb] = useState<Friend[]>([]);
  const [showAdditionOption, setShowAdditionOption] = useState<boolean>(false);

  const [initialVals, setInitialVals] = useState<Friend>({
    legal_name: '',
    email: '',
  });

  const newSpeakerSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    legal_name: Yup.string().required('Friend Name required'),
  });

  useEffect(() => {
    if (question && question.answers) {
      const answer = question.answers[0];
      if (answer && answer.option_id) {
        setOptionId(answer.option_id);
        setShowAdditionOption(
          getValueById(answer.option_id, question.options || []).option ===
            'Yes'
            ? true
            : false
        );
        setTextAnswer(answer.text_answer || '');
      }
    }
  }, [question]);

  useEffect(() => {
    void fetchFriends();
  }, [ceremony]);

  const fetchFriends = async () => {
    if (ceremony) {
      try {
        const response = await getFriends(ceremony?.id);
        if (response.success) {
          setFriendsFromDb(response.data);
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (activeQuestionId && activeQuestionId !== question.id) {
      setTipExpanded(0);
    } else if (activeQuestionId && activeQuestionId === question.id) {
      setTipExpanded(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuestionId]);

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(true);
    setOptionId(ev.target.value);
    setShowAdditionOption(
      getValueById(ev.target.value, question.options || []).option === 'Yes'
        ? true
        : false
    );
    void saveQuestionAnswer(ev.target.value);
  };

  const handleError = () => {
    setChanged(true);
    openSnackBar('Unexpected error', 5000, 'error');
  };

  const saveQuestionAnswer = async (optionId: string) => {
    if (ceremony) {
      const answer: QuestionAnswer = {
        ceremony_id: ceremony.id,
        question_id: question.id,
        option_id: optionId,
        text_answer: textAnswer,
      };
      try {
        const saveResponse = await saveAnswer(answer);
        if (saveResponse.success) {
          openSnackBar('Answer saved', 5000, 'success');
        } else {
          handleError();
        }
      } catch (err) {
        handleError();
      }
    }
  };

  const handleSave = async (values: Friend, resetForm: any) => {
    if (ceremony) {
      const answer: QuestionAnswer = {
        ceremony_id: ceremony.id,
        question_id: question.id,
        option_id: optionId || undefined,
        text_answer: textAnswer,
      };
      setChanged(false);
      try {
        const saveResponse = await saveAnswer(answer);
        if (saveResponse.success) {
          try {
            const body: SaveFriendsBody = {
              ...values,
              ceremony_id: ceremony.id,
            };
            const saveFriendsResponse = await saveFriends(body);
            if (saveFriendsResponse.success) {
              setQuestionChanged(true);
              await fetchFriends();
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              resetForm();
            } else {
              handleError();
            }
          } catch (error) {
            handleError();
          }
        } else {
          handleError();
        }
      } catch (error) {
        handleError();
      }
    }
  };

  const mappedOptions = question.options?.map((o) => ({
    label: o.option,
    value: o.id,
  }));

  const sendSpeakerInvitation = async () => {
    if (ceremony) {
      try {
        setTitle('Sending Emails');
        const saveResponse = await sendFriendsInvitation(ceremony.id);
        if (saveResponse.success) {
          openSnackBar(
            'Invitations have been sent succesfully.',
            5000,
            'success'
          );
          setTitle('Invitations Sent');
          setTimeout(() => {
            setTitle('Send Invitations');
          }, 3000);
        } else {
          handleError();
        }
      } catch (error) {
        handleError();
      }
    }
  };

  const onRemove = async (memberId: number) => {
    // Todo
    try {
      const removeBody: RemoveMemberBody = {
        member_id: memberId,
      };
      const saveResponse = await removeMember(removeBody);
      if (saveResponse.success) {
        try {
          await fetchFriends();
          openSnackBar('Member removed.', 5000, 'success');
        } catch (error) {
          handleError();
        }
      } else {
        handleError();
      }
    } catch (error) {
      handleError();
    }
  };
  return (
    <div className='col-span-4 md:col-span-6 lg:col-span-12 grid grid-cols-4 md:grid-cols-6 lg:grid-cols-12'>
      <SnackbarComponent
        isActive={isActive}
        message={message}
        type={type}
        handleClose={handleClose}
      />
      <div
        onClick={() => setActiveQuestionId(question.id)}
        className='question-container mb-8 px-4 md:px-11 py-6 border col-span-4 md:col-span-6 lg:col-span-9'
      >
        <div className='mx-2 my-3 font-nanum text-2xl leading-8'>
          {replacePlaceholders(
            question.question,
            couple1?.preferred_name || couple1?.legal_name,
            couple2?.preferred_name || couple2?.legal_name,
            officiant?.preferred_name || officiant?.legal_name,
            currentUser?.preferred_name || currentUser?.legal_name
          )}
          { asterisk ? <span className='ml-1' style={{color: '#995D30'}}>*</span> : null }
        </div>
        <CustomRadioSelect
          options={mappedOptions}
          onChange={handleChange}
          value={optionId}
          name={'test'}
          row={question.options && question.options.length > 2}
          readOnly={readOnly}
        />
        <Formik
          initialValues={initialVals}
          validationSchema={newSpeakerSchema}
          onSubmit={async (values, { resetForm }) => {
            await handleSave(values, resetForm);
          }}
        >
          {({ values, errors, touched }) => (
            <Form>
              {showAdditionOption && (
                <div>
                  <div className='flex flex-col md:flex-row w-full'>
                    <Field
                      name={`legal_name`}
                      placeholder='Name'
                      type='text'
                      className={'w-full md:w-2/5 mt-4 h-fit'}
                      setChanged={() => setChanged(true)}
                      component={CustomFormikInput}
                      readOnly={readOnly}
                      error={touched.legal_name && !!errors.legal_name}
                      helperText={touched.legal_name && errors.legal_name}
                    />
                    <Field
                      name={`email`}
                      placeholder='Email'
                      type='email'
                      className={'w-full md:w-2/5 mt-4 h-fit'}
                      component={CustomFormikInput}
                      setChanged={() => setChanged(true)}
                      readOnly={readOnly}
                      error={touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                    />

                    <button
                      type={'submit'}
                      className={
                        'w-full md:w-1/5 h-14 ml-2 mt-4 p-6 flex justify-center text-center items-center bg-white border border-forest-primary font-inter font-semibold text-forest-primary cursor-pointer'
                      }
                    >
                      Add
                    </button>
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
        {friendsFromDb.map((f) => (
          <div
            className='flex flex-col ml-2 sm:ml-0 sm:flex-row w-full my-2 items-center'
            key={f.id}
          >
            <div className='bg-white h-14 border border-seaFoam-primary mx-2 p-3 w-full sm:w-4/5 flex flex-row items-center'>
              <img
                src={profileIcon}
                alt={'profileIcon'}
                className='h-5 w-min mr-4'
              />
              <h2 className='font-inter font-semibold'>
                {f.legal_name} - {f.email}
              </h2>
            </div>

            <button
              className={
                'w-full sm:w-1/5 h-14 p-6 flex justify-center text-center items-center bg-white border border-forest-primary font-inter font-semibold text-forest-primary cursor-pointer'
              }
              onClick={() => {
                f.id && void onRemove(f.id);
              }}
            >
              Remove
            </button>
          </div>
        ))}
        {friendsFromDb.length > 0 && (
          <Button
            className='bg-forest-primary text-white mt-5 mx-2'
            onClick={() => sendSpeakerInvitation()}
            title={title}
          />
        )}
      </div>
      {showTip && (
        <Tip
          text={replacePlaceholders(
            question.help_short_text,
            couple1?.preferred_name || couple1?.legal_name,
            couple2?.preferred_name || couple2?.legal_name,
            officiant?.preferred_name || officiant?.legal_name,
            currentUser?.preferred_name || currentUser?.legal_name
          )}
          id={1}
          expanded={tipExpanded === 1}
          setExpanded={(id: number) => setTipExpanded(id)}
          link={question.help_detail_text}
          className={'w-72 ml-5 bg-forest-primary'}
        />
      )}
    </div>
  );
};
