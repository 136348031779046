import moment, { Moment } from 'moment';
import { useState } from 'react';

import { OutputData } from '../api/output';
import {
  GetQuestionAnswerStatusResponse,
  getQuestionAnswerStatus,
} from '../api/question';
import { getAPIErrorMessage } from '../helpers/helper';
import { useSnackbar } from '../provider/snackbarProvider';


export type BuilderContextType = {
  generateDraftInitiated: boolean;
  setGenerateDraftInitiated: (value: boolean) => void;
  textGenerated: boolean;
  setTextGenerated: (value: boolean) => void;
  textGenerateComplete: boolean;
  setTextGenerateComplete: (value: boolean) => void;
  versionHistoryModalOpen: boolean;
  setVersionHistoryModalOpen: (value: boolean) => void;
  totalMinutes: number;
  setTotalMinutes: (value: number) => void;
  setSelectedVersionId: (versionId: number) => void;
  getQAStatus: (ceremonyId: string) => Promise<void>;
  aiGeneratedText: string;
  setAiGeneratedText: (value: string) => void;
  aiGeneratedTextRendered: string;
  setAiGeneratedTextRendered: (value: string) => void;
  aiGeneratedTextIndex: number;
  setAiGeneratedTextIndex: (value: number) => void;
  textGenerating: boolean;
  setTextGenerating: (value: boolean) => void;
  selectedVersionId: number;
  versions: OutputData[];
  setVersions: (versions: OutputData[]) => void;
  draftingModalOpen: boolean;
  setDraftingModalOpen: (value: boolean) => void;
  completedModalOpen: boolean;
  setCompletedModalOpen: (value: boolean) => void;
  generationError: boolean;
  setGenerationError: (value: boolean) => void;
  getMomentOfLatestVersion: () => moment.Moment | undefined;
};

//generate custom hook
export const useBuilder = () => {
  const [draftingModalOpen, setDraftingModalOpen] = useState(false);
  const [generationError, setGenerationError] = useState<boolean>(false);
  const [completedModalOpen, setCompletedModalOpen] = useState(false);
  const [generateDraftInitiated, setGenerateDraftInitiated] = useState(false);
  const [aiGeneratedText, setAiGeneratedText] = useState<string>('');
  const [aiGeneratedTextRendered, setAiGeneratedTextRendered] =
    useState<string>('');
  const [aiGeneratedTextIndex, setAiGeneratedTextIndex] = useState<number>(0);
  const [textGenerated, setTextGenerated] = useState<boolean>(false);
  const [textGenerateComplete, setTextGenerateComplete] =
    useState<boolean>(false);
  const [textGenerating, setTextGenerating] = useState<boolean>(false);
  const [versionHistoryModalOpen, setVersionHistoryModalOpen] =
    useState<boolean>(false);
  const [selectedVersionId, setSelectedVersionId] = useState<number>(0);
  const [versions, setVersions] = useState<OutputData[]>([]);
  const [totalMinutes, setTotalMinutes] = useState(0);

  const { openSnackBar } = useSnackbar();

  const getQAStatus = async (ceremonyId: string) => {
    //showLoader();
    try {
      const qaStatusResponse: GetQuestionAnswerStatusResponse =
        await getQuestionAnswerStatus(ceremonyId);
      if (qaStatusResponse.success) {
        //hideLoader();
      } else {
        //hideLoader();
        openSnackBar(qaStatusResponse.message, 'error');
      }
    } catch (err) {
      //hideLoader();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      openSnackBar(getAPIErrorMessage(err as any), 'error');
    }
  };

  const getMomentOfLatestVersion = () => {
    let latestVersion: moment.Moment | undefined = undefined

    versions.forEach((version) => {
      
      if (version.updatedAt) {
        const versionDate = moment(version.updatedAt)

        if(latestVersion == undefined) {
          latestVersion = versionDate
        }
        else if (versionDate.isAfter(latestVersion)) {
          latestVersion = versionDate
        }
      }
      else if (version.createdAt){
        const versionDate = moment(version.createdAt)
        if(latestVersion == undefined) {
          latestVersion = versionDate
        }
        else if (versionDate.isAfter(latestVersion)) {
          latestVersion = versionDate
        }
      }

    })

    return latestVersion 
  };

  return {
    draftingModalOpen,
    setDraftingModalOpen,
    completedModalOpen,
    setCompletedModalOpen,
    generateDraftInitiated,
    setGenerateDraftInitiated,
    aiGeneratedText,
    setAiGeneratedText,
    aiGeneratedTextRendered,
    setAiGeneratedTextRendered,
    aiGeneratedTextIndex,
    setAiGeneratedTextIndex,
    textGenerated,
    setTextGenerated,
    textGenerateComplete,
    setTextGenerateComplete,
    textGenerating,
    setTextGenerating,
    versionHistoryModalOpen,
    setVersionHistoryModalOpen,
    selectedVersionId,
    setSelectedVersionId,
    versions,
    setVersions,
    totalMinutes,
    setTotalMinutes,
    getQAStatus,
    generationError,
    setGenerationError,
    getMomentOfLatestVersion,
  };
};
