import ForestCheckmark from '../../assets/images/svg/forest-checkmark.svg';
import Info from '../../assets/images/svg/info.svg';
import Button from '../../components/v2/Button/Button';
import { Typography } from '../../components/v2/Typography/Typography';

type PayPlanCardProps = {
  planName: string;
  price: string;
  fullPrice?: string;
  billingDetails?: string;
  imgSrc: string;
  features: Feature[];
  featuresDescription?: string;
  mostPopular?: boolean;
  onSelectClick?: () => void;
  currentPlan?: boolean;
  buttonText?: string;
};

type Feature = {
  title: string;
  elevioBenefit?: string;
}

const PayPlanCard = (props: PayPlanCardProps) => {
  const {
    planName,
    price,
    fullPrice,
    billingDetails,
    imgSrc,
    features,
    mostPopular,
    onSelectClick,
    featuresDescription,
    currentPlan = false,
    buttonText = 'Select'
  } = props;
  return (
    <div
      className={`relative p-6 rounded-3xl border ${
        mostPopular ? 'border-2 border-copper-primary' : 'border-forest-primary'
      }  min-w-[340px] max-w-[90vw] max-w-screen`}
    >
      {mostPopular && (
        <div className='w-full flex justify-center'>
          <Typography
            variant='functional-low'
            type='body-200'
            className={`self-center text-white bg-copper-primary absolute top-[-14px] px-3 py-1 rounded-lg`}
          >
            Most Popular
          </Typography>
        </div>
      )}

      <div className='flex flex-row justify-between'>
        <div>
          <Typography type='display-100'>{planName}</Typography>
          <div className='flex flex-row justify-start items-end'>
            <Typography type='display-600'>{price}</Typography>
            {fullPrice && (
              <Typography className='line-through text-forest-300 ml-2'>
                {fullPrice}
              </Typography>
            )}
          </div>
        </div>
        <img src={imgSrc} />
      </div>
      <div className='h-6'>
        {billingDetails && (
          <Typography type='body-400' className='text-gray-700'>{billingDetails}</Typography>
        )}
      </div>
      <div>
        {
          featuresDescription ? (
            <Typography type='body-400' className='max-w-[345px]'>{featuresDescription}</Typography>
          ) : null
        }
        {features.map((feature, index) => (
          <div className={`flex mt-5`} key={index} >
            <img src={ForestCheckmark} alt={'check mark'} className={'pr-5'} />
            <Typography type='body-400'>{feature.title}</Typography>
            {
              feature.elevioBenefit ? (
                <img src={Info} alt={'info'} className={`pl-2 cursor-pointer ${feature.elevioBenefit}`} />
              ) :null
            }
          </div>
        ))}
      </div>
      {
        currentPlan ? (
          <Button className='w-full mt-6' disabled={true}>
              Current Plan
            </Button>
        ) : (
          onSelectClick ? (
            <Button className='w-full mt-6' onClick={onSelectClick}>
              {buttonText}
            </Button>
          ) : null
        )
      }
    </div>
  );
};

export default PayPlanCard;
