import React from 'react';

type ChecklistItemIconProps = {
  iconBackground: string;
  iconSrc: string;
};

const ChecklistItemIcon = (props: ChecklistItemIconProps) => {
  const { iconBackground, iconSrc } = props;
  return (
    <div
      className={`w-12 h-12 flex items-center justify-center rounded-3xl ${iconBackground}`}
    >
      <img alt='checklist-item-icon' src={iconSrc} />
    </div>
  );
};

export default ChecklistItemIcon;
