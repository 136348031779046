/* eslint-disable -- TODO: fix eslint errors */

import { useState, useEffect } from 'react';
import { Pronouncement, getPronouncements } from '../api/pronouncement';

const usePronouncements = () => {
  const [pronouncements, setPronouncements] = useState<Pronouncement[]>();
  useEffect(() => {
    const fetchPronouncements = async () => {
      try {
        const response = await getPronouncements();
        if (response.success) {
          setPronouncements(response.data);
        }
      } catch (error) {}
    };
    fetchPronouncements();
  }, []);

  return {
    pronouncements,
  };
};

export default usePronouncements;
