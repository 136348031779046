/* eslint-disable -- TODO: fix eslint errors */

import { useState } from 'react';
import { getModuleQuestions } from '../api/question';
import { Question } from '../pages/Modules/common/moduleQuestion/ModuleQuestion';
import { Module } from './useActiveModule';

type UseQuestionsProps = {
  ceremonyId?: string | number;
  module?: Module;
};

const useQuestions = (props?: UseQuestionsProps) => {
  const [questions, setQuestions] = useState<Question[]>();
  const [additionalQuestions, setAdditionalQuestions] = useState<Question[]>();
  const [isNewReflectionQuestions, setIsNewReflectionQuestions] =
    useState<boolean>(false);
  const [answeredTogether, setAnsweredTogether] = useState<boolean>(false);

  const fetchQuestions = async (module: Module, ceremonyId: string) => {
    try {
      const response = await getModuleQuestions(module, ceremonyId);
      if (response.success) {
        setQuestions(response.data);
      }
    } catch (error) {}
  };

  const fetchAdditionalQuestions = async (
    module: Module,
    ceremonyId: string
  ) => {
    try {
      const response = await getModuleQuestions(module, ceremonyId);
      if (response.success) {
        setAdditionalQuestions(response.data);
      }
    } catch (error) {}
  };

  const getQuestionByIdentifier = (identifier: string) => {
    let result: Question | null = null;
    if (questions && questions.length > 0) {
      const tempQuestion = questions.find((q) => q.identifier === identifier);
      if (tempQuestion) {
        result = tempQuestion;
      }
    }
    return result;
  };

  const getQuestionByQuestionText = (questionText: string) => {
    let result: Question | null = null;
    if (questions && questions.length > 0) {
      const tempQuestion = questions.find((q) => q.question === questionText);
      if (tempQuestion) {
        result = tempQuestion;
      }
    }
    return result;
  };

  const fetchIsNewReflectionQuestions = async (ceremonyId: string) => {
    try {
      const response = await getModuleQuestions(
        Module.OfficiantRemarks,
        ceremonyId
      );
      if (response.success) {
        const question = response.data?.find(
          (question) => question.identifier === 'together_or_individual'
        );

        if (question) {
          setIsNewReflectionQuestions(true);
          const wantedOptionId = question.options?.find(
            (o) => o.option === 'Answer Together'
          )?.id;
          if (
            wantedOptionId &&
            question.answers &&
            question.answers.find(
              (a) => a.option_id === wantedOptionId.toString()
            )
          ) {
            setAnsweredTogether(true);
          }
        } else {
          setIsNewReflectionQuestions(false);
        }
      }
    } catch (error) {}
  };

  return {
    questions,
    additionalQuestions,
    fetchQuestions,
    fetchAdditionalQuestions,
    getQuestionByIdentifier,
    fetchIsNewReflectionQuestions,
    isNewReflectionQuestions,
    answeredTogether,
    getQuestionByQuestionText,
  };
};

/** Useful to avoid many imports */
useQuestions.Modules = Module;

export default useQuestions;
