import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PaymentMethod, OrderType, PayOrderBody } from '../../api/paywall';
import puzzleSvg from '../../assets/images/image/puzzle.svg';
import useSubscriptionPlans from '../../hooks/useSubscriptionPlans';
import { usePlanner } from '../../provider/plannerProvider';

import PayPlanCard from './PayPlanCard';

const PaymentModalPayPerCeremonyContent = () => {
  const { payPerCeremonyData } = useSubscriptionPlans();

  const {
    numberOfCeremoniesToPayFor,
    setShowAddWedModal,
    setShowPaymentModal,
    setAddClientsClicked,
    handlePayPerCeremony,
  } = usePlanner();

  let billingDetails = 'one time fee';

  if (numberOfCeremoniesToPayFor > 1) {
    billingDetails += `, $${
      numberOfCeremoniesToPayFor * payPerCeremonyData.pricePerCeremony
    } total for ${numberOfCeremoniesToPayFor} ceremonies`;
  }

  return (
    <>
      {/* pay per ceremonies */}
      <div className='flex flex-col md:flex-row justify-center gap-6'>
        <PayPlanCard
          planName={'Full access client'}
          price={payPerCeremonyData.pricePerCeremony.toString()}
          fullPrice={payPerCeremonyData.fullPricePerCeremony.toString()}
          billingDetails={billingDetails}
          imgSrc={puzzleSvg}
          featuresDescription="Grants full access for you and your clients to Provenance's tools for crafting personalized ceremonies, vows, and toasts:"
          features={[
            {
              title: 'Ceremony Builder',
              elevioBenefit: 'ceremony-benefit',
            },
            {
              title: 'Vow Builder',
              elevioBenefit: 'vow-benefit',
            },
            {
              title: 'Toast Builder',
              elevioBenefit: 'toast-benefit',
            },
            {
              title: '24/7 Support from Provenance Team',
              elevioBenefit: '',
            },
            {
              title: '$20 Wedding Planner Discount',
              elevioBenefit: '',
            },
          ]}
          buttonText={
            numberOfCeremoniesToPayFor > 0 ? 'Select' : 'Add Client(s)'
          }
          onSelectClick={() => {
            if (numberOfCeremoniesToPayFor > 0) {
              void handlePayPerCeremony();
            } else {
              setAddClientsClicked(true);
              setShowAddWedModal(true);
              setShowPaymentModal(false);
            }
          }}
        />
      </div>
    </>
  );
};

export default PaymentModalPayPerCeremonyContent;
