/* eslint-disable -- TODO: fix eslint errors */

import { useEffect, useState } from 'react';
import { Ceremony, Member } from '../api/ceremony';
import {
  FriendWithQuestions,
  getFriendsAndFamilyAnswers,
} from '../api/friends';

const useFriends = () => {
  const [friendsWithQuestions, setFriends] = useState<FriendWithQuestions[]>(
    []
  );

  const fetchFriendsWithQuestions = async (ceremonyId: string) => {
    if (ceremonyId) {
      try {
        const response = await getFriendsAndFamilyAnswers(parseInt(ceremonyId));
        if (response.success) {
          setFriends(response.data);
        }
      } catch (error) {}
    }
  };

  return {
    friendsWithQuestions,
    fetchFriendsWithQuestions,
  };
};

export default useFriends;
