import { DraftingModal } from '../../components/v2/DraftingModal/DraftingModal';
import { useVowBuilder } from '../../provider/vowBuilderProvider';

export const DraftingVowsModal = () => {
  const {
    textGenerating,
    textGenerateComplete,
    draftingModalOpen,
    setDraftingModalOpen,
    aiGeneratedTextIndex,
    generationError
  } = useVowBuilder();
  return (
    <DraftingModal
      generationError={generationError}
      modalOpen={draftingModalOpen}
      setModalOpen={setDraftingModalOpen}
      textGenerateComplete={textGenerateComplete}
      textGenerating={textGenerating}
      aiGeneratedTextIndex={aiGeneratedTextIndex}
      titleDrafting={'Drafting your vows...'}
      descriptionDrafting={
        'We’re using your questionnaire responses to craft a jumping off point for your vows. Once complete, you’ll be able to view, edit and/or download your vows freely.'
      }
      titlePopulating={'Finishing up...'}
      descriptionPopulating={
        'We’re using your questionnaire responses to craft a jumping off point for your vows. Once complete, you’ll be able to view, edit and/or download your vows freely.'
      }
      titleComplete={'Your vows are ready to view!'}
      descriptionComplete={
        'Freely view, edit, and/or download your completed vows. If they don’t sound quite right, simply regenerate them.'
      }
      buttonTextComplete={'View My Vows'}
    />
  );
};
