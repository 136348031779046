
import { SideMenu } from '../SideMenu/SideMenu';
import { SideMenuItem } from '../SideMenu/SideMenuItem';
import ActiveWeddingsCounter from '../v2/ActiveWeddingsCounter/ActiveWeddingsCounter';
import { Separator } from '../v2/Separator/Separator';

import ballotCheckSelectedSvg from './images/ballot-check-selected.svg';
import ballotCheckSvg from './images/ballot-check.svg';
import booksSelectedSvg from './images/books-selected.svg';
import booksSvg from './images/books.svg';
import qrCodeSvg from './images/qrcode.svg';
import tableRowsSelectedSvg from './images/table-rows-selected.svg';
import tableRowsSvg from './images/table-rows.svg';

export const ViewWeddingMenuContent = (props: { expanded: boolean }) => {
  const { expanded } = props;

  return (
    <div className='h-full flex flex-col'>
      {
        expanded && <ActiveWeddingsCounter />
      }
      <Separator
        className='border-forest-100 mb-6 mt-5 w-full'
        variant='horizontal'
      />
      <SideMenu expanded={expanded} title='TOOLS'>
        <SideMenuItem
          renderIcon={() => <img src={ballotCheckSvg} />}
          renderSelectedIcon={() => (
            <img src={ballotCheckSelectedSvg} />
          )}
          text='Onboarding Checklist'
          link={`/ceremony/planner/dashboard`}
          selected={
            location.pathname.indexOf(
              `/ceremony/planner/dashboard`
            ) >= 0
          }
          expanded={expanded}
        />
        <SideMenuItem
          renderIcon={() => <img src={tableRowsSvg} />}
          renderSelectedIcon={() => (
            <img src={tableRowsSelectedSvg} />
          )}
          text='Client List'
          link={`/ceremony/planner/client-list`}
          selected={
            location.pathname.indexOf(
              `/ceremony/planner/client-list`
            ) >= 0
          }
          expanded={expanded}
        />
        <SideMenuItem
          renderIcon={() => <img src={booksSvg} />}
          renderSelectedIcon={() => (
            <img src={booksSelectedSvg} />
          )}
          text='Client Resources'
          link={`/ceremony/planner/client-resources`}
          selected={
            location.pathname.indexOf(
              `/ceremony/planner/client-resources`
            ) >= 0
          }
          expanded={expanded}
        />
        <SideMenuItem
          renderIcon={() => <img src={qrCodeSvg} />}
          renderSelectedIcon={() => (
            <img src={qrCodeSvg} />
          )}
          text='Client Invite Link'
          link={`/wp-add-ceremony`}
          selected={
            location.pathname.indexOf(
              `/wp-add-ceremony`
            ) >= 0
          }
          expanded={expanded}
        />
      </SideMenu>
    </div>
  );
};
