//create a hook for fetching giftcards
import { useState } from 'react';

import { Giftcard, getGiftcard, saveGiftcard } from '../api/giftcards';

const useGiftcards = () => {
  const [giftcard, setGiftcard] = useState<Giftcard>();
  const fetchGiftcard = async () => {
    setGiftcard(undefined);
    try {
      const response = await getGiftcard();
      if (response.success) {
        setGiftcard(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveNewGiftcard = async (giftcard: Giftcard) => {
    try {
      await saveGiftcard(giftcard);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    giftcard: giftcard,
    fetchGiftcard,
    saveNewGiftcard,
  };
};

export default useGiftcards;
