/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState } from 'react';

import { User } from '../api/auth';
import { Ceremony as CeremonyExtended } from '../api/ceremony';
import {
  NewPaymentBody,
  OrderType,
  PayOrderBody,
  PayOrderResponse,
  PayOrdersResponse,
  PaymentMethod,
  newMultipleOrdersPaymentSession,
  placeNewOrders,
} from '../api/paywall';
import { WeddingPlannerStatus, activateCeremony } from '../api/weddingPlanner';
import { getAPIErrorMessage } from '../helpers/helper';
import useUser from '../hooks/useUser';
import useWeddingPlannerStatus from '../hooks/useWeddingPlannerStatus';
import { Ceremony } from '../pages/Ceremony/PlannerDashboard/AddCeremonyModal';
import { useDashboard } from '../provider/dashboardProvider';

import { useSnackbar } from './snackbarProvider';

type PlannerContextType = {
  ceremonies?: Ceremony[];
  setCeremonies: (ceremonies: Ceremony[]) => void;
  showSummaryModal: boolean;
  setShowSummaryModal: (value: boolean) => void;
  showPaymentModal: boolean;
  setShowPaymentModal: (value: boolean) => void;
  viewWeddingSection: string;
  setViewWeddingSection: (value: string) => void;
  showAddWedModal: boolean;
  setShowAddWedModal: (value: boolean) => void;
  showVideoModal: boolean;
  setShowVideoModal: (value: boolean) => void;
  showDeactivateModal: boolean;
  setShowDeactivateModal: (value: boolean) => void;
  showReactivateModal: boolean;
  setShowReactivateModal: (value: boolean) => void;
  weddingPlannerStatus?: WeddingPlannerStatus;
  fetchWeddingPlannerStatus: () => void;
  setNumberOfCeremoniesBeingAdded: (value: number) => void;
  numberOfCeremoniesBeingAdded: number;
  numberOfCeremoniesToPayFor: number;
  setNumberOfCeremoniesToPayFor: React.Dispatch<React.SetStateAction<number>>;
  ceremonyIdsOfCreatedCeremonies: string[];
  setCeremonyIdsOfCreatedCeremonies: (value: string[]) => void;
  freeWeddingAdded: boolean;
  setFreeWeddingAdded: (value: boolean) => void;
  addClientsClicked: boolean;
  setAddClientsClicked: (value: boolean) => void;
  handlePayPerCeremony: (ceremonyIds?: string[]) => Promise<void>;
  reactivateCeremony: () => void;
};

export const isWeddingPlannerInCeremony = (
  user: User,
  ceremony: CeremonyExtended
) => {
  let result = false;
  if (
    user &&
    ceremony.members &&
    ceremony.members.some(
      (member) =>
        member.user_id === user.id && member.member_type === 'wedding_planner'
    )
  ) {
    result = true;
  }
  return result;
};

export const PlannerContext = React.createContext<PlannerContextType>({
  ceremonies: [],
  setCeremonies: () => {},
  showSummaryModal: false,
  setShowSummaryModal: () => {},
  showPaymentModal: false,
  setShowPaymentModal: () => {},
  showVideoModal: false,
  setShowVideoModal: () => {},
  viewWeddingSection: 'Upcoming Weddings',
  setViewWeddingSection: () => {},
  showAddWedModal: false,
  setShowAddWedModal: () => {},
  showDeactivateModal: false,
  setShowDeactivateModal: () => {},
  showReactivateModal: false,
  setShowReactivateModal: () => {},
  weddingPlannerStatus: undefined,
  fetchWeddingPlannerStatus: () => {},
  setNumberOfCeremoniesBeingAdded: () => {},
  numberOfCeremoniesBeingAdded: 0,
  numberOfCeremoniesToPayFor: 0,
  setNumberOfCeremoniesToPayFor: () => {},
  ceremonyIdsOfCreatedCeremonies: [],
  setCeremonyIdsOfCreatedCeremonies: () => {},
  freeWeddingAdded: false,
  setFreeWeddingAdded: () => {},
  addClientsClicked: false,
  setAddClientsClicked: () => {},
  handlePayPerCeremony: async () => {},
  reactivateCeremony: () => {},
});

export const PlannerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [ceremonies, setCeremonies] = useState<Ceremony[]>();
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [numberOfCeremoniesBeingAdded, setNumberOfCeremoniesBeingAdded] =
    useState<number>(0);

  const [viewWeddingSection, setViewWeddingSection] =
    useState('Upcoming Weddings');
  const [showAddWedModal, setShowAddWedModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showReactivateModal, setShowReactivateModal] = useState(false);

  const [numberOfCeremoniesToPayFor, setNumberOfCeremoniesToPayFor] =
    useState<number>(0);
  const [ceremonyIdsOfCreatedCeremonies, setCeremonyIdsOfCreatedCeremonies] =
    useState<string[]>([]);
  const [freeWeddingAdded, setFreeWeddingAdded] = useState(false);
  const [addClientsClicked, setAddClientsClicked] = useState(false);

  const { weddingPlannerStatus, fetchWeddingPlannerStatus } =
    useWeddingPlannerStatus();

  const { openSnackBar } = useSnackbar();
  const { fetchCeremony, ceremony } = useDashboard();

  const payPerCeremonyData = {
    pricePerCeremony: 69.99,
    fullPricePerCeremony: 89.99,
  };

  const handlePayPerCeremony = async (ceremonyIds?: string[]) => {
    const idsArray = ceremonyIds ? ceremonyIds : ceremonyIdsOfCreatedCeremonies;
    const orders = idsArray.map((ceremonyId) => {
      const tempOrder: PayOrderBody = {
        ceremony_id: ceremonyId,
        payment_method: PaymentMethod.card,
        value: payPerCeremonyData.pricePerCeremony,
        type: OrderType.ceremony_designer,
        payment_type: 'wp_pay_per_ceremony',
        currency: 'USD',
      };
      return tempOrder;
    });
    await placeOrders(orders);
    setNumberOfCeremoniesToPayFor(0);
    setCeremonyIdsOfCreatedCeremonies([]);
  };

  const placeOrders = async (orders: PayOrderBody[]) => {
    //showLoader();
    try {
      const orderResponse: PayOrdersResponse = await placeNewOrders({
        orders: orders,
      });
      if (orderResponse.success) {
        await createPaymentSession(orderResponse.data.refs);
      } else {
        openSnackBar(orderResponse.message, 'error');
      }
    } catch (err) {
      openSnackBar(getAPIErrorMessage(err), 'error');
    } finally {
      //hideLoader();
    }
  };

  const createPaymentSession = async (ref: string) => {
    try {
      const payBody: NewPaymentBody = {
        ref: ref,
        currency: 'USD',
      };
      const sessionResponse: PayOrderResponse =
        await newMultipleOrdersPaymentSession(payBody);
      if (sessionResponse.success) {
        window.location.href = sessionResponse.message;
        return;
      } else {
        openSnackBar(sessionResponse.message);
      }
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      openSnackBar(getAPIErrorMessage(err as any));
    } finally {
      //hideLoader();
    }
  };

  const reactivateCeremony = async () => {
    if (!ceremony) return;

    try {
      const result = await activateCeremony(ceremony.id);
      if (result.success) {
        openSnackBar('Ceremony reactivated successfully');
        fetchCeremony(ceremony.id.toString());
      } else {
        openSnackBar('Please upgrade your subscription', 'error');
        //setShowReactivateModal(true);
      }
    } catch (err: any) {
      if (err?.message === 'No active subscription') {
        openSnackBar(
          'No available slots for another subscription, please upgrade',
          'error'
        );
        return;
      }
      console.log(err);
      openSnackBar('Something went wrong in reactivating ceremony', 'error');
    }
  };

  const value = {
    ceremonies,
    setCeremonies,
    showSummaryModal,
    setShowSummaryModal,
    showPaymentModal,
    setShowPaymentModal,
    viewWeddingSection,
    setViewWeddingSection,
    showAddWedModal,
    setShowAddWedModal,
    showDeactivateModal,
    setShowDeactivateModal,
    showReactivateModal,
    setShowReactivateModal,
    weddingPlannerStatus,
    fetchWeddingPlannerStatus,
    setNumberOfCeremoniesBeingAdded,
    numberOfCeremoniesBeingAdded,
    numberOfCeremoniesToPayFor,
    setNumberOfCeremoniesToPayFor,
    ceremonyIdsOfCreatedCeremonies,
    setCeremonyIdsOfCreatedCeremonies,
    freeWeddingAdded,
    setFreeWeddingAdded,
    addClientsClicked,
    setAddClientsClicked,
    handlePayPerCeremony,
    reactivateCeremony,
    showVideoModal,
    setShowVideoModal,
  };
  // @ts-ignore
  return (
    <PlannerContext.Provider value={value}>{children}</PlannerContext.Provider>
  );
};

export const usePlanner = () => {
  return useContext(PlannerContext);
};
