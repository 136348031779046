import { User } from './auth';
import { Culture } from './culture';
import { _request } from './request';
import { RitualType } from './ritualType';
import { Tag } from './tags';

export type Ritual = {
  id: number;
  name: string;
  text: string;
  author: string;
  time?: number;
  image_url?: string;
  ritual_types: RitualType[];
  cultures: Culture[];
  tags?: Tag[];
  createdBy?: User;
  createdAt: Date;
  popularity_count: number;
};

export type RitualBody = {
  name: string;
  text: string;
  time?: number;
  image_url?: string;
  created_for: number;
  ritual_types?: string[];
  cultures?: string[];
  tags?: string[];
};

export type GetRitualsResponse = {
  success: boolean;
  message: string;
  data: Ritual[];
};

export type SaveRitualsResponse = {
  success: boolean;
  message: string;
  data: Ritual;
};

export const getRituals = async () => {
  const responseBody = await _request<GetRitualsResponse>({
    url: `rituals`,
    method: 'GET',
  });
  return responseBody;
};

export const saveRitual = async (ritual: RitualBody) => {
  const responseBody = await _request<SaveRitualsResponse>({
    url: `rituals/save-ritual`,
    method: 'POST',
    body: { ritual },
  });
  return responseBody;
};
