import { Dialog, DialogContent } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ModulePageWrapperTransition } from './Transition';
import './styles.scss';

type ModulePageWrapperProps = {
  open?: boolean;
  onClose?: () => Promise<void> | void;
};

export const ModulePageWrapper = ({
  children,
}: React.PropsWithChildren<ModulePageWrapperProps>) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add('remove-elevio');
    return () => document.body.classList.remove('remove-elevio');
  }, []);

  return (
    <Dialog
      className='ModulePageWrapper ModulePageWrapper-root'
      fullWidth
      maxWidth={false}
      onClose={() => navigate(-1)}
      open
      TransitionComponent={ModulePageWrapperTransition}
    >
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
