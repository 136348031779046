import React from 'react';

import Button from '../../../../components/v2/Button/Button';

type SkipTaskButtonProps = {
  onClick: () => Promise<void> | void;
};

const SkipTaskButton = (props: SkipTaskButtonProps) => {
  const { onClick } = props;
  return (
    <Button
      eventClick={(event) => {
        event.stopPropagation();
        void onClick();
      }}
      variant='text'
    >
      Skip Task
    </Button>
  );
};

export default SkipTaskButton;
