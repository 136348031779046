import { GenericResponse } from './ceremony';
import { _request } from './request';

export enum WaitlistModule {
  PreMaritalCounselling = 'pre-marital-counselling',
  LegalServiceGuidance = 'legal-service-guidance',
}

export type WaitListResponse = {
  enrollment?: WaitListEnrollment;
  questions: WaitListQuestions[];
};

export type WaitListEnrollmentResponse = {
  success: boolean;
  message: string;
  data: WaitListEnrollment[];
};

export type WaitListEnrollment = {
  id: number;
  module: WaitlistModule;
  ceremony_id: number;
  member_id: number;
  user_id: number;
  status: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type WaitListQuestions = {
  id: number;
  question: string;
  order: number;
  module: WaitlistModule;
  type: 'text' | 'radio' | 'select';
  has_textfield?: boolean;
  status: boolean;
  options: WaitListQuestionsOptions[];
  answer?: WaitListQuestionsAnswers;
};

export type WaitListQuestionsOptions = {
  id: number;
  option: string;
  question_id: number;
};

export type WaitListQuestionsAnswers = {
  id: number;
  module: WaitlistModule;
  ceremony_id: number;
  question_id: number;
  option_id?: string;
  option_answer?: string;
  text_answer?: string;
};

export type GetWaitlistQuestionAnswersResponse = {
  success: boolean;
  message: string;
  data: WaitListResponse;
};

export type SaveAnswerResponse = {
  success: boolean;
  message: string;
};

export type SaveWaitlistQARequest = {
  data: SaveWaitlistQABody[];
} & JoinWaitlistBody;

export type SaveWaitlistQABody = {
  qid: string; //Question Id
  oid: string; //Option Id
  textans: string; // Text Answer
};

export type JoinWaitlistBody = {
  cid: string;
  mid: string;
};

export const getAllWaitList = async () => {
  const responseBody = await _request<WaitListEnrollmentResponse>({
    url: `waitlist/get-all-waitlist`,
    method: 'GET',
  });
  return responseBody;
};

export const getWaitlistQuestionAnswers = async (
  waitListModule: WaitlistModule
) => {
  const responseBody = await _request<GetWaitlistQuestionAnswersResponse>({
    url: `waitlist/get-module/${waitListModule}`,
    method: 'GET',
  });
  return responseBody;
};

export const joinWaitlistByModule = async (
  waitListModule: WaitlistModule,
  body: JoinWaitlistBody
) => {
  const responseBody = await _request<GenericResponse>({
    url: `waitlist/join-waitlist/${waitListModule}`,
    method: 'POST',
    body,
  });
  return responseBody;
};

export const saveWaitlistQA = async (
  waitListModule: WaitlistModule,
  body: SaveWaitlistQARequest
) => {
  const responseBody = await _request<GetWaitlistQuestionAnswersResponse>({
    url: `waitlist/qa-waitlist/${waitListModule}`,
    method: 'POST',
    body,
  });
  return responseBody;
};
