import { DatePicker } from '@mui/x-date-pickers';
import { useFormikContext } from 'formik';
import moment from 'moment';

import renderText from '../../../helpers/renderText';
import { Checkbox } from '../../v2/Checkbox';
import { TextField } from '../../v2/TextField';

type Props = {
  namePrefix?: string;
  index?: number;
};

export const WeddingDateField = (props: Props) => {
  const {
    values,
    setFieldValue,
    setTouched,
    handleBlur,
    errors,
    touched,
    setFieldError,
  } = useFormikContext<any>();

  const { namePrefix, index } = props;

  let dateName = 'weddingDate';
  let unknownDateName = 'weddingDateUnknown';

  if (namePrefix !== undefined) {
    dateName = namePrefix + '.weddingDate';
    unknownDateName = namePrefix + '.weddingDateUnknown';
  }

  const errorMessage = touched.weddingDate
    ? (errors.weddingDate as string)
    : '';
  const isWeddingDateError = touched.weddingDate && !!errors.weddingDate;

  const getDatePickerValue = () => {
    if (
      index !== undefined &&
      dateName.indexOf('ceremonies.' + index.toString()) != -1
    ) {
      const dateValue = values.ceremonies[index].weddingDate;
      return dateValue ? moment.utc(Date.parse(dateValue)) : null;
    }

    return values.weddingDate
      ? moment.utc(Date.parse(values.weddingDate))
      : null;
  };

  const isWeddingDateUnknown: () => boolean = () => {
    if (
      index !== undefined &&
      unknownDateName.indexOf('ceremonies.' + index.toString()) != -1
    ) {
      return values.ceremonies[index].weddingDateUnknown ? true : false;
    }
    return values.weddingDateUnknown ? true : false;
  };
  const labelText = renderText('not_sure_yet');

  return (
    <div className='flex flex-col gap-3'>
      <DatePicker
        value={getDatePickerValue()}
        minDate={moment.utc(Date.now())}
        inputFormat={'MM/DD/YYYY'}
        onChange={(value) => {
          setFieldValue(dateName, value?.format('YYYY-MM-DD'));
        }}
        disabled={isWeddingDateUnknown()}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            onBlur={() => handleBlur(dateName)}
            helperText={errorMessage}
            error={isWeddingDateError}
            label='Wedding Date'
            labelVariant='outside'
            disabled={!!isWeddingDateUnknown()}
            onClick={() => setFieldValue(unknownDateName, false)}
          />
        )}
      />
        <Checkbox
          label={labelText}
          checked={!!isWeddingDateUnknown()}
          onChange={(_, checked) => {
            setFieldValue(unknownDateName, checked);
            if (checked) {
              setFieldValue(dateName, '');
            }
          }}
        />
      

    
    </div>
  );
};
