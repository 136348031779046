import './HearAboutModalNew.scss';

import { useEffect, useState } from 'react';

import { HearABoutOption, getHearAboutOptions, submitHearAboutResponse } from '../../../api/hearAbout';
import { useAuth } from '../../../provider/authProvider';
import CustomTextArea from '../../forms/CustomTextArea';
import Button from '../Button/Button';
import { Modal } from '../Modal/Modal';
import { Typography } from '../Typography/Typography';


type HearAboutModalProps = {
  onCancelClick: () => void;
  modalOpen: boolean;
};


const HearAboutModal = (
  props: HearAboutModalProps
) => {
  const {
    onCancelClick,
    modalOpen,
  } = props;

  const [options, setOptions] = useState<HearABoutOption[]>([]);
  const [optionSelected, setOptionSelected] = useState<number | undefined>(undefined);
  const [comment, setComment] = useState<string>('');
  const { user } = useAuth()
  const [alreadySubmitted, setAlreadySubmitted] = useState<boolean>(false);

  async function f() {
    const newOptions = await getHearAboutOptions();
    if (newOptions.data){
        setOptions(newOptions.data);
    }
  }

  useEffect(() => {
    if(modalOpen) {
        f();
    }
  }, [modalOpen]);

  useEffect(() => {
    if (user){
      user.custom_fields.forEach((field) => {
        if (field.key == "hear_about_submitted"){
          setAlreadySubmitted( true );
        }
      })
    }
  }, [user])

  const submitResponse = () => {
    submitHearAboutResponse({option_id: optionSelected, comment})
    onCancelClick()
  }

  return (
    <Modal
      isOpen={modalOpen && options.length > 0 && !alreadySubmitted}
      onClose={() => onCancelClick()}
      showCloseButton={true}
      title={''}
    >
      <div className='self-center lg:max-w-md flex flex-col justify-center text-center '>
      
        <div className="text-copper-primary uppercase text-sm mb-2">
            Before you continue...
        </div>
        
        <Typography type='display-100'>How did you hear about Provenance?</Typography>
        
        <div>
            {
                options.map((option) => {
                    return (
                      <div key={option.id}>
                        <div 
                        className={'option' + (optionSelected === option.id ? ' selected' : '')}
                        onClick={() => {
                          setOptionSelected(option.id)
                          setComment('')
                        }}
                        >
                          <Typography type='body-200' variant='functional-low' className='p-2'>
                              {option.name}
                          </Typography>
                        </div >
                        {option.allowComment ? ( 
                          <div className='mt-2 conditional-rendering'>
                            <CustomTextArea 
                            value={comment} 
                            onChange={(e) =>setComment(e.currentTarget.value)}
                            placeholder='Please add a comment'
                            customSize={200}
                            >
                            </CustomTextArea>
                          </div>
                        ) : null}
                      </div>
                    )
                })
            }

        </div>

        <div className={`flex flex-col-reverse md:flex-row justify-center mt-8`} >
            <Button
                className='mt-2 md:mt-0 md:mr-2 md:w-auto w-full'
                onClick={submitResponse}
                variant='secondary'
            >
                Skip
            </Button>
            <Button 
                className=' md:w-auto w-full' 
                onClick={submitResponse}
                disabled={!optionSelected}
            >
                Submit
            </Button>
        </div>
        
      </div>
    </Modal>
  );
};

export default HearAboutModal;
