import { _request } from './request';

export type verificationCodeResponse = {
  success: boolean;
  message: string;
};

export const sendVerificationCode = async () => {
  const responseBody = await _request<verificationCodeResponse>({
    url: `verificationCodes/sendEmail`,
    method: 'GET',
  });
  return responseBody;
};

export const verifyCode = async (code: string) => {
  const responseBody = await _request<verificationCodeResponse>({
    url: `verificationCodes/verify`,
    method: 'POST',
    body: { code },
  });
  return responseBody;
};
