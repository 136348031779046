import classNames from "classnames";

import circleExclamationMarygold from "../../../../assets/images/svg/circle-exclamation-marygold.svg";
import { Typography } from "../../../../components/v2/Typography/Typography";


export type QuestionBannerProps = {
    type: string;
    text: string | JSX.Element;
    className?: string;
}

export const QuestionBanner = (props: QuestionBannerProps) => {
    const { type, text, className } = props;

    return <div className={classNames("flex p-6 rounded-3xl bg-marigold-100 items-start gap-4", className)}>
        <img src={circleExclamationMarygold} alt="exclamation" height={24} width={24} />
        <Typography 
            type='body-400' 
            variant='functional-low'
            className="text-marigold-700"
        >
            {text}
        </Typography>
    </div>
}