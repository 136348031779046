type GuestDashboardWarningProps = {
  message: string;
  buttonLabel?: string;
  onClick?: () => Promise<void> | void;
};

export const GuestDashboardWarning = ({
  message,
  onClick,
  buttonLabel,
}: GuestDashboardWarningProps) => (
  <div className='flex justify-between bg-copper-primary text-white py-5 px-8'>
    <span className='font-nanum text-base sm:text-2xl self-start'>{message}</span>
    <button
      onClick={onClick}
      className='font-inter text-[16px] cursor-pointer uppercase pt-1'
    >
      {buttonLabel || 'Try Now'}
    </button>
  </div>
);
