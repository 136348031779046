import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import * as React from 'react';

import { Typography } from '../Typography/Typography';

import './Switcher.scss'

export type SwitcherProps = {
    labelLeft?: string;
    labelRight?: string;
    text?: string;
    className?: string;
    onChange: (checked: boolean) => void;
}

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 40,
    height: 24,
    padding: 0,
    border: '1px solid #354740',
    borderRadius: 24 / 2,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 3,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#354740',
          opacity: 1,
          border: 1,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: '#fff'
        }
      },
      /* '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#000',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: 
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      }, */
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 16,
      height: 16,
      backgroundColor: '#354740'
    },
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 0,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
}));

const Switcher = (props: SwitcherProps) => {
    const { onChange, text, labelLeft , labelRight, className} = props;
    
    return (
    <div className={classNames(
      'flex justify-between items-center flex-wrap CustomSwitcher',
      className,
    )}>
        {text ? (
            <Typography
                type='body-400'
                variant='functional-low'
            >
                {text}
            </Typography>
        ): null
        }

        <div className='flex items-center flex-wrap '>
            {
                labelLeft && <Typography
                type='body-400'
                variant='functional-low'
            >
                {labelLeft}
            </Typography>
            }
        
            <FormControlLabel
                control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                label={false}
                onChange={(e, checked) => onChange ? onChange(checked) : null}
            />

            {
                labelRight && <Typography
                type='body-400'
                variant='functional-low'
            >
                {labelRight}
            </Typography>
            }
        
        </div>
    </div>


    )
}

export default Switcher