import ButtonPill from "../../../../components/v2/ButtonPill";
import { DropdownMultiselect } from "../../../../components/v2/Dropdown/DropdownMultiselect";
import { useWindowSize } from "../../../../hooks/useWindowSize";

import './ClientsListFilters.scss';

export type ClientsListFiltersProps = {
  filterOptions: {
    text: string;
  }[];
  selectedFilter: string;
  setSelectedFilter: (filter: string) => void;
}

export const ClientsListFilters = (props: ClientsListFiltersProps) => {

  const { isMobile } = useWindowSize()
  const { filterOptions, selectedFilter, setSelectedFilter } = props;

  return (
    <div className='ClienListContainer'>
        {
            isMobile ? (
              <>
                <DropdownMultiselect
                  options={filterOptions.map((filter) => ({ label: filter.text, value: filter.text }))}
                  values={[selectedFilter]}
                  handleChange={(event) => setSelectedFilter(event.target.value as string)}
                  multiselect={false}
                  buttonPillAppearance={true}
                />
              </>
            ) : (
                <div className='ClientListFilters'>
                    {filterOptions.map((filter, index) => (
                        <ButtonPill
                          key={index}
                          text={filter.text}
                          selected={selectedFilter === filter.text}
                          onClick={() => setSelectedFilter(filter.text)}
                          size='xs'
                          hideCheckIcon={true}
                        />
                    ))}
                </div>
            )
        }
    </div>
  )
};