/* eslint-disable @typescript-eslint/no-explicit-any */
import { getCookie } from '../helpers/helper';

import { Ceremony, Member, MemberType } from './ceremony';
import { _request } from './request';

export type SignUpRequestBody = {
  full_name?: string;
  email: string;
  password: string;
  subscribe_email: boolean;
  terms: boolean;
  referral_sub_id?: string;
  referral_id?: string;
  source?: string | null;
  member_type?: MemberType;
  desired_tools?: string[];
  country_code?: string;
};

export type SignInUpResponse = {
  success: boolean;
  message: string;
  token: string;
};

export type VerifyInviteLinkResponse = {
  success: boolean;
  message: string;
  hasRegistered: boolean;
};

export type SignInBody = {
  email: string;
  password: string;
};

export type SignUpSchema = {
  terms: boolean;
  subscribe_email: boolean;
  full_name?: string;
  email: string;
  password: string;
};

// e = email address (encrypted)
// cid = Ceremony Id for which the user is invited to review
// r = Role for with the user is invited e.g. Couple=1, Officiant=2
export type VerifyInviteLinkBody = {
  e: string;
  cid: string;
  r: number;
};

export type VerifyGiftcardLinkBody = {
  e: string;
};

export type VerifyTokenBody = {
  verification_code: string;
};

export type VerifyTokenResponse = {
  success: boolean;
  message: string;
};

export type SetLastActiveResponse = {
  success: boolean;
  message: string;
};

export type ResetPasswordBody = {
  verification_code?: string;
  password: string;
  confirmPassword: string;
};

export type ResetPasswordResponse = {
  success: boolean;
  message: string;
};

export type ForgotPasswordBody = {
  email: string;
};

export type ForgotPasswordResponse = {
  success: boolean;
  message: string;
};

export interface UserRole {
  user_id: number;
  role_id: number;
}

export interface Role {
  id: number;
  name: string;
  User_Role: UserRole;
}

export interface CustomField {
  key: string;
  value: string;
}

export type User = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  referral_id?: string;
  referral_sub_id?: string;
  active: boolean;
  member_type?: MemberType;
  is_email_verified: boolean;
  createdAt: Date;
  updatedAt: Date;
  onboarding_completed?: boolean;
  deletedAt?: Date;
  roles: Role[];
  hash: string;
  custom_fields: CustomField[];
  isWeddingPlannerWithActiveCeremonies?: boolean;
  hasActiveWeddingPlannerSubscription?: boolean;
  activeCeremoniesCount?: number;
  isDeactivatable?: boolean;
  ceremonies?: Ceremony[];
  country_code?: string;
};

export type MeResponse = {
  success: boolean;
  message: string;
  user: User;
};

// type ReferralRequestBody = {
//   email: string;
//   upsert: boolean;
//   uuid: string;
// };
type CreateReferralRequestBody = {
  email: string;
  upsert: boolean;
  uuid: string;
  referrer?: string;
  host?: string;
};

type ReferralResponseBody = {
  data: ReferralResponse;
  response: string;
};

type ReferralResponse = {
  id: string;
  email: string;
  code: string;
};

export type GenerateAuthTokenRequestBody = {
  redeemerApp: RedeemerApps;
};

export type GenerateReflectionsApiKeyRequestBody = {
  ceremonyId: string | number;
  redeemerApp: RedeemerApps;
};

export enum RedeemerApps {
  CEREMONIES = 'CEREMONIES',
  BIRTHS = 'BIRTHS',
  FUNERALS = 'FUNERALS',
  REFLECTIONS = 'REFLECTIONS',
  THANKYOU = 'THANKYOU',
}

export type GenerateAuthTokenResponse = {
  success: boolean;
  message: string;
  token: string;
};

export const signIn = async (body: SignInBody) => {
  const responseBody = await _request<SignInUpResponse>({
    url: 'auth/signin',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const verifyInviteLink = async (body: VerifyInviteLinkBody) => {
  const responseBody = await _request<VerifyInviteLinkResponse>({
    url: 'auth/verify-review-link',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const verifyGiftcardLink = async (body: VerifyGiftcardLinkBody) => {
  const responseBody = await _request<VerifyInviteLinkResponse>({
    url: 'auth/verify-giftcard-link',
    method: 'POST',
    body,
  });
  return responseBody;
};

// const getReferralData = async (body: ReferralRequestBody) => {
//   const responseBody = await _request<ReferralResponseBody>({
//     url: `https://app.referralhero.com/widget/${
//       process.env.REACT_APP_REFERRAL_HERO_CAMPAIGN_ID ?? ''
//     }/post`,
//     method: 'POST',
//     useRelativeUrl: false,
//     body,
//   });
//   return responseBody.data;
// };

const createReferralUser = async (body: CreateReferralRequestBody) => {
  const referrer = getCookie(`PROVENANCE_REFERRER_T`);
  body.referrer = referrer ?? '';
  const responseBody = await _request<ReferralResponseBody>({
    url: `https://app.referralhero.com/widget/${
      process.env.REACT_APP_REFERRAL_HERO_CAMPAIGN_ID ?? ''
    }/post`,
    method: 'POST',
    useRelativeUrl: false,
    body,
  });
  return responseBody.data;
};

export const signUp = async (body: SignUpRequestBody) => {
  const responseBody = await _request<SignInUpResponse>({
    url: 'auth/signup',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const forgetPassword = async (body: ForgotPasswordBody) => {
  const responseBody = await _request<ForgotPasswordResponse>({
    url: 'auth/forget-password',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const resetPasswordApi = async (body: ResetPasswordBody) => {
  const responseBody = await _request<ResetPasswordResponse>({
    url: 'auth/reset-password',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const createPasswordApi = async (body: ResetPasswordBody) => {
  const responseBody = await _request<ResetPasswordResponse>({
    url: 'auth/create-password',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const verifyEmailRequest = async (body: VerifyTokenBody) => {
  const responseBody = await _request<VerifyTokenResponse>({
    url: 'auth/verify-email',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const changeEmailRequest = async (body: VerifyTokenBody) => {
  const responseBody = await _request<VerifyTokenResponse>({
    url: 'auth/change-email',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const verifyResetToken = async (body: VerifyTokenBody) => {
  const responseBody = await _request<VerifyTokenResponse>({
    url: 'auth/reset-token-verify',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const getMe = async () => {
  const responseBody = await _request<MeResponse>({
    url: 'users/me',
    method: 'GET',
  });
  return responseBody;
};

export const setLastActive = async () => {
  const responseBody = await _request<SetLastActiveResponse>({
    url: 'users/set-last-active',
    method: 'POST',
  });
  return responseBody;
};

export const generateAuthToken = async (body: GenerateAuthTokenRequestBody) => {
  const responseBody = await _request<GenerateAuthTokenResponse>({
    url: 'auth/generate-auth-token',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const generateExternalApiKey = async (
  body: GenerateReflectionsApiKeyRequestBody
) => {
  const responseBody = await _request<GenerateAuthTokenResponse>({
    url: 'auth/generate-external-api-key',
    method: 'POST',
    body,
  });
  return responseBody;
};

export const verifyAuthToken = async (token: string) => {
  const responseBody = await _request<SignInUpResponse>({
    url: 'auth/validate-auth-token',
    method: 'POST',
    body: { token },
  });
  return responseBody;
};
