import React, { useEffect, useState } from 'react';

import { SaveWaitlistQABody, WaitListQuestions } from '../../../api/waitlist';
import CustomRadioSelect from '../../../components/forms/CustomRadioSelect';
import CustomTextInput from '../../../components/forms/CustomTextInput';

type RadioQuestionProps = {
  question: WaitListQuestions;
  updateAnswer: (qid: string, oid: string, textans: string) => void;
  answer?: SaveWaitlistQABody;
};

const RadioQuestion = ({
  question,
  answer,
  updateAnswer,
}: RadioQuestionProps) => {
  const handleTextChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    updateAnswer(question.id.toString(), answer?.oid ?? '', ev.target.value);
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    updateAnswer(
      question.id.toString(),
      ev.target.value,
      answer?.textans ?? ''
    );
  };

  return (
    <div className='col-span-4 md:col-span-6 lg:col-span-12 w-full my-4'>
      <div className='mx-2 my-3 font-nanum text-2xl leading-8'>
        {question.question}
      </div>
      <CustomRadioSelect
        options={question.options.map((option) => ({
          label: option.option,
          value: option.id.toString(),
        }))}
        onChange={handleChange}
        className={'gap-1'}
        value={answer?.oid || null}
        name={'optionQuestion'}
        simpleRadio={true}
        singleRow={false}
        row={true}
      />
      {question.has_textfield && (
        <CustomTextInput
          className='mt-4 bg-white'
          multiline
          rows={5}
          value={answer?.textans}
          onChange={handleTextChange}
          placeholder={'Type here...'}
        />
      )}
    </div>
  );
};

export default RadioQuestion;
