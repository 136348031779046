import moment from 'moment';

import { Ceremony, Member } from '../../../api/ceremony';
import Button from '../../../components/v2/Button/Button';
import ButtonPill from '../../../components/v2/ButtonPill';
import { Modal } from '../../../components/v2/Modal/Modal';
import { Typography } from '../../../components/v2/Typography/Typography';
import { getCoupleName } from '../../../helpers/ceremonyHelper'
import { usePlanner } from '../../../provider/plannerProvider';


type ReactivateCeremonyModalProps = {
  ceremony: Ceremony;
  activeWeddings: number;
  totalWeddings: number;
  couple1?: Member;
  couple2?: Member;
};

const ReactivateCeremonyModal = (props: ReactivateCeremonyModalProps) => {
  const { ceremony, activeWeddings, totalWeddings, couple1, couple2 } = props;
  let { coupleName, date } = ceremony;

  const { showReactivateModal, setShowReactivateModal, reactivateCeremony } = usePlanner();

  if (!date) date = 'TBD';

  const getCeremonyCoupleName = () => {
    if (ceremony.coupleName) {
      return ceremony.coupleName;
    } else if (couple1 && couple2){
      return getCoupleName(couple1, couple2) 
    }
  };


  return (
    <Modal
      isOpen={showReactivateModal}
      onClose={() => setShowReactivateModal(false)}
      height='auto'
    >
      {activeWeddings >= totalWeddings ? (
        <>
          <Typography className='mb-2' type='display-600'>
            No available slots
          </Typography>
          <Typography className='mb-8' type='body-400'>
            <>
              You are currently using all {totalWeddings} active wedding slots.
              In order to reactivate this wedding first you will need to
              deactivate one of the other active weddings.
            </>
          </Typography>
          <Typography className='mb-8' type='body-400'>
            <>
              Learn more about
                <Button
                  size='200'
                  onClick={() => {
                    window.open('https://provenance.elevio.help/en/articles/63-how-does-provenance-pro-work', '_blank');
                  }}
                  variant='text'
                  typographyVariant='primary'
                  className='ml-1'
                >
                  how active weddings work
                </Button>
            </>
          </Typography>
          <div className='flex justify-end w-full'>
            <ButtonPill
              text='Got it'
              onClick={() => {
                setShowReactivateModal(false);
              }}
              className='bg-forest-primary text-white'
            />
          </div>
        </>
      ) : (
        <>
          <Typography className='mb-2' type='display-600'>
            Confirm Reactivation
          </Typography>
          <Typography className='mb-8' type='body-400'>
            <>
              Your are about to reactivate{' '}
              <b>
                {getCeremonyCoupleName()}
                {date !== 'TBD' ? ' ' + moment(date).format('MMM Do, YY') : null}.
              </b>{' '}
              The couple will regain full access to the platform, and it will occupy one of your active wedding slots.
            </>
          </Typography>
          <Typography className='mb-8' type='body-400'>
            <>
              Current free slots: {activeWeddings}/{totalWeddings}
            </>
          </Typography>
          <Typography className='mb-8' type='body-400'>
            <>
              Learn more about
              <Button
                size='200'
                onClick={() => {
                  window.open('https://provenance.elevio.help/en/articles/63-how-does-provenance-pro-work', '_blank');
                }}
                variant='text'
                typographyVariant='primary'
                className='ml-1'
              >
                how active weddings work
              </Button>
            </>
          </Typography>
          <div className='flex justify-between w-full'>
            <Button
              size='100'
              onClick={() => setShowReactivateModal(false)}
              variant='text'
            >
              Cancel
            </Button>
            <ButtonPill
              text='Confirm'
              onClick={() => {
                reactivateCeremony();
                setShowReactivateModal(false)
              }}
              className='bg-forest-primary text-white'
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default ReactivateCeremonyModal;
