import './index.scss';

type TitleProps = {
  className: string;
  text: string;
  h: number;
};

export const Title = (props: TitleProps) => {
  const { className, text, h } = props;

  const header = () => {
    switch (h) {
      case 1:
        return <h1 className={`title-component ${className} h${h}`}>{text}</h1>;
      case 2:
        return <h2 className={`title-component ${className} h${h}`}>{text}</h2>;
    }
  };

  return <>{header()}</>;
};
