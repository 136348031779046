import { User } from './auth';
import { Ceremony } from './ceremony';
import { _request } from './request';

export type PayOptionsResponse = {
  success: boolean;
  message: string;
  data: PayOption[];
  conversionRates: ConversionRate[];
};

export type ConversionRate = {
  id: number;
  currency_from: string;
  currency_to: string;
  conversion_from_value: number;
  conversion_to_value: number;
  conversion_to_country_code: string;
  createdAt: string;
  updatedAt: string;
};

export type CURRENCIES = {
  [key: string]: string;
};

export type PayOrderResponse = {
  success: boolean;
  message: string;
  data: PayOrderResponseData;
};
type PayOrderResponseData = {
  order_id: number;
  ref: string;
};

export type PayOrdersResponse = {
  success: boolean;
  message: string;
  data: PayOrdersResponseData;
};
type PayOrdersResponseData = {
  refs: string;
};

export enum PaymentMethod {
  card = 'card',
  bank_transfer = 'bank_transfer',
  cash = 'cash',
}

export enum OrderType {
  ceremony_designer = 'ceremony_designer',
  speech_writing = 'speech_writing',
  merchandising = 'merchandising',
  guest_toast_builder = 'guest_toast_builder',
}

export enum appToolOptions {
  landing_page = 'landing_page', 
  ceremony_designer = 'ceremony_designer',
  toast_builder = 'toast_builder',
  vows_builder = 'vows_builder',
  welcome_toast_builder = 'welcome_toast_builder',
  none = '',
}

export type PayOrderBody = {
  ceremony_id?: string;
  payment_method: PaymentMethod;
  type: OrderType;
  product_id?: number;
  upsold_product_id?: number;
  subscription_plan_id?: number;
  payment_type?:
    | 'one_time_payment'
    | 'subscription'
    | 'wp_pay_per_ceremony'
    | 'merchandising'; //I don't think 'merchandising' is added as a payment type in the backend'
  value?: number;
  currency?: string;
  is_giftcard?: boolean;
  app_tool?: appToolOptions;
  source?: OrderSources;
  addons?: string[];
};

export type PayOrdersBody = {
  orders: PayOrderBody[];
};

export interface CeremonyAccessibleModules {
  id: number;
  name: string;
  price: number;
  description: string;
  category: string;
  active: boolean;
  is_popular?: boolean;
  version: number;
  createdAt: string;
  updatedAt: string;
  modules?: ActivatedModule[];
}

export type PayOption = {
  features: PayPlansFeature[];
} & CeremonyAccessibleModules;

export interface PayPlansFeature {
  id: number;
  plan_id: number;
  title: string;
  subtitle?: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface ActivatedModule {
  id: number;
  pricing_option_id: number;
  module_identifier: string;
}

export interface NewPaymentBody {
  pid?: number;
  sessionId?: string;
  ref: string;
  currency?: string;
  isGiftcard?: boolean;
}

export interface NewSubscriptionBody {
  subscriptionPlanId?: number;
  sessionId?: string;
  ref: string;
  currency?: string;
}

export interface NewPaymentRequestBody {
  ref: string;
  payee: string;
  payeeDetail: payeeDetailType;
}
type payeeDetailType = {
  name: string;
  email: string;
};
export interface PayRequestResponse {
  success: boolean;
  message: string;
}
export interface OrderResponse {
  success: boolean;
  message: string;
  order: Order;
}

export interface Order {
  id: number;
  ceremony_id: number;
  user_id: number;
  payment_method: string;
  payment_type: string;
  type: string;
  product_id: number;
  subscription_plan_id: number;
  subscription_id: string;
  active_until: Date;
  cancel_at_period_end?: boolean;
  value: number;
  actual_amount: number;
  currency: string;
  reference: string;
  status: string;
  refunded: boolean;
  createdAt: string;
  updatedAt: string;
  payment_requests?: PaymentRequest[];
  user?: User;
  ceremony?: Ceremony;
  option?: PayOption;
  source?: OrderSources;
}

export enum OrderSources {
  null = '',
  cb_landing_officiants = 'cb_landing_officiants',
  cb_landing_vows = 'cb_landing_vows',
  cb_landing_idos = 'cb_landing_idos',
  cb_landing_pronouncement = 'cb_landing_pronouncement',
  cb_editor = 'cb_editor',
  vb_landing = 'vb_landing',
  vb_editor = 'vb_editor',
  tb_landing = 'tb_landing',
  tb_landing_welcome_toast = 'tb_landing_welcome_toast',
  tb_editor = 'tb_editor',
  merch_vb_landing = 'merch_vb_landing',
  merch_vb_editor = 'merch_vb_editor',
  merch_tb_landing = 'merch_tb_landing',
  merch_tb_editor = 'merch_tb_editor',
  merch_cb_editor = 'merch_cb_editor',
  merch_wt_editor = 'merch_wt_editor',
  landingPage = 'lading_page',
}

export interface PaymentRequest {
  id: number;
  name: string;
  email: string;
  order_id: number;
  member_id: number;
  createdAt: string;
  updatedAt: string;
}

export interface getOrdersBody {
  user_id: number;
}
export interface getOrdersResponse {
  success: boolean;
  message: string;
  orders: Array<Order>;
}

export const getOrders = async () => {
  const responseBody = await _request<getOrdersResponse>({
    url: `orders/get-orders`,
    method: 'GET',
  });
  return responseBody;
};

export const getPaymentOptions = async (ceremonyId: string) => {
  const responseBody = await _request<PayOptionsResponse>({
    url: `paymentOptions/${ceremonyId}`,
    method: 'GET',
  });
  return responseBody;
};

export const placeNewOrder = async (body: PayOrderBody) => {
  const responseBody = await _request<PayOrderResponse>({
    url: `orders/place-order`,
    method: 'POST',
    body: body,
  });
  return responseBody;
};

export const placeNewOrders = async (body: PayOrdersBody) => {
  const responseBody = await _request<PayOrdersResponse>({
    url: `orders/place-orders`,
    method: 'POST',
    body: body,
  });
  return responseBody;
};

export const getOrderByRef = async (refid: string, reqid: string) => {
  const responseBody = await _request<OrderResponse>({
    url: `orders/get-order/${reqid}/${refid}`,
    method: 'GET',
  });
  return responseBody;
};

export const newpaymentSession = async (body: NewPaymentBody) => {
  const responseBody = await _request<PayOrderResponse>({
    url: `orders/create-payment-session`,
    method: 'POST',
    body: body,
  });
  return responseBody;
};

export const newMultipleOrdersPaymentSession = async (body: NewPaymentBody) => {
  const responseBody = await _request<PayOrderResponse>({
    url: `orders/create-payment-session-multiple-orders`,
    method: 'POST',
    body: body,
  });
  return responseBody;
};

export const sendPaymentRequest = async (body: NewPaymentRequestBody) => {
  const responseBody = await _request<PayRequestResponse>({
    url: `orders/send-pay-request`,
    method: 'POST',
    body: body,
  });
  return responseBody;
};

export const confirmpaymentSession = async (body: NewPaymentBody) => {
  const responseBody = await _request<OrderResponse>({
    url: `orders/confirm-order`,
    method: 'POST',
    body: body,
  });
  return responseBody;
};

export const confirmMultipleOrders = async (body: NewPaymentBody) => {
  const responseBody = await _request<OrderResponse>({
    url: `orders/confirm-orders`,
    method: 'POST',
    body: body,
  });
  return responseBody;
};

export const confirmSubscription = async (body: NewSubscriptionBody) => {
  const responseBody = await _request<OrderResponse>({
    url: `orders/confirm-subscription`,
    method: 'POST',
    body: body,
  });
  return responseBody;
};

export const createCheckoutSession = async (body: NewSubscriptionBody) => {
  const responseBody = await _request<PayOrderResponse>({
    url: `orders/create-checkout-session`,
    method: 'POST',
    body: body,
  });
  return responseBody;
};

export const createPortalSession = async () => {
  const responseBody = await _request<PayOrderResponse>({
    url: `orders/create-portal-session`,
    method: 'POST',
  });
  return responseBody;
};
