/* eslint-disable -- TODO: fix eslint errors */

import { useState, useEffect } from 'react';
import { Declaration, getDeclarations } from '../api/declaration';

const useDeclarations = () => {
  const [declarations, setDeclarations] = useState<Declaration[]>();
  useEffect(() => {
    const fetchDeclarations = async () => {
      try {
        const response = await getDeclarations();
        if (response.success) {
          setDeclarations(response.data);
        }
      } catch (error) {}
    };
    fetchDeclarations();
  }, []);

  return {
    declarations,
  };
};

export default useDeclarations;
