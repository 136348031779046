import { ModuleStatus } from '../pages/ModulesV2/enum';

import { _request } from './request';

//old
export enum ChecklistItemType {
  ceremonyDesigner = 'ceremony_designer',
  vows = 'vows',
  toasts = 'toasts',
  speechwritingSupport = 'speechwriting_support',
  publicSpeaking = 'public_speaking',
  premaritalCounseling = 'premarital_counseling',
  legalServices = 'legal_services',
  weddingDate = 'wedding_date',
  invitePartner = 'invite_partner',
  invitePartnerA = 'invite_partner_a',
  invitePartnerB = 'invite_partner_b',
  inviteOfficiant = 'invite_officiant',
  inviteWeddingPlanner = 'invite_wedding_planner',
  includeVenue = 'include_venue',
  startVows = 'start_vows',
  completeVows = 'complete_vows',
  confirmTone = 'confirm_tone',
  inviteGuests = 'invite_guests',
  inviteGuestsReherseal = 'invite_guests_reherseal',
  prenump = 'prenump',
  getOrdained = 'get_ordained',
  welcome_toast = 'welcome_toast',
}

//old
export type ChecklistSubItemProps = {
  title: string;
  description?: string;
  status?: ModuleStatus;
  buttonTitle?: string;
  onButtonClick?: () => Promise<void> | void;
  textButtonTitle?: string;
  onTextButtonClick?: () => Promise<void> | void;
  hidden?: boolean;
};

export type ChecklistItemDefinition = {
  id: number;
  identifier: string;
  URL: string;
  is_paid: boolean;
  member_type: string;
  group: string;
  order: number;
  hidden: boolean;
  member_checklist_item: MemberChecklistItem;
};

export type MemberChecklistItem = {
  checklist_item_definition_id: number;
  ceremony_id?: number;
  member_id?: number;
  completed?: boolean;
  skipped?: boolean;
  hidden?: boolean;
};

export type SaveMemberChecklistItemsResponse = {
  success: boolean;
  message: string;
  data: MemberChecklistItem;
};

export type GetChecklistItemsResponse = {
  success: boolean;
  message: string;
  data: ChecklistItemDefinition[];
};

export const getChecklistItemsForCeremony = async (
  ceremonyId: number | null = null,
  isWeddingPlanner: boolean | null = null
) => {
  const params = new URLSearchParams();

  if (ceremonyId) {
    params.append('ceremonyId', ceremonyId.toString());
  }

  if (isWeddingPlanner) {
    params.append('isWeddingPlanner', isWeddingPlanner.toString());
  }

  const responseBody = await _request<GetChecklistItemsResponse>({
    url: `checklistitemdefinitions?${params.toString()}`,
    method: 'GET',
  });
  return responseBody;
};

export const saveChecklistItem = async (
  checklistItem: MemberChecklistItem,
  isWp: boolean,
  isUserLevel: boolean
) => {
  const responseBody = await _request<SaveMemberChecklistItemsResponse>({
    url: 'memberchecklistitems/save',
    method: 'POST',
    body: {
      ...checklistItem,
      isWeddingPlanner: isWp,
      isUserLevel,
    },
  });
  return responseBody;
};
