import React, { useEffect, useState } from 'react';

import {
  sendVerificationCode,
  verifyCode,
} from '../../../api/verificationCodes';
import { maskEmail } from '../../../helpers/emailHelper';
import { useDashboard } from '../../../provider/dashboardProvider';
import { useSnackbar } from '../../../provider/snackbarProvider';
import CustomTextInput from '../../forms/CustomTextInput';
import Button from '../Button/Button';
import { Modal } from '../Modal/Modal';
import { Typography } from '../Typography/Typography';

const VerifyCodeModal = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const {
    verifyCodeModalOpen,
    setVerifyCodeModalOpen,
    currentUser,
    setCreateNewPasswordModalOpen,
  } = useDashboard();

  const { openSnackBar } = useSnackbar();

  const sendCode = async () => {
    try {
      const response = await sendVerificationCode();
      if (response.success) {
        openSnackBar('Verification code sent successfully', 'success');
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (verifyCodeModalOpen) {
      void sendCode();
      setCode('');
    }
  }, [verifyCodeModalOpen]);

  const handleResendCode = async () => {
    await sendCode();
  };

  const handleContinue = async () => {
    try {
      const response = await verifyCode(code);
      if (response.success) {
        setVerifyCodeModalOpen(false);
        setCreateNewPasswordModalOpen(true);
      } else {
        setError(
          'Incorrect verification code. Please try again or request a new code.'
        );
      }
    } catch (error) {
      setError('Could not verify the code');
      console.log('error', error);
    }
  };

  return (
    <Modal
      isOpen={verifyCodeModalOpen}
      onClose={() => setVerifyCodeModalOpen(false)}
    >
      <>
        <Typography type='display-600'>Verify that it’s you</Typography>
        <Typography type='body-400' className='mt-2 mb-8'>
          {`An email was sent to ${maskEmail(
            currentUser?.email || ''
          )} with a six digital
          verification code. Enter that code below to continue.`}
        </Typography>
        <CustomTextInput
          label='Verification Code'
          placeholder='Enter your 6 digit code'
          fullWidth={true}
          className='w-full mx-0'
          value={code}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            setCode(ev.target.value);
          }}
          error={!!error}
          helperText={error}
        />
        <div className='flex flex-row justify-between items-center mt-8'>
          <Button variant='text' onClick={handleResendCode}>
            Resend Code
          </Button>
          <Button onClick={handleContinue}>Continue</Button>
        </div>
      </>
    </Modal>
  );
};

export default VerifyCodeModal;
