import { useEffect, useState } from 'react';

import { updateUserCustomField } from '../../../../api/user';
import CustomCheckBox from '../../../../components/forms/CustomCheckBox';
import Button from '../../../../components/v2/Button/Button';
import { Modal } from '../../../../components/v2/Modal/Modal';
import { Typography } from '../../../../components/v2/Typography/Typography';
import { useAuth } from '../../../../provider/authProvider';
import { useCTA } from '../../../../provider/ctaProvider';

type ModalProps = {
  isOpen: boolean;
  onCancelClick: () => void;
  onContinue: () => void;
};

export const GenerationIncentiveModal = (props: ModalProps) => {
  const { isOpen, onCancelClick, onContinue } = props;
  const [isCheckboxMarked, setIsCheckboxMarked] = useState(false);

  const customFieldKey = 'dont_show_generation_incentive_modal';

  const { user, getProfile } = useAuth();

  useEffect(() => {
    const hideModal =
      user.custom_fields?.find(
        (customField) => customField.key === customFieldKey
      ) != undefined;

    if (isOpen && hideModal) {
      onContinue();
    }
  }, [isOpen]);

  const handleContinue = async () => {
    if (isCheckboxMarked) {
      await updateUserCustomField(customFieldKey, 'true');
      void getProfile(() => {});
    }

    onContinue();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onCancelClick()}
      showCloseButton={true}
      title={''}
      maxWidth={'sm'}
    >
      <div className='self-center flex flex-col justify-center text-center '>
        <>
          <Typography type='display-100' className='mb-3'>
            <>
              Want to generate a draft now?
            </>
          </Typography>
          <Typography type='body-200'>
            The more questions you answer, the more personal and
            complete your draft will be. You can always come back and answer more questions later.
          </Typography>

          <CustomCheckBox
            customClassName='mt-4'
            label={
              <Typography
                type='body-200'
                variant='functional-low'
                className='inline'
              >
                Do not show this message again
              </Typography>
            }
            onChange={() => setIsCheckboxMarked(!isCheckboxMarked)}
            checked={isCheckboxMarked}
          />

          <div
            className={`flex flex-col-reverse md:flex-row justify-center mt-8`}
          >
            <Button
              className='mt-2 md:mt-0 md:mr-2 md:w-auto w-full'
              onClick={onCancelClick}
              variant='secondary'
            >
              Answer More Questions
            </Button>
            <Button
              className=' md:w-auto w-full'
              onClick={handleContinue}
            >
              Generate a draft now
            </Button>
          </div>
        </>
      </div>
    </Modal>
  );
};
