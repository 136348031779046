/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';

import Button from '../../components/button';
import CustomCheckBox from '../../components/forms/CustomCheckBox';
import { Modal } from '../../components/v2/Modal/Modal';

import './Calendar.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onBackClick: () => void;
  onNextClick: (date: string | null) => void;
  title: string;
};

export const EventDateModal = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const [date, setDate] = useState<Dayjs | null>(null);
  const [noDate, setNoDate] = useState(false);
  const { isOpen, onClose, title, onBackClick, onNextClick } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='flex flex-col items-center'>
        <h3 className='font-nanum text-2xl'>{title}</h3>
        <CalendarPicker
          minDate={dayjs()}
          date={date}
          onChange={(newDate) => setDate(newDate)}
          disabled={noDate}
          className={`max-w-full text-[12px]`}
        />
        <CustomCheckBox
          label="I don't know the date yet"
          value={noDate}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            setNoDate(ev.target.value === 'false' ? true : false);
          }}
          checked={noDate}
        />
        <div className='mt-12 flex flex-row justify-center items-center'>
          <Button
            className='text-forest-primary bg-white mr-4'
            title='BACK'
            onClick={onBackClick}
          />
          <Button
            className='bg-forest-primary text-white'
            title='NEXT'
            disabled={!date && !noDate}
            onClick={() =>
              onNextClick(noDate ? null : date?.format('YYYY-MM-DD') || null)
            }
          />
        </div>
      </div>
    </Modal>
  );
};
