import { FieldProps } from 'formik';

import CustomTextArea from '../../../components/forms/CustomTextArea';

interface CustomInputProps {
  type?: 'text' | 'number' | 'password' | 'email';
  placeholder?: string;
  setChanged: () => void;
  readOnly?: boolean;
  className?: string;
  error?: boolean;
  helperText: string;
}

export const CustomFormikTextArea = ({
  field, // { name, value, onChange, onBlur }
  form,
  className,
  setChanged,
  error,
  helperText,
  ...props
}: // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
CustomInputProps & FieldProps<string>) => {
  return (
    <CustomTextArea
      className={className}
      value={field.value}
      name={field.name}
      onChange={(ev) => {
        field.onChange(ev);
        if (setChanged) {
          setChanged();
        }
      }}
      onBlur={(ev) => {
        field.onChange(ev);
        if (setChanged) {
          setChanged();
        }
      }}
      placeholder={props.placeholder}
      required={true}
      type={props.type}
      readOnly={props.readOnly}
      error={error}
      helperText={helperText}
      {...props}
    />
  );
};
