/* eslint-disable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return */
import { capitalizeFirstLetter } from './helper';

export const replacePlaceholders = (
  value: string,
  couple1Name?: string,
  couple2Name?: string,
  officiantName?: string,
  user_name?: string
) => {
  const result =
    value &&
    value
      .replaceAll('{{PARTNER_A}}', capitalizeFirstLetter(couple1Name!) || '')
      .replaceAll('{{PARTNER_B}}', capitalizeFirstLetter(couple2Name!) || '')
      .replaceAll(
        '{{OFFICIANT_NAME}}',
        capitalizeFirstLetter(officiantName!) || ''
      )
      .replaceAll(
        '{{PARTNER_A_B}}',
        couple1Name && couple2Name
          ? `${capitalizeFirstLetter(couple1Name)} & ${capitalizeFirstLetter(
              couple2Name
            )}`
          : ''
      )
      .replaceAll('{{USER_NAME}}', user_name || '');
  return result;
};

type InterpolatePlaceholdersProps = {
  text: string;
  data?: Record<string, any>; // eslint-disable-line
};

export const interpolatePlaceholders = (
  props: InterpolatePlaceholdersProps
) => {
  const { data = {} } = props;
  const placeholderMap: Record<string, () => string> = {
    '{{PARTNER_A}}': () => {
      return (
        data?.couple1?.preferred_name ||
        data?.couple1?.legal_name ||
        data?.couple1Name
      );
    },
    '{{PARTNER_B}}': () => {
      // eslint-disable-next-line
      return data?.couple2?.preferred_name.includes('TBD')
        ? 'Partner'
        : data?.couple2?.preferred_name ||
            data?.couple2?.legal_name ||
            data?.couple2Name;
    },
    '{{OFFICIANT_NAME}}': () => {
      return (
        data?.officiant?.preferred_name ||
        data?.officiant?.legal_name ||
        data?.officiantName
      );
    },
    '{{PARTNER_A_B}}': () => {
      const partnerA = placeholderMap['{{PARTNER_A}}']();
      const partnerB = placeholderMap['{{PARTNER_B}}']();
      return partnerA && partnerB ? `${partnerA} & ${partnerB}` : '';
    },
    '{{USER_NAME}}': () => {
      return (
        data?.user?.preferred_name || data?.user?.legal_name || data?.user_name
      );
    },
    '[Partner A’s last name]': () => {
      if (data?.couple1?.legal_name.includes('TBD')) { // eslint-disable-line
        return '[Partner A’s last names]';
      }

      if (data?.couple1?.legal_name) {
        const names = data.couple1.legal_name.split(' '); // eslint-disable-line
        if (names[1]) {
          return capitalizeFirstLetter(names[1]);
        }
      }

      return '[Partner A’s last name]';
    },
    '[Partner B’s last name]': () => {

      if (data?.couple2?.legal_name.includes('TBD')) { // eslint-disable-line
        return '[Partner B’s last name]';
      }

      if (data?.couple2?.legal_name) {
        const names = data.couple2.legal_name.split(' '); // eslint-disable-line
        if (names[1]) {
          return capitalizeFirstLetter(names[1]);
        }
      }

      return '[Partner B’s last names]';
    },
  };

  const placeholders = [...props.text.matchAll(/\{\{[^}]+\}\}/gi)].map(
    (m) => m[0]
  );
  let result = props.text;

  placeholders.forEach((placeholder) => {
    result = result.replace(
      placeholder,
      placeholderMap?.[placeholder]?.() || placeholder
    );
  });

  result = result.replaceAll(
    '[Partner A’s last name]',
    placeholderMap['[Partner A’s last name]']() || ''
  );

  result = result.replaceAll(
    '[Partner B’s last name]',
    placeholderMap['[Partner B’s last name]']() || ''
  );

  return result;
};
