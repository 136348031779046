import React from 'react';

import Button from '../../../../components/v2/Button/Button';
import { useWindowSize } from '../../../../hooks/useWindowSize';

type WatchDemoButtonProps = {
  onClick: () => void;
};

const WatchDemoButton = (props: WatchDemoButtonProps) => {
  const { onClick } = props;
  const { isMobile } = useWindowSize()
  return (
    <Button
      size='100'
      variant='secondary'
      className={isMobile ? 'flex' : 'flex ml-4'}
      eventClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
      renderLeftIcon={() => (
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clip-path='url(#clip0_7982_320)'>
            <path
              d='M6.51 11.8542C6.66 11.95 6.82875 12 7.00125 12C7.19344 12 7.38225 11.937 7.5405 11.8218L11.538 8.94938C11.8275 8.74172 12 8.38756 12 7.96256C12 7.57586 11.8264 7.22049 11.5369 7.01258L7.5375 4.17929C7.2258 3.95337 6.83437 3.93916 6.50737 4.14686C6.195 4.34595 6 4.72095 6 5.12511V10.8709C6 11.2792 6.195 11.6542 6.51 11.8542ZM7.2 5.52093L10.6163 8.00006L7.2 10.4792V5.52093Z'
              fill='#354740'
            />
            <circle
              cx='8'
              cy='8'
              r='7.35'
              stroke='#354740'
              strokeWidth='1.3'
            />
          </g>
          <defs>
            <clipPath id='clip0_7982_320'>
              <rect width='16' height='16' fill='white' />
            </clipPath>
          </defs>
        </svg>
      )}
    >
    {isMobile ? 'Demo' : 'Watch Demo'}
    </Button>
  );
};

export default WatchDemoButton;
