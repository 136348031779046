import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Member } from '../../../api/ceremony';
import { ChecklistItemDefinition } from '../../../api/checklistItemDefinitions';
import Button from '../../../components/v2/Button/Button';
import { Typography } from '../../../components/v2/Typography/Typography';
import { getGroupChecklistItem } from '../../../helpers/checklistHelpers/checklistItemHelper';
import { Module } from '../../../hooks/useActiveModule';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useChecklist } from '../../../provider/checklistProvider';
import { useCreateEvent } from '../../../provider/createEventProvider';
import { useDashboard } from '../../../provider/dashboardProvider';
import { useAccessibleModule } from '../../../provider/moduleAccessProvider';

import ChecklistGroup, {
  ChecklistGroups,
  GroupChecklistItem,
} from './v2/ChecklistGroup';

type OfficiantChecklistItemsProps = {
  ceremonyId: number;
  currentUser: Member;
  couple1?: Member;
  couple2?: Member;
};

const OfficiantChecklistItems = (props: OfficiantChecklistItemsProps) => {
  const { ceremonyId, couple1, couple2, currentUser } = props;
  const { checklistItems, fetchChecklistItems, saveMemberChecklistItem } =
    useChecklist();

  const { setChooseCollaboratorModalOpen } = useDashboard();

  const navigate = useNavigate();
  const { isMobile } = useWindowSize()

  const {
    hasAccessToDeclarationOfIntent,
    hasAccessToDirectionalQuestions,
    hasAccessToOfficiantRemarks,
    hasAccessToRitualsAndReadings,
    hasAccessToVowsCeremony,
    hasAccessToPronouncementCelebration,
    hasAccessToVowsAi,
  } = useAccessibleModule();

  const { setEventRoleModalOpen } = useCreateEvent();

  const checkAccessToModule = (module: Module) => {
    switch (module) {
      case Module.DeclarationOfIntent:
        return hasAccessToDeclarationOfIntent;
      case Module.DirectionalQuestions:
        return hasAccessToDirectionalQuestions;
      case Module.OfficiantRemarks:
        return hasAccessToOfficiantRemarks;
      case Module.RitualsAndReadings:
        return hasAccessToRitualsAndReadings;
      case Module.VowsCeremony:
        return hasAccessToVowsCeremony;
      case Module.PronouncementCelebration:
        return hasAccessToPronouncementCelebration;
      case Module.VowsAi:
        return hasAccessToVowsAi;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (ceremonyId) {
      void fetchChecklistItems(ceremonyId, false);
    }
  }, [ceremonyId]);
  const getChecklistItemsByGroup = (
    checklistItems: ChecklistItemDefinition[],
    group: ChecklistGroups
  ) => {
    const items = checklistItems?.filter((item) => item.group === group);
    const result = [] as GroupChecklistItem[];
    items.map((item) => {
      const mapped = getGroupChecklistItem(
        item,
        navigate,
        checkAccessToModule,
        saveMemberChecklistItem,
        setChooseCollaboratorModalOpen,
        () => {},
        () => {},
        ceremonyId,
        currentUser,
        couple1,
        couple2
      );
      if (mapped) {
        result.push(mapped);
      }
    });
    return result;
  };

  return (
    <>
      {checklistItems && checklistItems.length > 0 && (
        <>
          <ChecklistGroup
            title='3 months before wedding'
            checklistItems={getChecklistItemsByGroup(
              checklistItems,
              ChecklistGroups.THREE_MONTHS_BEFORE
            )}
          />
          <ChecklistGroup
            title='2 months before wedding'
            checklistItems={getChecklistItemsByGroup(
              checklistItems,
              ChecklistGroups.TWO_MONTHS_BEFORE
            )}
          />
          <ChecklistGroup
            title='1 month before wedding'
            checklistItems={getChecklistItemsByGroup(
              checklistItems,
              ChecklistGroups.ONE_MONTH_BEFORE
            )}
          />
          <ChecklistGroup
            title='1 week before wedding'
            checklistItems={getChecklistItemsByGroup(
              checklistItems,
              ChecklistGroups.ONE_WEEK_BEFORE
            )}
          />
          <ChecklistGroup
            title='After the wedding'
            checklistItems={getChecklistItemsByGroup(
              checklistItems,
              ChecklistGroups.AFTER_THE_WEDDING
            )}
          />
          <ChecklistGroup
              title='Congratulations! This wedding is wrapped up'
              checklistItems={[]}
            />

            <div className={classNames('mt-2 w-[429px]', {
              'ml-10 ': !isMobile
            })}>
              <Typography type='body-400'>
                Are you officiating another wedding soon? Get started by creating a new event for the new couple.
              </Typography>
              <Button
                onClick={() => setEventRoleModalOpen(true) }
                fullWidth={false}
                className='mt-4'
              >
                Create New Event
              </Button>
            </div>
        </>
      )}
    </>
  );
};

export default OfficiantChecklistItems;
