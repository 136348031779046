/* eslint-disable @typescript-eslint/no-empty-function */
import debounce from 'lodash.debounce';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { saveAnswer } from '../../api/question';
import {
  Question,
  QuestionAnswer,
} from '../../pages/Modules/common/moduleQuestion/ModuleQuestion';
import { useCTA } from '../ctaProvider';

export type DropdownQuestionContextType = {
  selectedValues: Array<string | number>;
  setSelectedValues: (
    value: Array<string | number>,
    isInitial?: boolean
  ) => void;
};

export const DropdownQuestionContext =
  React.createContext<DropdownQuestionContextType>({
    selectedValues: [],
    setSelectedValues: () => {},
  });

export const DropdownQuestionProvider = ({
  children,
  question,
  ceremonyId,
  onChange,
}: {
  children: React.ReactNode;
  question: Question;
  ceremonyId: number;
  onChange?: () => void;
}) => {
  const [selectedValues, setSelectedValuesState] = useState<
    Array<string | number>
  >([]);

  const shouldAllowSelect = useCallback(() => {
    if (!!question.max_options && Array.isArray(selectedValues))
      return selectedValues.length < question.max_options;
    return true;
  }, [question, selectedValues]);

  const onSelect = useCallback(
    (newValue: Array<string | number>, isInitial?: boolean) => {
      const isSelecting = newValue.length > selectedValues.length;

      // Do not allow selection by using some criteria.
      if (isSelecting && !shouldAllowSelect()) return;
      if (isInitial && newValue) {
        handleQuestionAnswered(question.id);
      }
      if (!isInitial) {
        handleQuestionUnsaved(question.id);
        debouncedCall(newValue, ceremonyId, question.id);
      }

      setSelectedValuesState(newValue);
    },
    [selectedValues, shouldAllowSelect]
  );

  // useEffect(() => {
  //   setSelectedValues((initialValue || []).map((opt) => opt.id));
  // }, [initialValue]);

  // const selectedOptions = selectedValues
  //   .map((value) => options.find((opt) => getOptionValue(opt) === value))
  //   .filter(Boolean);

  const {
    handleQuestionSaved,
    handleQuestionSaving,
    handleQuestionUnsaved,
    handleQuestionAnswered,
    handleQuestionNotAnswered,
  } = useCTA();

  const debouncedSaveFunction = async (
    selectedOptionIds: Array<string | number>,
    ceremonyId: number,
    questionId: number
  ) => {
    const answer: QuestionAnswer = {
      ceremony_id: ceremonyId,
      question_id: question.id,
      option_id: selectedOptionIds.join(','),
    };
    try {
      handleQuestionSaving(questionId);
      const saved = await saveAnswer(answer);
      if (saved) {
        handleQuestionSaved(questionId);
        onChange && void onChange();
        if (answer.text_answer || answer.option_id) {
          handleQuestionAnswered(questionId);
        } else {
          handleQuestionNotAnswered(questionId);
        }
      } else {
        handleQuestionUnsaved(questionId);
      }
    } catch (error) {
      handleQuestionUnsaved(questionId);
    }
  };

  const debouncedCall = useCallback(
    debounce(
      (
        selectedOptionIds: Array<string | number>,
        ceremony_id: number,
        question_id: number
      ) => {
        void debouncedSaveFunction(selectedOptionIds, ceremony_id, question_id);
      },
      2000
    ),
    [ceremonyId]
  );

  const value = {
    selectedValues,
    setSelectedValues: onSelect,
  };
  // @ts-ignore
  return (
    <>
      <DropdownQuestionContext.Provider value={value}>
        {children}
      </DropdownQuestionContext.Provider>
    </>
  );
};

export const useDropdownQuestion = () => {
  return useContext(DropdownQuestionContext);
};
