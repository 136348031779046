export const introJsSteps = [
    {
      element: '.CeremonyDetailsCard-root',
      tooltipClass: 'customTooltip customTooltipProvenance firstTooltip',
      title: 'Manage ceremony details',
      intro: "We know plans can change, so here's where you can make updates to your event settings (or create a new event if you're planning another wedding)!",
      position: 'right',
    },
    {
      element: '.Collaborators-root',
      tooltipClass: 'customTooltip customTooltipProvenance',
      title: 'Include Key Participants',
      intro:'Add in your client’s officiant and monitor the status of each invite to the platform. ',
      position: 'right',
    },
    {
      element: '.introJSWP_dashboard_step3',
      tooltipClass: 'customTooltip customTooltipProvenance',
      title: 'Monitor Clients’ Progress',
      intro:'Review your client’s ceremony script and vows, download/print them when completed, and monitor their progress to prevent procrastination! ',
      position: 'right',
    },
    {
      element: '.introJSWP_dashboard_step4',
      tooltipClass: 'customTooltip customTooltipProvenance',
      title: 'Invite Guest Speakers',
      intro:'Ensure the toasts are as beautiful as the rest of the day. Invite guest speakers, monitor their progress, and review their completed toasts below. ',
      position: 'right',
    }  
  ];
