import { useState } from 'react';

import { MemberSubType } from '../../api/ceremony';
import Button from '../../components/button';
import { Modal } from '../../components/v2/Modal/Modal';

import { ModalRoleItem } from './ModalRoleItem';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onBackClick: () => void;
  onNextClick: (role: MemberSubType) => void;
  title: string;
};

export const EventGuestRoleModal = (props: Props) => {
  const { isOpen, onClose, title, onBackClick, onNextClick } = props;
  const [selected, setSelected] = useState<MemberSubType>();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <>
        <h3 className='font-nanum text-2xl'>{title}</h3>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
          <div>
            <ModalRoleItem
              text={`Parent`}
              onClick={() => setSelected(MemberSubType.parent)}
              selected={selected === MemberSubType.parent}
            />
            <ModalRoleItem
              text={`Sibling`}
              onClick={() => setSelected(MemberSubType.sibling)}
              selected={selected === MemberSubType.sibling}
            />
            <ModalRoleItem
              text={`Best Man`}
              onClick={() => setSelected(MemberSubType.bestMan)}
              selected={selected === MemberSubType.bestMan}
            />
            <ModalRoleItem
              text={`Friend`}
              onClick={() => setSelected(MemberSubType.friend)}
              selected={selected === MemberSubType.friend}
            />
            <ModalRoleItem
              text={`Cousin`}
              onClick={() => setSelected(MemberSubType.cousin)}
              selected={selected === MemberSubType.cousin}
            />
          </div>
          <div>
            <ModalRoleItem
              text={`Grandparent`}
              onClick={() => setSelected(MemberSubType.grandparent)}
              selected={selected === MemberSubType.grandparent}
            />
            <ModalRoleItem
              text={`Maid of Honor`}
              onClick={() => setSelected(MemberSubType.maidOfHonor)}
              selected={selected === MemberSubType.maidOfHonor}
            />
            <ModalRoleItem
              text={`Wedding Party`}
              onClick={() => setSelected(MemberSubType.weddingParty)}
              selected={selected === MemberSubType.weddingParty}
            />
            <ModalRoleItem
              text={`Aunt/Uncle`}
              onClick={() => setSelected(MemberSubType.auntUncle)}
              selected={selected === MemberSubType.auntUncle}
            />
            <ModalRoleItem
              text={`Mentor`}
              onClick={() => setSelected(MemberSubType.mentor)}
              selected={selected === MemberSubType.mentor}
            />
          </div>
          <div className='col-span-1 sm:col-span-2'>
            <ModalRoleItem
              text={`Other`}
              onClick={() => setSelected(MemberSubType.other)}
              selected={selected === MemberSubType.other}
            />
          </div>
        </div>
        <div className='mt-12 flex flex-row justify-center items-center'>
          <Button
            className='text-forest-primary bg-white mr-4'
            title='BACK'
            onClick={onBackClick}
          />
          <Button
            className='bg-forest-primary text-white'
            title='NEXT'
            disabled={!selected}
            onClick={() => (selected ? onNextClick(selected) : {})}
          />
        </div>
      </>
    </Modal>
  );
};
