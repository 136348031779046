/* eslint-disable -- TODO: fix eslint errors */

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import closeIcon from '../../assets/images/icons/close-x-v2.svg';
import { Breakpoint } from '@mui/material';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='down' ref={ref} {...props} />;
});

type ModalProps = {
  isOpen: boolean;
  title?: string;
  subtitle?: string;
  onClose: () => void;
  children: JSX.Element;
  onHeaderButtonClick?: () => void;
  headerButtonText?: string;
  showCloseButton?: boolean;
  maxWidth?: Breakpoint;
};

export const Modal = ({
  isOpen,
  onClose,
  title,
  subtitle,
  children,
  onHeaderButtonClick,
  headerButtonText,
  showCloseButton = true,
  maxWidth,
}: ModalProps) => {
  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: '#EFEDE7',
          boxShadow: 'none',
          borderRadius: 0,
        },
      }}
      maxWidth={maxWidth ? maxWidth : false}
      open={isOpen}
      TransitionComponent={Transition}
      onClose={showCloseButton ? () => onClose() : () => {}}
    >
      <DialogContent
        style={{
          padding: 0,
        }}
      >
        <div className={`px-8 py-4 lg:py-16 relative`}>
          <div className='flex flex-row justify-between'>
            {subtitle && (
              <h4 className='text-lg font-nanum  text-forest-primary'>
                {subtitle}
              </h4>
            )}
            {showCloseButton && (
              <div
                className='cursor-pointer ml-auto absolute right-6 top-6'
                onClick={onClose}
              >
                <img alt='close' src={closeIcon} />
              </div>
            )}
          </div>
          <div className='flex flex-row justify-between items-center'>
            {title && (
              <h2 className='text-3xl sm:text-5xl font-nanum  text-forest-primary'>
                {title}
              </h2>
            )}
            {onHeaderButtonClick && (
              <div
                className='px-8 h-11 flex justify-center items-center bg-white text-forest-primary cursor-pointer'
                onClick={onHeaderButtonClick}
              >
                {headerButtonText || ''}
              </div>
            )}
          </div>

          <div className='py-4'>{children}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
