import { Member } from '../../api/ceremony';
import {
  ChecklistItemDefinition,
  MemberChecklistItem,
} from '../../api/checklistItemDefinitions';
import { useDashboard } from '../../provider/dashboardProvider';

import { ChecklistItemIdentifier } from './identifiers';

const checklistItemsYoutubeVideoIdentifiers = {
  [ChecklistItemIdentifier.PLANNER_PORTAL_DEMO]: 'xpL2sIXYBY0',
};

const completeOnWatchDemoClickIdentifiers = [
  ChecklistItemIdentifier.PLANNER_PORTAL_DEMO,
];

export const getWatchDemoOnClickHandler = (
  item: ChecklistItemDefinition,
  ceremonyId: number | undefined,
  currentUser: Member | undefined,
  saveMemberChecklistItem: (
    memberChecklistItem: MemberChecklistItem
  ) => Promise<void>
) => {
  const { setYoutubeVideoModalOpen, setYoutubeVideoUrl } = useDashboard();

  if (checklistItemsYoutubeVideoIdentifiers.hasOwnProperty(item.identifier)) {
    return () => {
      if (
        completeOnWatchDemoClickIdentifiers.includes(
          item.identifier as ChecklistItemIdentifier
        )
      ) {
        //IFFY - Immediately Invoked Function Expression
        (async () => {
          const newMemberChecklistItem: MemberChecklistItem = {
            ceremony_id: ceremonyId,
            member_id: currentUser?.id,
            checklist_item_definition_id: item.id,
            skipped: false,
            completed: true,
          };

          await saveMemberChecklistItem(newMemberChecklistItem);
        })();
      }

      setYoutubeVideoUrl(item.URL);
      setYoutubeVideoModalOpen(true);
    };
  } else {
    if (item.URL) {
      return () => {
        setYoutubeVideoUrl(item.URL);
        setYoutubeVideoModalOpen(true);
      };
    } else {
      return undefined;
    }
  }
};
