import { useEffect, useState } from 'react';

import { GenericResponse, updateCeremonyMember } from '../../api/ceremony';
import { CustomSwitch } from '../../components/forms/CustomSwitch';
import Button from '../../components/v2/Button/Button';
import { Modal } from '../../components/v2/Modal/Modal';
import { Typography } from '../../components/v2/Typography/Typography';
import { useDashboard } from '../../provider/dashboardProvider';
import { useSnackbar } from '../../provider/snackbarProvider';
import { InfoBox } from '../ModulesV2/RingExchange/InfoBox';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

type AccessControlMemberElementProps = {
  title: string;
  shareTime: boolean;
  onChangeShareTime: (val: boolean) => void;
  shareContents: boolean;
  onChangeShareContents: (val: boolean) => void;
  withBottomBorder?: boolean;
};
const AccessControlMemberElement = (props: AccessControlMemberElementProps) => {
  const {
    title,
    shareTime,
    onChangeShareTime,
    shareContents,
    onChangeShareContents,
  } = props;
  return (
    <div className='w-full mt-2 p-6 border border-forest-100 rounded-3xl'>
      <Typography type='body-600'>{title}</Typography>
      <div className='mt-2 flex flex-row justify-between items-center'>
        <Typography variant='functional-low' type='body-400'>
          Share vow length
        </Typography>
        <CustomSwitch checked={shareTime} onChange={onChangeShareTime} />
      </div>
      <div className='mt-2 flex flex-row justify-between items-center'>
        <Typography variant='functional-low' type='body-400'>
          Share contents of vows
        </Typography>
        <CustomSwitch
          checked={shareContents}
          onChange={onChangeShareContents}
        />
      </div>
    </div>
  );
};

export const AccessControlModal = (props: Props) => {
  const { isOpen, onClose } = props;
  const [shareVowsTimeWithOfficiant, setShareVowsTimeWithOfficiant] =
    useState(false);
  const [shareVowsContentWithOfficiant, setShareVowsContentWithOfficiant] =
    useState(false);
  const [shareVowsTimeWithPartner, setShareVowsTimeWithPartner] =
    useState(false);
  const [shareVowsContentWithPartner, setShareVowsContentWithPartner] =
    useState(false);
  const [shareVowsTimeWithPlanner, setShareVowsTimeWithPlanner] =
    useState(false);
  const [shareVowsContentWithPlanner, setShareVowsContentWithPlanner] =
    useState(false);
  const [shouldSave, setShouldSave] = useState(false);
  const { currentUser } = useDashboard();
  const { openSnackBar } = useSnackbar();

  useEffect(() => {
    if (currentUser) {
      setShareVowsTimeWithOfficiant(
        currentUser.share_vows_time_with_officiant || false
      );
      setShareVowsContentWithOfficiant(
        currentUser.share_vows_content_with_officiant || false
      );
      setShareVowsTimeWithPartner(
        currentUser.share_vows_time_with_partner || false
      );
      setShareVowsContentWithPartner(
        currentUser.share_vows_content_with_partner || false
      );
      setShareVowsTimeWithPlanner(
        currentUser.share_vows_time_with_planner || false
      );
      setShareVowsContentWithPlanner(
        currentUser.share_vows_content_with_planner || false
      );
    }
  }, [currentUser]);

  const updateMember = async () => {
    if (currentUser) {
      try {
        const member = currentUser;
        member.share_vows_time_with_officiant = shareVowsTimeWithOfficiant;
        member.share_vows_content_with_officiant =
          shareVowsContentWithOfficiant;
        member.share_vows_time_with_partner = shareVowsTimeWithPartner;
        member.share_vows_content_with_partner = shareVowsContentWithPartner;
        member.share_vows_time_with_planner = shareVowsTimeWithPlanner;
        member.share_vows_content_with_planner = shareVowsContentWithPlanner;

        const response: GenericResponse = await updateCeremonyMember(member);
        if (response.success) {
          openSnackBar(`Permissions changed`);
        } else {
          openSnackBar(`Something went wrong`, 'error');
        }
      } catch (err) {
        openSnackBar(`Something went wrong`, 'error');
      }
    }
  };

  useEffect(() => {
    if (shouldSave) {
      void updateMember();
      setShouldSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSave]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={''}>
      <div className='max-w-xl flex justify-start items-start text-forest-primary'>
        <div className='max-w-2xl flex flex-col justify-start items-start'>
          <Typography type='display-100'>Vow Privacy</Typography>
          <Typography type='body-400' className='mt-4'>
            Want to keep your vows a secret? Manage the parts of your vows
            collaborators have access to.
          </Typography>
          <InfoBox
            className='my-4'
            text='Target length is shared by default to all collaborators'
          />
          <AccessControlMemberElement
            title='Officiant'
            shareContents={shareVowsContentWithOfficiant}
            onChangeShareContents={(val) => {
              setShareVowsContentWithOfficiant(val);
              setShouldSave(true);
            }}
            shareTime={shareVowsTimeWithOfficiant}
            onChangeShareTime={(val) => {
              setShareVowsTimeWithOfficiant(val);
              setShouldSave(true);
            }}
          />
          <AccessControlMemberElement
            title='Partner'
            shareContents={shareVowsContentWithPartner}
            onChangeShareContents={(val) => {
              setShareVowsContentWithPartner(val);
              setShouldSave(true);
            }}
            shareTime={shareVowsTimeWithPartner}
            onChangeShareTime={(val) => {
              setShareVowsTimeWithPartner(val);
              setShouldSave(true);
            }}
          />
          <AccessControlMemberElement
            title='Wedding Planner'
            shareContents={shareVowsContentWithPlanner}
            onChangeShareContents={(val) => {
              setShareVowsContentWithPlanner(val);
              setShouldSave(true);
            }}
            shareTime={shareVowsTimeWithPlanner}
            onChangeShareTime={(val) => {
              setShareVowsTimeWithPlanner(val);
              setShouldSave(true);
            }}
            withBottomBorder={false}
          />
          <Button className=' mt-2 self-center' onClick={onClose}>
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
};
