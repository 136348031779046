import { useNavigate } from 'react-router-dom';

import { MemberType } from '../../../api/ceremony';
import { useDashboard } from '../../../provider/dashboardProvider';

export const RedirectHandler = () => {
  const navigate = useNavigate();
  const { ceremony, currentRole } = useDashboard();
  if (
    currentRole === MemberType.bride ||
    currentRole === MemberType.groom ||
    currentRole === MemberType.other ||
    currentRole === MemberType.officiant
  ) {
    navigate(`/ceremony/${ceremony?.id.toString() || ''}/checklist`);
  } else if (currentRole === MemberType.weddingPlanner) {
    navigate(`/ceremony/${ceremony?.id.toString() || ''}/planner`);
  } else if (currentRole === MemberType.guests) {
    navigate(`/ceremony/${ceremony?.id.toString() || ''}/guest`);
  }
  return <div>Loading...</div>;
};
