import { FieldProps } from 'formik';

import CustomDropdown from '../../../components/forms/CustomDropdown';
import CustomTextInput from '../../../components/forms/CustomTextInput';

interface CustomSelectProps {
  type?: string;
  label: string;
  placeholder?: string;
  setChanged: () => void;
  readOnly?: boolean;
  noSort?: boolean;
  className?: string;
  options: [];
}

export const CustomFormikSelect = ({
  field, // { name, value, onChange, onBlur }
  form,
  className,
  setChanged,
  label,
  ...props
}: // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
CustomSelectProps & FieldProps<string>) => (
  <CustomDropdown
    label={label}
    className={className}
    value={field.value}
    name={field.name}
    onChange={(value: string) => {
      field.onChange(value);
      if (setChanged) {
        setChanged();
      }
    }}
    placeholder={props.placeholder}
    required={true}
    type={props.type}
    options={props.options}
    noSort={props.noSort}
    inputProps={{ readOnly: props.readOnly }}
  />
);
