import { SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';

import { saveCulture } from '../../../api/culture';
import { getRitualTypes, RitualType } from '../../../api/ritualType';
import { getTags, Tag } from '../../../api/tags';
import { CustomMultiselect } from '../../../components/forms/CustomMultiselect';
import CustomTextInput from '../../../components/forms/CustomTextInput';
import Button from '../../../components/v2/Button/Button';
import { Modal } from '../../../components/v2/Modal/Modal';
import { Typography } from '../../../components/v2/Typography/Typography';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useCultures } from '../../../provider/cultureProvider';

import './readingsSearch.scss';
// import { DropdownMultiselect } from '../../../components/v2/Dropdown/DropdownMultiselect';
// import { Input } from '../../../components/v2/Input/Input';

type ReadingsSearchProps = {
  isOpen: boolean;
  onClose: () => void;
  ceremonyId: number;
  handleSave: (
    title: string,
    cultureValues: string[],
    ritualTypeValues: string[],
    tagValues: string[],
    description: string,
    time?: number
  ) => Promise<void>;
};

export const AddReadingModal = (props: ReadingsSearchProps) => {
  const { isOpen, onClose, handleSave, ceremonyId } = props;

  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');

  const [time, setTime] = useState('');
  const [description, setDescription] = useState('');
  const [cultureValues, setCultureValues] = useState<string[]>([]);
  const [ritualTypeValues, setRitualTypeValues] = useState<string[]>([]);
  const [tagValues, setTagValues] = useState<string[]>([]);

  const [titleError, setTitleError] = useState('');
  const [authorError, setAuthorError] = useState('');
  const [timeError, setTimeError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [cultureValuesError, setCultureValuesError] = useState('');
  const [ritualTypeValuesError, setRitualTypeValuesError] = useState('');
  const [tagValuesError, setTagValuesError] = useState('');
  const [ritualTypes, setRitualTypes] = useState<RitualType[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);

  const { cultures, fetchCultures } = useCultures();
  const { openSnackBar } = useSnackbar();

  useEffect(() => {
    const fetchRitualTypes = async () => {
      try {
        const response = await getRitualTypes();
        if (response.success) {
          setRitualTypes(response.data);
        }
      } catch (error) {}
    };
    const fetchTags = async () => {
      try {
        const response = await getTags();
        if (response.success) {
          setTags(response.data);
        }
      } catch (error) {}
    };
    void fetchCultures();
    void fetchRitualTypes();
    void fetchTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCultureChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setCultureValues(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    setCultureValuesError('');
  };

  const handleAddNewCulture = async (cultureName: string) => {
    try {
      const response = await saveCulture({
        name: cultureName,
        created_for: ceremonyId,
      });
      if (response.data) {
        await fetchCultures();
        const newCultureId = response.data.id;
        const updatedCultureValues = [
          ...cultureValues,
          newCultureId.toString(),
        ];
        setCultureValues(updatedCultureValues);
        // On autofill we get a stringified value.
      }
    } catch (error) {
      openSnackBar(
        error instanceof Error
          ? error.message
          : 'Something went wrong. Could not send password link',
        5000,
        'error'
      );
    }
  };

  const handleRitualTypeChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setRitualTypeValues(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    setRitualTypeValuesError('');
  };

  const handleTagChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setTagValues(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    setTagValuesError('');
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={'Add your own reading/ritual'}
      >
        <div>
          <Typography variant='functional-low' type='body-200'>
            Title
          </Typography>
          <CustomTextInput
            className='w-full ml-0'
            value={title}
            placeholder={'Enter a title'}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              setTitle(ev.target.value);
              setTitleError('');
            }}
            type={'text'}
            error={!!titleError}
            helperText={titleError}
          />
          <Typography variant='functional-low' type='body-200' className='mt-6'>
            Associated cultures and/or religions
          </Typography>
          <CustomMultiselect
            className='w-full'
            options={cultures.map((r) => ({
              label: r.name,
              value: r.id.toString(),
            }))}
            values={cultureValues}
            handleChange={handleCultureChange}
            chipType='culture'
            error={!!cultureValuesError}
            helperText={cultureValuesError}
            handleAddNew={handleAddNewCulture}
          />
          <Typography variant='functional-low' type='body-200' className='mt-6'>
            Ceremony Placement
          </Typography>
          <CustomMultiselect
            className='w-full'
            options={ritualTypes.map((r) => ({
              label: r.name,
              value: r.id.toString(),
            }))}
            values={ritualTypeValues}
            handleChange={handleRitualTypeChange}
            chipType='placement'
            error={!!ritualTypeValuesError}
            helperText={ritualTypeValuesError}
          />
          <Typography variant='functional-low' type='body-200' className='mt-6'>
            Method
          </Typography>
          <CustomMultiselect
            className='w-full '
            options={tags.map((r) => ({
              label: r.name,
              value: r.id.toString(),
            }))}
            values={tagValues}
            placeholder={'Select relevant tags'}
            handleChange={handleTagChange}
            chipType='method'
            error={!!tagValuesError}
            helperText={tagValuesError}
          />
          <Typography variant='functional-low' type='body-200' className='mt-6'>
            Duration
          </Typography>
          <CustomTextInput
            className='w-full ml-0'
            value={time}
            placeholder={'Estimated time in minutes'}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              setTime(ev.target.value);
              setTimeError('');
            }}
            type={'number'}
            error={!!timeError}
            helperText={timeError}
          />
          <Typography variant='functional-low' type='body-200' className='mt-6'>
            Describe your reading/ritual
          </Typography>
          <CustomTextInput
            className='w-full ml-0'
            multiline
            rows={5}
            value={description}
            placeholder={'Enter the description'}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              setDescription(ev.target.value);
              setDescriptionError('');
            }}
            error={!!descriptionError}
            helperText={descriptionError}
          />
          <div className=' mt-4 w-full flex flex-row justify-between items-center'>
            <div>
              <Typography
                onClick={onClose}
                variant='functional-low'
                type='body-400'
                className='border-0 border-b border-dashed border-forest-primary cursor-pointer'
              >
                Close
              </Typography>
            </div>

            <Button
              onClick={async () => {
                if (!title) {
                  setTitleError('Required');
                }
                if (!time) {
                  setTimeError('Required');
                }
                if (!description) {
                  setDescriptionError('Required');
                }
                if (cultureValues.length === 0) {
                  setCultureValuesError('Required');
                }
                if (ritualTypeValues.length === 0) {
                  setRitualTypeValuesError('Required');
                }
                if (tagValues.length === 0) {
                  setTagValuesError('Required');
                }
                if (
                  title &&
                  cultureValues.length > 0 &&
                  ritualTypeValues.length > 0 &&
                  tagValues.length > 0 &&
                  description &&
                  time
                ) {
                  await handleSave(
                    title,
                    cultureValues,
                    ritualTypeValues,
                    tagValues,
                    description,
                    parseInt(time)
                  );

                  setTitle('');
                  setTime('');
                  setDescription('');
                  setCultureValues([]);
                  setRitualTypeValues([]);
                  setTagValues([]);
                }
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
